import { BiCalendar, BiChevronDown } from "react-icons/bi";
import {
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
} from "@chakra-ui/react";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { useCallback, useEffect, useState } from "react";

interface MonthPickerProps {
  begin: Date;
  end: Date;
  value?: Date | undefined;
  onChange?: (value: Date) => void;
}

export default function MonthPicker({
  begin,
  end,
  value,
  onChange,
}: MonthPickerProps) {
  const months: string[] = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const [year, setYear] = useState<number>(end.getFullYear());
  const [month, setMonth] = useState<number>(end.getMonth());

  const handleBackwardYear = useCallback(() => {
    if (year <= begin.getFullYear()) return;
    setYear(year - 1);
  }, [begin, year, setYear]);

  const handleForwardYear = useCallback(() => {
    if (year >= end.getFullYear()) return;
    setYear(year + 1);
  }, [end, year, setYear]);

  useEffect(() => {
    if (value) {
      setYear(value.getFullYear());
      setMonth(value.getMonth());
    }
  }, [value, setMonth, setYear]);

  return (
    <Menu strategy="fixed">
      <MenuButton
        as={Button}
        size="sm"
        variant="outline"
        leftIcon={<Icon as={BiCalendar} mr={1} />}
        rightIcon={<Icon as={BiChevronDown} />}
      >
        {months?.[month]}/{year}
      </MenuButton>
      <MenuList display="flex" flexDir="column">
        <Flex
          mb={4}
          flexGrow={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            aria-label="Backward Year"
            variant="unstyled"
            isDisabled={year <= begin.getFullYear()}
            onClick={handleBackwardYear}
          >
            <Icon as={MdOutlineArrowBackIosNew} />
          </Button>
          <Box aria-label="Year">{year}</Box>
          <Button
            aria-label="Forward Year"
            variant="unstyled"
            isDisabled={year >= end.getFullYear()}
            onClick={handleForwardYear}
          >
            <Icon as={MdOutlineArrowForwardIos} />
          </Button>
        </Flex>
        <SimpleGrid p={2} columns={4}>
          {months.map((m: string, index: number) => (
            <MenuItem
              key={m}
              aria-label={`${m}${
                month === index && year === value?.getFullYear()
                  ? " Selected"
                  : ""
              }`}
              size="sm"
              variant="unstyled"
              borderWidth={1}
              borderColor="#f5f5f5"
              borderLeftWidth={
                month === index && year === value?.getFullYear() ? 2 : 1
              }
              borderLeftColor={
                month === index && year === value?.getFullYear()
                  ? "green.400"
                  : "#f5f5f5"
              }
              borderRadius={0}
              position="relative"
              isDisabled={
                new Date(begin.getFullYear(), begin.getMonth(), 0, 0, 1) >=
                  new Date(year, index) ||
                new Date(end.getFullYear(), end.getMonth(), 23, 59, 59) <=
                  new Date(year, index)
              }
              onClick={() => {
                setMonth(index);
                onChange?.(new Date(year, index, 1, 0, 0, 1));
              }}
            >
              {m.substring(0, 3)}
            </MenuItem>
          ))}
        </SimpleGrid>
      </MenuList>
    </Menu>
  );
}
