import chuvas from "assets/images/icons/mapa-tematico/chuvas.svg";
import concentracaoColheita from "assets/images/icons/mapa-tematico/concentracao-colheita.svg";
import distribuicaoVarietal from "assets/images/icons/mapa-tematico/distribuicao-varietal.svg";
import imagemSatelite from "assets/images/icons/mapa-tematico/imagem-satelite.svg";
import texturaSolo from "assets/images/icons/mapa-tematico/textura-solo.svg";
import operacoesExecutadas from "assets/images/icons/mapa-tematico/operacoes-executadas.svg";
import numeroDeAplicacoes from "assets/images/icons/mapa-tematico/numero-de-aplicacoes.svg";
import produtividade from "assets/images/icons/mapa-tematico/produtividade.svg";
import idadeDoTalhao from "assets/images/icons/mapa-tematico/idade-do-talhao.svg";
import ordensDeServicosAbertas from "assets/images/icons/mapa-tematico/ordens-de-servicos-abertas.svg";
import distribuicaoDeEquipes from "assets/images/people-group.svg";

import { TypesMapEnum } from "core/features/maps/data";

export const getIcon = (id: string): any => {
  if (id === TypesMapEnum.DISTRIBUICAO_VARIETAL) {
    return distribuicaoVarietal;
  }

  if (id === TypesMapEnum.CHUVAS) {
    return chuvas;
  }

  if (id === TypesMapEnum.CONCENTRACAO_COLHEITA) {
    return concentracaoColheita;
  }

  if (id === TypesMapEnum.SATELLITE_IMAGE) {
    return imagemSatelite;
  }

  if (id === TypesMapEnum.OPERACOES_EXECUTADAS) {
    return operacoesExecutadas;
  }

  if (id === TypesMapEnum.NUMERO_DE_APLICACOES) {
    return numeroDeAplicacoes;
  }

  if (id === TypesMapEnum.PRODUTIVIDADE) {
    return produtividade;
  }

  if (id === TypesMapEnum.IDADE_DO_TALHAO) {
    return idadeDoTalhao;
  }
  if (id === TypesMapEnum.ORDENS_DE_SERVICO_ABERTA) {
    return ordensDeServicosAbertas;
  }

  if (id === TypesMapEnum.DISTRIBUICAO_DE_EQUIPES) {
    return distribuicaoDeEquipes;
  }

  return texturaSolo;
};
