import { v4 } from "uuid";
import JsFileDownloader from "js-file-downloader";

export function forceDownload(blob: BlobPart) {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", v4());

  // Append to html link element page
  document.body.appendChild(link);

  setTimeout(() => {
    // Start download
    link.click();
  }, 1500);

  // Clean up and remove the link
  link.parentNode?.removeChild(link);
}

export async function forceDownloadByUrl(url: string) {
  const link = document.createElement("a");
  link.href = url;
  const extension = url?.split(".").pop()?.split("?")[0];
  link.setAttribute("download", `file-${+new Date()}.${extension}`);
  link.setAttribute("target", `_blank`);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode?.removeChild(link);
}

export async function forceDownloadByUrl2(fileUrl: string) {
  new JsFileDownloader({
    url: fileUrl,
  });
}
