import styled from "styled-components";
import theme from "@chakra-ui/theme";

export const ErrorBoundaryComponent = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.white};
  flex-direction: column;
`;
