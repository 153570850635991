import { AxiosError } from "axios";
import { useAuth } from "core/features/auth/store";
import { useFilters } from "core/features/filters/store";
import { showError } from "infra/helpers/alerta";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getProfile } from "../services";
import { useLogout } from "./useLogout";

export function useBoot() {
  const history = useHistory();
  const { handleLogout } = useLogout();
  const { filters, updateFiltroOcupacao } = useFilters();

  useEffect(() => {
    if (!filters.filtroDistribuicaoVarietal?.variedadeGrupos) {
      updateFiltroOcupacao({
        variedadeGrupos: [],
        tecnologias: [],
        variedades: [],
        ocupacoes: [],
        finalidades: [],
      });
    }
  }, [
    filters.filtroDistribuicaoVarietal?.variedadeGrupos,
    updateFiltroOcupacao,
  ]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const init = useCallback(async () => {
    try {
      setIsLoading(true);

      const data = await getProfile();

      useAuth.setState({
        user: data?.data!,
      });

      if (!data?.data.aceitoTermosDoWeb) {
        history.push("/auth/term-of-use");
      }
    } catch (error: any) {
      const err: AxiosError = error as any;

      if (
        err.response?.status === 403 ||
        error?.message.includes("Usuário não autorizado")
      ) {
        await showError(
          "Solicite acesso ao seu administrador.",
          "Usuário não encontrado"
        );

        await handleLogout();
      }
    } finally {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, handleLogout]);

  return { isLoading, init };
}
