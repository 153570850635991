import React, { useState } from "react";

import {
  Box,
  Button,
  Flex,
  Image,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Popover,
  useDisclosure,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { TalhoesProps } from "core/features/talhoes/typings/listaEGeojson";
import TrashIcon from "assets/images/icons/trash.svg";
import UploadIcon from "assets/images/icons/upload.svg";
import CloseIcon from "assets/images/icons/close-icon.svg";
import KMLIcon from "assets/images/icons/kml-icon.svg";
import { ModalModeEnum } from "core/features/talhoes/contexts/Talhoes.context";
import { FaCheck } from "react-icons/fa";
import { useUploadKMLCode } from "../../../hooks/useUploadKMLCode";

interface MapEditionModeProps {
  showEditOptions: boolean;
  currentItem?: TalhoesProps;
  showCancelModal: boolean;

  ableToSave: boolean;
  toogleEditOptions: (prop: boolean) => void;
  onCancel: () => void;
  eraseAll: () => void;

  onChangeModalMode: (prop: ModalModeEnum | undefined) => void;
  onOpenModal: () => void;
  onSave: () => void;
}

const MapEditionMode = ({
  showEditOptions,
  currentItem,
  showCancelModal,
  ableToSave,
  toogleEditOptions,
  eraseAll,
  onCancel,
  onChangeModalMode,
  onOpenModal,
  onSave,
}: MapEditionModeProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { items, onChange, utils } = useUploadKMLCode();
  const [tipVisible, setTipVisible] = useState<boolean>(false);

  return (
    <>
      {!showEditOptions ? (
        <Button
          width="110px"
          height="45px"
          borderRadius="7px"
          background="white"
          border="2px solid #D9D9D9"
          marginBottom="13px"
          _active={{}}
          _focus={{}}
          _hover={{}}
          marginLeft="10px"
          disabled={!currentItem}
          onClick={() => currentItem && toogleEditOptions(true)}
        >
          Editar talhão
        </Button>
      ) : (
        <Flex
          justifyContent="space-between"
          padding="0 10px"
          paddingBottom={"10px"}
        >
          <Box display="flex" gridGap="4px">
            <Button
              background="white"
              border="2px solid #D9D9D9"
              color="gray"
              onClick={
                showCancelModal ? onCancel : () => toogleEditOptions(false)
              }
            >
              Cancelar edição
            </Button>

            <Button
              background="white"
              border="2px solid #D9D9D9"
              width="35px"
              height="40px"
              padding="0"
              _active={{}}
              _focus={{}}
              _hover={{}}
              disabled={!showCancelModal && !currentItem?.possuiGeometria}
              onClick={() => {
                onChangeModalMode(ModalModeEnum.delete);
                onOpenModal();
              }}
            >
              <Image src={TrashIcon} />
            </Button>

            <Popover isOpen={isOpen} isLazy>
              <PopoverTrigger>
                <Button
                  background="white"
                  border="2px solid #D9D9D9"
                  width="35px"
                  height="40px"
                  padding="0"
                  _active={{}}
                  _focus={{}}
                  _hover={{}}
                  onClick={() => {
                    if (isOpen) {
                      return onClose();
                    }
                    return onOpen();
                  }}
                >
                  <Image src={UploadIcon} />
                </Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent
                  marginLeft="44%"
                  paddingLeft="22px"
                  width={["419px", "30vw"]}
                  outline={"none"}
                  _focus={{}}
                  _active={{}}
                  padding={"0 22px"}
                >
                  <PopoverHeader
                    fontWeight="medium"
                    color="#676C77"
                    fontSize={"1.1rem"}
                    marginBottom="11px"
                    marginTop="15px"
                  >
                    <Flex justifyContent="space-between">
                      Importar polígono via código KML
                      <Image
                        src={CloseIcon}
                        width="22px"
                        height="22px"
                        onClick={() => onClose()}
                        cursor="pointer"
                      />
                    </Flex>
                  </PopoverHeader>

                  <PopoverBody>
                    <Flex gridGap="10px" alignItems={"center"}>
                      <Text color="#676C77" fontSize="1rem" fontWeight="medium">
                        Informes as coordenadas
                      </Text>

                      <Text
                        border={"1px solid gray"}
                        width={"15px"}
                        height={"15px"}
                        borderRadius={"50%"}
                        textAlign="center"
                        display="flex"
                        alignItems="center"
                        padding={2}
                        justifyContent="center"
                        transform="rotate(180deg)"
                        onMouseEnter={() => setTipVisible(true)}
                        onMouseLeave={() => setTipVisible(false)}
                      >
                        i
                        <Box
                          position="absolute"
                          top="20px"
                          transform="rotate(-180deg)"
                          height="150px"
                          background="white"
                          minWidth="313px"
                          boxShadow=" 0px 3px 6px #00000029"
                          borderRadius="5px"
                          padding="14px"
                          alignItems="center"
                          opacity={tipVisible ? 1 : 0}
                          transition="all .5s"
                          pointerEvents="none"
                        >
                          <Flex
                            gridGap="12px"
                            alignItems="center"
                            height={"100%"}
                          >
                            <Image src={KMLIcon} />
                            <Box>
                              <Text
                                color="gray"
                                fontSize="15px"
                                fontWeight="medium"
                                textAlign="left"
                              >
                                Coordenadas do arquivo KML
                              </Text>
                              <Text
                                textAlign="left"
                                color="#676C77"
                                fontSize="13px"
                                width="100%"
                              >
                                Podem ser encontradas dentro do arquivo após a
                                tag{" "}
                                <strong
                                  style={{
                                    color: "#11A557",
                                    fontWeight: 400,
                                  }}
                                >
                                  "{"<"}/coordinates{">"}"
                                </strong>
                              </Text>
                            </Box>
                          </Flex>
                        </Box>
                      </Text>
                    </Flex>
                    <Textarea
                      _focus={{}}
                      _active={{}}
                      resize={"none"}
                      border="1px solid #707070 !important"
                      height="115px"
                      placeholder="-58.9669821935441,-13.5509404150689 -58.9670653839886,-13.550989334337 -58.9670702063349,-13.5509887832306"
                      fontSize="0.8rem"
                      value={items.code}
                      onChange={(e) => onChange.setCode(e.target.value)}
                    ></Textarea>
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      marginTop="23px"
                    >
                      <Button
                        _active={{}}
                        _focus={{}}
                        background="none"
                        fontWeight="medium"
                        fontSize="14px"
                        padding={0}
                        onClick={onChange.handleClear}
                      >
                        LIMPAR
                      </Button>
                      <Button
                        _active={{}}
                        _focus={{}}
                        fontWeight="medium"
                        background="#11A557 0% 0% no-repeat padding-box"
                        color="white"
                        height="33px"
                        fontSize="13px"
                        onClick={() => {
                          onClose();
                          utils.applyKMLCoords();
                        }}
                        // width="96px"
                      >
                        <FaCheck
                          style={{
                            marginRight: "8px",
                          }}
                        />{" "}
                        APLICAR
                      </Button>
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          </Box>
          <Box>
            <Button
              background="green"
              color="white"
              marginRight="10px"
              onClick={onSave}
              disabled={!ableToSave}
            >
              Salvar
            </Button>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default MapEditionMode;
