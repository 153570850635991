import { useMutation } from "react-query";

import URL from "core/resources/urls";
import { httpAgrodrive } from "infra/http";
import { ItemEditForm } from "../types/arquivos";
import config from "infra/config";

export default function useMoveDir() {
  return useMutation(
    [URL.AGRODRIVE.DIRETORIO()],
    async (body: ItemEditForm) => {
      // const form = new FormData();
      // form.append("Id", body.Id);
      // if (body.NovaLocalizacao) {
      //   form.append("NovaLocalizacao", body.NovaLocalizacao);
      // }
      // if (body.NovoNomeArquivo) {
      //   form.append("NovoNomeArquivo", body.NovoNomeArquivo);
      // }
      // if (body.Tags) {
      //   body.Tags?.map((item: string) => form.append("Tags", item));
      // }

      await httpAgrodrive.put(URL.AGRODRIVE.DIRETORIO(), body, {
        headers: {
          "x-api-key": config.agrodriveClientId,
        },
      });
    }
  );
}
