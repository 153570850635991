export enum MapTypeId {
  HYBRID = "hybrid",
  ROADMAP = "roadmap",
  SATELLITE = "satellite",
  TERRAIN = "terrain",
}

export enum OcupacaoEnum {
  TODOS = 0,
  SOJA = 1,
  MILHO = 2,
  ALGODAO = 3,
}

export enum ModalTalhaoIndex {
  Detalhes = 0,
  OrdemServico = 1,
  OperacoesRealizadas = 2,
  Aplicacoes = 3,
  Produtividade = 4,
  IdadeDoTalhao = 5,
}
