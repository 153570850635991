import { useState, useEffect, useContext } from "react";
import { Box } from "@chakra-ui/react";
import { atrasosColumsName } from "./columns";
import DatatableAntd from "components/datatable-antd";
import AtrasosModal from "../Modal";
import JustificativasModal from "../Modal/JustificativasModal";
import { useGetAtrasos } from "core/features/justificativa/hooks/useGetAtrasos";
import { JustificativaContext } from "core/features/justificativa/context/Justificativa.context";
import { FooterAtrasosTable } from "./Footer";

const AtrasosTable = () => {
  const {
    currentUnidade,
    currentSafra,
    currentCultura,
    currentRegional,
    currentMotivo,
    selecteds,
    apenasAprovados,
    apenasJustificados,
    apenasAprovadosCiente,
    apenasPendentesJustificativas,
    page,
    size,
    setPage,
    setSize,
    setSelecteds,
  } = useContext(JustificativaContext);

  const [atrasos, setAtrasos] = useState([]);
  const [isAtrasosModalOpen, setIsAtrasosModalOpen] = useState(false);
  const [isJustificativasModalOpen, setIsJustificativasModalOpen] = useState(false);
  const [atraso, setAtraso] = useState();
  const [justificativa, setJustificativa] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [totalAtrasos, setTotalAtrasos] = useState<number>(0);

  const { data, isFetching } = useGetAtrasos({
    idFazenda: currentUnidade?.id,
    codigoOcupacao: currentCultura?.codigo,
    idSafra: currentSafra?.id,
    regional: currentRegional,
    idPlanoOperacionalOperacaoTipoAtraso: currentMotivo?.idPlanoOperacionalOperacaoTipoAtraso,
    apenasAprovados,
    apenasJustificados,
    apenasAprovadosCiente,
    apenasPendentesJustificativas,
    page,
    size,
  });

  const openAtrasosModal = (atraso: any) => {
    setIsAtrasosModalOpen(true);
    setAtraso(atraso);
  };

  const openJustificativasModal = (atraso: any) => {
    setIsJustificativasModalOpen(true);
    setAtraso(atraso);
  }

  const openDetalhesJustificativa = (justificativa: any) => {
    setIsJustificativasModalOpen(false);
    setIsAtrasosModalOpen(true);
    setJustificativa(justificativa);
  }

  const reset = () => {
    setIsAtrasosModalOpen(false);
    setIsJustificativasModalOpen(false);
    setAtraso(undefined);
    setJustificativa(undefined);
  }

  useEffect(() => {
    setSelecteds([]);
    setSelectedRowKeys([]);
    setAtrasos(data?.atrasos);
    setTotalAtrasos(data?.total);
  }, [data]);

  useEffect(() => {
    if (selecteds?.length === 0) {
      setSelectedRowKeys([]);
    }
  }, [selecteds]);

  return (
    <Box height="100%" width="100%" padding="32px">
      {isAtrasosModalOpen && (
        <AtrasosModal
          isOpen={isAtrasosModalOpen}
          closeModal={reset}
          atraso={atraso}
          justificativa={justificativa}
        />
      )}

      {isJustificativasModalOpen && (
        <JustificativasModal
          isOpen={isJustificativasModalOpen}
          closeModal={reset}
          atraso={atraso}
          openDetalhesJustificativa={openDetalhesJustificativa}
        />
      )}

      <DatatableAntd
        columns={atrasosColumsName(
          openAtrasosModal,
          openJustificativasModal,
        )}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelecteds(selectedRows);
          },
        }}
        loading={isFetching}
        dataSource={atrasos}
        pagination={{
          total: totalAtrasos,
          showSizeChanger: true,
          onChange: (page: number, size: number) => {
            setPage(page);
            setSize(size);
          },
        }}
        scroll={{ y: 520 }}
        onRow={() => ({
          style: {
            cursor: "pointer",
          }
        })}
        footer={() => <FooterAtrasosTable />}
      />
    </Box>
  );
};

export default AtrasosTable;
