import create from "zustand";
import { CustomFeature, LatLong, TalhaoResult } from "../typings";

export interface UseMapStore {
  data: TalhaoResult | null;
  talhao?: CustomFeature | null | undefined;
  center: LatLong | null;
  update: (value: TalhaoResult) => void;
  updateTalhao: (talhao: CustomFeature | null | undefined) => void;
  updateCenter: (coordinates: LatLong) => void;
}

export const useMapStore = create<UseMapStore>((set) => ({
  data: null,
  talhao: null,
  center: null,
  updateCenter: (coordinates: LatLong) => set(() => ({ center: coordinates })),
  updateTalhao: (talhao?: CustomFeature | null | undefined) =>
    set(() => ({ talhao })),
  update: (data: TalhaoResult) => set(() => ({ data })),
}));
