import { useContext, useEffect, useRef, useState } from "react";
import {
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import { gray500 } from "core/resources/theme/colors";
import { FaSearch } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { TalhaoDetailContext } from "core/features/maps/talhao/TalhaoDetailContext";

interface InputSearchProps extends Omit<InputProps, "onChange"> {
  timeoutDelay?: number;
  onChange: (text: string) => any;
  placeholder?: string;
  isNumeric?: boolean;
}

const InputSearch = ({
  timeoutDelay = 300,
  onChange,
  placeholder = "Pesquisar...",
  isNumeric,
  isDisabled,
  ...rest
}: InputSearchProps) => {
  const [value, setValue] = useState<string>("");
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();
  const { ordensDeServicoNumeroOs } = useContext(TalhaoDetailContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const _value = isNumeric ? value.replace(/[^0-9]/gi, "") : value;

    const slicedValued = rest.maxLength
      ? _value.slice(0, rest.maxLength)
      : _value;

    setValue(slicedValued);
  };

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }

    timeoutRef.current = setTimeout(() => onChange?.(value), timeoutDelay);
  }, [onChange, timeoutDelay, value]);

  useEffect(() => {
    if (ordensDeServicoNumeroOs === undefined) {
      setValue("");
    }
  }, [ordensDeServicoNumeroOs]);

  useEffect(() => {
    if (isDisabled) {
      onChange("");
      setValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled]);

  return (
    <InputGroup>
      <Input
        value={value}
        placeholder={placeholder}
        borderColor={gray500}
        rounded={24}
        size="md"
        {...rest}
        onChange={handleChange}
        isDisabled={isDisabled}
      />
      <InputRightElement>
        {value.length ? (
          <Icon
            data-testid="input-search-clear-button"
            as={IoIosClose}
            boxSize={8}
            color={gray500}
            ml={-4}
            cursor="pointer"
            onClick={() => setValue("")}
          />
        ) : (
          <Icon as={FaSearch} boxSize={4} ml={-4} color={gray500} />
        )}
      </InputRightElement>
    </InputGroup>
  );
};

export default InputSearch;
