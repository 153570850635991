import React, { useState } from "react";
import { Button, Flex, Image, Text, IconButton } from "@chakra-ui/react";
import LoadingBox from "components/layout/loading-box";
import RadioColor from "components/RadioColor";
import { useFilters } from "core/features/filters/store";
import { useFiltersLists } from "core/features/filters/store/lists";
import { EquipeType } from "core/features/maps/typings";
import ContainerFilter from "../../ContainerFilter";
import TableOrdensDeServicosAbertasPorEquipe from "./TableOrdensDeServicosAbertasPorEquipe";
import OpenFolderImg from "../../../../../../assets/images/open-folder.png";
import FolderImg from "../../../../../../assets/images/folder.png";
import NotFoundMapFilters from "components/NotFoundMapFilters";
import { FaChevronUp } from "react-icons/fa";
import MapGridButton from "components/MapGridButton";

interface FiltroDistribuicaoDeEquipesProps {
  isVisible: boolean;
  handleChangeIsVisible: (prop: boolean) => void;
  handleOpenGrid: () => void;
}

const FiltroDistribuicaoDeEquipes = ({
  handleChangeIsVisible,
  handleOpenGrid,
  isVisible,
}: FiltroDistribuicaoDeEquipesProps) => {
  const { filters, updateFiltroDistribuicaoDeEquipes } = useFilters();
  const { distribuicaoDeEquipes, loadings } = useFiltersLists();
  const [showModalTable, setShowModalTable] = useState<boolean>(false);
  const equipes = distribuicaoDeEquipes?.equipes;
  const actualEquipe = filters.filtroDistribuicaoDeEquipes?.equipe;
  const isLoading = loadings.distribuicaoDeEquipes;
  const isOrdensDeServicosButtonDisable =
    !actualEquipe || !equipes?.find((equipe) => equipe.id === actualEquipe.id);

  const selectEquipe = (equipe: EquipeType) => {
    if (equipe.id === actualEquipe?.id) return;
    updateFiltroDistribuicaoDeEquipes({
      equipe: {
        color: equipe.cor,
        name: equipe.nome,
        id: equipe.id,
      },
      setorId: filters.setor?.id,
    });
  };

  if (loadings.distribuicaoDeEquipes) {
    return <LoadingBox isVisible={isVisible} />;
  }

  if (!distribuicaoDeEquipes && !loadings.distribuicaoDeEquipes) {
    return (
      <ContainerFilter
        containerProps={{
          mt: 3,
          rounded: 8,
          padding: 4,
        }}
      >
        <Flex pl={1} mb={1}>
          <NotFoundMapFilters message="Não foram encontadras ocupações para o filtro selecionado" />
        </Flex>
      </ContainerFilter>
    );
  }

  return (
    <ContainerFilter
      containerProps={{
        mt: 3,
        rounded: 8,
        padding: 2,
        zIndex: 3,
      }}
      isVisible={isVisible}
    >
      {!isLoading &&
        equipes &&
        equipes.map((equipe) => {
          return (
            <RadioColor
              key={equipe.id}
              checked={equipe.id === actualEquipe?.id}
              color={equipe.cor}
              name={equipe.nome}
              onClick={() => selectEquipe(equipe)}
            />
          );
        })}

      {showModalTable && actualEquipe && (
        <TableOrdensDeServicosAbertasPorEquipe
          equipeName={actualEquipe.name}
          equipeId={actualEquipe.id}
          handleCloseModal={() => setShowModalTable(false)}
        />
      )}
      <Button
        onClick={() => setShowModalTable(true)}
        background={showModalTable ? "green.700" : "initial"}
        _hover={{}}
        border="1px solid #707070"
        borderRadius="15px"
        marginLeft="12px"
        marginTop="30px"
        disabled={isOrdensDeServicosButtonDisable}
      >
        <Image
          src={showModalTable ? OpenFolderImg : FolderImg}
          marginRight="8px"
          width={showModalTable ? "27px" : "30px"}
          hiehgt={showModalTable ? "18px" : "27px"}
        />
        <Text color={showModalTable ? "white" : "gray"}>
          Exibir Ordens Abertas
        </Text>
      </Button>
      <Flex
        padding="5px"
        justifyContent="space-between"
        borderTop="1px solid #84848440 "
        alignItems="center"
        paddingTop="15px"
        marginTop="10px"
      >
        <MapGridButton handleOpenGrid={handleOpenGrid} />

        <IconButton
          background="none"
          _hover={{}}
          _focus={{}}
          _active={{}}
          icon={<FaChevronUp color="#848484" />}
          aria-label="close-button"
          marginRight="20px"
          onClick={() => handleChangeIsVisible(false)}
        />
      </Flex>
    </ContainerFilter>
  );
};
export default FiltroDistribuicaoDeEquipes;
