import { MapTypeId } from "core/features/maps/enums";
import { LatLong } from "core/features/maps/typings";
import { TalhoesContext } from "core/features/talhoes/contexts/Talhoes.context";
import {
  GeoJsonProps,
  TalhoesProps,
} from "core/features/talhoes/typings/listaEGeojson";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

interface UseMapFilterProps {
  currentItem?: TalhoesProps;
  geojson?: GeoJsonProps;
  defaultPontoCentral: LatLong;
}

export const useMapFilter = ({
  currentItem,
  geojson,
  defaultPontoCentral,
}: UseMapFilterProps) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [canMove, setCanMove] = useState<boolean>(false);
  const [polygonPath, setPolygonPath] =
    useState<{ lat: number; lng: number }[]>();
  const [theresChanges, setTheresChanges] = useState<boolean>(false);
  const polygonRef = useRef<any>(null);
  const { pathsToSave, setImportKMLCode } = useContext(TalhoesContext);

  const currentItemGeojson = geojson?.features.find((item) => {
    return item.properties.id === currentItem?.talhaoId;
  });

  const onLoad = (mapToSet: google.maps.Map) => {
    setMap(mapToSet);
  };

  const onUnmountMap = (mapToSet: google.maps.Map) => {
    setMap(null);
  };

  const onUnmountPolygon = () => {
    polygonRef.current = null;
  };

  const onLoadPolygon = (polygon: any) => {
    polygonRef.current = polygon;
  };

  const onPolygonEdit = () => {
    if (polygonRef.current) {
      setImportKMLCode(false);
      setTheresChanges(true);
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng: any) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPolygonPath(nextPath);
    }
  };

  const handleCreatePolygon = (props: LatLong) => {
    setImportKMLCode(false);
    if (polygonPath && polygonPath?.length >= 4) return;
    setTheresChanges(true);
    if (polygonPath) {
      setPolygonPath([
        ...polygonPath,
        {
          lat: props.lat,
          lng: props.long,
        },
      ]);
    } else {
      setPolygonPath([
        {
          lat: props.lat,
          lng: props.long,
        },
      ]);
    }
  };

  const getPolygonCoordinates = useCallback(() => {
    let ret: { lat: number; lng: number }[] = [];
    currentItemGeojson?.geometry.coordinates[0].forEach((item: number[]) => {
      if (typeof item[0] === "object" && typeof item[1] === "object") {
        return item.forEach((items: any) => {
          ret.push({
            lat: items[1] as number,
            lng: items[0] as number,
          });
        });
      }

      ret.push({
        lat: item[1],
        lng: item[0],
      });
    });

    return ret;
  }, [currentItemGeojson]);

  useEffect(() => {
    if (map) {
      map.setMapTypeId(MapTypeId.SATELLITE);
      map.setZoom(11.5);
      map.setCenter({
        lat: defaultPontoCentral.lat,
        lng: defaultPontoCentral.long,
      });

      map.data.setStyle((feature) => {
        const id = feature.getProperty("id");
        const shouldRemove = id === currentItem?.talhaoId;

        if (shouldRemove) {
          return {
            opacity: 0,
            fillOpacity: 0,
            strokeOpacity: 0,
          };
        }

        const fill = feature.getProperty("fill");
        const fillOpacity = feature.getProperty("fillOpacity");
        const stroke = feature.getProperty("stroke");
        const strokeOpacity = feature.getProperty("strokeOpacity");
        const strokeWidth = feature.getProperty("strokeWidth");

        return {
          opacity: fillOpacity,
          fillColor: fill,
          fillOpacity,
          strokeColor: stroke,
          strokeOpacity,
          strokeWidth,
        };
      });

      if (geojson) {
        map.data.addGeoJson(geojson!);
      }
    }

    return () => {
      map?.data.forEach((item) => {
        map.data.remove(item);
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, geojson, currentItem]);

  useEffect(() => {
    setPolygonPath([...getPolygonCoordinates()]);
    setTheresChanges(false);
    map?.setZoom(14);
    if (currentItem && currentItemGeojson) {
      map?.setCenter({
        lat: currentItemGeojson.properties.pontoCentralLat!,
        lng: currentItemGeojson.properties.pontoCentralLong!,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem, currentItemGeojson, getPolygonCoordinates]);

  useEffect(() => {
    const shouldRemoveMark =
      geojson?.features.find((item) => item.id === currentItem?.codigo) ===
      undefined;

    if (shouldRemoveMark) {
      setPolygonPath(undefined);
    }
  }, [currentItem, geojson]);

  useEffect(() => {
    if (currentItem && pathsToSave) {
      map?.setCenter({
        lat: pathsToSave[0].lat,
        lng: pathsToSave[0].lng,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem, pathsToSave]);

  return {
    items: {
      polygonPath,
      canEdit,
      canMove,
      map,
      theresChanges,
    },
    onChange: {
      onPolygonEdit,
      changeCanEdit: setCanEdit,
      changeCanMove: setCanMove,
      handleCreatePolygon,
      setPolygonPath,
      setTheresChanges,
    },
    loaders: {
      onLoadPolygon,
      onLoad,
      onUnmountPolygon,
      onUnmountMap,
    },
  };
};
