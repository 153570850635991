import { datadogRum } from "@datadog/browser-rum";
import config from "infra/config";

datadogRum.init({
  applicationId: "d28732c3-cef0-4232-9c7b-dda18df62cfd",
  clientToken: "pub2d4765035b8f54774781ce5b8faeab7e",
  site: "datadoghq.com",
  service: "agrosig-web",
  env: config.env === "production" ? "prd" : "qas",
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();
