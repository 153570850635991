import styled from "styled-components";

interface BreadChildProps {
  isActual?: boolean;
}

export const MainBread = styled.div`
  width: 100%;
  max-width: 1110px;
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
`;

export const BreadChild = styled.a<BreadChildProps>`
  height: 2rem;
  width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  color: #525252;
  font-size: 18px;
  ${(props) => (props.isActual ? "font-weight: 600;" : "font-weight: 400;")}
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const Spare = styled.span`
  color: #525252;
  font-size: 18px;
  margin-left: 0.35rem;
  margin-right: 0.35rem;
`;
