import { DateRange } from "@material-ui/pickers";
import { useFilters } from "core/features/filters/store";
import { parseISO } from "date-fns";
import { AdvancedFiltersProps } from "../components/distribuicao-varietal";
import {
  getPeriodoColheita,
  getPeriodoEmergencia,
  getPeriodoPlantio,
  getPeriodoPrevisaoDeColheita,
} from "../utils/distribuicao-varietal-utils";

const useAdvancedFiltersDistribuicaoVarietal = () => {
  const { updateFiltroOcupacao, filters } = useFilters();
  const { filtroDistribuicaoVarietal } = filters;
  const filterPlantio = [
    filtroDistribuicaoVarietal?.periodoPlantio?.dataInicial
      ? (parseISO(
          filtroDistribuicaoVarietal?.periodoPlantio?.dataInicial
        ) as Date)
      : null,

    filtroDistribuicaoVarietal?.periodoPlantio?.dataFinal
      ? (parseISO(
          filtroDistribuicaoVarietal?.periodoPlantio?.dataFinal
        ) as Date)
      : null,
  ] as DateRange<Date>;

  const filterPrevisaoDeColheita = [
    filtroDistribuicaoVarietal?.periodoPrevisaoDeColheita?.dataInicial
      ? (parseISO(
          filtroDistribuicaoVarietal?.periodoPrevisaoDeColheita?.dataInicial
        ) as Date)
      : null,

    filtroDistribuicaoVarietal?.periodoPrevisaoDeColheita?.dataFinal
      ? (parseISO(
          filtroDistribuicaoVarietal?.periodoPrevisaoDeColheita?.dataFinal
        ) as Date)
      : null,
  ] as DateRange<Date>;

  const filterColheita = [
    filtroDistribuicaoVarietal?.periodoColheita?.dataInicial
      ? (parseISO(
          filtroDistribuicaoVarietal?.periodoColheita?.dataInicial
        ) as Date)
      : null,

    filtroDistribuicaoVarietal?.periodoColheita?.dataFinal
      ? (parseISO(
          filtroDistribuicaoVarietal?.periodoColheita?.dataFinal
        ) as Date)
      : null,
  ] as DateRange<Date>;

  const filterEmergencia = [
    filtroDistribuicaoVarietal?.periodoEmergencia?.dataInicial
      ? (parseISO(
          filtroDistribuicaoVarietal?.periodoEmergencia?.dataInicial
        ) as Date)
      : null,

    filtroDistribuicaoVarietal?.periodoEmergencia?.dataFinal
      ? (parseISO(
          filtroDistribuicaoVarietal?.periodoEmergencia?.dataFinal
        ) as Date)
      : null,
  ] as DateRange<Date>;

  const applyFilters = (
    advancedFilters: AdvancedFiltersProps,
    handleCloseModal: () => void
  ) => {
    const periodoPlantio = getPeriodoPlantio(advancedFilters);
    const periodoEmergencia = getPeriodoEmergencia(advancedFilters);
    const periodoColheita = getPeriodoColheita(advancedFilters);
    const periodoPrevisaoDeColheita =
      getPeriodoPrevisaoDeColheita(advancedFilters);

    updateFiltroOcupacao(
      {
        periodoPlantio,
        periodoEmergencia,
        periodoColheita,
        periodoPrevisaoDeColheita,
      },
      true
    );
    handleCloseModal();
  };

  const clearFilters = (
    handleResetFiltersState: (advancedFilters: AdvancedFiltersProps) => void
  ) => {
    updateFiltroOcupacao(
      {
        periodoPlantio: {},
        periodoEmergencia: {},
        periodoColheita: {},
        periodoPrevisaoDeColheita: {},
      },
      true
    );
    handleResetFiltersState({
      plantio: [null, null],
      emergencia: [null, null],
      colheita: [null, null],
      previsaoDeColheita: [null, null],
    });
  };

  return {
    items: {
      filterPlantio,
      filterPrevisaoDeColheita,
      filterColheita,
      filterEmergencia,
    },
    onChange: applyFilters,
    clearFilters,
  };
};

export default useAdvancedFiltersDistribuicaoVarietal;
