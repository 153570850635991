import { Button, Flex, Text } from "@chakra-ui/react";

import CheckboxFiltro from "components/inputs/checkbox-filtro";
import NotFoundMapFilters from "components/NotFoundMapFilters";
import useOrdensDeServicoAbertaOperacoesFilters from "../hooks/useOrdensDeServicoAbertaOperacoesFilters";

const OrdensDeServicoOperacoes = () => {
  const {
    items,
    selectOperacao,
    unSelectAllOperacoes,
    unSelectOperacao,
    value,
    onChange,
  } = useOrdensDeServicoAbertaOperacoesFilters();

  const isCheckedAll = value.length === items?.length;

  return (
    <Flex flexDirection={"column"} marginLeft="10px">
      {items.length ? (
        <Flex flexDirection="row" justifyContent="space-between">
          <CheckboxFiltro
            onClick={() => {
              onChange(isCheckedAll ? [] : items);
            }}
            text="Todos"
            isChecked={isCheckedAll}
          />
          <Button
            height="19px"
            background="none"
            _active={{ background: "none", border: "none" }}
            _hover={{ background: "none", border: "none" }}
            _focus={{ background: "none", border: "none" }}
            onClick={unSelectAllOperacoes}
          >
            <Text fontWeight="normal" color="gray.700" fontSize="14px">
              LIMPAR
            </Text>
          </Button>
        </Flex>
      ) : null}

      {items.map((operacao) => {
        const isOperacaoChecked = !!value.find(
          (op) => op.descricao === operacao.descricao
        );

        return (
          <CheckboxFiltro
            onClick={() =>
              isOperacaoChecked
                ? unSelectOperacao(operacao.descricao)
                : selectOperacao(operacao.descricao)
            }
            text={operacao.descricao}
            color={operacao.cor}
            isChecked={isOperacaoChecked}
            key={operacao.operacaoId}
          />
        );
      })}

      {items && !items?.length ? (
        <Flex>
          <NotFoundMapFilters message="Não foram encontradas ordens de serviço abertas para o filtro selecionado" />
        </Flex>
      ) : null}
    </Flex>
  );
};

export default OrdensDeServicoOperacoes;
