import { Flex, Box } from "@chakra-ui/react";
import { LatLong } from "features/maps/typings";
import { MdGpsFixed } from "react-icons/md";
import { FiShare2 } from "react-icons/fi";
import { useControls } from "../../hooks/useControls";
import { showError } from "infra/helpers/alerta";
import { useFilters } from "features/filters/store";
import { useSaveShare } from "features/share-map/hooks/useSaveShare";
import { useCallback, useState } from "react";
import { Spin } from "antd";
import CopyToClipboard from "@vigosan/react-copy-to-clipboard";
import { TipoMapaTalhoes } from "features/maps/typings";
import ControlItem from "./ControlItem";
import ModalCopy from "./ModalCopy";
import MenuTypeMap from "./MenuTypeMap";
import ControlZoom from "./ControlZoom";
import { exportFilters } from "features/filters/services/export-filters";
import { useMapaDeChuvaPeriodos } from "components/inputs/filter-safra-unidade-mapatematico/items/mapa-de-chuva/hooks/useMapaDeChuvaPeriodos";
import { getDateStringFromDate } from "../advancedFilters/items/utils/mapa-de-chuva.utils";
import { useMapaDeChuvaActualIndiceDates } from "components/inputs/filter-safra-unidade-mapatematico/items/mapa-de-chuva/hooks/MapaDeChuvaActualIndiceDates";
import ControlViewType from "./ControlViewType";
import { CurrentViewTypeEnum } from "../../typings";

function MapControl({
  map,
  center,
  currentViewType,
  handleChangeCurrentViewType,
}: {
  map: google.maps.Map;
  center: LatLong;
  currentViewType: CurrentViewTypeEnum;
  handleChangeCurrentViewType: (prop: CurrentViewTypeEnum) => void;
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [urlShared, setUrlShared] = useState<string | null>(null);
  const { value: mapaDeChuvaIndicesValues } = useMapaDeChuvaActualIndiceDates();

  const { resetNavigate } = useControls(map);
  const { filters } = useFilters();
  const { mutate: _handleSave, isLoading } = useSaveShare();

  const { tipoMapa } = filters;

  const { value, items } = useMapaDeChuvaPeriodos();

  const mapaDeChuvaFiltroRapidoFounded = useCallback(() => {
    return items.find((item) => item.codigo === value);
  }, [items, value]);

  const mapaDeChuvaPeriodoDataInicial = getDateStringFromDate(
    new Date(mapaDeChuvaFiltroRapidoFounded()?.dataInicial!)
  );
  const mapaDeChuvaPeriodoDataFinal = getDateStringFromDate(
    new Date(mapaDeChuvaFiltroRapidoFounded()?.dataFinal!)
  );

  const acutalPeriodoSelected = useCallback(
    () =>
      mapaDeChuvaFiltroRapidoFounded()
        ? {
            horaInicial: mapaDeChuvaIndicesValues.initialHour,
            horaFinal: mapaDeChuvaIndicesValues.endHour,
            dataInicial: mapaDeChuvaPeriodoDataInicial,
            dataFinal: mapaDeChuvaPeriodoDataFinal,
          }
        : {
            horaInicial: filters.filtroMapaDeChuva?.horaInicial!,
            horaFinal: filters.filtroMapaDeChuva?.horaFinal!,
            dataInicial: filters.filtroMapaDeChuva?.dataInicial,
            dataFinal: filters.filtroMapaDeChuva?.dataFinal,
          },
    [
      mapaDeChuvaFiltroRapidoFounded,
      mapaDeChuvaIndicesValues.initialHour,
      mapaDeChuvaIndicesValues.endHour,
      mapaDeChuvaPeriodoDataInicial,
      mapaDeChuvaPeriodoDataFinal,
      filters.filtroMapaDeChuva?.dataFinal,
      filters.filtroMapaDeChuva?.dataInicial,
      filters.filtroMapaDeChuva?.horaInicial,
      filters.filtroMapaDeChuva?.horaFinal,
    ]
  );

  const showShareButton = tipoMapa !== TipoMapaTalhoes.Satelite;

  const handleShare = useCallback(() => {
    if (isLoading) {
      return;
    }

    const data = exportFilters({
      mapaDeChuva: {
        dataInicial: acutalPeriodoSelected().dataInicial,
        dataFinal: acutalPeriodoSelected().dataFinal,
        horaFinal: acutalPeriodoSelected().horaFinal,
        horaInicial: acutalPeriodoSelected().horaInicial,
        indicesPluviometrico: filters.filtroMapaDeChuva?.indicesPluviometrico,
        periodo: 6,
      },
    });

    _handleSave(
      {
        data: data,
        feature: `${data.tipoMapa}`,
      },
      {
        onSuccess: (res) => {
          const url = `${window.location.origin}/s/${res.code}`;

          setUrlShared(url);

          setIsModalVisible(true);
        },
        onError: () => showError(),
      }
    );
  }, [
    _handleSave,
    isLoading,
    filters.filtroMapaDeChuva,
    acutalPeriodoSelected,
  ]);

  return (
    <CopyToClipboard
      render={({ copy }: any) => (
        <Flex
          pos="fixed"
          flexDir="column"
          gridGap="12px"
          right="16px"
          bottom="32px"
          zIndex={9}
        >
          {isModalVisible ? (
            <ModalCopy
              onClose={() => setIsModalVisible(false)}
              visible={isModalVisible}
              url={urlShared!}
              onOk={() => {
                copy(urlShared);
                setIsModalVisible(false);
              }}
            />
          ) : null}

          {showShareButton ? (
            <ControlItem
              testId="config-mapa-compartilhar"
              onClick={() => handleShare()}
            >
              {isLoading ? (
                <Box pt="4px">
                  <Spin />
                </Box>
              ) : (
                <FiShare2 fontSize="18px" />
              )}
            </ControlItem>
          ) : null}

          <ControlViewType
            currentType={currentViewType}
            handleChange={handleChangeCurrentViewType}
          />

          <ControlItem
            testId="config-mapa-reseta-posicao"
            onClick={() => resetNavigate(center)}
          >
            <MdGpsFixed fontSize="18px" />
          </ControlItem>

          <ControlZoom map={map} />

          <ControlItem>
            <MenuTypeMap map={map} />
          </ControlItem>
        </Flex>
      )}
    />
  );
}

export default MapControl;
