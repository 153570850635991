import { useFiltersLists } from "features/filters/store/lists";
import { OcupacaoEnum } from "features/maps/enums";
import { useConcentracaoColheitaStore } from "features/filters/store/useConcentracaoColheitaStore";
import { useEffect } from "react";

function useConcentracaoColheitaOcupacoesFilters() {
  const {
    filters: { ocupacao },
    update,
    updateField,
  } = useConcentracaoColheitaStore();

  const { concentracaoColheita } = useFiltersLists();

  const onChange = (ocupacaoId: OcupacaoEnum) => {
    const findOcupacao = concentracaoColheita?.ocupacoes.find(
      (item) => item.codigo === ocupacaoId
    );
    const findFirstTecnologiaEnabled = findOcupacao?.tecnologias.find(
      (item) => item.id
    );

    update({
      ocupacao: findOcupacao?.codigo,
      tecnologias: findFirstTecnologiaEnabled
        ? [findFirstTecnologiaEnabled?.id as string]
        : [],
      concentracoes: [],
    });
  };

  useEffect(() => {
    // Pega a ocuparacao
    let findOcupacao = concentracaoColheita?.ocupacoes.find(
      (item) => item.codigo === ocupacao && item.enabled
    );

    // Caso ela esteja disabilitada entao pega a primeira habilitada
    if (!findOcupacao) {
      findOcupacao = concentracaoColheita?.ocupacoes.find(
        (item) => item.enabled
      );
    }

    updateField("ocupacao", findOcupacao?.codigo);
  }, [ocupacao, concentracaoColheita?.ocupacoes, updateField]);

  return {
    value: ocupacao,
    items: concentracaoColheita?.ocupacoes ?? [],
    onChange,
  };
}

export default useConcentracaoColheitaOcupacoesFilters;
