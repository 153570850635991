import { StatusInsumosEnum } from "features/maps/typings/aplicacoes";
import { InsumosTalhao } from "features/maps/hooks/useTalhoesInsumosGrupos";
import { AiOutlineBarChart } from "react-icons/ai";
import { ActionButton } from "./ActionButton";

interface ActionButtonProps {
  selectedStatus: StatusInsumosEnum;
  onChangeStatus: (status: StatusInsumosEnum) => void;
  insumos: InsumosTalhao[];
}

export const ActionButtonsAplicacoes = ({
  selectedStatus,
  onChangeStatus,
  insumos,
}: ActionButtonProps) => {
  const isHerbicidaEnabled = insumos?.find(
    (item) => item.codigo === StatusInsumosEnum.Herbicidas
  );
  const isInseticidasEnabled = insumos?.find(
    (item) => item.codigo === StatusInsumosEnum.Inseticidas
  );
  const isFungicidasEnabled = insumos?.find(
    (item) => item.codigo === StatusInsumosEnum.Fungicidas
  );
  const isReguladoresEnabled = insumos?.find(
    (item) => item.codigo === StatusInsumosEnum.Reguladores
  );
  const isFertilizantesEnabled = insumos?.find(
    (item) => item.codigo === StatusInsumosEnum.Fertilizantes
  );

  return (
    <>
      <ActionButton
        testID="aplicacoes-status-herbicidas"
        disabled={!isHerbicidaEnabled}
        active={selectedStatus === StatusInsumosEnum.Herbicidas}
        onClick={() => onChangeStatus(StatusInsumosEnum.Herbicidas)}
        title="Herbicidas"
      />

      <ActionButton
        testID="aplicacoes-status-inseticidas"
        disabled={!isInseticidasEnabled}
        active={selectedStatus === StatusInsumosEnum.Inseticidas}
        onClick={() => onChangeStatus(StatusInsumosEnum.Inseticidas)}
        title="Inseticidas"
      />

      <ActionButton
        testID="aplicacoes-status-fungicidas"
        disabled={!isFungicidasEnabled}
        active={selectedStatus === StatusInsumosEnum.Fungicidas}
        onClick={() => onChangeStatus(StatusInsumosEnum.Fungicidas)}
        title="Fungicidas"
      />

      <ActionButton
        testID="aplicacoes-status-reguladores"
        disabled={!isReguladoresEnabled}
        active={selectedStatus === StatusInsumosEnum.Reguladores}
        onClick={() => onChangeStatus(StatusInsumosEnum.Reguladores)}
        title="Reguladores"
      />

      <ActionButton
        testID="aplicacoes-button-fertilizantes"
        disabled={!isFertilizantesEnabled}
        active={selectedStatus === StatusInsumosEnum.Fertilizantes}
        onClick={() => onChangeStatus(StatusInsumosEnum.Fertilizantes)}
        title="Fertilizantes"
      />
      <ActionButton
        testID="aplicacoes-button-custos"
        active={selectedStatus === StatusInsumosEnum.Indicadores}
        onClick={() => onChangeStatus(StatusInsumosEnum.Indicadores)}
        title="Custos"
        icon={<AiOutlineBarChart fontSize={24} />}
      />
    </>
  );
};
