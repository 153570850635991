import { Flex, IconButton } from "@chakra-ui/react";
import ContainerFilter from "../../ContainerFilter";

import LoadingBox from "components/layout/loading-box";
import useOrdensDeServicoAbertaFilters from "./hooks/useOrdensDeServicoAbertaFilters";
import OrdensDeServicoOperacoes from "./components/OrdensDeServicoOperacoes";
import FiltroOrdensDeServicoPeriodos from "./components/OrdensDeServicoPeriodos";
import { FaChevronUp } from "react-icons/fa";
import MapGridButton from "components/MapGridButton";

interface FiltroOrdensDeServicoAbertaProps {
  isVisible: boolean;
  handleChangeIsVisible: (prop: boolean) => void;
  handleOpenGrid: () => void;
}

const FiltroOrdensDeServicoAberta = ({
  isVisible,
  handleOpenGrid,
  handleChangeIsVisible,
}: FiltroOrdensDeServicoAbertaProps) => {
  const { isLoading } = useOrdensDeServicoAbertaFilters();

  if (isLoading) {
    return <LoadingBox isVisible={isVisible} />;
  }

  return (
    <ContainerFilter
      containerProps={{
        mt: 3,
        rounded: 8,
        padding: 4,
      }}
      isVisible={isVisible}
    >
      <Flex mb={6}>
        <FiltroOrdensDeServicoPeriodos />
      </Flex>
      <OrdensDeServicoOperacoes />
      <Flex
        padding="5px"
        flexDirection="row-reverse"
        borderTop="1px solid #84848440 "
        marginTop="10px"
      >
        <IconButton
          background="none"
          _hover={{}}
          _focus={{}}
          _active={{}}
          icon={<FaChevronUp color="#848484" />}
          aria-label="close-button"
          marginRight="20px"
          onClick={() => handleChangeIsVisible(false)}
        />
      </Flex>
      <Flex
        padding="5px"
        justifyContent="space-between"
        borderTop="1px solid #84848440 "
        alignItems="center"
        paddingTop="15px"
        marginTop="10px"
      >
        <MapGridButton handleOpenGrid={handleOpenGrid} />

        <IconButton
          background="none"
          _hover={{}}
          _focus={{}}
          _active={{}}
          icon={<FaChevronUp color="#848484" />}
          aria-label="close-button"
          marginRight="20px"
          onClick={() => handleChangeIsVisible(false)}
        />
      </Flex>
    </ContainerFilter>
  );
};

export default FiltroOrdensDeServicoAberta;
