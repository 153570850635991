export const currentCulturaMock = [
  { nome: "Soja", id: "01" },
  { nome: "Milho", id: "02" },
  { nome: "Algodão", id: "03" },
];

export const currentTalhaoMock = [
  { nome: "01", id: "01" },
  { nome: "02", id: "02" },
  { nome: "03", id: "03" },
  { nome: "04", id: "04" },
  { nome: "05", id: "05" },
  { nome: "06", id: "06" },
  { nome: "07", id: "07" },
  { nome: "08", id: "08" },
];
