import { Tag } from "@chakra-ui/react";
import { Setor } from "features/unidades/typings";
import { primary } from "core/resources/theme/colors";

function SetorTag({
  value,
  item,
  onClick,
}: {
  value: Setor | null;
  item: Setor;
  onClick: () => void;
}) {
  return (
    <Tag
      onClick={() => onClick()}
      bg={value?.id === item.id ? primary : "white"}
      color={value?.id === item.id ? "white" : undefined}
      cursor="pointer"
      fontWeight="normal"
      rounded="full"
      size="lg"
      fontSize="14px"
      borderWidth={1}
      borderColor={value?.id === item.id ? primary : "gray.500"}
    >
      {item.nome}
    </Tag>
  );
}

export default SetorTag;
