export const green = "#009540";
export const green600 = "#009742";
export const green700 = "#11A557";
export const green800 = "#0B954C";

export const primary = green;

export const black62 = "#00000062";

export const blue = "#0569A8";
export const blue800 = "#0569A8";
export const blue600 = "#298CD8";
export const blue400 = "#719EFF";
export const blue100 = "#E9EEFA";

export const gray800 = "#525252";
export const gray700 = "#697B98";
export const gray400 = "#BFC8D6";
export const gray300 = "#D8DEE8";
export const gray350 = "#BFC4CE";
export const gray100 = "#F2F4F7";
export const gray50 = "#EFF0F9";
export const gray150 = "#EFEFEF";
export const gray900 = "#2C2C2C";

export const gray500 = "#707070";
export const gray550 = "#ADB3C0";
export const gray250 = "#99A0B0";

export const red100 = "#BE0000";

export const black50 = "#e2e8f0";
export const black = "#000000";

export const yellow = "#E2CC78";
export const yellow400 = "#FFC850";

export const whiteAlpha = "rgba(255, 255, 255, .07)";
export const white = "#ffffff";
