/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  Button,
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Input,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFiltersLists } from "core/features/filters/store/lists";
import { Safra, Unidade } from "core/features/maps/typings";
import { Setor } from "core/features/unidades/typings";
import { useSetores } from "core/features/unidades/hooks/useSetores";
import { green700 } from "core/resources/theme/colors";
import { BiChevronDown } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import { Cultura, Talhao } from "../../types/tags";
import SojaGraySVG from "assets/images/icons/ocupacoes/soja-gray.svg";
import MilhoGraySVG from "assets/images/icons/ocupacoes/milho-gray.svg";
import AlgodaoGraySVG from "assets/images/icons/ocupacoes/algodao-gray.svg";
import {
  currentCulturaMock,
  currentTalhaoMock,
} from "../ModalNewFolder/mocks/tags";
import { ArquivoResponse } from "ui/pages/app/arquivos/agrodrive/types/arquivos";
import { CustomTag } from "ui/pages/app/arquivos/agrodrive/types/tags";
import {
  MainTile,
  TileTitle,
  TileInput,
  TagArea,
  TagCloud,
  Tag,
  TagEmptyState,
} from "./styles";

interface AgrodriveFiltersTagsProps {
  setSelected?: React.Dispatch<React.SetStateAction<string>>;
  items: {
    currentSafras: Safra[];
    currentUnidades: Unidade[];
    currentCultura: Cultura[];
    currentSetores: Setor[];
    currentTalhao: Talhao[];
    tagsPersonalizadas: CustomTag[];
    tagsVinculadas?: string[];
    tree?: ArquivoResponse[];
    customTagsData?: CustomTag[];
  };
  onChange: {
    setCurrentSafras: (prop: Safra[]) => void;
    setCurrentUnidades: (prop: Unidade[]) => void;
    setCurrentCultura: (prop: Cultura[]) => void;
    setCurrentSetores: (prop: Setor[]) => void;
    setCurrentTalhao: (prop: Talhao[]) => void;
    setTagsPersonalizadas: (prop: CustomTag[]) => void;
    setTagsVinculadas: (prop: string[]) => void;
  };
  disableSearch?: boolean;
}
export const AgrodriveFiltersTags = ({
  setSelected,
  items: {
    currentSafras,
    currentUnidades,
    currentCultura,
    currentSetores,
    currentTalhao,
    tagsPersonalizadas,
    tree,
    customTagsData,
  },
  onChange: {
    setCurrentSafras,
    setCurrentUnidades,
    setCurrentCultura,
    setCurrentSetores,
    setCurrentTalhao,
    setTagsPersonalizadas,
  },
  disableSearch,
}: AgrodriveFiltersTagsProps) => {
  const { safras, unidades } = useFiltersLists();
  const [customTagsList, setCustomTagsList] = useState<CustomTag[] | undefined>(
    []
  );
  const [filterTagInput, setFilterTagInput] = useState<string>("");
  const { data } = useSetores(
    currentUnidades[0]?.id!,
    currentSafras[0]?.id!,
    currentSafras[0]?.id! && currentUnidades[0]?.id! ? true : false
  );

  const CulturasMock = currentCulturaMock;
  const TalhoesMock = currentTalhaoMock;

  const resetSelectedTags = () => {
    setCurrentSafras([]);
    setCurrentUnidades([]);
    setCurrentCultura([]);
    setCurrentSetores([]);
    setCurrentTalhao([]);
    setTagsPersonalizadas([]);
  };
  // useEffect(() => {
  // resetSelectedTags();
  // }, [tree]);

  useEffect(() => {
    setCustomTagsList(customTagsData);
  }, [customTagsData]);

  const filterCustomTags = (tagName: string) => {
    setFilterTagInput(tagName);
    if (customTagsData) {
      if (tagName) {
        const result = customTagsData.filter((tag: CustomTag) => {
          return tag.nome.indexOf(tagName.trim()) !== -1;
        });
        setCustomTagsList(result);
      } else {
        setCustomTagsList(customTagsData);
      }
    }
  };

  const defineSelectedOtherTags = (tagNameOfList: string) => {
    const result = tagsPersonalizadas.filter(
      (element) => element.nome === tagNameOfList
    );

    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
    return false;
  };

  const focusInputStyles = {
    borderColor: "#707070",
  };
  return (
    <Flex flexDirection="row" gridGap="2">
      {/* //!FAZENDAS */}
      <Menu flip>
        <MenuButton
          as={Button}
          alignItems="center"
          border="1px solid"
          borderColor="gray"
          borderRadius="md"
          background={currentUnidades.length ? green700 : "white"}
          color={currentUnidades.length ? "white" : "gray"}
          _hover={{ opacity: 0.75 }}
          _active={{}}
          _focus={{}}
        >
          <Flex alignItems="center" gridGap="5px">
            {currentUnidades.length > 1
              ? `${currentUnidades[0].nome}, +${currentUnidades.length - 1}`
              : currentUnidades.length
              ? currentUnidades[0]?.nome
              : "Unidades"}
            {currentUnidades.length ? (
              <IoMdClose
                onClick={() => setCurrentUnidades([])}
                style={{ pointerEvents: "all" }}
              />
            ) : (
              <BiChevronDown />
            )}
          </Flex>
        </MenuButton>
        <MenuList overflowY="scroll" maxHeight="300px">
          <MenuOptionGroup title="Selecione uma unidade" type="checkbox">
            {unidades.map((unidade) => {
              return (
                <MenuItem
                  key={unidade.id}
                  value={unidade.nome}
                  bg={
                    currentUnidades.find((item) => item === unidade)
                      ? "#11A55710 0% 0% no-repeat padding-box"
                      : ""
                  }
                  isChecked={!!currentUnidades.find((item) => item === unidade)}
                  onClick={() => {
                    if (!!currentUnidades.find((item) => item === unidade)) {
                      const newUnidades = currentUnidades.filter(
                        (item) => item.id !== unidade.id
                      );

                      return setCurrentUnidades(newUnidades);
                    }
                    setCurrentUnidades([...currentUnidades, unidade]);
                  }}
                >
                  <Flex w="100%" justifyContent="space-between">
                    {/* //VOLTARAQUI */}
                    {unidade.nome}
                    {currentUnidades.find((item) => item === unidade) ? (
                      <FaCheck color="#10A456" />
                    ) : undefined}
                  </Flex>
                </MenuItem>
              );
            })}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
      {/* //!SAFRAS */}
      <Menu flip>
        <MenuButton
          as={Button}
          alignItems="center"
          border="1px solid"
          borderColor="gray"
          borderRadius="md"
          background={currentSafras.length ? green700 : "white"}
          color={currentSafras.length ? "white" : "gray"}
          _hover={{ opacity: 0.75 }}
          _active={{}}
          _focus={{}}
        >
          <Flex alignItems="center" gridGap="5px">
            {currentSafras.length > 1
              ? `${currentSafras[0].descricao}, +${currentSafras.length - 1}`
              : currentSafras.length
              ? currentSafras[0]?.descricao
              : "Safras"}
            {currentSafras.length ? (
              <IoMdClose
                onClick={() => setCurrentSafras([])}
                style={{ pointerEvents: "all" }}
              />
            ) : (
              <BiChevronDown />
            )}
          </Flex>
        </MenuButton>
        <MenuList overflowY="scroll" maxHeight="300px">
          <MenuOptionGroup title="Selecione uma ou mais safras" type="checkbox">
            {safras.map((safra) => {
              return (
                <MenuItem
                  key={safra.id}
                  value={safra.descricao}
                  bg={
                    currentSafras.find((item) => item === safra)
                      ? "#11A55710 0% 0% no-repeat padding-box"
                      : ""
                  }
                  isChecked={!!currentSafras.find((item) => item === safra)}
                  onClick={() => {
                    if (!!currentSafras.find((item) => item === safra)) {
                      const newSafras = currentSafras.filter(
                        (item) => item.id !== safra.id
                      );
                      return setCurrentSafras(newSafras);
                    }
                    console.log("Safra selected", safra);
                    setCurrentSafras([...currentSafras, safra]);
                  }}
                >
                  <Flex w="100%" justifyContent="space-between">
                    {safra.descricao}
                    {currentSafras.find((item) => item === safra) ? (
                      <FaCheck color="#10A456" />
                    ) : undefined}
                  </Flex>
                </MenuItem>
              );
            })}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
      {/* //!CULTURA */}
      <Menu flip>
        <MenuButton
          as={Button}
          alignItems="center"
          border="1px solid"
          borderColor="gray"
          borderRadius="md"
          background={currentCultura.length ? green700 : "white"}
          color={currentCultura.length ? "white" : "gray"}
          _hover={{ opacity: 0.75 }}
          _active={{}}
          _focus={{}}
        >
          <Flex alignItems="center" gridGap="5px">
            {currentCultura.length > 1
              ? `${currentCultura[0].nome}, +${currentCultura.length - 1}`
              : currentCultura.length
              ? currentCultura[0]?.nome
              : "Culturas"}
            {currentCultura?.length ? (
              <IoMdClose
                onClick={() => setCurrentCultura([])}
                style={{ pointerEvents: "all" }}
              />
            ) : (
              <BiChevronDown />
            )}
          </Flex>
        </MenuButton>
        <MenuList overflowY="scroll" maxHeight="300px">
          <MenuOptionGroup title="Selecione uma cultura" type="checkbox">
            {CulturasMock.map((cultura) => {
              return (
                <MenuItem
                  key={cultura.id}
                  value={cultura.nome}
                  bg={
                    currentCultura?.find((item) => item === cultura)
                      ? "#11A55710 0% 0% no-repeat padding-box"
                      : ""
                  }
                  isChecked={!!currentCultura.find((item) => item === cultura)} //false
                  onClick={() => {
                    if (!!currentCultura.find((item) => item === cultura)) {
                      const newCulturas = currentCultura.filter(
                        (item) => item.id !== cultura.id
                      );
                      return setCurrentCultura(newCulturas);
                    }
                    setCurrentCultura([...currentCultura, cultura]);
                  }}
                >
                  <Flex w="100%" justifyContent="flex-start">
                    {cultura.nome === "Soja" && (
                      <Image mr="3" w="12px" src={SojaGraySVG} />
                    )}
                    {cultura.nome === "Milho" && (
                      <Image mr="3" w="12px" src={MilhoGraySVG} />
                    )}
                    {cultura.nome === "Algodão" && (
                      <Image mr="3" w="13px" src={AlgodaoGraySVG} />
                    )}
                    {cultura.nome}
                    <Flex w="100%" justifyContent="flex-end">
                      {currentCultura.find((item) => item === cultura) && (
                        <FaCheck color="#10A456" />
                      )}
                    </Flex>
                  </Flex>
                </MenuItem>
              );
            })}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
      {/* //!SETOR */}
      <Menu flip>
        <MenuButton
          as={Button}
          border="1px solid"
          borderColor="gray"
          borderRadius="md"
          background={currentSetores.length ? green700 : "white"}
          color={currentSetores.length ? "white" : "gray"}
          _hover={{ opacity: 0.75 }}
          _active={{}}
          _focus={{}}
        >
          <Flex alignItems="center" gridGap="5px">
            {currentSetores.length > 1
              ? `${currentSetores[0].nome}, +${currentSetores.length - 1}`
              : currentSetores.length
              ? currentSetores[0]?.nome
              : "Setores"}
            {currentSetores.length ? (
              <IoMdClose
                onClick={() => setCurrentSetores([])}
                style={{ pointerEvents: "all" }}
              />
            ) : (
              <BiChevronDown />
            )}
          </Flex>
        </MenuButton>
        <MenuList overflowY="scroll" maxHeight="300px">
          <MenuOptionGroup
            title={
              currentSafras.length && currentUnidades.length && data?.length
                ? "Selecione um setor"
                : currentSafras.length &&
                  currentUnidades.length &&
                  !data?.length
                ? "Não foi encontrado nenhum setor para esse filtro"
                : "Para selecionar um setor\nselecione primeiro uma safra\ne uma unidade"
            }
            type="checkbox"
          >
            {data?.map((setor) => (
              <MenuItem
                key={setor.id}
                value={setor.nome}
                bg={
                  currentSetores.find((item) => item === setor)
                    ? "#11A55710 0% 0% no-repeat padding-box"
                    : ""
                }
                isChecked={!!currentSetores.find((item) => item === setor)}
                onClick={() => {
                  if (!!currentSetores.find((item) => item === setor)) {
                    const newSetores = currentSetores.filter(
                      (item) => item.id !== setor.id
                    );
                    return setCurrentSetores(newSetores);
                  }
                  setCurrentSetores([...currentSetores, setor]);
                }}
              >
                <Flex w="100%" justifyContent="space-between">
                  {setor.nome}
                  {currentSetores.find((item) => item === setor) ? (
                    <FaCheck color="#10A456" />
                  ) : undefined}
                </Flex>
              </MenuItem>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
      {/* //!TALHAO */}
      <Menu flip>
        <MenuButton
          as={Button}
          border="1px solid"
          borderColor="gray"
          borderRadius="md"
          background={currentTalhao.length ? green700 : "white"}
          color={currentTalhao.length ? "white" : "gray"}
          _hover={{ opacity: 0.75 }}
          _active={{}}
          _focus={{}}
        >
          <Flex alignItems="center" gridGap="5px">
            {currentTalhao.length > 1
              ? `${currentTalhao[0].nome}, +${currentTalhao.length - 1}`
              : currentTalhao.length
              ? currentTalhao[0]?.nome
              : "Talhões"}
            {currentTalhao.length ? (
              <IoMdClose
                onClick={() => setCurrentTalhao([])}
                style={{ pointerEvents: "all" }}
              />
            ) : (
              <BiChevronDown />
            )}
          </Flex>
        </MenuButton>
        <MenuList overflowY="scroll" maxHeight="300px">
          <MenuOptionGroup title="Selecione um ou mais talhões" type="checkbox">
            {TalhoesMock?.map((talhao) => (
              <MenuItem
                key={talhao.id}
                value={talhao.nome}
                bg={
                  currentTalhao?.find((item) => item === talhao)
                    ? "#11A55710 0% 0% no-repeat padding-box"
                    : ""
                }
                isChecked={!!currentTalhao.find((item) => item === talhao)}
                onClick={() => {
                  if (!!currentTalhao.find((item) => item === talhao)) {
                    const newTalhoes = currentTalhao.filter(
                      (item) => item.id !== talhao.id
                    );
                    return setCurrentTalhao(newTalhoes);
                  }
                  setCurrentTalhao([...currentTalhao, talhao]);
                }}
              >
                <Flex w="100%" justifyContent="space-between">
                  {talhao.nome}
                  {currentTalhao.find((item) => item === talhao) && (
                    <FaCheck color="#10A456" />
                  )}
                </Flex>
              </MenuItem>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
      {/* //!OUTROS */}
      <Menu flip>
        <MenuButton
          as={Button}
          alignItems="center"
          border="1px solid"
          borderColor="gray"
          borderRadius="md"
          background={tagsPersonalizadas.length ? green700 : "white"}
          color={tagsPersonalizadas.length ? "white" : "gray"}
          _hover={{ opacity: 0.75 }}
          _active={{}}
          _focus={{}}
        >
          <Flex alignItems="center" gridGap="5px">
            {tagsPersonalizadas.length > 1
              ? `${tagsPersonalizadas[0].nome}, +${
                  tagsPersonalizadas.length - 1
                }`
              : tagsPersonalizadas.length
              ? tagsPersonalizadas[0]?.nome
              : "Outros"}
            {tagsPersonalizadas.length ? (
              <IoMdClose
                onClick={() => setTagsPersonalizadas([])}
                style={{ pointerEvents: "all" }}
              />
            ) : (
              <BiChevronDown />
            )}
          </Flex>
        </MenuButton>
        <MenuList overflowY="scroll" maxHeight="300px">
          <MenuOptionGroup title="" type={undefined}>
            <Flex w="100%" justifyContent="space-between">
              <MainTile>
                <TileTitle>Tags do usuário</TileTitle>
                <TileInput>
                  <Input
                    height="29px"
                    color="gray.800"
                    placeholder={"Pesquise uma tag"}
                    _focus={focusInputStyles}
                    value={filterTagInput}
                    onChange={({ target }) => filterCustomTags(target.value)}
                  />
                </TileInput>
                <TagArea>
                  {customTagsList && customTagsList.length > 0 ? (
                    <TagCloud>
                      {customTagsList
                        .sort((a: CustomTag, b: CustomTag) => {
                          if (a.quantidade > b.quantidade) {
                            return -1;
                          }
                          if (a.quantidade < b.quantidade) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((tag: CustomTag, i: number) => (
                          <>
                            <Tag
                              onClick={(i) => {
                                // setTagsPersonalizadas([item.nome]);
                                if (
                                  !!tagsPersonalizadas.find(
                                    (item) => item === tag
                                  )
                                ) {
                                  const newTags = tagsPersonalizadas.filter(
                                    (item) => item.nome !== tag.nome
                                  );
                                  return setTagsPersonalizadas(newTags);
                                }
                                setTagsPersonalizadas([
                                  ...tagsPersonalizadas,
                                  tag,
                                ]);
                              }}
                              selected={defineSelectedOtherTags(tag.nome)}
                            >
                              {tag.nome}
                            </Tag>
                          </>
                        ))}
                    </TagCloud>
                  ) : (
                    <TagEmptyState>
                      {!disableSearch ? (
                        <Tag
                          onClick={(item) => {
                            setTagsPersonalizadas([
                              ...tagsPersonalizadas,
                              {
                                nome: filterTagInput,
                                quantidade: 1,
                              },
                            ]);
                          }}
                          selected={true}
                        >
                          {`Adicionar tag ${filterTagInput}`}
                        </Tag>
                      ) : (
                        <p>Nenhum resultado</p>
                      )}
                    </TagEmptyState>
                  )}
                </TagArea>
              </MainTile>
            </Flex>
          </MenuOptionGroup>
        </MenuList>
      </Menu>

      <Button
        variant="ghost"
        fontWeight={"medium"}
        fontSize={15}
        color="gray.700"
        data-testid="limpar-filtros-tags"
        disabled={
          !currentSafras.length &&
          !currentUnidades.length &&
          !currentCultura.length &&
          !currentSetores.length &&
          !currentTalhao.length &&
          !tagsPersonalizadas.length
        }
        onClick={() => {
          currentSafras ? setCurrentSafras([]) : undefined;
          currentUnidades ? setCurrentUnidades([]) : undefined;
          currentCultura ? setCurrentCultura([]) : undefined;
          currentSetores ? setCurrentSetores([]) : undefined;
          currentTalhao ? setCurrentTalhao([]) : undefined;
          tagsPersonalizadas ? setTagsPersonalizadas([]) : undefined;
        }}
      >
        Limpar
      </Button>
    </Flex>
  );
};
