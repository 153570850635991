import { Flex, FlexProps } from "@chakra-ui/layout";

function FullWrapper({ children, ...rest }: FlexProps) {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      pos="fixed"
      top={0}
      bottom={0}
      right={0}
      left={0}
      {...rest}
    >
      {children}
    </Flex>
  );
}

export default FullWrapper;
