import URL from "core/resources/urls";
import http from "infra/http";

interface SalvarTalhaoEditadoProps {
  talhaoId: string;
  path: {
    lat: number;
    lng: number;
  }[];
}

export const salvarTalhaoEditado = async ({
  talhaoId,
  path,
}: SalvarTalhaoEditadoProps) => {
  const { data } = await http.put(
    URL.EDITAR_TALHOES.SALVAR_TALHOES_EDITADOS(talhaoId),
    {
      points: path,
    }
  );

  return data;
};
