import OcupacoesButtons from "../../../OcupacoesButtons";
import useProdutividadeOcupacaoFilters from "../hooks/useProdutividadeOcupacaoFilters";

function ProdutividadeOcupacaoFilter() {
  const { value, items, selectOcupacao } = useProdutividadeOcupacaoFilters();

  return (
    <OcupacoesButtons onChange={selectOcupacao} value={value!} items={items} />
  );
}

export default ProdutividadeOcupacaoFilter;
