import {
  Divider,
  Flex,
  Image,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Text,
  useToast,
  Input,
  Button,
} from "@chakra-ui/react";
import animationLoading from "assets/lotties/uploading.json";
import { green700 } from "core/resources/theme/colors";
import BreadcrumbAgrodrive from "../../../agrodrive/components/Breadcrumb";
import { sortFiles } from "../../../agrodrive/components/AgroTable/utils";
import SimpleTable from "../../../agrodrive/components/SimpleTable";
import { useContext, useEffect, useState } from "react";
import GenericFileIcon from "assets/images/icons/agrodrive/genericFileIcon.svg";
import useMoveDir from "../../hooks/useMoveDir";
import { AgrodriveContext } from "core/features/agrodrive/contexts/Agrodrive.context";
import { LoadingTile } from "./styles";
import Lottie from "react-lottie";
import {
  ArquivoResponse,
  DiretorioData,
} from "ui/pages/app/arquivos/agrodrive/types/arquivos";
import useGetDiretorios from "ui/pages/app/arquivos/agrodrive/hooks/useGetDiretorios";
import { showError, showSuccess } from "infra/helpers/alerta";

interface ModalMoverProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  item: any;
}

export default function ModalMover({ isOpen, onClose, item }: ModalMoverProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedNewDir, setSelectedNewDir] = useState<string>("");
  const [fullPath, setFullPath] = useState<string>("/");
  const [tree, setTree] = useState<ArquivoResponse[]>([]);
  const [, setCurrentFolder] = useState<ArquivoResponse | DiretorioData>();

  const toast = useToast();

  const {
    onChange: { refetchDiretorioList: refetchMainDiretorioList },
  } = useContext(AgrodriveContext);

  const changeDir = (selectedItem: any) => {
    if (selectedItem.tipo === "Folder") {
      setTree([...tree, selectedItem]);
    }
  };

  const { mutate: handleMoveItem } = useMoveDir();

  const handleChangeDir = () => {
    setLoading(true);
    handleMoveItem(
      {
        nome: item.nome,
        novoNome: item.nome,
        localizacao: item.localizacao ? item.localizacao : "/",
        novaLocalizacao: fullPath,
        tags: item.tags,
      },
      {
        onError: () => {
          showError();
          onClose();
          setLoading(false);
        },
        onSuccess: () => {
          setLoading(false);
          refetchMainDiretorioList();
          onClose();
          showSuccess();
        },
      }
    );
  };

  const getFullPath = () => {
    let completePath = "/";
    // eslint-disable-next-line array-callback-return
    tree.map((item: ArquivoResponse) => {
      completePath = completePath + `${item.nome}/`;
    });

    const completePathFormatted = completePath.substring(
      0,
      completePath.length - 1
    );

    if (completePath !== "/") {
      setFullPath(`${completePathFormatted.replace("/", "")}`);
    }
  };

  const {
    data: diretorioData,
    refetch: refetchDiretorioList,
    isFetching,
    isLoading,
  } = useGetDiretorios(fullPath, 1, 200, "");

  useEffect(() => {
    getFullPath();
  }, [tree]);

  useEffect(() => {
    refetchDiretorioList();
  }, [fullPath]);

  return (
    <Modal
      size="4xl"
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex display="flex" flexdir="row" mb="4">
            <Image src={GenericFileIcon} />
            <Text ml="4" mt="1" color="#6B6C7E">
              Mover item
            </Text>
          </Flex>
          <Divider />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack mb="2">
            <Text fontWeight={700} fontSize="16px">
              Selecione novo local
            </Text>
            <Text marginLeft={"2"} color="red.600" size="16px">
              *
            </Text>
          </HStack>
          <BreadcrumbAgrodrive
            items={tree}
            onChange={(v) => {
              setTree(v);
              setCurrentFolder(v.length ? v[v.length - 1] : undefined);
            }}
            independentMode={true}
            independentChange={setFullPath}
            independentTree={setTree}
          />
          <SimpleTable
            items={diretorioData && sortFiles(diretorioData?.itens)}
            changeDir={changeDir}
            loading={isLoading}
          />
        </ModalBody>
        <ModalFooter justifyContent={"center"} alignItems={"center"}>
          <Button variant="ghost" onClick={onClose} w="100px" h="40px">
            Cancelar
          </Button>
          <Button
            bg={green700}
            color={"white"}
            w="190px"
            h="40px"
            ml="1"
            mr={3}
            _hover={{
              opacity: 0.73,
            }}
            onClick={() => handleChangeDir()}
          >
            Mover para esta pasta
          </Button>
        </ModalFooter>
        {loading && (
          <LoadingTile>
            <Lottie
              options={{
                animationData: animationLoading,
                rendererSettings: {
                  clearCanvas: true,
                },
              }}
              width={150}
            />
          </LoadingTile>
        )}
      </ModalContent>
    </Modal>
  );
}
