import { Button, ButtonProps, Flex, Image, Text } from "@chakra-ui/react";

interface ButtonLoginProps extends ButtonProps {
  title: string;
  img: any;
  color: string;
  onClick: () => void;
}
function ButtonLogin({
  title,
  img,
  color,
  onClick,
  ...rest
}: ButtonLoginProps) {
  return (
    <Button
      onClick={() => onClick()}
      shadow="lg"
      leftIcon={
        <Flex
          ml={-3}
          justifyContent="center"
          alignItems="center"
          h="42px"
          w="42px"
          rounded="4px"
          bg="white"
        >
          <Image w="30px" src={img} />
        </Flex>
      }
      justifyContent="flex-start"
      isFullWidth
      h="48px"
      colorScheme={color}
      {...rest}
    >
      <Text color="white" fontSize="18px" pl="30px">
        {title}
      </Text>
    </Button>
  );
}

export default ButtonLogin;
