import React, { useCallback, useEffect } from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import CalendarSelect from "components/calendar-select";
import { DateRange } from "@material-ui/pickers/DateRangePicker/RangeTypes";
import Select, { Props } from "react-select";
import styled from "styled-components";
import { getErrorMessage } from "../utils/distribuicao-varietal-utils";
import { gray400, green700 } from "core/resources/theme/colors";
import { AiFillCheckCircle } from "react-icons/ai";
import { useFiltersLists } from "core/features/filters/store/lists";
import useAdvancedFiltersOperacoesExecutadas from "../hooks/useAdvancedFiltersOperacoesExecutadas";
import { useFilters } from "core/features/filters/store";

interface SelectProps extends Props {
  notOverlap?: boolean;
}

const SelectStyled = styled(Select)<SelectProps>`
  width: 100%;
  color: grey;
  cursor: pointer;
  border-color: ${gray400};
  z-index: ${(props) => (props.notOverlap ? 5 : 12)};
  height: 40px;
`;

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 40,
    maxHeight: 80,
    overflowY: "scroll",
    border: `1px solid ${gray400}`,
    boxShadow: state.isFocused ? `0px 0px 2px ${green700}` : "none",
    "&:hover": {
      border: `1px solid ${green700}`,
      boxShadow: `0px 0px 2px ${green700}`,
    },
  }),
  option: (base: any, state: any) => {
    return {
      ...base,
      backgroundColor: state.isSelected ? green700 : "inherit",
      "&:hover": {
        backgroundColor: state.isSelected ? green700 : "#F2F4F7",
      },
    };
  },
};
interface OperacoesExecutadasAdvancedFiltersProps {
  handleCloseModal: () => void;
}

const OperacoesExecutadasAdvancedFilters = ({
  handleCloseModal,
}: OperacoesExecutadasAdvancedFiltersProps) => {
  const {
    onChange,
    equipeId,
    setEquipeId,
    advancedFilters,
    setAdvancedFilters,
    selected,
    handleClearFilters,
    setSelected,
  } = useAdvancedFiltersOperacoesExecutadas();
  const { filters } = useFilters();
  const { filtroOperacoesExecutadas } = filters;

  const { operacoesExecutadas } = useFiltersLists();

  const formattedInsumosAdvancedFilters =
    operacoesExecutadas?.filtrosAvancados?.insumos.map((insumo) => {
      return {
        insumoId: insumo.insumoId,
        tipoApontamento: insumo.tipoApontamento,
        value: insumo.insumoId,
        label: `${insumo.codigo} - ${insumo.descricao}`,
      };
    });

  const formattedEquipesAdvancedFilters =
    operacoesExecutadas?.filtrosAvancados?.equipes.map((equipe) => {
      return {
        value: equipe.id,
        label: equipe.nome,
      };
    });

  const handleChangePeriodoOPeracoes = (props: DateRange<Date>) => {
    setAdvancedFilters(props);
  };

  useEffect(() => {
    if (!filtroOperacoesExecutadas?.operacoes.length) {
      setEquipeId({ value: "", label: "" });
      setSelected([]);
      setAdvancedFilters([null, null]);
    }
  }, [
    filtroOperacoesExecutadas?.operacoes.length,
    setAdvancedFilters,
    setEquipeId,
    setSelected,
  ]);

  return (
    <>
      <Text
        fontSize="16px"
        color="green.600"
        fontWeight="bold"
        margin="20px 20px 10px"
      >
        {" "}
        Filtros Avançados
      </Text>

      <CalendarSelect
        errors={useCallback(
          () => getErrorMessage(advancedFilters),
          [advancedFilters]
        )()}
        title="Periodo"
        value={advancedFilters}
        onChange={handleChangePeriodoOPeracoes}
      />
      <Flex ml={4} w="100%" flex={1} flexDir="column" display="flex">
        <Flex>
          <Text mr={1} color="gray.700">
            Insumo
          </Text>
          {selected && selected.length > 0 ? (
            <AiFillCheckCircle color={green700} size="1.2rem" />
          ) : null}
        </Flex>
        <Flex width="100%" mt={2} mb={2} pr={10}>
          <SelectStyled
            options={formattedInsumosAdvancedFilters ?? []}
            placeholder="Selecione"
            isMulti
            onChange={(option: any) => setSelected(option)}
            value={selected}
            styles={customStyles}
          />
        </Flex>
      </Flex>
      <Flex ml={4} mt={10} w="100%" flex={1} flexDir="column" display="flex">
        <Flex>
          <Text mr={1} color="gray.700">
            Equipe
          </Text>
          {equipeId && equipeId.value ? (
            <AiFillCheckCircle color={green700} size="1.2rem" />
          ) : null}
        </Flex>

        <Flex width="100%" mt={2} mb={2} pr={10}>
          <SelectStyled
            notOverlap
            options={formattedEquipesAdvancedFilters ?? []}
            placeholder="Selecione uma equipe"
            isClearable
            styles={customStyles}
            value={!equipeId.value ? equipeId.value : equipeId}
            onChange={(option: any) => {
              if (!option) {
                setEquipeId({ value: "", label: "" });
                return;
              }
              setEquipeId({ value: option.value, label: option.label });
            }}
          />
        </Flex>
      </Flex>
      <Flex justifyContent="flex-end" pb={6} pt={6} pl={1} pr={6}>
        <Button
          background="transparent"
          onClick={handleClearFilters}
          marginRight="auto"
        >
          <Text color="gray.600">Limpar filtros</Text>
        </Button>

        <Button
          background="green.600"
          onClick={() =>
            onChange(
              {
                periodoOperacao: advancedFilters,
                equipeIds: equipeId,
                insumos: selected,
              },
              handleCloseModal
            )
          }
          disabled={false}
        >
          <Text color="white">Aplicar Filtros</Text>
        </Button>
      </Flex>
    </>
  );
};

export default OperacoesExecutadasAdvancedFilters;
