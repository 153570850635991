import URL from "core/resources/urls";
import http from "infra/http";
import { useMutation } from "react-query";
import { SharedRequest } from "../typings";

export function useSaveShare() {
  return useMutation(URL.SHARE.CRIAR, async (body: SharedRequest) => {
    const { data }: { data: { code: string } } = await http.post(
      URL.SHARE.CRIAR,
      body
    );

    return data;
  });
}
