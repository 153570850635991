import { Text } from "@chakra-ui/react";
import { Table, TableColumnType } from "antd";
import {
  AplicacoesProperties,
  Insumos,
  StatusInsumosEnum,
} from "features/maps/typings/aplicacoes";
import formatDate from "infra/helpers/formatDate";
import { formatMoney } from "infra/helpers/number";

export const columnTablesAplicacoes: TableColumnType<AplicacoesProperties>[] = [
  { ...Table.EXPAND_COLUMN, width: "5px", align: "right" },
  {
    key: "1",
    title: "DATA",
    width: 100,
    render(record, { children }: any) {
      return !children ? null : formatDate(record.dataAplicacao);
    },
  },
  {
    key: "2",
    title: "APLICAÇÃO",
    width: 180,
    render(record, { children }: any) {
      return !children ? null : `${record.aplicacao + "º Aplicação"}`;
    },
  },
  {
    key: "3",
    title: "ORDEM",
    width: 90,
    dataIndex: "numeroOrdemServico",
  },
  {
    key: "4",
    title: "OPERAÇÃO",
    render(record, { children }: any) {
      return !children ? null : (
        <Text
          display="inline"
          textAlign="left"
          overflowX="visible"
          overflow="clip"
          textTransform={"uppercase"}
        >
          {record.operacao}
        </Text>
      );
    },
  },
  {
    key: "5",
    title: "DAE",
    width: 70,
    dataIndex: "dae",
  },
  {
    key: "6",
    title: "INTERV.",
    width: 70,
    dataIndex: "intervalo",
  },
  {
    key: "7",
    title: `US$/HA`,
    width: 100,
    align: "right",
    render(record, { children }: any) {
      return !children
        ? null
        : `$${formatMoney(record.custoTotalDolarHa).replace("R$", "").trim()}`;
    },
  },
];

export const columnTablesAplicacoesDetalhes: TableColumnType<Insumos>[] = [
  {
    title: "INSUMO",
    dataIndex: "insumo",
  },
  {
    title: "PROGRAMADO",
    width: 150,

    align: "right",
    render(record) {
      return `${formatMoney(
        record.doseProgramada,
        ""
      )} ${record.unidadeMedidaSigla.toUpperCase()}`;
    },
  },
  {
    title: "REALIZADO",
    width: 150,
    align: "right",
    render(record) {
      return `${formatMoney(
        record.doseRealizada,
        ""
      )} ${record.unidadeMedidaSigla.toUpperCase()}`;
    },
  },
  {
    title: "ÁREA (HA)",
    width: 150,
    align: "right",
    render(record) {
      return formatMoney(record.areaExecutada, "");
    },
  },
  {
    title: `US$/HA`,
    width: 150,
    align: "right",
    render(record) {
      return formatMoney(record.valorDolarHa, "");
    },
  },
];

export const getInsumoName = (insumo: StatusInsumosEnum) => {
  if (insumo === StatusInsumosEnum.Fungicidas) {
    return `Fungicidas`;
  }

  if (insumo === StatusInsumosEnum.Herbicidas) {
    return `Herbicidas`;
  }
  if (insumo === StatusInsumosEnum.Inseticidas) {
    return `Inseticidas`;
  }

  if (insumo === StatusInsumosEnum.Fertilizantes) {
    return `Fertilizantes`;
  }

  return `Reguladores`;
};
