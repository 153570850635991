import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from "axios";

import config from "infra/config"
import { refreshToken } from "./utils";

export enum ENDPOINTS {
    FARM = "/farms",
    FARM_MOSAICS = "/farm/:farm_id/mosaics",
    FARM_SECTORS = "/farm/:farm_id/sectors",
    FARM_FIELDS = "/farm/:farm_id/fields",
    FARM_FIELD = "/farm/:farm_id/field/:field_id",
    FARM_FIELD_IMAGES = "/farm/:farm_id/field/:field_id/images",
    FARM_FIELD_IMAGE = "/farm/:farm_id/field/:field_id/image/:image_id",
    SECTOR_MOSAICS = "/sector/:sector_id/mosaics",
    FIELDS_SEARCH = "/fields/search",
    IMAGE_ROUTE = "/image/:image_id/route",
    IMAGE_ROUTE_DOWNLOAD = "/image/:image_id/route/downloadkml",
}

const farmApi = axios.create({
    baseURL: config.apiFarmUrl,
    headers: {
        "Access-Control-Allow-Origin": "*",
    }
});

farmApi.interceptors.request.use((config: AxiosRequestConfig) => {
    return refreshToken(config)
}, (error: AxiosError) => {
    Promise.reject(error)
})

export async function get<T>(url: string, queryParams = {}, headers: AxiosRequestHeaders = {}) {
    const config: AxiosRequestConfig = {
        headers: headers,
        params: queryParams
    }
    try {
        const { data } = await farmApi.get<T>(url, config);
        return data
     } catch (error) {
        throw error
    }
}

export async function findAll<T>(url: string, queryParams = {}) {
    const config: AxiosRequestConfig = {
        params: queryParams
    }
    try { 
        const { data } = await farmApi.get<T>(url, config);
        return {data};
    } catch (error) {
        throw error
    }
}

export async function findOne<T>(url: string, queryParams = {}, headers = {}) {
    const config: AxiosRequestConfig = {
        headers: headers,
        params: queryParams
    }
    try {
        const { data } = await farmApi.get<T>(url, config);
        return {data}
    } catch (error) {
        throw error
    }
}

export async function download<T>(url: string, queryParams = {}, headers = {}) { 
    const config: AxiosRequestConfig = {
        headers: headers,
        params: queryParams
    }
    try {
        return await farmApi.get<T>(url, config);
     } catch (error) {
        throw error
    }
}
