import { useEffect } from "react";
import { Flex, IconButton } from "@chakra-ui/react";

import ContainerFilter from "../../ContainerFilter";
import { useFiltersLists } from "features/filters/store/lists";
import { useFilters } from "features/filters/store";

import OcupacoesButtons from "../../OcupacoesButtons";
import SelectedButtons from "../../SelectedButtons";
import { Idades, Ocupacoes, TecnologiasItem } from "features/maps/typings";
import CheckboxFiltro from "components/inputs/checkbox-filtro";
import { ButtonClearMapCheckbox } from "../../ButtonLimpar";
import { OcupacaoEnum } from "features/maps/enums";
import LoadingBox from "components/layout/loading-box";
import NotFoundMapFilters from "components/NotFoundMapFilters";
import { FaChevronUp } from "react-icons/fa";
import MapGridButton from "components/MapGridButton";

interface FiltersIdadeDoTalhaoProps {
  isVisible: boolean;
  handleChangeIsVisible: (prop: boolean) => void;
  handleOpenGrid: () => void;
}

function FiltersIdadeDoTalhao({
  handleChangeIsVisible,
  handleOpenGrid,
  isVisible,
}: FiltersIdadeDoTalhaoProps) {
  const { filters, updateFiltroIdadeDoTalhao } = useFilters();
  const { loadings, idadeDoTalhao } = useFiltersLists();

  const { filtroIdadeDoTalhao } = filters;

  const findTecnologiasByOcupacaoSelected = idadeDoTalhao?.ocupacoes.find(
    (item) => item.codigo === filtroIdadeDoTalhao?.ocupacao
  );

  const findIdades = idadeDoTalhao?.idades.filter(
    (item) => item.ocupacao === filtroIdadeDoTalhao?.ocupacao
  );

  const findIdadesWithTecnologiaSelected = findIdades?.filter((item) =>
    filtroIdadeDoTalhao?.tecnologias.includes(item.tecnologiaId)
  );

  const idades =
    findIdadesWithTecnologiaSelected?.length! > 0
      ? findIdadesWithTecnologiaSelected
      : findIdades;

  const removedDuplicateIdades = idades?.filter(
    (v, i, a) => a.findIndex((t) => t.idade === v.idade) === i
  );

  const isCheckedTodos =
    !!filtroIdadeDoTalhao?.idades?.length &&
    filtroIdadeDoTalhao?.idades.length === removedDuplicateIdades?.length;

  const onClickOperacao = (idade: Idades) => {
    const formattedIdade = {
      idade: idade.idade,
      color: idade.cor,
      tecnologiaId: idade.tecnologiaId,
    };

    if (filtroIdadeDoTalhao?.idades?.find((id) => idade.idade === id.idade)) {
      const filteredIdades = filtroIdadeDoTalhao.idades.filter(
        (id) => id.idade !== idade.idade
      );
      updateFiltroIdadeDoTalhao({
        tecnologias: filtroIdadeDoTalhao?.tecnologias,
        ocupacao: filtroIdadeDoTalhao?.ocupacao,
        idades: filteredIdades,
      });
    } else {
      updateFiltroIdadeDoTalhao(
        {
          tecnologias: filtroIdadeDoTalhao?.tecnologias,
          ocupacao: filtroIdadeDoTalhao?.ocupacao,
          idades: [...(filtroIdadeDoTalhao?.idades ?? []), formattedIdade],
        },
        true
      );
    }
  };

  const onClearProdutividades = () => {
    updateFiltroIdadeDoTalhao({
      tecnologias: filters.filtroIdadeDoTalhao?.tecnologias,
      ocupacao: filters.filtroIdadeDoTalhao?.ocupacao,
      idades: [],
    });
  };

  const formattedIdadesFilters = removedDuplicateIdades?.map((item) => {
    return {
      color: item.cor,
      idade: item.idade,
      tecnologiaId: item.tecnologiaId,
    };
  });

  const selectOcupacao = (ocupacaoId: OcupacaoEnum) => {
    const findOcupacao = idadeDoTalhao?.ocupacoes.find(
      (item) => item.codigo === ocupacaoId
    );
    const findFirstTecnologiaEnabled = findOcupacao?.tecnologias.find(
      (item) => item.id
    );
    updateFiltroIdadeDoTalhao({
      ocupacao: findOcupacao?.codigo,
      tecnologias: findFirstTecnologiaEnabled?.id
        ? [findFirstTecnologiaEnabled.id]
        : [],
      idades: [],
    });
  };

  useEffect(() => {
    if (!filtroIdadeDoTalhao?.tecnologias.length) {
      const ocp = idadeDoTalhao?.ocupacoes || [];
      const isOcupacaoEnabled = ocp.find(
        (item) => item.codigo === filters.filtroIdadeDoTalhao?.ocupacao
      );

      if (
        !filters.filtroIdadeDoTalhao?.ocupacao ||
        !isOcupacaoEnabled?.enabled
      ) {
        const findFirstOcupacaoEnabled = ocp.find((item) => item.enabled);
        const findFirstTecnologiaEnabled =
          findFirstOcupacaoEnabled?.tecnologias.find((item) => item.id);
        updateFiltroIdadeDoTalhao({
          ocupacao: findFirstOcupacaoEnabled?.codigo,
          tecnologias: findFirstTecnologiaEnabled?.id
            ? [findFirstTecnologiaEnabled.id]
            : [],
          idades: [],
        });
      }
    }
  }, [
    filters.filtroIdadeDoTalhao?.ocupacao,
    filtroIdadeDoTalhao?.tecnologias,
    idadeDoTalhao?.ocupacoes,
    updateFiltroIdadeDoTalhao,
  ]);

  const updateTecnologiasSelected = (tecnologiaId?: string) => {
    if (filters.filtroIdadeDoTalhao?.tecnologias.length) {
      const findElementIndex =
        filters.filtroIdadeDoTalhao.tecnologias.findIndex(
          (item) => item === tecnologiaId
        );
      const newArray = [...filters.filtroIdadeDoTalhao.tecnologias];
      if (findElementIndex > -1) {
        if (filters.filtroIdadeDoTalhao?.tecnologias.length === 1) {
          return;
        }
        newArray.splice(findElementIndex, 1);
      } else {
        newArray.push(tecnologiaId as string);
      }
      const foundCompativeIdades = filters.filtroIdadeDoTalhao.idades.filter(
        (item) => newArray.includes(item.tecnologiaId as string)
      );

      const checkTodos = findTecnologiasByOcupacaoSelected?.tecnologias
        .filter((item) => item.id !== null)
        .map((i) => {
          return i.id;
        });

      if (!tecnologiaId) {
        updateFiltroIdadeDoTalhao({
          idades: foundCompativeIdades,
          ocupacao: filters.filtroIdadeDoTalhao?.ocupacao,
          tecnologias: checkTodos,
        });
      } else {
        updateFiltroIdadeDoTalhao({
          idades: foundCompativeIdades,
          ocupacao: filters.filtroIdadeDoTalhao?.ocupacao,
          tecnologias: newArray,
        });
      }
    }
  };

  useEffect(() => {
    if (!filtroIdadeDoTalhao?.tecnologias.length) {
      const findOcupacao = idadeDoTalhao?.ocupacoes.find(
        (item) => item.codigo === filtroIdadeDoTalhao?.ocupacao
      );
      const findFirstTecnologiaEnabled = findOcupacao?.tecnologias?.find(
        (item) => item.id
      );

      if (!filtroIdadeDoTalhao?.tecnologias.length) {
        updateFiltroIdadeDoTalhao({
          idades: [],
          ocupacao: filtroIdadeDoTalhao?.ocupacao,
          tecnologias: findFirstTecnologiaEnabled?.id
            ? [findFirstTecnologiaEnabled.id]
            : [],
        });
      }
    }
  }, [
    filtroIdadeDoTalhao?.ocupacao,
    filtroIdadeDoTalhao?.tecnologias.length,
    idadeDoTalhao?.ocupacoes,
    updateFiltroIdadeDoTalhao,
  ]);

  useEffect(() => {
    const ocupacaoSelected = idadeDoTalhao?.ocupacoes.find(
      (item) => item.codigo === filtroIdadeDoTalhao?.ocupacao
    );
    const isOcupacaoEnabled = ocupacaoSelected?.enabled;
    const findFirstOcupacaoEnabled = idadeDoTalhao?.ocupacoes.find(
      (item) => item.enabled
    );

    if (isOcupacaoEnabled !== undefined && isOcupacaoEnabled === false) {
      const findFirstTecnologiaEnabled =
        findFirstOcupacaoEnabled?.tecnologias &&
        findFirstOcupacaoEnabled?.tecnologias.find((item) => item.id);

      const idsIdadesFilter = filtroIdadeDoTalhao?.idades.map((item) => {
        return item.idade;
      });

      const compativeIdades = removedDuplicateIdades
        ?.filter((item) => idsIdadesFilter?.includes(item.idade))
        .map((idade) => {
          return {
            color: idade.cor,
            idade: idade.idade,
            tecnologiaId: idade.tecnologiaId,
          };
        });
      const compativeTecnologias = findFirstOcupacaoEnabled?.tecnologias
        .filter((item) => filtroIdadeDoTalhao?.tecnologias.includes(item.id))
        .map((tecnologia) => {
          return tecnologia.id;
        });

      updateFiltroIdadeDoTalhao({
        idades: compativeIdades?.length ? compativeIdades : [],
        ocupacao: findFirstOcupacaoEnabled?.codigo,
        tecnologias: compativeTecnologias?.length
          ? compativeTecnologias
          : findFirstTecnologiaEnabled?.id
          ? [findFirstTecnologiaEnabled.id]
          : [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.unidade,
    findTecnologiasByOcupacaoSelected,
    filtroIdadeDoTalhao?.tecnologias.length,
  ]);

  if (loadings.idadeDoTalhao) {
    return <LoadingBox isVisible={isVisible} />;
  }

  if (!idadeDoTalhao && !loadings.idadeDoTalhao) {
    return (
      <ContainerFilter
        containerProps={{
          mt: 3,
          rounded: 8,
          padding: 4,
        }}
      >
        <Flex pl={1} mb={1}>
          <NotFoundMapFilters message="Não foram encontadras ocupações para o filtro selecionado" />
        </Flex>
        <Flex
          padding="5px"
          flexDirection="row-reverse"
          borderTop="1px solid #84848440 "
          marginTop="10px"
        >
          <IconButton
            background="none"
            _hover={{}}
            _focus={{}}
            _active={{}}
            icon={<FaChevronUp color="#848484" />}
            aria-label="close-button"
            marginRight="20px"
            onClick={() => handleChangeIsVisible(false)}
          />
        </Flex>
      </ContainerFilter>
    );
  }

  return (
    <ContainerFilter
      containerProps={{
        mt: 3,
        rounded: 8,
        padding: 4,
      }}
      isVisible={isVisible}
    >
      <OcupacoesButtons
        onChange={selectOcupacao}
        value={filters.filtroIdadeDoTalhao?.ocupacao!}
        items={idadeDoTalhao?.ocupacoes as Ocupacoes[]}
      />
      {!loadings.produtividade ? (
        <>
          <SelectedButtons
            value={filters.filtroIdadeDoTalhao?.tecnologias ?? []}
            items={
              findTecnologiasByOcupacaoSelected?.tecnologias as TecnologiasItem[]
            }
            onChange={(v: any) => updateTecnologiasSelected(v)}
          />
          <Flex h="1px" bg="gray.200" mt={4} flex={1} />

          <Flex mt={4} flexDir="column" ml={2}>
            {removedDuplicateIdades && !removedDuplicateIdades.length && (
              <Flex>
                <NotFoundMapFilters message="Não foram encontadras idades para o filtro selecionado" />
              </Flex>
            )}
            <Flex>
              {removedDuplicateIdades?.length ? (
                <CheckboxFiltro
                  disabled={false}
                  onClick={() =>
                    updateFiltroIdadeDoTalhao({
                      tecnologias: filters.filtroIdadeDoTalhao?.tecnologias,
                      ocupacao: filters.filtroIdadeDoTalhao?.ocupacao,
                      idades: isCheckedTodos
                        ? []
                        : (formattedIdadesFilters as any),
                    })
                  }
                  isChecked={isCheckedTodos}
                  text={"Todas"}
                  data-testid="menu-filtros-operacoes-executadas-todos"
                />
              ) : null}
              {removedDuplicateIdades && removedDuplicateIdades.length > 0 && (
                <ButtonClearMapCheckbox onClear={onClearProdutividades} />
              )}
            </Flex>

            {removedDuplicateIdades?.map((idade, index) => (
              <CheckboxFiltro
                key={idade.idade + index}
                text={idade.idade.toString()}
                onClick={() => onClickOperacao(idade)}
                color={idade.cor}
                data-testid={`menu-filtro-idade-do-talhao-${idade.idade}`}
                isChecked={
                  !!filters.filtroIdadeDoTalhao?.idades?.find(
                    (id) => id.idade === idade.idade
                  )
                }
              />
            ))}
          </Flex>
        </>
      ) : null}

      <Flex
        padding="5px"
        justifyContent="space-between"
        borderTop="1px solid #84848440 "
        alignItems="center"
        paddingTop="15px"
        marginTop="10px"
      >
        <MapGridButton handleOpenGrid={handleOpenGrid} />

        <IconButton
          background="none"
          _hover={{}}
          _focus={{}}
          _active={{}}
          icon={<FaChevronUp color="#848484" />}
          aria-label="close-button"
          marginRight="20px"
          onClick={() => handleChangeIsVisible(false)}
        />
      </Flex>
    </ContainerFilter>
  );
}

export default FiltersIdadeDoTalhao;
