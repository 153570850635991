import { FeatureCollection, Geometry } from "geojson";

import { OcupacaoEnum } from "../enums";
import {
  MapaDeChuvaFiltroIndice,
  MapaDeChuvaFiltroRapidoProps,
} from "./mapaDeChuva";

export interface TalhaoResultProperties {
  latitude: number;
  longitude: number;
  latitudeDelta: number;
  longitudeDelta: number;
  latitudeMinima: number;
  latitudeMaxima: number;
  longitudeMinima: number;
  longitudeMaxima: number;
  dataInicial?: string;
  dataFinal?: string;
  periodo?: number;
  periodoDescricao?: string;
  TexturaSoloCodigo?: number;
  TexturaSoloNome?: string;
}

export interface GridProps {
  Area: number;
  AreaColhida: number;
  Codigo: string;
  Cor: string;
  DAE: number | null;
  DataColheita: string;
  DataEmergencia: string;
  DataPlantio: string;
  EstadioFenologico: number | null;
  Ocupacao: string;
  PesoColhido: string;
  PontoCentralLat: string;
  PontoCentralLong: string;
  Produtividade: string;
  Progresso: number;
  TalhaoId: string;
  Variedade: string;
}

export interface TalhaoResult
  extends FeatureCollection<Geometry, CustomFeature> {
  Properties?: TalhaoResultProperties;
  Grid: GridProps[];
}

export interface CustomFeature {
  codigo: string;
  fill: string;
  fillOpacity: number;
  id: string;
  ocupacao: number;
  pontoCentralLat: number;
  pontoCentralLong: number;
  stroke: string;
  strokeOpacity: number;
  strokeWidth: number;
  texturaSolo: string;
  unidadeId: string;
  variedadeCodigo: string;
  variedadeNome: string;
  TexturaSoloCodigo?: number;
  TexturaSoloNome?: string;
}

export interface CustomLatLng {
  lat: () => number;
  lng: () => number;
}

export interface Unidade {
  id: string;
  codigo: string;
  distancia: any | null;
  nome: string;
  unidadePadrao: boolean;
  pontoCentral: LatLong;
}

export interface Variedade {
  codigo: string;
  id: string;
  nome: string;
  color: string;
  ocupacaoCodigo: OcupacaoEnum;
  finalidadeId: string | null;
  tecnologiaId: string | null;
  variedadeGrupoId: string | null;
}

export interface VariedadeGrupo {
  color: string;
  finalidadeId: string;
  id: string;
  nome: string;
  ocupacaoCodigo: OcupacaoEnum;
  tecnologiaId: string | null;
  variedades: Variedade[];
}
export interface Tecnologia {
  id: string | null;
  nome: string;
  variedadeGrupos: VariedadeGrupo[];
  ocupacaoCodigo: OcupacaoEnum;
  finalidadeId: string | null;
  color: string;
}
export interface Finalidade {
  id: string | null;
  color: string;
  nome: string;
  tecnologias: Tecnologia[];
  ocupacaoCodigo: OcupacaoEnum;
}
export interface Ocupacao {
  id: OcupacaoEnum;
  codigo: OcupacaoEnum;
  nome: string;
  finalidades: Finalidade[];
  color?: string | null;
}

export enum OcupacaoNomeEnum {
  Algodão = "Algodão",
  Soja = "Soja",
  Milho = "Milho",
}

export enum KeyItemsOcupacoes {
  ocupacoes = "ocupacoes",
  finalidades = "finalidades",
  tecnologias = "tecnologias",
  variedadeGrupos = "variedadeGrupos",
  variedades = "variedades",
}

export interface FiltroDistribuicaoVarietal {
  ocupacoes: Ocupacao[];
  finalidades: Finalidade[];
  tecnologias: Tecnologia[];
  variedadeGrupos: VariedadeGrupo[];
  variedades: Variedade[];
  periodoPlantio?: PeriodoAdvancedFiltersProps;
  periodoEmergencia?: PeriodoAdvancedFiltersProps;
  periodoColheita?: PeriodoAdvancedFiltersProps;
  periodoPrevisaoDeColheita?: PeriodoAdvancedFiltersProps;
}

export interface FiltroOperacoesInOperacoesExecutadas {
  operacao: string;
  color: string;
  tecnologiaId?: string;
}

export interface FiltroOperacoesInConcentracaoColheita {
  operacao: string;
  color: string;
  tecnologiaId?: string;
}

export interface FiltroProdutividadeInProdutividade {
  codigo: number;
  color: string;
  descricao: string;
  tecnologiaId?: string;
  min: number;
  max: number;
}

export interface FiltroIdadeInIdadeDoTalhao {
  idade: number;
  color: string;
  tecnologiaId: string;
}

export interface FiltroAplicacoesInNumeroDeAplicacoes {
  codigo: number;
  color: string;
  tecnologiaId: string;
  insumoGrupo?: number;
}

export interface FiltroOperacoesExecutadas {
  ocupacao: number;
  tecnologias: string[];
  operacoes: FiltroOperacoesInOperacoesExecutadas[];
  equipeIds?: string[];
  insumos?: {
    insumoId: string;
    tipoApontamento: number;
  }[];
  periodoOperacao?: {
    dataInicial?: string;
    dataFinal?: string;
  };
}

export enum ConcentracaoColheitaTipo {
  Colhido = 1,
  Previsto = 2,
  Ambos = 3,
}

export interface FiltroConcentracaoColheitaItemSemana {
  semanaCodigo: number;
  cor: string;
}

export interface FiltroConcentracaoColheitaItem {
  nome: string;
  ano: number;
  mes: number;
  cor: string;
  semanas: FiltroConcentracaoColheitaItemSemana[];
}

export interface FiltroConcentracaoColheita {
  tipo: ConcentracaoColheitaTipo;
  ocupacao: OcupacaoEnum;
  tecnologias: string[];
  concentracoes: FiltroConcentracaoColheitaItem[];
}

export interface FiltroProdutividade {
  ocupacao: number;
  tecnologias: string[];
  produtividades: FiltroProdutividadeInProdutividade[];
}

export interface FiltroIdadeDoTalhao {
  ocupacao: number;
  tecnologias: string[];
  idades: FiltroIdadeInIdadeDoTalhao[];
}

export interface FiltroNumeroDeAplicacoes {
  ocupacao: number;
  tecnologias: string[];
  aplicacoes: FiltroAplicacoesInNumeroDeAplicacoes[];
  insumoGrupo: number;
}

export interface FiltroOrdensDeServicosAbertas {
  periodos: number[];
  operacoes: OrdensDeServicosAbertaOperacoesType[];
}

export interface FiltroDistribuicaoDeEquipes {
  equipe: {
    id: string;
    color: string;
    name: string;
  };
  setorId?: string;
}

export interface FiltroMapaDeChuvas {
  periodo: number;
  dataInicial: string;
  horaInicial: string;
  dataFinal: string;
  horaFinal: string;
  indicesPluviometrico: number[];
}
export interface PeriodoAdvancedFiltersProps {
  dataInicial?: string;
  dataFinal?: string;
}

export interface DistribuicaoVarietalPostBody {
  setorId?: string;
  ocupacoes: number[];
  finalidades: {
    ocupacao: OcupacaoEnum;
    finalidadeId: string | null;
    color: string;
  }[];
  tecnologias: {
    ocupacao: OcupacaoEnum;
    finalidadeId: string | null;
    tecnologiaId: string | null;
    color: string;
  }[];
  variedadeGrupos: {
    ocupacao: OcupacaoEnum;
    finalidadeId: string | null;
    tecnologiaId: string | null;
    variedadeGrupoId: string | null;
    color: string;
  }[];
  variedades: {
    ocupacao: OcupacaoEnum;
    finalidadeId: string | null;
    tecnologiaId: string | null;
    variedadeId: string | null;
    variedadeGrupoId: string | null;
    color: string;
  }[];
  periodoPlantio: PeriodoAdvancedFiltersProps;
  periodoEmergencia: PeriodoAdvancedFiltersProps;
  periodoColheita: PeriodoAdvancedFiltersProps;
  periodoPrevisaoColheita: PeriodoAdvancedFiltersProps;
}

export interface DistribuicaoVarietalPostData {
  Properties: {
    ProdutividadeMedia: number | null;
    latitude: number;
    latitudeDelta: number;
    latitudeMaxima: number;
    latitudeMinima: number;
    longitude: number;
    longitudeDelta: number;
    longitudeMaxima: number;
    longitudeMinima: number;
    totalAreaHa: number;
  };
}

export interface LatLong {
  lat: number;
  long: number;
}

export interface Safra {
  id: string;
  descricao: string;
  codigo: string;
  isSafraAtual?: boolean;
  periodoPluviometricoInicio?: string;
  periodoPluviometricoFim?: string;
}

export interface Produtividade {
  produtividade: number;
  unidadeMedida: string;
}
export interface TalhaoDetalhes {
  ocupacao: Ocupacao | null;
  variedade: Variedade;
  tecnologia: {
    id: string;
    nome: string;
  };
  plantio: {
    previsao: {
      data: string;
    };
    real: {
      dataInicio: string;
      dataFim: string;
    };
  } | null;
  emergencia: {
    dataEmergencia: string;
    diasDesdeOPlantio: number;
  } | null;
  estadiosFenologico:
    | {
        codigo: string;
        descricao: string;
        data: string;
        dae: number;
      }[]
    | null;
  hoje: {
    data: string;
    dae: number;
  } | null;
  colheita: {
    previsao: {
      data: string;
      dae: number;
      dias: number | null;
    };
    real: {
      dataInicio: string;
      dataFim: string;
      dae: number;
    };
  };
  produtividade: Produtividade | null;
  quantidadeDeOperacoes: number;
  quantidadeDeOrdensEmAberto: number;
  ciclo: {
    esperado: number;
    realizado: number;
    porcentagem: number;
  };
  aplicacoes: {
    inseticidas: number;
    fungicidas: number;
    herbicidas: number;
    reguladores: number;
  };
  chuvaAcumulada: {
    baseMetereologicaId: string;
    valor: number;
    dataInicioSoma: string;
    dataFimSoma: string;
  } | null;
  estande: number;
  defaultFiltroGraficoPluviometrico: number;
}

export interface TecnologiasItem {
  color: string | null;
  finalidadeId: string | null;
  id: string;
  nome: string;
  ocupacaoCodigo: number;
}

export interface ConcentracaoColheitaTecnologiaItem {
  color: string | null;
  finalidadeId: string | null;
  id: string;
  nome: string;
  ocupacaoCodigo: number;
  variedades: {
    id: string;
    codigo: string;
    nome: string;
    color: string;
    ocupacaoCodigo: number;
    finalidadeId: string;
    tecnologiaId: string;
  }[];
}

export interface OperacoesExecutadasOcupacoes {
  codigo: number;
  nome: string;
  tecnologias: TecnologiasItem[];
  enabled: boolean;
}

// export interface ProdutividadeOcupacoes {
export interface Ocupacoes {
  codigo: number;
  nome: string;
  tecnologias: TecnologiasItem[];
  enabled: boolean;
}

export interface ProdutividadeProdutividades {
  codigo: number;
  cor: string;
  ocupacao: number;
  descricao: string;
  tecnologiaId: string;
  min: number;
  max: number;
}

export interface NumeroDeAplicacoesOcupacoes {
  codigo: number;
  nome: string;
  tecnologias: TecnologiasItem[];
  enabled: boolean;
  insumos: NumeroDeAplicacoesInsumos[];
}

export interface ConcentracaoColheitaOcupacao {
  codigo: number;
  nome: string;
  tecnologias: ConcentracaoColheitaTecnologiaItem[];
  enabled: boolean;
}
export interface OperacoesOrdensDeServicosAbertas {}

export interface OperacoesExecutadasOperacoes {
  cor: string;
  descricao: string;
  ocupacao: number;
  operacaoId: string;
  tecnologiaId: string;
}

export interface NumeroDeAplicacoesAplicacoes {
  codigo: number;
  cor: string;
  descricao: string;
  ocupacao: number;
  tecnologiaId: string;
  insumoGrupo: number;
}

export interface NumeroDeAplicacoesInsumos {
  codigo: number;
  descricao: string;
  enabled: boolean;
}

export interface OperacoesExecutadas {
  ocupacoes: Ocupacoes[];
  operacoes: OperacoesExecutadasOperacoes[];
  filtrosAvancados: {
    equipes: {
      id: string;
      nome: string;
    }[];
    insumos: {
      codigo: string;
      descricao: string;
      insumoId: string;
      tipoApontamento: number;
    }[];
  };
}

export interface ProdutividadeMap {
  ocupacoes: Ocupacoes[];
  produtividades: ProdutividadeProdutividades[];
}

export interface Idades {
  cor: string;
  idade: number;
  ocupacao: number;
  tecnologiaId: string;
}

export interface IdadeDoTalhao {
  ocupacoes: Ocupacoes[];
  idades: Idades[];
}

export interface NumeroDeAplicacoes {
  ocupacoes: NumeroDeAplicacoesOcupacoes[];
  aplicacoes: NumeroDeAplicacoesAplicacoes[];
}

export interface ConcentracaoColheitaItemSemana {
  nome: string;
  semanaCodigo: number;
  cor: string;
  descricao: string;
}

export interface ConcentracaoColheitaItem {
  nome: string;
  ano: number;
  mes: number;
  cor: string;
  ocupacao: number;
  tecnologiaId: string;
  tipo: number;
  tipoDescricao: string;
  concentracoesPorSemana: ConcentracaoColheitaItemSemana[];
}

export interface ConcentracaoColheita {
  ocupacoes: ConcentracaoColheitaOcupacao[];
  concentracoes: ConcentracaoColheitaItem[];
}

export type OrdensDeServicosAbertaOperacoesType = {
  cor: string;
  descricao: string;
  operacaoId: string;
  periodo: number;
};

export interface PeriodosProps {
  periodo: number;
  descricao: string;
  enabled: boolean;
}

export interface OrdensDeServicosAberta {
  ocupacoes: Ocupacoes[];
  operacoes: OrdensDeServicosAbertaOperacoesType[];
  periodos: PeriodosProps[];
  tecnologias: string[];
}

export type EquipeType = {
  id: string;
  nome: string;
  cor: string;
};

export interface DistribuicaoDeEquipes {
  equipes: EquipeType[];
}

export type OrdemPorEquipe = {
  area: number;
  dataAbertura: string;
  descricao: string;
  diasAberto: number;
  numero: number;
  talhao: string;
};

export interface OrdensDeServicosAbertasPorEquipePorps {
  equipe: string;
  ordens: OrdemPorEquipe[];
}

export interface MapaDeChuvasProps {
  filtroRapido: MapaDeChuvaFiltroRapidoProps[];
  indices: MapaDeChuvaFiltroIndice[];
}

export interface TexturaSoloListagemItem {
  nome: string;
  codigo: number;
  cor: string;
}
export interface TexturaSolo {
  codigo: string;
  nome: string;
  porcentagemArgilaInicial: number;
  porcentagemArgilaFinal: number;
}
export interface TalhaoResumido {
  id: string;
  codigo: string;
  unidade: string;
  setor: string;
  area: number;
  texturaSolo: TexturaSolo | null;
}

export enum TipoMapaTalhoes {
  DistribuicaoVarietal = 1,
  Texturas = 2,
  Chuvas = 3,
  Satelite = 4,
  ConcentracaoColheita = 5,
  OperacoesExecutadas = 6,
  NumeroDeAplicacoes = 7,
  Produtividade = 8,
  IdadeDoTalhao = 9,
  OrdensDeServicosAberta = 10,
  DistribuicaoDeEquipes = 11,
}

export interface ApiPagination {
  currentPage: number;
  resultsPerPage: number;
  totalCount: number;
}

export interface OrdensDeServicosAbertaFilters {
  periodos: number[];
  operacoes: OrdensDeServicosAbertaOperacoesType[];
}
