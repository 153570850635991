import { createContext, ReactNode, useState, useEffect } from "react";
import { Safra, Unidade } from "core/features/maps/typings";
import { Setor } from "core/features/unidades/typings";
import useGetDiretorios from "ui/pages/app/arquivos/agrodrive/hooks/useGetDiretorios";
import {
  ArquivoResponse,
  DiretorioResponse,
} from "ui/pages/app/arquivos/agrodrive/types/arquivos";
import { DiretorioData } from "ui/pages/app/arquivos/agrodrive/types/arquivos";
import {
  Cultura,
  Talhao,
  CustomTag,
} from "ui/pages/app/arquivos/agrodrive/types/tags";
import useGetFiles from "ui/pages/app/arquivos/agrodrive/hooks/useGetFiles";
import useGetTags from "ui/pages/app/arquivos/agrodrive/hooks/useGetTags";

export interface AgrodriveContextProps {
  items: {
    currentSafras: Safra[];
    currentUnidades: Unidade[];
    currentCultura: Cultura[];
    currentSetores: Setor[];
    currentTalhao: Talhao[];
    tagsPersonalizadas: CustomTag[];
    tagsVinculadas?: string[];
    tree: ArquivoResponse[];
    selecteds: any[];
    diretorioData?: DiretorioResponse;
    isLoadingUploadFile: boolean;
    isLoadingDiretorioList: boolean;
    isFetchingDiretorios: boolean;
    diretorioRaiz: string;
    path: string;
    fullPath: string;
    arquivos: DiretorioData[];
    pagina: number;
    updating: boolean;
    criouUmArquivo: boolean;
    tagsHerdadas?: string[];
    customTagsData?: CustomTag[] | undefined;
    searchTerm?: string;
    searchTags?: string;
  };
  onChange: {
    setCurrentSafras: (prop: Safra[]) => void;
    setCurrentUnidades: (prop: Unidade[]) => void;
    setCurrentCultura: (prop: Cultura[]) => void;
    setCurrentSetores: (prop: Setor[]) => void;
    setCurrentTalhao: (prop: Talhao[]) => void;
    setTagsPersonalizadas: (prop: CustomTag[]) => void;
    setTagsVinculadas: (prop: string[]) => void;
    setTree: (prop: ArquivoResponse[]) => void;
    setSelecteds: (prop: any[]) => void;
    refetchDiretorioList: (props?: any) => Promise<any>;
    setPath: (prop: string) => void;
    setFullPath: (prop: string) => void;
    setPagina: (pagina: number) => void;
    setArquivos: (prop: DiretorioData[]) => void;
    setUpdating: (prop: boolean) => void;
    setCriouUmArquivo: (prop: boolean) => void;
    setTagsHerdadas: (prop: string[]) => void;
    searchingTerm: (prop: string) => void;
    refetchCustomTags: () => Promise<any>;
  };
}

export const AgrodriveContext = createContext({} as AgrodriveContextProps);

export const AgrodriveContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [currentSafras, setCurrentSafras] = useState<Safra[]>([]);
  const [currentUnidades, setCurrentUnidades] = useState<Unidade[]>([]);
  const [currentCultura, setCurrentCultura] = useState<Cultura[]>([]);
  const [currentSetores, setCurrentSetores] = useState<Setor[]>([]);
  const [currentTalhao, setCurrentTalhao] = useState<Talhao[]>([]);
  const [tagsPersonalizadas, setTagsPersonalizadas] = useState<CustomTag[]>([]);
  const [tagsVinculadas, setTagsVinculadas] = useState<string[]>([]);
  const [tagsHerdadas, setTagsHerdadas] = useState<string[]>([]);
  const [tree, setTree] = useState<ArquivoResponse[]>([]);
  const [selecteds, setSelecteds] = useState<any[]>([]);
  const diretorioRaiz = "/";
  const [path, setPath] = useState<string>(diretorioRaiz);
  const [fullPath, setFullPath] = useState<string>("/");
  const [pagina, setPagina] = useState<number>(1);
  const [arquivos, setArquivos] = useState<DiretorioData[]>([]);
  const [updating, setUpdating] = useState<boolean>(false);
  const [criouUmArquivo, setCriouUmArquivo] = useState<boolean>(false);

  //NEW IMPLEMENT
  const [searchTermsLength, setSearchTermsLength] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchTags, setSearchTags] = useState<string>("");

  const getFullPath = () => {
    let completePath = "/";
    // eslint-disable-next-line array-callback-return
    tree.map((item: ArquivoResponse) => {
      completePath = completePath + `${item.nome}/`;
    });

    const completePathFormatted = completePath.substring(
      0,
      completePath.length - 1
    );

    if (completePath !== diretorioRaiz) {
      setFullPath(`${completePathFormatted.replace("/", "")}`);
    }
  };

  const {
    data: diretorioData,
    refetch: refetchDiretorioList,
    isFetching,
    isLoading,
  } = useGetDiretorios(fullPath, pagina, 400, "");

  const {
    data: customTagsData,
    refetch: refetchCustomTags,
  } = useGetTags();

  const {
    data: fileData,
    refetch: refetchFileList,
  } = useGetFiles(1, 800, searchTerm, searchTags);

  const searchingTerm = (search: string) => {
    if (search.length > 0) {
      setSearchTerm(search);
    } else {
      setSearchTerm("");
      setArquivos(diretorioData?.itens as DiretorioData[]);
    }
  };

  const getTags = (): string => {
    let tagsNames: string[] = [];

    currentSafras.forEach((tag) =>
      tagsNames.push(`Safra ${tag.descricao.replace("/", "-")}`)
    );
    currentUnidades.forEach((tag) => tagsNames.push(tag.nome));
    currentCultura.forEach((tag) => tagsNames.push(tag.nome));
    currentSetores.forEach((tag) => tagsNames.push(tag.nome));
    currentTalhao.forEach((tag) => tagsNames.push(tag.nome));
    tagsPersonalizadas.forEach((tag) => tagsNames.push(tag.nome));
    const stringgedArrayTags = tagsNames.join(",");
    return stringgedArrayTags;
  };

  useEffect(() => {
    getFullPath();
  }, [tree]);

  useEffect(() => {
    setSearchTermsLength(searchTerm.length);
    refetchFileList();
  }, [searchTerm, searchTags]);

  useEffect(() => {
    if (fileData?.itens) {
      if (searchTermsLength > 0 || searchTags.length > 0) {
        setArquivos(fileData?.itens);
      } else {
        refetchDiretorioList();
      }
    }
  }, [fileData]);

  useEffect(() => {
    if (diretorioData?.itens && path === diretorioRaiz && !isLoading) {
      if (updating) {
        setPagina(1);
        setUpdating(false);
        return setArquivos([...diretorioData.itens] as DiretorioData[]);
      } else {
        return setArquivos([
          ...arquivos,
          ...diretorioData.itens,
        ] as DiretorioData[]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diretorioData?.itens, path, pagina]);

  useEffect(() => {
    if (!isFetching) {
      setPagina(1);
      setArquivos(diretorioData?.itens!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diretorioData?.itens, path, isFetching, isLoading, pagina]);

  useEffect(() => {
    setSelecteds([]);
  }, [path]);

  useEffect(() => {
    const refinedTags = getTags();
    if (refinedTags.length > 0) {
      setSearchTags(refinedTags);
    } else {
      setSearchTags("");
      refetchDiretorioList();
    }
  }, [
    currentSafras,
    currentUnidades,
    currentCultura,
    currentSetores,
    currentTalhao,
    tagsPersonalizadas,
    setTagsPersonalizadas,
  ]);

  return (
    <AgrodriveContext.Provider
      value={{
        items: {
          currentSafras,
          currentUnidades,
          currentCultura,
          currentSetores,
          currentTalhao,
          tagsPersonalizadas,
          tagsVinculadas,
          tree,
          selecteds,
          diretorioData,
          isLoadingUploadFile: isLoading,
          isLoadingDiretorioList: isLoading,
          isFetchingDiretorios: isFetching,
          diretorioRaiz,
          path,
          fullPath,
          arquivos,
          pagina,
          updating,
          criouUmArquivo,
          tagsHerdadas,
          customTagsData,
          searchTerm,
          searchTags,
        },
        onChange: {
          setCurrentSafras,
          setCurrentUnidades,
          setCurrentCultura,
          setCurrentSetores,
          setCurrentTalhao,
          setTagsPersonalizadas,
          setTagsVinculadas,
          setTree,
          setSelecteds,
          refetchDiretorioList,
          setPath,
          setFullPath,
          setPagina,
          setArquivos,
          setUpdating,
          setCriouUmArquivo,
          setTagsHerdadas,
          searchingTerm,
          refetchCustomTags,
        },
      }}
    >
      {children}
    </AgrodriveContext.Provider>
  );
};
