import { useEffect, useRef, useState } from "react";

interface DataInfoOverlayProps {
  map: google.maps.Map;
  position: google.maps.LatLng;
  info: string;
}

export default function DataInfoOverlay({
  map,
  position,
  info,
}: DataInfoOverlayProps) {
  const [overlayView, setOverlayView] = useState<
    google.maps.OverlayView | undefined
  >();

  const ref = useRef<HTMLDivElement>(document.createElement("div"));

  useEffect(() => {
    if (!overlayView) {
      setOverlayView(new google.maps.OverlayView());
    }

    return () => {
      if (overlayView) {
        overlayView.setMap(null);
      }
    };
  }, [overlayView]);

  useEffect(() => {
    if (overlayView) {
      overlayView.onAdd = () => {
        ref.current.style.margin = "-15px 0 0 -15px";
        ref.current.style.width = "30px";
        ref.current.style.height = "30px";
        ref.current.style.backgroundColor = "#fff";
        ref.current.style.borderRadius = "50%";
        ref.current.style.textAlign = "center";
        ref.current.style.font = "bold 11px/30px sans-serif";
        ref.current.style.position = "absolute";
        ref.current.style.zIndex = "1000";

        ref.current.textContent = info;

        overlayView.getPanes()?.overlayLayer.appendChild(ref.current);
      };

      overlayView.draw = () => {
        const sw = overlayView
          .getProjection()
          .fromLatLngToDivPixel(
            new google.maps.LatLng(position.lat(), position.lng())
          );
        const ne = overlayView
          .getProjection()
          .fromLatLngToDivPixel(
            new google.maps.LatLng(position.lat(), position.lng())
          );
        ref.current.style.left = `${sw?.x}px`;
        ref.current.style.top = `${ne?.y}px`;
      };

      overlayView.onRemove = () => {
        ref.current.parentNode?.removeChild(ref.current);
      };

      overlayView.setMap(map);
    }
  }, [map, position, info, overlayView, ref]);

  return null;
}
