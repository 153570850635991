import { OcupacaoEnum } from "core/features/maps/enums";
import {
  ConcentracaoColheitaTipo,
  FiltroConcentracaoColheita,
} from "core/features/maps/typings";
import create from "zustand";
import { configurePersist } from "zustand-persist";

const { persist } = configurePersist({
  storage: localStorage,
});

const defaultValue: FiltroConcentracaoColheita = {
  tecnologias: [],
  concentracoes: [],
  ocupacao: OcupacaoEnum.SOJA,
  tipo: ConcentracaoColheitaTipo.Colhido,
};

export interface UseFilters {
  filters: FiltroConcentracaoColheita;
  clear: () => void;
  updateField: (prop: keyof FiltroConcentracaoColheita, value: any) => any;
  update: (value: Partial<FiltroConcentracaoColheita>, merge?: boolean) => any;
}

export const useConcentracaoColheitaStore = create<UseFilters>(
  persist(
    {
      key: "@filters-concentracao-colheita-agrosig-v1",
    },
    (set) => ({
      filters: defaultValue,
      clear: () =>
        set(() => ({
          filters: defaultValue,
        })),
      updateField: (prop: keyof FiltroConcentracaoColheita, value: any) => {
        set((state) => {
          return {
            ...state,
            filters: Object.assign(state.filters, {
              [prop]: value,
            }),
          };
        });
      },

      update: (value: Partial<FiltroConcentracaoColheita>, merge = false) => {
        set((state) => {
          return {
            ...state,
            filters: Object.assign(
              state.filters,
              merge
                ? {
                    ...state.filters,
                    ...value,
                  }
                : value
            ),
          };
        });
      },
    })
  )
);
