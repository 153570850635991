import { Flex } from "@chakra-ui/layout";
import { Button, Icon } from "@chakra-ui/react";
import { IoMdCheckmark, IoMdAdd } from "react-icons/io";

import { gray800, green700 } from "core/resources/theme/colors";
import { useMemo } from "react";

type Item = { id: string; nome: string; enabled?: boolean };

interface SelectedButtonsProps {
  onChange: (v?: any) => void;
  items: Item[];
  value: string[];
  disabledItems?: string[];
  isMultipleChanges?: boolean;
}

const SelectedButtons = ({
  items,
  value,
  onChange,
  disabledItems,
  isMultipleChanges,
}: SelectedButtonsProps) => {
  const _items = useMemo(
    () =>
      items?.filter(
        (item) => item.id !== null && !disabledItems?.includes(item.id)
      ) ?? [],
    [items, disabledItems]
  );

  const isChecked = (item: Item) =>
    value?.includes(item.id) && !!item.id && !disabledItems?.includes(item.id);

  const totalSelecteds = _items.reduce((agg, item) => {
    if (isChecked(item)) {
      return agg + 1;
    }
    return agg;
  }, 0);

  const isSelectedAll = totalSelecteds >= _items.length;
  const firstEnabledPeriodo = items?.find((periodo) => periodo !== undefined);

  return (
    <Flex flexWrap="wrap" alignItems="center" mt={3} flexDir="row">
      <Button
        key={1}
        disabled={!firstEnabledPeriodo}
        justifyContent="space-around"
        alignItems="center"
        _hover={{
          backgroundColor: "none",
        }}
        ml={2}
        onClick={() => {
          if (isMultipleChanges) {
            onChange(items.map((item) => item.id));
          } else {
            onChange();
          }
        }}
        mt={2}
        color={isSelectedAll && firstEnabledPeriodo ? "white" : gray800}
        h="38px"
        bg={isSelectedAll && firstEnabledPeriodo ? green700 : "white"}
        border={`1px solid ${gray800}`}
        rounded={30}
      >
        Todos
        <Icon
          as={isSelectedAll && firstEnabledPeriodo ? IoMdCheckmark : IoMdAdd}
          ml={2}
          boxSize={6}
          color={isSelectedAll && firstEnabledPeriodo ? "white" : gray800}
        />
      </Button>
      {items?.map((item, index) => (
        <Button
          key={item.id + index}
          disabled={!item.id || disabledItems?.includes(item.id)}
          justifyContent="space-around"
          alignItems="center"
          _hover={{
            backgroundColor: "none",
          }}
          ml={2}
          onClick={() => {
            if (item.id) {
              if (isMultipleChanges) {
                if (isChecked(item)) {
                  if (value.length > 1) {
                    onChange(value.filter((_v) => _v !== item.id));
                  }
                } else {
                  onChange([...value, item.id]);
                }
              } else {
                onChange(item.id);
              }
            }
          }}
          mt={2}
          color={isChecked(item) ? "white" : gray800}
          h="38px"
          bg={isChecked(item) ? green700 : "white"}
          border={`1px solid ${gray800}`}
          rounded={30}
        >
          {item.nome}
          <Icon
            as={isChecked(item) ? IoMdCheckmark : IoMdAdd}
            ml={2}
            boxSize={6}
            color={isChecked(item) ? "white" : gray800}
          />
        </Button>
      ))}
    </Flex>
  );
};

export default SelectedButtons;
