import api from "../utils/api";
import { useQuery } from "react-query";
import URL from "core/resources/urls";

export function useGetPrecipitacao(t: string) {
  return useQuery(
    [t, URL.PRECIPITACAO.OBTER],
    async () => {
      const { data } = await api.get(URL.PRECIPITACAO.OBTER(t));

      return data;
    }
  );
}
