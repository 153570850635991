import SelectedButtons from "../../../SelectedButtons";
import useProdutividadeTecnologiasFilters from "../hooks/useProdutividadeTecnologiasFilters";

function ProdutividadeTecnologiasFilter() {
  const { updateTecnologiasSelected, valueTecnologias, tecnologias } =
    useProdutividadeTecnologiasFilters();

  return (
    <SelectedButtons
      value={valueTecnologias}
      items={tecnologias}
      onChange={updateTecnologiasSelected}
    />
  );
}

export default ProdutividadeTecnologiasFilter;
