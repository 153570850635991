import { useQuery } from "react-query";
import http from "infra/http";
import URL from "core/resources/urls";

export function useGetRegional() {
  return useQuery(
    [URL.UNIDADES.REGIONAL],
    async () => {
      const { data } = await http.get(URL.UNIDADES.REGIONAL);

      return data;
    }
  );
}
