import * as _ from "lodash";

import {
  FiltroDistribuicaoVarietal,
  KeyItemsOcupacoes,
  Ocupacao,
  TipoMapaTalhoes,
} from "features/maps/typings";
import { LIST_TYPES_MAPS, MapFilterIndex } from "features/maps/data";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Box, useDisclosure } from "@chakra-ui/react";
import ConcentracaoColheita from "./items/concentracao-colheita";
import FilterHeader from "./header";
import { FilterIndex } from "./enum";
import FiltersIdadeDoTalhao from "./items/idade-do-talhao";
import FiltersMapaTematico from "./items/mapa-tematico";
import FiltersNumeroDeAplicacoes from "./items/numero-de-aplicacoes";
import FiltersOcupacao from "./items/ocupacao";
import FiltersOperacoesExecutadas from "./items/operacoes-executadas";
import FiltersProdutividade from "./items/produtividade";
import FiltersSafra from "./items/safra";
import FiltersTexturaSolo from "./items/textura-solo";
import FiltersUnidade from "./items/unidade";
import FiltroOrdensDeServicoAberta from "./items/ordens-de-servico-aberta";
import { OcupacaoEnum } from "features/maps/enums";
import SatelliteMenu from "./items/SatelliteMenu";
import { eventLogger } from "infra/events";
import { getIndexMapaTematicoForTipoMapa } from "ui/pages/app/home/util";
import { getOcupacoesItemsInFiltro } from "./items/ocupacao/helper";
import { mixpanel } from "infra/packages/react-mixpanel";
import { useAuth } from "features/auth/store";
import { useFilters } from "features/filters/store";
import { useFiltersLists } from "features/filters/store/lists";
import { useMenuFilterIndex } from "features/filters/hooks/useMenuFilterIndex";
import FiltroDistribuicaoDeEquipes from "./items/distribuição-de-equipes";
import FiltroMapaDeChuva from "./items/mapa-de-chuva";
import { CenterMapContext } from "core/features/maps/center-map/CenterMap.context";
import DetailFromTalhoes from "./DetailFromTalhoes";

function FilterSafraUnidadeMapaTematico() {
  const lastOcupacoesRef = useRef<Ocupacao[] | undefined>();
  const { filters, updateField, updateFiltroOcupacao, clearFiltroOcupacao } =
    useFilters();
  const { ocupacoes, unidades, safras, loadings } = useFiltersLists();
  const { handleChangeShouldForceCenter } = useContext(CenterMapContext);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const {
    isOpen: isGridOpen,
    onClose: onCloseGrid,
    onOpen: onOpenGrid,
  } = useDisclosure();
  const { user } = useAuth();
  const { unidade, tipoMapa } = filters;

  const _ocupacoes = useMemo(
    () =>
      ocupacoes.map((ocupacao) => ({
        ...ocupacao,
        id: ocupacao.codigo,
      })),
    [ocupacoes]
  );

  const { indexOpen, setIndexOpen } = useMenuFilterIndex();

  const handleOpen = useCallback(
    (index: number) => {
      const currentIndexOpen = useMenuFilterIndex.getState().indexOpen;
      const _index =
        currentIndexOpen === index
          ? getIndexMapaTematicoForTipoMapa(tipoMapa)
          : index;

      setIndexOpen(_index);
    },
    [setIndexOpen, tipoMapa]
  );

  const filtroSomenteOcupacoes = useMemo(
    () => ({
      finalidades: [],
      tecnologias: [],
      variedades: [],
      variedadeGrupos: [],
      ocupacoes: _ocupacoes,
    }),
    [_ocupacoes]
  );

  useEffect(() => {
    if (
      lastOcupacoesRef.current &&
      !_.isEqual(lastOcupacoesRef.current, ocupacoes)
    ) {
      const oldFiltro =
        useFilters.getState().filters.filtroDistribuicaoVarietal;

      const newFiltro = getOcupacoesItemsInFiltro(ocupacoes, oldFiltro);

      updateFiltroOcupacao(newFiltro);
    }
    lastOcupacoesRef.current = ocupacoes;
  }, [filtroSomenteOcupacoes, ocupacoes, updateFiltroOcupacao]);

  useEffect(() => {
    if (ocupacoes.length && !filters.filtroDistribuicaoVarietal) {
      updateFiltroOcupacao(filtroSomenteOcupacoes);
    }
  }, [
    ocupacoes.length,
    filters.filtroDistribuicaoVarietal,
    updateFiltroOcupacao,
    filtroSomenteOcupacoes,
  ]);

  return (
    <Box pos="relative" maxW="430px" w="100%" shadow="2xl">
      <FilterHeader
        indexOpen={indexOpen}
        onToggle={(index) => handleOpen(index)}
        isMenuVisible={isVisible}
        handleOpenMenu={() => setIsVisible(true)}
      />

      {indexOpen === FilterIndex.Mapa_Tematico ? (
        <FiltersMapaTematico
          data={LIST_TYPES_MAPS}
          onClick={(item) => {
            mixpanel.track(
              `MapaTematico_${item.nome}`,
              eventLogger(user?.email as string)
            );

            if (item.nome === "Concentração de Colheita") {
              handleChangeShouldForceCenter(true);
            }
            if (
              item.filterMenuIndex ===
                MapFilterIndex.ORDENS_DE_SERVICO_ABERTA ||
              item.filterMenuIndex === MapFilterIndex.DISTRIBUICAO_DE_EQUIPES ||
              (item.filterMenuIndex === MapFilterIndex.TEXTURA_SOLO &&
                !!safras?.length) ||
              (item.filterMenuIndex === MapFilterIndex.IDADE_DO_TALHAO &&
                !!safras.length) ||
              item.filterMenuIndex === MapFilterIndex.CHUVAS
            ) {
              updateField(
                "safra",
                safras.find((item) => {
                  return item.isSafraAtual;
                })
              );
            }
            setIndexOpen(item.filterMenuIndex);
          }}
        />
      ) : null}
      {indexOpen === FilterIndex.Concentracao_Colheita ? (
        <ConcentracaoColheita
          handleOpenGrid={onOpenGrid}
          handleChangeIsVisible={setIsVisible}
          isVisible={isVisible}
        />
      ) : null}

      {indexOpen === FilterIndex.Textura_Solo ? (
        <FiltersTexturaSolo
          handleChangeIsVisible={setIsVisible}
          isVisible={isVisible}
        />
      ) : null}
      {indexOpen === FilterIndex.Operacoes_Executadas ? (
        <FiltersOperacoesExecutadas
          handleOpenGrid={onOpenGrid}
          handleChangeIsVisible={setIsVisible}
          isVisible={isVisible}
        />
      ) : null}
      {indexOpen === FilterIndex.Produtividade ? (
        <FiltersProdutividade
          handleChangeIsVisible={setIsVisible}
          isVisible={isVisible}
          handleOpenGrid={onOpenGrid}
        />
      ) : null}
      {indexOpen === FilterIndex.Numero_De_Aplicacoes ? (
        <FiltersNumeroDeAplicacoes
          handleOpenGrid={onOpenGrid}
          handleChangeIsVisible={setIsVisible}
          isVisible={isVisible}
        />
      ) : null}

      {indexOpen === FilterIndex.Idade_Do_Talhao ? (
        <FiltersIdadeDoTalhao
          handleOpenGrid={onOpenGrid}
          handleChangeIsVisible={setIsVisible}
          isVisible={isVisible}
        />
      ) : null}
      {indexOpen === FilterIndex.Ordens_De_Servicos_Abertas ? (
        <FiltroOrdensDeServicoAberta
          handleOpenGrid={onOpenGrid}
          handleChangeIsVisible={setIsVisible}
          isVisible={isVisible}
        />
      ) : null}

      {indexOpen === FilterIndex.Distribuicao_de_equipes ? (
        <FiltroDistribuicaoDeEquipes
          handleOpenGrid={onOpenGrid}
          handleChangeIsVisible={setIsVisible}
          isVisible={isVisible}
        />
      ) : null}

      {indexOpen === FilterIndex.Chuvas ? (
        <FiltroMapaDeChuva
          handleChangeIsVisible={setIsVisible}
          isVisible={isVisible}
        />
      ) : null}

      {indexOpen === FilterIndex.Ocupacao ? (
        <FiltersOcupacao
          handleOpenGrid={onOpenGrid}
          handleChangeIsVisible={setIsVisible}
          isVisible={isVisible}
          data={_ocupacoes}
          isLoading={loadings.ocupacoes}
          filtro={filters.filtroDistribuicaoVarietal}
          onClick={(items, keyItem, isChecked) => {
            const newFiltro: Partial<FiltroDistribuicaoVarietal> =
              filters.filtroDistribuicaoVarietal
                ? filters.filtroDistribuicaoVarietal
                : {
                    finalidades: [],
                    ocupacoes: [],
                    tecnologias: [],
                    variedadeGrupos: [],
                    variedades: [],
                  };

            if (isChecked) {
              const itemsNotInFilter = items.filter(
                (_item) =>
                  !(filters.filtroDistribuicaoVarietal?.[keyItem] as any)?.find(
                    (itemFiltro: any) => _.isEqual(_item, itemFiltro)
                  )
              );

              newFiltro[keyItem] = filters.filtroDistribuicaoVarietal
                ? [
                    ...filters.filtroDistribuicaoVarietal?.[keyItem],
                    ...itemsNotInFilter,
                  ]
                : [...(items as any)];

              const castItems = items as {
                id: string;
                codigo?: OcupacaoEnum;
                ocupacaoCodigo?: OcupacaoEnum;
                finalidadeId?: string;
                tecnologiaId?: string;
                variedadeGrupoId?: string;
              }[];

              castItems.forEach((castItem) => {
                newFiltro.ocupacoes = newFiltro.ocupacoes?.filter(
                  (ocupacao) => {
                    if (!!castItem.ocupacaoCodigo) {
                      return ocupacao.codigo !== castItem.ocupacaoCodigo;
                    } else if (keyItem === KeyItemsOcupacoes.ocupacoes) {
                      return true;
                    }
                    return true;
                  }
                );

                newFiltro.finalidades = newFiltro.finalidades?.filter(
                  (finalidade) => {
                    if (!!castItem.finalidadeId) {
                      return (
                        finalidade.id !== castItem.finalidadeId ||
                        finalidade.ocupacaoCodigo !== castItem.ocupacaoCodigo
                      );
                    } else if (keyItem === KeyItemsOcupacoes.finalidades) {
                      return true;
                    } else if (keyItem === KeyItemsOcupacoes.ocupacoes) {
                      return finalidade.ocupacaoCodigo !== castItem.codigo;
                    }
                    return true;
                  }
                );

                newFiltro.tecnologias = newFiltro.tecnologias?.filter(
                  (tecnologia) => {
                    if (!!castItem.tecnologiaId) {
                      return (
                        tecnologia.id !== castItem.tecnologiaId ||
                        tecnologia.finalidadeId !== castItem.finalidadeId ||
                        tecnologia.ocupacaoCodigo !== castItem.ocupacaoCodigo
                      );
                    } else if (keyItem === KeyItemsOcupacoes.tecnologias) {
                      return true;
                    } else if (keyItem === KeyItemsOcupacoes.finalidades) {
                      return tecnologia.finalidadeId !== castItem.id;
                    } else if (keyItem === KeyItemsOcupacoes.ocupacoes) {
                      return tecnologia.ocupacaoCodigo !== castItem.codigo;
                    }
                    return true;
                  }
                );

                newFiltro.variedadeGrupos = newFiltro.variedadeGrupos?.filter(
                  (variedadeGrupo) => {
                    if (!!castItem.tecnologiaId) {
                      return (
                        variedadeGrupo.id !== castItem.variedadeGrupoId ||
                        variedadeGrupo.finalidadeId !== castItem.finalidadeId ||
                        variedadeGrupo.ocupacaoCodigo !==
                          castItem.ocupacaoCodigo ||
                        variedadeGrupo.tecnologiaId !== castItem.tecnologiaId
                      );
                    } else if (keyItem === KeyItemsOcupacoes.variedadeGrupos) {
                      return true;
                    } else if (keyItem === KeyItemsOcupacoes.finalidades) {
                      return variedadeGrupo.finalidadeId !== castItem.id;
                    } else if (keyItem === KeyItemsOcupacoes.tecnologias) {
                      return variedadeGrupo.tecnologiaId !== castItem.id;
                    } else if (keyItem === KeyItemsOcupacoes.ocupacoes) {
                      return variedadeGrupo.ocupacaoCodigo !== castItem.codigo;
                    }
                    return true;
                  }
                );

                newFiltro.variedades = newFiltro.variedades?.filter(
                  (variedade) => {
                    if (keyItem === KeyItemsOcupacoes.ocupacoes) {
                      return variedade.ocupacaoCodigo !== castItem.codigo;
                    } else if (keyItem === KeyItemsOcupacoes.finalidades) {
                      return variedade.finalidadeId !== castItem.id;
                    } else if (keyItem === KeyItemsOcupacoes.tecnologias) {
                      return variedade.tecnologiaId !== castItem.id;
                    } else if (keyItem === KeyItemsOcupacoes.variedadeGrupos) {
                      return variedade.variedadeGrupoId !== castItem.id;
                    } else if (keyItem === KeyItemsOcupacoes.variedades) {
                      return true;
                    }
                    return true;
                  }
                );
              });
            } else {
              newFiltro[keyItem] = filters.filtroDistribuicaoVarietal
                ? (filters.filtroDistribuicaoVarietal[keyItem] as any).filter(
                    (_item: any) =>
                      !items.find((removedItem) =>
                        _.isEqual(removedItem, _item)
                      )
                  )
                : [];
            }

            updateFiltroOcupacao(newFiltro, true);
          }}
          onClickTodosOcupacoes={(isChecked) => {
            if (isChecked) {
              updateFiltroOcupacao(
                {
                  finalidades: [],
                  tecnologias: [],
                  variedades: [],
                  variedadeGrupos: [],
                  ocupacoes: ocupacoes.map((ocupacao) => ({
                    ...ocupacao,
                    id: ocupacao.codigo,
                  })),
                },
                true
              );
            } else {
              clearFiltroOcupacao();
            }
          }}
          onClear={clearFiltroOcupacao}
        />
      ) : null}
      {indexOpen === FilterIndex.Unidade ? (
        <FiltersUnidade
          unidadeSelected={unidade || undefined}
          data={unidades}
          onClick={(item) => {
            handleChangeShouldForceCenter(true);
            updateField("setor", null);
            updateField("unidade", item);
            setIndexOpen(getIndexMapaTematicoForTipoMapa(tipoMapa));
            mixpanel.track(
              `Unidade_${item.nome}`,
              eventLogger(user?.email as string)
            );
          }}
        />
      ) : null}
      {indexOpen === FilterIndex.Safra ? (
        <FiltersSafra
          data={safras}
          onClick={(item) => {
            updateField("setor", null);
            updateField("safra", item);

            if (tipoMapa === TipoMapaTalhoes.OperacoesExecutadas) {
              setIndexOpen(FilterIndex.Operacoes_Executadas);
            } else if (tipoMapa === TipoMapaTalhoes.Satelite) {
              setIndexOpen(FilterIndex.Imagens_Satelite);
            } else if (tipoMapa === TipoMapaTalhoes.NumeroDeAplicacoes) {
              setIndexOpen(FilterIndex.Numero_De_Aplicacoes);
            } else if (tipoMapa === TipoMapaTalhoes.Produtividade) {
              setIndexOpen(FilterIndex.Produtividade);
            } else if (tipoMapa === TipoMapaTalhoes.ConcentracaoColheita) {
              setIndexOpen(FilterIndex.Concentracao_Colheita);
            } else {
              setIndexOpen(FilterIndex.Ocupacao);
            }
          }}
        />
      ) : null}
      <SatelliteMenu
        indexOpen={indexOpen}
        unit={filters.unidade ?? undefined}
        initialDate={filters.safra?.periodoPluviometricoInicio}
        finalDate={filters.safra?.periodoPluviometricoFim}
        isVisible={isVisible}
        handleChangeIsVisible={() => setIsVisible(false)}
      />

      <DetailFromTalhoes isOpen={isGridOpen} onClose={onCloseGrid} />
    </Box>
  );
}

export default FilterSafraUnidadeMapaTematico;
