import { useLocation } from "react-router-dom";
import { LoadScript } from "@react-google-maps/api";
import { Flex, Spinner } from "@chakra-ui/react";
import BaseMap from "components/maps/base-map";
import { CenterMapContextProvider } from "core/features/maps/center-map/CenterMap.context";
import { useGetPrecipitacao } from "core/features/precipitacao/hooks/useGetPrecipitacao";
import { useGetConfigurations } from "core/features/precipitacao/hooks/useGetConfigurations";
import { GeometryType } from "components/maps/base-map/typings";

export default function PrecipitacaoPage() {
  const { search } = useLocation();
  const t = search?.split("=")[1];

  const decodedString = atob(t).split("|");
  const talhoes = decodedString[4].split(",");
  const precipitacao = decodedString[5];
  
  const { data: envs, isFetching: isEnvsFetching } = useGetConfigurations();
  const { data: precipitacaoData, isFetching: isPrecipitacaoFetching } = useGetPrecipitacao(t);

  const points =
    precipitacaoData?.features.filter(
      (feature: any) => feature.geometry.type === GeometryType.Point
    ) || [];

  const center = {
    lat: points[0]?.geometry.coordinates[1],
    long: points[0]?.geometry.coordinates[0],
  }

  return (
    <>
      {envs?.react_app_maps_key && precipitacaoData && center ? (
        <LoadScript googleMapsApiKey={envs?.react_app_maps_key}>
          <CenterMapContextProvider>
            <Flex
              justifyContent="center"
              alignItems="center"
              w="100%"
              h="100vh"
            >
              <BaseMap
                points={precipitacaoData}
                talhoes={talhoes}
                precipitacao={precipitacao}
                isLoading={isEnvsFetching || isPrecipitacaoFetching}
                center={center}
              >{null}</BaseMap>
            </Flex>
          </CenterMapContextProvider>
        </LoadScript>
      ) : (
        <Flex
          justifyContent="center"
          alignItems="center"
          w="100%"
          h="100vh"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#009540"
            size="xl"
          />
        </Flex>
      )}
    </>
  );
}
