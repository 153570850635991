import { ENDPOINTS, download } from "infra/http/farmApi";
import { UseQueryOptions, useQuery } from "react-query";

export default function useImageRouteDownload(imageId: string | number, params: any = {}, options: UseQueryOptions = {}) {
    const queryOptions: UseQueryOptions = {
        ...options,
    }

    const endpoint = ENDPOINTS.IMAGE_ROUTE_DOWNLOAD.replace(/:image_id/gi, imageId.toString());

    return useQuery<any>(
        [ENDPOINTS.IMAGE_ROUTE_DOWNLOAD, imageId, params],
        () => download(endpoint, params, { responseType: "blob" }),
        queryOptions
    )
}
