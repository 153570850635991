import { Text, Flex } from "@chakra-ui/react";
import { AgrodriveContext } from "core/features/agrodrive/contexts/Agrodrive.context";
import { useContext } from "react";
import {
  ArquivoResponse,
  DiretorioData,
  DiretorioResponse,
} from "../../types/arquivos";
import { MainContainer } from "./styles";
import DatatableAntd from "components/datatable-antd";
import { getIcon } from "../AgroTable/utils";
import { gray800 } from "core/resources/theme/colors";

interface SimpleTableProps {
  items: DiretorioData[] | undefined;
  changeDir: (item: DiretorioData) => void;
  loading: boolean;
}

const BreadcrumbAgrodrive = ({
  items,
  changeDir,
  loading,
}: SimpleTableProps) => {
  return (
    <MainContainer>
      <DatatableAntd
        columns={[
          {
            title: "NOME",
            render: (value: ArquivoResponse) => {
              return (
                <Flex
                  flex={1}
                  gridGap="6px"
                  alignItems="center"
                  onClick={() => {
                    changeDir(value);
                  }}
                  opacity={value.tipo !== "Folder" ? 0.5 : 1}
                >
                  <Flex>{getIcon(value)}</Flex>
                  <Flex
                    color={gray800}
                    cursor="pointer"
                    display="inline-block"
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                  >
                    {value.nome}
                  </Flex>
                </Flex>
              );
            },
            width: 200,
          },
        ]}
        loading={loading}
        dataSource={items}
        scroll={{ y: "260px" }}
        onLoad={() => {
          console.log("load");
        }}
        style={{ height: "300px" }}
      />
    </MainContainer>
  );
};

export default BreadcrumbAgrodrive;
