import styled from "styled-components";

import FilterSafraUnidadeMapaTematico from "components/inputs/filter-safra-unidade-mapatematico";
import { Flex } from "@chakra-ui/layout";

import SearchInput from "components/maps/base-map/components/SearchInput";
import AdvancedFilters from "components/maps/base-map/components/advancedFilters";
import { useFilters } from "core/features/filters/store";
import { TipoMapaTalhoes } from "core/features/maps/typings";
import { green700 } from "core/resources/theme/colors";

type AdvancedFilterEnabledOnMapsType = {
  tipoMapa: TipoMapaTalhoes;
};

const Container = styled(Flex)`
  z-index: 1011;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 16px;
  padding-left: 0;
  height: 60px;
  .section-edit-profile:hover {
    .text-edit-profile {
      color: ${green700};
    }
    .icon-edit-profile {
      color: ${green700};
    }
  }
`;

function Appbar() {
  const { filters } = useFilters();
  const advancedFilterEnabledOnMaps: AdvancedFilterEnabledOnMapsType[] = [
    {
      tipoMapa: TipoMapaTalhoes.DistribuicaoVarietal,
    },
    {
      tipoMapa: TipoMapaTalhoes.Chuvas,
    },
    {
      tipoMapa: TipoMapaTalhoes.OperacoesExecutadas,
    },
  ];

  const actualAdvancedFilter = advancedFilterEnabledOnMaps.find(
    (maps) => maps.tipoMapa === filters.tipoMapa
  );

  return (
    <Container>
      <Flex flex={1} marginLeft="107px">
        <FilterSafraUnidadeMapaTematico />
        {actualAdvancedFilter ? <AdvancedFilters /> : null}
      </Flex>

      <Flex>
        <SearchInput />
      </Flex>
    </Container>
  );
}

export default Appbar;
