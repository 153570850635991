import { Button, Text } from "@chakra-ui/react";
import { ImTable } from "react-icons/im";

interface MapGridButtonProps {
  handleOpenGrid: () => void;
}

const MapGridButton = ({ handleOpenGrid }: MapGridButtonProps) => {
  return (
    <Button
      onClick={handleOpenGrid}
      alignItems="center"
      background="none"
      border="1px solid #838997"
      borderRadius="10px"
      _active={{}}
      _focus={{}}
    >
      <ImTable color="#838997" />
      <Text fontSize="16px" marginLeft="7px" color="#838997">
        Talhões em GRID
      </Text>
    </Button>
  );
};

export default MapGridButton;
