import React, { useContext, useEffect, useState } from "react";
import * as S from "./style";
import { ImportTalhoesContext } from "core/features/talhoes/contexts/ImportTalhoes.context";
import { Text } from "@chakra-ui/react";

enum LinePositionEnum {
  first = 50,
  second = 25,
  third = 0,
}

const Steps = () => {
  const [currentLinePosition, setCurrentLinePosition] = useState<number>(50);
  const { step } = useContext(ImportTalhoesContext);

  useEffect(() => {
    if (step === 1) {
      setCurrentLinePosition(50);
    }

    if (step === 2) {
      setCurrentLinePosition(25);
    }

    if (step === 3) {
      setCurrentLinePosition(0);
    }
  }, [step]);

  return (
    <S.Container>
      <S.BackLine postionInVW={-currentLinePosition}></S.BackLine>
      <S.Step active={currentLinePosition <= LinePositionEnum.first}>
        <Text
          position="absolute"
          bottom="-35px"
          fontWeight="medium"
          color="#11A557"
          fontSize="18px"
          width="200px"
          left={"-55px"}
          opacity={currentLinePosition === LinePositionEnum.first ? 1 : 0}
          transition="1.5s"
        >
          Importar Arquivo
        </Text>
      </S.Step>
      <S.Step active={currentLinePosition <= LinePositionEnum.second}>
        <Text
          position="absolute"
          bottom="-35px"
          fontWeight="medium"
          color="#11A557"
          fontSize="18px"
          width="200px"
          left={"-55px"}
          opacity={currentLinePosition === LinePositionEnum.second ? 1 : 0}
          transition="all 1.5s"
        >
          Importar Arquivo
        </Text>
      </S.Step>
      <S.Step active={currentLinePosition === LinePositionEnum.third}>
        <Text
          position="absolute"
          bottom="-35px"
          fontWeight="medium"
          color="#11A557"
          fontSize="18px"
          width="200px"
          left={"-40px"}
          opacity={currentLinePosition === LinePositionEnum.third ? 1 : 0}
          transition="all 1.5s"
        >
          Confirmação
        </Text>
      </S.Step>
    </S.Container>
  );
};

export default Steps;
