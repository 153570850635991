import { Box, Flex } from "@chakra-ui/layout";
import { Safra } from "features/maps/typings";

function SafraItem({
  item,
  value,
  onClick,
}: {
  item: Safra;
  value: Safra | null;
  onClick: () => void;
}) {
  const isSafraSelected = item.codigo === value?.codigo;

  return (
    <Box
      onClick={onClick}
      data-testid={`menu-filtros-safras-${item.descricao}`}
      borderTop="1px solid #E5E5E5"
      borderBottom="1px solid #E5E5E5"
      borderRight="1px solid #E5E5E5"
      borderLeft={isSafraSelected ? "4px solid green" : "1px solid #E5E5E5"}
    >
      <Flex
        flex={1}
        h="100px"
        alignItems="center"
        transition=".1s linear all"
        justifyContent="center"
        cursor="pointer"
        bg={isSafraSelected ? "gray.200" : "white"}
        _hover={{
          bgColor: "gray.100",
        }}
      >
        {item.descricao}
      </Flex>
    </Box>
  );
}

export default SafraItem;
