import { Flex, IconButton, Text } from "@chakra-ui/react";
import { InputHTMLAttributes } from "react";
import { Container, Input } from "./styles";
import { IconType } from "react-icons";
import { MdClose } from "react-icons/md";

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  Icon?: IconType;
  rounded?: boolean;
  required?: boolean;
  error?: string;
  clearFn?: () => void;
}

const TextInput = ({
  label,
  Icon,
  rounded,
  disabled,
  required,
  error,
  clearFn,
  ...rest
}: TextInputProps) => {
  return (
    <>
      <Flex alignItems="center">
        <Container rounded={rounded} disabled={disabled}>
          <Flex
            alignItems="center"
            paddingRight="11px"
            height={"100%"}
            borderRadius={rounded ? 14 : 7}
            width="100%"
          >
            <Input {...rest} rounded={rounded} disabled={disabled} />
            {Icon && (
              <IconButton
                icon={rest.value ? <MdClose /> : <Icon />}
                aria-label="button-search"
                _focus={{ border: 0 }}
                _hover={{ background: "none" }}
                _active={{
                  background: "none",
                }}
                background={"transparent"}
                onClick={clearFn}
              />
            )}
          </Flex>
        </Container>
        {required && (
          <Text marginLeft={"10px"} color="red.600" size="16px">
            *
          </Text>
        )}
      </Flex>
      {error && (
        <Text
          color="#E84336"
          fontSize={"12px"}
          marginBottom={"6px"}
        >
          {error}
        </Text>
      )}
    </>
  );
};

export default TextInput;
