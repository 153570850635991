import useTalhaoOrdensServico from "features/maps/hooks/useTalhaoOrdensServico";
import { CustomFeature, TipoMapaTalhoes } from "features/maps/typings";
import { useCallback, useContext, useEffect, useState, useMemo } from "react";

import { TalhaoDetailContext } from "features/maps/talhao/TalhaoDetailContext";
import { TalhaoOrdensServico } from "features/maps/typings/ordensServico";
import { useFilters } from "features/filters/store";

interface TabOrdensServicoProps {
  talhao: CustomFeature;
  active: boolean;
}

export const useTabOrdensServico = ({
  talhao,
  active,
}: TabOrdensServicoProps) => {
  const { filters } = useFilters();

  const { filtroOrdensDeServicosAbertas, tipoMapa } = filters;
  const [pagination, setPagination] = useState({ page: 1, itemCount: 1 });
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const {
    ordensDeServicoFilterIndex,
    handleChangeOrdensDeServicoFilterIndex,
    ordensDeServicoNumeroOs,
    handleOrdensDeServicoNumeroOs,
  } = useContext(TalhaoDetailContext);

  const { data, isLoading, isFetching, fetchNextPage } = useTalhaoOrdensServico(
    {
      talhaoId: active ? talhao.id : null,
      status: ordensDeServicoFilterIndex,
      numeroOs: ordensDeServicoNumeroOs,
    }
  );

  const items = useMemo(() => {
    const pages = data?.pages ?? [];

    const result = pages.reduce(
      (agg: TalhaoOrdensServico[], item) => agg.concat(item.ordens ?? []),
      []
    );

    result.forEach((item) => {
      (item as any).key = item.ordemDeServicoId;
    });

    return result;
  }, [data]);

  const paginate = data?.pages[0]?.paginate;

  const resetPagination = useCallback(() => {
    setPagination({ page: 1, itemCount: 100 });
  }, []);

  useEffect(() => {
    if (paginate) {
      const totalPages = Math.ceil(paginate.totalCount / 10);
      setPagination((_pagination) => ({
        page: totalPages < paginate.currentPage ? 1 : paginate.currentPage,
        itemCount: paginate.totalCount,
      }));
    } else if (!isLoading) {
      resetPagination();
    }
  }, [isLoading, paginate, resetPagination]);

  const _isLoading = isFetching;

  const nextPage = () => {
    const lastPage = data?.pages[data.pages.length - 1];

    if (!lastPage) {
      return;
    }

    const totalPages =
      lastPage.paginate.totalCount / data?.pages[0].paginate.resultsPerPage;

    const hasNextPage = totalPages > data?.pages.length!;

    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  };

  const isActive = useCallback(
    (item: TalhaoOrdensServico) => {
      if (
        tipoMapa !== TipoMapaTalhoes.OrdensDeServicosAberta &&
        tipoMapa !== TipoMapaTalhoes.DistribuicaoDeEquipes
      ) {
        return false;
      }

      if (tipoMapa === TipoMapaTalhoes.DistribuicaoDeEquipes) {
        const { equipe } = item;
        return equipe === filters.filtroDistribuicaoDeEquipes?.equipe.name;
      }

      return !!filtroOrdensDeServicosAbertas?.operacoes.find(
        (op) => op.descricao === item.descricao
      );
    },
    [
      filtroOrdensDeServicosAbertas?.operacoes,
      tipoMapa,
      filters.filtroDistribuicaoDeEquipes?.equipe.name,
    ]
  );

  const selectRow = (ordemServicoId: string) => {
    if (expandedRows.includes(ordemServicoId)) {
      setExpandedRows((prev) => prev.filter((p) => p !== ordemServicoId));

      return;
    }
    setExpandedRows((prev) => [...prev, ordemServicoId]);
  };

  return {
    _isLoading,
    handleChangeOrdensDeServicoFilterIndex,
    handleOrdensDeServicoNumeroOs,
    isActive,
    items,
    nextPage,
    pagination,
    selectRow,
    expandedRows,
    ordensDeServicoFilterIndex,
    data,
  };
};
