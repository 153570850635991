import { DateRange } from "@material-ui/pickers";
import { useFilters } from "core/features/filters/store";
import { useFiltersLists } from "core/features/filters/store/lists";
import { parseISO, format } from "date-fns";
import { useState } from "react";

export interface AdvancedFiltersOperacoesExecutadas {
  equipeIds?: {
    value?: string;
    label?: string;
  };
  insumos?: {
    insumoId: string;
    tipoApontamento: number;
  }[];
  periodoOperacao?: DateRange<Date>;
}

const useAdvancedFiltersOperacoesExecutadas = () => {
  const { updateFiltroOperacoesExecutadas, filters } = useFilters();
  const { operacoesExecutadas } = useFiltersLists();

  const { filtroOperacoesExecutadas } = filters;

  const filterPeriodo = [
    filtroOperacoesExecutadas?.periodoOperacao?.dataInicial
      ? (parseISO(
          filtroOperacoesExecutadas?.periodoOperacao?.dataInicial
        ) as Date)
      : null,

    filtroOperacoesExecutadas?.periodoOperacao?.dataFinal
      ? (parseISO(
          filtroOperacoesExecutadas?.periodoOperacao?.dataFinal
        ) as Date)
      : null,
  ] as DateRange<Date>;

  const equipeIds = filtroOperacoesExecutadas?.equipeIds;
  const insumos = filtroOperacoesExecutadas?.insumos;

  const findEquipeId = operacoesExecutadas?.filtrosAvancados.equipes.find(
    (equipe) => equipe.id === equipeIds?.[0]
  );

  const equipe = {
    label: findEquipeId?.nome || "",
    value: findEquipeId?.id || "",
  };

  const [equipeId, setEquipeId] = useState(equipe);
  const [advancedFilters, setAdvancedFilters] =
    useState<DateRange<Date>>(filterPeriodo);
  const [selected, setSelected] = useState(insumos);

  const applyFilters = (
    advancedFilters: AdvancedFiltersOperacoesExecutadas,
    handleCloseModal: () => void
  ) => {
    if (
      !advancedFilters.equipeIds?.value &&
      !advancedFilters.insumos?.length &&
      !advancedFilters.periodoOperacao?.[0] &&
      !advancedFilters.periodoOperacao?.[1]
    ) {
      return;
    }
    const equipes = advancedFilters.equipeIds;

    const periodoPlantioToFilter = {
      dataInicial: advancedFilters?.periodoOperacao?.[0]
        ? format(advancedFilters?.periodoOperacao?.[0], "yyyy-MM-dd").toString()
        : undefined,
      dataFinal: advancedFilters?.periodoOperacao?.[1]
        ? format(advancedFilters?.periodoOperacao?.[1], "yyyy-MM-dd").toString()
        : undefined,
    };
    updateFiltroOperacoesExecutadas(
      {
        equipeIds: equipes && equipes.value ? [equipes.value] : [],
        periodoOperacao: periodoPlantioToFilter,
        insumos: advancedFilters.insumos ?? [],
      },
      true
    );
    handleCloseModal();
  };

  const clearFilters = () => {
    updateFiltroOperacoesExecutadas(
      {
        equipeIds: [],
        periodoOperacao: undefined,
        insumos: [],
      },
      true
    );
  };

  const clearFilterStates = () => {
    setEquipeId({ value: "", label: "" });
    setSelected([]);
    setAdvancedFilters([null, null]);
  };

  const handleClearFilters = () => {
    clearFilterStates();
    clearFilters();
  };

  return {
    equipeIds,
    insumos,
    filterPeriodo,
    onChange: applyFilters,
    clearFilters,
    advancedFilters,
    setAdvancedFilters,
    equipeId,
    setEquipeId,
    selected,
    setSelected,
    handleClearFilters,
    clearFilterStates,
  };
};

export default useAdvancedFiltersOperacoesExecutadas;
