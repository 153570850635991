import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";

import notFoundIcon from "assets/images/not-found-map-filters.svg";

const NotFoundMapFilters = ({message}: {message: string}) => {
  return (
    <Flex flex={1} flexDir="row" w="100%" pl={6} pr={6} alignItems="center" justifyContent="center">
      <Image src={notFoundIcon} />
      <Text  color="gray.500" ml="12px">{message}</Text>
    </Flex>
  );
};

export default NotFoundMapFilters;
