import { Flex } from "@chakra-ui/react";
import JustificativaHeader from "./components/Header";
import AtrasosTable from "./components/AtrasosTable";
import AtrasosFilters from "./components/Filters";

const Justificativa = () => {
    return (
        <Flex
            flex={1}
            background="#FAFAFA"
            flexDirection="column"
            paddingTop="40px"
            height="100vh"
            gridGap="20px"
            maxWidth="calc(100vw - 81px)"
            overflow="hidden"
        >
            <JustificativaHeader />

            <AtrasosFilters />

            <AtrasosTable />
        </Flex>
    );
}

export default Justificativa;
