import URL from "core/resources/urls";
import config from "infra/config";
import { httpAgrodrive } from "infra/http";
import { useQuery } from "react-query";
import { DiretorioResponse } from "../types/arquivos";

export default function useGetDiretorios(
  diretorio: string,
  pagina = 1,
  tamanhoPagina: number,
  orderBy: string
) {
  return useQuery(
    [
      URL.AGRODRIVE.DIRETORIO_LIST(diretorio, pagina, tamanhoPagina, orderBy),
      diretorio,
      pagina,
      tamanhoPagina,
      orderBy,
    ],
    async () => {
      const { data } = await httpAgrodrive.get(
        URL.AGRODRIVE.DIRETORIO_LIST(diretorio, pagina, tamanhoPagina, orderBy),
        {
          headers: {
            "x-api-key": config.agrodriveClientId,
          },
        }
      );

      return data as DiretorioResponse;
    }
  );
}
