import { useContext, useState } from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
import TalhoesHeader from "./components/Header";
import TalhoesFilters from "./components/Filters";
import EditarTalhoesImage from "assets/images/EditarTalhoesImage.svg";
import { TalhoesContext } from "core/features/talhoes/contexts/Talhoes.context";
import ImportTalhoes from "./components/ImportTalhoes";

export type CurrentViewType = "edit" | "import";

const Talhoes = () => {
  const { currentSafra, currentUnidade } = useContext(TalhoesContext);
  const [currentView, setCurrentView] = useState<CurrentViewType>("edit");

  return (
    <Flex
      flex={1}
      background="#FAFAFA"
      flexDirection="column"
      paddingTop="40px"
      height="100vh"
      gridGap="20px"
      maxWidth="calc(100vw - 81px)"
      overflow="hidden"
    >
      <TalhoesHeader
        currentView={currentView}
        changeCurrentView={setCurrentView}
      />

      {currentSafra && currentUnidade && currentView === "edit" ? (
        <>
          <TalhoesFilters />
        </>
      ) : currentSafra && currentUnidade && currentView === "import" ? (
        <ImportTalhoes
          currentSafraId={currentSafra.id}
          currentUnidadeId={currentUnidade.id}
        />
      ) : (
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          flex={1}
        >
          <Image src={EditarTalhoesImage} height="40%" />
          <Text
            fontWeight="medium"
            color="#989898"
            fontSize="26px"
            marginTop="31px"
          >
            Selecione Unidade e Safra para visualizar os talhões
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default Talhoes;
