import styled from "styled-components";

export const containerStyle = {
  width: "100%",
  height: "100%",
};

export const MarkersContainer = styled.div`
  border: 2px solid black !important;
  & > .marker-text {
    margin-left: 10px;
  }
  z-index: 1000000;
`;
