import styled from "styled-components";

interface BreadChildProps {
  isActual?: boolean;
}

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
