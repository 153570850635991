import { gray300, gray500, green700, white } from "core/resources/theme/colors";
import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  color: black;

  display: flex;
  /* align-items: fle-; */
  flex-direction: column;

  /* padding-left: 10px; */

  svg {
    z-index: 999;
    position: absolute;
    left: 8px;
    top: 11px;
    pointer-events: none;
  }

  &:hover {
    svg {
      color: ${green700} !important;
    }

    input {
      border: 1px solid ${green700};
    }
    p {
      color: ${green700};
    }
  }
  input:focus,
  input:active {
    border: 1px solid ${green700};
  }

  input:focus ~ p,
  input:focus ~ svg {
    color: ${green700};
  }
`;

export const Input = styled.input<Partial<{ error: boolean; large?: boolean }>>`
  border-radius: 5px;
  width: ${({ large }) => (large ? "100%" : "10.5rem")};
  height: 40px;
  border: 1px solid ${gray300};
  padding-left: 10px;
  outline: none;
  background: none;
  padding: 0 30px;
  ${({ error }) =>
    error &&
    css`
      border: 1px solid red;
    `}
`;
export const Label = styled.p<{ show: boolean }>`
  position: absolute;
  left: 0.7rem;
  top: -0.6rem;
  pointer-events: none;
  background-color: none;
  padding: 0px 5px;
  font-size: 12px;
  opacity: 0;
  background: ${white};
  transition: opacity 0.7s;
  z-index: 9;
  color: ${gray500};
  ${({ show }) =>
    show &&
    css`
      opacity: 1;
    `}
`;
