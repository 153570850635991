import { useCallback, useEffect, useState } from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import { HourSelect } from "components/hour-select";
import useAdvancedFiltersOnMapaDeChuvas from "../hooks/useAdvancedFiltersOnMapaDeChuvas";
import { useFilters } from "core/features/filters/store";
import { getDateFromFilter } from "../utils/mapa-de-chuva.utils";
import CalendarSelect from "components/calendar-select";
import { DateRange } from "@material-ui/pickers";
import { isSameDay } from "date-fns";

export interface AdvancedFiltersMapaDeChuvaProps {
  minDate: Date | null;
  maxDate: Date | null;
  minHour?: string;

  maxHour?: string;

  date: DateRange<Date>;
}

interface ErrorsProps {
  initialDate?: string;
  endDate?: string;
  initialHour?: string;
  endHour?: string;
}
interface MapaDeChuvaAdvancedFilterProps {
  handleCloseModal: () => void;
}

export function MapaDeChuvaAdvancedFilter({
  handleCloseModal,
}: MapaDeChuvaAdvancedFilterProps) {
  const { values, onChange, onClear } = useAdvancedFiltersOnMapaDeChuvas();
  const [errors, setErrors] = useState<ErrorsProps>({});
  const { filters } = useFilters();
  const safraDateInitial = getDateFromFilter(
    filters.safra?.periodoPluviometricoInicio!
  );

  const safraDateEnd = getDateFromFilter(
    filters.safra?.periodoPluviometricoFim!
  );

  const [advancedFilters, setAdvancedFilters] =
    useState<AdvancedFiltersMapaDeChuvaProps>({
      minDate: values.minDate,
      maxDate: values.maxDate,
      minHour: values.minHour,
      maxHour: values.maxHour,
      date: [values.minDate, values.maxDate],
    });

  const isAnyDateValid =
    advancedFilters.date[0] !== null || advancedFilters.date[1] !== null;
  const isOnlyInitialDate =
    !!advancedFilters.date[0] && !advancedFilters.date[1];
  const isOnlyEndlDate = !!advancedFilters.date[1] && !advancedFilters.date[0];

  const isSingleDate = isOnlyInitialDate || isOnlyEndlDate;

  const isDatesTheSame =
    advancedFilters.date[0] &&
    advancedFilters.date[1] &&
    isSameDay(advancedFilters.date[0], advancedFilters.date[1]);

  const handleChangeDate = (newDate: DateRange<Date>) => {
    setAdvancedFilters({
      ...advancedFilters,
      date: newDate,
    });
  };

  const handleChangeMinHour = (newDate?: string) => {
    setAdvancedFilters({
      ...advancedFilters,
      minHour: newDate,
    });
  };

  const handleChangeMaxHour = (newDate?: string) => {
    setAdvancedFilters({
      ...advancedFilters,
      maxHour: newDate,
    });
  };

  const handleSubmit = () => {
    const minHourToSet = advancedFilters.minHour;

    onChange({
      dataInicial: advancedFilters.date[0]!,
      dataFinal: advancedFilters.date[1]!,
      horaFinal: advancedFilters.maxHour!,
      horaInicial: advancedFilters.minHour!,
    });

    setAdvancedFilters({ ...advancedFilters, minHour: minHourToSet });
    handleCloseModal();
  };

  const handleClear = () => {
    setAdvancedFilters({
      maxDate: null,
      maxHour: "",
      minDate: null,
      minHour: "",
      date: [null, null],
    });

    setErrors({});
    onClear();
  };

  const disableApplyButton = useCallback(() => {
    const isMinHourValid = advancedFilters.minHour !== "";
    const isMaxHourValid = advancedFilters.maxHour !== "";

    const theresAnyError = Object.values(errors).filter(
      (message) => !!message
    ).length;

    const isAllValid =
      !theresAnyError && isAnyDateValid && isMinHourValid && isMaxHourValid;

    return !isAllValid;
  }, [advancedFilters, isAnyDateValid, errors]);

  useEffect(() => {
    const initialHour = advancedFilters.minHour?.split(":")[0];
    const endHour = advancedFilters.maxHour?.split(":")[0];

    if (isAnyDateValid && isSingleDate) {
      if (initialHour && !endHour) {
        setErrors({
          ...errors,
          endHour: "Data final é obrigatória",
        });
      } else if (!initialHour && endHour) {
        setErrors({
          ...errors,
          initialHour: "Data inicial é obrigatória",
        });
      } else if (initialHour && endHour) {
        setErrors({
          ...errors,
          initialHour: "",
          endHour: "",
        });
      }
    } else if (isAnyDateValid && !isSingleDate) {
      setErrors({
        ...errors,
        initialHour: !initialHour ? "Hora inicial é obrigatório" : "",
        endHour: !endHour ? "Hora final é obrigatório" : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancedFilters, isAnyDateValid]);

  return (
    <>
      <Text
        fontSize="16px"
        color="green.600"
        fontWeight="bold"
        margin="20px 15px 10px"
      >
        {" "}
        Filtros Avançados
      </Text>

      <Flex>
        <Flex maxWidth="55%">
          <CalendarSelect
            title="Período"
            errors={{}}
            onChange={handleChangeDate}
            value={advancedFilters.date}
            containerColumn
            minDate={safraDateInitial}
            maxDate={safraDateEnd}
          />
        </Flex>

        <Flex
          flexDirection="column"
          gridGap="30px"
          justify="center"
          alignItems="center"
          paddingTop="36px"
          width="40%"
        >
          <HourSelect
            label="Das"
            value={advancedFilters.minHour}
            onChange={handleChangeMinHour}
            error={errors.initialHour}
            maxValue={
              isDatesTheSame || isSingleDate
                ? advancedFilters.maxHour
                : undefined
            }
          />
          <HourSelect
            label="Até"
            value={advancedFilters.maxHour}
            onChange={handleChangeMaxHour}
            error={errors.endHour}
            minValue={
              isDatesTheSame || isSingleDate
                ? advancedFilters.minHour
                : undefined
            }
          />
        </Flex>
      </Flex>

      <Flex margin="10px 0" justifyContent="space-around">
        <Button
          width="40%"
          color="gray.500"
          background="none"
          fontWeight="medium"
          onClick={handleClear}
        >
          Limpar filtros
        </Button>
        <Button
          background="green.700"
          borderRadius="10px"
          boxShadow="0px 3px 6px #c1c1c1"
          width="40%"
          disabled={disableApplyButton()}
          onClick={handleSubmit}
        >
          Aplicar filtros
        </Button>
      </Flex>
    </>
  );
}
