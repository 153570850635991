import React, { useCallback, useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import { gray500 } from "core/resources/theme/colors";
import { useFilters } from "core/features/filters/store";
import { TipoMapaTalhoes } from "core/features/maps/typings";
import AdvancedFilterImage from "../../../../../../assets/images/advanced-filters.svg";
import AdvancedFilterActiveImage from "../../../../../../assets/images/advanced-filters-active.svg";
import {
  getNumberOfAdvancedFiltersDistribuicaoVarietal,
  getNumberOfAdvancedFiltersOperacoesExecutadas,
} from "./utils";
import * as S from "./styles";
import DistribuicaoVarietalAdvancedFilters from "./items/components/distribuicao-varietal";
import { useIsApplyButtonDisabledOnDistribuicaoVarietal } from "./hooks/useIsApplyButtonDisabledOnDistribuicaoVarietal";

import { MapaDeChuvaAdvancedFilter } from "./items/components/mapa-de-chuva-advanced-filters";
import OperacoesExecutadasAdvancedFilters from "./items/components/operacoes-executadas";
import { useIsApplyButtonDisabledOnOperacoesExecutadas } from "./hooks/useApplyButtonDisabledOnOperacoesExecutadas";

type AdvancedFilterEnabledOnMapsType = {
  tipoMapa: TipoMapaTalhoes;
  component: React.ReactNode;
};

const AdvancedFilters = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { filters } = useFilters();

  const { disable: disabledDistribuicaoVarietalFilters } =
    useIsApplyButtonDisabledOnDistribuicaoVarietal();
  const { disabledOperacoesAdvancedFilters } =
    useIsApplyButtonDisabledOnOperacoesExecutadas();

  const {
    filters: {
      filtroDistribuicaoVarietal,
      tipoMapa,
      filtroOperacoesExecutadas,
    },
  } = useFilters();

  const popoverWidthContent =
    tipoMapa === TipoMapaTalhoes.OperacoesExecutadas ? "700px" : "400px";

  const advancedFilterEnabledOnMaps: AdvancedFilterEnabledOnMapsType[] = [
    {
      tipoMapa: TipoMapaTalhoes.DistribuicaoVarietal,
      component: (
        <DistribuicaoVarietalAdvancedFilters
          handleCloseModal={() => setIsModalOpen(false)}
        />
      ),
    },

    {
      tipoMapa: TipoMapaTalhoes.Chuvas,
      component: (
        <MapaDeChuvaAdvancedFilter
          handleCloseModal={() => setIsModalOpen(false)}
        />
      ),
    },
    {
      tipoMapa: TipoMapaTalhoes.OperacoesExecutadas,
      component: (
        <OperacoesExecutadasAdvancedFilters
          handleCloseModal={() => setIsModalOpen(false)}
        />
      ),
    },
  ];

  const isDisabled = useCallback(() => {
    if (tipoMapa === TipoMapaTalhoes.OperacoesExecutadas) {
      return disabledOperacoesAdvancedFilters;
    }
    if (tipoMapa === TipoMapaTalhoes.DistribuicaoVarietal) {
      return disabledDistribuicaoVarietalFilters;
    }
  }, [
    disabledDistribuicaoVarietalFilters,
    disabledOperacoesAdvancedFilters,
    tipoMapa,
  ]);

  const actualAdvancedFilter = advancedFilterEnabledOnMaps.find(
    (maps) => maps.tipoMapa === filters.tipoMapa
  );

  const getNumberOfAdvancedFilters = useCallback(() => {
    if (
      tipoMapa === TipoMapaTalhoes.DistribuicaoVarietal &&
      filtroDistribuicaoVarietal
    ) {
      return getNumberOfAdvancedFiltersDistribuicaoVarietal(
        filtroDistribuicaoVarietal
      );
    }
    if (
      tipoMapa === TipoMapaTalhoes.OperacoesExecutadas &&
      filtroOperacoesExecutadas
    ) {
      return getNumberOfAdvancedFiltersOperacoesExecutadas(
        filtroOperacoesExecutadas
      );
    }

    return 0;
  }, [filtroDistribuicaoVarietal, filtroOperacoesExecutadas, tipoMapa]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isDisabled() && isModalOpen) {
      setIsModalOpen(false);
    }
  }, [isDisabled, isModalOpen]);

  return (
    <Popover placement="bottom-start" closeOnBlur={false} isOpen={isModalOpen}>
      <PopoverTrigger>
        <Box position="relative">
          <IconButton
            width="45px"
            height="45px"
            padding={"0px"}
            background={isModalOpen ? "green.700" : "white"}
            boxShadow={"md"}
            borderRadius="5px"
            marginLeft="20px"
            onClick={openModal}
            color="blue"
            shadow="0px 0px 15px #00000080"
            border="none"
            aria-label="advanced filters button"
            disabled={isDisabled()}
            icon={
              isModalOpen ? (
                <S.IconButtonImage src={AdvancedFilterActiveImage} />
              ) : (
                <S.IconButtonImage src={AdvancedFilterImage} />
              )
            }
          ></IconButton>

          {getNumberOfAdvancedFilters() !== 0 && (
            <Badge
              position="absolute"
              top="-7px"
              right="-7px"
              borderRadius="50%"
              background="green.700"
              padding="2px 6px"
              color="white"
            >
              {getNumberOfAdvancedFilters()}
            </Badge>
          )}
        </Box>
      </PopoverTrigger>
      <PopoverContent
        color="white"
        width={popoverWidthContent}
        outline="none"
        border="none"
        marginTop="35px"
        marginLeft="20px"
        _active={{}}
        _focus={{}}
      >
        <PopoverArrow
          border={"12px solid white"}
          marginLeft="-7px"
          boxShadow="none"
        />
        <Button
          data-testid="talhao-modal-close-button"
          onClick={closeModal}
          position="absolute"
          top="12px"
          right="24px"
          w="fit-content"
          px={0}
          bg="transparent"
          py={0}
          _active={{}}
          _focus={{}}
        >
          <MdClose size={32} color={gray500} />
        </Button>
        {actualAdvancedFilter?.component}
      </PopoverContent>
    </Popover>
  );
};

export default AdvancedFilters;
