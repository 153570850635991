import { ImportTalhoesContextProvider } from "core/features/talhoes/contexts/ImportTalhoes.context";
import Forms from "./Forms";
import Steps from "./Steps";

interface ImportTalhoesProps {
  currentSafraId: string;
  currentUnidadeId: string;
}

const ImportTalhoes = ({
  currentSafraId,
  currentUnidadeId,
}: ImportTalhoesProps) => {
  return (
    <>
      <ImportTalhoesContextProvider>
        <Steps />

        <Forms
          currentSafraId={currentSafraId}
          currentUnidadeId={currentUnidadeId}
        />
      </ImportTalhoesContextProvider>
    </>
  );
};

export default ImportTalhoes;
