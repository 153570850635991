import { ReactNode, useContext, useEffect } from "react";
import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import { containerStyle } from "components/maps/base-map/styles";
import { DEFAULT_ZOOM, options } from "components/maps/base-map/config";
import { MapTypeId } from "core/features/maps/enums";

import {
  GeoJsonProps,
  TalhoesProps,
} from "core/features/talhoes/typings/listaEGeojson";
import { useMapFilter } from "../../../hooks/useMapFilter";
import { LatLong } from "core/features/maps/typings";
import { white } from "core/resources/theme/colors";
import ControlZoom from "components/maps/base-map/components/controls/ControlZoom";
import { Flex } from "@chakra-ui/layout";
import { MdGpsFixed } from "react-icons/md";
import { TalhoesContext } from "core/features/talhoes/contexts/Talhoes.context";

interface MapFilterProps {
  children: ReactNode;
  currentItem?: TalhoesProps;
  geojson?: GeoJsonProps;

  canEditPolygon: boolean;
  canMovePolygon: boolean;
  defaultPontoCentral: LatLong;

  changePathsToSave: (props: { lat: number; lng: number }[]) => void;
  pathsToSave?: { lat: number; lng: number }[];
}

const MapFilter = ({
  children,
  currentItem,
  geojson,
  canEditPolygon,
  canMovePolygon,
  defaultPontoCentral,
  changePathsToSave,
  pathsToSave,
}: MapFilterProps) => {
  const {
    items: { polygonPath, map, theresChanges },
    loaders: { onLoad, onLoadPolygon, onUnmountPolygon, onUnmountMap },
    onChange: { onPolygonEdit, handleCreatePolygon, setPolygonPath },
  } = useMapFilter({
    currentItem,
    geojson,
    defaultPontoCentral,
  });

  const { importKMLCode } = useContext(TalhoesContext);

  useEffect(() => {
    if (polygonPath && theresChanges) {
      changePathsToSave(polygonPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygonPath]);

  useEffect(() => {
    if (pathsToSave && importKMLCode) {
      setPolygonPath(pathsToSave);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathsToSave, importKMLCode]);

  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={DEFAULT_ZOOM}
        onLoad={onLoad}
        onUnmount={onUnmountMap}
        mapTypeId={MapTypeId.SATELLITE}
        options={{ ...options }}
        onClick={(props) => {
          if (!canEditPolygon) return;
          handleCreatePolygon({
            lat: props.latLng?.lat()!,
            long: props.latLng?.lng()!,
          });
        }}
      >
        {children}
        {geojson?.features.map((item, index) => {
          return (
            <Marker
              position={{
                lat: item.properties.pontoCentralLat,
                lng: item.properties.pontoCentralLong,
              }}
              label={{
                text:
                  item.properties.id === currentItem?.talhaoId
                    ? " "
                    : String(item?.id),
                color: white,
                fontWeight: "800",
                // className: "pluviometrico-value",
              }}
              zIndex={1}
              icon={{ url: "/marker.png", size: new google.maps.Size(0, 0) }}
              key={item.id + index}
            ></Marker>
          );
        })}

        {currentItem && polygonPath && (
          <Polygon
            onLoad={onLoadPolygon}
            options={{
              geodesic: true,
              fillColor: "#11A557",
              fillOpacity: 0.7,
              zIndex: 999,
            }}
            editable={canEditPolygon}
            draggable={canMovePolygon}
            path={polygonPath}
            onMouseUp={onPolygonEdit}
            onDragEnd={onPolygonEdit}
            onUnmount={onUnmountPolygon}
          />
        )}
      </GoogleMap>
      <Flex position="absolute" bottom={10} right={10} flexDirection="column">
        <Flex
          w="30px"
          h="30px"
          p={0}
          rounded="full"
          background="white"
          alignItems="center"
          justifyContent="center"
          marginBottom="10px"
          onClick={() => {
            const currentItemGeojson = geojson?.features.find(
              (item) => item.properties.id === currentItem?.talhaoId
            );

            map?.setCenter({
              lat: currentItemGeojson
                ? currentItemGeojson.properties.pontoCentralLat
                : defaultPontoCentral.lat,
              lng: currentItemGeojson
                ? currentItemGeojson.properties.pontoCentralLong
                : defaultPontoCentral.long,
            });
            map?.setZoom(
              currentItemGeojson?.properties.pontoCentralLat ? 14 : 11
            );
          }}
        >
          <MdGpsFixed fontSize="18px" />
        </Flex>
        <ControlZoom map={map!} />
      </Flex>
    </>
  );
};

export default MapFilter;
