import React from "react";
import { Tooltip as ChakraTooltip } from "@chakra-ui/react";
import { gray250 } from "core/resources/theme/colors";

function Tooltip({
  text,
  children,
}: {
  text: string;
  children: React.ReactNode;
}) {
  return (
    <ChakraTooltip
      label={text}
      fontSize={14}
      fontWeight="400"
      px={3}
      hasArrow
      placement="bottom"
      bg={gray250}
      borderRadius="md"
      mr="-4px"
    >
      {children}
    </ChakraTooltip>
  );
}

export default Tooltip;
