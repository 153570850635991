import create from "zustand";
import { configurePersist } from "zustand-persist";

import { UserType } from "../typings/auth";

const { persist } = configurePersist({
  storage: localStorage,
});

export interface UseAuth {
  user: Partial<UserType> | null;
  token: string | null;
  expirationToken: string | null;
  update: (value: Partial<UserType>) => void;
  updateToken: (value: string | null) => void;
  updateExpirationToken: (value: string | null) => void;
  updateField: (prop: keyof UserType, value: any) => any;
  clear: () => any;
}

const initialState = {
  token: null,
  expirationToken: null,
  user: null,
};

export const useAuth = create<UseAuth>(
  persist(
    {
      key: "@auth-agrosig",
    },
    (set) => ({
      ...initialState,
      updateToken: (token: string | null) => set(() => ({ token })),
      updateExpirationToken: (expirationToken: string | null) =>
        set(() => ({ expirationToken })),
      update: (user: Partial<UserType>) => set(() => ({ user })),
      updateField: (prop: keyof UserType, value: any) => {
        set((state) => {
          return {
            ...state,
            user: Object.assign(state.user || ({} as any), { [prop]: value }),
          };
        });
      },
      clear: () => set(() => ({ ...initialState })),
    })
  )
);
