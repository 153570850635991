import {
  TexturaSoloListagemItem,
  OperacoesExecutadas,
  NumeroDeAplicacoes,
  ProdutividadeMap,
  ConcentracaoColheita,
  IdadeDoTalhao,
  OrdensDeServicosAberta,
  DistribuicaoDeEquipes,
  MapaDeChuvasProps,
} from "./../../maps/typings";

import { Ocupacao, Safra, Unidade } from "core/features/maps/typings";
import create from "zustand";
import { configurePersist } from "zustand-persist";

const { persist } = configurePersist({
  storage: localStorage,
});

export interface FiltersLoading {
  ocupacoes: boolean;
  safras: boolean;
  unidades: boolean;
  operacoesExecutadas: boolean;
  concentracaoColheita: boolean;
  numeroDeAplicacoes: boolean;
  produtividade: boolean;
  texturas: boolean;
  idadeDoTalhao: boolean;
  ordensDeServicosAbertas: boolean;
  distribuicaoDeEquipes: boolean;
  mapaDeChuvas: boolean;
}

export interface UseFiltersLists {
  ocupacoes: Ocupacao[];
  safras: Safra[];
  unidades: Unidade[];
  texturas: TexturaSoloListagemItem[];
  operacoesExecutadas: OperacoesExecutadas | null;
  numeroDeAplicacoes: NumeroDeAplicacoes | null;
  concentracaoColheita: ConcentracaoColheita | null;
  produtividade: ProdutividadeMap | null;
  idadeDoTalhao: IdadeDoTalhao | null;
  loadings: FiltersLoading;
  ordensDeServicosAbertas: OrdensDeServicosAberta | null;
  distribuicaoDeEquipes: DistribuicaoDeEquipes | null;
  mapaDeChuvas: MapaDeChuvasProps | null;

  clear: () => void;
  setLoading: (prop: keyof FiltersLoading, value: boolean) => void;
  updateOcupacoes: (ocupacoes: Ocupacao[]) => void;
  updateSafras: (safras: Safra[]) => void;
  updateUnidades: (unidades: Unidade[]) => void;
  updateTexturasSolo: (texturas: TexturaSoloListagemItem[]) => void;
  updateOperacoesExecutadas: (
    operacoesExecutadas: OperacoesExecutadas | null
  ) => void;
  updateProdutividade: (produtividade: ProdutividadeMap | null) => void;
  updateNumeroDeAplicacoes: (
    numeroDeAplicacoes: NumeroDeAplicacoes | null
  ) => void;
  updateConcentracaoColheita: (
    concentracaoColheita: ConcentracaoColheita | null
  ) => void;
  updateIdadeDoTalhao: (idadeDoTalhao: IdadeDoTalhao | null) => void;
  updateOrdensDeServicoAbertas: (
    ordensDeServicosAbertas: OrdensDeServicosAberta | null
  ) => void;
  updateDistribuicaoDeEquipes: (
    distribuicaoDeEquipes: DistribuicaoDeEquipes | null
  ) => void;
  updateMapaDeChuvas: (chuvas: MapaDeChuvasProps | null) => void;
}

export const useFiltersLists = create<UseFiltersLists>(
  persist(
    {
      key: "@filters-lists-agrosig",
    },
    (set) => ({
      ocupacoes: [],
      safras: [],
      unidades: [],
      texturas: [],
      operacoesExecutadas: null,
      numeroDeAplicacoes: null,
      concentracaoColheita: null,
      produtividade: null,
      idadeDoTalhao: null,
      ordensDeServicosAbertas: null,
      distribuicaoDeEquipes: null,
      mapaDeChuvas: null,
      loadings: {
        ocupacoes: false,
        concentracaoColheita: false,
        safras: false,
        unidades: false,
        operacoesExecutadas: false,
        numeroDeAplicacoes: false,
        produtividade: false,
        texturas: false,
        idadeDoTalhao: false,
        ordensDeServicosAbertas: false,
        distribuicaoDeEquipes: false,
        mapaDeChuvas: false,
      },
      setLoading: (prop: string, value: boolean) => {
        set((state) => ({
          ...state,
          loadings: {
            ...state.loadings,
            [prop]: value,
          },
        }));
      },
      updateOcupacoes: (ocupacoes) => set(() => ({ ocupacoes })),
      updateSafras: (safras) => set(() => ({ safras })),
      updateUnidades: (unidades) => set(() => ({ unidades })),
      updateTexturasSolo: (texturas) => set(() => ({ texturas })),
      updateOperacoesExecutadas: (operacoesExecutadas) =>
        set(() => ({ operacoesExecutadas })),
      updateProdutividade: (produtividade) => set(() => ({ produtividade })),
      updateNumeroDeAplicacoes: (numeroDeAplicacoes) =>
        set(() => ({ numeroDeAplicacoes })),
      updateConcentracaoColheita: (concentracaoColheita) =>
        set(() => ({ concentracaoColheita })),
      updateIdadeDoTalhao: (idadeDoTalhao) => set(() => ({ idadeDoTalhao })),
      updateOrdensDeServicoAbertas: (ordensDeServicosAbertas) =>
        set(() => ({ ordensDeServicosAbertas })),
      updateDistribuicaoDeEquipes: (distribuicaoDeEquipes) =>
        set(() => ({ distribuicaoDeEquipes })),
      updateMapaDeChuvas: (mapaDeChuvas) => set(() => ({ mapaDeChuvas })),

      clear: () => set(() => ({ unidades: [], ocupacoes: [], safras: [] })),
    })
  )
);
