import { Box, Text } from "@chakra-ui/react";
import { TableColumnType, Table } from "antd";

import {
  TalhaoOrdensServico,
  TalhaoOrdensServicoTelemetriaResumoHoras,
  TalhaoOrdensServicoTelemetriaIndicadoresOperacionaisFormatted,
} from "core/features/maps/typings/ordensServico";
import formatDate from "infra/helpers/formatDate";
import { formatMoney } from "infra/helpers/number";
import { capitalizeFirstLetter, fallbackText } from "ui/utils";
import { BadgeStatus } from "./BadgeStatus";

export const columnTablesOrdensServicoTempoTotal: (
  tempoTotal: string
) => TableColumnType<TalhaoOrdensServicoTelemetriaResumoHoras>[] = (
  tempoTotal
) => [
  {
    key: "8",
    title: "TEMPO TOTAL",
    render: (data: TalhaoOrdensServicoTelemetriaResumoHoras) => {
      return <Box textAlign="left">{data.descricao.toUpperCase()}</Box>;
    },
  },
  {
    key: "9",
    title: () => tempoTotal,
    width: "25%",
    align: "right",
    render: (data: TalhaoOrdensServicoTelemetriaResumoHoras) => {
      return <Box textAlign="right">{data.tempo || "---"}</Box>;
    },
  },
];

export const columnTablesOrdensServicoIndicadoresOperacionais: TableColumnType<TalhaoOrdensServicoTelemetriaIndicadoresOperacionaisFormatted>[] =
  [
    {
      key: "10",
      title: "INDICADORES OPERACIONAIS",
      dataIndex: "descricao",
    },
    {
      key: "11",
      title: "",
      render: (
        data: TalhaoOrdensServicoTelemetriaIndicadoresOperacionaisFormatted
      ) => {
        return <Box textAlign="right">{data.value}</Box>;
      },
    },
  ];

export const columnTablesOrdensServico: TableColumnType<TalhaoOrdensServico>[] =
  [
    Table.EXPAND_COLUMN,
    {
      key: "1",
      title: "ORDEM",
      width: 70,
      dataIndex: "numero",
    },
    {
      key: "2",
      title: "STATUS",
      width: 80,
      render: (data: TalhaoOrdensServico) => {
        return <BadgeStatus status={data.situacao} />;
      },
    },
    {
      key: "3",
      title: "DATA",
      width: 120,
      render: (data: TalhaoOrdensServico) => {
        return data.dataAbertura
          ? formatDate(data.dataAbertura as string)
          : fallbackText;
      },
    },
    {
      key: "4",
      title: `DIAS`,
      width: 90,
      render: (data: TalhaoOrdensServico) => {
        return data.diasAberto || fallbackText;
      },
    },
    {
      key: "5",
      title: "OPERAÇÃO",

      render: (data: TalhaoOrdensServico) => {
        return (
          <Text
            display="inline"
            align="left"
            overflowX="visible"
            overflow="clip"
          >
            {capitalizeFirstLetter(data.descricao ?? "").toUpperCase()}
          </Text>
        );
      },
    },
    {
      key: "6",
      title: "EQUIPE",
      width: 220,
      render: (data: TalhaoOrdensServico) => {
        return (
          <Text
            display="inline"
            align="left"
            overflowX="visible"
            overflow="clip"
          >
            {data.equipe?.toUpperCase()}
          </Text>
        );
      },
    },
    {
      key: "7",
      width: 100,
      title: "ÁREA(HA)",
      align: "right",
      render: (data: TalhaoOrdensServico) => {
        return (
          <Box textAlign="right">
            {formatMoney(data.area).replace("R$", "")}
          </Box>
        );
      },
    },
  ];
