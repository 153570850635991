import { Box, Flex, Text } from "@chakra-ui/react";
import { GraficoAplicacoesItemResult } from "features/aplicacoes/typings";
import { blue600, gray900 } from "core/resources/theme/colors";
import { LabelList } from "recharts";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import { formatMoney } from "infra/helpers/number";

const GraphicNameShape = styled.div<{ bg: string }>`
  width: 11px;
  height: 11px;
  background: ${({ bg }) => bg};
  border-radius: 50%;
  margin: 0 5px 0 10px;
`;

const renderCustomizedLabel = (props: any) => {
  const { x, y, width, value, color } = props;
  const radius = 10;

  return (
    <g>
      <text
        x={x + width / 2}
        y={y - radius}
        fill={color}
        fontWeight={"bold"}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {formatMoney(value, "$")}
      </text>
    </g>
  );
};

function GraficoCustoMedioAplicacoes({
  items,
}: {
  items: GraficoAplicacoesItemResult[];
}) {
  return (
    <Box
      py="32px"
      boxShadow="0 0 5px rgba(0,0,0,.2)"
      overflow={"hidden"}
      rounded={10}
    >
      <Text mb="16px" textAlign={"center"} fontWeight={"bold"}>
        Custo x Média por Aplicação
      </Text>

      <Box h="37.5vh" maxH="400px">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={400}
            data={items.map((item) => ({
              ...item,
              total:
                item.mediaDemaisTalhoes > item.talhao
                  ? item.mediaDemaisTalhoes
                  : item.talhao,
            }))}
            margin={{
              right: 30,
              top: 30,
            }}
          >
            <YAxis dataKey="total" />

            <CartesianGrid
              strokeWidth={2}
              strokeDasharray="3 3"
              vertical={false}
            />

            <XAxis dataKey="insumo" />

            <Bar
              fill={blue600}
              dataKey="talhao"
              isAnimationActive={true}
              maxBarSize={65}
              radius={[10, 10, 0, 0]}
              minPointSize={5}
            >
              <LabelList
                dataKey="talhao"
                color={blue600}
                content={renderCustomizedLabel}
              />
            </Bar>
            <Bar
              fill={gray900}
              dataKey="mediaDemaisTalhoes"
              opacity={0.9}
              isAnimationActive={true}
              maxBarSize={65}
              radius={[10, 10, 0, 0]}
              minPointSize={5}
            >
              <LabelList
                dataKey="mediaDemaisTalhoes"
                color={gray900}
                content={renderCustomizedLabel}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>

      <Flex justifyContent="center" mt="38px">
        <Flex alignItems="center">
          <GraphicNameShape bg={blue600} />
          <Text fontWeight="medium">Custo total por aplicação (US$/HA)</Text>
        </Flex>
        <Flex alignItems="center">
          <GraphicNameShape bg={gray900} />
          <Text fontWeight="medium">Média dos demais talhões (US$/HA)</Text>
        </Flex>
      </Flex>
    </Box>
  );
}

export default GraficoCustoMedioAplicacoes;
