import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { FaCircle } from "react-icons/fa";
import BadgeText from "./components/BadgeText";

const getTooltipValues = (
  list: Array<string>,
  itemsToRemove: Array<string>
): string => {
  const newList = list.filter((item) => !itemsToRemove.includes(item));
  return newList.join(", ");
};

export const usersModalColumsName = (fazendasData: any[]) => [
  {
    title: "",
    width: 60,
    render: (user: any) => {
      return (
        <Flex justifyContent="center">
          <Avatar
            key={user.usuarioId + user.nome}
            src={user.avatarUrl}
            height="25px"
            width="25px"
          />
        </Flex>
      );
    },
  },
  {
    title: "NOME",
    render: (user: any) => {
      return (
        <Text fontWeight="regular" fontSize="0.8rem">
          {user.nome}
        </Text>
      );
    },
    width: 200,
  },
  {
    title: "E-MAIL",
    render: (user: any) => {
      return (
        <Text fontWeight="regular" fontSize="0.8rem">
          {user.email}
        </Text>
      );
    },
    width: 200,
  },
  {
    title: "PERMISSÕES",
    render: (user: any) => {
      return (
        <Flex>
          {user.perfis.length > 2
            ? [
                user.perfis.map((perfil: any, index: number) =>
                  index < 2 ? (
                    <BadgeText
                      text={perfil}
                      key={perfil + index}
                      useDots={true}
                    />
                  ) : (
                    ""
                  )
                ),
                <BadgeText
                  text={`+${user.perfis.length - 2}`}
                  tooltipText={getTooltipValues(user.perfis, [
                    user.perfis[0],
                    user.perfis[1],
                  ])}
                />,
              ]
            : user.perfis.map((perfil: any, index: number) => (
                <BadgeText
                  text={perfil}
                  key={perfil + index}
                  useDots={user.perfis.length > 1}
                />
              ))}
        </Flex>
      );
    },
    width: 220,
  },
  {
    title: "FAZENDAS",
    render: (user: any) => {
      return (
        <Flex>
          {user.fazendas.length === fazendasData?.length ? (
            <BadgeText text="Todas" />
          ) : user.fazendas.length > 2 ? (
            [
              user.fazendas.map((fazenda: any, index: number) =>
                index < 2 ? (
                  <Box>
                    <BadgeText text={fazenda} key={fazenda + index} />
                  </Box>
                ) : (
                  ""
                )
              ),

              <Box>
                <BadgeText
                  text={`+${user.fazendas.length - 2}`}
                  tooltipText={getTooltipValues(user.fazendas, [
                    user.fazendas[0],
                    user.fazendas[1],
                  ])}
                />
              </Box>,
            ]
          ) : (
            user.fazendas.map((fazenda: any, index: number) => (
              <Box key={fazenda + index}>
                <BadgeText text={fazenda} />
              </Box>
            ))
          )}
        </Flex>
      );
    },
    width: 220,
  },
  {
    title: "ATIVO",
    render: (user: any) => {
      return (
        <Flex justifyContent="center">
          {user.ativo ? (
            <FaCircle color="#11A557" size={"22px"} />
          ) : (
            <FaCircle color="#707070" size={"22px"} />
          )}
        </Flex>
      );
    },
    width: 70,
  },
];
