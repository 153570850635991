import { Text, Tooltip, Box, Flex } from "@chakra-ui/react";
import { IoEye } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";

const formataData = (data: string) => {
  const somenteData = data.split("T")[0];
  return somenteData.split("-").reverse().join("/");
};

const getTipoAtraso = (tipos: any[], id: string) => {
  const tipo = tipos?.find((tipo: any) => tipo.idPlanoOperacionalOperacaoTipoAtraso === id);
  return tipo?.descricao;
}

export const justificativaColumsName = (
  tiposAtraso: any[],
  openDetalhesJustificativa: any,
  hasPermissionToEdit: boolean | undefined,
) => [
  {
    title: "Tipo de Atraso",
    render: (justificativa: any) => {
      return (
        <Text fontWeight="regular" fontSize="0.8rem">
          {getTipoAtraso(tiposAtraso, justificativa.idPlanoOperacionalOperacaoTipoAtraso)}
        </Text>
      );
    },
    width: 140,
    align: "center" as const,
  },
  {
    title: "Justificativa",
    render: (justificativa: any) => {
      return (
        <Text fontWeight="regular" fontSize="0.8rem">
          {justificativa.descricao}
        </Text>
      );
    },
    width: 200,
    align: "center" as const,
  },
  {
    title: "Data",
    render: (justificativa: any) => {
      return (
        <Text fontWeight="regular" fontSize="0.8rem">
          {formataData(justificativa.dataJustificativa)}
        </Text>
      );
    },
    width: 90,
    align: "center" as const,
  },
  {
    title: "Ações",
    render: (justificativa: any) => {
      return (
        <Flex justifyContent="center">
          {hasPermissionToEdit && (
            <Tooltip placement="top" hasArrow label="Ver Justificativa">
              <Box p={1}>
                <IoEye onClick={() => openDetalhesJustificativa(justificativa)} size="16px" />
              </Box>
            </Tooltip>
          )}
        </Flex>
      );
    },
    width: 90,
    align: "center" as const,
  },
  {
    title: "Aprovado",
    render: (justificativa: any) => {
      return (
        <Flex justifyContent="center">
          {justificativa?.dataAprovacao && <FaCheckCircle size="16px" color="#198754" />}
        </Flex>
      );
    },
    width: 90,
    align: "center" as const,
  }
];
