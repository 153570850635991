import { ColumnsType } from "antd/lib/table";
import { OrdemPorEquipe } from "core/features/maps/typings";
import formatDate from "infra/helpers/formatDate";

export const TableOrdensPorEquipeColumns: ColumnsType<OrdemPorEquipe> = [
  {
    title: "ORDEM",
    dataIndex: "numero",
    width: 70,
  },
  {
    title: "DATA",
    render: (value) => formatDate(value),
    width: 100,
  },
  {
    title: "DIAS",
    dataIndex: "diasAberto",
    width: 60,
  },
  {
    title: "TALHÃO",
    dataIndex: "talhao",
    width: 70,
  },
  {
    title: "OPERAÇÃO",
    dataIndex: "descricao",
  },
  {
    title: "AREA(HA)",
    dataIndex: "area",
    width: 100,
    align: "right",
  },
];
