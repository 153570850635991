import { Flex } from "@chakra-ui/react";
import CheckboxFiltro from "components/inputs/checkbox-filtro";
import { ButtonClearMapCheckbox } from "../../../ButtonLimpar";
import { capitalizeFirstLetter } from "ui/utils";
import useProdutividadeItemsFilters from "../hooks/useProdutividadeItemsFilters";
import NotFoundMapFilters from "components/NotFoundMapFilters";

function ProdutividadeItems() {
  const {
    formattedProdutividadesFilters,
    isCheckedTodos,
    onClear,
    onChange,
    value,
    isLoading,
    updateFiltroProdutividade,
    items,
  } = useProdutividadeItemsFilters();

  return (
    <Flex mt={4} flexDir="column" ml={2}>
      <Flex>
        {items?.length ? (
          <>
            <CheckboxFiltro
              disabled={false}
              onClick={() =>
                updateFiltroProdutividade(
                  {
                    produtividades: isCheckedTodos
                      ? []
                      : (formattedProdutividadesFilters as any),
                  },
                  true
                )
              }
              isChecked={isCheckedTodos}
              text={"Todas"}
              data-testid="menu-filtros-operacoes-executadas-todos"
            />
            <ButtonClearMapCheckbox onClear={onClear} />
          </>
        ) : null}
      </Flex>

      {items?.map((produtividade, index) => (
        <CheckboxFiltro
          key={produtividade.codigo + index}
          text={capitalizeFirstLetter(produtividade.descricao)}
          onClick={() => onChange(produtividade)}
          color={produtividade.cor}
          data-testid={`menu-filtro-produtividade-${produtividade.descricao}`}
          isChecked={
            !!value?.find((produt) => produt.codigo === produtividade.codigo)
          }
        />
      ))}

      {!items?.length && !isLoading ? (
        <NotFoundMapFilters message="Não foram encontradas produtividades para o filtro selecionado" />
      ) : null}
    </Flex>
  );
}

export default ProdutividadeItems;
