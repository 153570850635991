import React, { useState } from "react";
import { Flex, Text, TextProps, Box } from "@chakra-ui/react";

import styled, { css } from "styled-components";
import Tooltip from "../TabOrdensServico/Tooltip";
import CloudIcon from "../../../../../../assets/images/nuvem.png";
import TractorIcon from "../../../../../../assets/images/tractor.png";
import TractorHoverIcon from "../../../../../../assets/images/tractor-hover.png";
import CalendarIcon from "../../../../../../assets/images/time-2.png";
import TreesIcon from "../../../../../../assets/images/trees.png";
import FolderIcon from "../../../../../../assets/images/folder.png";
import OpenFolderIcon from "../../../../../../assets/images/open-folder.png";

import { black, gray500, green600, white } from "core/resources/theme/colors";

interface IconStyledProps {
  verySmall?: boolean;
  small?: boolean;
  semiMedium?: boolean;
  medium?: boolean;
  semiLarge?: boolean;
}

interface BoxInfoProps {
  title: string;
  value: string | number;
  iconLeft?: IconType;
  iconRight?: IconType;
  iconLeftOnMouseEnter?: IconType;
  hoverEffect?: boolean;
  iconRightTooltipText?: string;
  onClick?: (index: number) => void;
}

interface TextPersonalizedProps extends TextProps {
  color: string;
}

const icons = {
  folder: () => <IconStyled src={FolderIcon} />,
  openFolder: () => <IconStyled src={OpenFolderIcon} semiMedium />,
  tractor: () => <IconStyled src={TractorIcon} small />,
  tractorHover: () => <IconStyled src={TractorHoverIcon} semiLarge />,
  rain: () => <IconStyled src={CloudIcon} alt="icone" small />,
  tree: () => <IconStyled src={TreesIcon} medium />,
  calendar: () => <IconStyled src={CalendarIcon} verySmall />,
};

type IconType = keyof typeof icons;

const BoxStyled = styled(Box)<BoxInfoProps>`
  box-shadow: 1px 0px 0.15rem #00000033;
  transition: box-shadow 0.3s ease-in-out;
  max-height: 125px;
  ${({ hoverEffect, value }) =>
    hoverEffect &&
    value !== 0 &&
    css`
      &:hover {
        background: ${green600};
        color: white;
        cursor: pointer;
        box-shadow: none;
      }
    `}
`;

const IconStyled = styled.img<IconStyledProps>`
  width: 1.9rem;
  height: 1.9rem;
  margin: 0;

  ${({ verySmall }) =>
    verySmall &&
    css`
      width: 0.99rem;
      height: 0.99rem;
    `}

  ${({ small }) =>
    small &&
    css`
      width: 1.3rem;
      height: 1.3rem;
    `}

  ${({ medium }) =>
    medium &&
    css`
      height: 1.6rem;
      width: 1.3rem;
    `}


  ${({ semiMedium }) =>
    semiMedium &&
    css`
      height: 1rem;
      width: 1.4rem;
      margin-top: 0.5rem;
      margin-left: 0.3rem;
      margin-bottom: 0.4rem;
    `}

    ${({ semiLarge }) =>
    semiLarge &&
    css`
      width: 2rem;
      height: 1.3rem;
      margin-left: -0.6rem;
    `}
`;

const TextTituloItem = ({ children, color }: TextPersonalizedProps) => (
  <Text color={color} textAlign={"center"} fontSize="0.8rem">
    {children}
  </Text>
);

const TextValorItem = ({ children, color }: TextPersonalizedProps) => (
  <Text
    display="inline"
    fontSize="1.5rem"
    fontWeight="bold"
    color={color}
    whiteSpace="nowrap"
  >
    {children}
  </Text>
);

const BoxInfo = ({
  title,
  value,
  iconLeft,
  iconRight,
  iconLeftOnMouseEnter,
  hoverEffect,
  onClick,
  iconRightTooltipText,
}: BoxInfoProps) => {
  const [actualIconLeft, setActualIconLeft] = useState<React.FC | undefined>(
    iconLeft && icons[iconLeft]
  );
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const onMouseEnter = () => {
    if (iconLeftOnMouseEnter && value !== 0) {
      setActualIconLeft(icons[iconLeftOnMouseEnter]);
    }
    if (hoverEffect && value !== 0) {
      setIsHovered(true);
    }
  };

  const onMouseLeave = () => {
    if (hoverEffect && value !== 0) {
      setIsHovered(false);
      setActualIconLeft(iconLeft && icons[iconLeft]);
    }
  };

  return (
    <BoxStyled
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      borderWidth="2px"
      borderRadius="10px"
      display="flex"
      flexDir="column"
      minHeight="7rem"
      minWidth="6.5rem"
      marginRight="25px"
      marginBottom="25px"
      alignItems="center"
      justifyContent="center"
      maxW="6.5rem"
      paddingTop={"8px"}
      padding="8px"
      hoverEffect={hoverEffect}
      value={value}
    >
      <Flex
        flexDir="row"
        width={"100%"}
        justifyContent="space-between"
        padding=" 0.3rem 0.7rem"
      >
        {iconLeft ? actualIconLeft : <div />}

        {iconRight ? (
          <Tooltip text={iconRightTooltipText || ""}>
            <p>{icons[iconRight]()}</p>
          </Tooltip>
        ) : (
          <div />
        )}
      </Flex>
      <TextValorItem color={isHovered ? white : black}>{value}</TextValorItem>
      <TextTituloItem color={isHovered ? white : gray500}>
        {title}
      </TextTituloItem>
    </BoxStyled>
  );
};

export default BoxInfo;
