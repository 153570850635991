import styled from "styled-components";

export const LoadingTile = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffffc3;
  position: absolute;
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;
`;
