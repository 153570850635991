import RInputMask from "react-input-mask";
import { gray700 } from "core/resources/theme/colors";
import styled, { css } from "styled-components";

type ContainerProps = {
  rounded?: boolean;
  disabled?: boolean;
};

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  height: ${({ rounded }) => (rounded ? 33 : 41)}px;
  overflow: hidden;
  width: 100%;
  border: 1px solid ${({ disabled }) => (disabled ? "#BFC8D6" : "#707070")};
  border-radius: ${({ rounded }) => (rounded ? 14 : 7)}px;

  margin: 4px 0;
  ${({ disabled }) =>
    disabled &&
    css`
      background: #fafafa;
    `}
`;

export const Input = styled.input<ContainerProps>`
  font-family: "Roboto";
  font-size: 16px;
  color: ${gray700};
  width: 100%;
  height: 100%;
  outline: 0;
  border: 0;
  padding: ${({ rounded }) => (rounded ? "0px 10px " : "10px")};
  align-items: center;
`;

export const InputMaskStyled = styled(RInputMask)`
  font-family: "Roboto";
  font-size: 16px;
  color: ${gray700};
  width: 100%;
  height: 100%;
  outline: 0;
  border: 0;
  padding: 0px 10px;
  align-items: center;
`;
