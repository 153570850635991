import SelectedButtons from "../../../SelectedButtons";
import useOrdensDeServicoAbertaPeriodosFilters from "../hooks/useOrdensDeServicoAbertaPeriodosFilters";

const FiltroOrdensDeServicoPeriodos = () => {
  const { mapperPeriodos, onChange, value } =
    useOrdensDeServicoAbertaPeriodosFilters();

  if (!mapperPeriodos.length) {
    return null;
  }

  return (
    <SelectedButtons
      value={(value?.periodos as any[]) ?? []}
      items={(mapperPeriodos as any[]) ?? []}
      isMultipleChanges
      disabledItems={
        mapperPeriodos
          .filter((item) => !item.enabled)
          .map((item) => item.id) as any[]
      }
      onChange={(periodos) => {
        onChange({ periodos });
      }}
    />
  );
};

export default FiltroOrdensDeServicoPeriodos;
