import { Tag, TagProps } from "@chakra-ui/react";
import React from "react";

function TagTalhaoResumido({ children }: TagProps) {
  return (
    <Tag bg="gray.150" color="black" rounded="full" h="30px" px={3}>
      {children}
    </Tag>
  );
}

export default TagTalhaoResumido;
