import React, { useCallback, useState } from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import CalendarSelect from "components/calendar-select";

import { DateRange } from "@material-ui/pickers/DateRangePicker/RangeTypes";

import { getErrorMessage } from "../utils/distribuicao-varietal-utils";
import useAdvancedFiltersDistribuicaoVarietal from "../hooks/useAdvancedFiltersDistribuicaoVarietal";

interface DistribuicaoVarietalAdvancedFiltersProps {
  handleCloseModal: () => void;
}
export interface AdvancedFiltersProps {
  plantio: DateRange<Date>;
  emergencia: DateRange<Date>;
  colheita: DateRange<Date>;
  previsaoDeColheita: DateRange<Date>;
}

const DistribuicaoVarietalAdvancedFilters = ({
  handleCloseModal,
}: DistribuicaoVarietalAdvancedFiltersProps) => {
  const {
    items: {
      filterColheita,
      filterEmergencia,
      filterPlantio,
      filterPrevisaoDeColheita,
    },
    onChange,
    clearFilters,
  } = useAdvancedFiltersDistribuicaoVarietal();

  const [advancedFilters, setAdvancedFilters] = useState<AdvancedFiltersProps>({
    plantio: filterPlantio,
    previsaoDeColheita: filterPrevisaoDeColheita,
    emergencia: filterEmergencia,
    colheita: filterColheita,
  });

  const handleChangePeriodoPlantio = (props: DateRange<Date>) => {
    setAdvancedFilters({
      ...advancedFilters,
      plantio: props,
    });
  };

  const handleChangePeriodoPrevisaoDeColheita = (props: DateRange<Date>) => {
    setAdvancedFilters({
      ...advancedFilters,
      previsaoDeColheita: props,
    });
  };

  const handleChangePeriodoColheita = (props: DateRange<Date>) => {
    setAdvancedFilters({
      ...advancedFilters,
      colheita: props,
    });
  };

  const handleChangePeriodoEmergencia = (props: DateRange<Date>) => {
    setAdvancedFilters({
      ...advancedFilters,
      emergencia: props,
    });
  };

  const checkIfSubmitButtonIsDisabled = useCallback(() => {
    const filters = [
      advancedFilters.colheita,
      advancedFilters.emergencia,
      advancedFilters.plantio,
      advancedFilters.previsaoDeColheita,
    ];

    const fieldsWithErrors = filters.filter((field) => {
      const fieldMessages = getErrorMessage(field);
      if (fieldMessages.finalDate || fieldMessages.initialDate) {
        return field;
      }

      return 0;
    });

    return !!fieldsWithErrors.length;
  }, [advancedFilters]);

  return (
    <>
      <Text
        fontSize="16px"
        color="green.600"
        fontWeight="bold"
        margin="20px 20px 10px"
      >
        {" "}
        Filtros Avançados
      </Text>
      <CalendarSelect
        errors={useCallback(
          () => getErrorMessage(advancedFilters.plantio),
          [advancedFilters.plantio]
        )()}
        title="Plantio"
        value={advancedFilters.plantio}
        onChange={handleChangePeriodoPlantio}
      />
      <CalendarSelect
        errors={useCallback(
          () => getErrorMessage(advancedFilters.previsaoDeColheita),
          [advancedFilters.previsaoDeColheita]
        )()}
        title="Previsão de Colheita"
        value={advancedFilters.previsaoDeColheita}
        onChange={handleChangePeriodoPrevisaoDeColheita}
      />{" "}
      <CalendarSelect
        errors={useCallback(
          () => getErrorMessage(advancedFilters.colheita),
          [advancedFilters.colheita]
        )()}
        title="Colheita"
        value={advancedFilters.colheita}
        onChange={handleChangePeriodoColheita}
      />{" "}
      <CalendarSelect
        errors={useCallback(
          () => getErrorMessage(advancedFilters.emergencia),
          [advancedFilters.emergencia]
        )()}
        title="Emergência"
        value={advancedFilters.emergencia}
        onChange={handleChangePeriodoEmergencia}
      />
      <Flex justifyContent="flex-end" padding="10px 20px">
        <Button
          background="transparent"
          onClick={() => clearFilters(setAdvancedFilters)}
          marginRight="10px"
        >
          <Text color="gray.600">Limpar filtros</Text>
        </Button>

        <Button
          background="green.600"
          onClick={() => onChange(advancedFilters, handleCloseModal)}
          disabled={checkIfSubmitButtonIsDisabled()}
        >
          <Text color="white">Aplicar Filtros</Text>
        </Button>
      </Flex>
    </>
  );
};

export default DistribuicaoVarietalAdvancedFilters;
