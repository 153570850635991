import { Flex, Text, IconButton, Tooltip } from "@chakra-ui/react";

import FilterColumn from "./FilterColumn";
import { FilterColumnSelected } from "./FilterColumnSelected";
import { FilterIndex } from "../enum";
import FilterSeparator from "./FilterSeparator";
import { Image } from "@chakra-ui/image";
import { NomeMapaTematicoEnum } from "core/features/maps/data";
import { TipoMapaTalhoes } from "core/features/maps/typings";
import { getIcon as getIconUnidade } from "infra/helpers/unidade";
import { useFilters } from "core/features/filters/store";
import { useFiltersLists } from "core/features/filters/store/lists";
import { FaChevronDown } from "react-icons/fa";

function FilterHeader({
  indexOpen,
  onToggle,
  isMenuVisible,
  handleOpenMenu,
}: {
  indexOpen: number | null;
  onToggle: (index: number) => void;
  isMenuVisible: boolean;
  handleOpenMenu: () => void;
}) {
  const { filters } = useFilters();
  const { loadings } = useFiltersLists();

  const { safra, unidade, tipoMapa } = filters;

  const _enabledSafra = enabledSafra(tipoMapa);

  const roundedBottomIndexes = [
    FilterIndex.Chuvas,
    FilterIndex.Concentracao_Colheita,
    FilterIndex.Imagens_Satelite,
    FilterIndex.Ocupacao,
    FilterIndex.Textura_Solo,
    FilterIndex.Operacoes_Executadas,
    FilterIndex.Numero_De_Aplicacoes,
    FilterIndex.Produtividade,
    FilterIndex.Idade_Do_Talhao,
    FilterIndex.Ordens_De_Servicos_Abertas,
    FilterIndex.Distribuicao_de_equipes,
  ];

  const isDistribuicaoEquipe =
    tipoMapa === TipoMapaTalhoes.DistribuicaoDeEquipes;

  const isOrdensDeServico = tipoMapa === TipoMapaTalhoes.OrdensDeServicosAberta;

  return (
    <Flex
      w="100%"
      bg="white"
      h="60px"
      position="relative"
      roundedTop="8px"
      roundedBottom={
        indexOpen === null || roundedBottomIndexes.includes(indexOpen) ? 8 : 0
      }
      py="8px"
    >
      <FilterColumn
        flex={0.3}
        isLoading={loadings.unidades}
        onClick={() => onToggle(FilterIndex.Unidade)}
        data-testid="menu-filtros-unidades"
      >
        <Image src={getIconUnidade(unidade?.codigo)} w="55px" h="30px" />
        {FilterIndex.Unidade === indexOpen && <FilterColumnSelected />}
      </FilterColumn>

      <FilterSeparator />

      <FilterColumn
        flex={1}
        isLoading={loadings.ocupacoes}
        onClick={() => onToggle(FilterIndex.Mapa_Tematico)}
        data-testid="menu-filtros-mapas-tematicos"
      >
        <Text>{getMapaTematicoName(tipoMapa)}</Text>
        {indexOpen && roundedBottomIndexes.includes(indexOpen) ? (
          <FilterColumnSelected />
        ) : null}
      </FilterColumn>

      <FilterSeparator />

      <FilterColumn
        flex={0.3}
        isLoading={loadings.safras}
        onClick={() => (_enabledSafra ? onToggle(FilterIndex.Safra) : null)}
        opacity={_enabledSafra ? 1 : 0.3}
        cursor={_enabledSafra ? "pointer" : "not-allowed"}
        data-testid="menu-filtros-safras"
      >
        {isDistribuicaoEquipe || isOrdensDeServico
          ? "---"
          : safra?.descricao?.replace("Safra", "").trim()}
        {FilterIndex.Safra === indexOpen && <FilterColumnSelected />}
      </FilterColumn>

      <Tooltip label="Expandir menu" placement="right-end" borderRadius="5px">
        <IconButton
          aria-label="expand-filters"
          icon={<FaChevronDown color="#848484" width="6px" height="6px" />}
          position="absolute"
          right="20px"
          borderRadius="7px"
          borderTopLeftRadius="0"
          borderTopRightRadius="0"
          background="#ffffff"
          height={isMenuVisible ? "0px !important" : "28px"}
          overflow="hidden"
          // transitionDelay={isMenuVisible ? "none !important" : ".2s !important"}
          _active={{}}
          _focus={{}}
          _hover={{
            height: "38px",
            bottom: "-30px",
          }}
          transition="all .5s"
          bottom={!isMenuVisible ? "-20px" : "0px !important"}
          // justifyContent="center"
          alignItems="flex-end"
          paddingBottom="5px"
          onClick={handleOpenMenu}
          pointerEvents={isMenuVisible ? "none" : undefined}
        />
      </Tooltip>
    </Flex>
  );
}

const enabledSafra = (tipoMapa: TipoMapaTalhoes) => {
  switch (tipoMapa) {
    case TipoMapaTalhoes.Texturas:
      return false;
    case TipoMapaTalhoes.IdadeDoTalhao:
      return false;
    case TipoMapaTalhoes.OrdensDeServicosAberta:
      return false;
    case TipoMapaTalhoes.DistribuicaoDeEquipes:
      return false;
    case TipoMapaTalhoes.Chuvas:
      return false;
    default:
      return true;
  }
};

const getMapaTematicoName = (tipoMapa: TipoMapaTalhoes) => {
  switch (tipoMapa) {
    case TipoMapaTalhoes.Texturas:
      return NomeMapaTematicoEnum.TEXTURA_SOLO;
    case TipoMapaTalhoes.DistribuicaoVarietal:
      return NomeMapaTematicoEnum.DISTRIBUICAO_VARIETAL;
    case TipoMapaTalhoes.Chuvas:
      return NomeMapaTematicoEnum.CHUVAS;
    case TipoMapaTalhoes.Satelite:
      return NomeMapaTematicoEnum.SATELLITE_IMAGE;
    case TipoMapaTalhoes.ConcentracaoColheita:
      return NomeMapaTematicoEnum.CONCENTRACAO_COLHEITA;
    case TipoMapaTalhoes.OperacoesExecutadas:
      return NomeMapaTematicoEnum.OPERACOES_EXECUTADAS;
    case TipoMapaTalhoes.NumeroDeAplicacoes:
      return NomeMapaTematicoEnum.NUMEROS_DE_APLICACOES;
    case TipoMapaTalhoes.Produtividade:
      return NomeMapaTematicoEnum.PRODUTIVIDADE;
    case TipoMapaTalhoes.IdadeDoTalhao:
      return NomeMapaTematicoEnum.IDADE_DO_TALHAO;
    case TipoMapaTalhoes.OrdensDeServicosAberta:
      return NomeMapaTematicoEnum.ORDENS_DE_SERVICO_ABERTA;
    case TipoMapaTalhoes.DistribuicaoDeEquipes:
      return NomeMapaTematicoEnum.DISTRIBUICAO_DE_EQUIPES;
    default:
      return "Mapa temático";
  }
};

export default FilterHeader;
