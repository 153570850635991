import { useContext, useState } from "react";
import axios from "axios";
import {
  Flex,
  Checkbox,
  Button,
  Text,
  Box,
} from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import http from "infra/http";
import URL from "core/resources/urls";
import { useAuth } from "core/features/auth/store";
import { PerfisEnum } from "core/features/users/enums";
import { showConfirm, showSuccess } from "infra/helpers/alerta";
import { JustificativaContext } from "core/features/justificativa/context/Justificativa.context";

const AtrasosFilters = () => {

  const [gerenteRegionalAprovados, setGerenteRegionalAprovados] = useState<boolean>(false);
  const [gerenteRegionalPendentes, setGerenteRegionalPendentes] = useState<boolean>(false);
  const [gerenteUnidadeAprovados, setGerenteUnidadeAprovados] = useState<boolean>(false);
  const [gerenteUnidadePendentes, setGerenteUnidadePendentes] = useState<boolean>(false);
  const [areaTecnicaJustificados, setAreaTecnicaJustificados] = useState<boolean>(false);
  const [areaTecnicaPendentes, setAreaTecnicaPendentes] = useState<boolean>(false);

  const {
    currentUnidade,
    currentSafra,
    currentCultura,
    currentRegional,
    currentMotivo,
    apenasAprovados,
    apenasJustificados,
    apenasAprovadosCiente,
    apenasPendentesJustificativas,
    selecteds,
    page,
    size,
    setApenasAprovados,
    setApenasJustificados,
    setApenasAprovadosCiente,
    setApenasPendentesJustificativas,
  } = useContext(JustificativaContext);

  const qc = useQueryClient();

  const { user } = useAuth();
  const perfis = user?.perfis;
  const hasGerenteRegionalPermission = perfis?.some((i) => i === PerfisEnum.Gerente_Regional);

  const showAprovarJustificativas = 
    hasGerenteRegionalPermission &&
    selecteds &&
    selecteds?.length > 0 && selecteds?.every((item: any) => item.justificado && item.aprovado && !item.ciente);

  const updateAtrasoCiente = () => {
    qc.setQueryData([
      currentUnidade?.id,
      currentSafra?.id,
      currentCultura?.codigo,
      currentRegional,
      currentMotivo?.idPlanoOperacionalOperacaoTipoAtraso,
      apenasAprovados,
      apenasJustificados,
      apenasAprovadosCiente,
      apenasPendentesJustificativas,
      page,
      size,
      URL.JUSTIFICATIVA.ATRASOS,
    ], (data: any) => {
      const atrasos = data?.atrasos?.map((atraso: any) => {
        if (selecteds && selecteds?.includes(atraso)) {
          atraso.ciente = true;
          return atraso;
        }
        return atraso;
      });
      return { total: data?.total, atrasos };
    })
  };

  const getJustificativas = async () => {
    const ids = selecteds ? selecteds?.map((item: any) => item.idTalhaoPlanoOperacionalOperacao) : [];
    const endpoints = ids ? ids.map((item: number) => URL.JUSTIFICATIVA.SALVAR(item)) : [];    
    const response = await axios.all(endpoints.map((endpoint) => http.get(endpoint)));
    const data = response.map((item: any) => item.data).flat(1);
    const idsJustificativas = data.map((item: any) => item.idPlanoOperacionalOperacaoJustificativa);
    const body = idsJustificativas.map((item: number) => ({ idPlanoOperacionalOperacaoJustificativa: item }));
    return body;
  };

  const aprovarJustificativas = async () => {
    const { isConfirmed } = await showConfirm("Deseja realmente aprovar as justificativas?");
    
    if (isConfirmed) {
      const body = await getJustificativas();
      const response = await http.put(URL.JUSTIFICATIVA.CIENTE, body);
      if (response?.status === 200) {
        updateAtrasoCiente();
        showSuccess("Justificativas aprovadas com sucesso");
      }
    }
  };

  const resetCheckboxes = () => {
    setGerenteRegionalAprovados(false);
    setGerenteRegionalPendentes(false);
    setGerenteUnidadeAprovados(false);
    setGerenteUnidadePendentes(false);
    setAreaTecnicaJustificados(false);
    setAreaTecnicaPendentes(false);
  };

  return (
    <Flex flexDirection="row" justifyContent={showAprovarJustificativas ? "space-between" : "end"} alignItems="center" marginTop="24px" width="100%" padding="0 30px">
      {showAprovarJustificativas && (
        <Flex flexDirection="row">
          <Button borderRadius={0} colorScheme="green" onClick={aprovarJustificativas}>
            + Aprovar Justificativas
          </Button>
        </Flex>
      )}

      <Flex flexDirection="row" gridGap="16px">
        <Box border="1px" borderColor="gray.400" padding="16px" position="relative">
          <Text marginBottom="8px" position="absolute" top="-12px" fontSize="16px" backgroundColor="#FAFAFA">
            Gerente Regional
          </Text>
          <Flex gridGap="12px">
            <Checkbox
              isChecked={gerenteRegionalAprovados}
              onChange={e => {
                resetCheckboxes();
                setGerenteRegionalAprovados(e.target.checked);
                setApenasAprovadosCiente(e.target.checked ? true : undefined);
                setApenasAprovados(undefined);
                setApenasJustificados(undefined);
                setApenasPendentesJustificativas(undefined);
              }}
            >Aprovados</Checkbox>
            <Checkbox
              isChecked={gerenteRegionalPendentes}
              onChange={e => {
                resetCheckboxes();
                setGerenteRegionalPendentes(e.target.checked);
                setApenasAprovadosCiente(e.target.checked ? false : undefined);
                setApenasAprovados(e.target.checked ? true : undefined);
                setApenasJustificados(e.target.checked ? true : undefined);
                setApenasPendentesJustificativas(undefined);
              }}
            >Pendentes</Checkbox>
          </Flex>
        </Box>

        <Box border="1px" borderColor="gray.400" padding="16px" position="relative">
          <Text marginBottom="8px" position="absolute" top="-12px" fontSize="16px" backgroundColor="#FAFAFA">
            Gerente Unidade
          </Text>
          <Flex gridGap="12px">
            <Checkbox
              isChecked={gerenteUnidadeAprovados}
              onChange={e => {
                resetCheckboxes();
                setGerenteUnidadeAprovados(e.target.checked);
                setApenasAprovados(e.target.checked ? true : undefined);
                setApenasJustificados(e.target.checked ? true : undefined);
                setApenasAprovadosCiente(undefined);
                setApenasPendentesJustificativas(undefined);
              }}
            >Aprovados</Checkbox>
            <Checkbox
              isChecked={gerenteUnidadePendentes}
              onChange={e => {
                resetCheckboxes();
                setGerenteUnidadePendentes(e.target.checked);
                setApenasAprovados(e.target.checked ? false : undefined);
                setApenasJustificados(e.target.checked ? true : undefined);
                setApenasAprovadosCiente(undefined);
                setApenasPendentesJustificativas(undefined);
              }}
            >Pendentes</Checkbox>
          </Flex>
        </Box>

        <Box border="1px" borderColor="gray.400" padding="16px" position="relative">
          <Text marginBottom="8px" position="absolute" top="-12px" fontSize="16px" backgroundColor="#FAFAFA">
            Área Técnica
          </Text>
          <Flex gridGap="12px">
            <Checkbox
              isChecked={areaTecnicaJustificados}
              onChange={e => {
                resetCheckboxes();
                setAreaTecnicaJustificados(e.target.checked);
                setApenasJustificados(e.target.checked ? true : undefined);
                setApenasAprovados(undefined);
                setApenasAprovadosCiente(undefined);
                setApenasPendentesJustificativas(undefined);
              }}
            >Justificados</Checkbox>
            <Checkbox
              isChecked={areaTecnicaPendentes}
              onChange={e => {
                resetCheckboxes();
                setAreaTecnicaPendentes(e.target.checked);
                setApenasPendentesJustificativas(e.target.checked ? true : undefined);
                setApenasJustificados(undefined);
                setApenasAprovados(undefined);
                setApenasAprovadosCiente(undefined);
              }}
            >Pendentes</Checkbox>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}

export default AtrasosFilters;
