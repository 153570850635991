import { FiltroConcentracaoColheita } from "core/features/maps/typings";
import { AxiosResponse } from "axios";
import http from "infra/http";
import { useQuery } from "react-query";
import { TalhaoResult } from "../typings";
import { mapPropertiesFeature } from "../utils/mapper";
import { useConcentracaoColheitaStore } from "core/features/filters/store/useConcentracaoColheitaStore";
import { useFilters } from "core/features/filters/store";
import { useMapStore } from "../store/useMapStore";
import URL from "core/resources/urls";

let controller: AbortController | null = null;

export function useMapaConcentracaoColheita() {
  const {
    filters: { unidade, safra, tipoMapa, setor },
  } = useFilters();
  const { update: updateMapStore } = useMapStore();

  const { filters } = useConcentracaoColheitaStore();
  const { concentracoes, ocupacao, tecnologias, tipo } = filters;

  const unidadeId = unidade?.id;
  const safraId = safra?.id;

  const url = `${URL.MAPAS.MAPA_CONCENTRACAO_COLHEITA(unidadeId!, safraId!)}`;
  const queryKey = url;

  return useQuery(
    [queryKey, setor, tipoMapa, concentracoes, ocupacao, tipo, tecnologias],
    async (): Promise<TalhaoResult> => {
      const headers = {
        "api-version": "1",
      };

      if (controller) {
        controller.abort();
        controller = null;
      }

      controller = new AbortController();

      const { data } = await http.post<
        FiltroConcentracaoColheita,
        AxiosResponse<TalhaoResult>
      >(
        url,
        {
          setorId: setor?.id,
          tipo,
          concentracoes,
          ocupacao,
          tecnologias: tecnologias.filter((item) => item),
        },
        {
          headers,
          signal: controller.signal,
        }
      );

      data.features.forEach(mapPropertiesFeature);

      updateMapStore(data);

      return data;
    },
    {
      enabled: !!(ocupacao && tipo && tecnologias.length > 0),
    }
  );
}
