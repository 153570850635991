import { Flex, Button, Checkbox, Text, Select } from "@chakra-ui/react";

import { ColumnType } from "antd/lib/table";
import { TalhoesToImportProps } from "core/features/talhoes/typings";

import {
  ArquivosProps,
  TalhaoNaoMapeadoProps,
  ValidarKMLRetProps,
} from "core/features/talhoes/typings/validacaoKML";

import { fallbackText } from "ui/utils";

interface Props {
  handleOpenModal: (row: ArquivosProps) => void;
  talhoesNaoMapeados?: TalhaoNaoMapeadoProps[];
  handleSelectTalhaoAtual: (
    arquivo: ValidarKMLRetProps["arquivo"][0],
    arquivoAtual?: ValidarKMLRetProps["talhoesNaoMapeados"][0]
  ) => void;
  talhoesMapeados: string[];
  talhoesToImport: TalhoesToImportProps[];
  setTalhoesToImport: (prop: TalhoesToImportProps[]) => void;
}

export enum StatusEnum {
  OK = "OK",
  NAO_ENCONTRADO = "NaoEncontrado",
  DUPLICADO = "Duplicado",
}

type SelectTalhoesTableColumnsType = ({
  handleOpenModal,
  handleSelectTalhaoAtual,
  talhoesNaoMapeados,
  talhoesMapeados,
}: Props) => ColumnType<ArquivosProps>[];

const BadgeText = ({
  text,
  statusEnum,
}: {
  text: string;
  statusEnum: string;
}) => {
  if (statusEnum === StatusEnum.NAO_ENCONTRADO) {
    return (
      <Text
        height="24px"
        borderRadius="16px"
        background="#FEEBEB"
        color="#FA4040"
        textAlign="center"
        padding="1px"
        paddingX="5px"
        fontSize="14px"
        // display="inline-block"
      >
        {text}
      </Text>
    );
  }

  if (statusEnum === StatusEnum.DUPLICADO) {
    return (
      <Text
        height="24px"
        borderRadius="16px"
        background="#FFF3E8"
        textAlign="center"
        padding="1px"
        fontSize="14px"
        color="#FFA743"
        display="inline-block"
        paddingX="14px"
      >
        {text}
      </Text>
    );
  }

  return (
    <Text
      height="24px"
      borderRadius="16px"
      background="#E6F7E8"
      color="#38C74C"
      display="inline-block"
      paddingX="14px"
      paddingTop="2px"
    >
      {text}
    </Text>
  );
};

var numberRegexPattern = /[^0-9]/g;

export const selectTalhoesTableColumns: SelectTalhoesTableColumnsType = (
  props: Props
) => [
  {
    title: "TALHÃO(ARQUIVO)",
    dataIndex: "codigo",
    width: "160px",
    className: "column-name",
    defaultSortOrder: "ascend",
    // sortOrder: "ascend",
    sortDirections: [],
    // sortDirections: ["ascend"],
    sorter: (a, b) => {
      const codigo1 = Number(a.codigo.replace(numberRegexPattern, ""));
      const codigo2 = Number(b.codigo.replace(numberRegexPattern, ""));

      return +codigo1 - +codigo2;
    },
  },
  {
    title: "TALHÃO(ATUAL)",

    className: "column-name",
    width: "140px",
    render: (value) => {
      if (
        value.statusEnum === StatusEnum.DUPLICADO ||
        value.statusEnum === StatusEnum.NAO_ENCONTRADO ||
        value.alterado
      ) {
        return (
          <Select
            placeholder="Selecione"
            onChange={(prop) =>
              props.handleSelectTalhaoAtual(
                value,
                prop.target.value === ""
                  ? undefined
                  : JSON.parse(prop.target.value)
              )
            }
          >
            {props.talhoesNaoMapeados?.map((item) => {
              if (
                props.talhoesMapeados.find(
                  (talhao) => talhao === item.codigo
                ) &&
                value?.atual?.codigo !== item.codigo
              ) {
                return null;
              }

              return (
                <option value={JSON.stringify(item)}>{item.codigo}</option>
              );
            })}
          </Select>
        );
      }
      return (
        <Text>{value?.atual?.codigo ? value.atual.codigo : value.codigo}</Text>
      );
    },
  },

  {
    title: "ÁREA",
    dataIndex: "area",
    className: "column-name",
    align: "center",
    render: (item) => {
      return item || fallbackText;
    },
  },
  {
    title: "GEOMETRIA",
    className: "column-name",
    width: "120px",

    render: (value, record) => (
      <Button
        onClick={() => props.handleOpenModal(record)}
        background="white 0% 0% no-repeat padding-box"
        border="1px solid  #DFDFDF"
        borderRadius="4px"
        width="65px"
        height="22px"
        fontSize="12.5px"
        color="#000000"
      >
        Detalhes
      </Button>
    ),
  },
  {
    title: "STATUS",
    dataIndex: "statusDescription",
    className: "column-name",
    width: "145px",
    render: (value, record) => {
      return <BadgeText statusEnum={record.statusEnum} text={value} />;
    },
  },
  {
    title: () => {
      return (
        <Flex alignItems="center" justifyContent="flex-end" gridGap="10px">
          IMPORTAR
        </Flex>
      );
    },
    className: "column-name",
    align: "right",

    render: (value, record) => {
      return (
        <Checkbox
          colorScheme="green"
          isDisabled={!value?.talhaoId || !value.arquivo.wtk}
          isChecked={
            !!props.talhoesToImport.find(
              (item) => item.talhaoId === value?.talhaoId
            )
          }
          onChange={() => {
            const isSelected = !!props.talhoesToImport.find(
              (item) => item.talhaoId === value?.talhaoId
            );
            if (isSelected) {
              props.setTalhoesToImport(
                props.talhoesToImport.filter(
                  (item) => item.talhaoId !== value?.talhaoId
                )
              );
            } else {
              props.setTalhoesToImport([
                ...props.talhoesToImport,
                {
                  talhaoId: value?.talhaoId,
                  wkt: value?.arquivo?.wkt
                    ? value?.arquivo?.wkt
                    : value?.arquivo?.wtk,
                },
              ]);
            }
          }}
        />
      );
    },
  },
];
