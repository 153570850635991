import styled, { css } from "styled-components";

export const Container = styled.div<{ marginLeftInVW: number }>`
  /* margin: 0 auto; */
  display: flexbox;
  justify-content: center;
  align-items: center;
  /* max-width: 100vw; */
  /* 
  ${({ marginLeftInVW }) => marginLeftInVW !== 0 && css``} */

  margin-left: ${({ marginLeftInVW }) => marginLeftInVW}vw;

  transition: all 1s;
`;
