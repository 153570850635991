import { ENDPOINTS, get } from "infra/http/farmApi";
import { UseQueryOptions, useQuery } from "react-query";

export default function useFarmFields(farmId: string | number, params: any = {}, options: UseQueryOptions = {}) {
  const queryOptions: UseQueryOptions = {
    ...options,
  }
  
  const endpoint = ENDPOINTS.FARM_FIELDS
    .replace(/:farm_id/gi, farmId.toString());  

  return useQuery<any>(
    [ENDPOINTS.FARM_FIELDS, farmId, params],
    () => get<any>(endpoint, params),
    queryOptions
  );
}
