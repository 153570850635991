import { useQuery } from "react-query";
import axios from "axios";
import http from "infra/http";
import URL from "core/resources/urls";

interface UseGetAtrasosProps {
  idFazenda?: string;
  codigoOcupacao?: string;
  idSafra?: string;
  regional?: string;
  idPlanoOperacionalOperacaoTipoAtraso?: number;
  apenasAprovados?: boolean;
  apenasJustificados?: boolean;
  apenasAprovadosCiente?: boolean;
  apenasPendentesJustificativas?: boolean;
  page?: number;
  size?: number;
}

export function useGetAtrasos({
  idFazenda,
  codigoOcupacao,
  idSafra,
  regional,
  idPlanoOperacionalOperacaoTipoAtraso,
  apenasAprovados,
  apenasJustificados,
  apenasAprovadosCiente,
  apenasPendentesJustificativas,
  page,
  size,
}: UseGetAtrasosProps) {
  return useQuery(
    [
      idFazenda,
      codigoOcupacao,
      idSafra,
      regional,
      idPlanoOperacionalOperacaoTipoAtraso,
      apenasAprovados,
      apenasJustificados,
      apenasAprovadosCiente,
      apenasPendentesJustificativas,
      page,
      size,
      URL.JUSTIFICATIVA.ATRASOS,
    ],
    async () => {

      const endpoints = [
        URL.JUSTIFICATIVA.ATRASOS(
          idFazenda,
          codigoOcupacao,
          idSafra,
          regional,
          idPlanoOperacionalOperacaoTipoAtraso,
          apenasAprovados,
          apenasJustificados,
          apenasAprovadosCiente,
          apenasPendentesJustificativas,
          page,
          size
        ),
        URL.JUSTIFICATIVA.ATRASOS_TOTAL(
          idFazenda,
          codigoOcupacao,
          idSafra,
          regional,
          idPlanoOperacionalOperacaoTipoAtraso,
          apenasAprovados,
          apenasJustificados,
          apenasAprovadosCiente,
          apenasPendentesJustificativas,
        ),
      ];

      const response = await axios.all(endpoints.map((endpoint: any) => http.get(endpoint)));

      const { data } = response[0];
      const atrasos = data?.map((item: any, index: number) => ({ ...item, key: index }));
      const { data: total } = response[1];

      return { total, atrasos };
    }
  );
}
