import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Text,
  Checkbox,
  useDisclosure,
} from "@chakra-ui/react";
import DatatableAntd from "components/datatable-antd";
import BadgeText from "components/maps/base-map/components/UsersModal/components/BadgeText";
import { AgrodriveContext } from "core/features/agrodrive/contexts/Agrodrive.context";
import { useLoading } from "core/features/global-loading";
import { gray800 } from "core/resources/theme/colors";
import { showConfirm, showError, showSuccess } from "infra/helpers/alerta";
import { useContext, useState } from "react";
import { MdOutlineMoreVert } from "react-icons/md";
import { TableContainerStyled } from "./styles";
import {
  ArquivoResponse,
  DiretorioData,
  UseGetFilesDataProps,
} from "../../types/arquivos";
import { getIcon, getFileSize, sortFiles } from "./utils";
import { FooterAgroTable } from "./FooterAgroTable";
import useDeleteFiles from "../../hooks/useDeleteFiles";
import ModalRename from "../ModalRename";
import ModalMover from "../ModalMover";

export interface AgroTableProps {
  data: UseGetFilesDataProps | ArquivoResponse[];
  isLoading: boolean;
  selecteds: ArquivoResponse[];
  onSelecteds: (items: ArquivoResponse[] | DiretorioData[]) => void;
  onClick: (v: ArquivoResponse | DiretorioData) => void;
}

const getTooltipValues = (list: string[], itemsToRemove: string[]): string => {
  const newList = list.filter((item) => !itemsToRemove.includes(item));
  return newList.join(", ");
};

function AgroTable({
  data,
  isLoading,
  selecteds,
  onSelecteds,
}: AgroTableProps) {
  const [showMenuIndex, setShowMenuIndex] = useState<number | null>(null);
  const [itemToEdit, setItemToEdit] = useState<any>({});
  const { mutate: handleDelete, isLoading: isLoadingDelete } = useDeleteFiles();

  const {
    items: { pagina, arquivos, tree, searchTags, searchTerm },
    onChange: {
      setPagina,
      setTree,
      refetchDiretorioList,
      setSelecteds,
      setPath,
      setUpdating,
      setTagsHerdadas,
    },
  } = useContext(AgrodriveContext);

  useLoading(isLoadingDelete);

  const isChecked = (id: string) =>
    !!selecteds.find((item: any) => item.id === id);

  const handleSelectAll = () => {
    const isAllSelecteds = arquivos.length === selecteds.length;

    if (isAllSelecteds) {
      onSelecteds([]);
    } else {
      onSelecteds(arquivos);
    }
  };

  const handleSelect = (arquivo: ArquivoResponse) => {
    const isAlreadySelected = !!selecteds.find(
      (item) => item.id === arquivo.id
    );

    if (!isAlreadySelected) {
      onSelecteds([...selecteds, arquivo]);
    } else {
      const itemsNotEqual = selecteds.filter((item) => item.id !== arquivo.id);

      onSelecteds(itemsNotEqual);
    }
  };

  const {
    isOpen: isOpenModalRename,
    onOpen: onOpenModalRename,
    onClose: onCloseModalRename,
  } = useDisclosure();

  const {
    isOpen: isOpenModalMover,
    onOpen: onOpenModalMover,
    onClose: onCloseModalMover,
  } = useDisclosure();

  return (
    <>
      {isOpenModalRename ? (
        <ModalRename
          isOpen={isOpenModalRename}
          onClose={onCloseModalRename}
          onOpen={onOpenModalRename}
          item={itemToEdit}
        />
      ) : null}

      {isOpenModalMover ? (
        <ModalMover
          isOpen={isOpenModalMover}
          onClose={onCloseModalMover}
          onOpen={onOpenModalMover}
          item={itemToEdit}
        />
      ) : null}

      <TableContainerStyled>
        <DatatableAntd
          columns={[
            {
              title: () => (
                <Checkbox
                  colorScheme="green"
                  marginTop="3px"
                  marginLeft="3px"
                  width={"18px"}
                  height={"18px"}
                  border="1.5px solid #000"
                  borderRadius="4.8px"
                  onChange={handleSelectAll}
                  isDisabled={arquivos && !arquivos.length}
                  isChecked={
                    arquivos &&
                    arquivos.length > 0 &&
                    arquivos.length === selecteds.length
                  }
                />
              ),
              width: 40,

              render(value: ArquivoResponse) {
                return (
                  <Flex
                    flex={1}
                    position="absolute"
                    bottom="0"
                    top="0"
                    right="0"
                    left="0"
                    alignItems={"center"}
                    justifyContent={"center"}
                    pointerEvents="none"
                  >
                    <Checkbox
                      colorScheme="green"
                      width={"18px"}
                      height={"18px"}
                      marginTop="3px"
                      marginLeft="3px"
                      border="1.5px solid #676B6D"
                      borderRadius="4.8px"
                      pointerEvents="auto"
                      isChecked={
                        !!selecteds.find((item) => item.id === value.id)
                      }
                      onChange={() => {
                        handleSelect(value);
                      }}
                    />
                  </Flex>
                );
              },
            },

            {
              title: "NOME",
              width: 400,
              render(value: ArquivoResponse) {
                return (
                  <Flex
                    flex={1}
                    gridGap="6px"
                    alignItems="center"
                    onClick={() => {
                      if (value.tipo === "Folder") {
                        setPath(value.nome);
                        setTagsHerdadas(value.tags);
                        setTree([...tree, value]);
                      }
                    }}
                  >
                    <Flex>{getIcon(value)}</Flex>
                    <Flex
                      color={gray800}
                      cursor="pointer"
                      display="inline-block"
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                    >
                      {value.nome}
                    </Flex>
                  </Flex>
                );
              },
            },

            {
              title: "",
              width: 30,
              render(value: ArquivoResponse, _, index) {
                const opened =
                  showMenuIndex === index || isChecked(value?.id || "");

                return opened ? (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    position={"absolute"}
                    top={2}
                    left={0}
                    right={0}
                  >
                    <Menu>
                      {({ isOpen }) => (
                        <>
                          <MenuButton onClick={(e) => e.stopPropagation()}>
                            <MdOutlineMoreVert size={24} color="gray.100" />
                          </MenuButton>

                          {isOpen ? (
                            <MenuList
                              position={"relative"}
                              top={0}
                              left={0}
                              right={0}
                            >
                              <MenuItem
                                onClick={() => {
                                  setItemToEdit(value);
                                  onOpenModalMover();
                                }}
                              >
                                Mover para
                              </MenuItem>
                              <MenuItem
                                onClick={(e) => {
                                  setItemToEdit(value);
                                  onOpenModalRename();
                                }}
                              >
                                Renomear
                              </MenuItem>

                              <MenuItem
                                disabled={isLoadingDelete}
                                onClick={async (e) => {
                                  e.stopPropagation();

                                  const { isConfirmed } = await showConfirm();

                                  if (isConfirmed) {
                                    handleDelete([value.id!], {
                                      onSuccess: () => {
                                        setUpdating(true);
                                        refetchDiretorioList();
                                        showSuccess();
                                        setSelecteds([]);
                                      },
                                      onError: (err) => {
                                        showError();
                                      },
                                    });
                                  }
                                }}
                                color="red.800"
                              >
                                Excluir
                              </MenuItem>
                            </MenuList>
                          ) : null}
                        </>
                      )}
                    </Menu>
                  </Flex>
                ) : (
                  <Flex w="30px" />
                );
              },
            },
            {
              title: "TAGS",
              render(value: any) {
                const { tags } = value;
                if (tags) {
                  const tagsShow = tags.slice(0, 3);

                  return (
                    <Flex gridGap={2}>
                      {tagsShow.map((tag: string[], index: number) => (
                        <Tag rounded="full" key={index}>
                          {tag}
                        </Tag>
                      ))}

                      {tags.length > tagsShow.length
                        ? ((
                            <BadgeText
                              text={`+${tags.length - tagsShow.length}`}
                              tooltipText={getTooltipValues(value.tags, [
                                tags[0],
                              ])}
                            />
                          ),
                          (
                            <Tag rounded="full">
                              +{tags.length - tagsShow.length}
                            </Tag>
                          ))
                        : null}
                    </Flex>
                  );
                }
              },
            },

            {
              title: "TAMANHO",
              width: 90,
              render(value: ArquivoResponse) {
                if (value.tipo === "Folder") {
                  return <Text textAlign="center">---</Text>;
                }

                if (value.tamanhoEmBytes)
                  return (
                    <Text textAlign="right">
                      {getFileSize(value.tamanhoEmBytes)}
                    </Text>
                  );
              },
            },
          ]}
          loading={isLoading}
          dataSource={sortFiles(arquivos)}
          onRow={(arquivo) => ({
            className: isChecked(arquivo.id!) ? "row-selected" : "",
            style: {
              cursor: "pointer",
            },
            onMouseEnter: () =>
              setShowMenuIndex(
                (data as UseGetFilesDataProps)?.itens?.findIndex(
                  (d) => d.id === arquivo.id
                )
              ),

            onMouseLeave: () => setShowMenuIndex(null),
          })}
          rowClassName="row"
          scroll={{ y: "calc(100vh - 320px)" }}
          onLoad={() => {
            console.log("TERMS and TAGS", searchTerm, searchTags);
            if (!searchTerm && !searchTags) {
              setPagina(pagina + 1);
            }
          }}
          footer={() => <FooterAgroTable />}
        />
      </TableContainerStyled>
    </>
  );
}

export default AgroTable;
