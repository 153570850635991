import URL from "core/resources/urls";
import http from "infra/http";
import { useQuery } from "react-query";
import {
  OcupacaoETecnologiasReturnProps,
  UseOcupacaoETecnologiasProps,
} from "../typings/ocupacaoETenologias";

export const useOcupacaoETecnologias = ({
  unidadeId,
  safraId,
}: UseOcupacaoETecnologiasProps) => {
  return useQuery(
    [unidadeId, safraId, URL.EDITAR_TALHOES.OCUPACAO_E_TECNOLOGIAS],
    async () => {
      const { data } = await http.get<OcupacaoETecnologiasReturnProps>(
        URL.EDITAR_TALHOES.OCUPACAO_E_TECNOLOGIAS(unidadeId, safraId)
      );
      return {
        items: data.ocupacoes,
      };
    }
  );
};
