import { FiltroMapaDeChuvas, TipoMapaTalhoes } from "features/maps/typings";
import { useFilters } from "../store";
import { useConcentracaoColheitaStore } from "../store/useConcentracaoColheitaStore";
import { FilterSaved } from "../typings";

interface exportFiltersProps {
  mapaDeChuva?: Partial<FiltroMapaDeChuvas>;
}

export function exportFilters({
  mapaDeChuva,
}: exportFiltersProps): FilterSaved {
  const store = useFilters.getState();

  const { tipoMapa } = store.filters;

  const result: FilterSaved = {
    unidade: store.filters.unidade,
    mapType: store.filters.mapType,
    data: null,
    safra: store.filters.safra,
    setor: store.filters.setor,
    tipoMapa,
  };

  if (tipoMapa === TipoMapaTalhoes.ConcentracaoColheita) {
    result.data = useConcentracaoColheitaStore.getState().filters;
  }

  if (tipoMapa === TipoMapaTalhoes.DistribuicaoVarietal) {
    result.data = store.filters.filtroDistribuicaoVarietal;
  }

  if (tipoMapa === TipoMapaTalhoes.Texturas) {
    result.data = store.filters.texturas;
  }

  if (tipoMapa === TipoMapaTalhoes.OperacoesExecutadas) {
    result.data = store.filters.filtroOperacoesExecutadas;
  }

  if (tipoMapa === TipoMapaTalhoes.NumeroDeAplicacoes) {
    result.data = store.filters.filtroNumeroDeAplicacoes;
  }

  if (tipoMapa === TipoMapaTalhoes.Produtividade) {
    result.data = store.filters.filtroProdutividade;
  }

  if (tipoMapa === TipoMapaTalhoes.IdadeDoTalhao) {
    result.data = store.filters.filtroIdadeDoTalhao;
  }

  if (tipoMapa === TipoMapaTalhoes.OrdensDeServicosAberta) {
    result.data = store.filters.filtroOrdensDeServicosAbertas;
  }
  if (tipoMapa === TipoMapaTalhoes.DistribuicaoDeEquipes) {
    result.data = store.filters.filtroDistribuicaoDeEquipes;
  }
  if (tipoMapa === TipoMapaTalhoes.Chuvas) {
    result.data = mapaDeChuva;
  }

  return result;
}
