import URL from "core/resources/urls";
import http from "infra/http";
import { useQuery } from "react-query";

export interface SafraPeriodoProps {
  id: string;
  nome: string;
  nomeOriginal: string;
  ocupacao: number;
}

const useSafraPeriodo = (unidadeId: string, safraId: string) => {
  return useQuery(
    [
      URL.IMPORTAR_TALHOES.SAFRA_PERIODO(unidadeId, safraId),
      unidadeId,
      safraId,
    ],
    async () => {
      const { data } = await http.get(
        URL.IMPORTAR_TALHOES.SAFRA_PERIODO(unidadeId, safraId)
      );

      return {
        items: data as SafraPeriodoProps[],
      };
    }
  );
};

export default useSafraPeriodo;
