import { Collapse, Flex } from "@chakra-ui/react";

import CheckboxFiltro from "components/inputs/checkbox-filtro";
import { useEffect, useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { gray700 } from "core/resources/theme/colors";
import { capitalizeFirstLetter } from "ui/utils";
import { ICheckboxItem } from "../../checkbox-tree";
import { ButtonClearMapCheckbox } from "../../../ButtonLimpar";
import useConcentracaoColheitaItemsFilters from "../hooks/useConcentracaoColheitaItemsFilters";
import NotFoundMapFilters from "components/NotFoundMapFilters";

type onChangeValue = (v: ICheckboxItem[]) => void;

type onChangeFn = (fn: (prev: ICheckboxItem[]) => any) => any;

interface ConcentracaoItemsProps {
  value: ICheckboxItem[];
  onChange: onChangeFn & onChangeValue;
}

function ConcentracaoItems({ value, onChange }: ConcentracaoItemsProps) {
  const {
    items,
    isCheckChild,
    isCheckRoot,
    isCheckedAllChildren,
    isCheckedAllRoots,
  } = useConcentracaoColheitaItemsFilters(value);

  const [collapseds, setCollapseds] = useState<Record<number, boolean>>({});

  useEffect(() => {
    const nextCollapseds: Record<number, boolean> = {};

    items.forEach((_item, index) => {
      nextCollapseds[index] = true;
    });

    setCollapseds(nextCollapseds);
  }, [items]);

  return (
    <>
      <Flex mt="8px">
        {items.length ? (
          <>
            <CheckboxFiltro
              onClick={() => onChange(isCheckedAllRoots ? [] : items)}
              isChecked={isCheckedAllRoots}
              text={"Todos"}
              data-testid="menu-filtros-concentracao-colheita-todos"
            />
            <ButtonClearMapCheckbox onClear={() => onChange([])} />
          </>
        ) : null}
      </Flex>
      <Flex flexDir="column">
        {!items.length ? (
          <Flex mt={4}>
            <NotFoundMapFilters message="Não foram encontradas concentrações para o filtro selecionado" />
          </Flex>
        ) : null}

        {items.map((item, key) => (
          <Flex flexDir="column">
            <Flex key={key} alignItems="center">
              <CheckboxFiltro
                onClick={() => {
                  if (isCheckRoot(item)) {
                    onChange((prev) =>
                      prev.filter((p) => p.value !== item.value)
                    );
                  } else {
                    onChange((prev) =>
                      [...prev, item].filter(
                        (p) => p.parent?.value !== item.value
                      )
                    );
                  }
                }}
                isChecked={isCheckRoot(item)}
                text={capitalizeFirstLetter(item.label)}
                color={item.color}
              />
              {item.children.length ? (
                <Flex
                  cursor="pointer"
                  onClick={() => {
                    collapseds[key] = !collapseds[key];

                    setCollapseds({ ...collapseds });
                  }}
                  ml={2}
                >
                  {!collapseds[key] ? (
                    <BiChevronDown fontSize={20} color="gray.900" />
                  ) : (
                    <BiChevronUp fontSize={20} color="gray.900" />
                  )}
                </Flex>
              ) : null}
            </Flex>

            <Collapse in={collapseds[key]}>
              <Flex flex={1} alignItems="center" mb={1} pl={6}>
                <CheckboxFiltro
                  isChecked={isCheckedAllChildren(item)}
                  size="md"
                  borderColor={gray700}
                  text="Todas"
                  onClick={() => {
                    if (isCheckedAllChildren(item)) {
                      const next = value
                        .filter(
                          (p) => !item.children.find((c) => c.color === p.color)
                        )
                        .filter((p) => p.color !== item.color);

                      onChange([...next]);
                    } else {
                      const addeds = item.children.filter((c) => {
                        if (isCheckChild(c)) {
                          return false;
                        }

                        return true;
                      });

                      onChange((prev) =>
                        [...prev, ...addeds].filter(
                          (c) => c.value !== item.value
                        )
                      );
                    }
                  }}
                />
              </Flex>

              {item.children.map((child, index) => (
                <Flex
                  key={index}
                  pl={6}
                  mb={index === item.children.length - 1 ? 0 : 1}
                >
                  <CheckboxFiltro
                    onClick={() => {
                      if (isCheckChild(child)) {
                        const next = value.filter((p) => {
                          if (
                            p.value === child.value &&
                            p.parent?.value === item.value
                          ) {
                            return false;
                          }

                          return true;
                        });

                        onChange(next);
                      } else {
                        const next = value.filter(
                          (p) => p.value !== item.value
                        );

                        onChange([...next, child]);
                      }
                    }}
                    isChecked={isCheckChild(child)}
                    text={child.label + ` (${child.extras.descricao})`}
                    color={child.color}
                  />
                </Flex>
              ))}
            </Collapse>
          </Flex>
        ))}
      </Flex>
    </>
  );
}

export default ConcentracaoItems;
