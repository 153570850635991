import { Box } from "@chakra-ui/layout";
import { importFilters } from "core/features/filters/services/import-filters";
import { useMenuFilterIndex } from "features/filters/hooks/useMenuFilterIndex";
import { useFilters } from "features/filters/store";
import { useShare } from "features/share-map/hooks/useShare";
import { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { getIndexMapaTematicoForTipoMapa } from "../home/util";

function SharePage() {
  const route = useRouteMatch<{ code: string }>();

  const { setIndexOpen } = useMenuFilterIndex();
  const { updateBootLoading } = useFilters();

  const { data, isFetching } = useShare(route.params.code);

  useEffect(() => {
    updateBootLoading(isFetching);
  }, [updateBootLoading, isFetching]);

  useEffect(() => {
    if (data) {
      const index = getIndexMapaTematicoForTipoMapa(+data.feature);

      importFilters(+data.feature, data.data);

      if (index) {
        setIndexOpen(index);
      }
    }
  }, [data, setIndexOpen]);

  return <Box />;
}

export default SharePage;
