import { Box, Flex, Spacer } from "@chakra-ui/react";
import { AgrodriveButtons } from "./AgrodriveButtons";
import { AgrodriveFiltersTags } from "./AgrodriveFiltersTags";
import { useContext } from "react";
import { AgrodriveContext } from "core/features/agrodrive/contexts/Agrodrive.context";

interface NavHeaderProps {
  localizacao: string;
}

function NavHeader({ localizacao }: NavHeaderProps) {
  const { items, onChange } = useContext(AgrodriveContext);
  return (
    <>
      <Flex mb={4} flexDir="row" flex={1} w="100%" alignItems="center">
        <AgrodriveButtons localizacao={localizacao} />
        <Spacer />
        <Box flexDir={"row"}>
          <AgrodriveFiltersTags
            items={items}
            onChange={onChange}
            disableSearch={true}
          />
        </Box>
      </Flex>
    </>
  );
}

export default NavHeader;
