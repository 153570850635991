import { Button } from "@chakra-ui/react";

interface ButtonLimparProps {
  onClear: () => void;
}
function ButtonLimpar({ onClear }: ButtonLimparProps) {
  return (
    <Button
      onClick={onClear}
      position="absolute"
      right={6}
      top={2}
      variant="ghost"
      fontWeight={"medium"}
      fontSize={15}
      color="gray.700"
      data-testid="menu-filtros-limpar-filtros"
    >
      LIMPAR
    </Button>
  );
}

export function ButtonClearMapCheckbox({ onClear }: ButtonLimparProps) {
  return (
    <Button
      onClick={onClear}
      position="relative"
      ml="auto"
      variant="ghost"
      mt={-2}
      fontWeight={"medium"}
      fontSize={15}
      color="gray.700"
      data-testid="menu-filtros-limpar-filtros"
    >
      LIMPAR
    </Button>
  );
}

export default ButtonLimpar;
