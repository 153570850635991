import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";

import { Modal } from "antd";
import { blue100 } from "core/resources/theme/colors";
import { AiOutlineLink } from "react-icons/ai";

function ModalCopy({
  onOk,
  visible,
  onClose,
  url,
}: {
  url: string;
  onOk: () => void;
  visible: boolean;
  onClose: () => void;
}) {

  return (
    <Modal
      onCancel={() => onClose()}
      title="Compartilhar Mapa"
      className="modal-default"
      visible={visible}
      centered
      footer={
        <Button
          size="sm"
          rounded="12px"
          colorScheme="green"
          onClick={() => {
            onOk();
          }}
        >
          Copiar
        </Button>
      }
    >
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<AiOutlineLink color="gray.300" />}
        />

        <Input rounded={0} bg={blue100} value={url!} readOnly />
      </InputGroup>
      <Box mt="4px">Copie e envie esse link para compartilhar o seu mapa.</Box>
    </Modal>
  );
}

export default ModalCopy;
