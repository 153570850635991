import axios, { AxiosError } from "axios";

import config from "infra/config";
import { refreshToken } from "./utils";

const http = axios.create({
  baseURL: config.urlApi,
});

export const httpAgrodrive = axios.create({
  baseURL: config.urlAgrodriveApi,
})

http.interceptors.request.use(
  (config) => {
    return refreshToken(config);
  },
  (error: AxiosError) => {
    Promise.reject(error);
  }
);

http.interceptors.response.use(
  (config) => {
    return refreshToken(config);
  },
  (error: AxiosError) => {
    Promise.reject(error);
  }
);

export default http;
