import Lottie from "react-lottie";
import animationSplash from "assets/lotties/splash.json";

function Splash() {
  return (
    <Lottie
      options={{
        animationData: animationSplash,
        rendererSettings: {
          clearCanvas: true,
        },
      }}
      width={300}
    />
  );
}

export default Splash;
