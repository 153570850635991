import { MenuItem, MenuItemProps, useToast } from "@chakra-ui/react";

import { useCallback } from "react";

interface TargetProps extends MenuItemProps {
  url: string;
}

export default function Target({ url, children, ...props }: TargetProps) {
  const toast = useToast();

  const handleDownload = useCallback(() => {
    try {
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
    } catch (error: any) {
      toast({
        title: "Erro ao baixar arquivo",
        description: error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [url, toast]);

  return (
    <MenuItem {...props} onClick={handleDownload}>
      {children}
    </MenuItem>
  );
}
