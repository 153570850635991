import ContainerFilter from "../../ContainerFilter";
import { useFiltersLists } from "core/features/filters/store/lists";
import CheckboxFiltro from "components/inputs/checkbox-filtro";
import { useFilters } from "core/features/filters/store";
import { TexturaSoloListagemItem } from "core/features/maps/typings";
import { Flex, IconButton } from "@chakra-ui/react";
import ButtonLimpar from "../../ButtonLimpar";
import LoadingBox from "components/layout/loading-box";
import { FaChevronUp } from "react-icons/fa";

interface FiltersTexturaSoloProps {
  isVisible: boolean;
  handleChangeIsVisible: (prop: boolean) => void;
}

function FiltersTexturaSolo({
  isVisible,
  handleChangeIsVisible,
}: FiltersTexturaSoloProps) {
  const { filters, updateFiltroTexturas } = useFilters();
  const { texturas, loadings } = useFiltersLists();

  const texturasSorted = texturas.sort((a, b) => {
    const n1: number = a.codigo;
    const n2: number = b.codigo;

    return n1 > n2 ? 1 : -1;
  });

  const isCheckedTodos =
    !!filters.texturas &&
    !!texturas?.length &&
    filters.texturas.length === texturas.length;

  const onClickTextura = (textura: TexturaSoloListagemItem) => {
    if (filters.texturas?.find((t) => textura.codigo === t.codigo)) {
      updateFiltroTexturas(
        filters.texturas.filter((t) => t.codigo !== textura.codigo)
      );
    } else {
      updateFiltroTexturas([textura], true);
    }
  };

  const onClear = () => {
    updateFiltroTexturas([]);
  };

  if (!texturas || loadings.texturas) {
    return <LoadingBox isVisible={isVisible} />;
  }

  return (
    <ContainerFilter
      containerProps={{
        mt: 3,
        rounded: 8,
      }}
      isVisible={isVisible}
    >
      <Flex flexDir="column" p={4}>
        {texturasSorted?.length ? (
          <CheckboxFiltro
            disabled={false}
            onClick={() =>
              updateFiltroTexturas(isCheckedTodos ? [] : texturasSorted)
            }
            isChecked={isCheckedTodos}
            text={"Todos"}
            data-testid="menu-filtros-texturas-todos"
          />
        ) : null}

        {texturasSorted?.map((textura, index) => (
          <CheckboxFiltro
            key={index}
            text={textura.nome}
            onClick={() => onClickTextura(textura)}
            color={textura.cor}
            data-testid={`menu-filtro-texturas-${textura.nome}`}
            isChecked={
              !!filters.texturas?.find((t) => t.codigo === textura.codigo)
            }
          />
        ))}
      </Flex>
      <ButtonLimpar onClear={onClear} />
      <Flex
        padding="5px"
        flexDirection="row-reverse"
        borderTop="1px solid #84848440 "
      >
        <IconButton
          background="none"
          _hover={{}}
          _focus={{}}
          _active={{}}
          icon={<FaChevronUp color="#848484" />}
          aria-label="close-button"
          marginRight="20px"
          onClick={() => handleChangeIsVisible(false)}
        />
      </Flex>
    </ContainerFilter>
  );
}

export default FiltersTexturaSolo;
