import ContainerFilter from "../../ContainerFilter";
import MapaTematicoItem from "./MapaTematicoItem";
import { Types_Map } from "core/features/maps/data";
import useAuthenticatedUser from "core/features/user/hooks/useAuthenticatedUser";

function FiltersMapaTematico({
  onClick,
  data,
}: {
  onClick: (item: Types_Map) => void;
  data: Types_Map[];
}) {
  const user = useAuthenticatedUser();

  return (
    <ContainerFilter title="Escolha um mapa temático" isVisible>
      {data &&
        data.map((item: Types_Map, key: number) =>
          item.enabled ? (
            <MapaTematicoItem
              key={item.id}
              onClick={() => {
                // Google Analytics | Dimension Tracking
                gtag("event", "thematic_map_view", {
                  user_id: user?.username,
                  user_email: user?.username,
                  map: item?.nome,
                });

                onClick(item);
              }}
              item={item}
              isBorderBottom={key !== data.length - 1}
            />
          ) : null
        )}
    </ContainerFilter>
  );
}

export default FiltersMapaTematico;
