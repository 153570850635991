import { TableColumnType, Table } from "antd";
import {
  OperacoesRealizadas,
  OperacoesRealizadasDetalhes,
} from "core/features/maps/typings/operacoesRealizadas";
import { formatMoney } from "infra/helpers/number";
import moment from "moment";
import { fallbackText } from "ui/utils";

const moneyToNumberFixed = (money: string) => {
  const numberFixed = Number(money.replace(",", ".")).toFixed(3);
  const stringFormatted = String(numberFixed).replace(".", ",");

  return stringFormatted;
};

export const columnTablesOperacoesRealizadas: TableColumnType<OperacoesRealizadas>[] =
  [
    Table.EXPAND_COLUMN,
    {
      key: "1",
      title: "DATA",
      width: 110,
      render: (item) => {
        return moment(item.dataAbertura).format("DD/MM/YYYY");
      },
    },
    {
      key: "2",
      title: "OPERAÇÃO",
      dataIndex: "descricao",
    },
    {
      key: "3",
      title: "ORDEM",
      width: 80,
      dataIndex: "numero",
    },
    {
      key: "4",
      title: "ORIGEM",
      width: 140,
      dataIndex: "origem",
    },
    {
      key: "5",
      title: "EQUIPE",
      dataIndex: "equipe",
    },
    {
      key: "6",
      title: "ÁREA(HA)",
      width: 120,
      align: "right",
      render(record: OperacoesRealizadas) {
        return formatMoney(record.areaHa, "");
      },
    },
    {
      key: "7",
      title: `US$/HA`,
      width: 80,
      align: "right",
      render(record: OperacoesRealizadas) {
        if (record.custoTotal === 0) {
          return fallbackText;
        }

        return `$${formatMoney(record.custoTotal).replace("R$", "").trim()}`;
      },
    },
  ];

export const columnTablesOperacoesRealizadasDetalhes: TableColumnType<OperacoesRealizadasDetalhes>[] =
  [
    {
      key: "1",
      title: "INSUMO",
      width: 220,
      dataIndex: "insumoDescricao",
    },
    {
      key: "2",
      title: "GRUPO",
      width: 160,
      dataIndex: "insumoGrupoDescricao",
    },
    {
      key: "3",
      title: "PROGRAMADO",
      width: 140,
      align: "right",
      render(record) {
        return `${moneyToNumberFixed(
          formatMoney(record.doseProgramada, "")
        )} ${record.unidadeMedidaSigla.toUpperCase()}`;
      },
    },
    {
      key: "4",
      title: "REALIZADO",
      width: 200,
      align: "right",
      render(record) {
        return `${moneyToNumberFixed(
          formatMoney(record.doseRealizada, "")
        )} ${record.unidadeMedidaSigla.toUpperCase()}`;
      },
    },
    {
      key: "5",
      title: "ÁREA (HA)",
      width: 120,
      align: "right",
      render(record: OperacoesRealizadasDetalhes) {
        return formatMoney(record.areaExecutada, "");
      },
    },
    {
      key: "6",
      title: `US$/HA`,
      width: 100,
      align: "right",
      render(record: OperacoesRealizadasDetalhes) {
        return formatMoney(record.custo, "");
      },
    },
  ];
