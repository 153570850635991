import create from "zustand";

type ImageOverlay = {
    url: string;
    bounds: { west: number; east: number; north: number; south: number } | undefined;
}

export type ImageOverlayState = {
    farmImageOverlay: ImageOverlay | undefined;
    setFarmImageOverlay: (overlay: ImageOverlay | undefined) => void;
    sectorImageOverlay: ImageOverlay | undefined;
    setSectorImageOverlay: (overlay: ImageOverlay | undefined) => void;
    fieldImageOverlay: ImageOverlay | undefined;
    setFieldImageOverlay: (overlay: ImageOverlay | undefined) => void;
    reset: () => void;
}

const useFieldImageOverlayStore = create<ImageOverlayState>(set => ({
    farmImageOverlay: undefined,
    setFarmImageOverlay: (overlay: ImageOverlay | undefined) => set(state => ({
        ...state,
        farmImageOverlay: overlay
    })),
    sectorImageOverlay: undefined,
    setSectorImageOverlay: (overlay: ImageOverlay | undefined) => set(state => ({
        ...state,
        sectorImageOverlay: overlay
    })),
    fieldImageOverlay: undefined,
    setFieldImageOverlay: (overlay: ImageOverlay | undefined) => set(state => ({
        ...state,
        fieldImageOverlay: overlay
    })),
    reset: () => set(state => ({
        ...state,
        farmImageOverlay: undefined,
        sectorImageOverlay: undefined,
        fieldImageOverlay: undefined
    }))
}));

export default useFieldImageOverlayStore;
