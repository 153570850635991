import {
  Modal,
  ModalContent,
  ModalOverlay,
  Image,
  Flex,
  Text,
  Button,
  Spinner,
} from "@chakra-ui/react";
import AvisoRed from "assets/images/icons/aviso-red.svg";
import { showError } from "infra/helpers/alerta";
import { useState } from "react";

interface UpdateConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  submit: () => Promise<any>;
}

const UpdateConfirmationModal = ({
  isOpen,
  onClose,
  submit,
}: UpdateConfirmationModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Modal isOpen={isOpen} onClose={() => {}} isCentered>
      <ModalOverlay />
      <ModalContent
        padding="40px 56px"
        minHeight="313px"
        minW="466px"
        borderRadius="10px"
      >
        <Flex justifyContent="center">
          <Image src={AvisoRed} width="89px" height="89px" />
        </Flex>
        <Text
          color="#676C77"
          fontSize="20px"
          fontWeight="bold"
          marginTop="13px"
          width="100%"
        >
          Deseja realmente iniciar a atualização?
        </Text>
        <Text
          width="100%"
          color="#676C77"
          fontSize="20px"
          marginTop="2px"
          textAlign="center"
          paddingRight="3px"
          css={{
            span: {
              fontWeight: "bold",
            },
          }}
        >
          Esta ação pode ser <span> irreversível </span>
        </Text>
        <Flex
          justifyContent="center"
          alignItems="center"
          gridGap="15px"
          marginTop="23px"
        >
          <Button
            background="white"
            border="1px solid #5B5B5B"
            color="#5B5B5B"
            borderRadius="8px"
            width="117px"
            height="53px"
            fontWeight="bold"
            onClick={onClose}
            isDisabled={isLoading}
          >
            Cancelar
          </Button>
          <Button
            background="#F07577"
            color="white"
            borderRadius="8px"
            width="117px"
            height="53px"
            fontWeight="bold"
            isDisabled={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true);
                await submit();
              } catch (err) {
                setIsLoading(false);
                showError();
              } finally {
                setIsLoading(false);
              }
            }}
          >
            {isLoading ? <Spinner thickness="4px" /> : "Iniciar"}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
export default UpdateConfirmationModal;
