import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 50vw;
  margin: 50px auto;
  justify-content: space-between;
`;

export const BackLine = styled.div<{ postionInVW: number }>`
  position: absolute;
  right: 0;
  left: 0;
  z-index: 0;
  height: 3px;
  background: #b1b3be;
  background-image: linear-gradient(#11a557, #11a557);
  background-position: ${({ postionInVW }) => postionInVW}vw 0;
  background-repeat: no-repeat;
  transition: all 1s;
`;

export const Step = styled.div<{ active?: boolean }>`
  position: relative;
  width: 30px;
  height: 30px;
  background: #fafafa;
  border: ${({ active }) =>
    !active ? "2px solid #B1B3BE" : "2px solid #11a557"};
  margin-top: -14px;
  z-index: 1;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: linear-gradient(#11a557, #11a557);
  background-position: ${({ active }) => (active ? "0px 0px" : "-30px 0px")};
  background-repeat: no-repeat;

  transition: background 0.5s;
  transition-delay: ${({ active }) => (!active ? 0 : 1)}s;

  ::after {
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background: #fafafa;
  }
`;
