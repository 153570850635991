import {
  FiltroDistribuicaoVarietal,
  Finalidade,
  KeyItemsOcupacoes,
  Ocupacao,
  Tecnologia,
  Variedade,
  VariedadeGrupo,
} from "core/features/maps/typings";
import * as _ from "lodash";

const isEqualWithoutColor = (obj: any, other: any) => {
  const _obj = { ...obj };
  const _other = { ...other };

  if (_obj.color) {
    delete _obj.color;
  }

  if (_other.color) {
    delete _other.color;
  }

  return _.isEqual(_obj, _other);
};
const getFinalidadesFromOcupacoes = (ocupacoes: Ocupacao[]) => {
  const finalidades: Finalidade[] = [];

  ocupacoes.forEach((ocupacao) => finalidades.push(...ocupacao.finalidades));

  return finalidades;
};

const getTecnologiasFromOcupacoes = (ocupacoes: Ocupacao[]) => {
  const tecnologias: Tecnologia[] = [];

  const finalidades = getFinalidadesFromOcupacoes(ocupacoes);
  finalidades.forEach((finalidade) =>
    tecnologias.push(...finalidade.tecnologias)
  );

  return tecnologias;
};

const getVariedadeGruposFromOcupacoes = (ocupacoes: Ocupacao[]) => {
  const variedadeGrupos: VariedadeGrupo[] = [];

  const tecnologias = getTecnologiasFromOcupacoes(ocupacoes);
  tecnologias.forEach((tecnologia) =>
    variedadeGrupos.push(...tecnologia.variedadeGrupos)
  );

  return variedadeGrupos;
};

const getVariedadesFromOcupacoes = (ocupacoes: Ocupacao[]) => {
  const variedades: Variedade[] = [];

  const variedadeGrupos = getVariedadeGruposFromOcupacoes(ocupacoes);
  variedadeGrupos.forEach((variedadeGrupo) =>
    variedades.push(...variedadeGrupo.variedades)
  );

  return variedades;
};

const getOcupacoesIntersect = (
  ocupacoes: Ocupacao[],
  filtro: FiltroDistribuicaoVarietal
) => {
  return ocupacoes.filter((ocupacao) =>
    filtro.ocupacoes.find((o) => o.codigo === ocupacao.codigo)
  );
};

const getFinalidadesIntersect = (
  finalidades: Finalidade[],
  filtro: FiltroDistribuicaoVarietal
) => {
  return finalidades.filter((finalidade) =>
    filtro.finalidades.find((f) => isEqualWithoutColor(f, finalidade))
  );
};

const getTecnologiasIntersect = (
  tecnologias: Tecnologia[],
  filtro: FiltroDistribuicaoVarietal
) => {
  return tecnologias.filter((tecnologia) =>
    filtro.tecnologias.find((t) => isEqualWithoutColor(t, tecnologia))
  );
};

const getVariedadeGruposIntersect = (
  variedadeGrupos: VariedadeGrupo[],
  filtro: FiltroDistribuicaoVarietal
) => {
  return variedadeGrupos.filter((variedadeGrupo) =>
    filtro.variedadeGrupos.find((v) => isEqualWithoutColor(v, variedadeGrupo))
  );
};

const getVariedadesIntersect = (
  variedades: Variedade[],
  filtro: FiltroDistribuicaoVarietal
) => {
  return variedades.filter((variedade) =>
    filtro.variedades.find((v) => isEqualWithoutColor(v, variedade))
  );
};

export const getOcupacoesItemsInFiltro = (
  ocupacoes: Ocupacao[],
  filtro: FiltroDistribuicaoVarietal | null
): FiltroDistribuicaoVarietal => {
  if (!filtro) {
    return {
      finalidades: [],
      ocupacoes: ocupacoes.map((ocupacao) => ({
        ...ocupacao,
        id: ocupacao.codigo,
      })),
      tecnologias: [],
      variedadeGrupos: [],
      variedades: [],
    };
  }

  const finalidadesInOcupacoes = getFinalidadesFromOcupacoes(ocupacoes);
  const tecnologiasInOcupacoes = getTecnologiasFromOcupacoes(ocupacoes);
  const variedadeGruposInOcupacoes = getVariedadeGruposFromOcupacoes(ocupacoes);
  const variedadesInOcupacoes = getVariedadesFromOcupacoes(ocupacoes);
  return {
    ocupacoes: getOcupacoesIntersect(ocupacoes, filtro).map((ocupacao) => ({
      ...ocupacao,
      id: ocupacao.codigo,
    })),
    finalidades: getFinalidadesIntersect(finalidadesInOcupacoes, filtro),
    tecnologias: getTecnologiasIntersect(tecnologiasInOcupacoes, filtro),
    variedadeGrupos: getVariedadeGruposIntersect(
      variedadeGruposInOcupacoes,
      filtro
    ),
    variedades: getVariedadesIntersect(variedadesInOcupacoes, filtro),
  };
};

export const getCheckedChildrenColors = (
  item: Ocupacao | Finalidade | Tecnologia | Variedade | VariedadeGrupo,
  keyItem: KeyItemsOcupacoes,
  filtro: FiltroDistribuicaoVarietal | null
) => {
  if (!filtro) {
    return [];
  }
  const childrenColors: string[] = [];

  if (keyItem === KeyItemsOcupacoes.ocupacoes) {
    const finalidadeChildren = getChildrenForItem(
      item,
      keyItem
    ) as Finalidade[];
    const tecnologiaChildren = _.flattenDeep(
      finalidadeChildren.map(
        (finalidade) =>
          getChildrenForItem(
            finalidade,
            KeyItemsOcupacoes.finalidades
          ) as Tecnologia[]
      )
    );
    const variedadeGrupoChildren = _.flattenDeep(
      tecnologiaChildren.map(
        (tecnologia) =>
          getChildrenForItem(
            tecnologia,
            KeyItemsOcupacoes.tecnologias
          ) as VariedadeGrupo[]
      )
    );
    const variedadeChildren = _.flattenDeep(
      variedadeGrupoChildren.map(
        (variedadeGrupo) =>
          getChildrenForItem(
            variedadeGrupo,
            KeyItemsOcupacoes.variedadeGrupos
          ) as Variedade[]
      )
    );

    const finalidadeChildrenInFiltro = getFinalidadesIntersect(
      finalidadeChildren,
      filtro
    );
    const tecnologiaChildrenInFiltro = getTecnologiasIntersect(
      tecnologiaChildren,
      filtro
    );
    const variedadeGrupoChildrenInFiltro = getVariedadeGruposIntersect(
      variedadeGrupoChildren,
      filtro
    );
    const variedadeChildrenInFiltro = getVariedadesIntersect(
      variedadeChildren,
      filtro
    );

    childrenColors.push(
      ...finalidadeChildrenInFiltro.map((finalidade) => finalidade.color),
      ...tecnologiaChildrenInFiltro.map((tecnologia) => tecnologia.color),
      ...variedadeGrupoChildrenInFiltro.map(
        (variedadeGrupo) => variedadeGrupo.color
      ),
      ...variedadeChildrenInFiltro.map((variedade) => variedade.color)
    );
  }

  if (keyItem === KeyItemsOcupacoes.finalidades) {
    const tecnologiaChildren = getChildrenForItem(
      item,
      keyItem
    ) as Tecnologia[];

    const variedadeGruposChildren = _.flattenDeep(
      tecnologiaChildren.map(
        (tecnologia) =>
          getChildrenForItem(
            tecnologia,
            KeyItemsOcupacoes.tecnologias
          ) as VariedadeGrupo[]
      )
    );

    const variedadeChildren = _.flattenDeep(
      variedadeGruposChildren.map(
        (variedadeGrupo) =>
          getChildrenForItem(
            variedadeGrupo,
            KeyItemsOcupacoes.variedadeGrupos
          ) as Variedade[]
      )
    );

    const tecnologiaChildrenInFiltro = getTecnologiasIntersect(
      tecnologiaChildren,
      filtro
    );
    const variedadeGruposChildrenInFiltro = getVariedadeGruposIntersect(
      variedadeGruposChildren,
      filtro
    );
    const variedadeChildrenInFiltro = getVariedadesIntersect(
      variedadeChildren,
      filtro
    );

    childrenColors.push(
      ...tecnologiaChildrenInFiltro.map((tecnologia) => tecnologia.color),
      ...variedadeGruposChildrenInFiltro.map(
        (variedadeGrupo) => variedadeGrupo.color
      ),
      ...variedadeChildrenInFiltro.map((variedade) => variedade.color)
    );
  }

  if (keyItem === KeyItemsOcupacoes.tecnologias) {
    const variedadeGruposChildren = getChildrenForItem(
      item,
      keyItem
    ) as VariedadeGrupo[];

    const variedadeChildren = _.flattenDeep(
      variedadeGruposChildren.map(
        (variedadeGrupo) =>
          getChildrenForItem(
            variedadeGrupo,
            KeyItemsOcupacoes.variedadeGrupos
          ) as Variedade[]
      )
    );

    const variedadeGruposChildrenInFiltro = getVariedadeGruposIntersect(
      variedadeGruposChildren,
      filtro
    );

    const variedadesChildrenInFiltro = getVariedadesIntersect(
      variedadeChildren,
      filtro
    );

    childrenColors.push(
      ...variedadeGruposChildrenInFiltro.map(
        (variedadeGrupo) => variedadeGrupo.color
      ),
      ...variedadesChildrenInFiltro.map((variedade) => variedade.color)
    );
  }

  if (keyItem === KeyItemsOcupacoes.variedadeGrupos) {
    const variedadeChildren = getChildrenForItem(item, keyItem) as Variedade[];

    const variedadeChildrenInFiltro = getVariedadesIntersect(
      variedadeChildren,
      filtro
    );

    childrenColors.push(
      ...variedadeChildrenInFiltro.map((variedade) => variedade.color)
    );
  }

  return childrenColors.filter((c) => !!c);
};

export const getChildrenForItem = (
  item: Ocupacao | Tecnologia | Finalidade | Variedade | VariedadeGrupo,
  keyItem: KeyItemsOcupacoes
) => {
  switch (keyItem) {
    case KeyItemsOcupacoes.finalidades:
      return (item as Finalidade).tecnologias;
    case KeyItemsOcupacoes.tecnologias:
      return (item as Tecnologia).variedadeGrupos;
    case KeyItemsOcupacoes.variedadeGrupos:
      return (item as VariedadeGrupo).variedades;
    case KeyItemsOcupacoes.ocupacoes:
      return (item as Ocupacao).finalidades;
    default:
      return [];
  }
};

export const getKeyForIndentLevel = (indent: number): KeyItemsOcupacoes => {
  switch (indent) {
    case 0:
      return KeyItemsOcupacoes.finalidades;
    case 1:
      return KeyItemsOcupacoes.tecnologias;
    case 2:
      return KeyItemsOcupacoes.variedadeGrupos;
    case 3:
      return KeyItemsOcupacoes.variedades;
    default:
      return KeyItemsOcupacoes.ocupacoes;
  }
};
