import http from "infra/http";
import { useQuery } from "react-query";
import URL from "core/resources/urls";
import { TipoMapaTalhoes } from "../typings";

export function useDistribuicaoDeEquipes(
  unidadeId: string,
  tipoMapa: TipoMapaTalhoes
) {
  return useQuery(
    [URL.MAPAS.DISTRIBUICAO_DE_EQUIPES(unidadeId)],
    async () => {
      const { data } = await http.get(
        URL.MAPAS.DISTRIBUICAO_DE_EQUIPES(unidadeId)
      );
      return data;
    },
    {
      enabled: tipoMapa === TipoMapaTalhoes.DistribuicaoDeEquipes,
    }
  );
}
