import { Button, Flex } from "@chakra-ui/react";
import { gray500, green700 } from "core/resources/theme/colors";

interface ActionButtonProps {
  onClick: () => void;
  title?: string;
  active: boolean;
  disabled?: boolean;
  icon?: any;
  testID: string;
}

export const ActionButton = ({
  onClick,
  title,
  active,
  disabled,
  testID,
  icon,
}: ActionButtonProps) => {
  return (
    <>
      <Button
        disabled={disabled}
        data-testid={testID}
        fontSize={16}
        gap={2}
        _hover={{
          opacity: 0.73,
        }}
        ml={2}
        // w={40}
        px={"8px"}
        onClick={() => onClick()}
        borderWidth={1}
        borderColor={active ? green700 : gray500}
        bg={active ? green700 : "white"}
        rounded={20}
        color={active ? "white" : gray500}
      >
        {icon ? <Flex mr="4px">{icon}</Flex> : null}
        <Flex>{title}</Flex>
      </Button>
    </>
  );
};
