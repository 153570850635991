import { GoogleMap } from "@react-google-maps/api";
import { ArquivoType } from "core/features/talhoes/typings/validacaoKML";
import { containerStyle } from "components/maps/base-map/styles";

import { MapTypeId } from "core/features/maps/enums";
import { useContext, useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { TalhoesContext } from "core/features/talhoes/contexts/Talhoes.context";
import { ImportTalhoesContext } from "core/features/talhoes/contexts/ImportTalhoes.context";

interface TalhaoMapInfoProps {
  item?: ArquivoType;
  label: string;
  type: "Atual" | "Arquivo";
  area?: string;
}

const TalhaoMapInfo = (prop: TalhaoMapInfoProps) => {
  const [map, setMap] = useState<google.maps.Map>();
  const { currentUnidade } = useContext(TalhoesContext);
  const { safraPeriodo } = useContext(ImportTalhoesContext);

  const geojson = prop.item?.geoJson
    ? JSON.parse(prop.item.geoJson)
    : undefined;

  useEffect(() => {
    if (map) {
      map.setMapTypeId(MapTypeId.SATELLITE);
      if (geojson) {
        map.data.addGeoJson(geojson);
      }
      if (!prop.item?.pontoCentral && geojson) {
        map.setCenter({
          lat: geojson.geometry.coordinates[0][0][1],
          lng: geojson.geometry.coordinates[0][0][0],
        });
      }
      map.data.setStyle((feature) => {
        return {
          strokeColor: "#ffffff",
          fillColor: "#ffffff00",
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, prop]);

  return (
    <Box
      height="419px"
      width="384px"
      boxShadow="0px 1px 3px #0000005E"
      paddingX="16px"
      paddingTop="11px"
      borderRadius="7px"
    >
      <GoogleMap
        onLoad={(map) => setMap(map)}
        mapContainerStyle={{
          ...containerStyle,
          borderRadius: "5px",
          maxHeight: "264px",
        }}
        zoom={14}
        options={{
          panControl: false,
          zoomControl: false,
          scaleControl: false,
          rotateControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          keyboardShortcuts: false,
        }}
        center={{
          lat: prop?.item!.pontoCentral?.lat!,
          lng: prop?.item!.pontoCentral?.long!,
        }}
      ></GoogleMap>
      <Text
        fontWeight="bold"
        color="#11A557"
        fontSize="19px"
        letterSpacing="0.21px"
        marginTop="23px"
      >
        Talhão {prop.label}
      </Text>
      <Text
        fontWeight="bold"
        color="#525252"
        fontSize="16px"
        letterSpacing="0.21px"
        paddingLeft="2px"
      >
        {prop.type === "Arquivo" ? "Arquivo kml" : prop.type}
      </Text>
      <Text
        fontWeight="medium"
        color="#525252"
        fontSize="14px"
        letterSpacing="0.21px"
        paddingLeft="3px"
      >
        {currentUnidade?.nome} {safraPeriodo?.nome.toLocaleUpperCase()}
      </Text>
      <Text></Text>
    </Box>
  );
};

export default TalhaoMapInfo;
