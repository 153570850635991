import { KEY_AUTH_TENANT } from "infra/packages/@azure/instance";

export const eventLogger = (userEmail: string, typeAD?: string) => {
  const tenant = localStorage.getItem(KEY_AUTH_TENANT);
  const logger = {
    userEmail,
    data: new Date().toString(),
    tenant: typeAD || tenant,
  };
  return logger;
};
