import { useFiltersLists } from "features/filters/store/lists";

import delay from "delay";
import { useEffect } from "react";
import { useConcentracaoColheitaStore } from "features/filters/store/useConcentracaoColheitaStore";
import { useFilters } from "features/filters/store";

function useConcentracaoColheitaTecnologiasFilters() {
  const {
    filters: { ocupacao, tecnologias },
    update,
  } = useConcentracaoColheitaStore();

  const { concentracaoColheita } = useFiltersLists();
  const {
    filters: { unidade },
  } = useFilters();

  const selected = concentracaoColheita?.ocupacoes.find(
    (item) => item.codigo === ocupacao
  );

  useEffect(() => {
    (async () => {
      await delay(300);

      if (!tecnologias.length) {
        const firstTecnologiaEnabled = selected?.tecnologias?.find(
          (item) => item.id
        );

        update(
          {
            concentracoes: [],
            tecnologias: firstTecnologiaEnabled
              ? [firstTecnologiaEnabled.id]
              : [],
          },
          true
        );
      }
    })();
  }, [ocupacao, selected?.tecnologias, tecnologias.length, update]);

  useEffect(() => {
    update(
      {
        concentracoes: [],
        tecnologias: [],
      },
      true
    );
  }, [unidade?.id, update]);

  const onChange = (tecnologiaId?: string) => {
    if (tecnologias.length) {
      const findElementIndex = tecnologias.findIndex(
        (item) => item === tecnologiaId
      );
      const newArray: string[] = [...tecnologias];
      if (findElementIndex > -1) {
        if (tecnologias.length === 1) {
          return;
        }
        newArray.splice(findElementIndex, 1);
      } else if (tecnologiaId) {
        newArray.push(tecnologiaId);
      }

      const checkTodos = selected?.tecnologias
        .filter((item) => !!item.id)
        .map((i) => i.id);

      if (!tecnologiaId) {
        update({
          concentracoes: [],
          ocupacao,
          tecnologias: checkTodos,
        });
      } else {
        update({
          concentracoes: [],
          ocupacao,
          tecnologias: newArray,
        });
      }
    }
  };

  return {
    onChange,
    value: tecnologias ?? [],
    items: selected?.tecnologias ?? [],
  };
}

export default useConcentracaoColheitaTecnologiasFilters;
