import { TalhoesContext } from "core/features/talhoes/contexts/Talhoes.context";
import { useContext, useState } from "react";

export const useUploadKMLCode = () => {
  const [code, setCode] = useState<string>("");
  const { setPathsToSave, setImportKMLCode } = useContext(TalhoesContext);

  const getCoordsObjectByCode = () => {
    const codeSplited = code.split(" ");
    const coordsArrayObj = codeSplited.map((coords) => {
      const lat = +coords.split(",")[1];
      const lng = +coords.split(",")[0];

      return {
        lat,
        lng,
      };
    });

    return coordsArrayObj;
  };

  const applyKMLCoords = () => {
    const coords = getCoordsObjectByCode();
    setPathsToSave(coords);
    setImportKMLCode(true);

    setTimeout(() => {
      setCode("");
    }, 1000);
  };

  const handleClear = () => {
    setCode("");
  };

  return {
    items: {
      code,
    },
    onChange: {
      setCode,
      handleClear,
    },
    utils: {
      applyKMLCoords,
    },
  };
};
