import ContainerFilter from "../../ContainerFilter";
import {
  Finalidade,
  FiltroDistribuicaoVarietal,
  KeyItemsOcupacoes,
  Ocupacao,
  Tecnologia,
  Variedade,
  VariedadeGrupo,
} from "core/features/maps/typings";
import OcupacaoItem from "./OcupacaoItem";
import { OcupacaoEnum } from "core/features/maps/enums";
import { Flex, IconButton } from "@chakra-ui/react";
import CheckboxFiltro from "components/inputs/checkbox-filtro";
import { getIcon } from "infra/helpers/ocupacao";
import ButtonLimpar from "../../ButtonLimpar";
import LoadingBox from "components/layout/loading-box";
import NotFoundMapFilters from "components/NotFoundMapFilters";
import { FaChevronUp } from "react-icons/fa";
import MapGridButton from "components/MapGridButton";

function FiltersOcupacao({
  onClick,
  onClickTodosOcupacoes,
  onClear,
  data,
  filtro,
  isLoading,
  handleChangeIsVisible,
  isVisible,
  handleOpenGrid,
}: {
  onClick: (
    items: (Ocupacao | Tecnologia | Finalidade | Variedade | VariedadeGrupo)[],
    keyItem: KeyItemsOcupacoes,
    isChecked: boolean
  ) => any;
  onClickTodosOcupacoes: (checked: boolean) => any;
  onClear: () => any;
  data: Ocupacao[];
  filtro: FiltroDistribuicaoVarietal | null;
  isLoading: boolean;
  isVisible: boolean;
  handleChangeIsVisible: (prop: boolean) => void;
  handleOpenGrid: () => void;
}) {
  const ocupacoes: Ocupacao[] = [...data.map((d) => ({ ...d, id: d.codigo }))];

  const isCheckedTodos =
    filtro?.ocupacoes && filtro?.ocupacoes?.length >= data.length;

  if (!data || isLoading) {
    return <LoadingBox isVisible={isVisible} />;
  }

  return (
    <ContainerFilter
      containerProps={{
        mt: 3,
        rounded: 8,
      }}
      isVisible={isVisible}
    >
      <Flex p={4} flexDir="column">
        {data && !data.length ? (
          <Flex pl={1} mb={1}>
            <NotFoundMapFilters message="Não foram encontadras ocupações para o filtro selecionado" />
          </Flex>
        ) : null}
        <Flex pl={1} mb={1}>
          {data.length ? (
            <CheckboxFiltro
              disabled={false}
              onClick={() => onClickTodosOcupacoes(!isCheckedTodos)}
              isChecked={isCheckedTodos}
              text={"Todos"}
              imageIcon={getIcon(ocupacoes, OcupacaoEnum.TODOS)}
              data-testid={`menu-filtros-ocupacoes-todos`}
            />
          ) : null}
        </Flex>

        {data.length
          ? ocupacoes.map((item, key) => (
              <OcupacaoItem
                ocupacoes={ocupacoes}
                key={key}
                onClick={onClick}
                item={item}
                indent={0}
                index={key}
                keyItem={KeyItemsOcupacoes.ocupacoes}
                filtro={filtro}
                showTodos={false}
                isCollapsed={true}
              />
            ))
          : null}
      </Flex>
      {data.length ? <ButtonLimpar onClear={onClear} /> : null}
      <Flex
        padding="5px"
        justifyContent="space-between"
        borderTop="1px solid #84848440 "
        alignItems="center"
        paddingTop="15px"
        marginTop="10px"
      >
        <MapGridButton handleOpenGrid={handleOpenGrid} />

        <IconButton
          background="none"
          _hover={{}}
          _focus={{}}
          _active={{}}
          icon={<FaChevronUp color="#848484" />}
          aria-label="close-button"
          marginRight="20px"
          onClick={() => handleChangeIsVisible(false)}
        />
      </Flex>
    </ContainerFilter>
  );
}

export default FiltersOcupacao;
