import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import SearchInputFiles from "./SearchInput";

import AgrodriveLogo from "../../../../../../../assets/images/icons/agrodrive/agrodriveIcon.svg";
import { ArquivoResponse } from "../../types/arquivos";

interface File {
  name: string;
  type: string;
  modified: string;
  tags: string[];
  size: string;
}

interface HeaderProps {
  searchingTerm: (prop: string) => void;
  tree?: ArquivoResponse[];
}
const HeaderAgroDrive = ({ searchingTerm, tree }: HeaderProps) => {
  const [searchFiles, setSearchFiles] = useState<string>("");

  function handleChangeSearch(letters: string) {
    searchingTerm(letters);
    setSearchFiles(letters);
  }

  useEffect(() => {
    setSearchFiles("");
  }, [tree]);
  return (
    <>
      <Flex
        as="header"
        w="100%"
        justifyContent="space-between"
        h="24"
        px="12"
        borderColor="gray.500"
        boxShadow="0px 1px 4px #0000007D"
      >
        <Flex flexDir="row">
          <Image src={AgrodriveLogo} />
          <Text
            fontSize="26px"
            fontWeight="medium"
            letterSpacing="tight"
            justifyContent="center"
            color="#676C77"
            mt="8"
            ml="2"
          >
            Agrodrive
          </Text>
        </Flex>

        <Box mt="7">
          <SearchInputFiles
            placeholder="Pesquisar por nome do arquivo ou pasta"
            searchValue={searchFiles}
            handleChangeSearch={(letters) => handleChangeSearch(letters)}
            resetInput={() => handleChangeSearch("")}
          />
        </Box>
      </Flex>
    </>
  );
};

export default HeaderAgroDrive;
