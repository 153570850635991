import { useEffect, useState } from "react";

interface DataOverlayProps extends google.maps.Data.DataOptions {
  geojson: object;
  onAddFeature?: (feature: google.maps.Data.AddFeatureEvent) => void;
  onClick?: (event: google.maps.Data.MouseEvent) => void;
  onContextMenu?: (event: google.maps.Data.MouseEvent) => void;
  onDblClick?: (event: google.maps.Data.MouseEvent) => void;
  onMouseDown?: (event: google.maps.Data.MouseEvent) => void;
  onMouseOut?: (event: google.maps.Data.MouseEvent) => void;
  onMouseOver?: (event: google.maps.Data.MouseEvent) => void;
  onMouseUp?: (event: google.maps.Data.MouseEvent) => void;
  onRemoveFeature?: (event: google.maps.Data.RemoveFeatureEvent) => void;
  onRemoveProperty?: (event: google.maps.Data.RemovePropertyEvent) => void;
  onSetGeometry?: (event: google.maps.Data.SetGeometryEvent) => void;
  onSetProperty?: (event: google.maps.Data.SetPropertyEvent) => void;
}

export default function DataOverlay({
  map,
  geojson,
  ...props
}: DataOverlayProps) {
  const [data, setData] = useState<google.maps.Data | undefined>();

  useEffect(() => {
    if (!data) {
      setData(new google.maps.Data());
    }
    return () => {
      if (data) {
        data.setMap(null);
      }
    };
  }, [data]);

  useEffect(() => {
    let addfeature: google.maps.MapsEventListener | undefined;
    let click: google.maps.MapsEventListener | undefined;
    let contextMenu: google.maps.MapsEventListener | undefined;
    let dblClick: google.maps.MapsEventListener | undefined;
    let mouseDown: google.maps.MapsEventListener | undefined;
    let mouseOut: google.maps.MapsEventListener | undefined;
    let mouseOver: google.maps.MapsEventListener | undefined;
    let mouseUp: google.maps.MapsEventListener | undefined;
    let removeFeature: google.maps.MapsEventListener | undefined;
    let removeProperty: google.maps.MapsEventListener | undefined;
    let setGeometry: google.maps.MapsEventListener | undefined;
    let setProperty: google.maps.MapsEventListener | undefined;
    if (data) {
      data.setMap(map);

      data.addGeoJson(geojson);
      data.setStyle(props?.style ?? {});

      if (props?.onAddFeature) {
        addfeature = data.addListener("addfeature", props.onAddFeature);
      }
      if (props.onClick) {
        click = data.addListener("click", props.onClick);
      }
      if (props.onContextMenu) {
        contextMenu = data.addListener("contextmenu", props.onContextMenu);
      }
      if (props.onDblClick) {
        dblClick = data.addListener("dblclick", props.onDblClick);
      }
      if (props.onMouseDown) {
        mouseDown = data.addListener("mousedown", props.onMouseDown);
      }
      if (props.onMouseOut) {
        mouseOut = data.addListener("mouseout", props.onMouseOut);
      }
      if (props.onMouseOver) {
        mouseOver = data.addListener("mouseover", props.onMouseOver);
      }
      if (props.onMouseUp) {
        mouseUp = data.addListener("mouseup", props.onMouseUp);
      }
      if (props.onRemoveFeature) {
        removeFeature = data.addListener(
          "removefeature",
          props.onRemoveFeature
        );
      }
      if (props.onRemoveProperty) {
        removeProperty = data.addListener(
          "removeproperty",
          props.onRemoveProperty
        );
      }
      if (props.onSetGeometry) {
        setGeometry = data.addListener("setgeometry", props.onSetGeometry);
      }
      if (props.onSetProperty) {
        setProperty = data.addListener("setproperty", props.onSetProperty);
      }
    }
    return () => {
      if (addfeature) {
        google.maps.event.removeListener(addfeature);
      }
      if (click) {
        google.maps.event.removeListener(click);
      }
      if (contextMenu) {
        google.maps.event.removeListener(contextMenu);
      }
      if (dblClick) {
        google.maps.event.removeListener(dblClick);
      }
      if (mouseDown) {
        google.maps.event.removeListener(mouseDown);
      }
      if (mouseOut) {
        google.maps.event.removeListener(mouseOut);
      }
      if (mouseOver) {
        google.maps.event.removeListener(mouseOver);
      }
      if (mouseUp) {
        google.maps.event.removeListener(mouseUp);
      }
      if (removeFeature) {
        google.maps.event.removeListener(removeFeature);
      }
      if (removeProperty) {
        google.maps.event.removeListener(removeProperty);
      }
      if (setGeometry) {
        google.maps.event.removeListener(setGeometry);
      }
      if (setProperty) {
        google.maps.event.removeListener(setProperty);
      }
    };
  }, [map, geojson, data, props]);

  return null;
}
