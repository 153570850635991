import React from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/menu";
import { useAuth } from "core/features/auth/store";
import { AiOutlineUser } from "react-icons/ai";
import { Avatar, Flex, Text } from "@chakra-ui/react";

import { useLogout } from "core/features/auth/hooks/useLogout";
import { Tooltip } from "@chakra-ui/react";
import { useHistory } from "react-router";
import styled from "styled-components";

const AnchorLink = styled.a`
  background-color: none;
  &:hover {
    color: inherit;
  }
`;

const UserMenu = () => {
  const { user } = useAuth();
  const { handleLogout } = useLogout();

  const { push } = useHistory();
  const handleGoToChangeProfile = () => {
    push("/Change-Profile");
  };

  return (
    <Menu>
      <Tooltip label={user?.userName!} placement="right" hasArrow>
        <MenuButton
          cursor="pointer"
          shadow="lg"
          border="2px solid white"
          w="45px"
          h="45px"
          rounded="full"
          overflowY="hidden"
          bgColor="gray.200"
          justifyContent="center"
          alignItems="center"
          bgImage={user?.avatarUrl!}
          bgSize="auto 100%"
          bgPosition={"center"}
          bgRepeat="no-repeat"
          data-testid="button-trigger-menu-perfil"
          marginBottom="20px"
        >
          {!user?.avatarUrl ? <AiOutlineUser size="18px" /> : null}
        </MenuButton>
      </Tooltip>

      <MenuList>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Avatar src={user?.avatarUrl} margin="0 auto" />
          <Text fontWeight="bold" mt="10px">
            {user?.userName}
          </Text>
          <Text>{user?.email}</Text>
        </Flex>

        <MenuDivider />

        <MenuItem
          color="gray.500"
          data-testid="menu-manual-do-usuario"
          onClick={handleGoToChangeProfile}
        >
          Meu perfil
        </MenuItem>
        <MenuItem color="gray.500" data-testid="menu-manual-do-usuario">
          <AnchorLink
            href="https://agrosigprdstorage.blob.core.windows.net/files/AgroSIG%20WEB%20Manual.pdf?sp=r&st=2022-11-29T18:24:32Z&se=2100-11-30T02:24:32Z&spr=https&sv=2021-06-08&sr=b&sig=vAqfZ0d79TY58%2FIB%2BkgmXFaq8SG6AakFn3jiFXWgoU8%3D"
            target="_blank"
            // rel="noreferrer"
          >
            Manual do usuário
          </AnchorLink>
        </MenuItem>
        <MenuItem
          color="gray.500"
          onClick={() => handleLogout()}
          data-testid="menu-perfil-sair"
        >
          Sair
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UserMenu;
