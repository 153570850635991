import URL from "core/resources/urls";
import http from "infra/http";
import { useQuery } from "react-query";

interface UseMapaDeChuvaFiltroIndicesProps {
  unidadeId: string;
  periodo: number;
  dataInicial: string | null;
  dataFinal: string | null;
  setorId: string | null;
  horaInicial: string | null;
  horaFinal: string | null;
}

export function useMapaDeChuvaFiltroIndices({
  unidadeId,
  periodo,
  dataFinal,
  dataInicial,
  setorId,
  horaInicial,
  horaFinal,
}: UseMapaDeChuvaFiltroIndicesProps) {
  const customInitialDate = `${dataInicial}T${horaInicial}-04:00`;
  const customEndDate = `${dataFinal}T${horaFinal}-04:00`;
  const CUSTOM_PEDIODO_CODIGO = 6;

  return useQuery(
    [
      URL.MAPAS.MAPA_DE_CHUVA_FILTRO_INDICES,
      unidadeId,
      periodo,
      dataFinal,
      dataInicial,
      setorId,
      horaInicial,
      horaFinal,
    ],
    async () => {
      const promise = http.get(
        URL.MAPAS.MAPA_DE_CHUVA_FILTRO_INDICES(periodo, unidadeId),
        {
          params: {
            setorId,
            dataInicial:
              periodo === CUSTOM_PEDIODO_CODIGO
                ? customInitialDate
                : dataInicial,
            dataFinal:
              periodo === CUSTOM_PEDIODO_CODIGO ? customEndDate : dataFinal,
          },
        }
      );

      return (await promise).data;
    }
  );
}
