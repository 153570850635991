import './styles.less';
import "antd/dist/antd.less";
import { ConfigProvider } from "antd";

import antPtBR from "antd/lib/locale/pt_BR";

function AntdProvider({ children }: any) {
  return <ConfigProvider locale={antPtBR}>{children}</ConfigProvider>;
}

export default AntdProvider;
