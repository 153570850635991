import URL from "core/resources/urls";
import http from "infra/http";
import { useQuery } from "react-query";
import { Setor } from "../typings";

export function useSetores(
  unidadeId: string,
  safraId: string,
  enadled: boolean
) {
  return useQuery(
    [URL.UNIDADES.SETORES(unidadeId!, safraId!), unidadeId, safraId],
    async () => {
      const { data } = await http.get<Setor[]>(
        URL.UNIDADES.SETORES(unidadeId, safraId)
      );

      return data;
    },
    {
      enabled: (!!unidadeId || !safraId) && enadled,
    }
  );
}
