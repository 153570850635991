import {
  AuthenticatedTemplate,
  AuthenticatedTemplateProps,
  MsalProvider,
  UnauthenticatedTemplate,
  UnauthenticatedTemplateProps,
} from "@azure/msal-react";
import { ReactChild, ReactChildren, ReactElement } from "react";
import { msalAmaggiConfig, msalNuvemConfig } from "./config";

import { PublicClientApplication } from "@azure/msal-browser";

interface AuthProviderProps {
  children: ReactElement | ReactChild | ReactChildren | JSX.Element;
}

export function AuthProvider({ children }: AuthProviderProps) {
  let instance;
  switch (window?.localStorage?.getItem?.("auth_config")) {
    case "nuvem":
      instance = new PublicClientApplication(msalNuvemConfig);
      break;
    case "amaggi":
      instance = new PublicClientApplication(msalAmaggiConfig);
      break;
    default:
      instance = new PublicClientApplication(msalAmaggiConfig);
      break;
  }

  return <MsalProvider instance={instance}>{children}</MsalProvider>;
}

export function Authenticated({
  children,
  ...props
}: AuthenticatedTemplateProps) {
  return <AuthenticatedTemplate {...props}>{children}</AuthenticatedTemplate>;
}

export function Unauthenticated({
  children,
  ...props
}: UnauthenticatedTemplateProps) {
  return (
    <UnauthenticatedTemplate {...props}>{children}</UnauthenticatedTemplate>
  );
}
