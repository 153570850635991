import {
  Box,
  BoxProps,
  Button,
  Flex,
  Icon,
  Skeleton,
  Text,
} from "@chakra-ui/react";

import { BiCalendar } from "react-icons/bi";
import Download from "ui/components/Satellite/Download/Download";
import { Mosaic } from "core/features/image/typing";
import RadioImage from "components/Form/RadioImage";
import RadioImageGroup from "components/Form/RadioImageGroup";
import Refetch from "components/Refetch";
import Target from "ui/components/Satellite/Download/Target";
import ndvi from "assets/images/ndvi.png";
import rgb from "assets/images/rgb.png";
import useAuthenticatedUser from "core/features/user/hooks/useAuthenticatedUser";
import { useEffect } from "react";
import useFarmStore from "core/features/farm/states/useFarmStore";
import useSectorMosaics from "core/features/image/hooks/useSectorMosaics";

interface SectorMosaicMenuProps extends BoxProps {
  defaultValue?: any;
  onChange?: (v: any) => void;
}

export default function SectorMosaicMenu({
  defaultValue,
  onChange,
  ...props
}: SectorMosaicMenuProps) {
  const user = useAuthenticatedUser();

  // Local Filters State
  const { sector, currentInitialDate, currentFinalDate } = useFarmStore();

  // API Requests
  const {
    data,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isPlaceholderData,
    isSuccess,
    isError,
    refetch,
  } = useSectorMosaics(
    sector?.id ?? 0,
    {
      start_date: currentInitialDate,
      finish_date: currentFinalDate,
      page_size: 10,
    },
    {
      enabled: !!sector?.id && !!currentInitialDate && !!currentFinalDate,
      staleTime: Infinity,
    }
  );

  const handleChange = (mosaic: Mosaic, url: string) => {
    let type: string = "";

    switch (url) {
      case mosaic.rgb_uri:
        type = "RGB";
        break;
      case mosaic.ndvi_uri:
        type = "NDVI";
        break;
    }

    // Google Analytics | Dimension Tracking
    gtag("event", "sector_mosaic_view", {
      user_id: user?.username,
      user_email: user?.username,
      farm: sector?.farm_name,
      sector: `${sector?.farm_name} / ${sector?.name}`,
      image_type: type,
    });

    onChange?.({ url: url, boundingBox: mosaic?.geo?.[0] });
  };

  useEffect(() => {
    const first: Mosaic = data?.pages?.[0]?.data?.[0];
    if (!defaultValue && !isPlaceholderData && isSuccess && first) {
      onChange?.({
        url: first.rgb_uri,
        boundingBox: first.geo?.[0],
      });
    }
  }, [defaultValue, data, isPlaceholderData, isSuccess, onChange]);

  return (
    <Box {...props}>
      {data?.pages?.map((group, i) => (
        <Box key={i}>
          {group?.data?.map((mosaic: Mosaic) => (
            <Box
              key={mosaic.ndvi_uri}
              mb={4}
              pb={4}
              borderBottomWidth={1}
              borderBottomColor="gray.100"
            >
              <Box mb={2} color="gray.800">
                <Skeleton
                  mr={1}
                  isLoaded={!isPlaceholderData}
                  display="inline-block"
                >
                  <Icon as={BiCalendar} boxSize={5} mr={2} />
                </Skeleton>
                <Skeleton isLoaded={!isPlaceholderData} display="inline-block">
                  {new Date(mosaic.date).toLocaleDateString("pt-BR")}
                </Skeleton>
              </Box>
              <Flex flexWrap="nowrap" alignItems="flex-end">
                <Box flex={1}>
                  <RadioImageGroup
                    name="type"
                    value={defaultValue?.url}
                    onChange={(url: string) => handleChange(mosaic, url)}
                  >
                    <RadioImage
                      url={rgb}
                      value={mosaic.rgb_uri}
                      isLoading={isPlaceholderData}
                    >
                      RGB
                    </RadioImage>
                    <RadioImage
                      url={ndvi}
                      value={mosaic.ndvi_uri}
                      isLoading={isPlaceholderData}
                    >
                      NDVI
                    </RadioImage>
                  </RadioImageGroup>
                </Box>
                <Box mb={2}>
                  <Skeleton isLoaded={!isPlaceholderData}>
                    <Download>
                      {mosaic.tif_rgb_uri && (
                        <Target px={2} py={1} url={mosaic.tif_rgb_uri}>
                          Baixar TIF RGB
                        </Target>
                      )}
                      {mosaic.tif_ndvi_uri && (
                        <Target px={2} py={1} url={mosaic.tif_ndvi_uri}>
                          Baixar TIF NDVI
                        </Target>
                      )}
                      {mosaic.tif_mosaic_uri && (
                        <Target px={2} py={1} url={mosaic.tif_mosaic_uri}>
                          Baixar TIF Mosaico
                        </Target>
                      )}
                    </Download>
                  </Skeleton>
                </Box>
              </Flex>
            </Box>
          ))}
        </Box>
      ))}
      {isSuccess &&
        (data?.pages?.[0]?.data?.length ? (
          <Flex>
            <Button
              m="auto"
              size="sm"
              disabled={!hasNextPage || isFetchingNextPage}
              onClick={() => fetchNextPage()}
            >
              {isFetchingNextPage
                ? "Carregando ..."
                : hasNextPage
                ? "Carregar mais"
                : "Não há mais dados"}
            </Button>
          </Flex>
        ) : (
          <Text>Não há mosaicos para o período selecionado!</Text>
        ))}
      {isError && (
        <Refetch
          title="Erro ao obter a lista de mosaicos para do setor."
          refetch={refetch}
        />
      )}
    </Box>
  );
}
