import { useFilters } from "core/features/filters/store";
import { useFiltersLists } from "core/features/filters/store/lists";
import * as datefns from "date-fns";

export function useMapaDeChuvaActualIndiceDates() {
  const { filters } = useFilters();
  const { mapaDeChuvas } = useFiltersLists();
  const { filtroMapaDeChuva } = filters;

  const getDatesFromFilter = () => {
    const initialDateSplited = filtroMapaDeChuva?.dataInicial
      ?.split("-")
      .reverse();
    const endDateSplited = filtroMapaDeChuva?.dataFinal?.split("-").reverse();

    const initialDate = initialDateSplited?.reduce((prev, current, array) => {
      return `${prev}/${current}`;
    });

    const endDate = endDateSplited?.reduce((prev, current, array) => {
      return `${prev}/${current}`;
    });

    const repeatInitialDate =
      filtroMapaDeChuva?.dataInicial && !filtroMapaDeChuva?.dataFinal;
    const repeatEndDate =
      filtroMapaDeChuva?.dataFinal && !filtroMapaDeChuva?.dataInicial;

    return {
      initialDate: repeatEndDate ? endDate : initialDate,
      endDate: repeatInitialDate ? initialDate : endDate,
      initialHour: filtroMapaDeChuva?.horaInicial,
      endHour: filtroMapaDeChuva?.horaFinal,
    };
  };

  const getDateFromFiltroRapido = () => {
    const actualPeriodo = mapaDeChuvas?.filtroRapido.find(
      (filtro) => filtro.codigo === filtroMapaDeChuva?.periodo
    );

    const initialDateTransformed = new Date(actualPeriodo?.dataInicial!);
    const initialDateString = actualPeriodo?.dataInicial.toString();
    const finalDateString = actualPeriodo?.dataFinal.toString();

    const EndDateTransformed = new Date(actualPeriodo?.dataFinal!);

    const hourWithOneDigitLengh = 4;

    const initialHour = `${datefns
      .parseISO(initialDateString?.split("-04")[0]!)
      .getHours()}:00`;

    const endHour = `${datefns
      .parseISO(finalDateString?.split("-04")[0]!)
      .getHours()}:00`;

    const initialDate = `${initialDateTransformed.getDate()}/${
      initialDateTransformed.getMonth() + 1
    }/${initialDateTransformed.getFullYear()}`;

    const endDate = `${EndDateTransformed.getDate()}/${
      EndDateTransformed.getMonth() + 1
    }/${EndDateTransformed.getFullYear()}`;

    return {
      initialDate,
      endDate,
      initialHour:
        initialHour.length > hourWithOneDigitLengh
          ? initialHour
          : `0${initialHour}`,
      endHour: endHour.length > hourWithOneDigitLengh ? endHour : `0${endHour}`,
    };
  };

  const values =
    filtroMapaDeChuva?.periodo === 6
      ? getDatesFromFilter()
      : getDateFromFiltroRapido();

  return {
    value: {
      ...values,
    },
  };
}
