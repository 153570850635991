import URL from "core/resources/urls";
import http from "infra/http";
import { useQuery } from "react-query";

interface PerfilDataProps {
  perfilId: string;
  codigo: number;
  nome: string;
  descricao: string;
}

export function usePerfis() {
  return useQuery(URL.PERFIS.PERFIS_LISTA, async () => {
    const { data } = await http.get(URL.PERFIS.PERFIS_LISTA);
    return data as PerfilDataProps[];
  });
}
