import URL from "core/resources/urls";
import http from "infra/http";
import { useQuery } from "react-query";

const useGraficoPluviometrico = (talhaoId: string, filterBy: number) => {
  return useQuery(
    [talhaoId, URL.GRAFICO.INDICES_PLUVIOMETRICOS, filterBy],
    async () => {
      const data = await http.get(
        URL.GRAFICO.INDICES_PLUVIOMETRICOS(talhaoId, filterBy)
      );

      return {
        data: data.data.data,
        options: data.data.options,
      };
    }
  );
};

export default useGraficoPluviometrico;
