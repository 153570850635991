import { PaginateProps } from "core/features/paginate/typings";
import URL from "core/resources/urls";
import http from "infra/http";
import { useInfiniteQuery } from "react-query";

import { ProdutividadeData } from "../typings/produtividade";

export default function useTalhaoProdutividade(
  talhaoId: string | null,
  filter?: number,
  page?: number
) {
  return useInfiniteQuery(
    [URL.TALHOES.PRODUTIVIDADE(talhaoId!), page, filter],
    async ({ pageParam }) => {
      const { data, headers } = await http.get(
        URL.TALHOES.PRODUTIVIDADE(talhaoId!, page),
        {
          params: {
            Page: pageParam ?? 1,
            Ocupacao: filter,
          },
        }
      );

      const { paginate } = headers;

      return {
        data: data as ProdutividadeData[],
        paginate: JSON.parse(paginate) as PaginateProps,
      };
    },
    {
      enabled: !!talhaoId,
      getNextPageParam: (lastPage, pages) => lastPage.paginate.currentPage + 1,
    }
  );
}
