import { Flex, Button, ButtonProps } from "@chakra-ui/react";
import { gray700 } from "core/resources/theme/colors";
import { useConcentracaoColheitaStore } from "features/filters/store/useConcentracaoColheitaStore";
import { useEffect } from "react";
import { useConcentracaoColheitaColhidoFilters } from "../hooks/useConcentracaoColheitaColhidoFilters";

function ColhidoCombo() {
  const {
    updateField,
    filters: { tipo, tecnologias, ocupacao },
  } = useConcentracaoColheitaStore();

  const { items } = useConcentracaoColheitaColhidoFilters();

  useEffect(() => {
    const firstEnabled = items.find((i) => i.enabled);

    if (!tipo) {
      updateField("tipo", firstEnabled?.value);
    } else {
      let item = items.find((i) => i.value === tipo);

      if (!item?.enabled) {
        item = firstEnabled;
      }

      updateField("tipo", item?.value ?? null);
    }
  }, [ocupacao, tecnologias, updateField, tipo, items]);

  return (
    <Flex flex={1} flexDir="row">
      {items.map((item, index) => (
        <ColhidoComboButton
          disabled={!item.enabled}
          borderLeftRadius={index === 0 ? 16 : 0}
          borderRightRadius={index === items.length - 1 ? 16 : 0}
          onClick={() => updateField("tipo", item.value)}
          active={tipo === item.value}
        >
          {item.label}
        </ColhidoComboButton>
      ))}
    </Flex>
  );
}

function ColhidoComboButton(props: ButtonProps & { active: boolean }) {
  return (
    <Button
      borderRight={`1px solid ${gray700}`}
      borderTop={`1px solid ${gray700}`}
      borderBottom={`1px solid ${gray700}`}
      h="30px"
      justifyContent="center"
      flex={1}
      px="16px"
      bg={props.active ? "green.700" : "white"}
      color={props.active ? "white" : "gray.700"}
      py="4px"
      cursor="pointer"
      _active={{
        bg: props.active ? "green.500" : "gray.200",
      }}
      {...props}
    />
  );
}

export default ColhidoCombo;
