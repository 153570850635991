import icon1 from "assets/images/fazendas/1.svg";
import icon2 from "assets/images/fazendas/2.svg";
import icon3 from "assets/images/fazendas/3.svg";
import icon5 from "assets/images/fazendas/5.svg";
import icon8 from "assets/images/fazendas/8.svg";
import icon10 from "assets/images/fazendas/10.svg";
import icon11 from "assets/images/fazendas/11.svg";
import icon12 from "assets/images/fazendas/12.svg";
import icon13 from "assets/images/fazendas/13.svg";
import icon14 from "assets/images/fazendas/14.svg";
import icon15 from "assets/images/fazendas/15.svg";
import icon16 from "assets/images/fazendas/16.svg";
import icon17 from "assets/images/fazendas/17.svg";
import icon18 from "assets/images/fazendas/18.svg";
import all from "assets/images/fazendas/all.svg";

export const getIcon = (cod?: string) => {
  switch (cod) {
    case "1":
      return icon1;
    case "2":
      return icon2;
    case "3":
      return icon3;
    case "5":
      return icon5;
    case "8":
      return icon8;
    case "10":
      return icon10;
    case "11":
      return icon11;
    case "12":
      return icon12;
    case "13":
      return icon13;
    case "14":
      return icon14;
    case "15":
      return icon15;
    case "16":
      return icon16;
    case "17":
      return icon17;
    case "18":
      return icon18;
    default:
      return all;
  }
};
