import jwtDecode from "jwt-decode";
import { showError } from "infra/helpers/alerta";
import { setInstance, TypeAD } from "infra/packages/@azure/instance";
import { Info } from "../typings/auth";
import { useAuth } from "../store";
import moment from "moment";
import { PopupRequest, PublicClientApplication } from "@azure/msal-browser";
import { useState } from "react";

export function useLogin(instance: PublicClientApplication) {
  const { update, updateToken, updateExpirationToken } = useAuth();

  const [inProgress, setInProgress] = useState(false);

  const handleLogin = async (typeAD: TypeAD, request: PopupRequest) => {
    try {
      setInProgress(true);

      const result = await instance.loginPopup(request);

      const decoded = jwtDecode<Info>(result.accessToken);

      updateExpirationToken(moment(result.expiresOn).format());

      updateToken(result.accessToken);

      update({
        email: decoded.preferred_username,
        userName: decoded.name,
      });

      window.localStorage.setItem("auth_config", typeAD);
      setInstance(typeAD);

      window.location.href = `${window.location.href}`;
    } catch (error) {
      console.log("error login", error);
      await showError(
        "Solicite acesso ao seu administrador.",
        "Usuário não encontrado"
      );
    } finally {
      setInProgress(false);
    }
  };

  return {
    handleLogin,
    inProgress,
  };
}
