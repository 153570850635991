import { Flex, Text } from "@chakra-ui/layout";
import {
  getIndexMapaTematicoForTipoMapa,
  getTipoMapaForIndexOpen,
} from "ui/pages/app/home/util";

import ContainerItem from "../../ContainerItem";
import { Image } from "@chakra-ui/image";
import { Types_Map } from "core/features/maps/data";
import { getIcon } from "infra/helpers/mapa-tematico";
import { useMenuFilterIndex } from "core/features/filters/hooks/useMenuFilterIndex";

function MapaTematicoItem({
  item,
  onClick,
  isBorderBottom,
}: {
  item: Types_Map;
  onClick: () => void;
  isBorderBottom: boolean;
}) {
  const { indexOpen } = useMenuFilterIndex();
  const icon = getIcon(item.id);

  const tipoMapa = getTipoMapaForIndexOpen(indexOpen);
  const indexMapaTematico = getIndexMapaTematicoForTipoMapa(tipoMapa);
  const isSelected = item.filterMenuIndex === indexMapaTematico;

  return (
    <ContainerItem
      onClick={onClick}
      isSelected={isSelected}
      isBorderBottom={isBorderBottom}
      data-testid={`menu-filtros-mapas-tematicos-${item.nome}`}
    >
      <Flex w="80px" alignItems="center" justifyContent="center" px="6px">
        <Image src={icon} h="15px" maxW="30px" />
      </Flex>
      <Flex alignItems="center" flex={1}>
        <Text color="gray.900" fontSize="14px">
          {item.nome}
        </Text>
      </Flex>
    </ContainerItem>
  );
}

export default MapaTematicoItem;
