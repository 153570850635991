import { Flex } from "@chakra-ui/react";

function MapControl({
  onClick,
  children,
  testId,
}: {
  children: any;
  onClick?: () => void;
  testId?: string;
}) {
  return (
    <Flex
      data-testid={testId}
      justifyContent="center"
      alignItems="center"
      w="30px"
      h="30px"
      rounded="full"
      cursor="pointer"
      bg="white"
      onClick={onClick}
    >
      {children}
    </Flex>
  );
}

export default MapControl;
