import URL from "core/resources/urls";
import http from "infra/http";
import { useQuery } from "react-query";
import { Unidade } from "../typings";

export function useUnidades() {
  return useQuery(URL.MAPAS.UNIDADES, async () => {
    const { data } = await http.get<Unidade[]>(URL.MAPAS.UNIDADES, {
      headers: {
        "api-version": "2",
      },
    });

    return data;
  });
}
