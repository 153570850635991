import algodao from "assets/images/icons/ocupacoes/algodao.svg";
import soja from "assets/images/icons/ocupacoes/soja.svg";
import milho from "assets/images/icons/ocupacoes/milho.svg";
import sojaAlgodaoMilho from "assets/images/icons/ocupacoes/soja_algodao_milho.svg";
import sojaMilho from "assets/images/icons/ocupacoes/soja_milho.svg";
import sojaAlgodao from "assets/images/icons/ocupacoes/soja_algodao.svg";
import algodaoMilho from "assets/images/icons/ocupacoes/algodao_milho.svg";
import { OcupacaoEnum } from "core/features/maps/enums";
import { Ocupacao } from "core/features/maps/typings";

export const getIcon = (items: Ocupacao[], cod?: OcupacaoEnum): any => {
  const image = getImageIcon(cod);

  if (image) {
    return image;
  }

  const codigos = items.map((item) => item.codigo);

  if (
    codigos.includes(OcupacaoEnum.SOJA) &&
    codigos.includes(OcupacaoEnum.ALGODAO) &&
    codigos.includes(OcupacaoEnum.MILHO)
  ) {
    return sojaAlgodaoMilho;
  }

  if (
    codigos.includes(OcupacaoEnum.ALGODAO) &&
    codigos.includes(OcupacaoEnum.MILHO)
  ) {
    return algodaoMilho;
  }

  if (
    codigos.includes(OcupacaoEnum.ALGODAO) &&
    codigos.includes(OcupacaoEnum.SOJA)
  ) {
    return sojaAlgodao;
  }

  if (
    codigos.includes(OcupacaoEnum.MILHO) &&
    codigos.includes(OcupacaoEnum.SOJA)
  ) {
    return sojaMilho;
  }

  const imageUnic = getImageIcon(codigos[0]);

  return imageUnic;
};

function getImageIcon(cod?: OcupacaoEnum) {
  if (cod === OcupacaoEnum.ALGODAO) {
    return algodao;
  }

  if (cod === OcupacaoEnum.MILHO) {
    return milho;
  }

  if (cod === OcupacaoEnum.SOJA) {
    return soja;
  }

  return null;
}
