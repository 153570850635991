import { loginNuvemRequest, msalNuvemConfig } from "infra/auth/config";

import ButtonLogin from "../ButtonLogin";
import { PublicClientApplication } from "@azure/msal-browser";
import logo from "assets/images/icons/nuvem.svg";
import { useLogin } from "core/features/auth/hooks/useLogin";
import { TypeAD } from "infra/packages/@azure/instance";

function ButtonNuvem() {
  const instance = new PublicClientApplication(msalNuvemConfig);

  const { handleLogin, inProgress } = useLogin(instance);

  return (
    <ButtonLogin
      onClick={() => handleLogin(TypeAD.Nuvem, loginNuvemRequest)}
      title="Acessar com login Nuvem"
      data-testid="button-login-nuvem"
      img={logo}
      color="blue"
      isLoading={inProgress}
    />
  );
}

export default ButtonNuvem;
