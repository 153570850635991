import { useEffect, useState } from "react";

import LoadingBar from "react-top-loading-bar";
import useFarmStore from "core/features/farm/states/useFarmStore";
import useImageLoad from "core/features/image/hooks/useImageLoad";
import useImageOverlayStore from "core/features/image/states/useImageOverlayStore";
import { useToast } from "@chakra-ui/react";

interface ImageLayerProps {
  map: google.maps.Map | undefined;
}

export default function ImageLayer({ map }: ImageLayerProps) {
  const toast = useToast();
  const [groundOverlay, setGroundOverlay] =
    useState<google.maps.GroundOverlay>();
  const { farmImageOverlay, sectorImageOverlay, fieldImageOverlay } =
    useImageOverlayStore();
  const { farm, sector, field } = useFarmStore();

  const { isLoading: isLoadingFarmImage, hasError: hasErrorFarmImage } =
    useImageLoad(farmImageOverlay?.url);
  const { isLoading: isLoadingSectorImage, hasError: hasErrorSectorImage } =
    useImageLoad(sectorImageOverlay?.url);
  const { isLoading: isLoadingFieldImage, hasError: hasErrorFieldImage } =
    useImageLoad(fieldImageOverlay?.url);

  useEffect(() => {
    if (!groundOverlay) {
      setGroundOverlay(
        new google.maps.GroundOverlay("", new google.maps.LatLngBounds())
      );
    }

    return () => {
      if (groundOverlay) {
        groundOverlay.setMap(null);
      }
    };
  }, [groundOverlay]);

  useEffect(() => {
    if (field) {
      groundOverlay?.setValues({
        map,
        url: fieldImageOverlay?.url,
        bounds: new google.maps.LatLngBounds(
          new google.maps.LatLng(
            fieldImageOverlay?.bounds?.south ?? 0,
            fieldImageOverlay?.bounds?.west ?? 0
          ),
          new google.maps.LatLng(
            fieldImageOverlay?.bounds?.north ?? 0,
            fieldImageOverlay?.bounds?.east ?? 0
          )
        ),
      });
    }

    if (sector && !field) {
      groundOverlay?.setValues({
        map,
        url: sectorImageOverlay?.url,
        bounds: new google.maps.LatLngBounds(
          new google.maps.LatLng(
            sectorImageOverlay?.bounds?.south ?? 0,
            sectorImageOverlay?.bounds?.west ?? 0
          ),
          new google.maps.LatLng(
            sectorImageOverlay?.bounds?.north ?? 0,
            sectorImageOverlay?.bounds?.east ?? 0
          )
        ),
      });
    }

    if (farm && !sector && !field) {
      groundOverlay?.setValues({
        map,
        url: farmImageOverlay?.url,
        bounds: new google.maps.LatLngBounds(
          new google.maps.LatLng(
            farmImageOverlay?.bounds?.south ?? 0,
            farmImageOverlay?.bounds?.west ?? 0
          ),
          new google.maps.LatLng(
            farmImageOverlay?.bounds?.north ?? 0,
            farmImageOverlay?.bounds?.east ?? 0
          )
        ),
      });
    }

    return () => {
      if (groundOverlay) {
        groundOverlay.setMap(null);
      }
    };
  });

  useEffect(() => {
    if (hasErrorFarmImage || hasErrorSectorImage || hasErrorFieldImage) {
      toast({
        title: "Houve erro ao carregar a imagem",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [toast, hasErrorFarmImage, hasErrorSectorImage, hasErrorFieldImage]);

  return (
    <>
      <LoadingBar
        progress={
          isLoadingFarmImage || isLoadingSectorImage || isLoadingFieldImage
            ? 80
            : 100
        }
        color="#009540"
        loaderSpeed={4000}
        waitingTime={0}
      />
    </>
  );
}
