import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { AxiosRequestConfig } from "axios";
import { useAuth } from "core/features/auth/store";
import msalInstance, { azureScopes } from "infra/packages/@azure/instance";
import moment from "moment";

export async function refreshToken(
  _config: AxiosRequestConfig 
): Promise<AxiosRequestConfig> {
  const { token, expirationToken, clear: clearAuth } = useAuth.getState();

  const clearAuthData = () => {
    localStorage.clear();
    clearAuth();
    window.location.reload();
  };

  let accessToken = token;

  if (accessToken && _config?.headers && !_config.headers["authorization"]) {
    if (moment(expirationToken).isBefore(moment())) {
      const currentAccount = msalInstance.getActiveAccount();

      await msalInstance
        .acquireTokenSilent({
          scopes: azureScopes(msalInstance.getConfiguration().auth.clientId),
          account: currentAccount!,
          forceRefresh: false,
        })
        .then((responseAccessToken) => {
          if (responseAccessToken) {
            useAuth.setState({
              expirationToken: moment(responseAccessToken.expiresOn).format(),
              token: responseAccessToken.accessToken,
            });

            accessToken = responseAccessToken.accessToken;
          }
        })
        .catch(async (error) => {
          if (error instanceof InteractionRequiredAuthError) {
            const request = {
              scopes: azureScopes(
                msalInstance.getConfiguration().auth.clientId
              ),
              loginHint: currentAccount!.username, // For v1 endpoints, use upn from idToken claims
            };
            // fallback to interaction when silent call fails
            return await msalInstance
              .acquireTokenPopup(request)
              .then((accessTokenResponse) => {
                useAuth.setState({
                  expirationToken: moment(
                    accessTokenResponse.expiresOn
                  ).format(),
                  token: accessTokenResponse.accessToken,
                });
              })
              .catch(() => {
                clearAuthData();
              });
          } else {
            clearAuthData();
          }
        });
    }

    if (accessToken) {
      _config.headers["authorization"] = `Bearer ${accessToken}`;
    }
  } else {
    clearAuthData();
  }

  return _config;
}
