import { useState } from "react";
import {
  Box,
  Text,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react";
import Checkbox from "components/inputs/checkbox";
import DatatableAntd from "components/datatable-antd";
import detailTalhoesColums from "./utils";
import { useTalhoesMapa } from "core/features/maps/hooks/useTalhoesMapa";
import { IoIosClose } from "react-icons/io";

interface DetailFromTalhoesProps {
  isOpen: boolean;
  onClose: () => void;
}

const DetailFromTalhoes = ({ isOpen, onClose }: DetailFromTalhoesProps) => {
  const [filterData, setFilterData] = useState(false);
  
  const { data, isLoading, isFetching } = useTalhoesMapa();

  const dataFiltered = data?.Grid?.filter(item => item.Area > 0.01);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <Box
          position="relative"
          shadow="2xl"
          background="#FFFFFF"
          width="88vw"
          maxW="1202px"
          marginLeft="-25vw"
        >
          <IconButton
            aria-label="close"
            position="absolute"
            background="none"
            _active={{}}
            _focus={{}}
            _hover={{}}
            right="10px"
            top="10px"
            icon={<IoIosClose size="2rem" color="" />}
            onClick={onClose}
          />
          <Text
            marginTop="24px"
            marginLeft="22px"
            marginBottom="23px"
            fontWeight="medium"
            color="#2C2C2C"
            fontSize="24px"
          >
            Detalhes dos talhões em GRID
          </Text>

          <Checkbox
            style={{ marginLeft: "24px", marginBottom: "24px" }}
            onChange={(e) => setFilterData(e.target.checked)}
          >
            <Text fontSize={14}>Exibir apenas talhões com área maior que 0.01 HA</Text>
          </Checkbox>

          <Box marginX="22px" marginBottom="36px">
            <DatatableAntd
              loading={isLoading || isFetching}
              columns={detailTalhoesColums}
              dataSource={(filterData && dataFiltered) ? dataFiltered : data?.Grid}
              scroll={{
                y: "calc(100vh - 300px)",
              }}
            />
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default DetailFromTalhoes;
