import {
  FaRegFileAlt,
  FaRegFolderOpen,
  FaSprayCan,
  FaChartPie,
  FaTractor,
} from "react-icons/fa";
import { CustomFeature } from "core/features/maps/typings";
import TabDetalhes from "ui/components/maps/mapa-geral/components/TabDetalhes";
import TabOrdensServico from "../TabOrdensServico";
import TabOperacoesRealizadas from "../TabOperacoesRealizadas";
import TabAplicacoes from "../TabAplicacoes";
import TabProdutividade from "../TabProdutividade";

export interface TalhaoModalTab {
  label: string;
  Icon: React.ReactNode;
  Component: React.FC<{
    active: boolean;
    filterActive: boolean;
    talhao: CustomFeature;
  }>;
}

const iconProps = {
  fontSize: "20px",
};

export const tabs: TalhaoModalTab[] = [
  {
    label: "DETALHES",
    Icon: <FaRegFileAlt {...iconProps} />,
    Component: TabDetalhes,
  },
  {
    label: "ORDENS DE SERVIÇO",
    Icon: <FaRegFolderOpen {...iconProps} />,
    Component: TabOrdensServico,
  },
  {
    label: "OPERAÇÕES REALIZADAS",
    Icon: <FaTractor {...iconProps} />,
    Component: TabOperacoesRealizadas,
  },
  {
    label: "APLICAÇÕES",
    Icon: <FaSprayCan {...iconProps} />,
    Component: TabAplicacoes,
  },
  {
    label: "PRODUTIVIDADE",
    Icon: <FaChartPie {...iconProps} />,
    Component: TabProdutividade,
  },
];
