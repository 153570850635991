import { useIdadeDoTalhao } from "core/features/maps/hooks/useIdadeDoTalhao";
import { useConcentracaoColheita } from "features/maps/hooks/useConcentracaoColheita";
import { useNumeroDeAplicacoes } from "core/features/maps/hooks/useNumeroDeAplicacoes";
import { useOcupacoes } from "core/features/maps/hooks/useOcupacoes";
import { useOperacoesExecutadas } from "core/features/maps/hooks/useOperacoesExecutadas";
import { useOrdensDeServicosAbertas } from "core/features/maps/hooks/useOrdensDeServicosAbertas";
import { useProdutividade } from "core/features/maps/hooks/useProdutividade";
import { useSafras } from "core/features/maps/hooks/useSafras";
import { useTexturasSolo } from "core/features/maps/hooks/useTexturasSolo";
import { useUnidades } from "core/features/maps/hooks/useUnidades";
import { useEffect } from "react";
import { useFilters } from "../store";
import { useFiltersLists } from "../store/lists";
import { useDistribuicaoDeEquipes } from "core/features/maps/hooks/useDistribuicaoDeEquipes";
import { useMapaDeChuvaFiltroRapido } from "core/features/maps/hooks/useMapaDeChuvaFiltroRapido";
import { useMapaDeChuvaFiltroIndices } from "core/features/maps/hooks/useMapaDeChuvaFiltroIndices";

export function useFiltersBaseLists() {
  const { filters, updateField } = useFilters();
  const {
    updateOcupacoes,
    updateSafras,
    updateUnidades,
    updateTexturasSolo,
    updateOperacoesExecutadas,
    updateProdutividade,
    updateNumeroDeAplicacoes,
    updateConcentracaoColheita,
    updateIdadeDoTalhao,
    updateOrdensDeServicoAbertas,
    updateDistribuicaoDeEquipes,
    updateMapaDeChuvas,
    setLoading,
  } = useFiltersLists();

  const { unidade, safra, texturas, tipoMapa, filtroMapaDeChuva } =
    filters ?? {};

  const {
    data: unidades,
    isLoading: isLoadingUnidades,
    isFetchedAfterMount: isFetchedAfterMountUnidades,
  } = useUnidades();

  const {
    data: safras,
    isLoading: isLoadingSafras,
    isFetchedAfterMount: isFetchedAfterMountSafras,
  } = useSafras();

  const {
    data: texturasItems,
    isLoading: isLoadingTexturas,
    isFetchedAfterMount: isFetchedAfterMountTexturas,
  } = useTexturasSolo(tipoMapa);

  const {
    data: operacoesExecutadasItems,
    isLoading: isLoadingOperacoesExecutadas,
  } = useOperacoesExecutadas(unidade?.id!, safra?.id!, tipoMapa);

  const {
    data: concentracaoColheitaItems,
    isLoading: isLoadingConcentracaoColheitas,
  } = useConcentracaoColheita(unidade?.id!, safra?.id!, tipoMapa);

  const { data: produtividadeItems, isLoading: isLoadingProdutividade } =
    useProdutividade(unidade?.id as string, safra?.id as string, tipoMapa);

  const {
    data: numeroDeAplicacoesItems,
    isLoading: isLoadingNumeroDeAplicacoes,
  } = useNumeroDeAplicacoes(unidade?.id!, safra?.id!, tipoMapa);

  const { data: ocupacoes, isLoading: isLoadingOcupacoes } = useOcupacoes(
    unidade?.id,
    safra?.id,
    tipoMapa
  );

  const {
    data: ordensDeServicosAbertas,
    isLoading: isOrdensDeServicosAbertasLoading,
  } = useOrdensDeServicosAbertas(filters.unidade?.id!, tipoMapa);

  const {
    data: distribuicaodeEquipes,
    isLoading: isDistribuicaoDeEquipesLoading,
  } = useDistribuicaoDeEquipes(filters.unidade?.id!, tipoMapa);

  const { data: idadeDoTalhaoItems, isLoading: isLoadingIdadeDoTalhao } =
    useIdadeDoTalhao(unidade?.id as string, safra?.id as string, tipoMapa);

  const {
    data: mapaDeChuvaFiltroRapido,
    isLoading: isLoadingMapaDeChuvaFiltroRapido,
  } = useMapaDeChuvaFiltroRapido();

  const {
    data: mapaDeChuvaFiltroIndices,
    isLoading: isLoadingMapaDeChuvaFiltroIndices,
  } = useMapaDeChuvaFiltroIndices({
    dataFinal:
      filtroMapaDeChuva?.dataFinal ||
      (mapaDeChuvaFiltroRapido?.find(
        (periodo) => periodo.codigo === filtroMapaDeChuva?.periodo
      )?.dataFinal as string),
    dataInicial:
      filtroMapaDeChuva?.dataInicial ||
      (mapaDeChuvaFiltroRapido?.find(
        (periodo) => periodo.codigo === filtroMapaDeChuva?.periodo
      )?.dataInicial as string),
    periodo: filtroMapaDeChuva?.periodo || 0,
    setorId: filters.setor?.id || null,
    unidadeId: unidade?.id!,
    horaInicial: filtroMapaDeChuva?.horaInicial || null,
    horaFinal: filtroMapaDeChuva?.horaFinal || null,
  });

  useEffect(() => {
    setLoading("unidades", isLoadingUnidades);
  }, [isLoadingUnidades, setLoading]);

  useEffect(() => {
    setLoading("safras", isLoadingSafras);
  }, [isLoadingSafras, setLoading]);

  useEffect(() => {
    setLoading("ocupacoes", isLoadingOcupacoes);
  }, [isLoadingOcupacoes, setLoading]);

  useEffect(() => {
    setLoading("texturas", isLoadingTexturas);
  }, [isLoadingTexturas, setLoading]);

  useEffect(() => {
    setLoading("operacoesExecutadas", isLoadingOperacoesExecutadas);
  }, [isLoadingOperacoesExecutadas, setLoading]);

  useEffect(() => {
    setLoading("concentracaoColheita", isLoadingConcentracaoColheitas);
  }, [isLoadingConcentracaoColheitas, setLoading]);

  useEffect(() => {
    setLoading("produtividade", isLoadingProdutividade);
  }, [isLoadingProdutividade, setLoading]);

  useEffect(() => {
    setLoading("idadeDoTalhao", isLoadingIdadeDoTalhao);
  }, [isLoadingIdadeDoTalhao, setLoading]);

  useEffect(() => {
    setLoading("numeroDeAplicacoes", isLoadingNumeroDeAplicacoes);
  }, [isLoadingNumeroDeAplicacoes, setLoading]);

  useEffect(() => {
    setLoading("ordensDeServicosAbertas", isOrdensDeServicosAbertasLoading);
  }, [isOrdensDeServicosAbertasLoading, setLoading]);

  useEffect(() => {
    setLoading("distribuicaoDeEquipes", isDistribuicaoDeEquipesLoading);
  }, [isDistribuicaoDeEquipesLoading, setLoading]);

  useEffect(() => {
    setLoading(
      "mapaDeChuvas",
      isLoadingMapaDeChuvaFiltroRapido || isLoadingMapaDeChuvaFiltroIndices
    );
  }, [
    isLoadingMapaDeChuvaFiltroRapido,
    isLoadingMapaDeChuvaFiltroIndices,
    setLoading,
  ]);

  useEffect(() => {
    if (ocupacoes) {
      updateOcupacoes(ocupacoes);
    }
  }, [ocupacoes, updateOcupacoes]);

  useEffect(() => {
    updateSafras(safras ?? []);
  }, [safras, updateSafras]);

  useEffect(() => {
    updateTexturasSolo(texturasItems ?? []);
  }, [texturasItems, updateTexturasSolo]);

  useEffect(() => {
    updateOperacoesExecutadas(operacoesExecutadasItems ?? null);
  }, [operacoesExecutadasItems, updateOperacoesExecutadas]);

  useEffect(() => {
    updateConcentracaoColheita(concentracaoColheitaItems ?? null);
  }, [concentracaoColheitaItems, updateConcentracaoColheita]);

  useEffect(() => {
    updateProdutividade(produtividadeItems ?? null);
  }, [produtividadeItems, updateProdutividade]);

  useEffect(() => {
    updateNumeroDeAplicacoes(numeroDeAplicacoesItems ?? null);
  }, [numeroDeAplicacoesItems, updateNumeroDeAplicacoes]);

  useEffect(() => {
    updateIdadeDoTalhao(idadeDoTalhaoItems ?? null);
  }, [idadeDoTalhaoItems, updateIdadeDoTalhao]);
  useEffect(() => {
    updateOrdensDeServicoAbertas(ordensDeServicosAbertas ?? null);
  }, [ordensDeServicosAbertas, updateOrdensDeServicoAbertas]);
  useEffect(() => {
    updateDistribuicaoDeEquipes(distribuicaodeEquipes ?? null);
  }, [distribuicaodeEquipes, updateDistribuicaoDeEquipes]);

  useEffect(() => {
    updateMapaDeChuvas({
      filtroRapido: mapaDeChuvaFiltroRapido ?? [],
      indices: mapaDeChuvaFiltroIndices ?? [],
    });
  }, [updateMapaDeChuvas, mapaDeChuvaFiltroIndices, mapaDeChuvaFiltroRapido]);

  useEffect(() => {
    if (unidades) {
      updateUnidades(unidades);
    }
  }, [unidades, updateUnidades]);

  useEffect(() => {
    if (isFetchedAfterMountUnidades && !unidade && unidades?.length) {
      updateField(
        "unidade",
        unidades.find((item) => item.unidadePadrao)
      );
    }
  }, [isFetchedAfterMountUnidades, updateField, unidade, unidades]);

  useEffect(() => {
    if (isFetchedAfterMountSafras && !safra && safras?.length) {
      updateField(
        "safra",
        safras.find((item) => item.isSafraAtual)
      );
    }
  }, [isFetchedAfterMountSafras, updateField, safra, safras]);

  useEffect(() => {
    if (isFetchedAfterMountTexturas && !texturas && texturasItems?.length) {
      updateField("texturas", texturasItems);
    }
  }, [isFetchedAfterMountTexturas, updateField, texturas, texturasItems]);
}
