import { useQuery } from "react-query";

import URL from "core/resources/urls";
import http from "infra/http";
import { Aplicacoes, StatusInsumosEnum } from "../typings/aplicacoes";

interface useTalhaoOperacoesRealizadasProps {
  talhaoId: string | null;
  numeroOs?: number;
  insumoGrupo: number;
}

export default function useTalhoesAplicacoes({
  talhaoId,
  numeroOs,
  insumoGrupo,
}: useTalhaoOperacoesRealizadasProps) {
  return useQuery(
    [URL.TALHOES.APLICACOES(talhaoId!), numeroOs, insumoGrupo],
    async () => {
      if (insumoGrupo) {
        const { data } = await http.get(URL.TALHOES.APLICACOES(talhaoId!), {
          params: {
            insumoGrupo,
            numeroOs: !!numeroOs ? numeroOs : undefined,
          },
        });

        return {
          aplicacoesTalhao: data,
        } as {
          aplicacoesTalhao: Aplicacoes;
        };
      }
    },
    {
      enabled: !!talhaoId && insumoGrupo !== StatusInsumosEnum.Indicadores,
    }
  );
}
