import { Fragment, useCallback, useEffect } from "react";

import DataInfoOverlay from "../Overlays/DataInfoOverlay";
import DataOverlay from "../Overlays/DataOverlay";
import { Field } from "core/features/farm/typing";
import LoadingBar from "react-top-loading-bar";
import { Position } from "geojson";
import useAuthenticatedUser from "core/features/user/hooks/useAuthenticatedUser";
import useFarmFields from "core/features/farm/hooks/useFarmFields";
import useFarmStore from "core/features/farm/states/useFarmStore";
import useSearchStore from "core/features/search/states/useSearchStore";

interface FieldOverlayProps {
  map: google.maps.Map;
}

export default function FieldLayer({ map }: FieldOverlayProps) {
  const { query } = useSearchStore();

  const user = useAuthenticatedUser();

  const { farm, bounds, sector, field, setField } = useFarmStore();

  const { data, isLoading, isFetching } = useFarmFields(
    farm?.id ?? 0,
    { orderBy: "name" },
    {
      enabled: !!farm?.id,
      staleTime: Infinity,
    }
  );

  const filter = useCallback(
    (item: Field) =>
      query
        ? sector
          ? item.sector_id === sector.id &&
            item.name.toLocaleLowerCase().includes(query.toLowerCase())
          : item.name.toLowerCase().includes(query.toLowerCase())
        : field
        ? item.id === field.id
        : sector
        ? item.sector_id === sector.id
        : true,
    [query, sector, field]
  );

  const handleClick = (event: google.maps.Data.MouseEvent) => {
    const farmName = event.feature.getProperty("farm_name");
    const sectorName = event.feature.getProperty("sector_name");
    const fieldId = event.feature.getProperty("field_id");
    const fieldName = event.feature.getProperty("field_name");

    setField({
      farm_name: farmName,
      sector_name: sectorName,
      id: fieldId,
      name: fieldName,
    });

    // Google Analytics | Dimension Tracking
    gtag("event", "field_view", {
      user_id: user?.username,
      user_email: user?.username,
      farm: farmName,
      sector: `${farmName} / ${sectorName}`,
      field: `${farmName} / ${sectorName} / ${fieldName}`,
    });
  };

  useEffect(() => {
    const bounds = new google.maps.LatLngBounds();
    if (data) {
      data?.filter(filter)?.forEach((item: Field) => {
        item.bounds?.coordinates?.[0]?.forEach((coordinate: Position) => {
          bounds.extend(new google.maps.LatLng(coordinate[1], coordinate[0]));
        });
      });
      if (!bounds.isEmpty()) {
        map.fitBounds(bounds, { left: 400 });
      }
    }
  }, [map, data, filter]);

  return (
    <>
      <LoadingBar
        progress={isLoading || isFetching ? 80 : 100}
        color="#009540"
        loaderSpeed={4000}
        waitingTime={0}
      />
      {data?.filter(filter)?.map((f: Field) => (
        <Fragment key={f.id}>
          {(query || bounds) && (
            <DataInfoOverlay
              map={map}
              position={
                new google.maps.LatLng(
                  f.center?.coordinates[1] ?? 0,
                  f.center?.coordinates[0] ?? 0
                )
              }
              info={f.name}
            />
          )}
          <DataOverlay
            map={map}
            geojson={{
              type: "Feature",
              properties: {
                center: f.center?.coordinates,
                bounds: f.bounds?.coordinates,
                farm_name: f?.farm_name,
                sector_name: f?.sector_name,
                field_id: f.id,
                field_name: f.name,
              },
              geometry: f?.geo,
            }}
            style={{
              fillOpacity: 0,
              strokeColor: "white",
              strokeWeight: bounds ? 1 : 0,
            }}
            onClick={handleClick}
          />
        </Fragment>
      ))}
    </>
  );
}
