import { useEffect } from "react";
import create from "zustand";

export interface UseGlobalLoading {
  isLoading: boolean;
  setLoading: (value: boolean) => void;
}

export const useGlobalLoading = create<UseGlobalLoading>((set) => ({
  isLoading: false,
  setLoading: (value: boolean) => set(() => ({ isLoading: value })),
}));

export const useLoading = (state: boolean) => {
  const { setLoading } = useGlobalLoading();

  useEffect(() => {
    setLoading(state);
  }, [setLoading, state]);
};
