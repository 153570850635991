import { CheckboxProps, Flex, Image, Text } from "@chakra-ui/react";
import { gray300, gray700 } from "core/resources/theme/colors";

import Checkbox from "ui/components/inputs/checkbox";

interface CheckboxFiltroProps extends Omit<CheckboxProps, "color"> {
  imageIcon?: string | null;
  color?: string | null;
  text: string;
  disabled?: boolean;
  onClick: () => any;
}
function CheckboxFiltro({
  imageIcon,
  color,
  text,
  disabled,
  onClick,
  ...rest
}: CheckboxFiltroProps) {
  return (
    <Checkbox
      onChange={() => (!disabled ? onClick() : null)}
      size="md"
      borderColor={disabled ? gray300 : gray700}
      cursor={disabled ? "not-allowed" : "pointer"}
      {...rest}
    >
      <Flex alignItems="center" justifyContent="center">
        {imageIcon ? (
          <Image src={imageIcon || undefined} h="16px" mr={2} />
        ) : color ? (
          <Flex
            h="15px"
            w="15px"
            bg={color}
            mr={2}
            borderRadius="3px"
            border={color === "#FFFFFF" ? "1px solid" : "none"}
            borderColor={color === "#FFFFFF" ? "gray.400" : "none"}
          />
        ) : null}
        <Text
          color={disabled ? gray300 : gray700}
          fontSize="16px"
          fontWeight={rest.isChecked ? "bold" : "400"}
        >
          {text}
        </Text>
      </Flex>
    </Checkbox>
  );
}

export default CheckboxFiltro;
