import { useFilters } from "core/features/filters/store";

export const useIsApplyButtonDisabledOnDistribuicaoVarietal = () => {
  const { filters } = useFilters();
  const { filtroDistribuicaoVarietal } = filters;
  const isChecked =
    !filtroDistribuicaoVarietal?.ocupacoes.length &&
    !filtroDistribuicaoVarietal?.finalidades.length &&
    !filtroDistribuicaoVarietal?.tecnologias.length &&
    !filtroDistribuicaoVarietal?.variedadeGrupos.length &&
    !filtroDistribuicaoVarietal?.variedades.length;

  return {
    disable: isChecked,
  };
};
