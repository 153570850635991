import { useState, useEffect, useCallback } from "react";

import URL from "core/resources/urls";
import http from "infra/http";

export interface InsumosTalhao {
  codigo: number;
  descricao: string;
}

export default function useTalhoesInsumosGrupos(talhaoId: string | null) {
  const [loadingInsumosDisponiveis, setLoadingInsumosDisponiveis] =
    useState<boolean>(false);
  const [insumos, setInsumos] = useState<InsumosTalhao[]>([]);

  const getTalhoesInsumosGrupos = useCallback(async () => {
    if (!talhaoId) {
      return;
    }

    try {
      setLoadingInsumosDisponiveis(true);
      const { data } = await http.get(
        URL.INSUMOS_TALHOES.INSUMOS_TALHAO(talhaoId)
      );
      setInsumos(data);
      setLoadingInsumosDisponiveis(false);
    } catch (error) {
      console.error(error);
    }
  }, [talhaoId]);

  useEffect(() => {
    getTalhoesInsumosGrupos();
  }, [getTalhoesInsumosGrupos]);

  return { insumos, loadingInsumosDisponiveis };
}
