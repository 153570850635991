import { useState, useContext } from "react";
import { Box, Flex } from "@chakra-ui/react";
import AgroTable from "./agrodrive/components/AgroTable/AgroTable";
import BreadcrumbAgrodrive from "./agrodrive/components/Breadcrumb";
import HeaderAgroDrive from "./agrodrive/components/Header";
import NavHeader from "./agrodrive/components/NavHeader";
import {
  ArquivoResponse,
  DiretorioData,
  UseGetFilesDataProps,
} from "./agrodrive/types/arquivos";
import { AgrodriveContext } from "core/features/agrodrive/contexts/Agrodrive.context";
import { ModalNewFolderContextProvider } from "core/features/agrodrive/contexts/ModalNewFolder.context";
import { ModalNewFileContextProvider } from "core/features/agrodrive/contexts/ModalNewFile.context";

const Arquivos = () => {
  const [, setCurrentFolder] = useState<ArquivoResponse | DiretorioData>();

  const {
    items: { tree, selecteds, path, diretorioData, isFetchingDiretorios },
    onChange: { setTree, setSelecteds, refetchDiretorioList, searchingTerm },
  } = useContext(AgrodriveContext);

  return (
    <ModalNewFileContextProvider>
      <ModalNewFolderContextProvider>
        <Flex flex={1} bgColor="white" flexDir={"column"}>
          <HeaderAgroDrive searchingTerm={searchingTerm} tree={tree} />
          <Box mt="4" px="12">
            <BreadcrumbAgrodrive
              items={tree}
              onChange={(v) => {
                setTree(v);
                setCurrentFolder(v.length ? v[v.length - 1] : undefined);
              }}
            />
          </Box>

          <Box px="12">
            <NavHeader localizacao={path} />
          </Box>

          <Box px="12">
            <AgroTable
              data={diretorioData as UseGetFilesDataProps}
              selecteds={selecteds}
              onSelecteds={(items) => {
                setSelecteds(items);
              }}
              onClick={(item) => {
                setSelecteds([...selecteds, item]);
              }}
              isLoading={isFetchingDiretorios}
            />
          </Box>
        </Flex>
      </ModalNewFolderContextProvider>
    </ModalNewFileContextProvider>
  );
};

export default Arquivos;
