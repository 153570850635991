export const fallbackText = "---";

export const formatString = (text: string) => {
  return text.length > 20 ? text.substring(0, 20) + "..." : text;
};

export const capitalizeFirstLetter = (text: string) => {
  return text[0].toUpperCase() + text.slice(1).toLowerCase();
};

export const numberWithCommas = (x: number | string) => {
  const formatted = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const lastIndex = formatted.lastIndexOf(".");
  if (lastIndex === -1) {
    return x;
  }
  const replacement = ",";
  return (
    formatted.substring(0, lastIndex) +
    replacement +
    formatted.substring(lastIndex + 1)
  );
};
