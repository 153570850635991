import { Component, ErrorInfo, ReactNode } from "react";
import { Button, Image, Text, Icon } from "@chakra-ui/react";
import { AiOutlineReload } from "react-icons/ai";
import { ErrorBoundaryComponent } from "./styles";

import balaoError from "assets/images/error-boundary/balao-boundary.png";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? (
      <ErrorBoundaryComponent>
        <Image src={balaoError} w="600px" h="auto" />
        <Text color="gray.800" fontWeight="bold" mt={8} fontSize="32px">
          Oops!
        </Text>
        <Text color="gray.800" fontWeight="bold" fontSize="32px">
          Algo está errado :(
        </Text>
        <Button
          leftIcon={<Icon as={AiOutlineReload} fontSize="20px" fontWeight="bold" />}
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
          _hover={{
            backgroundColor: 'green.700'
          }}
          mt="24px"
          cursor="pointer"
          fontWeight="bold"
          borderRadius="30px"
          bg="green.500"
          w="180px"
          h="45px"
          border="none"
          color="white"
        >
          Atualizar Página
        </Button>
      </ErrorBoundaryComponent>
    ) : (
      children
    );
  }
}

export default ErrorBoundary;
