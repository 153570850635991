import { Flex, Box, FlexProps } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";

interface FilterColumnProps extends FlexProps {
  onClick: () => void;
  isLoading: boolean;
  children: any;
  flex?: number;
}
function FilterColumn({
  onClick,
  isLoading,
  children,
  flex,
  ...rest
}: FilterColumnProps) {
  return (
    <Flex
      cursor="pointer"
      flex={flex ?? 1}
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      {...rest}
    >
      <Box
        fontSize="16px"
        fontWeight="bold"
        color="gray.900"
        textAlign="center"
      >
        {isLoading ? <Skeleton w="40px" h="20px" /> : null}
        {!isLoading ? children : null}
      </Box>
    </Flex>
  );
}

export default FilterColumn;
