import { Flex, Skeleton } from "@chakra-ui/react";

function LoadingSkeleton() {
  return (
    <Flex
      gridGap="16px"
      flex={1}
      mt="32px"
      height="0"
      overflowY="hidden"
      minHeight="70vh"
    >
      <Flex flex={0.5} gridGap="16px" flexDir="column">
        <Skeleton w="280px" h="20px" />
        <Skeleton w="5px" h="60px" />
        <Skeleton w="250px" h="20px" />
        <Skeleton w="5px" h="60px" />
        <Skeleton w="320px" h="20px" />
        <Skeleton w="5px" h="60px" />
        <Skeleton w="180px" h="20px" />
      </Flex>
      <Flex flex={1} gridGap="8px" flexDir="column" overflow="hidden">
        <Flex height="300px" width="100%">
          <Skeleton width="100%" height="300px" />
        </Flex>

        <Skeleton w="200px" h="20px" />
        <Skeleton w="100%" h="10px" mb={8} />
        <Skeleton w="100px" h="10px" />
        <Skeleton w="80px" h="10px" />

        <Flex gridGap="16px">
          <Skeleton w="120px" h="120px" />
          <Skeleton w="120px" h="120px" />
          <Skeleton w="120px" h="120px" />
          <Skeleton w="120px" h="120px" />
        </Flex>

        <Flex gridGap="16px">
          <Skeleton w="120px" h="120px" />
          <Skeleton w="120px" h="120px" />
          <Skeleton w="120px" h="120px" />
          <Skeleton w="120px" h="120px" />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default LoadingSkeleton;
