import React from "react";
import { Box, Button, Flex, Input } from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";

const focusInputStyles = {
  borderColor: "#707070",
};

interface SearchInputFilesProps {
  searchValue: string;
  placeholder: string;
  handleChangeSearch: (letter: string) => void;
  resetInput: () => void;
}

const SearchInputFiles = ({
  searchValue,
  placeholder,
  handleChangeSearch,
  resetInput,
}: SearchInputFilesProps) => {
  return (
    <Flex>
      <Flex flex="1" justifyContent={"flex-end"} alignItems="flex-end">
        <Box
          display="flex"
          flexDirection="row"
          borderColor="#9E9A9A"
          borderRadius="32px"
          border="0.5px solid"
          alignItems={"center"}
          paddingRight="10px"
        >
          <Input
            border="none"
            height="39px"
            minW="413px"
            color="gray.800"
            placeholder={placeholder}
            data-testid="input-search-agrodrive"
            _focus={focusInputStyles}
            value={searchValue}
            onChange={({ target }) => handleChangeSearch(target.value)}
          />
          <Button
            size="sm"
            background="none"
            _hover={{ background: "none" }}
            _active={{ background: "none", border: "none" }}
            _focus={{ border: "none" }}
          >
            {searchValue === "" ? (
              <FaSearch size="18px" />
            ) : (
              <MdClose size="26px" onClick={resetInput} />
            )}
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};
export default SearchInputFiles;
