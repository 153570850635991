import { ApiPagination } from "./../typings/index";
import URL from "core/resources/urls";
import http from "infra/http";
import { useInfiniteQuery } from "react-query";
import {
  OperacoesRealizadas,
  PropertieCustoTotal,
} from "../typings/operacoesRealizadas";

interface useTalhaoOperacoesRealizadasProps {
  talhaoId: string | null;
  numeroOs?: number;
}

export default function useTalhaoOperacoesRealizadas({
  talhaoId,
  numeroOs,
}: useTalhaoOperacoesRealizadasProps) {
  return useInfiniteQuery(
    [URL.TALHOES.OPERACOES_REALIZADAS(talhaoId!), numeroOs],
    async ({ pageParam }) => {
      const { data, headers } = await http.get(
        URL.TALHOES.OPERACOES_REALIZADAS(talhaoId!),
        {
          params: {
            page: pageParam ?? 1,
            numeroOs: !!numeroOs ? numeroOs : undefined,
            size: 100,
          },
        }
      );
      const { paginate, properties } = headers;

      return {
        operacoes: data,
        paginate: JSON.parse(paginate),
        properties: JSON.parse(properties),
      } as {
        operacoes: OperacoesRealizadas[];
        paginate: ApiPagination;
        properties: PropertieCustoTotal;
      };
    },
    {
      enabled: !!talhaoId,
      getNextPageParam: (lastPage, pages) => lastPage.paginate.currentPage + 1,
    }
  );
}
