import OcupacoesButtons from "../../../OcupacoesButtons";
import useConcentracaoColheitaOcupacoesFilters from "../hooks/useConcentracaoColheitaOcupacoesFilters";

function ConcentracaoOcupacoes() {
  const { value, onChange, items } = useConcentracaoColheitaOcupacoesFilters();

  return (
    <OcupacoesButtons
      onChange={onChange}
      value={value}
      items={items ?? []}
    />
  );
}

export default ConcentracaoOcupacoes;
