import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Image,
  useToast,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";

import OcupacoesButtons from "components/inputs/filter-safra-unidade-mapatematico/OcupacoesButtons";
import SelectedButtons from "components/inputs/filter-safra-unidade-mapatematico/SelectedButtons";
import { FaSearch } from "react-icons/fa";

import MapFilter from "./MapFIlter";

import AvisoIcon from "assets/images/icons/aviso.svg";
import TrashRedIcon from "assets/images/icons/trash-red.svg";

import TalhoesIcon from "assets/images/talhoes.svg";

import { useOcupacaoETecnologias } from "core/features/talhoes/hooks/useOcupacaoETecnologias";
import TalhoesTable from "./TalhoesTable";

import { useListaEGeojson } from "core/features/talhoes/hooks/useListaEGeojson";
import { TalhoesProps } from "core/features/talhoes/typings/listaEGeojson";
import { salvarTalhaoEditado } from "core/features/talhoes/service/salvarTalhaoEditado";
import { FaBan, FaCheck } from "react-icons/fa";
import MapEditionMode from "./MapEditionMode";

import {
  ModalModeEnum,
  TalhoesContext,
} from "core/features/talhoes/contexts/Talhoes.context";

export interface CurrentItemProps {
  talhao: number;
  variedade: string;
  tecnologia: string;
  ocupacao: number;
  area: string;
  coords: { lat: number; lng: number }[];
}

const TalhoesFilters = () => {
  const {
    currentSafra,
    currentUnidade,
    ocupacao,
    tecnologias,
    currentItem,
    nextItem,
    modalMode,
    shouldShowCancelModal,
    isOpen,
    pathsToSave,
    onClose,
    onOpen,
    setCurrentItem,
    setOcupacao,
    setNextTecs,
    setNextItem,
    setModalMode,
    setShouldShowCancelModal,
    setNextOcupacao,
    setPathsToSave,
  } = useContext(TalhoesContext);

  const toast = useToast();
  const { data } = useOcupacaoETecnologias({
    safraId: currentSafra!.id,
    unidadeId: currentUnidade!.id,
  });
  const [showEditOptions, setShowEditOptions] = useState<boolean>(false);

  const [inputSearch, setInputSearch] = useState<string>("");
  const [ableToSave, setAbleToSave] = useState<boolean>(shouldShowCancelModal);
  const [ableToDelete, setAbleToDelete] = useState<boolean>(true);

  const {
    data: dataLista,
    refetch,
    isFetching,
  } = useListaEGeojson({
    safraId: currentSafra!.id,
    unidadeId: currentUnidade!.id,
    Ocupacao: ocupacao,
    Tecnologias: tecnologias,
  });

  const numberRegexPattern = /[^0-9]/g;
  dataLista?.items.talhoes.sort((a, b) => {
    const codigo1 = Number(a.codigo.replace(numberRegexPattern, ""));
    const codigo2 = Number(b.codigo.replace(numberRegexPattern, ""));

    return +codigo1 - +codigo2;
  });

  const currentItemGeojson = dataLista?.items.geoJson?.features.find(
    (item) => item.properties.codigo === currentItem?.codigo
  );

  const searchResults = dataLista?.items.talhoes.filter(
    (item) =>
      item.codigo
        .toLocaleLowerCase()
        .includes(inputSearch.toLocaleLowerCase()) ||
      item.variedade
        .toLocaleLowerCase()
        .includes(inputSearch.toLocaleLowerCase())
  );

  const getTecnologias = useCallback(() => {
    const tecsToReturn = data?.items.find((item) => item.codigo === ocupacao);
    return tecsToReturn?.tecnologias!;
  }, [data?.items, ocupacao]);

  const eraseAll = () => {
    setShowEditOptions(false);

    setCurrentItem(undefined);
    setPathsToSave(undefined);
    setShouldShowCancelModal(false);
  };

  const handleCancel = () => {
    if (shouldShowCancelModal) {
      setModalMode(ModalModeEnum.cancel);
      onOpen();
      return;
    }
  };

  const handleSave = async () => {
    try {
      if (pathsToSave) {
        const valueToRepeat = pathsToSave[0]!;
        const shouldRepeatValue =
          pathsToSave[0].lat !== pathsToSave[pathsToSave.length - 1].lat &&
          pathsToSave[0].lng !== pathsToSave[pathsToSave.length - 1].lng;
        setAbleToSave(false);
        await salvarTalhaoEditado({
          talhaoId: currentItem?.talhaoId!,
          path: shouldRepeatValue
            ? [...pathsToSave!, valueToRepeat]
            : pathsToSave!,
        });
        toast({
          title: "Polígono salvo com sucesso!.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        await refetch();
        eraseAll();
      }
    } catch (err: any) {
      setAbleToSave(true);
      if (
        err.response.data.Details[0].Errors[0].includes(
          "Formato de geometria inválido!"
        )
      ) {
        setModalMode(ModalModeEnum.invalidPolygon);
        setShouldShowCancelModal(true);
        onOpen();
      }
    }
  };

  const handleDelete = async () => {
    try {
      await salvarTalhaoEditado({
        talhaoId: currentItem?.talhaoId!,
        path: [],
      });
      toast({
        title: "Polígono deletado com sucesso!.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      await refetch();

      eraseAll();

      onClose(true);
    } catch (err) {
      toast({
        title: "Houve um erro ao deletar o polígono!.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  useEffect(() => {
    if (ocupacao !== data?.items[0].codigo) return;
    setOcupacao(data?.items[0].codigo!);
    setNextTecs(data?.items[0].tecnologias.map((item) => item.id)!);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, ocupacao]);

  useEffect(() => {
    const actualOcupacao = data?.items.find((item) => item.codigo === ocupacao);
    setNextTecs(actualOcupacao?.tecnologias.map((item) => item.id)!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ocupacao]);

  useEffect(() => {
    setOcupacao(1);
    setNextTecs([]);
    eraseAll();
    setShouldShowCancelModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUnidade, currentSafra]);

  useEffect(() => {
    setCurrentItem(undefined);
    eraseAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ocupacao, tecnologias]);

  useEffect(() => {
    if (currentItem) {
      setAbleToDelete(true);
    }
  }, [currentItem]);

  useEffect(() => {
    if (pathsToSave?.length && currentItemGeojson) {
      let shouldShowCancelModal = false;
      currentItemGeojson?.geometry.coordinates[0].forEach((item, index) => {
        const isLatitudeEqual = pathsToSave[index]?.lat === item[1];
        const isLongitudeEqual = pathsToSave[index]?.lng === item[0];

        if (!isLatitudeEqual || !isLongitudeEqual) {
          shouldShowCancelModal = true;
        }
      });

      setShouldShowCancelModal(shouldShowCancelModal);
    }
    if (pathsToSave?.length && !currentItemGeojson) {
      setShouldShowCancelModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathsToSave, dataLista?.items, currentItem]);

  useEffect(() => {
    setAbleToSave(shouldShowCancelModal);
  }, [shouldShowCancelModal]);

  return (
    <Flex
      background={"white"}
      flex={1}
      margin="0 30px 10px"
      boxShadow="0px 3px 6px #0000005C"
      borderRadius="5px"
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      gridGap={5}
    >
      <Flex flexDirection={"column"} flex={0.37} marginTop="15px">
        <OcupacoesButtons
          items={data?.items!}
          onChange={setNextOcupacao}
          value={ocupacao}
        />
        <SelectedButtons
          items={getTecnologias()}
          onChange={setNextTecs}
          value={tecnologias}
          isMultipleChanges
        />
        <Box
          width="100%"
          borderTop="1px solid"
          borderColor={"gray.300"}
          margin="10px 0"
        ></Box>
        <Flex
          alignItems="center"
          border="1px solid #9E9A9A"
          borderRadius="20px"
          height="30px"
          padding="0 5px"
          ml={2}
          mb={3}
        >
          <Input
            border="0"
            placeholder="Pesquisar talhão"
            _active={{}}
            _focus={{}}
            value={inputSearch}
            onChange={({ target }) => setInputSearch(target.value)}
          />
          <FaSearch style={{ marginRight: "7px" }} />
        </Flex>

        <TalhoesTable
          talhoes={
            inputSearch === "" ? dataLista?.items.talhoes! : searchResults!
          }
          changeCurrentItem={setCurrentItem}
          currentItem={currentItem}
          isLoading={isFetching}
          totalItems={
            inputSearch === ""
              ? dataLista?.items.talhoes.length
              : searchResults?.length
          }
          shouldShowModal={shouldShowCancelModal}
          handleShowModal={(nextTalhao: TalhoesProps) => {
            setModalMode(ModalModeEnum.cancel);
            setNextItem(nextTalhao);
            onOpen();
          }}
        />
      </Flex>
      <Flex
        flexDirection="column"
        flex={1}
        height="100%"
        borderLeft=" 0.5px solid #BCBBBB"
        paddingTop={"15px"}
      >
        <MapEditionMode
          currentItem={currentItem}
          eraseAll={eraseAll}
          onCancel={handleCancel}
          onChangeModalMode={setModalMode}
          onOpenModal={onOpen}
          onSave={handleSave}
          showCancelModal={shouldShowCancelModal}
          showEditOptions={showEditOptions}
          toogleEditOptions={setShowEditOptions}
          ableToSave={ableToSave}
        />

        <MapFilter
          currentItem={currentItem}
          geojson={dataLista?.items.geoJson!}
          canEditPolygon={showEditOptions}
          canMovePolygon={showEditOptions}
          defaultPontoCentral={currentUnidade!.pontoCentral}
          changePathsToSave={setPathsToSave}
          pathsToSave={pathsToSave}
        >
          <Modal isOpen={isOpen} onClose={() => {}} isCentered>
            <ModalOverlay onClick={() => {}} />
            <ModalContent
              padding={
                modalMode === ModalModeEnum.invalidPolygon
                  ? "30px 20px"
                  : "30px 50px"
              }
            >
              <ModalBody
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Image
                  src={
                    modalMode === ModalModeEnum.cancel
                      ? AvisoIcon
                      : modalMode === ModalModeEnum.invalidPolygon
                      ? TalhoesIcon
                      : TrashRedIcon
                  }
                  width={"25px"}
                />
                <Text
                  fontWeight="medium"
                  fontSize="16px"
                  textAlign="center"
                  color="gray500"
                  marginTop="15px"
                >
                  {modalMode === ModalModeEnum.cancel
                    ? "Deseja mesmo sair da edição? Mudanças que não foram salvas serão perdidas."
                    : modalMode === ModalModeEnum.invalidPolygon
                    ? "Formato de geometria inválido! Por favor, revise se algum ponto está sobreposto a outro."
                    : `Tem certeza que deseja apagar o polígono do talhão ${currentItem?.codigo}?`}
                </Text>

                <Flex gridGap="5px" marginTop="30px">
                  {modalMode !== ModalModeEnum.invalidPolygon && (
                    <Button
                      display="flex"
                      alignItems="center"
                      gridGap="10px"
                      fontWeight="medium"
                      background="none"
                      border="1px solid #99A0B0"
                      color="#99A0B0"
                      onClick={() => onClose(true)}
                    >
                      <FaBan />{" "}
                      {modalMode === ModalModeEnum.cancel
                        ? "Não, continuar"
                        : "CANCELAR"}
                    </Button>
                  )}
                  <Button
                    display="flex"
                    alignItems="center"
                    gridGap="10px"
                    fontWeight="medium"
                    background="green.400"
                    disabled={!ableToDelete}
                    color="white"
                    onClick={async () => {
                      if (modalMode === ModalModeEnum.cancel) {
                        eraseAll();
                        onClose(false);
                        if (nextItem) {
                          setCurrentItem(nextItem);
                          setNextItem(undefined);
                        }
                        setShouldShowCancelModal(false);
                      }

                      if (modalMode === ModalModeEnum.delete) {
                        setAbleToDelete(false);
                        try {
                          await handleDelete();
                        } catch {
                          setAbleToDelete(true);
                        }
                      }

                      if (modalMode === ModalModeEnum.invalidPolygon) {
                        // setAbleToSave(true);
                        onClose(false);
                      }
                    }}
                  >
                    <FaCheck />
                    {modalMode === ModalModeEnum.cancel
                      ? "Sim, cancelar"
                      : modalMode === ModalModeEnum.invalidPolygon
                      ? "Entendi"
                      : "REMOVER"}
                  </Button>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
        </MapFilter>
      </Flex>
    </Flex>
  );
};

export default TalhoesFilters;
