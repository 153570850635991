import { useQuery } from "react-query";
import URL from "core/resources/urls";
import http from "infra/http";
import { GraficoAplicacoesResult } from "../typings";

export function useGraficoAplicacoes(talhaoId: string) {
  const url = URL.TALHOES.APLICACOES_GRAFICO(talhaoId);

  return useQuery(
    url,
    async () => {
      const { data } = await http.get<GraficoAplicacoesResult>(url, {
        headers: {
          "api-version": "1.0",
        },
      });

      return data;
    },
    {
      enabled: !!talhaoId,
    }
  );
}
