import styled from "styled-components";

export const TableContainerStyled = styled.div`
  .ant-checkbox-inner {
    border-width: 2px !important;
    border-color: #666 !important;
    box-shadow: none !important;
    border-radius: 4.8px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #666 !important;
  }

  .css-3a538a {
    border: none;
  }

  /* .row-selected > td {
    background-color: #f5f1f1 !important;
  }

  .row:nth-child(even) {
    background: #f8f8f8;
  } */

  .row-selected {
    border: 2px solid black !important;
    background: #4de79512;
  }
  tr:hover {
    cursor: pointer;
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    .ant-table-body
    > table
    > tbody
    > tr.row-selected
    > td {
    background-color: #e0f3e9 !important;
  }

  .row > td:nth-child(1) {
    pointer-events: none !important;
    z-index: 0;
    /* position: relative; */
  }

  .ant-empty {
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    height: calc(100vh - 360px) !important;
  }

  .ant-table-body {
    min-height: calc(100vh - 320px);
    border-left: 1px solid #e2dbdb !important;
    border-right: 1px solid #e2dbdb !important;
  }

  .ant-table table {
    width: 100% !important;
    text-align: left !important;
    border-radius: 2px 2px 0 0 !important;
    border-collapse: collapse !important;
    border-spacing: 0 !important;
  }

  .ant-table-row-level-0 {
    border: 1px solid #bab7b7 !important;
  }
`;

export const PopupContainer = styled.div`
  .ant-popover-inner-content,
  .ant-popover-title {
    padding: 0 !important;
  }
`;
