import { Collapse, Flex, Text, Box } from "@chakra-ui/react";
import InputSearch from "components/inputs/input-search";
import { CustomFeature, TipoMapaTalhoes } from "features/maps/typings";
import { useCallback, useMemo, useState } from "react";

import {
  columnTablesOperacoesRealizadas,
  columnTablesOperacoesRealizadasDetalhes,
} from "./utils";
import useTalhaoOperacoesRealizadas from "features/maps/hooks/useTalhaoOperacoesRealizadas";
import { OperacoesRealizadas } from "features/maps/typings/operacoesRealizadas";
import DatatableAntd from "components/datatable-antd";
import { formatMoney } from "infra/helpers/number";
import { IoIosArrowUp } from "react-icons/io";
import { MdChevronRight } from "react-icons/md";
import { useFilters } from "core/features/filters/store";

interface TabOrdensServicoProps {
  talhao: CustomFeature;
  active: boolean;
  filterActive: boolean;
}

const TabOperacoesRealizadas = ({
  talhao,
  active,
  filterActive,
}: TabOrdensServicoProps) => {
  const [numeroOs, setNumeroOs] = useState<number | undefined>();

  const { filters } = useFilters();

  const { filtroOperacoesExecutadas, tipoMapa } = filters;

  const { data, isLoading, isFetching, fetchNextPage } =
    useTalhaoOperacoesRealizadas({
      talhaoId: active ? talhao.id : null,
      numeroOs,
    });

  const items = useMemo(() => {
    const pages = data?.pages ?? [];

    const result = pages.reduce(
      (agg: OperacoesRealizadas[], item) => agg.concat(item.operacoes ?? []),
      []
    );

    result.forEach((item) => {
      (item as any).key = item.id;
      (item as any).children = item.detalhes.map((d, index) => ({
        ...d,
        key: `${index}-${d.insumoDescricao}`,
      }));
    });

    return result;
  }, [data]);

  const _isLoading = isLoading || isFetching;

  const nextPage = () => {
    const lastPage = data?.pages[data.pages.length - 1];

    if (!lastPage || !data || !data.pages.length) {
      return;
    }

    const totalPages =
      lastPage.paginate.totalCount / data.pages[0].paginate.resultsPerPage;

    const hasNextPage = totalPages > data.pages.length;

    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  };

  const isActive = useCallback(
    (item: OperacoesRealizadas) => {
      if (tipoMapa !== TipoMapaTalhoes.OperacoesExecutadas) {
        return false;
      }

      return !!filtroOperacoesExecutadas?.operacoes.find(
        (op) => op.operacao === item.operacaoId
      );
    },
    [filtroOperacoesExecutadas?.operacoes, tipoMapa]
  );

  return (
    <Flex flexDir="column" flex={1}>
      <Collapse in={filterActive}>
        <Flex mb={4} flexDir="row" flex={1} w="100%" alignItems="center">
          <InputSearch
            data-testid="input-search-tab-operacoes-realizadas"
            placeholder="Digite aqui o número da ordem de serviço"
            onChange={(text) => {
              setNumeroOs(Number(text));
            }}
            maxLength={9}
            isNumeric
          />
        </Flex>
      </Collapse>
      <Flex flexDir="column">
        <DatatableAntd
          loading={_isLoading}
          dataSource={items}
          columns={columnTablesOperacoesRealizadas}
          scroll={{ y: "calc(100vh - 380px)" }}
          onLoad={() => {
            nextPage();
          }}
          onRow={(record) => {
            return {
              className: isActive(record) ? "row-selected" : "",

              style: {
                cursor: record.detalhes?.length ? "pointer" : "inherit",
              },
            };
          }}
          expandable={{
            rowExpandable: (record) => !!record.detalhes?.length,
            expandRowByClick: true,
            expandIcon: ({ expanded, onExpand, record }) =>
              record.detalhes?.length ? (
                expanded ? (
                  <IoIosArrowUp onClick={(e) => onExpand(record, e as any)} />
                ) : (
                  <MdChevronRight onClick={(e) => onExpand(record, e as any)} />
                )
              ) : null,
            expandedRowRender: (record) => (
              <Box m={8} ml={0}>
                <DatatableAntd
                  dataSource={record.detalhes ?? []}
                  columns={columnTablesOperacoesRealizadasDetalhes}
                />
              </Box>
            ),
          }}
          footer={() => (
            <Flex flex={1}>
              <Flex>
                <Text mr={2}>TOTAL DE OPERAÇÕES: </Text>
                <Text fontWeight="bold">
                  {data?.pages[0]?.paginate?.totalCount ?? 0}
                </Text>
              </Flex>

              <Flex flex={1} />

              <Flex>
                <Text mr={2}>CUSTO MÉDIO: </Text>
                <Text fontWeight="bold">
                  {formatMoney(
                    data?.pages[0]?.properties?.custoTotal ?? 0
                  ).replace("R$", "US$")}
                </Text>
              </Flex>
            </Flex>
          )}
        />
      </Flex>
    </Flex>
  );
};

export default TabOperacoesRealizadas;
