import { FilterIndex } from "components/inputs/filter-safra-unidade-mapatematico/enum";
import { MapFilterIndex } from "features/maps/data";
import { TipoMapaTalhoes } from "features/maps/typings";

let lastTipoMapa: TipoMapaTalhoes = TipoMapaTalhoes.DistribuicaoVarietal;

export function getTipoMapaForIndexOpen(index: FilterIndex | null) {
  switch (index) {
    case FilterIndex.Ocupacao:
      lastTipoMapa = TipoMapaTalhoes.DistribuicaoVarietal;
      return TipoMapaTalhoes.DistribuicaoVarietal;

    case FilterIndex.Textura_Solo:
      lastTipoMapa = TipoMapaTalhoes.Texturas;
      return TipoMapaTalhoes.Texturas;

    case FilterIndex.Operacoes_Executadas:
      lastTipoMapa = TipoMapaTalhoes.OperacoesExecutadas;
      return TipoMapaTalhoes.OperacoesExecutadas;

    case FilterIndex.Imagens_Satelite:
      lastTipoMapa = TipoMapaTalhoes.Satelite;
      return TipoMapaTalhoes.Satelite;

    case FilterIndex.Numero_De_Aplicacoes:
      lastTipoMapa = TipoMapaTalhoes.NumeroDeAplicacoes;
      return TipoMapaTalhoes.NumeroDeAplicacoes;

    case FilterIndex.Produtividade:
      lastTipoMapa = TipoMapaTalhoes.Produtividade;
      return TipoMapaTalhoes.Produtividade;

    case FilterIndex.Concentracao_Colheita:
      lastTipoMapa = TipoMapaTalhoes.ConcentracaoColheita;
      return TipoMapaTalhoes.ConcentracaoColheita;

    case FilterIndex.Idade_Do_Talhao:
      lastTipoMapa = TipoMapaTalhoes.IdadeDoTalhao;
      return TipoMapaTalhoes.IdadeDoTalhao;

    case FilterIndex.Ordens_De_Servicos_Abertas:
      lastTipoMapa = TipoMapaTalhoes.OrdensDeServicosAberta;
      return TipoMapaTalhoes.OrdensDeServicosAberta;

    case FilterIndex.Distribuicao_de_equipes:
      lastTipoMapa = TipoMapaTalhoes.DistribuicaoDeEquipes;
      return TipoMapaTalhoes.DistribuicaoDeEquipes;

    case FilterIndex.Chuvas:
      lastTipoMapa = TipoMapaTalhoes.Chuvas;
      return TipoMapaTalhoes.Chuvas;

    default:
      return lastTipoMapa;
  }
}

export let lastFiltroIndexOpen: MapFilterIndex =
  MapFilterIndex.DISTRIBUICAO_VARIETAL;

export function getIndexMapaTematicoForTipoMapa(tipoMapa: TipoMapaTalhoes) {
  switch (tipoMapa) {
    case TipoMapaTalhoes.DistribuicaoVarietal:
      lastFiltroIndexOpen = MapFilterIndex.DISTRIBUICAO_VARIETAL;
      return MapFilterIndex.DISTRIBUICAO_VARIETAL;

    case TipoMapaTalhoes.Texturas:
      lastFiltroIndexOpen = MapFilterIndex.TEXTURA_SOLO;
      return MapFilterIndex.TEXTURA_SOLO;

    case TipoMapaTalhoes.OperacoesExecutadas:
      lastFiltroIndexOpen = MapFilterIndex.OPERACOES_EXECUTADAS;
      return MapFilterIndex.OPERACOES_EXECUTADAS;

    case TipoMapaTalhoes.Satelite:
      lastFiltroIndexOpen = MapFilterIndex.SATELLITE_IMAGE;
      return MapFilterIndex.SATELLITE_IMAGE;

    case TipoMapaTalhoes.NumeroDeAplicacoes:
      lastFiltroIndexOpen = MapFilterIndex.NUMERO_DE_APLICACOES;
      return MapFilterIndex.NUMERO_DE_APLICACOES;

    case TipoMapaTalhoes.Produtividade:
      lastFiltroIndexOpen = MapFilterIndex.PRODUTIVIDADE;
      return MapFilterIndex.PRODUTIVIDADE;

    case TipoMapaTalhoes.ConcentracaoColheita:
      lastFiltroIndexOpen = MapFilterIndex.CONCENTRACAO_COLHEITA;
      return MapFilterIndex.CONCENTRACAO_COLHEITA;

    case TipoMapaTalhoes.IdadeDoTalhao:
      lastFiltroIndexOpen = MapFilterIndex.IDADE_DO_TALHAO;
      return MapFilterIndex.IDADE_DO_TALHAO;

    case TipoMapaTalhoes.OrdensDeServicosAberta:
      lastFiltroIndexOpen = MapFilterIndex.ORDENS_DE_SERVICO_ABERTA;
      return MapFilterIndex.ORDENS_DE_SERVICO_ABERTA;

    case TipoMapaTalhoes.DistribuicaoDeEquipes:
      lastFiltroIndexOpen = MapFilterIndex.DISTRIBUICAO_DE_EQUIPES;
      return MapFilterIndex.DISTRIBUICAO_DE_EQUIPES;

    case TipoMapaTalhoes.Chuvas:
      lastFiltroIndexOpen = MapFilterIndex.CHUVAS;
      return MapFilterIndex.CHUVAS;
    default:
      return lastFiltroIndexOpen;
  }
}
