import URL from "core/resources/urls";
import http from "infra/http";
import { TalhoesToImportProps } from "../typings";

const atualizarViaKML = async (talhoes: TalhoesToImportProps[]) => {
  const { data } = await http.put(URL.IMPORTAR_TALHOES.KML_UPDATE(), {
    talhoes,
  });

  return data.talhoes as string[];
};

export default atualizarViaKML;
