import ContainerFilter from "../../ContainerFilter";
import UnidadeItem from "./UnidadeItem";
import { Unidade } from "core/features/maps/typings";

function FiltersUnidade({
  onClick,
  data,
  unidadeSelected,
}: {
  onClick: (item: Unidade) => void;
  data: Unidade[];
  unidadeSelected?: Unidade;
}) {
  return (
    <ContainerFilter title="Escolha uma unidade" isVisible>
      {data.map((item, index) => (
        <UnidadeItem
          unidadeSelected={unidadeSelected}
          key={item.id}
          onClick={() => onClick(item)}
          item={item}
          isBorderBottom={index !== data.length - 1}
        />
      ))}
    </ContainerFilter>
  );
}

export default FiltersUnidade;
