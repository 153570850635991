import { Flex } from "@chakra-ui/react";

import useSearch from "features/search/hooks/useSearch";

import SearchInputField from "./components/SearchInputField";
import SearchCollpase from "./components/SearchCollpase";

const SearchInput = () => {
  const { focused, value, onChange, onSearch, onClear, onFocus, onBlur } =
    useSearch();

  return (
    <Flex mr="32px">
      <SearchCollpase open={focused} onClose={() => onBlur()}>
        <SearchInputField
          value={value}
          onChange={(v: string) => onChange(v)}
          onSearch={() => onSearch()}
          onClear={() => onClear()}
          onFocus={() => onFocus()}
        />
      </SearchCollpase>
    </Flex>
  );
};

export default SearchInput;
