import { useQuery } from "react-query";

import URL from "core/resources/urls";
import { httpAgrodrive } from "infra/http";
import { UseGetFilesDataProps } from "../types/arquivos";
import config from "infra/config";

export default function useGetFiles(
  pagina = 1,
  tamanhoPagina = 100,
  texto?: string,
  tags?: string
) {
  return useQuery(
    [
      URL.AGRODRIVE.ARQUIVOS_LIST(pagina, tamanhoPagina, texto, tags),
      pagina,
      tamanhoPagina,
      texto,
      tags,
    ],
    async () => {
      const { data } = await httpAgrodrive.get<UseGetFilesDataProps>(
        URL.AGRODRIVE.ARQUIVOS_LIST(pagina, tamanhoPagina, texto, tags),
        {
          headers: {
            "x-api-key": config.agrodriveClientId,
          },
        }
      );

      return data;
    }
  );
}
