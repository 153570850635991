import { Flex, Skeleton } from "@chakra-ui/react";

function SearchSkeleton() {
  return (
    <Flex gridGap="8px" flexDir="column">
      <Flex gridGap="8px">
        <Skeleton w="60px" h="35px" rounded={"full"} />{" "}
        <Skeleton w="80px" h="35px" rounded={"full"} />{" "}
        <Skeleton w="50px" h="35px" rounded={"full"} />{" "}
        <Skeleton w="70px" h="35px" rounded={"full"} />{" "}
      </Flex>

      <Flex gridGap="8px">
        <Skeleton w="80px" h="35px" rounded={"full"} />{" "}
        <Skeleton w="65px" h="35px" rounded={"full"} />{" "}
        <Skeleton w="100px" h="35px" rounded={"full"} />{" "}
      </Flex>
    </Flex>
  );
}

export default SearchSkeleton;
