import { TipoMapaTalhoes } from "features/maps/typings";
import { useFilters } from "../store";
import { useConcentracaoColheitaStore } from "../store/useConcentracaoColheitaStore";
import { FilterSaved } from "../typings";

export function importFilters(tipoMapa: TipoMapaTalhoes, data: FilterSaved) {
  const store = useFilters.getState();

  store.updateField("unidade", data.unidade);
  store.updateField("setor", data.setor);
  store.updateField("mapType", data.mapType);
  store.updateField("tipoMapa", data.tipoMapa);
  store.updateField("safra", data.safra);

  if (tipoMapa === TipoMapaTalhoes.ConcentracaoColheita) {
    useConcentracaoColheitaStore.getState().update({ ...data.data });
    return;
  }

  if (tipoMapa === TipoMapaTalhoes.DistribuicaoVarietal) {
    store.updateFiltroOcupacao(data.data);
  }

  if (tipoMapa === TipoMapaTalhoes.Texturas) {
    store.updateFiltroTexturas(data.data);
  }

  if (tipoMapa === TipoMapaTalhoes.OperacoesExecutadas) {
    store.updateFiltroOperacoesExecutadas(data.data);
  }

  if (tipoMapa === TipoMapaTalhoes.NumeroDeAplicacoes) {
    store.updateFiltroNumeroDeAplicacoes(data.data);
  }

  if (tipoMapa === TipoMapaTalhoes.Produtividade) {
    store.updateFiltroProdutividade(data.data);
  }

  if (tipoMapa === TipoMapaTalhoes.IdadeDoTalhao) {
    store.updateFiltroIdadeDoTalhao(data.data);
  }

  if (tipoMapa === TipoMapaTalhoes.OrdensDeServicosAberta) {
    store.updateFiltroOrdensDeServicosAbertas(data.data);
  }
  if (tipoMapa === TipoMapaTalhoes.DistribuicaoDeEquipes) {
    store.updateFiltroDistribuicaoDeEquipes(data.data);
  }
  if (tipoMapa === TipoMapaTalhoes.Chuvas) {
    store.updateFiltroMapaDeChuvas(data.data);
  }
}
