import URL from "core/resources/urls";
import http from "infra/http";
import { useQuery } from "react-query";
import { TipoMapaTalhoes } from "../typings";

export function useOcupacoes(unidadeId?: string, safraId?: string, tipoMapa?: TipoMapaTalhoes) {
  return useQuery(
    URL.MAPAS.OCUPACOES(unidadeId!, safraId!),
    async () => {
      const { data } = await http.get(
        URL.MAPAS.OCUPACOES(unidadeId!, safraId!),
        {
          headers: {
            "api-version": "2.0",
          },
        }
      );

      return data;
    },
    {
      enabled: !!(unidadeId && safraId) && tipoMapa === TipoMapaTalhoes.DistribuicaoVarietal,
    }
  );
}
