import URL from "core/resources/urls";
import config from "infra/config";
import { httpAgrodrive } from "infra/http";
import { DiretorioData } from "ui/pages/app/arquivos/agrodrive/types/arquivos";

export const createNewFolder = async ({nome, localizacao, tags }: DiretorioData): Promise<void> => {
    await httpAgrodrive.post(URL.AGRODRIVE.DIRETORIO(), {nome, localizacao, tags}, {
      headers: {
        "x-api-key": config.agrodriveClientId,
      },
    })
};