import React from "react";
import { forwardRef, Text } from "@chakra-ui/react";
import { MuiTextFieldProps } from "@material-ui/pickers/_shared/PureDateInput";

import { BiCalendar } from "react-icons/bi";
import * as S from "./styles";

type CustomInputProps = MuiTextFieldProps & {
  errorMessage?: string;
  largeInput?: boolean;
  isEqual?: boolean;
};

const CustomInput = forwardRef<CustomInputProps, any>((props, ref) => {
  return (
    <S.Container>
      {!!props.inputProps?.value && <S.Label show>{props.label}</S.Label>}
      <S.Input
        ref={ref}
        {...props.inputProps}
        error={
          (props.error && !props.isEqual) ||
          (!!props.errorMessage && !props.isEqual)
        }
        placeholder={String(props.placeholder)}
        large={props.largeInput}
      />
      <BiCalendar size="1.1rem" />

      <Text color="red" fontSize="12px" marginTop="5px">
        {props.errorMessage || " "}
      </Text>
    </S.Container>
  );
});

export default CustomInput;
