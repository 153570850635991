import Appbar from "components/layout/appbar";
import { Box } from "@chakra-ui/layout";
import MapaGeral from "components/maps/mapa-geral";
import { getTipoMapaForIndexOpen } from "./util";
import { useFiltersBaseLists } from "core/features/filters/hooks/useFiltersBaseLists";
import { useMenuFilterIndex } from "core/features/filters/hooks/useMenuFilterIndex";
import { useEffect } from "react";
import { useFilters } from "core/features/filters/store";
import MapaConcentracaoColheita from "components/maps/mapa-concentracao-colheita";
import { TipoMapaTalhoes } from "core/features/maps/typings";
import { CenterMapContextProvider } from "core/features/maps/center-map/CenterMap.context";

function HomePage() {
  const { indexOpen } = useMenuFilterIndex();
  const {
    updateField,
    filters: { tipoMapa },
  } = useFilters();

  useFiltersBaseLists();

  useEffect(() => {
    (() => {
      const tipoMapa = getTipoMapaForIndexOpen(indexOpen);

      updateField("tipoMapa", tipoMapa);
    })();
  }, [indexOpen, updateField]);

  return (
    <Box w="100%">
      {tipoMapa === TipoMapaTalhoes.ConcentracaoColheita ? (
        <MapaConcentracaoColheita />
      ) : null}

      <CenterMapContextProvider>
        <Appbar />

        {tipoMapa !== TipoMapaTalhoes.ConcentracaoColheita ? (
          <MapaGeral />
        ) : null}
      </CenterMapContextProvider>
    </Box>
  );
}

export default HomePage;
