import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  ChakraProps,
} from "@chakra-ui/react";

interface RefetchProps extends ChakraProps {
  title: string;
  refetch: () => void;
}

export default function Refetch({ title, refetch, ...props }: RefetchProps) {
  return (
    <Alert py={0} borderRadius="lg" status="error" {...props}>
      <AlertIcon />
      <AlertDescription fontSize="sm">
        {title}{" "}
        <Button size="sm" variant="unstyled" onClick={() => refetch()}>
          Tentar novamente
        </Button>
      </AlertDescription>
    </Alert>
  );
}
