import { useFilters } from "core/features/filters/store";

import {
  getDateFromFilter,
  getDateStringFromDate,
} from "../utils/mapa-de-chuva.utils";

interface applyChangeProps {
  horaInicial: string;
  horaFinal: string;
  dataInicial: Date;
  dataFinal: Date;
}

function useAdvancedFiltersOnMapaDeChuvas() {
  const { filters, updateFiltroMapaDeChuvas } = useFilters();
  const { filtroMapaDeChuva } = filters;

  const minDate = filtroMapaDeChuva?.dataInicial
    ? getDateFromFilter(filtroMapaDeChuva.dataInicial)
    : null;
  const maxDate = filtroMapaDeChuva?.dataFinal
    ? getDateFromFilter(filtroMapaDeChuva.dataFinal)
    : null;

  const applyChanges = (props: applyChangeProps) => {
    const dataInicial = props.dataInicial
      ? getDateStringFromDate(props.dataInicial as Date)
      : undefined;
    const dataFinal = props.dataFinal
      ? getDateStringFromDate(props.dataFinal as Date)
      : undefined;
    const horaInicial = props.horaInicial;

    const horaFinal = props.horaFinal;

    updateFiltroMapaDeChuvas({
      horaInicial,
      dataInicial,
      dataFinal,
      horaFinal,
      periodo: 6,
      indicesPluviometrico: [0],
    });
  };

  const clearFilters = () => {
    updateFiltroMapaDeChuvas(
      {
        dataFinal: undefined,
        dataInicial: undefined,
        horaInicial: undefined,
        horaFinal: undefined,
        periodo: 2,
      },
      true
    );
  };

  return {
    values: {
      minDate,
      maxDate,
      minHour: filters.filtroMapaDeChuva?.horaInicial,
      maxHour: filters.filtroMapaDeChuva?.horaFinal,
    },
    onChange: applyChanges,
    onClear: clearFilters,
  };
}

export default useAdvancedFiltersOnMapaDeChuvas;
