import { useEffect, useState } from "react"

const useImageLoad = (src: string | undefined) => { 
    const [isLoading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState(false);

    useEffect(() => { 
        let image: any = undefined;
        const handleError = () => { 
            setHasError(true);
            setIsLoading(false);
        };

        const handleLoad = () => { 
            setIsLoading(false);
            setHasError(false)
        };

        if (src) {
            setIsLoading(true);
            setHasError(false);

            const image = new Image();
            image.src = src;
            
            image.addEventListener("error", handleError);
            image.addEventListener("load", handleLoad);
        }

        return () => { 
            image?.removeEventListener("error", handleError);
            image?.removeEventListener("load", handleLoad);
        }
    }, [src]);

    return { isLoading, hasError };
}

export default useImageLoad;
