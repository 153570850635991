export interface Insumos {
  areaExecutada: number;
  doseProgramada: number;
  doseRealizada: number;
  insumo: string;
  valorDolarHa: number;
  unidadeMedidaSigla: string;
}

export interface AplicacoesProperties {
  aplicacao: number;
  custoTotalDolarHa: number;
  dae: number;
  dataAplicacao: string;
  insumos: Insumos[];
  intervalo: number;
  numeroOrdemServico: number;
  operacao: string;
}

export interface Aplicacoes {
  custoTotalDolarHa: number;
  aplicacoes: AplicacoesProperties[];
}

export enum StatusInsumosEnum {
  Fertilizantes = 305,
  Herbicidas = 308,
  Inseticidas = 309,
  Fungicidas = 310,
  Reguladores = 316,
  Indicadores = 0,
}
