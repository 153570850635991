import { Children, ReactElement, cloneElement, isValidElement } from "react";
import { HStack, RadioGroupProps, useRadioGroup } from "@chakra-ui/react";

interface RadioImageProps extends RadioGroupProps {
  name: string;
  defaultValue?: string | number;
  value?: string | number;
  onChange?: (value: string) => void;
  isLoading?: boolean;
}

export default function RadioImageGroup({
  children,
  name,
  defaultValue,
  value,
  onChange,
}: RadioImageProps) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange: onChange ?? undefined,
  });

  const group = getRootProps();

  const childrenWithProps = Children.map(children, (child) => {
    const { value } = (child as ReactElement)?.props ?? {};

    if (isValidElement(child)) {
      return cloneElement(child, {
        ...getRadioProps({ value }),
      });
    }

    return child;
  });

  return (
    <HStack spacing={[4]} alignItems="flex-start" {...group}>
      {childrenWithProps}
    </HStack>
  );
}
