import { Box, BoxProps, Checkbox } from "@chakra-ui/react";
import { ChangeEvent, useCallback, useEffect } from "react";

import useRouteOverlayStore from "core/features/image/states/useRouteOverlayStore";

interface RouteProps extends BoxProps {
  imageId: string | number;
}
export default function Route({ imageId, ...props }: RouteProps) {
  const { imageId: id, setImageId: setId, reset } = useRouteOverlayStore();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setId(e.target.checked ? e.target.value : undefined);
    },
    [setId]
  );

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <Box {...props}>
      <Checkbox
        size="sm"
        colorScheme="green"
        onChange={handleChange}
        value={imageId}
        isChecked={id === imageId.toString()}
      >
        Ver rota
      </Checkbox>
    </Box>
  );
}
