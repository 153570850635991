import React from "react";
import { Box, Text, Tooltip } from "@chakra-ui/react";

interface BadgeTextProps {
  text: string;
  tooltipText?: string;
  useDots?: boolean;
}

function BadgeText({ text, tooltipText, useDots }: BadgeTextProps) {
  const MAX_LENGTH_TEXT = 17;
  const textWithDots = `${text.slice(0, 24)}...`;

  return (
    <Tooltip label={tooltipText} hasArrow>
      <Box
        background="#DFDFDF"
        margin={"0px 3px"}
        padding="3px 7px"
        borderRadius="16px"
        maxHeight="25px"
        overflow={"hidden"}
      >
        <Text fontSize={"12px"} fontWeight="normal">
          {text.length > MAX_LENGTH_TEXT && useDots ? textWithDots : text}
        </Text>
      </Box>
    </Tooltip>
  );
}

export default BadgeText;
