import {
  ComponentSingleStyleConfig,
  extendTheme,
  theme as themeChakra,
} from "@chakra-ui/react";
import {
  blue,
  blue100,
  blue400,
  blue600,
  blue800,
  gray100,
  gray150,
  gray300,
  gray400,
  gray50,
  gray500,
  gray700,
  gray800,
  gray900,
  green,
  green600,
  green700,
  primary,
  yellow,
  yellow400,
  green800,
  black62,
} from "core/resources/theme/colors";

const Navbar: ComponentSingleStyleConfig = {
  baseStyle: () => ({
    top: 4,
    left: 0,
    px: 4,
    w: "100%",
    position: "fixed",
    zIndex: 999999,
  }),
};

const theme = extendTheme({
  ...themeChakra,
  colors: {
    ...themeChakra.colors,
    black: black62,
    yellow: {
      ...themeChakra.colors.yellow,
      500: yellow,
      400: yellow400,
    },
    blue: {
      ...themeChakra.colors.blue,
      100: blue100,
      400: blue400,
      500: blue,
      600: blue600,
      800: blue800,
    },
    green: {
      ...themeChakra.colors.green,
      500: green,
      600: green600,
      700: green700,
      800: green800,
    },
    primary: primary,
    gray: {
      ...themeChakra.colors.gray,
      50: gray50,
      100: gray100,
      150: gray150,
      300: gray300,
      400: gray400,
      500: gray500,
      700: gray700,
      800: gray800,
      900: gray900,
    },
    progressBar: {
      500: blue600,
    },
  },
  fonts: {
    ...themeChakra.fonts,
    body: "Roboto, sans-serif",
    heading: "Roboto, serif",
    mono: "Menlo, monospace",
  },
  components: {
    ...themeChakra.components,
    Navbar: Navbar,
    Button: {
      ...themeChakra.components.Button,
      baseStyle: {
        ...themeChakra.components.Button.baseStyle,
        fontWeight: "400",
      },
    },
  },
  styles: {
    ...themeChakra.styles,
    global: {
      "html, body": {
        fontFamily: "Roboto, sans-serif",
        color: gray800,
      },
      ".chakra-text": {
        color: gray900,
      },
    },
  },
});

export default theme;
