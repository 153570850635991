import { useContext } from "react";
import {
  Button,
  Flex,
  MenuButton,
  MenuItem,
  Text,
  Menu,
  MenuList,
  Box,
} from "@chakra-ui/react";
import { useFiltersLists } from "core/features/filters/store/lists";
import { AiFillCaretDown } from "react-icons/ai";

import { useFiltersBaseLists } from "core/features/filters/hooks/useFiltersBaseLists";
import { TalhoesContext } from "core/features/talhoes/contexts/Talhoes.context";
import { CurrentViewType } from "../..";

interface TalhoesHeaderProps {
  currentView?: CurrentViewType;
  changeCurrentView: (prop: CurrentViewType) => void;
}

const TalhoesHeader = ({
  currentView,
  changeCurrentView,
}: TalhoesHeaderProps) => {
  useFiltersBaseLists();
  const { unidades, safras } = useFiltersLists();
  const {
    currentSafra,
    currentUnidade,
    nextSafra,
    nextUnidade,
    setNextSafra,
    setNextUnidade,
  } = useContext(TalhoesContext);

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between" width="100%" padding="0 30px">
        <Text fontWeight="medium" fontSize="26px" color="#676C77">
          Talhões
        </Text>

        <Flex>
          <Menu flip>
            <MenuButton
              as={Button}
              border="1px solid"
              borderColor={nextUnidade ? "gray" : "green"}
              borderRadius={0}
              background="white"
              color={nextUnidade ? "gray" : "green"}
              _active={{}}
              _focus={{}}
            >
              <Flex alignItems="center" gridGap="5px">
                {currentUnidade?.nome || "Selecionar a Unidade"}
                <AiFillCaretDown />
              </Flex>
            </MenuButton>
            <MenuList>
              {unidades.map((prop) => {
                return (
                  <MenuItem key={prop.id} onClick={() => setNextUnidade(prop)}>
                    {prop.nome}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>

          <Menu>
            <MenuButton
              as={Button}
              border="1px solid"
              borderColor={nextUnidade && !nextSafra ? "green" : "gray"}
              borderRadius={0}
              background="white"
              color={nextUnidade && !nextSafra ? "green" : "gray"}
              _active={{}}
              _focus={{}}
            >
              <Flex alignItems="center" gridGap="5px">
                {currentSafra?.descricao || "selecionar a Safra"}
                <AiFillCaretDown />
              </Flex>
            </MenuButton>
            <MenuList>
              {safras.map((prop) => {
                return (
                  <MenuItem key={prop.id} onClick={() => setNextSafra(prop)}>
                    {prop.descricao}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <Flex
        justifyContent="space-between"
        margin="0 30px"
        borderTop="2px solid #BCBBBB"
        marginTop="27px"
        paddingTop="13px"
      >
        <Text
          fontWeight="medium"
          color="#676C77"
          fontSize={"1.5rem"}
          opacity={currentSafra && currentUnidade ? 1 : 0}
          transition="opacity .5s"
        >
          {currentView === "edit"
            ? "Editar Talhão"
            : currentView === "import"
            ? "Importar KML"
            : ""}
        </Text>

        <Box
          opacity={currentSafra && currentUnidade ? 1 : 0}
          marginTop={currentSafra && currentUnidade ? "0px" : "30px"}
          transition="all .5s"
        >
          <Button
            _focus={{}}
            _active={{}}
            _hover={{}}
            background={currentView === "edit" ? "green.800" : "#FFFFFF"}
            border="0.5px solid #9E9A9A"
            borderWidth={currentView === "edit" ? 0 : "0.5px"}
            borderTopRightRadius="0"
            borderBottomRightRadius="0"
            borderRight="0"
            onClick={() => changeCurrentView("edit")}
            color={currentView === "edit" ? "white" : "gray"}
            fontWeight="medium"
          >
            Editar Talhão
          </Button>
          <Button
            _focus={{}}
            _active={{}}
            _hover={{}}
            background={currentView === "import" ? "green.800" : "#FFFFFF"}
            border="0.5px solid #9E9A9A"
            borderWidth={currentView === "import" ? 0 : "0.5px"}
            borderTopLeftRadius="0"
            borderBottomLeftRadius="0"
            borderLeft="0"
            onClick={() => changeCurrentView("import")}
            fontWeight="medium"
            color={currentView === "import" ? "white" : "gray"}
          >
            Importar KML
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default TalhoesHeader;
