import { Flex } from "@chakra-ui/react";
import useTalhaoDetalhes from "core/features/maps/hooks/useTalhaoDetalhes";
import { CustomFeature } from "core/features/maps/typings";

import CicloDesenvolvimento from "./CicloDesenvolvimento";
import GraficoPluviometrico from "./GraficoPluviometrico";

import LoadingSkeleton from "./LoadingSkeleton";
import OutrosDetalhes from "./OutrosDetalhes";
import Pulverizacoes from "./Pulverizacoes";
import Timeline from "./Timeline";

export interface TabDetalhesProps {
  talhao: CustomFeature;
  active: boolean;
}

function TabDetalhes({ talhao, active }: TabDetalhesProps) {
  const { data: detalhesTalhao, isLoading } = useTalhaoDetalhes(
    active ? talhao.id : null
  );

  if (isLoading || !detalhesTalhao) {
    return <LoadingSkeleton />;
  }

  return (
    <Flex gridGap={2}>
      <Flex
        minW="280px"
        flexDir="column"
        gridGap={4}
        minHeight="73vh"
        height="0"
        overflowY="scroll"
        maxW="35%"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#EFEFEF",
            borderRadius: "24px",
          },
        }}
      >
        <OutrosDetalhes detalhesTalhao={detalhesTalhao!} />
        <Timeline detalhesTalhao={detalhesTalhao!} />
      </Flex>
      <Flex
        flex={1}
        flexDir="column"
        minHeight="73vh"
        height="0"
        overflowY="scroll"
        paddingRight="10px"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#EFEFEF",
            borderRadius: "24px",
          },
        }}
      >
        <GraficoPluviometrico
          talhaoId={talhao.id!}
          defaultFilter={detalhesTalhao.defaultFiltroGraficoPluviometrico!}
        />
        <CicloDesenvolvimento detalhesTalhao={detalhesTalhao!} />
        <Flex mt={6}>
          <Pulverizacoes
            detalhesTalhao={detalhesTalhao!}
            totalDeOperacoes={detalhesTalhao!.quantidadeDeOperacoes}
            ordensEmAberto={detalhesTalhao!.quantidadeDeOrdensEmAberto}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default TabDetalhes;
