import React, { useContext } from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";

import { BiChevronDown } from "react-icons/bi";
import KMLFileIcon from "assets/images/icons/kml-file.svg";
import * as S from "./style";
import useImportKMLFile from "ui/pages/app/talhoes/hooks/useImportKMLFile";
import { ImportTalhoesContext } from "core/features/talhoes/contexts/ImportTalhoes.context";

import { FaTrash } from "react-icons/fa";
import { Spinner } from "components/layout/loading-box/styles";

interface ImportFileProps {
  currentSafraId: string;
  currentUnidadeId: string;
}

const ImportFile = ({ currentSafraId, currentUnidadeId }: ImportFileProps) => {
  const { change, items } = useImportKMLFile({
    currentSafraId,
    currentUnidadeId,
  });
  const { kmlValidationRet, setKMLValidationRet, setStep } =
    useContext(ImportTalhoesContext);

  const getFileSize = () => {
    let _size = items.file?.size;
    const sufixes = ["B", "KB", "MB", "GB", "TB"];
    const i = Math.round(Math.log(_size!) / Math.log(1024));
    return `${(_size! / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
  };

  return (
    <Flex
      flexDirection={"column"}
      alignItems="center"
      justifyContent="center"
      width="100vw !important"
    >
      <Box marginRight="85px">
        <Flex justifyContent="center">
          <Menu flip preventOverflow={true} strategy="fixed">
            <MenuButton
              as={Button}
              _active={{}}
              _focus={{}}
              background="#ffffff"
              height="24px"
              fontSize="14px"
              color="#525252"
              border={`1px solid ${
                items.safraPeriodoError ? "#FA4040" : "#525252"
              }`}
              padding="px 8px"
            >
              <Flex
                alignItems="center"
                justifyContent={"center"}
                color={items.safraPeriodoError ? "#FA4040" : "#525252"}
              >
                {items.safraPeriodo
                  ? items.safraPeriodo.nomeOriginal
                  : "Selecione o período da safra"}{" "}
                <BiChevronDown />
              </Flex>
            </MenuButton>
            <MenuList>
              {items?.safras?.map((safra) => {
                return (
                  <MenuItem
                    key={safra.id}
                    onClick={() => {
                      change.setSafraPeriodo(safra);
                      change.setSafraPeriodoError(false);
                    }}
                  >
                    {safra.nomeOriginal}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        </Flex>
        <Flex
          border="0.5px dashed #838997"
          width="50vw"
          height="209px"
          borderRadius="10px"
          marginTop="20px"
          position="relative"
          overflow="hidden"
        >
          <S.Input
            ref={items.inputRef}
            type="file"
            accept=".kml"
            onDrop={(e) => {
              e.preventDefault();
              if (!items.safraPeriodo) {
                return change.setSafraPeriodoError(true);
              }
              if (!!kmlValidationRet) return;
              change.setFile(e.dataTransfer.files[0] as any);
            }}
            onChange={(e) => {
              console.log(e.target.files);
              if (!items.safraPeriodo) {
                return change.setSafraPeriodoError(true);
              }

              console.log(kmlValidationRet);
              if (!!kmlValidationRet) return;

              change.setFile(e.target?.files![0]);
              e.target.value = "";
            }}
            disabled={!!kmlValidationRet}
          />
          {/* <button onClick={testeKML}> test kml</button> */}
          <Box
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            background="#FAFAFA"
            display="flex"
            justifyContent="center"
            alignItems="center"
            pointerEvents="none"
            flexDirection={"column"}
          >
            <Image src={KMLFileIcon} />

            <Text fontSize="1.4rem" fontWeight="bold" color="#676C77">
              {kmlValidationRet
                ? "Arquivo carregado com sucesso"
                : items.isLoading
                ? "Validando arquivo..."
                : "Arraste e solte seus arquivos aqui"}
            </Text>

            {!kmlValidationRet && !items.isLoading ? (
              <Text
                fontSize="12px"
                color="#676C77B3"
                marginBottom="4.33px"
                fontWeight="bold"
              >
                Somente arquivo .KML
              </Text>
            ) : !items.isLoading ? (
              <Text
                fontSize="12px"
                color="#676C77B3"
                marginBottom="4.33px"
                fontWeight="bold"
              >
                Tamanho: {getFileSize()}
              </Text>
            ) : (
              ""
            )}

            {items.isLoading && (
              <Box mt="10px">
                <Spinner />
              </Box>
            )}

            {!kmlValidationRet && !items.isLoading && (
              <Button
                onClick={() => items.inputRef.current?.click()}
                background="green.500"
                color="white"
                fontSize="12px"
                boxShadow="0px 5px 6px #06713833"
                pointerEvents="all"
                cursor={"pointer"}
                onDrop={(e) => {
                  e.preventDefault();
                  if (!!kmlValidationRet) return;
                  change.setFile(e.dataTransfer.files[0] as any);
                }}
              >
                Escolher Arquivo
              </Button>
            )}
            {!kmlValidationRet && !items.isLoading && (
              <Text fontWeight="bold" color="#676C77B3" marginTop="4.33px">
                Tamanho máximo: 50MB
              </Text>
            )}
          </Box>
        </Flex>

        {kmlValidationRet && (
          <Flex gridGap="5px" width="100%" marginTop="21px" alignItems="center">
            <Text fontSize="18px" color="#676C77B3" fontWeight="bold">
              O arquivo{" "}
            </Text>
            <Text fontSize="18px" color="#11A557" fontWeight="bold">
              {" "}
              "{items.file?.name.split(".")[0]}"{" "}
            </Text>
            <Text fontSize="18px" color="#676C77B3" fontWeight="bold">
              contém{" "}
            </Text>
            <Text fontSize="18px" color="#11A557" fontWeight="bold">
              {kmlValidationRet?.arquivo.length}
            </Text>
            <Text fontSize="18px" color="#676C77B3" fontWeight="bold">
              talhões{" "}
            </Text>

            <IconButton
              aria-label="trash-button"
              icon={<FaTrash color="#FA4040" />}
              background="none"
              _active={{}}
              _focus={{}}
              _hover={{}}
              margin="0"
              onClick={() => {
                setKMLValidationRet(undefined);
                change.setFile(undefined);
                // inputRef!.current!.value = null;
              }}
            />
          </Flex>
        )}

        {kmlValidationRet && (
          <Flex width="100%" justifyContent="flex-end">
            <Button
              width="135px"
              background="#11A557"
              color="white"
              fontWeight="medium"
              boxShadow="0px 5px 6px #06713833"
              marginTop="20px"
              onClick={() => setStep(2)}
              isDisabled={items.isLoading}
            >
              {" "}
              Avançar
            </Button>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default ImportFile;
