import { Flex, Skeleton } from "@chakra-ui/react";

const SkeletonGraficoCustoTotal = () => {
  return (
    <Flex gap="16px" mt="32px" alignItems={"flex-end"}>
      <Skeleton roundedTop={16} mr="8px" h="400px" w="80px" />
      <Skeleton roundedTop={16} mr="48px" h="340px" w="80px" />
      <Skeleton roundedTop={16} mr="8px" h="240px" w="80px" />
      <Skeleton roundedTop={16} mr="48px" h="320px" w="80px" />
      <Skeleton roundedTop={16} mr="8px" h="400px" w="80px" />
      <Skeleton roundedTop={16} mr="48px" h="400px" w="80px" />
      <Skeleton roundedTop={16} mr="8px" h="400px" w="80px" />
      <Skeleton roundedTop={16} h="340px" w="80px" />
    </Flex>
  );
};

export default SkeletonGraficoCustoTotal;
