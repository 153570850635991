import { useDisclosure } from "@chakra-ui/react";
import { Safra, Unidade } from "core/features/maps/typings";
import React, { createContext, ReactNode, useEffect, useState } from "react";
import { TalhoesProps } from "../typings/listaEGeojson";

export enum ModalModeEnum {
  cancel = "cancel",
  delete = "delete",
  invalidPolygon = "invalid-polygon",
}

interface TalhoesContextProps {
  ocupacao: number;
  nextOcupacao?: number;
  tecnologias: string[];
  currentUnidade?: Unidade;
  currentSafra?: Safra;
  currentItem?: TalhoesProps;
  nextItem?: TalhoesProps;
  shouldShowCancelModal: boolean;
  modalMode: ModalModeEnum | undefined;
  nextUnidade?: Unidade;
  nextSafra?: Safra;
  isOpen: boolean;
  nextTecs: string[];
  pathsToSave?: {
    lat: number;
    lng: number;
  }[];
  importKMLCode: boolean;
  setCurrentUnidade: (prop: Unidade) => void;
  setCurrentSafra: (prop: Safra) => void;
  setNextUnidade: (prop: Unidade) => void;
  setNextSafra: (prop: Safra) => void;
  setOcupacao: (prop: number) => void;
  setNextOcupacao: (prop: number) => void;
  setTecnologias: (prop: string[]) => void;
  setCurrentItem: (prop?: TalhoesProps) => void;
  setNextItem: (prop?: TalhoesProps) => void;
  setModalMode: (prop: ModalModeEnum | undefined) => void;
  setShouldShowCancelModal: (prop: boolean) => void;
  onOpen: () => void;
  onClose: (resetAll: boolean) => void;
  setNextTecs: (tecs: string[]) => void;
  setPathsToSave: (
    path?: {
      lat: number;
      lng: number;
    }[]
  ) => void;
  setImportKMLCode: (prop: boolean) => void;
}

export const TalhoesContext = createContext<TalhoesContextProps>({
  currentSafra: undefined,
  currentUnidade: undefined,
  currentItem: undefined,
  ocupacao: 1,
  nextOcupacao: undefined,
  tecnologias: [],
  modalMode: ModalModeEnum.cancel,
  shouldShowCancelModal: false,
  nextSafra: undefined,
  nextUnidade: undefined,
  isOpen: false,
  pathsToSave: undefined,
  importKMLCode: false,
  setImportKMLCode: (prop: boolean) => {},
  setPathsToSave: (
    path?: {
      lat: number;
      lng: number;
    }[]
  ) => {},
  setCurrentSafra: (prop: Safra) => {},
  setCurrentUnidade: (prop: Unidade) => {},
  setCurrentItem: (prop?: TalhoesProps) => {},
  setNextItem: (prop?: TalhoesProps) => {},
  setOcupacao: (prop: number) => {},
  setNextOcupacao: (prop: number) => {},
  setTecnologias: (prop: string[]) => {},
  setModalMode: (
    prop: "cancel" | "delete" | "invalid-polygon" | undefined
  ) => {},
  setShouldShowCancelModal: (prop: boolean) => {},
  setNextSafra: (props: Safra) => {},
  setNextUnidade: (props: Unidade) => {},
  onClose: (resetAll: boolean) => {},
  onOpen: () => {},
  nextTecs: [],
  setNextTecs: (tecs: string[]) => {},
});

export const TalhoesContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [currentUnidade, setCurrentUnidade] = useState<Unidade>();
  const [currentSafra, setCurrentSafra] = useState<Safra>();
  const [nextUnidade, setNextUnidade] = useState<Unidade>();
  const [nextSafra, setNextSafra] = useState<Safra>();
  const [ocupacao, setOcupacao] = useState<number>(1);
  const [tecnologias, setTecnologias] = useState<string[]>([]);
  const [currentItem, setCurrentItem] = useState<TalhoesProps>();
  const [nextItem, setNextItem] = useState<TalhoesProps>();
  const [modalMode, setModalMode] = useState<ModalModeEnum>();
  const [shouldShowCancelModal, setShouldShowCancelModal] =
    useState<boolean>(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [nextOcupacao, setNextOcupacao] = useState<number>();
  const [nextTecs, setNextTecs] = useState<string[]>([]);
  const [importKMLCode, setImportKMLCode] = useState<boolean>(false);
  const [pathsToSave, setPathsToSave] = useState<
    {
      lat: number;
      lng: number;
    }[]
  >();

  useEffect(() => {
    if (
      (!currentUnidade || !currentSafra) &&
      !shouldShowCancelModal &&
      (nextSafra || nextUnidade)
    ) {
      setCurrentSafra(nextSafra ? nextSafra : currentSafra);
      setCurrentUnidade(nextUnidade ? nextUnidade : currentUnidade);
    }

    if (
      nextUnidade &&
      nextUnidade?.nome !== currentUnidade?.nome &&
      !shouldShowCancelModal
    ) {
      setCurrentUnidade(nextUnidade);
    }

    if (
      nextSafra &&
      nextSafra?.descricao !== currentSafra?.descricao &&
      !shouldShowCancelModal
    ) {
      setCurrentSafra(nextSafra);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextUnidade, nextSafra, shouldShowCancelModal]);

  useEffect(() => {
    if (
      (shouldShowCancelModal &&
        nextSafra &&
        nextSafra?.id !== currentSafra?.id) ||
      (shouldShowCancelModal &&
        nextUnidade &&
        nextUnidade?.id !== currentUnidade?.id)
    ) {
      setModalMode(ModalModeEnum.cancel);
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextSafra, nextUnidade]);

  useEffect(() => {
    if (shouldShowCancelModal && nextOcupacao && nextOcupacao !== ocupacao) {
      setModalMode(ModalModeEnum.cancel);
      onOpen();
    } else if (
      !shouldShowCancelModal &&
      nextOcupacao &&
      nextOcupacao !== ocupacao
    ) {
      setOcupacao(nextOcupacao);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextOcupacao, shouldShowCancelModal]);

  useEffect(() => {
    if (!tecnologias?.length && nextTecs?.length) {
      setTecnologias(nextTecs);
    }

    if (tecnologias?.length !== nextTecs?.length && !shouldShowCancelModal) {
      setTecnologias(nextTecs);
    } else if (
      tecnologias?.length !== nextTecs?.length &&
      shouldShowCancelModal
    ) {
      setModalMode(ModalModeEnum.cancel);
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTecs, shouldShowCancelModal]);

  return (
    <TalhoesContext.Provider
      value={{
        nextOcupacao,
        currentUnidade,
        currentSafra,
        ocupacao,
        tecnologias,
        currentItem,
        nextItem,
        modalMode,
        shouldShowCancelModal,
        nextSafra,
        nextUnidade,
        isOpen,
        nextTecs,
        pathsToSave,
        importKMLCode,
        setImportKMLCode,
        setPathsToSave,
        setCurrentItem,
        setOcupacao,
        setNextOcupacao,
        setTecnologias,
        setCurrentUnidade,
        setCurrentSafra,
        setNextItem,
        setModalMode,
        setShouldShowCancelModal,
        setNextSafra,
        setNextUnidade,
        setNextTecs,
        onClose: (resetAll: boolean) => {
          if (resetAll) {
            setNextSafra(undefined);
            setNextUnidade(undefined);
            setNextItem(undefined);
            setNextOcupacao(undefined);
          }
          onClose();
        },
        onOpen,
      }}
    >
      {children}
    </TalhoesContext.Provider>
  );
};
