import { Flex } from "@chakra-ui/react";
import { ImportTalhoesContext } from "core/features/talhoes/contexts/ImportTalhoes.context";
import React, { useContext } from "react";
import ImportFile from "./components/ImportFile";
import SelectTalhoes from "./components/SelectTalhoes";
import Confirmation from "./components/Confirmation";
import * as S from "./style";

interface FormsProps {
  currentSafraId: string;
  currentUnidadeId: string;
}

const Forms = ({ currentSafraId, currentUnidadeId }: FormsProps) => {
  const { step } = useContext(ImportTalhoesContext);

  return (
    <S.Container marginLeftInVW={0}>
      <Flex justifyContent={"center"} alignItems="flex-start">
        {step === 1 && (
          <ImportFile
            currentSafraId={currentSafraId}
            currentUnidadeId={currentUnidadeId}
          />
        )}

        {step === 2 && <SelectTalhoes />}

        {step === 3 && <Confirmation />}
      </Flex>
    </S.Container>
  );
};

export default Forms;
