import {
  Avatar,
  Box,
  Button,
  Flex,
  IconButton,
  Switch,
  Text,
} from "@chakra-ui/react";
import Checkbox from "components/inputs/checkbox";

import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  FaArrowLeft,
  FaCamera,
  FaCheck,
  FaSearch,
  FaUserPlus,
} from "react-icons/fa";
import TextInput from "../TextInput";
import UnidadeButton from "../UnidadeButton";
import styled from "styled-components";

import { UsuarioPerfilProps } from "core/features/users/typings";
import "./styles.css";
import { useEditUser } from "./hooks/useEditUser";
import { Unidade } from "core/features/maps/typings";

import InputMask from "../InputMask";

export interface EditUserProps {
  user?: UsuarioPerfilProps;
  handleGoBack: () => void;
}

const InputFile = styled.input`
  position: absolute;
  bottom: 3px;
  right: 5px;
  width: 43px;
  height: 53px;
  z-index: 1100;
  cursor: pointer;
  opacity: 0;
`;

const EditUser = ({ user, handleGoBack }: EditUserProps) => {
  const { items, onChange, errors } = useEditUser({ user, handleGoBack });

  const isPrecipitacaoChecked = items.receberAlertaInicioFimChuva || items.receberAlertaAcumuladoChuva;
  const showPhoneMessage = isPrecipitacaoChecked && (items.celular === "" || items.celular === undefined);

  return (
    <Flex flexDirection="column" height="100%">
      <Flex
        borderBottom="1px solid"
        borderBottomColor="gray.300"
        paddingBottom={"6px"}
        marginBottom={"15px"}
        alignItems="center"
      >
        <FaArrowLeft
          color="#009540"
          size="23px"
          style={{
            marginRight: "19px",
          }}
          onClick={handleGoBack}
        />
        <FaUserPlus color="#009540" size="27px" />
        <Text
          fontWeight={"bold"}
          fontSize={"24px"}
          color="green.700"
          marginLeft="15px"
        >
          {user ? "Editar Usuário" : "Cadastrar Usuário"}
        </Text>
      </Flex>
      <Flex height="100%" alignItems="center" justifyContent="center">
        <Flex flexDirection="column" alignItems={"center"}>
          <Box position="relative">
            <Avatar
              width="169px"
              height="169px"
              src={items.photoPreview as string}
            />
            <InputFile type="file" onChange={onChange.handleSelectPhoto} />
            <IconButton
              background="green.700"
              position="absolute"
              bottom={"3px"}
              right={"5px"}
              borderRadius="50%"
              width="47px"
              height="47px"
              icon={<FaCamera color="white" size="20px" />}
              aria-label=""
            />
          </Box>
          <Text
            fontWeight="medium"
            color="green.700"
            fontSize="18px"
            alignItems={"center"}
            marginTop="34px"
          >
            {items.ativo ? "Ativo" : "Inativo"}{" "}
            <Switch
              isChecked={items.ativo}
              onChange={() => onChange.setAtivo(!items.ativo)}
              colorScheme="green"
              size="md"
            />
          </Text>
        </Flex>
        <Flex marginLeft={"39px"} flexDirection="column" flex={1}>
          <Text fontWeight="medium" color="gray.800" marginBottom=".5%">
            INFORMAÇÕES DO USUÁRIO
          </Text>
          <TextInput
            label="E-mail"
            required
            placeholder="Email"
            error={
              errors.error && errors.error.includes("e-mail")
                ? errors.error
                : undefined
            }
            value={items.email}
            onChange={(props) => onChange.setEmail(props.target.value)}
            disabled={user?.email !== undefined}
          />
          <TextInput
            required
            error={
              errors.error && errors.error.includes("Nome")
                ? errors.error
                : undefined
            }
            label="nome"
            placeholder="Nome"
            value={items.nome}
            onChange={(props) => onChange.setNome(props.target.value)}
          />
          <InputMask
            required={items.receberAlertaInicioFimChuva || items.receberAlertaAcumuladoChuva}
            error={showPhoneMessage ? "Celular é obrigatório" : undefined}
            label="celular"
            placeholder="Celular"
            value={items.celular}
            onChange={(props) => onChange.setCelular(props.target.value)}
            mask="(99) 99999-9999"
          />
          <Flex marginTop={"1.5%"} className="permissions-title">
            <Text fontWeight="medium" fontSize="14px" color="gray.800">
              PERMISSÕES
            </Text>
            <Text color="red.600" marginLeft="5px">
              *
            </Text>
          </Flex>

          <Flex gridGap={"20px"} marginTop="1%" className="permission-boxes">
            {items.perfis?.map((perfil) => (
              <>
                <Checkbox
                  colorScheme={"green"}
                  isChecked={items.perfisSelecionados.includes(perfil.nome)}
                  onChange={() => onChange.handleCheckPermissao(perfil.nome)}
                />
                <Text key={perfil.nome} marginLeft="-15px">
                  {perfil.nome}
                </Text>
              </>
            ))}
          </Flex>

          <Flex gridGap={"20px"} className="permission-boxes">
            <Checkbox
              colorScheme={"green"}
              isChecked={items.receberAlertaInicioFimChuva}
              onChange={(e) => onChange.setReceberAlertaInicioFimChuva(e.target.checked)}
            />
            <Text marginLeft="-15px">
              Precipitação - Início e Fim de Chuva
            </Text>

            <Checkbox
              colorScheme={"green"}
              isChecked={items.receberAlertaAcumuladoChuva}
              onChange={(e) => {
                onChange.setReceberAlertaAcumuladoChuva(e.target.checked);
                onChange.setReceberAlertaAcumuladoChuvaTodasFazendas(false);
                if (items.receberAlertaAcumuladoChuvaTodasFazendas) {
                  onChange.setNotificacoesFazendas([]);
                }
              }}
            />
            <Text marginLeft="-15px">
              Precipitação - Resumo Diurno/Noturno
            </Text>

            {items.receberAlertaAcumuladoChuva && (
              <>
                <Checkbox
                  colorScheme={"green"}
                  isChecked={items.receberAlertaAcumuladoChuvaTodasFazendas}
                  onChange={(e) => {
                    onChange.setReceberAlertaAcumuladoChuvaTodasFazendas(e.target.checked);
                    onChange.handleCheckTodasNotificacaoFazendas();
                  }}
                />
                <Text marginLeft="-15px">
                  Receber de todas as fazendas 
                </Text>
              </>
            )}
          </Flex>

          <Box
            border="1px solid"
            borderColor="gray.400"
            position={"relative"}
            borderRadius={"7px"}
            marginTop="3%"
            padding="23px"
          >
            <Flex
              position={"absolute"}
              top="-10px"
              left="9px"
              background="white"
            >
              <Text
                background={"white"}
                padding="0 5px 0 0"
                fontWeight={"medium"}
                color={
                  items.perfisSelecionados.includes("Administrador")
                    ? "gray.400"
                    : "gray.800"
                }
              >
                ACESSO POR FAZENDA
              </Text>
              <Text color="red.600" marginRight={"5px"}>
                *
              </Text>
            </Flex>
            <TextInput
              label="Pesquise pelo nome da fazenda"
              placeholder="Pesquise pelo nome da fazenda"
              Icon={() => <FaSearch size="18px" />}
              rounded
              value={items.searchFazenda}
              onChange={({ target }) => onChange.setSearchFazenda(target.value)}
              disabled={items.perfisSelecionados.includes("Administrador")}
              clearFn={() => onChange.setSearchFazenda("")}
            />
            <Flex flexDirection={"row"} flexWrap={"wrap"}>
              {items.searchFazenda === "" && (
                <UnidadeButton
                  label="Todas"
                  onClick={onChange.handleCheckTodasFazendas}
                  disabled={
                    items.perfisSelecionados.includes("Administrador") ||
                    (items.perfisSelecionados.includes("Gestor de Usuários") &&
                      items.perfisSelecionados.length === 1)
                  }
                  active={
                    items.fazendas &&
                    items.fazendasSelecionadas.length >= items.fazendas.length
                  }
                />
              )}
              {!items.isFetchingPerfis && items.showFilteredFazendas
                ? items.fazendas
                    ?.filter((farm: Unidade) =>
                      farm.nome
                        .toLowerCase()
                        .includes(items.searchFazenda.toLowerCase())
                    )
                    .map((farm) => (
                      <UnidadeButton
                        disabled={items.perfisSelecionados.includes(
                          "Administrador"
                        )}
                        label={farm.nome}
                        active={items.fazendasSelecionadas.includes(farm.nome)}
                        onClick={() => onChange.handleCheckFazenda(farm.nome)}
                      />
                    ))
                : items.fazendas &&
                  items.fazendas.map((unidade) => (
                    <UnidadeButton
                      label={unidade.nome}
                      disabled={
                        items.perfisSelecionados.includes("Administrador") ||
                        (items.perfisSelecionados.includes(
                          "Gestor de Usuários"
                        ) &&
                          items.perfisSelecionados.length === 1)
                      }
                      active={items.fazendasSelecionadas.includes(unidade.nome)}
                      onClick={() => onChange.handleCheckFazenda(unidade.nome)}
                    />
                  ))}
            </Flex>
          </Box>

          <Box
            border="1px solid"
            borderColor="gray.400"
            position={"relative"}
            borderRadius={"7px"}
            marginTop="3%"
            padding="23px"
          >
            <Flex
              position={"absolute"}
              top="-10px"
              left="9px"
              background="white"
            >
              <Text
                background={"white"}
                padding="0 5px 0 0"
                fontWeight={"medium"}
                color={
                  items.perfisSelecionados.includes("Administrador")
                    ? "gray.400"
                    : "gray.800"
                }
              >
                FAZENDA PARA NOTIFICAÇÃO DE PRECIPITAÇÃO PLUVIOMÉTRICA POR WHATSAPP
              </Text>
              {isPrecipitacaoChecked && (
                <Text color="red.600" marginRight={"5px"}>*</Text>
              )}
            </Flex>
            <TextInput
              label="Pesquise pelo nome da fazenda"
              placeholder="Pesquise pelo nome da fazenda"
              Icon={() => <FaSearch size="18px" />}
              rounded
              value={items.searchNotificacaoFazenda}
              onChange={({ target }) => onChange.setSearchNotificacaoFazenda(target.value)}
              disabled={!isPrecipitacaoChecked}
              clearFn={() => onChange.setSearchNotificacaoFazenda("")}
            />
            <Flex flexDirection={"row"} flexWrap={"wrap"}>
              {items.searchNotificacaoFazenda === "" && (
                <UnidadeButton
                  label="Todas"
                  onClick={onChange.handleCheckTodasNotificacaoFazendas}
                  disabled={!isPrecipitacaoChecked}
                  active={
                    items.fazendas &&
                    items.notificacoesFazendas.length >= items.fazendas.length
                  }
                />
              )}
              {!items.isFetchingPerfis && items.showFilteredNotificaoFazendas
                ? items.fazendas
                    ?.filter((farm: Unidade) =>
                      farm.nome
                        .toLowerCase()
                        .includes(items.searchNotificacaoFazenda.toLowerCase())
                    )
                    .map((farm) => (
                      <UnidadeButton
                        disabled={false}
                        label={farm.nome}
                        active={items.notificacoesFazendas.includes(farm.nome)}
                        onClick={() => onChange.handleCheckNotificacaoFazenda(farm.nome)}
                      />
                    ))
                : items.fazendas &&
                  items.fazendas.map((unidade) => (
                    <UnidadeButton
                      label={unidade.nome}
                      disabled={!isPrecipitacaoChecked}
                      active={items.notificacoesFazendas.includes(unidade.nome)}
                      onClick={() => onChange.handleCheckNotificacaoFazenda(unidade.nome)}
                    />
              ))}
            </Flex>
          </Box>
          {isPrecipitacaoChecked && items.notificacoesFazendas.length === 0 && (
            <Text
              color="#E84336"
              fontSize={"12px"}
              marginBottom={"6px"}
              marginTop={"6px"}
            >
              Selecione no mínimo uma fazenda
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex
        borderTop={"1px solid"}
        borderTopColor="gray.400"
        marginTop={"3%"}
        paddingTop={"20px"}
        justifyContent="flex-end"
      >
        <Button
          aria-label="salvar"
          colorScheme={"gray"}
          variant="outline"
          fontWeight="medium"
          borderRadius="20px"
          height="37px"
          color="gray.500"
          borderColor="gray.500"
          marginRight="10px"
          onClick={handleGoBack}
          disabled={items.isLoading}
        >
          <AiOutlineCloseCircle style={{ marginRight: "10px" }} />
          Cancelar
        </Button>
        <Button
          aria-label="salvar"
          colorScheme={"green"}
          fontWeight="medium"
          borderRadius="20px"
          height="37px"
          onClick={
            user ? onChange.handleSubmitEditUser : onChange.handleCreateUser
          }
          isLoading={items.isLoading}
        >
          <FaCheck style={{ marginRight: "10px" }} />
          Salvar
        </Button>
      </Flex>
    </Flex>
  );
};

export default EditUser;
