import {
  Box,
  BoxProps,
  Button,
  Flex,
  Icon,
  Skeleton,
  Tag,
  Text,
} from "@chakra-ui/react";

import { AiFillCloud } from "react-icons/ai";
import { BiCalendar } from "react-icons/bi";
import Download from "ui/components/Satellite/Download/Download";
import { Image } from "core/features/image/typing";
import Kml from "components/Satellite/Download/Kml";
import RadioImage from "components/Form/RadioImage";
import RadioImageGroup from "components/Form/RadioImageGroup";
import Refetch from "components/Refetch";
import Route from "components/Satellite/Route";
import Target from "ui/components/Satellite/Download/Target";
import useAuthenticatedUser from "core/features/user/hooks/useAuthenticatedUser";
import { useEffect } from "react";
import useFarmState from "core/features/farm/states/useFarmStore";
import useImages from "core/features/farm/hooks/useImages";

interface FieldImageMenuProps extends BoxProps {
  defaultValue?: any;
  onChange?: (v: any) => void;
}

export default function FieldImageMenu({
  defaultValue,
  onChange,
  ...props
}: FieldImageMenuProps) {
  const user = useAuthenticatedUser();

  // Local Filters State
  const { farm, currentInitialDate, currentFinalDate, field } = useFarmState();
  // API Requests
  const {
    data,
    hasNextPage,
    isFetchingNextPage,
    isPlaceholderData,
    isSuccess,
    isError,
    refetch,
    fetchNextPage,
  } = useImages(
    farm?.id ?? 0,
    field?.id ?? 0,
    {
      start_date: currentInitialDate,
      finish_date: currentFinalDate,
      page_size: 10,
    },
    {
      enabled:
        !!farm?.id && !!currentInitialDate && !!currentFinalDate && !!field?.id,
      staleTime: Infinity,
    }
  );

  const handleChange = (image: Image, url: string) => {
    let type: string = "";

    switch (url) {
      case image.rgb_uri_large:
        type = "RGB";
        break;
      case image.ndvi_uri_large:
        type = "NDVI";
        break;
      case image.ndre_uri_large:
        type = "NDRE";
        break;
      case image.classifier_ndvi_uri_large:
        type = "Classificador NDVI";
        break;
      case image.classifier_ndre_uri_large:
        type = "Classificador NDRE";
        break;
    }

    // Google Analytics | Dimension Tracking
    gtag("event", "field_image_view", {
      user_id: user?.username,
      user_email: user?.username,
      farm: field?.farm_name,
      sector: `${field?.farm_name} / ${field?.sector_name}`,
      field: `${field?.farm_name} / ${field?.sector_name} / ${field?.name}`,
      iamge_type: type,
    });

    onChange?.({ url, boundingBox: image.geo });
  };

  useEffect(() => {
    const first: Image = data?.pages?.[0]?.data?.[0];
    if (!defaultValue && !isPlaceholderData && isSuccess && first) {
      onChange?.({
        url: first.rgb_uri_large,
        boundingBox: first.geo,
      });
    }
  }, [defaultValue, data, isPlaceholderData, isSuccess, onChange]);

  return (
    <Box {...props}>
      {data?.pages?.map((group, i) => (
        <Box key={i}>
          {group?.data?.map((image: Image) => (
            <Box
              key={image.image_id}
              title={`Image ${image.image_id}`}
              mb={4}
              pb={4}
              borderBottomWidth={1}
              borderBottomColor="gray.100"
            >
              <Flex justifyContent="space-between" flexWrap="wrap">
                <Box mb={2} color="gray.800">
                  <Skeleton
                    mr={1}
                    isLoaded={!isPlaceholderData}
                    display="inline-block"
                  >
                    <Icon as={BiCalendar} boxSize={5} mr={2} />
                  </Skeleton>
                  <Skeleton
                    isLoaded={!isPlaceholderData}
                    display="inline-block"
                  >
                    {new Date(image.date).toLocaleString("pt-BR")}
                  </Skeleton>
                </Box>

                <Box mb={2}>
                  <Skeleton
                    isLoaded={!isPlaceholderData}
                    mr={1}
                    display="inline-block"
                  >
                    <Tag
                      size="sm"
                      colorScheme="gray"
                      borderRadius="full"
                      fontSize="10px"
                    >
                      {image.bands_count} bandas
                    </Tag>
                  </Skeleton>
                  <Skeleton
                    isLoaded={!isPlaceholderData}
                    display="inline-block"
                  >
                    <Tag
                      size="sm"
                      colorScheme="gray"
                      borderRadius="full"
                      fontSize="10px"
                    >
                      {image.harmonized ? "harmonizado" : "não harmonizado"}
                    </Tag>
                  </Skeleton>
                </Box>
              </Flex>

              <Flex flexWrap="nowrap">
                <Box flex={1}>
                  <RadioImageGroup
                    name="image"
                    value={defaultValue?.url}
                    onChange={(url: string) => handleChange(image, url)}
                  >
                    <RadioImage
                      url={image.rgb_uri}
                      value={image.rgb_uri_large}
                      isLoading={isPlaceholderData}
                    >
                      RGB
                    </RadioImage>
                    <RadioImage
                      url={image.ndvi_uri}
                      value={image.ndvi_uri_large}
                      isLoading={isPlaceholderData}
                    >
                      NDVI
                    </RadioImage>
                    {image.classifier_ndvi_uri &&
                    image.classifier_ndvi_uri_large ? (
                      <RadioImage
                        url={image.classifier_ndvi_uri}
                        value={image.classifier_ndvi_uri_large}
                        bottomElement={
                          image.image_id &&
                          defaultValue?.url ===
                            image.classifier_ndvi_uri_large && (
                            <Route imageId={image.image_id} />
                          )
                        }
                        isLoading={isPlaceholderData}
                      >
                        NDVI Classificador
                      </RadioImage>
                    ) : (
                      <></>
                    )}
                  </RadioImageGroup>

                  <RadioImageGroup
                    name="image"
                    value={defaultValue?.url}
                    onChange={(url: string) => handleChange(image, url)}
                  >
                    <Box w="45px" />
                    {image.ndre_uri && image.ndre_uri_large ? (
                      <RadioImage
                        url={image.ndre_uri}
                        value={image.ndre_uri_large}
                        isLoading={isPlaceholderData}
                      >
                        NDRE
                      </RadioImage>
                    ) : (
                      <></>
                    )}
                    {image.classifier_ndre_uri &&
                    image.classifier_ndre_uri_large ? (
                      <RadioImage
                        url={image.classifier_ndre_uri}
                        value={image.classifier_ndre_uri_large}
                        bottomElement={
                          image.image_id &&
                          defaultValue?.url ===
                            image.classifier_ndre_uri_large && (
                            <Route imageId={image.image_id} />
                          )
                        }
                        isLoading={isPlaceholderData}
                      >
                        NDRE Classificador
                      </RadioImage>
                    ) : (
                      <></>
                    )}
                  </RadioImageGroup>
                </Box>
                <Flex flexDirection="column" justifyContent="space-between">
                  <Skeleton
                    mr={1}
                    h="25px"
                    display="inline-block"
                    isLoaded={!isPlaceholderData}
                  >
                    <Flex alignContent="center" flexWrap="nowrap">
                      <Icon
                        as={AiFillCloud}
                        boxSize={6}
                        mr={1}
                        color="#298CD8"
                      />
                      <Text>{image.cloud_percentage?.toFixed?.(1) ?? 0}%</Text>
                    </Flex>
                  </Skeleton>
                  <Skeleton
                    h="25px"
                    display="inline-block"
                    isLoaded={!isPlaceholderData}
                  >
                    <Download>
                      {image.tif_uri && (
                        <Target px={2} py={1} url={image.tif_uri}>
                          Baixar TIF
                        </Target>
                      )}
                      <Kml
                        imageId={image.image_id}
                        px={2}
                        py={1}
                        isDisabled={false}
                      />
                    </Download>
                  </Skeleton>
                </Flex>
              </Flex>
            </Box>
          ))}
        </Box>
      ))}
      {isSuccess &&
        (data?.pages?.[0]?.data?.length ? (
          <Flex>
            <Button
              m="auto"
              size="sm"
              disabled={!hasNextPage || isFetchingNextPage}
              onClick={() => fetchNextPage()}
            >
              {isFetchingNextPage
                ? "Carregando ..."
                : hasNextPage
                ? "Carregar mais"
                : "Não há mais dados"}
            </Button>
          </Flex>
        ) : (
          <Text>Não há imagens para o período selecionado!</Text>
        ))}
      {isError && (
        <Refetch
          title="Erro ao obter a lista de imagens para o talhão."
          refetch={refetch}
        />
      )}
    </Box>
  );
}
