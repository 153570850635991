import FieldLayer from "./FieldLayer";
import ImageLayer from "./ImageLayer";
import RouteLayer from "./RouteLayer";
import { memo } from "react";

interface LayersProps {
  map: google.maps.Map | undefined;
}

function Layers({ map }: LayersProps) {
  return (
    <>
      {map && <FieldLayer map={map} />}
      {map && <ImageLayer map={map} />}
      {map && <RouteLayer map={map} />}
    </>
  );
}

export default memo(Layers);
