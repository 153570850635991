import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuProps,
} from "@chakra-ui/react";

import { BiChevronDown } from "react-icons/bi";

interface DownloadProps extends MenuProps {
  isDisabled?: boolean;
}

export default function Download({ isDisabled, ...props }: DownloadProps) {
  return (
    <Menu {...props}>
      <MenuButton
        as={Button}
        size="sm"
        colorScheme="green"
        p="2px"
        pr="10px"
        pl="5px"
        height="auto"
        fontSize="12px"
        borderRadius="full"
        variant="outline"
        leftIcon={<Icon as={BiChevronDown} />}
        isDisabled={isDisabled}
        role="button"
      >
        Baixar
      </MenuButton>
      <MenuList p={0} minW="auto">
        {props.children}
      </MenuList>
    </Menu>
  );
}
