import { MapTypeId } from "core/features/maps/enums";
import {
  FiltroConcentracaoColheita,
  FiltroDistribuicaoDeEquipes,
  FiltroDistribuicaoVarietal,
  FiltroIdadeDoTalhao,
  FiltroNumeroDeAplicacoes,
  FiltroOperacoesExecutadas,
  FiltroOrdensDeServicosAbertas,
  FiltroMapaDeChuvas,
  FiltroProdutividade,
  TexturaSoloListagemItem,
  TipoMapaTalhoes,
} from "core/features/maps/typings";
import create from "zustand";
import { configurePersist } from "zustand-persist";
import { FiltersType } from "../typings";

const { persist } = configurePersist({
  storage: localStorage,
});

const defaultValue: FiltersType = {
  setor: null,
  filtroProdutividade: null,
  tipoMapa: TipoMapaTalhoes.DistribuicaoVarietal,
  mapType: MapTypeId.HYBRID,
  filtroDistribuicaoVarietal: null,
  safra: null,
  unidade: null,
  texturas: null,
  filtroOperacoesExecutadas: null,
  filtroNumeroDeAplicacoes: null,
  filtroConcentracaoColheita: null,
  filtroIdadeDoTalhao: null,
  filtroOrdensDeServicosAbertas: null,
  filtroDistribuicaoDeEquipes: null,
  filtroMapaDeChuva: null,
};

export interface UseFilters {
  isBootLoading: boolean;
  filters: FiltersType;
  clear: () => void;
  updateBootLoading: (state: boolean) => void;
  update: (value: FiltersType) => void;
  updateField: (prop: keyof FiltersType, value: any) => any;
  updateFiltroOcupacao: (
    value: Partial<FiltroDistribuicaoVarietal>,
    merge?: boolean
  ) => any;
  updateFiltroTexturas: (
    value: TexturaSoloListagemItem[],
    merge?: boolean
  ) => any;
  updateFiltroOperacoesExecutadas: (
    value: Partial<FiltroOperacoesExecutadas>,
    merge?: boolean
  ) => any;
  updateFiltroProdutividade: (
    value: Partial<FiltroProdutividade>,
    merge?: boolean
  ) => any;
  updateFiltroConcentracaoColheita: (
    value: Partial<FiltroConcentracaoColheita>,
    merge?: boolean
  ) => any;
  updateFiltroNumeroDeAplicacoes: (
    value: Partial<FiltroNumeroDeAplicacoes>,
    merge?: boolean
  ) => any;
  updateFiltroIdadeDoTalhao: (
    value: Partial<FiltroIdadeDoTalhao>,
    merge?: boolean
  ) => any;
  updateFiltroOrdensDeServicosAbertas: (
    value: Partial<FiltroOrdensDeServicosAbertas>,
    merge?: boolean
  ) => any;
  updateFiltroDistribuicaoDeEquipes: (
    value: Partial<FiltroDistribuicaoDeEquipes>,
    merge?: boolean
  ) => any;
  updateFiltroMapaDeChuvas: (
    value: Partial<FiltroMapaDeChuvas>,
    merge?: boolean
  ) => any;

  clearFiltroOcupacao: () => any;
}

export const useFilters = create<UseFilters>(
  persist(
    {
      key: "@filters-agrosig-v1",
    },
    (set) => ({
      isBootLoading: false,
      filters: defaultValue,
      clear: () =>
        set(() => ({
          filters: defaultValue,
        })),
      update: (value: FiltersType) => set(() => ({ filters: value })),
      updateBootLoading: (state: boolean) =>
        set(() => ({ isBootLoading: state })),
      updateField: (prop: keyof FiltersType, value: any) => {
        set((state) => {
          return {
            ...state,
            filters: Object.assign(state.filters, {
              [prop]: value,
            }),
          };
        });
      },
      updateFiltroOcupacao: (
        value: Partial<FiltroDistribuicaoVarietal>,
        merge = false
      ) => {
        set((state) => {
          return {
            ...state,
            filters: Object.assign(state.filters, {
              filtroDistribuicaoVarietal: merge
                ? { ...state.filters.filtroDistribuicaoVarietal, ...value }
                : value,
            }),
          };
        });
      },
      updateFiltroTexturas: (
        value: TexturaSoloListagemItem[],
        merge = false
      ) => {
        set((state) => {
          return {
            ...state,
            filters: Object.assign(state.filters, {
              texturas: merge ? state.filters.texturas?.concat(value) : value,
            }),
          };
        });
      },
      updateFiltroOperacoesExecutadas: (
        value: Partial<FiltroOperacoesExecutadas>,
        merge = false
      ) => {
        set((state) => {
          return {
            ...state,
            filters: Object.assign(state.filters, {
              filtroOperacoesExecutadas: merge
                ? { ...state.filters.filtroOperacoesExecutadas, ...value }
                : value,
            }),
          };
        });
      },
      updateFiltroConcentracaoColheita: (
        value: Partial<FiltroConcentracaoColheita>,
        merge = false
      ) => {
        set((state) => {
          return {
            ...state,
            filters: Object.assign(state.filters, {
              filtroConcentracaoColheita: merge
                ? { ...state.filters.filtroConcentracaoColheita, ...value }
                : value,
            }),
          };
        });
      },
      updateFiltroProdutividade: (
        value: Partial<FiltroProdutividade>,
        merge = false
      ) => {
        set((state) => {
          return {
            ...state,
            filters: Object.assign(state.filters, {
              filtroProdutividade: merge
                ? { ...state.filters.filtroProdutividade, ...value }
                : value,
            }),
          };
        });
      },
      updateFiltroNumeroDeAplicacoes: (
        value: Partial<FiltroNumeroDeAplicacoes>,
        merge = false
      ) => {
        set((state) => {
          return {
            ...state,
            filters: Object.assign(state.filters, {
              filtroNumeroDeAplicacoes: merge
                ? { ...state.filters.filtroNumeroDeAplicacoes, ...value }
                : value,
            }),
          };
        });
      },
      updateFiltroIdadeDoTalhao: (
        value: Partial<FiltroIdadeDoTalhao>,
        merge = false
      ) => {
        set((state) => {
          return {
            ...state,
            filters: Object.assign(state.filters, {
              filtroIdadeDoTalhao: merge
                ? { ...state.filters.filtroIdadeDoTalhao, ...value }
                : value,
            }),
          };
        });
      },
      clearFiltroOcupacao: () => {
        set((state) => ({
          ...state,
          filters: {
            ...state.filters,
            filtroDistribuicaoVarietal: {
              ocupacoes: [],
              finalidades: [],
              tecnologias: [],
              variedadeGrupos: [],
              variedades: [],
            },
          },
        }));
      },
      updateFiltroOrdensDeServicosAbertas: (
        value: Partial<FiltroOrdensDeServicosAbertas>,
        merge = false
      ) => {
        set((state) => ({
          ...state,
          filters: Object.assign(state.filters, {
            filtroOrdensDeServicosAbertas: merge
              ? { ...state.filters.filtroOrdensDeServicosAbertas, ...value }
              : value,
          }),
        }));
      },
      updateFiltroDistribuicaoDeEquipes: (
        value: Partial<FiltroDistribuicaoDeEquipes>,
        merge: any = false
      ) => {
        set((state) => ({
          ...state,
          filters: Object.assign(state.filters, {
            filtroDistribuicaoDeEquipes: merge
              ? { ...state.filters.filtroDistribuicaoDeEquipes, ...value }
              : value,
          }),
        }));
      },
      updateFiltroMapaDeChuvas: (value: any, merge?: boolean) => {
        set((state) => ({
          ...state,
          filters: Object.assign(state.filters, {
            filtroMapaDeChuva: merge
              ? { ...state.filters.filtroMapaDeChuva, ...value }
              : value,
          }),
        }));
      },
    })
  )
);
