import URL from "core/resources/urls";
import http from "infra/http";
import { useQuery } from "react-query";
import {
  UseListaEGeojsonProps,
  UseListaEGeojsonReturnProps,
} from "../typings/listaEGeojson";

export const useListaEGeojson = ({
  safraId,
  unidadeId,
  Ocupacao,
  Tecnologias,
}: UseListaEGeojsonProps) => {
  return useQuery(
    [
      URL.EDITAR_TALHOES.LISTA_E_GEOJSON,
      unidadeId,
      safraId,
      Ocupacao,
      Tecnologias,
    ],
    async () => {
      const { data } = await http.post(
        URL.EDITAR_TALHOES.LISTA_E_GEOJSON(unidadeId, safraId),
        {
          Ocupacao,
          Tecnologias,
        }
      );

      return {
        items: data as UseListaEGeojsonReturnProps,
      };
    }
  );
};
