const win: any = window as any;

const env = process.env ?? {};
const winEnv = win.env ?? {};

const config = {
  isDev: env.NODE_ENV === "development",
  env: env.NODE_ENV || win.NODE_ENV || "development",
  urlApi: env.REACT_APP_API_BASE_URL || winEnv.REACT_APP_API_BASE_URL,
  apiFarmUrl: env.REACT_APP_API_FARM_URL || winEnv.REACT_APP_API_FARM_URL,
  gaMeasurementId: env.REACT_APP_GA_MEASUREMENT_ID || winEnv.REACT_APP_GA_MEASUREMENT_ID,
  urlAgrodriveApi:
    env.REACT_APP_AGRODRIVE_API || winEnv.REACT_APP_AGRODRIVE_API,
  agrodriveClientId:
    env.REACT_APP_AGRODRIVE_CLIENT_ID ||
    winEnv.REACT_APP_AGRODRIVE_CLIENT_ID ||
    "7c7d65e8-39b6-4c78-a5d2-ab162324986e",
  azure: {
    nuvem: {
      authority:
        env.REACT_APP_NUVEM_AZURE_AD_AUTHORITY ||
        winEnv.REACT_APP_NUVEM_AZURE_AD_AUTHORITY,
      clientId:
        env.REACT_APP_NUVEM_AZURE_AD_CLIENT_ID ||
        winEnv.REACT_APP_NUVEM_AZURE_AD_CLIENT_ID,
    },
    amaggi: {
      authority:
        env.REACT_APP_AMAGGI_AZURE_AD_AUTHORITY ||
        winEnv.REACT_APP_AMAGGI_AZURE_AD_AUTHORITY,
      clientId:
        env.REACT_APP_AMAGGI_AZURE_AD_CLIENT_ID ||
        winEnv.REACT_APP_AMAGGI_AZURE_AD_CLIENT_ID,
    },
  },
  name: env.REACT_APP_TITLE || winEnv.REACT_APP_TITLE,
};

export default config;
