import {
  CustomFeature,
  CustomLatLng,
  LatLong,
} from "core/features/maps/typings";
import { FeatureCollection } from "geojson";

export interface UseMapaParams {
  onClickItem?: (latLng: CustomLatLng, feature: CustomFeature) => void;
  points?: FeatureCollection;
  center?: LatLong;
  talhoes?: string[];
}

export type MapRef = google.maps.Map | null;

export interface PropertiesProps {
  Properties?: {
    ProdutividadeMedia?: string;
    latitude?: number;
    latitudeDelta?: number;
    latitudeMaxima?: number;
    latitudeMinima?: number;
    longitude?: number;
    longitudeDelta?: number;
    longitudeMaxima?: number;
    longitudeMinima?: number;
    totalAreaHa?: number;
  };
}

export interface PointsBaseMap extends FeatureCollection {
  Properties?: {
    ProdutividadeMedia?: string;
    latitude?: number;
    latitudeDelta?: number;
    latitudeMaxima?: number;
    latitudeMinima?: number;
    longitude?: number;
    longitudeDelta?: number;
    longitudeMaxima?: number;
    longitudeMinima?: number;
    totalAreaHa?: number;
  };
}

export enum GeometryType {
  Polygon = "Polygon",
  Point = "Point",
  MultiPolygon = "MultiPolygon",
}

export enum CurrentViewTypeEnum {
  box = "box",
  numberOnly = "numberOnly",
  transparent = "transparent",
}
