import React from "react";
import { Button, Text } from "@chakra-ui/react";
import { FaCheck, FaPlus } from "react-icons/fa";

interface UnidadeButtonProps {
  label: string;
  active?: boolean;
  onClick: () => void;
  disabled: boolean;
}

const UnidadeButton = ({
  label,
  active,
  onClick,
  disabled,
}: UnidadeButtonProps) => {
  return (
    <Button
      background={active ? "green.700" : "white"}
      borderRadius="20px"
      fontSize={"14px"}
      height="28px"
      color={active ? "white" : "gray.800"}
      fontWeight="regular"
      alignItems="center"
      variant="outline"
      marginTop={"12.5px"}
      marginRight="8px"
      onClick={onClick}
      _focus={{}}
      _hover={{}}
      disabled={disabled}
    >
      <Text
        marginRight={"7px"}
        color={active ? "white" : "gray.700"}
        fontWeight="medium"
      >
        {label}
      </Text>
      {active ? <FaCheck /> : <FaPlus />}
    </Button>
  );
};

export default UnidadeButton;
