import { Flex, Text, Box, Button } from "@chakra-ui/react";
import { ImportTalhoesContext } from "core/features/talhoes/contexts/ImportTalhoes.context";
import { TalhoesContext } from "core/features/talhoes/contexts/Talhoes.context";

import { useContext } from "react";

const Confirmation = () => {
  const {
    safraPeriodo,
    talhoesAtualizados,

    handleClearFields,
  } = useContext(ImportTalhoesContext);
  const { currentSafra, currentUnidade } = useContext(TalhoesContext);

  return (
    <Flex flexDirection="column" width="50vw">
      <Text color="#11A557" fontSize="22px" fontWeight="medium">
        Importação concluída com sucesso!
      </Text>
      <Flex gridGap="4px" marginTop="20px" marginBottom="5px">
        <Text color="#676C77" fontWeight="bold" fontSize="18px">
          {talhoesAtualizados.length} talhões
        </Text>
        <Text color="#676C77" fontSize="18px">
          atualizados
        </Text>
      </Flex>

      <Box
        background="white"
        border="1px solid #707070"
        paddingX="22px"
        paddingY="8px"
        borderRadius="4px"
        height="156px"
      >
        <Flex>
          <Text
            color="#4D4F5C"
            fontWeight="medium"
            fontSize="18px"
            marginRight="5px"
          >
            {" "}
            {currentUnidade?.nome},{" "}
          </Text>

          <Text fontSize="18px" color="#4D4F5C">
            {safraPeriodo?.nome} {currentSafra?.descricao}
          </Text>
        </Flex>

        <Flex
          marginLeft="20px"
          color="#4D4F5C"
          fontSize="16px"
          fontWeight="medium"
          gridGap="5px"
        >
          {talhoesAtualizados.map((item, index) => (
            <Text>
              {item}
              {index !== talhoesAtualizados.length - 1 && ", "}
            </Text>
          ))}
        </Flex>
      </Box>
      <Flex flexDirection="row-reverse">
        <Button
          height="48px"
          background="#11A557"
          color="white"
          fontWeight="normal"
          width="175px"
          borderRadius="10px"
          fontSize="16px"
          marginTop="30px"
          boxShadow="0px 5px 6px #06713833"
          onClick={handleClearFields}
        >
          Finalizar
        </Button>
      </Flex>
    </Flex>
  );
};

export default Confirmation;
