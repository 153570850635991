import { Button } from "@chakra-ui/react";

interface PeriodoButtonProps {
  title: string;
  isChecked: boolean;
  onClick: () => void;
}

export const PeriodoButton = ({
  title,
  isChecked,
  onClick,
}: PeriodoButtonProps) => {
  return (
    <Button
      _focus={{}}
      _active={{}}
      background={isChecked ? "green.700" : "none"}
      fontWeight={isChecked ? "semibold" : "regular"}
      color={isChecked ? "white" : "gray.500"}
      borderRadius={22}
      border={isChecked ? "none" : `1px solid #707070`}
      margin="3px"
      onClick={onClick}
    >
      {title}
    </Button>
  );
};
