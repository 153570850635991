import { Box, BoxProps, Skeleton, Tag } from "@chakra-ui/react";

import Refetch from "components/Refetch";
import { Sector } from "core/features/farm/typing";
import useAuthenticatedUser from "core/features/user/hooks/useAuthenticatedUser";
import useFarmSectors from "core/features/farm/hooks/useFarmSectors";
import useFarmStore from "core/features/farm/states/useFarmStore";

interface SectorSelectorProps extends BoxProps {}

export default function SectorSelector(props: SectorSelectorProps) {
  const user = useAuthenticatedUser();

  const { farm, sector, update } = useFarmStore();

  // API Request
  const { data, isLoading, isFetching, isError, refetch } = useFarmSectors(
    farm?.id ?? 0,
    { order: "name" },
    { enabled: !!farm?.id, staleTime: Infinity }
  );

  return (
    <Box {...props}>
      {data?.map((s: Sector) => (
        <Skeleton
          key={s.id}
          mr={2}
          mb={2}
          borderRadius="full"
          display="inline-block"
          isLoaded={!isLoading && !isFetching}
        >
          <Tag
            px={4}
            py={2}
            variant={sector?.id === s.id ? "solid" : "outline"}
            colorScheme={sector?.id === s.id ? "green" : "gray"}
            borderRadius="full"
            cursor="pointer"
            title={`${s.name}${sector?.id === s.id ? " selecionado" : ""}`}
            onClick={() => {
              // Google Analytics | Dimension Tracking
              gtag("event", "sector_view", {
                user_id: user?.username,
                user_email: user?.username,
                farm: s.farm_name,
                sector: `${s.farm_name} / ${s.name}`,
              });

              update({
                sector: { farm_name: s.farm_name, id: s.id, name: s.name },
                field: undefined,
              });
            }}
          >
            {s.name}
          </Tag>
        </Skeleton>
      ))}
      {isError && (
        <Refetch title="Erro ao obter os setores ..." refetch={refetch} />
      )}
    </Box>
  );
}
