import { useFiltersLists } from "features/filters/store/lists";
import { useFilters } from "features/filters/store";

import { TecnologiasItem } from "features/maps/typings";

function useProdutividadeTecnologiasFilters() {
  const { filters, updateFiltroProdutividade } = useFilters();
  const { produtividade } = useFiltersLists();

  const { filtroProdutividade } = filters ?? {};

  const findTecnologiasByOcupacaoSelected = produtividade?.ocupacoes.find(
    (item) => item.codigo === filtroProdutividade?.ocupacao
  );

  const updateTecnologiasSelected = (tecnologiaId?: string) => {
    if (filters.filtroProdutividade?.tecnologias.length) {
      const findElementIndex =
        filters.filtroProdutividade.tecnologias.findIndex(
          (item) => item === tecnologiaId
        );
      const newArray = [...filters.filtroProdutividade.tecnologias];
      if (findElementIndex > -1) {
        if (filters.filtroProdutividade?.tecnologias.length === 1) {
          return;
        }
        newArray.splice(findElementIndex, 1);
      } else {
        newArray.push(tecnologiaId as string);
      }
      const foundCompativeProdutividades =
        filters.filtroProdutividade.produtividades.filter((item) =>
          newArray.includes(item.tecnologiaId as string)
        );

      const checkTodos = findTecnologiasByOcupacaoSelected?.tecnologias
        .filter((item) => item.id !== null)
        .map((i) => {
          return i.id;
        });

      if (!tecnologiaId) {
        updateFiltroProdutividade({
          produtividades: foundCompativeProdutividades,
          ocupacao: filters.filtroProdutividade?.ocupacao,
          tecnologias: checkTodos,
        });
      } else {
        updateFiltroProdutividade({
          produtividades: foundCompativeProdutividades,
          ocupacao: filters.filtroProdutividade?.ocupacao,
          tecnologias: newArray,
        });
      }
    }
  };

  return {
    updateTecnologiasSelected,
    valueTecnologias: filters.filtroProdutividade?.tecnologias ?? [],
    tecnologias:
      findTecnologiasByOcupacaoSelected?.tecnologias as TecnologiasItem[],
  };
}

export default useProdutividadeTecnologiasFilters;
