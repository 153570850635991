import { Flex } from "@chakra-ui/react";
import LoadingBox from "components/layout/loading-box";

import { useFilters } from "core/features/filters/store";

import { useMapaDeChuvaPeriodos } from "../../hooks/useMapaDeChuvaPeriodos";
import { PeriodoButton } from "../PeriodoButton";

export const MapaDeChuvaPeriodos = ({ isVisible }: { isVisible: boolean }) => {
  const { items, value, onChange, loading } = useMapaDeChuvaPeriodos();
  const { filters } = useFilters();
  const { filtroMapaDeChuva } = filters;
  const initialDateSplited = filtroMapaDeChuva?.dataInicial?.split("-") || [];
  const initialDate = `${initialDateSplited[2]}/${initialDateSplited[1]}/${initialDateSplited[0]}`;

  const finalDateSplited = filtroMapaDeChuva?.dataFinal?.split("-") || [];
  const finalDate = `${finalDateSplited[2]}/${finalDateSplited[1]}/${finalDateSplited[0]}`;

  const repeatInitialDate =
    filtroMapaDeChuva?.dataInicial && !filtroMapaDeChuva?.dataFinal;
  const repeatEndDate =
    filtroMapaDeChuva?.dataFinal && !filtroMapaDeChuva?.dataInicial;

  const customPeriodo = repeatInitialDate
    ? `${initialDate}-${initialDate}`
    : repeatEndDate
    ? `${finalDate}-${finalDate}`
    : `${initialDate}-${finalDate}`;

  if (loading) {
    return (
      <Flex pl={1} mb={1} alignItems="center" flex="1">
        <LoadingBox isVisible={isVisible} fitFilterContent />
      </Flex>
    );
  }

  return (
    <Flex flexWrap="wrap" gap="6px">
      {filtroMapaDeChuva?.periodo === 6 && (
        <PeriodoButton title={customPeriodo} onClick={() => ""} isChecked />
      )}

      {items?.map((periodo) => {
        return (
          <PeriodoButton
            title={periodo.periodo}
            isChecked={value === periodo.codigo}
            onClick={() => onChange(periodo.codigo)}
          />
        );
      })}
    </Flex>
  );
};
