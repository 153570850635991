import { Flex, Skeleton } from "@chakra-ui/react";

export default function LoadingSkeletonCheckbox() {
  return (
    <Flex margin="5px 0">
      <Skeleton height="17px" width="17px" marginRight="4px" />
      <Skeleton height="17px" width="17px" marginRight="4px" />
      <Skeleton height="15px" width="50px" marginRight="4px" />
    </Flex>
  );
}
