import { Checkbox as CheckboxChakra, CheckboxProps } from "@chakra-ui/checkbox";

function Checkbox({ ...rest }: CheckboxProps) {
  return (
    <CheckboxChakra 
      borderRadius="4px" 
      colorScheme="messenger"
      {...rest}
    />
  );
}

export default Checkbox;
