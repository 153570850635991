import {
  MenuItem,
  MenuItemProps,
  useBoolean,
  useToast,
} from "@chakra-ui/react";

import useImageRouteDownload from "core/features/image/hooks/useImageRouteDownload";

interface KmlProps extends MenuItemProps {
  imageId: string | number;
}

export default function Kml({ imageId, ...props }: KmlProps) {
  const params = {};
  const toast = useToast();
  const [download, setDownload] = useBoolean(false);
  const { isFetching, isLoading } = useImageRouteDownload(imageId, params, {
    enabled: !props.isDisabled && !!download,
    onSuccess: (data: any) => {
      try {
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          data?.headers?.["x-suggested-filename"] ?? "route.kml"
        );
        document.body.appendChild(link);
        link.click();
      } catch (error: any) {
        toast({
          title: "Erro ao baixar arquivo de rota",
          description: error.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    },
    onError: () => {
      toast({
        title: "Erro ao baixar arquivo de rota",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    },
    onSettled: () => setDownload.off(),
  });

  return (
    <MenuItem {...props} onClick={setDownload.on}>
      {isFetching || isLoading ? "Baixando KML ..." : "Baixar KML da rota"}
    </MenuItem>
  );
}
