import {Farm, Field, Sector} from "../typing";

import create from "zustand";

export type FarmState = {
    // Fazenda na API Agrosig Web.
    farm: Partial<Farm> | undefined;
    setFarm: (farm: Partial<Farm>) => void;
    
    // Data inicial da safra na API Agrosig Web.
    initialDate: string | undefined;
    setInitialDate: (initialDate: string | undefined) => void;
    // Data final da safra na API Agrosig Web.
    finalDate: string | undefined;
    setFinalDate: (finalDate: string | undefined) => void;
    
    currentInitialDate: string | undefined;
    setCurrentInitialDate: (currentInitialDate: string | undefined) => void;
    currentFinalDate: string | undefined;
    setCurrentFinalDate: (currentFinalDate: string | undefined) => void;
    
    bounds: boolean | undefined;
    setBounds: (boundary: boolean | undefined) => void;
    sector: Partial<Sector> | undefined;
    setSector: (sector: Partial<Sector> | undefined) => void;
    field: Partial<Field> | undefined;
    setField: (fieldId: Partial<Field> | undefined) => void;
    update: (state: Partial<FarmState>) => void;
    reset: () => void;
}

const useFarmStore = create<FarmState>(set => ({
    farm: undefined,
    setFarm: (farm: Partial<Farm> | undefined) => set((state: FarmState) => ({ ...state, farm: farm })),
    
    initialDate: undefined,
    setInitialDate: (initialDate: string | undefined) => set((state: FarmState) => ({ ...state, initialDate })),
    finalDate: undefined,
    setFinalDate: (finalDate: string | undefined) => set((state: FarmState) => ({ ...state, finalDate })),
    currentInitialDate: undefined,
    setCurrentInitialDate: (currentInitialDate: string | undefined) => set((state: FarmState) => ({ ...state, currentInitialDate })),
    currentFinalDate: undefined,
    setCurrentFinalDate: (currentFinalDate: string | undefined) => set((state: FarmState) => ({ ...state, currentFinalDate })),

    bounds: true,
    setBounds: (bounds: boolean | undefined) => set((state: FarmState) => ({ ...state, bounds })),
    sector: undefined,
    setSector: (sector: Partial<Sector> | undefined) => set((state: FarmState) => ({ ...state, sector: sector })),
    field: undefined,
    setField: (field: Partial<Field> | undefined) => set((state: FarmState) => ({ ...state, field: field })),
    update: (newState: Partial<FarmState>) => set((state: FarmState) => ({ ...state, ...newState })),
    reset: () => set(() => ({
        farm: undefined,
        initialDate: undefined,
        finalDate: undefined,
        currentInitialDate: undefined,
        currentFinalDate: undefined,
        bounds: true,
        sector: undefined,
        field: undefined
    }))
}));

export default useFarmStore;
