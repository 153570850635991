import URL from "core/resources/urls";
import http from "infra/http";
import { useQuery } from "react-query";
import { NumeroDeAplicacoes, TipoMapaTalhoes } from "../typings";

export function useNumeroDeAplicacoes(
  unidadeId: string,
  safraId: string,
  tipoMapa: TipoMapaTalhoes
) {
  return useQuery(
    URL.MAPAS.NUMERO_DE_APLICACOES(unidadeId!, safraId!),
    async () => {
      if (unidadeId && safraId) {
        const { data } = await http.get<NumeroDeAplicacoes>(
          URL.MAPAS.NUMERO_DE_APLICACOES(unidadeId!, safraId!)
        );

        return data;
      }
    },
    {
      enabled: tipoMapa === TipoMapaTalhoes.NumeroDeAplicacoes,
    }
  );
}
