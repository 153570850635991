import {
  Button,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

import { BiChevronDown } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import { MdOutlineMoreVert, MdOutlineUploadFile } from "react-icons/md";
import { HiDownload } from "react-icons/hi";
import { AiOutlineFolderAdd } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";

import { ActionButton } from "./ActionButton";
import { green700 } from "core/resources/theme/colors";
import ModalNewFolder from "./ModalNewFolder";
import ModalNewFile from "./ModalNewFile";
import { AgrodriveContext } from "core/features/agrodrive/contexts/Agrodrive.context";
import { useContext } from "react";

import { showConfirm, showError, showSuccess } from "infra/helpers/alerta";
import useDeleteFiles from "../hooks/useDeleteFiles";
import useDownloadFiles from "../hooks/useDownloadFiles";
import { forceDownloadByUrl } from "infra/helpers/download";

interface AgrodriveButtonsProps {
  localizacao: string;
}

export const AgrodriveButtons = ({ localizacao }: AgrodriveButtonsProps) => {
  // const { mutate: handleSave, isLoading } = useCreateFile();
  // const [openPasta, setOpenPasta] = useState(false);
  // const [itemEdit, setItemEdit] = useState<ArquivoResponse>();

  const {
    items: { isLoadingDiretorioList, selecteds },
    onChange: { refetchDiretorioList, setSelecteds, setUpdating },
  } = useContext(AgrodriveContext);

  const { mutate: handleDelete, isLoading: isLoadingDelete } = useDeleteFiles();

  const { data: downloadData, isLoading: isLoadingDownload } = useDownloadFiles(
    selecteds.map((item) => item.id)
  );

  const {
    isOpen: isOpenModalNewFolder,
    onOpen: onOpenModalNewFolder,
    onClose: onCloseModalNewFolder,
  } = useDisclosure();

  const {
    isOpen: isOpenModalNewFile,
    onOpen: onOpenModalNewFile,
    onClose: onCloseModalNewFile,
  } = useDisclosure();

  // const handleChangeFile = useCallback(
  //   (e: React.ChangeEvent<HTMLInputElement>) => {
  //     handleSave(
  //       { files: e.target.files },
  //       {
  //         onError() {
  //           showError();
  //         },
  //         onSuccess() {
  //           showSuccess();
  //           onRefresh();
  //         },
  //       }
  //     );
  //   },
  //   [handleSave, paths, onRefresh]
  // );

  // useLoading(isLoadingDelete);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const verifyDownloadSelectedSize = () => {
    const initialValue = 0;
    const totalSize = selecteds.reduce(
      (accumulator, allFiles) => accumulator + allFiles.tamanhoEmBytes,
      initialValue
    );

    return totalSize;
  };

  const verifyDownloadData = (urls: string[]) => {
    const verifySize = verifyDownloadSelectedSize();
    if (urls.length > 10 || verifySize > 100000) {
      onOpen();
    } else {
      HandleForceDownload(urls);
    }
  };

  const HandleForceDownload = async (urls: string[]) => {
    await Promise.all(
      urls.map(async (url: string) => {
        await forceDownloadByUrl(url);
      })
    );
    onClose();
  };
  return (
    <>
      {isOpenModalNewFolder ? (
        <ModalNewFolder
          isOpen={isOpenModalNewFolder}
          onClose={onCloseModalNewFolder}
          onOpen={onOpenModalNewFolder}
          localizacao={localizacao}
        />
      ) : null}

      {isOpenModalNewFile ? (
        <ModalNewFile
          isOpen={isOpenModalNewFile}
          onOpen={onOpenModalNewFile}
          onClose={onCloseModalNewFile}
          localizacao={localizacao}
        />
      ) : null}

      <Menu>
        <MenuButton
          as={Button}
          leftIcon={<FaPlus fontSize={12} />}
          rightIcon={<BiChevronDown />}
          fontSize={16}
          fontWeight="medium"
          _hover={{
            opacity: 0.73,
          }}
          px={"8px"}
          bg={green700}
          rounded={20}
          color={"white"}
          _expanded={{ bg: green700 }}
          disabled={isLoadingDiretorioList}
        >
          Novo
        </MenuButton>
        <MenuList>
          <MenuItem onClick={onOpenModalNewFolder}>
            <Icon as={AiOutlineFolderAdd} fontSize={20} mr="2" />
            Nova pasta
          </MenuItem>
          <MenuItem onClick={onOpenModalNewFile}>
            <Icon as={MdOutlineUploadFile} fontSize={20} mr="2" />
            Upload de arquivo
          </MenuItem>
        </MenuList>
      </Menu>

      <ActionButton
        title="Baixar"
        testID="download-arquivos"
        icon={<HiDownload fontSize={16} />}
        disabled={
          !selecteds.length ||
          isLoadingDiretorioList ||
          isLoadingDownload ||
          selecteds.some((item) => item.tipo === "Folder")
        }
        onClick={async () => {
          verifyDownloadData(downloadData);
        }}
      />

      <ActionButton
        title="Excluir"
        testID="deleta-arquivo"
        icon={<BsTrash fontSize={16} />}
        disabled={
          !selecteds.length || isLoadingDiretorioList || isLoadingDelete
        }
        onClick={(e) => {
          e.stopPropagation();
          const idsDosArquivosSelecionados = selecteds.map(
            (arquivo) => arquivo.id
          );

          showConfirm().then(({ isConfirmed }) => {
            if (isConfirmed) {
              handleDelete(idsDosArquivosSelecionados, {
                onError: () => {
                  showError();
                },
                onSuccess: () => {
                  setUpdating(true);
                  setSelecteds([]);
                  refetchDiretorioList();

                  showSuccess();
                },
              });
            }
          });
        }}
      />
      <Menu>
        {/* <MenuButton
          as={IconButton}
          icon={<MdOutlineMoreVert fontSize={24} color="gray.200" />}
          aria-label="Options"
          ml="1"
          padding={0}
          variant="gost"
          border="none"
          _hover={{ bg: "gray.200" }}
          disabled={!selecteds.length || isLoadingDelete}
        /> */}
        {/* <MenuList>
          <MenuItem>Renomear</MenuItem>
          <MenuItem>Mover</MenuItem> 
          <MenuItem>Histórico</MenuItem>  */}
        {/* <MenuItem
            disabled={!selecteds.length || isLoadingDelete}
            onClick={() => {
              // const idsDosArquivosSelecionados = selecteds.map(
              //   (arquivo) => arquivo.id
              // );
              // showConfirm().then(({ isConfirmed }) => {
                // if (isConfirmed) {
                //   handleDelete(idsDosArquivosSelecionados, {
                //     onError: () => {
                //       showError();
                //     },
                //     onSuccess: () => {
                //       setUpdating(true);
                //       refetchDiretorioList();
                //       showSuccess();
                //       setSelecteds([]);
                //     },
                //   });
                // }
              });
            }}
            color="red.800"
          > */}
        {/* Excluir
          </MenuItem>
        </MenuList> */}
      </Menu>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Você selecionou muitos arquivos ou o tamanho total é grande. Deseja
            realizar o download?
          </ModalHeader>
          <ModalCloseButton />

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              variant="ghost"
              onClick={() => HandleForceDownload(downloadData)}
            >
              Continuar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
