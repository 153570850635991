import { useMemo } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useUsuarios } from "core/features/users/hooks/useUsuarios";
import { usersModalColumsName } from "../utils";

import ActionsButtonsUsersModal from "./ActionsButtonsUsersModal";
import { FaUser } from "react-icons/fa";
import { useUnidades } from "core/features/maps/hooks/useUnidades";
import { UsuarioPerfilProps } from "core/features/users/typings";
import DatatableAntd from "components/datatable-antd";

type UserSelected = UsuarioPerfilProps | undefined;

interface UsersTableProps {
  userSelected: UserSelected;
  setUserSelected: (user: UserSelected) => void;
  handleEditUser: () => void;
  handleNewUser: () => void;
  search: string;
  handleChangeSearch: (letter: string) => void;
}

const UsersTable = ({
  userSelected,
  setUserSelected,
  handleEditUser,
  handleNewUser,
  search,
  handleChangeSearch,
}: UsersTableProps) => {
  const { data: fazendasData } = useUnidades();

  const { data, isFetching, fetchNextPage } = useUsuarios(
    search.length >= 3 ? search : ""
  );

  const items = useMemo(() => {
    const pages = data?.pages ?? [];

    const result = pages.reduce(
      (agg: UsuarioPerfilProps[], item) => agg.concat(item.users ?? []),
      []
    );

    result.forEach((item) => {
      (item as any).key = item.usuarioId;
    });

    return result;
  }, [data]);

  const nextPage = () => {
    const lastPage = data?.pages[data.pages.length - 1];

    if (!lastPage || !data || !data.pages.length) {
      return;
    }

    const totalPages =
      lastPage.paginate.totalCount / data.pages[0].paginate.resultsPerPage;

    const hasNextPage = totalPages > data.pages.length;

    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  };

  return (
    <Box height="100%" width="100%">
      <Flex
        borderBottom={"2px solid"}
        borderBottomColor="gray.200"
        paddingBottom={"10px"}
        marginBottom={"15px"}
        alignItems="center"
      >
        <FaUser size="20px" color="#009540" />
        <Text fontWeight={"medium"} fontSize={"1.3rem"} marginLeft="15px">
          Controle de Usuários
        </Text>
      </Flex>
      <ActionsButtonsUsersModal
        hasSelection={userSelected !== undefined}
        handleEdit={handleEditUser}
        handleNewUser={handleNewUser}
        searchValue={search}
        handleChangeSearch={(letters) => handleChangeSearch(letters)}
        resetInput={() => handleChangeSearch("")}
      />

      <Box borderBottom="1px solid" borderBottomColor="gray.700" padding="-1px">
        <DatatableAntd
          columns={usersModalColumsName(fazendasData ?? [])}
          loading={isFetching}
          dataSource={items}
          scroll={{ y: "calc(100vh - 260px)" }}
          onLoad={() => {
            nextPage();
          }}
          onRow={(user) => ({
            className: userSelected === user ? "row-selected" : "",
            style: {
              cursor: "pointer",
            },
            onClick: () =>
              userSelected === user
                ? setUserSelected(undefined)
                : setUserSelected(user),
          })}
        />
      </Box>
    </Box>
  );
};
export default UsersTable;
