import { Flex } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { Image } from "@chakra-ui/image";

import { gray700, gray800, green700 } from "core/resources/theme/colors";

import milhoGray from "assets/images/icons/ocupacoes/milho-gray.svg";
import milhoWhite from "assets/images/icons/ocupacoes/milho-white.svg";
import sojaGray from "assets/images/icons/ocupacoes/soja-gray.svg";
import sojaWhite from "assets/images/icons/ocupacoes/soja-white.svg";
import algodaoGray from "assets/images/icons/ocupacoes/algodao-gray.svg";
import algodaoWhite from "assets/images/icons/ocupacoes/algodao-white.svg";
import {
  ConcentracaoColheitaOcupacao,
  NumeroDeAplicacoesOcupacoes,
  OperacoesExecutadasOcupacoes,
} from "features/maps/typings";
import { OcupacaoEnum } from "features/maps/enums";
import { OcupacaoETecnologiasReturnProps } from "core/features/talhoes/typings/ocupacaoETenologias";

interface OcupacoesButtonsProps {
  items:
    | OperacoesExecutadasOcupacoes[]
    | NumeroDeAplicacoesOcupacoes[]
    | ConcentracaoColheitaOcupacao[]
    | OcupacaoETecnologiasReturnProps["ocupacoes"];
  onChange: (value: OcupacaoEnum) => void;
  value: OcupacaoEnum;
}

const OcupacoesButtons = ({
  items,
  value,
  onChange,
}: OcupacoesButtonsProps) => {
  const isEnabled = (codigo: number) => {
    const ocupacao = items?.find((item) => item.codigo === codigo);
    return ocupacao?.enabled;
  };

  return (
    <Flex
      flex={1}
      ml={2}
      alignItems="center"
      justifyContent="flex-start"
      flexDir="row"
    >
      <Button
        borderTop={`0.5px solid ${gray700}`}
        borderBottom={`0.5px solid ${gray700}`}
        borderLeft={`0.5px solid ${gray700}`}
        w="130px"
        disabled={!isEnabled(OcupacaoEnum.SOJA)}
        _hover={{
          backgroundColor: "none",
        }}
        h="45px"
        roundedRight={0}
        bg={
          value === OcupacaoEnum.SOJA && isEnabled(OcupacaoEnum.SOJA)
            ? green700
            : "white"
        }
        roundedLeft={40}
        px={6}
        justifyContent="space-around"
        onClick={() => {
          if (value !== OcupacaoEnum.SOJA) {
            onChange(OcupacaoEnum.SOJA);
          }
        }}
        color={
          value === OcupacaoEnum.SOJA && isEnabled(OcupacaoEnum.SOJA)
            ? "white"
            : gray800
        }
      >
        <Image
          src={
            value === OcupacaoEnum.SOJA && isEnabled(OcupacaoEnum.SOJA)
              ? sojaWhite
              : sojaGray
          }
        />
        Soja
      </Button>
      <Button
        _hover={{
          backgroundColor: "none",
        }}
        border={`0.5px solid ${gray700}`}
        w="130px"
        disabled={!isEnabled(OcupacaoEnum.MILHO)}
        rounded={0}
        h="45px"
        justifyContent="space-around"
        px={6}
        color={value === OcupacaoEnum.MILHO ? "white" : gray800}
        bg={value === OcupacaoEnum.MILHO ? green700 : "white"}
        onClick={() => {
          if (value !== OcupacaoEnum.MILHO) {
            onChange(OcupacaoEnum.MILHO);
          }
        }}
      >
        <Image src={value === OcupacaoEnum.MILHO ? milhoWhite : milhoGray} />
        Milho
      </Button>
      <Button
        _hover={{
          backgroundColor: "none",
        }}
        borderTop={`0.5px solid ${gray700}`}
        borderBottom={`0.5px solid ${gray700}`}
        borderRight={`0.5px solid ${gray700}`}
        disabled={!isEnabled(OcupacaoEnum.ALGODAO)}
        color={value === OcupacaoEnum.ALGODAO ? "white" : gray800}
        w="130px"
        justifyContent="space-around"
        px={2}
        h="45px"
        roundedRight={40}
        bg={value === OcupacaoEnum.ALGODAO ? green700 : "white"}
        roundedLeft={0}
        onClick={() => {
          if (value !== OcupacaoEnum.ALGODAO) {
            onChange(OcupacaoEnum.ALGODAO);
          }
        }}
      >
        <Image
          src={value === OcupacaoEnum.ALGODAO ? algodaoWhite : algodaoGray}
        />
        Algodão
      </Button>
    </Flex>
  );
};

export default OcupacoesButtons;
