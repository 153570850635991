import ContainerFilter from "../../ContainerFilter";
import { useFiltersLists } from "core/features/filters/store/lists";
import { useFilters } from "core/features/filters/store";

import OcupacoesButtons from "../../OcupacoesButtons";
import SelectedButtons from "../../SelectedButtons";
import { Flex, IconButton } from "@chakra-ui/react";
import {
  Ocupacoes,
  OperacoesExecutadasOperacoes,
  TecnologiasItem,
} from "core/features/maps/typings";
import CheckboxFiltro from "components/inputs/checkbox-filtro";
import { ButtonClearMapCheckbox } from "../../ButtonLimpar";
import { capitalizeFirstLetter } from "ui/utils";
import { useEffect } from "react";
import { OcupacaoEnum } from "core/features/maps/enums";
import LoadingBox from "components/layout/loading-box";
import NotFoundMapFilters from "components/NotFoundMapFilters";
import useAdvancedFiltersOperacoesExecutadas from "components/maps/base-map/components/advancedFilters/items/hooks/useAdvancedFiltersOperacoesExecutadas";

import { FaChevronUp } from "react-icons/fa";
import MapGridButton from "components/MapGridButton";

interface FiltersOperacoesExecutadasProps {
  isVisible: boolean;
  handleChangeIsVisible: (prop: boolean) => void;
  handleOpenGrid: () => void;
}

function FiltersOperacoesExecutadas({
  isVisible,
  handleChangeIsVisible,
  handleOpenGrid,
}: FiltersOperacoesExecutadasProps) {
  const { filters, updateFiltroOperacoesExecutadas } = useFilters();
  const { operacoesExecutadas, loadings } = useFiltersLists();
  const { clearFilterStates: clearAdvancedFiltersState } =
    useAdvancedFiltersOperacoesExecutadas();

  const { filtroOperacoesExecutadas } = filters;

  const findTecnologiasByOcupacaoSelected = operacoesExecutadas?.ocupacoes.find(
    (item) => item.codigo === filtroOperacoesExecutadas?.ocupacao
  );

  const findOperacoes = operacoesExecutadas?.operacoes.filter(
    (item) => item.ocupacao === filtroOperacoesExecutadas?.ocupacao
  );

  const findOperacoesWithTecnologiaSelected = findOperacoes?.filter((item) =>
    filtroOperacoesExecutadas?.tecnologias.includes(item.tecnologiaId)
  );

  const operacoes =
    findOperacoesWithTecnologiaSelected &&
    findOperacoesWithTecnologiaSelected?.length > 0
      ? findOperacoesWithTecnologiaSelected
      : findOperacoes;

  const removedDuplicateOperacoes = operacoes?.filter(
    (v, i, a) => a.findIndex((t) => t.descricao === v.descricao) === i
  );

  const isCheckedTodos =
    !!filtroOperacoesExecutadas?.operacoes?.length &&
    filtroOperacoesExecutadas?.operacoes.length ===
      removedDuplicateOperacoes?.length;

  const onClickOperacao = (operacao: OperacoesExecutadasOperacoes) => {
    const formattedOperacao = {
      operacao: operacao.operacaoId,
      color: operacao.cor,
      tecnologiaId: operacao.tecnologiaId,
    };

    if (
      filters.filtroOperacoesExecutadas?.operacoes?.find(
        (op) => operacao.operacaoId === op.operacao
      )
    ) {
      const filteredOperacoes =
        filters.filtroOperacoesExecutadas.operacoes.filter(
          (op) => op.operacao !== operacao.operacaoId
        );
      updateFiltroOperacoesExecutadas(
        {
          tecnologias: filters.filtroOperacoesExecutadas?.tecnologias,
          ocupacao: filters.filtroOperacoesExecutadas?.ocupacao,
          operacoes: filteredOperacoes,
        },
        true
      );
    } else {
      updateFiltroOperacoesExecutadas(
        {
          tecnologias: filters.filtroOperacoesExecutadas?.tecnologias,
          ocupacao: filters.filtroOperacoesExecutadas?.ocupacao,
          operacoes: [
            ...(filters.filtroOperacoesExecutadas?.operacoes as any),
            formattedOperacao,
          ],
        },
        true
      );
    }
  };

  const onClearOperacoes = () => {
    updateFiltroOperacoesExecutadas({
      tecnologias: filters.filtroOperacoesExecutadas?.tecnologias,
      ocupacao: filters.filtroOperacoesExecutadas?.ocupacao,
      operacoes: [],
      periodoOperacao: {},
      equipeIds: [],
      insumos: [],
    });
  };

  const formattedOperacoesExecutadasFilters = removedDuplicateOperacoes?.map(
    (item) => {
      return {
        color: item.cor,
        operacao: item.operacaoId,
        tecnologiaId: item.tecnologiaId,
      };
    }
  );

  const selectOcupacao = (ocupacaoId: OcupacaoEnum) => {
    const findOcupacao = operacoesExecutadas?.ocupacoes.find(
      (item) => item.codigo === ocupacaoId
    );
    const findFirstTecnologiaEnabled = findOcupacao?.tecnologias.find(
      (item) => item.id
    );
    updateFiltroOperacoesExecutadas({
      ocupacao: findOcupacao?.codigo,
      tecnologias: findFirstTecnologiaEnabled?.id
        ? [findFirstTecnologiaEnabled?.id]
        : [],
      operacoes: [],
    });
  };

  useEffect(() => {
    if (!filtroOperacoesExecutadas?.tecnologias.length) {
      const ocp = operacoesExecutadas?.ocupacoes || [];
      const isOcupacaoEnabled = ocp.find(
        (item) => item.codigo === filters.filtroOperacoesExecutadas?.ocupacao
      );

      if (!filtroOperacoesExecutadas?.ocupacao || !isOcupacaoEnabled?.enabled) {
        const findFirstOcupacaoEnabled = ocp.find((item) => item.enabled);
        const findFirstTecnologiaEnabled =
          findFirstOcupacaoEnabled?.tecnologias.find((item) => item.id);
        updateFiltroOperacoesExecutadas({
          ocupacao: findFirstOcupacaoEnabled?.codigo,
          tecnologias: findFirstTecnologiaEnabled?.id
            ? [findFirstTecnologiaEnabled?.id]
            : [],
          operacoes: [],
        });
      }
    }
  }, [
    filters.filtroOperacoesExecutadas?.ocupacao,
    filtroOperacoesExecutadas?.ocupacao,
    filtroOperacoesExecutadas?.tecnologias.length,
    operacoesExecutadas?.ocupacoes,
    updateFiltroOperacoesExecutadas,
  ]);

  const updateTecnologiasSelected = (tecnologiaId?: string) => {
    if (filters.filtroOperacoesExecutadas?.tecnologias.length) {
      const findElementIndex =
        filters.filtroOperacoesExecutadas.tecnologias.findIndex(
          (item) => item === tecnologiaId
        );
      const newArray = [...filters.filtroOperacoesExecutadas.tecnologias];
      if (findElementIndex > -1) {
        if (filters.filtroOperacoesExecutadas?.tecnologias.length === 1) {
          return;
        }
        newArray.splice(findElementIndex, 1);
      } else {
        newArray.push(tecnologiaId as string);
      }
      const foundCompativeOperacoes =
        filters.filtroOperacoesExecutadas.operacoes.filter((item) =>
          newArray.includes(item.tecnologiaId as string)
        );

      const checkTodos = findTecnologiasByOcupacaoSelected?.tecnologias
        .filter((item) => item.id !== null)
        .map((i) => {
          return i.id;
        });

      if (!tecnologiaId) {
        updateFiltroOperacoesExecutadas({
          operacoes: foundCompativeOperacoes,
          ocupacao: filters.filtroOperacoesExecutadas?.ocupacao,
          tecnologias: checkTodos,
        });
      } else {
        updateFiltroOperacoesExecutadas({
          operacoes: foundCompativeOperacoes,
          ocupacao: filters.filtroOperacoesExecutadas?.ocupacao,
          tecnologias: newArray,
        });
      }
    }
  };

  useEffect(() => {
    const isEmptyTecnologiaFirstLoading =
      !filtroOperacoesExecutadas?.tecnologias.length;
    const findOcupacao =
      operacoesExecutadas?.ocupacoes.find(
        (item) => item.codigo === filtroOperacoesExecutadas?.ocupacao
      ) ?? operacoesExecutadas?.ocupacoes.find((item) => item.enabled);

    if (isEmptyTecnologiaFirstLoading) {
      const findFirstTecnologiaEnabled =
        findOcupacao?.tecnologias &&
        findOcupacao?.tecnologias.find((item) => item.id);
      if (
        !filtroOperacoesExecutadas?.tecnologias.includes(
          findFirstTecnologiaEnabled?.id as string
        )
      ) {
        updateFiltroOperacoesExecutadas({
          operacoes: [],
          ocupacao: findOcupacao?.codigo,
          tecnologias: findFirstTecnologiaEnabled?.id
            ? [findFirstTecnologiaEnabled.id]
            : [],
        });
      }
    }
  }, [
    filters.unidade,
    filters.safra,
    operacoesExecutadas?.ocupacoes,
    updateFiltroOperacoesExecutadas,
    filtroOperacoesExecutadas?.tecnologias,
    filtroOperacoesExecutadas?.ocupacao,
  ]);

  const onClickTodos = () => {
    if (isCheckedTodos) {
      updateFiltroOperacoesExecutadas({
        tecnologias: filters.filtroOperacoesExecutadas?.tecnologias,
        ocupacao: filters.filtroOperacoesExecutadas?.ocupacao,
        operacoes: [],
        periodoOperacao: {},
        insumos: [],
        equipeIds: [],
      });
      setTimeout(() => {
        clearAdvancedFiltersState();
      }, 2000);
    } else {
      updateFiltroOperacoesExecutadas(
        {
          tecnologias: filters.filtroOperacoesExecutadas?.tecnologias,
          ocupacao: filters.filtroOperacoesExecutadas?.ocupacao,
          operacoes: formattedOperacoesExecutadasFilters,
        },
        true
      );
    }
  };

  if (loadings.operacoesExecutadas) {
    return <LoadingBox isVisible={isVisible} />;
  }

  if (!operacoesExecutadas && !loadings.operacoesExecutadas) {
    return (
      <ContainerFilter
        containerProps={{
          mt: 3,
          rounded: 8,
          padding: 4,
        }}
      >
        <Flex pl={1} mb={1}>
          <NotFoundMapFilters message="Não foram encontadras ocupações para o filtro selecionado" />
        </Flex>
        <Flex
          padding="5px"
          flexDirection="row-reverse"
          borderTop="1px solid #84848440 "
          marginTop="10px"
        >
          <IconButton
            background="none"
            _hover={{}}
            _focus={{}}
            _active={{}}
            icon={<FaChevronUp color="#848484" />}
            aria-label="close-button"
            marginRight="20px"
            onClick={() => handleChangeIsVisible(false)}
          />
        </Flex>
      </ContainerFilter>
    );
  }

  return (
    <ContainerFilter
      containerProps={{
        mt: 3,
        rounded: 8,
        padding: 4,
      }}
      isVisible={isVisible}
    >
      <OcupacoesButtons
        onChange={selectOcupacao}
        value={filters.filtroOperacoesExecutadas?.ocupacao!}
        items={operacoesExecutadas?.ocupacoes as Ocupacoes[]}
      />

      {!loadings.operacoesExecutadas ? (
        <>
          <SelectedButtons
            value={filters.filtroOperacoesExecutadas?.tecnologias ?? []}
            items={
              findTecnologiasByOcupacaoSelected?.tecnologias as TecnologiasItem[]
            }
            onChange={updateTecnologiasSelected}
          />
          <Flex h="1px" bg="gray.200" mt={4} flex={1} />

          <Flex mt={4} flexDir="column" ml={2}>
            {removedDuplicateOperacoes && !removedDuplicateOperacoes.length ? (
              <Flex>
                <NotFoundMapFilters message="Não foram encontradas operações para o filtro selecionado" />
              </Flex>
            ) : null}
            <Flex>
              {removedDuplicateOperacoes && removedDuplicateOperacoes.length ? (
                <>
                  <CheckboxFiltro
                    disabled={false}
                    onClick={onClickTodos}
                    isChecked={isCheckedTodos}
                    text={"Todas"}
                    data-testid="menu-filtros-operacoes-executadas-todos"
                  />

                  <ButtonClearMapCheckbox onClear={onClearOperacoes} />
                </>
              ) : null}
            </Flex>

            {removedDuplicateOperacoes &&
              removedDuplicateOperacoes.length > 0 &&
              removedDuplicateOperacoes.map((operacao, index) => (
                <CheckboxFiltro
                  key={operacao.operacaoId + index}
                  text={capitalizeFirstLetter(operacao.descricao)}
                  onClick={() => onClickOperacao(operacao)}
                  color={operacao.cor}
                  data-testid={`menu-filtro-operacoes-executadas-${operacao.descricao}`}
                  isChecked={
                    !!filters.filtroOperacoesExecutadas?.operacoes?.find(
                      (op) => op.operacao === operacao.operacaoId
                    )
                  }
                />
              ))}
          </Flex>
        </>
      ) : null}

      <Flex
        padding="5px"
        justifyContent="space-between"
        borderTop="1px solid #84848440 "
        alignItems="center"
        paddingTop="15px"
        marginTop="10px"
      >
        <MapGridButton handleOpenGrid={handleOpenGrid} />

        <IconButton
          background="none"
          _hover={{}}
          _focus={{}}
          _active={{}}
          icon={<FaChevronUp color="#848484" />}
          aria-label="close-button"
          marginRight="20px"
          onClick={() => handleChangeIsVisible(false)}
        />
      </Flex>
    </ContainerFilter>
  );
}

export default FiltersOperacoesExecutadas;
