import { Box, Flex, Skeleton, Text } from "@chakra-ui/react";
import useGraficoPluviometrico from "core/features/maps/hooks/useGraficoPluviometrico";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { useState } from "react";
import ActionsButtonGraficoPluviometrico from "./components/ActionsButtonsGraficoPluviometrico";

export enum FilterByEnum {
  Days = 1,
  Weeks = 2,
  Months = 3,
}

interface GraficoPluviometricoProps {
  talhaoId: string;
  defaultFilter: number;
}

const GraficoPluviometrico = ({
  talhaoId,
  defaultFilter,
}: GraficoPluviometricoProps) => {
  const [graphicFilterBy, setGraphicFilterBy] =
    useState<FilterByEnum>(defaultFilter);

  const { data: graphicData, isLoading } = useGraficoPluviometrico(
    talhaoId,
    graphicFilterBy
  );

  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
  );

  const data = {
    labels: graphicData?.data?.labels,
    datasets: graphicData?.data?.datasets,
  };

  if (isLoading || !graphicData || !graphicData.data)
    return (
      <Box height="300px" marginBottom="20px">
        <Skeleton width="100%" height="300px">
          <Skeleton height="20px" width="20px" borderRadius="5px" />
        </Skeleton>
      </Box>
    );

  return (
    <Box borderRadius="25px" marginBottom="20px">
      <Flex justifyContent="space-between" margin="20px 40px 12px">
        <Text fontSize="1.2rem" fontWeight="bold">
          Índice Pluviométrico
        </Text>
        <ActionsButtonGraficoPluviometrico
          actualFilter={graphicFilterBy}
          handleChange={setGraphicFilterBy}
        />
      </Flex>
      <Box>
        {data && (
          <Chart
            type="bar"
            style={{
              maxHeight: "300px",
            }}
            options={{
              ...graphicData?.options,

              layout: {
                autoPadding: true,
              },
              elements: {
                radius: 20,
                bar: {
                  borderRadius: 5,
                },
              },
              plugins: {
                legend: {
                  position: "bottom",
                  reverse: true,

                  labels: {
                    boxWidth: 10,
                    boxHeight: 10,
                    boxPadding: 1000,
                    padding: 20,
                  },
                },
                tooltip: {
                  axis: "xy",
                  titleAlign: "center",
                  bodyAlign: "center",
                  animation: {
                    delay: 0,
                  },
                  callbacks: {
                    label: (props) => {
                      const raw = props.raw as any;
                      const dae = raw.value.dae ? `DAE: ${raw.value.dae}` : "";
                      const description = `${raw.value.descricao}`;

                      const isPrecipitacao =
                        props.dataset.label === "Precipitação";

                      const isLabelDifferentOfDescricao =
                        raw.value.descricao !== props.dataset.label &&
                        raw.value.descricao !== "Emergência";

                      const showPrecipitacaoSplitedText =
                        raw.value.descricao &&
                        isLabelDifferentOfDescricao &&
                        isPrecipitacao;

                      const descPrecipitacaoSplitted = description.split("m ");

                      return [
                        `${props.dataset.label} `,
                        showPrecipitacaoSplitedText
                          ? `${descPrecipitacaoSplitted[0]}m`
                          : raw.value.descricao && isLabelDifferentOfDescricao
                          ? `${description}`
                          : "",
                        showPrecipitacaoSplitedText
                          ? descPrecipitacaoSplitted[1]
                          : "",
                        `${dae}`,
                      ].filter((item) => item !== "");
                    },
                  },
                },
              },
            }}
            data={data}
          />
        )}
      </Box>
    </Box>
  );
};

export default GraficoPluviometrico;
