import { Safra } from "core/features/maps/typings";
import SafraItem from "./SafraItem";
import { useFilters } from "core/features/filters/store";
import ContainerSafraFilter from "../../ContainerSafraFilter";
import { SimpleGrid } from "@chakra-ui/react";

function FiltersSafra({
  onClick,
  data,
}: {
  onClick: (item: Safra) => void;
  data: Safra[];
}) {
  const { filters } = useFilters();

  return (
    <ContainerSafraFilter title="Escolha uma safra">
      <SimpleGrid w="100%" columns={{ base: 4 }}>
        {data.map((item) => (
          <SafraItem
            key={item.id}
            value={filters.safra}
            onClick={() => onClick(item)}
            item={item}
          />
        ))}
      </SimpleGrid>
    </ContainerSafraFilter>
  );
}

export default FiltersSafra;
