import { useAuth } from "core/features/auth/store";
import { showError } from "infra/helpers/alerta";
import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import AppPages from "./app";
import TermOfUsePage from "./auth/term-of-use";

export default function Pages() {
  const { user } = useAuth();

  useEffect(() => {
    if (
      user?.perfis?.length === 1 &&
      user.perfis.includes("Indicadores Financeiros (AgroDASH)")
    ) {
      showError(
        "Solicite acesso ao seu administrador.",
        "Sem acesso ao sistema."
      );

      localStorage.clear();

      setTimeout(() => {
        window.location.reload();
      }, 3500);
    }
  }, [user]);

  return (
    <Switch>
      <Route exact path="/auth/term-of-use">
        <TermOfUsePage />
      </Route>

      <Route path="/">
        <AppPages />
      </Route>
    </Switch>
  );
}
