import { useToast } from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { usePerfis } from "core/features/maps/hooks/usePerfis";
import { useUnidades } from "core/features/maps/hooks/useUnidades";

import * as urls from "core/resources/urls";
import http from "infra/http";

import { useEffect, useState } from "react";
import * as yup from "yup";
import { EditUserProps } from "..";

export const useEditUser = ({ handleGoBack, user }: EditUserProps) => {
  const { data, isFetching } = usePerfis();
  const { data: fazendasData } = useUnidades();
  const [email, setEmail] = useState<string>(user?.email || "");
  const [nome, setNome] = useState<string | undefined>(user?.nome || undefined);
  const [celular, setCelular] = useState<string | undefined>(user?.celular?.substring(3) || undefined);
  const [perfis, setPerfis] = useState<string[]>(user?.perfis || []);
  const [fazendas, setFazendas] = useState<string[]>(user?.fazendas || []);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ativo, setAtivo] = useState<boolean | undefined>(
    user ? user.ativo : true
  );
  const [searchFazenda, setSearchFazenda] = useState<string>("");
  const [photo, setPhoto] = useState<Blob | File | string | undefined>(
    user?.avatarUrl
  );
  const [photoPreview, setPhotoPreview] = useState<string | undefined>(
    user?.avatarUrl
  );

  const [searchNotificacaoFazenda, setSearchNotificacaoFazenda] = useState<string>("");
  const [notificacoesFazendas, setNotificacoesFazendas] = useState<string[]>(user?.notificacaoFazendas || []);
  const [receberAlertaAcumuladoChuva, setReceberAlertaAcumuladoChuva] = useState<boolean | undefined>(user?.receberAlertaAcumuladoChuva);
  const [receberAlertaAcumuladoChuvaTodasFazendas, setReceberAlertaAcumuladoChuvaTodasFazendas] = useState<boolean | undefined>(user?.receberAlertaAcumuladoChuvaTodasFazendas);
  const [receberAlertaInicioFimChuva, setReceberAlertaInicioFimChuva] = useState<boolean | undefined>(user?.receberAlertaInicioFimChuva);

  const [error, setError] = useState<string>();

  const toast = useToast();

  const isPrecipitacaoChecked = receberAlertaInicioFimChuva || receberAlertaAcumuladoChuva;

  const editUserSchema = yup.object().shape({
    name: yup.string().required("Nome é obrigatório"),
    perfis: yup
      .array()
      .of(yup.string())
      .required("Perfis é obrigatorio")
      .min(1, "Adicione no mínimo uma permissão."),
    fazendas: yup
      .array()
      .of(yup.string())
      .required("Fazendas é obrigatorio")
      .min(
        perfis.includes("Gestor de Usuários") && perfis.length === 1 ? 0 : 1,
        "Adicione no mínimo uma fazenda."
      ),
    notificacaoFazendas: yup.array().of(yup.string()),
    celular: yup.string(),
    receberAlertaAcumuladoChuva: yup.boolean(),
    receberAlertaAcumuladoChuvaTodasFazendas: yup.boolean(),
    receberAlertaInicioFimChuva: yup.boolean(),
  });

  const newUserSchema = yup.object().shape({
    name: yup.string().required("Nome é obrigatório"),
    email: yup
      .string()
      .email("O campo e-mail deve ser válido")
      .required("O campo e-mail é obrigatório"),
    perfis: yup
      .array()
      .of(yup.string())
      .required("Perfis é obrigatorio")
      .min(1, "Adicione no mínimo uma permissão."),
    fazendas: yup
      .array()
      .of(yup.string())
      .required("Fazendas é obrigatorio")
      .min(
        perfis.includes("Gestor de Usuários") ? 0 : 1,
        "Adicione no mínimo uma fazenda."
      ),
    notificacaoFazendas: yup.array().of(yup.string()),
    celular: yup.string(),
    receberAlertaAcumuladoChuva: yup.boolean(),
    receberAlertaAcumuladoChuvaTodasFazendas: yup.boolean(),
    receberAlertaInicioFimChuva: yup.boolean(),
  });

  const showFilteredFazendas = fazendasData && searchFazenda !== "";

  const showFilteredNotificaoFazendas = fazendas && searchNotificacaoFazenda !== "";

  const handleCheckPhone = () => {
    const isPhoneFilled = celular !== undefined && celular !== "";
    return (isPrecipitacaoChecked && isPhoneFilled) || !isPrecipitacaoChecked;
  }

  const handleCheckIfSelectedFazendas = () => {
    const isFazendasSelected = notificacoesFazendas.length >= 1;
    return (isPrecipitacaoChecked && isFazendasSelected) || !isPrecipitacaoChecked;
  }

  const handleCheckPhoneFormat = () => {
    const phoneRegex = /([(][1-9]{2}[)])?[0-9]{4}[-]?[0-9]{4}/;
    const isPhoneCorrect = celular && phoneRegex.test(celular);
    return isPhoneCorrect;
  }

  const handleCheckPrecipitacaoErrors = () => {
    const havePhoneErrors = !handleCheckPhone();
    const haveFazendasErrors = !handleCheckIfSelectedFazendas();
    const isPhoneCorrect = celular && !handleCheckPhoneFormat();

    if (havePhoneErrors) {
      toast({
        description: "Informe o celular!",
        position: "top",
        duration: 3000,
        status: "error",
      });
    }

    if (haveFazendasErrors) {
      toast({
        description: "Selecione no mínimo uma fazenda para notificação!",
        position: "top",
        duration: 3000,
        status: "error",
      });
    }

    if (isPhoneCorrect) {
      toast({
        description: "Celular no formato incorreto!",
        position: "top",
        duration: 3000,
        status: "error",
      });
    }

    return havePhoneErrors || haveFazendasErrors || isPhoneCorrect;
  }

  const handleUnmaskPhone = (phone: string | undefined) => {
    if (phone === undefined) return user?.celular;
    const arr = Array.from(phone);
    const toRemove = ["(", ")", "-", " "];
    const phoneUnmasked = arr.filter((c) => !toRemove.includes(c)).join("");
    return phone !== "" ? `+55${phoneUnmasked}` : "";
  }

  const handleSubmitEditUser = async () => {
    try {
      if (handleCheckPrecipitacaoErrors()) {
        return;
      }

      setIsLoading(true);

      await editUserSchema.validate({
        name: nome,
        fazendas,
        perfis,
        notificacaoFazendas: notificacoesFazendas,
        celular,
        receberAlertaAcumuladoChuva,
        receberAlertaAcumuladoChuvaTodasFazendas,
        receberAlertaInicioFimChuva,
      });

      if (user && fazendasData && data && user.usuarioId) {
        const fazendasIds = fazendasData
          .filter((fazenda) => fazendas.includes(fazenda.nome))
          .map((f) => f.id);
        
        const notificacaoFazendasIds = fazendasData
          .filter((fazenda) => notificacoesFazendas.includes(fazenda.nome))
          .map((f) => f.id);

        const perfisIds = data
          .filter((perfil) => perfis.includes(perfil.nome))
          .map((p) => p.perfilId);

        await http.put(urls.default.USUARIO.ATUALIZAR_USUARIO(user.usuarioId), {
          userId: user.usuarioId,
          userName: nome,
          ativo,
          perfis: perfisIds,
          fazendas: fazendasIds,
          receberAlertaInicioFimChuva,
          receberAlertaAcumuladoChuva,
          receberAlertaAcumuladoChuvaTodasFazendas,
          notificacaoFazendas: notificacaoFazendasIds,
          celular: handleUnmaskPhone(celular),
        } as any);

        if (
          photo &&
          typeof photo === "object" &&
          String(photo) !== String(user.avatarUrl)
        ) {
          const formData = new FormData();
          formData.append("file", photo, "perfil.jpeg");
          await http({
            method: "put",
            url: urls.default.USUARIO.ATUALIAR_FOTO_DO_USUARIO(user.usuarioId),
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          });
        }
        setIsLoading(false);

        toast({
          description: "Usuário editado com sucesso!",
          position: "top",
          duration: 4000,
          status: "success",
        });
        handleGoBack();
      }
    } catch (err: any) {
      setIsLoading(false);

      if (String(err).includes("422")) {
        toast({
          status: "error",
          description: "Não foi possivel alterar a foto do usuário",
          position: "top",
          duration: 3000,
        });
      } else {
        toast({
          status: "error",
          description: err.message,
          position: "top",
          duration: 3000,
        });
      }
    }
  };

  const handleCreateUser = async () => {
    try {
      if (handleCheckPrecipitacaoErrors()) {
        return;
      }

      setIsLoading(true);

      await newUserSchema.validate({
        name: nome,
        email: email?.trim(),
        fazendas,
        perfis,
      });
      setError(undefined);
      if (data && fazendasData) {
        const fazendasIds = fazendasData
          .filter((fazenda) => fazendas.includes(fazenda.nome))
          .map((f) => f.id);

        const notificacaoFazendasIds = fazendasData
          .filter((fazenda) => notificacoesFazendas.includes(fazenda.nome))
          .map((f) => f.id);

        const perfisIds = data
          .filter((perfil) => perfis.includes(perfil.nome))
          .map((p) => p.perfilId);

        const { data: userNew } = await http.post<any, AxiosResponse<string>>(
          urls.default.USUARIO.NOVO_USUARIO,
          {
            email: email.trim(),
            userName: nome,
            celular: handleUnmaskPhone(celular),
            receberAlertaAcumuladoChuva,
            receberAlertaAcumuladoChuvaTodasFazendas,
            receberAlertaInicioFimChuva,
            ativo,
            perfis: perfisIds,
            fazendas: fazendasIds,
            notificacaoFazendas: notificacaoFazendasIds,
          }
        );

        if (photo && typeof photo === "object") {
          const formData = new FormData();
          formData.append("file", photo, "perfil.jpeg");
          await http({
            method: "put",
            url: urls.default.USUARIO.ATUALIAR_FOTO_DO_USUARIO(userNew),
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          });
        }
        setIsLoading(false);
        toast({
          description: "Usuário cadastrado com sucesso!",
          position: "top",
          duration: 4000,
          status: "success",
        });
        handleGoBack();
      }
    } catch (err: any) {
      const message = String(err.message).toLowerCase();
      setIsLoading(false);

      if (String(err).includes("422")) {
        return toast({
          description: "Não foi possível cadastrar a foto do usuário.",
          status: "error",
          duration: 4000,
          position: "top",
        });
      }

      if (message.includes("permissão") || message.includes("fazenda")) {
        return toast({
          description: message,
          status: "error",
          duration: 4000,
          position: "top",
        });
      }

      if (message.includes("nome") || message.includes("e-mail") || message.includes("celular")) {
        setError(err.message);
      }
      
      toast({
        description: "Não foi possível cadastrar o usuário.",
        status: "error",
        duration: 4000,
        position: "top",
      });
    }
  };

  const handleCheckPermissao = (perfilNome: string) => {
    if (perfis.includes(perfilNome)) {
      setPerfis(perfis.filter((nome) => nome !== perfilNome));
    } else {
      setPerfis([...perfis, perfilNome]);
    }
  };

  const handleCheckFazenda = (fazendaNome: string) => {
    if (fazendas.includes(fazendaNome)) {
      setFazendas(fazendas.filter((nome) => nome !== fazendaNome));
    } else {
      setFazendas([...fazendas, fazendaNome]);
    }
  };

  const handleCheckTodasFazendas = () => {
    if (fazendasData) {
      if (fazendas.length !== fazendasData?.length) {
        setFazendas(fazendasData.map((fazenda) => fazenda.nome));
      } else {
        setFazendas([]);
      }
    }
  };

  const handleCheckNotificacaoFazenda = (fazendaNome: string) => {
    if (notificacoesFazendas.includes(fazendaNome)) {
      setNotificacoesFazendas(notificacoesFazendas.filter((nome) => nome !== fazendaNome));
    } else {
      setNotificacoesFazendas([...notificacoesFazendas, fazendaNome]);
    }
  };

  const handleCheckTodasNotificacaoFazendas = () => {
    if (fazendasData) {
      if (notificacoesFazendas.length !== fazendasData?.length) {
        setNotificacoesFazendas(fazendasData.map((fazenda) => fazenda.nome));
      } else {
        setNotificacoesFazendas([]);
      }
    }
  };

  const handleSelectPhoto = (props: any) => {
    if (props && props.target && props.target.files) {
      setPhoto(props.target.files[0]);
      setPhotoPreview(URL.createObjectURL(props.target.files[0]));
    }
  };

  useEffect(() => {
    if (email.includes("@") && nome === undefined) {
      const nameByEmail = email
        .split("@")[0]
        .split(".")
        .map((name) => {
          const firstLetter = name.slice(0, 1).toUpperCase();
          const restOfTheName = name.slice(1, name.length);

          return `${firstLetter}${restOfTheName}`;
        });

      setNome(`${nameByEmail[0]} ${nameByEmail[1] ? nameByEmail[1] : ""}`);
    }
  }, [email, setNome, nome]);

  useEffect(() => {
    if (perfis.includes("Administrador") && fazendasData) {
      setFazendas(fazendasData.map((fazenda) => fazenda.nome));
    }

    if (
      perfis.includes("Gestor de Usuários") &&
      perfis.length === 1 &&
      fazendasData
    ) {
      setFazendas([]);
    }
  }, [perfis, setFazendas, fazendasData]);

  useEffect(() => {
    if (user && celular === undefined && celular !== "") {
      setCelular(user?.celular?.substring(3));
    }
  }, [celular]);

  useEffect(() => {
    if (!receberAlertaInicioFimChuva && !receberAlertaAcumuladoChuva) {
      setNotificacoesFazendas([]);
    }
  }, [receberAlertaInicioFimChuva, receberAlertaAcumuladoChuva]);

  return {
    items: {
      photoPreview,
      ativo,
      nome,
      email,
      celular,
      perfis: data,
      perfisSelecionados: perfis,
      searchFazenda,
      searchNotificacaoFazenda,
      fazendas: fazendasData,
      fazendasSelecionadas: fazendas,
      notificacoesFazendas,
      isFetchingPerfis: isFetching,
      receberAlertaAcumuladoChuva,
      receberAlertaAcumuladoChuvaTodasFazendas,
      receberAlertaInicioFimChuva,
      showFilteredFazendas,
      showFilteredNotificaoFazendas,
      isLoading,
    },
    onChange: {
      handleSelectPhoto,
      setAtivo,
      setNome,
      setEmail,
      setCelular,
      handleCheckPermissao,
      setSearchFazenda,
      setSearchNotificacaoFazenda,
      setReceberAlertaAcumuladoChuva,
      setReceberAlertaAcumuladoChuvaTodasFazendas,
      setReceberAlertaInicioFimChuva,
      setNotificacoesFazendas,
      handleCheckTodasFazendas,
      handleCheckFazenda,
      handleSubmitEditUser,
      handleCreateUser,
      handleCheckNotificacaoFazenda,
      handleCheckTodasNotificacaoFazendas,
    },
    errors: {
      error,
    },
  };
};
