import { DateRange } from "@material-ui/pickers";
import { format, isAfter, isValid } from "date-fns";
import { AdvancedFiltersProps } from "../components/distribuicao-varietal";

export interface ErrorProps {
  initialDate?: string;
  finalDate?: string;
}

export const getErrorMessage: (value: DateRange<Date>) => ErrorProps = (
  value: DateRange<Date>
) => {
  let errors: ErrorProps = {};
  const firstDate = value[0];
  const secondDate = value[1];

  const isFirstDateValid =
    (firstDate && isValid(firstDate)) || firstDate === null;
  const isSecondDateValid =
    (secondDate && isValid(secondDate)) || secondDate === null;
  const isFirstDateAfterSecondDate =
    firstDate && secondDate && isAfter(firstDate, secondDate);

  if (!isFirstDateValid) {
    errors = {
      ...errors,
      initialDate: "Data Inválida",
    };
  }

  if (!isSecondDateValid) {
    errors = {
      ...errors,
      finalDate: "Data Inválida",
    };
  }

  if (isFirstDateAfterSecondDate) {
    errors = {
      initialDate: "Data inicial maior que a final",
      finalDate: "Data final maior que a inicial",
    };
  }

  if (isFirstDateValid && !isFirstDateAfterSecondDate) {
    errors = {
      ...errors,
      initialDate: undefined,
    };
  }

  if (isSecondDateValid && !isFirstDateAfterSecondDate) {
    errors = {
      ...errors,
      finalDate: undefined,
    };
  }

  return errors;
};

export const getPeriodoPlantio = (advancedFilters: AdvancedFiltersProps) => {
  const { plantio } = advancedFilters;

  const periodoPlantioToFilter = {
    dataInicial: plantio[0] ? format(plantio?.[0], "yyyy-MM-dd") : undefined,
    dataFinal: plantio[1] ? format(plantio[1], "yyyy-MM-dd") : undefined,
  };

  return periodoPlantioToFilter;
};

export const getPeriodoEmergencia = (advancedFilters: AdvancedFiltersProps) => {
  const { emergencia } = advancedFilters;

  const periodoEmergenciaToFilter = {
    dataInicial: emergencia[0]
      ? format(emergencia[0], "yyyy-MM-dd")
      : undefined,
    dataFinal: emergencia[1] ? format(emergencia[1], "yyyy-MM-dd") : undefined,
  };

  return periodoEmergenciaToFilter;
};

export const getPeriodoColheita = (advancedFilters: AdvancedFiltersProps) => {
  const { colheita } = advancedFilters;

  const periodoColheitaToFilter = {
    dataInicial: colheita[0] ? format(colheita[0], "yyyy-MM-dd") : undefined,
    dataFinal: colheita[1] ? format(colheita[1], "yyyy-MM-dd") : undefined,
  };

  return periodoColheitaToFilter;
};
export const getPeriodoPrevisaoDeColheita = (
  advancedFilters: AdvancedFiltersProps
) => {
  const { previsaoDeColheita } = advancedFilters;

  const periodoPrevisaoDeColheitaToFilter = {
    dataInicial: previsaoDeColheita[0]
      ? format(previsaoDeColheita[0], "yyyy-MM-dd")
      : undefined,
    dataFinal: previsaoDeColheita[1]
      ? format(previsaoDeColheita[1], "yyyy-MM-dd")
      : undefined,
  };

  return periodoPrevisaoDeColheitaToFilter;
};
