import {
  Box,
  Flex,
  RadioProps,
  Skeleton,
  SkeletonCircle,
  Text,
  UseRadioProps,
  useRadio,
} from "@chakra-ui/react";

interface RadioImageProps extends RadioProps {
  url: string;
  children: React.ReactNode;
  bottomElement?: React.ReactNode;
  isLoading?: boolean;
}

export default function RadioImage(props: UseRadioProps & RadioImageProps) {
  const { getCheckboxProps, getInputProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Flex mb={1} flexDir="column">
      <Box as="label" role="image-radio">
        <input {...input} />
        <Box cursor="pointer" {...checkbox}>
          <Skeleton isLoaded={!props.isLoading}>
            <Box
              as="span"
              w="40px"
              h="50px"
              bgImage={`url(${props.url})`}
              bgRepeat="no-repeat"
              bgPos="center"
              bgSize="100%"
              borderWidth={2}
              borderColor="#DEE1E6"
              borderRadius="md"
              display="inline-block"
              overflow="hidden"
              _checked={{ borderColor: "green.500" }}
              {...checkbox}
            />
          </Skeleton>
          <Flex>
            <SkeletonCircle w={4} h={4} mr={1} isLoaded={!props.isLoading}>
              <Box
                w={4}
                h={4}
                mr={2}
                borderWidth={2}
                borderRadius="full"
                position="relative"
                _after={{
                  top: 0,
                  left: 0,
                  content: '""',
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderWidth: 1,
                  borderRadius: "full",
                  backgroundColor: "transparent",
                }}
                _checked={{
                  borderColor: "green.500",
                  _after: { bg: "green.500" },
                }}
                {...checkbox}
              />
            </SkeletonCircle>
            <Skeleton isLoaded={!props.isLoading}>
              <Text
                fontWeight="bold"
                fontSize="xs"
                _checked={{ color: "green.500" }}
                {...checkbox}
              >
                {props.children}
              </Text>
            </Skeleton>
          </Flex>
        </Box>
      </Box>
      {props.bottomElement}
    </Flex>
  );
}
