import ContainerFilter from "../../ContainerFilter";

import { Flex, IconButton } from "@chakra-ui/react";
import LoadingBox from "components/layout/loading-box";
import useProdutividadeFilters from "./hooks/useProdutividadeFilters";
import ProdutividadeOcupacaoFilter from "./components/ProdutividadeOcupacaoFilter";
import ProdutividadeTecnologiasFilter from "./components/ProdutividadeTecnologiasFilter";
import ProdutividadeItems from "./components/ProdutividadeItems";

import { FaChevronUp } from "react-icons/fa";
import MapGridButton from "components/MapGridButton";

interface FiltersProdutividadeProps {
  isVisible: boolean;
  handleChangeIsVisible: (prop: boolean) => void;
  handleOpenGrid: () => void;
}

function FiltersProdutividade({
  handleChangeIsVisible,
  handleOpenGrid,
  isVisible,
}: FiltersProdutividadeProps) {
  const { produtividade, isLoading } = useProdutividadeFilters();

  if (!produtividade || isLoading) {
    return <LoadingBox isVisible={isVisible} />;
  }

  return (
    <ContainerFilter
      containerProps={{
        mt: 3,
        rounded: 8,
        padding: 4,
      }}
      isVisible={isVisible}
    >
      <ProdutividadeOcupacaoFilter />

      <ProdutividadeTecnologiasFilter />

      <Flex h="1px" bg="gray.200" mt={4} flex={1} />

      <ProdutividadeItems />
      <Flex
        padding="5px"
        justifyContent="space-between"
        borderTop="1px solid #84848440 "
        alignItems="center"
        paddingTop="15px"
        marginTop="10px"
      >
        <MapGridButton handleOpenGrid={handleOpenGrid} />

        <IconButton
          background="none"
          _hover={{}}
          _focus={{}}
          _active={{}}
          icon={<FaChevronUp color="#848484" />}
          aria-label="close-button"
          marginRight="20px"
          onClick={() => handleChangeIsVisible(false)}
        />
      </Flex>
    </ContainerFilter>
  );
}

export default FiltersProdutividade;
