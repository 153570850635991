import { Box, Text, Heading, Flex } from "@chakra-ui/layout";
import Layout from "components/layout";
import logoHorizontal from "assets/images/logo-horizontal.svg";
import { Image } from "@chakra-ui/image";
import { Button } from "@chakra-ui/button";
import { useTermOfUse } from "core/features/auth/hooks/useTermOfUse";
import { showError } from "infra/helpers/alerta";
import { useLogout } from "core/features/auth/hooks/useLogout";
import { useHistory } from "react-router";
import { mixpanel } from "infra/packages/react-mixpanel";
import { AppEvents } from "core/resources/events/auth";

function TermOfUsePage() {
  const { isLoading, mutate } = useTermOfUse();
  const { handleLogout } = useLogout();

  const history = useHistory();

  const handleAccept = () => {
    mutate(undefined, {
      onError: () => {
        showError();
      },
      onSuccess: () => {
        mixpanel.track(AppEvents.LoginNovoCadastro);
        history.push("/");
        window.location.reload();
      },
    });
  };
  return (
    <Layout.Container>
      <Box pt="60px" mb="60px">
        <Image src={logoHorizontal} />
      </Box>
      <Box>
        <Heading color="green.400" mb="32px">
          Termo de uso
        </Heading>
        <Flex overflowY="auto" maxH="60vh" flexDir="column">
          <Text mb="16px" color="gray.900" fontSize="16px">
            <strong>1. FINALIDADE DESTES TERMOS DE USO</strong>
            <br />
            <br />
            1.1. Estes termos e condições gerais de uso (“Termo de Uso”),
            juntamente com a Política de Privacidade (“Política de
            Privacidade”), descrevem os direitos e obrigações dos usuários
            (“Você” ou “Titular”) ao utilizarem o AgroSIG.
            <br />
            1.2. Ao utilizar e acessar ao AgroSIG, você automaticamente concorda
            com todas as regras e condições deste Termo de Uso, em cumprir o
            mesmo, todas as leis e regulamentos aplicáveis, bem como concorda
            que é responsável pelo cumprimento de todas as leis locais
            aplicáveis. RESPONSABILIZANDO-SE INTEGRALMENTE POR TODOS E QUAISQUER
            ATOS PRATICADOS AO UTILIZAR o AgroSIG. CASO VOCÊ NÃO CONCORDE COM
            QUALQUER DOS TERMOS E CONDIÇÕES ABAIXO ESTABELECIDOS, VOCÊ NÃO DEVE
            UTILIZAR O AgroSIG. Os materiais contidos no AgroSIG são protegidos
            pelas leis de direitos autorais e marcas comerciais aplicáveis.
            <br />
            <br />
            <strong>2. USO DE LICENÇA</strong>
            <br />
            <br />
            2.1. É concedida a Você, permissão para baixar temporariamente uma
            cópia dos materiais (informações ou software) no AgroSIG, apenas
            para visualização transitória pessoal e não comercial. Esta é a
            concessão de uma licença limitada, temporária, não exclusiva, não
            transferível e revogável, e não se trata de uma transferência de
            título e, sob esta licença, você não pode:
            <br />
            Modificar ou copiar os materiais; Usar os materiais para qualquer
            finalidade comercial ou para exibição pública (comercial ou não
            comercial); Tentar descompilar ou fazer engenharia reversa de
            qualquer software contido no site AgroSIG; Remover quaisquer
            direitos autorais ou outras notações de propriedade dos materiais;
            ou Transferir os materiais para outra pessoa ou "espelhe" os
            materiais em qualquer outro servidor.
            <br />
            2.2. Esta licença será automaticamente rescindida se você violar
            alguma dessas restrições e poderá ser rescindida pela AgroSIG a
            qualquer momento. Ao encerrar a visualização desses materiais ou
            após o término desta licença, você deve apagar todos os materiais
            baixados em sua posse, seja em formato eletrônico ou impresso.
            <br />
            2.3. Você é o único e exclusivo responsável por acesso e/ou pelo uso
            indevido de seu usuário por terceiros, bem como por qualquer dano
            direto ou indireto que resulte do mau uso ou da inabilidade do uso
            das aplicações, por Você ou por quaisquer terceiros, sendo certo
            que, havendo evidências ou, ainda, meros indícios de uso irregular,
            inadequado, ou suspeito do AgroSIG, você poderá ter seu cadastro
            imediatamente suspenso ou, ainda, cancelado, sem prejuízo das demais
            sanções legais e contratuais.
            <br />
            <br />
            <strong>3. ISENÇÃO DE RESPONSABILIDADE</strong>
            <br />
            <br />
            3.1. Os materiais constantes no AgroSIG são fornecidos 'como estão'.
            AgroSIG não oferece garantias, expressas ou implícitas, e, por este
            meio, isenta e nega todas as outras garantias, incluindo, sem
            limitação, garantias implícitas ou condições de comercialização,
            adequação a um fim específico ou não violação de propriedade
            intelectual ou outra violação de direitos.
            <br />
            3.2. Além disso, o AgroSIG não garante ou faz qualquer representação
            relativa à precisão, aos resultados prováveis ou à confiabilidade do
            uso dos materiais em seu site ou de outra forma relacionado a esses
            materiais ou em sites vinculados a este site.
            <br />
            <br />
            <strong>4. LIMITAÇÕES</strong>
            <br />
            <br />
            4.1. Em nenhum caso o AgroSIG ou seus fornecedores serão
            responsáveis por quaisquer danos (incluindo, sem limitação, danos
            por perda de dados ou lucro ou devido a interrupção dos negócios)
            decorrentes do uso ou da incapacidade de usar os materiais
            constantes no AgroSIG, mesmo que AgroSIG ou um representante
            autorizado da AgroSIG tenha sido notificado oralmente ou por escrito
            da possibilidade de tais danos. Como algumas jurisdições não
            permitem limitações em garantias implícitas, ou limitações de
            responsabilidade por danos consequentes ou incidentais, essas
            limitações podem não se aplicar a Você.
            <br />
            <br />
            <strong>5. PRECISÃO DOS MATERIAIS</strong>
            <br />
            <br />
            5.1. Os materiais exibidos no site da AgroSIG podem incluir erros
            técnicos, tipográficos ou fotográficos, logo a AgroSIG não garante
            que qualquer material armazenado em seu sistema seja preciso,
            completo ou atual.
            <br />
            5.2. A AgroSIG se reserva o direito de realizar ajustes/alterações
            nos materiais a qualquer momento, sem aviso prévio, contudo não se
            compromete a atualizar os materiais.
            <br />
            <br />
            <strong>6. PUBLICAÇÕES SOCIAIS</strong>
            <br />
            <br />
            6.1. As redes sociais do AgroSIG têm por objetivo divulgar as
            atividades laborais, além de promover a democratização das
            informações e devem seguir as seguintes diretrizes:
            <br />
            Os posts e todas as publicações dentro do AgroSIG destinam-se apenas
            para fins informativos, excluindo qualquer utilização comercial ou
            publicitária. Os comentários publicados pelos usuários não
            constituem opinião da AMAGGI e não são por ela endossados, não
            constituindo aconselhamento de qualquer natureza. O conteúdo de cada
            comentário e as opiniões expressas são de única e exclusiva
            responsabilidade civil e penal do usuário. O conteúdo de cada
            comentário e as opiniões expressas são de única e exclusiva
            responsabilidade civil e penal do usuário. A Amaggi tem a
            prerrogativa de, a seu exclusivo critério e independentemente de
            qualquer aviso ou notificação, retirar qualquer comentário do ar,
            mesmo que o comentário não viole as regras estabelecidas neste Termo
            de Uso. Todos os comentários e conteúdos publicados pelos usuários
            nas redes sociais do AgroSIG são públicos e de divulgação imediata,
            todavia serão avaliados pela equipe de moderação/monitoramento do
            AgroSIG. Assim, nos reservamos o direito de excluir, sem prévio
            aviso, todos os conteúdos em não conformidade com este Termo de Uso,
            tais como aqueles conteúdos que possuam caráter difamatório, de
            ameaça a terceiros, assédio e/ou bullying, discurso de ódio (com
            base em raça, etnia, nacionalidade, gênero, deficiência ou qualquer
            doença), nudez, boatos, pornografia ou, ainda, mensagens com intuito
            comercial e/ou lucrativo ou que não tenha relação com o propósito de
            nossas redes sociais. A AMAGGI tem a prerrogativa de, a seu
            exclusivo critério e independentemente de qualquer aviso ou
            notificação, retirar qualquer comentário do ar, mesmo que o
            comentário não viole as regras estabelecidas neste Termo de Uso.
            <br />
            6.2. Garantindo o direito de vetar os comentários que:
            <br />
            Não tratem do assunto objeto do conteúdo em que foi postado;
            Utilizem linguagem chula, grosseira ou ofensiva, palavras cortadas
            por caracteres ou escritos em outra língua que não o português e
            totalmente em maiúscula; Divulguem banners publicitários; Ofereçam
            qualquer tipo de produto para venda; Veiculem material pornográfico,
            persecutório, ameaçador, racista ou discriminatório com relação à
            raça, religião ou nacionalidade; Veiculem informação sobre
            atividades ilegais e incitação ao crime; Veiculem afirmações ou
            material calunioso, injurioso ou difamatório; Veiculem propaganda
            política para candidatos, partidos ou coligações, bem como
            propaganda a respeito de seus órgãos ou representantes; Contenham
            páginas e arquivos criptografados ou protegidos por senhas; Veiculem
            programas e arquivos que contenham vírus ou qualquer outro código
            que ponha em risco arquivos; Veiculem informação relativa à
            pirataria de software; Utilizem-se do serviço, desvirtuando sua
            finalidade, com o intuito de cometer qualquer atividade ilícita;
            Veiculem informações sobre atividades de concursos, pirâmides,
            correntes, lixo eletrônico, spam ou quaisquer mensagens periódicas
            ou não solicitadas (comerciais ou não), ou abusivas; Veiculem
            conteúdo ou material protegido por direitos autorais, sem
            autorização do autor ou de seu representante. Veiculem fotos ou
            vídeos sem autorização dos fotografados.
            <br />
            <br />
            <strong>7. LINKS</strong>
            <br />
            <br />
            7.1. O AgroSIG não analisou todos os sites vinculados ao seu serviço
            e não é responsável pelo conteúdo de nenhum site vinculado. A
            inclusão de qualquer link não implica endosso por AgroSIG do site. O
            uso de qualquer site vinculado é por conta e risco do usuário.
            <br />
            <br />
            <strong>8. MODIFICAÇÕES</strong>
            <br />
            <br />
            8.1. O AgroSIG pode revisar este Termos de Uso a qualquer momento,
            sem aviso prévio e com a continuidade de seu uso e acesso ao
            AgroSIG, automaticamente você concorda em ficar vinculado à versão
            atual deste Termo de Uso.
            <br />
            <br />
            <strong>9. LEI APLICÁVEL</strong>
            <br />
            <br />
            9.1. Este Termos de Uso são regidos e interpretados de acordo com as
            leis da República Federativa do Brasil. e Você se submete
            irrevogavelmente à jurisdição exclusiva dos tribunais do município
            de Cuiabá, Estado de Mato Grosso.
          </Text>
        </Flex>
      </Box>

      <Flex mt="32px" gridGap="16px" justifyContent="center" pb={4}>
        <Button
          onClick={() => {
            handleLogout();
          }}
          isDisabled={isLoading}
          w="300px"
          bg="white"
          shadow="lg"
          data-testid="button-termos-recusar"
        >
          Recusar
        </Button>
        <Button
          isLoading={isLoading}
          isDisabled={isLoading}
          onClick={() => handleAccept()}
          w="300px"
          colorScheme="green"
          shadow="lg"
          data-testid="button-termos-aceitar"
        >
          Li e concordo com os termos
        </Button>
      </Flex>
    </Layout.Container>
  );
}

export default TermOfUsePage;
