import { ImportTalhoesContext } from "core/features/talhoes/contexts/ImportTalhoes.context";
import useSafraPeriodo from "core/features/talhoes/hooks/useSafraPeriodo";
import validarKML from "core/features/talhoes/service/validarKML";
import { useContext, useEffect, useRef, useState } from "react";

interface UseImportKMLFileProps {
  currentSafraId: string;
  currentUnidadeId: string;
}

const useImportKMLFile = ({
  currentSafraId,
  currentUnidadeId,
}: UseImportKMLFileProps) => {
  const { data } = useSafraPeriodo(currentSafraId, currentUnidadeId);

  const inputRef = useRef<HTMLInputElement>(null);
  const {
    safraPeriodo,
    setKMLValidationRet,
    kmlValidationRet,
    setSafraPeriodo,
    file,
    setFile,
  } = useContext(ImportTalhoesContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [safraPeriodoError, setSafraPeriodoError] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        console.log(file, safraPeriodo, !kmlValidationRet);
        if (file && safraPeriodo && !kmlValidationRet) {
          setIsLoading(true);
          const data = await validarKML({
            file,
            unidadeId: currentUnidadeId,
            safraId: currentSafraId,
            safraPeriodoId: safraPeriodo.id,
          });
          setIsLoading(false);

          if (data) {
            setKMLValidationRet(data);
          }
        }
      } catch (err) {
        setFile(undefined);
        setKMLValidationRet(undefined);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, safraPeriodo, currentSafraId, currentUnidadeId]);

  return {
    items: {
      safras: data?.items,
      safraPeriodo,
      file,
      inputRef,
      isLoading,
      safraPeriodoError,
    },
    change: {
      setFile,
      setSafraPeriodo,
      setIsLoading,
      setSafraPeriodoError,
    },
  };
};

export default useImportKMLFile;
