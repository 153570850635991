import URL from "core/resources/urls";
import http from "infra/http";
import { useQuery } from "react-query";
import { TipoMapaTalhoes } from "../typings";

export function useOrdensDeServicosAbertas(
  unidadeId: string,
  tipoMapa: TipoMapaTalhoes
) {
  return useQuery(
    [URL.MAPAS.ORDENS_DE_SERVICOS_ABERTAS(unidadeId), unidadeId],
    async () => {
      const { data } = await http.get(
        URL.MAPAS.ORDENS_DE_SERVICOS_ABERTAS(unidadeId)
      );

      return data;
    },
    {
      enabled:
        !!unidadeId && tipoMapa === TipoMapaTalhoes.OrdensDeServicosAberta,
    }
  );
}
