import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";

import { green700 } from "core/resources/theme/colors";
import NewFolderIcon from "assets/images/icons/agrodrive/newFolderIcon.svg";
import ErrorToastIcon from "assets/images/icons/agrodrive/error-toast.svg";
import { ModalNewFolderContext } from "core/features/agrodrive/contexts/ModalNewFolder.context";
import { AgrodriveFiltersTags } from "../AgrodriveFiltersTags";
import { AgrodriveContext } from "core/features/agrodrive/contexts/Agrodrive.context";
import { createNewFolder } from "core/features/agrodrive/services/createNewFolder";
import { useFiltersLists } from "core/features/filters/store/lists";

export interface ModalNewFolderProps {
  isOpen: boolean;
  localizacao: string;
  onOpen: () => void;
  onClose: () => void;
}

const ModalNewFolder = ({
  isOpen,
  localizacao,
  onClose,
}: ModalNewFolderProps) => {
  const [nomeDaPasta, setNomeDaPasta] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [, setTagsVinculadas] = useState<string[]>([]);
  const [, setSelectedTag] = useState<string>("");

  const {
    onChange: {
      refetchDiretorioList,
      setUpdating,
      setPagina,
      refetchCustomTags,
    },
    items: { fullPath, tagsHerdadas, customTagsData },
  } = useContext(AgrodriveContext);

  const {
    items: {
      currentSafrasVinculadas,
      currentUnidadesVinculada,
      currentSetoresVinculadas,
      tagsPersonalizadasVinculadas,
      currentCulturaVinculada,
      currentTalhoesVinculados,
    },
    onChange: {
      setCurrentCulturaVinculada,
      setCurrentSafrasVinculadas,
      setCurrentSetoresVinculados,
      setCurrentTalhoesVinculados,
      setCurrentUnidadesVinculadas,
      setTagsPersonalizadasVinculadas,
    },
    getTags,
  } = useContext(ModalNewFolderContext);

  useEffect(() => {
    const atLeastOneSelected =
      currentSafrasVinculadas.length ||
      currentUnidadesVinculada.length ||
      currentCulturaVinculada.length ||
      currentSetoresVinculadas.length ||
      currentTalhoesVinculados.length ||
      tagsPersonalizadasVinculadas.length;

    let isNameOnlySpace = true;

    for (let caracter of nomeDaPasta) {
      if (caracter !== " ") {
        isNameOnlySpace = false;
      }
    }

    if (atLeastOneSelected && nomeDaPasta !== "" && !isNameOnlySpace) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    if (nomeDaPasta.length >= 256) {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
    }
  }, [
    currentSafrasVinculadas,
    currentUnidadesVinculada,
    currentCulturaVinculada,
    currentSetoresVinculadas,
    currentTalhoesVinculados,
    tagsPersonalizadasVinculadas,
    nomeDaPasta,
  ]);

  const { safras, unidades } = useFiltersLists();

  //CONTHERE
  useEffect(() => {
    if (tagsHerdadas) {
      // tagsHerdadas
      const defaultUnidades = unidades.filter(
        (item) => item.nome === tagsHerdadas[0]
      );

      setCurrentUnidadesVinculadas(defaultUnidades);
    }

    getTags(tagsHerdadas);
  }, []);

  const toast = useToast();

  const handleCreateFolder = async () => {
    try {
      const newFolder = {
        nome: nomeDaPasta,
        localizacao: fullPath,
        tags: getTags(),
      };

      setPagina(1);
      setUpdating(true);
      await createNewFolder(newFolder);

      toast({
        position: "top-right",
        duration: 6000,
        status: "success",
        isClosable: true,
        render: () => (
          <Flex
            flexDir="row"
            alignItems="center"
            color="#7B7B7B"
            bg="#E0F3E9"
            borderRadius="4"
            w="351px"
            h="73px"
            px="4"
          >
            <Flex bg="green.500" w="5px" />

            <Image src={NewFolderIcon} maxHeight="37px" maxWidth="37px" />
            <Text ml="2">
              Pasta{" "}
              <Text as="span" fontWeight="bold" color="#01945F">
                {`"${nomeDaPasta.charAt(0).toUpperCase()}${nomeDaPasta.slice(
                  1
                )}"`}
              </Text>{" "}
              criada com sucesso{" "}
            </Text>
          </Flex>
        ),
      });

      await refetchDiretorioList();
      await refetchCustomTags();
      //AQUIO
      setUpdating(false);

      return onClose();
    } catch (error: any) {
      if (
        error.response.data.includes(
          "Não é possível criar um diretório sem tags."
        )
      ) {
        return toast({
          position: "top-right",
          duration: 6000,
          title: "Não é possível criar um diretório sem tags.",
          status: "error",
        });
      }

      if (
        error.response.data.includes(
          "Não é possível criar um diretório sem nome."
        )
      ) {
        return toast({
          position: "top-right",
          duration: 6000,
          title: "Não é possível criar um diretório sem nome.",
          status: "error",
        });
      }

      if (
        error.response.data.includes("Já existe uma pasta com este mesmo nome.")
      ) {
        return toast({
          position: "top-right",
          duration: 6000,
          title: "Já existe uma pasta com este mesmo nome.",
          status: "error",
          isClosable: true,
          render: () => (
            <Flex
              flexDir="row"
              alignItems="center"
              color="#7B7B7B"
              bg="#FDE6E6"
              borderRadius="4"
              w="351px"
              h="73px"
              // px="4"
            >
              <Flex
                flexDir={"column"}
                bg="#A42727"
                w="5px"
                height={"100%"}
                left={4}
              />
              <Image
                src={ErrorToastIcon}
                maxHeight="37px"
                maxWidth="37px"
                ml="2"
              />

              <Text ml="2">"Já existe uma pasta com este mesmo nome."</Text>
            </Flex>
          ),
        });
      }
      return toast({
        position: "top-right",
        duration: 2000,
        title: `Falha ao criar pasta`,
        status: "error",
      });
    }
  };

  return (
    <>
      <Modal size="4xl" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex display="flex" flexdir="row" mb="4">
              <Image src={NewFolderIcon} />
              <Text ml="4" mt="1" color="#6B6C7E">
                Nova Pasta
              </Text>
            </Flex>
            <Divider />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack mb="2">
              <Text fontWeight={700} fontSize="16px">
                Nome da Pasta
              </Text>
              <Text marginLeft={"2"} color="red.600" size="16px">
                *
              </Text>
            </HStack>
            <Input
              value={nomeDaPasta}
              autoFocus
              h="28px"
              borderRadius="5px"
              maxLength={256}
              border="1px solid #747474"
              focusBorderColor={green700}
              onChange={(e) => setNomeDaPasta(e.target.value)}
            />
            {showErrorMessage && (
              <Text color="red" mt="1">
                Não é possível criar um diretório com nome maior que 256
                caracteres.
              </Text>
            )}
            <HStack mt="8" mb="2">
              <Text fontWeight={700} fontSize="16px">
                Vincular tags
              </Text>
              <Text marginLeft={"2"} color="red.600" size="16px">
                *
              </Text>
            </HStack>
            <Box mb="16">
              <AgrodriveFiltersTags
                items={{
                  currentSafras: currentSafrasVinculadas,
                  currentUnidades: currentUnidadesVinculada,
                  currentCultura: currentCulturaVinculada,
                  currentSetores: currentSetoresVinculadas,
                  currentTalhao: currentTalhoesVinculados,
                  tagsPersonalizadas: tagsPersonalizadasVinculadas,
                  customTagsData: customTagsData,
                }}
                onChange={{
                  setCurrentSafras: setCurrentSafrasVinculadas,
                  setCurrentUnidades: setCurrentUnidadesVinculadas,
                  setCurrentCultura: setCurrentCulturaVinculada,
                  setCurrentSetores: setCurrentSetoresVinculados,
                  setCurrentTalhao: setCurrentTalhoesVinculados,
                  setTagsPersonalizadas: setTagsPersonalizadasVinculadas,
                  setTagsVinculadas: setTagsVinculadas,
                }}
                setSelected={(item) => setSelectedTag(item)}
              />
            </Box>
            <Divider />
          </ModalBody>

          <ModalFooter justifyContent={"center"} alignItems={"center"}>
            <Button variant="ghost" onClick={onClose} w="100px" h="40px">
              Cancelar
            </Button>
            <Button
              bg={green700}
              color={"white"}
              w="100px"
              h="40px"
              ml="1"
              mr={3}
              _hover={{
                opacity: 0.73,
              }}
              disabled={isDisabled}
              onClick={() => handleCreateFolder()}
            >
              Criar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalNewFolder;
