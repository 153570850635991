import { OcupacaoNomeEnum, TalhaoDetalhes } from "core/features/maps/typings";

import vegetativoImg from "assets/images/icons/002-vegetativo.png";
import iconePlantioImg from "assets/images/icons/004-icone-plantio.png";
import colheitaImg from "assets/images/icons/006-colheita.png";

import algodaoVN from "assets/images/icons/algodao/algodao_vn.png";
import algodaoV1 from "assets/images/icons/algodao/algodao_v1.png";
import algodaoV2 from "assets/images/icons/algodao/algodao_v2.png";
import algodaoV4 from "assets/images/icons/algodao/algodao_v4.png";
import algodaoV3 from "assets/images/icons/algodao/algodao_v3.png";
import algodaoC3AC8 from "assets/images/icons/algodao/algodao_c3_a_c8.png";
import algodaoF1AF7 from "assets/images/icons/algodao/algodao_f1_a_f7.png";
import algodaoC1OuC2 from "assets/images/icons/algodao/algodao_c1_ou_c2.png";
import algodaoFallback from "assets/images/icons/algodao/algodao_fallback.png";
import algodaoV5AV18OuB1AB7 from "assets/images/icons/algodao/algodao_v5_a_v18_ou_b1_a_b7.png";

import milhoVE from "assets/images/icons/milho/milho_ve.png";
import milhoV1EV2 from "assets/images/icons/milho/milho_v1_v2.png";
import milhoR1AR5 from "assets/images/icons/milho/milho_r1_a_r5.png";
import milhoR6AR9 from "assets/images/icons/milho/milho_r6_a_r9.png";
import milhoV9V18 from "assets/images/icons/milho/milho_v9_a_v18.png";
import milhoV3V4V5 from "assets/images/icons/milho/milho_v3_v4_e_v5.png";
import milhoV6V7V8 from "assets/images/icons/milho/milho_v6_v7_e_v8.png";
import milhoFallback from "assets/images/icons/milho/milho_fallback.png";

import sojaVE from "assets/images/icons/soja/soja_ve.png";
import sojaVC from "assets/images/icons/soja/soja_vc.png";
import sojaV1 from "assets/images/icons/soja/soja_v1.png";
import sojaV2 from "assets/images/icons/soja/soja_v2.png";
import sojaR1 from "assets/images/icons/soja/soja_r1.png";
import sojaR2 from "assets/images/icons/soja/soja_r2.png";
import sojaR3 from "assets/images/icons/soja/soja_r3.png";
import sojaR4 from "assets/images/icons/soja/soja_r4.png";
import sojaR5AR9 from "assets/images/icons/soja/soja_r5_a_r9.png";
import sojaV3AV18 from "assets/images/icons/soja/soja_v3_a_v18.png";
import sojaFallback from "assets/images/icons/soja/soja_fallback.png";
import { TimelineItemProps } from "./Timeline";
import { AiOutlineCalendar } from "react-icons/ai";
import formatDate from "infra/helpers/formatDate";
import { green700 } from "core/resources/theme/colors";

// eslint-disable-next-line no-shadow
export enum AlgodaoCodigo {
  VN = "VN",
  V1 = "V1",
  V2 = "V2",
  V3 = "V3",
  V4 = "V4",
  V5 = "V5",
  V6 = "V6",
  V7 = "V7",
  V8 = "V8",
  V9 = "V9",
  V10 = "V10",
  V11 = "V11",
  V12 = "V12",
  V13 = "V13",
  V14 = "V14",
  V15 = "V15",
  V16 = "V16",
  V17 = "V17",
  V18 = "V18",
  B1 = "B1",
  B2 = "B2",
  B3 = "B3",
  B4 = "B4",
  B5 = "B5",
  B6 = "B6",
  B7 = "B7",
  F1 = "F1",
  F2 = "F2",
  F3 = "F3",
  F4 = "F4",
  F5 = "F5",
  F6 = "F6",
  F7 = "F7",
  C1 = "C1",
  C2 = "C2",
  C3 = "C3",
  C4 = "C4",
  C5 = "C5",
  C6 = "C6",
  C7 = "C7",
  C8 = "C8",
}

// eslint-disable-next-line no-shadow
export enum MilhoCodigo {
  VE = "VE",
  V1 = "V1",
  V2 = "V2",
  V3 = "V3",
  V4 = "V4",
  V5 = "V5",
  V6 = "V6",
  V7 = "V7",
  V8 = "V8",
  V9 = "V9",
  V10 = "V10",
  V11 = "V11",
  V12 = "V12",
  V13 = "V13",
  V14 = "V14",
  V15 = "V15",
  V16 = "V16",
  V17 = "V17",
  V18 = "V18",

  R1 = "R1",
  R2 = "R2",
  R3 = "R3",
  R4 = "R4",
  R5 = "R5",
  R6 = "R6",
  R7 = "R7",
  R7_1 = "R7_1",
  R7_2 = "R7_2",
  R7_3 = "R7_3",
  R8_1 = "R8_1",
  R9 = "R9",
}

// eslint-disable-next-line no-shadow
export enum SojaCodigo {
  VE = "VE",
  VC = "VC",
  V1 = "V1",
  V2 = "V2",
  V3 = "V3",
  V4 = "V4",
  V5 = "V5",
  V6 = "V6",
  V7 = "V7",
  V8 = "V8",
  V9 = "V9",
  V10 = "V10",
  V11 = "V11",
  V12 = "V12",
  V13 = "V13",
  V14 = "V14",
  V15 = "V15",
  V16 = "V16",
  V17 = "V17",
  V18 = "V18",
  R1 = "R1",
  R2 = "R2",
  R3 = "R3",
  R4 = "R4",
  R5 = "R5",
  R5_1 = "R5.1",
  R5_2 = "R5.2",
  R5_3 = "R5.3",
  R5_4 = "R5.4",
  R5_5 = "R5.5",
  R6 = "R6",
  R7 = "R7",
  R7_1 = "R7.1",
  R7_2 = "R7.2",
  R7_3 = "R7.3",
  R8 = "R8",
  R8_1 = "R8.1",
  R8_2 = "R8.2",
  R9 = "R9",
}

export function getIconEstadiosFenologico(
  codigo: string,
  cultura: OcupacaoNomeEnum
) {
  codigo = codigo.toUpperCase();

  if (cultura === OcupacaoNomeEnum.Algodão) {
    return getIconEstadiosFenologicoAlgodao(codigo);
  }

  if (cultura === OcupacaoNomeEnum.Soja) {
    return getIconEstadiosFenologicoSoja(codigo);
  }

  if (cultura === OcupacaoNomeEnum.Milho) {
    return getIconEstadiosFenologicoMilho(codigo);
  }

  return null;
}

export function getIconEstadiosFenologicoAlgodao(codigo: string) {
  if (codigo === AlgodaoCodigo.VN) {
    return algodaoVN;
  }

  if (codigo === AlgodaoCodigo.V1) {
    return algodaoV1;
  }

  if (codigo === AlgodaoCodigo.V2) {
    return algodaoV2;
  }

  if (codigo === AlgodaoCodigo.V3) {
    return algodaoV3;
  }

  if (codigo === AlgodaoCodigo.V4) {
    return algodaoV4;
  }

  if (
    codigo === AlgodaoCodigo.V5 ||
    codigo === AlgodaoCodigo.V6 ||
    codigo === AlgodaoCodigo.V7 ||
    codigo === AlgodaoCodigo.V8 ||
    codigo === AlgodaoCodigo.V9 ||
    codigo === AlgodaoCodigo.V10 ||
    codigo === AlgodaoCodigo.V11 ||
    codigo === AlgodaoCodigo.V12 ||
    codigo === AlgodaoCodigo.V13 ||
    codigo === AlgodaoCodigo.V14 ||
    codigo === AlgodaoCodigo.V15 ||
    codigo === AlgodaoCodigo.V16 ||
    codigo === AlgodaoCodigo.V17 ||
    codigo === AlgodaoCodigo.V18 ||
    codigo === AlgodaoCodigo.B1 ||
    codigo === AlgodaoCodigo.B2 ||
    codigo === AlgodaoCodigo.B3 ||
    codigo === AlgodaoCodigo.B4 ||
    codigo === AlgodaoCodigo.B5 ||
    codigo === AlgodaoCodigo.B6 ||
    codigo === AlgodaoCodigo.B7
  ) {
    return algodaoV5AV18OuB1AB7;
  }

  if (
    codigo === AlgodaoCodigo.F1 ||
    codigo === AlgodaoCodigo.F2 ||
    codigo === AlgodaoCodigo.F3 ||
    codigo === AlgodaoCodigo.F4 ||
    codigo === AlgodaoCodigo.F5 ||
    codigo === AlgodaoCodigo.F6 ||
    codigo === AlgodaoCodigo.F7
  ) {
    return algodaoF1AF7;
  }

  if (codigo === AlgodaoCodigo.C1 || codigo === AlgodaoCodigo.C2) {
    return algodaoC1OuC2;
  }

  if (
    codigo === AlgodaoCodigo.C3 ||
    codigo === AlgodaoCodigo.C4 ||
    codigo === AlgodaoCodigo.C5 ||
    codigo === AlgodaoCodigo.C6 ||
    codigo === AlgodaoCodigo.C7 ||
    codigo === AlgodaoCodigo.C8
  ) {
    return algodaoC3AC8;
  }

  return algodaoFallback;
}

export function getIconEstadiosFenologicoMilho(codigo: string) {
  if (codigo === MilhoCodigo.VE) {
    return milhoVE;
  }

  if (codigo === MilhoCodigo.V1 || codigo === MilhoCodigo.V2) {
    return milhoV1EV2;
  }

  if (
    codigo === MilhoCodigo.V3 ||
    codigo === MilhoCodigo.V4 ||
    codigo === MilhoCodigo.V5
  ) {
    return milhoV3V4V5;
  }

  if (
    codigo === MilhoCodigo.V6 ||
    codigo === MilhoCodigo.V7 ||
    codigo === MilhoCodigo.V8
  ) {
    return milhoV6V7V8;
  }

  if (
    codigo === MilhoCodigo.V9 ||
    codigo === MilhoCodigo.V10 ||
    codigo === MilhoCodigo.V11 ||
    codigo === MilhoCodigo.V12 ||
    codigo === MilhoCodigo.V13 ||
    codigo === MilhoCodigo.V14 ||
    codigo === MilhoCodigo.V15 ||
    codigo === MilhoCodigo.V16 ||
    codigo === MilhoCodigo.V17 ||
    codigo === MilhoCodigo.V18
  ) {
    return milhoV9V18;
  }

  if (
    codigo === MilhoCodigo.R1 ||
    codigo === MilhoCodigo.R2 ||
    codigo === MilhoCodigo.R3 ||
    codigo === MilhoCodigo.R4 ||
    codigo === MilhoCodigo.R5
  ) {
    return milhoR1AR5;
  }

  if (
    codigo === MilhoCodigo.R6 ||
    codigo === MilhoCodigo.R7 ||
    codigo === MilhoCodigo.R7_1 ||
    codigo === MilhoCodigo.R7_2 ||
    codigo === MilhoCodigo.R7_3 ||
    codigo === MilhoCodigo.R8_1 ||
    codigo === MilhoCodigo.R9
  ) {
    return milhoR6AR9;
  }

  return milhoFallback;
}

export function getIconEstadiosFenologicoSoja(codigo: string) {
  if (codigo === SojaCodigo.VE) {
    return sojaVE;
  }

  if (codigo === SojaCodigo.VC) {
    return sojaVC;
  }

  if (codigo === SojaCodigo.V1) {
    return sojaV1;
  }

  if (codigo === SojaCodigo.V2) {
    return sojaV2;
  }

  if (
    codigo === SojaCodigo.V3 ||
    codigo === SojaCodigo.V4 ||
    codigo === SojaCodigo.V5 ||
    codigo === SojaCodigo.V6 ||
    codigo === SojaCodigo.V7 ||
    codigo === SojaCodigo.V8 ||
    codigo === SojaCodigo.V9 ||
    codigo === SojaCodigo.V10 ||
    codigo === SojaCodigo.V11 ||
    codigo === SojaCodigo.V12 ||
    codigo === SojaCodigo.V13 ||
    codigo === SojaCodigo.V14 ||
    codigo === SojaCodigo.V15 ||
    codigo === SojaCodigo.V16 ||
    codigo === SojaCodigo.V17 ||
    codigo === SojaCodigo.V18
  ) {
    return sojaV3AV18;
  }

  if (codigo === SojaCodigo.R1) {
    return sojaR1;
  }

  if (codigo === SojaCodigo.R2) {
    return sojaR2;
  }

  if (codigo === SojaCodigo.R3) {
    return sojaR3;
  }

  if (codigo === SojaCodigo.R4) {
    return sojaR4;
  }

  if (
    codigo === SojaCodigo.R5 ||
    codigo === SojaCodigo.R5_1 ||
    codigo === SojaCodigo.R5_2 ||
    codigo === SojaCodigo.R5_3 ||
    codigo === SojaCodigo.R5_4 ||
    codigo === SojaCodigo.R5_5 ||
    codigo === SojaCodigo.R6 ||
    codigo === SojaCodigo.R7 ||
    codigo === SojaCodigo.R7_1 ||
    codigo === SojaCodigo.R7_2 ||
    codigo === SojaCodigo.R7_3 ||
    codigo === SojaCodigo.R8 ||
    codigo === SojaCodigo.R8_1 ||
    codigo === SojaCodigo.R8_2 ||
    codigo === SojaCodigo.R9
  ) {
    return sojaR5AR9;
  }

  return sojaFallback;
}

export function getTimelineItems(
  detalhesTalhao: TalhaoDetalhes
): TimelineItemProps[] {
  const ocupacao: OcupacaoNomeEnum = detalhesTalhao.ocupacao
    ?.nome as OcupacaoNomeEnum;

  const items: TimelineItemProps[] = [];

  let dataPlantio = "A definir";

  if (detalhesTalhao.plantio?.real?.dataInicio) {
    const dataInicio = formatDate(
      detalhesTalhao.plantio.real.dataInicio.substring(0, 10)
    );
    const dataFim = formatDate(
      detalhesTalhao.plantio.real.dataFim.substring(0, 10)
    );

    dataPlantio = `Plantio: ${dataInicio} - ${dataFim}`;
  } else if (detalhesTalhao.plantio?.previsao) {
    const dataPrevisao = formatDate(
      detalhesTalhao.plantio?.previsao.data.substring(0, 10)
    );

    dataPlantio = `Previsão de Plantio: ${dataPrevisao}`;
  }

  items.push({
    image: iconePlantioImg,
    info: dataPlantio,
  });

  items.push({
    image: vegetativoImg,
    info: `Emergência: ${
      detalhesTalhao.emergencia?.dataEmergencia
        ? formatDate(detalhesTalhao.emergencia?.dataEmergencia.substring(0, 10))
        : "---"
    }`,
    showDivider: true,
    dividerInfo: detalhesTalhao.emergencia?.diasDesdeOPlantio
      ? `${detalhesTalhao.emergencia?.diasDesdeOPlantio} ${
          detalhesTalhao.emergencia?.diasDesdeOPlantio === 1 ? "dia" : "dias"
        }`
      : undefined,
  });

  if (detalhesTalhao.estadiosFenologico) {
    detalhesTalhao.estadiosFenologico.forEach((item) => {
      items.push({
        image: getIconEstadiosFenologico(item.codigo, ocupacao),
        info: `${item.codigo} ${item.descricao}: ${formatDate(
          item.data.substring(0, 10)
        )}`,
        showDivider: true,
        dividerInfo: `${item.dae} DAE`,
      });
    });
  }

  if (detalhesTalhao.hoje) {
    items.push({
      icon: <AiOutlineCalendar fontSize={20} color={green700} />,
      info: `Hoje: ${formatDate(detalhesTalhao.hoje.data.substring(0, 10))}`,
      showDivider: true,
      dividerInfo: `${detalhesTalhao.hoje.dae} DAE`,
    });
  }

  if (detalhesTalhao.colheita?.real?.dae) {
    const dataInicio = formatDate(
      detalhesTalhao.colheita.real.dataInicio.substring(0, 10)
    );
    const dataFim = formatDate(
      detalhesTalhao.colheita.real.dataFim.substring(0, 10)
    );

    items.push({
      image: colheitaImg,
      info: `Colheita: ${dataInicio} - ${dataFim}`,
      showDivider: true,
      dividerInfo: `${detalhesTalhao.colheita.real.dae} DAE`,
    });
  } else if (detalhesTalhao.colheita?.previsao) {
    items.push({
      image: colheitaImg,
      info: `Previsão de Colheita: ${formatDate(
        detalhesTalhao.colheita.previsao.data.substring(0, 10)
      )}`,
      showDivider: true,
      dividerInfo: `${detalhesTalhao.colheita.previsao.dae} DAE`,
    });
    if (detalhesTalhao.colheita.previsao.dias) {
      items.push({
        info: "",
        showDivider: true,
        dividerInfo: `Faltam ${detalhesTalhao.colheita.previsao.dias} ${
          detalhesTalhao.colheita.previsao.dias === 1 ? "dia" : "dias"
        }`,
      });
    }
  }

  return items;
}
