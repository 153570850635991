import { useState } from "react";

import { useFiltersLists } from "features/filters/store/lists";
import { ICheckboxItem } from "components/inputs/filter-safra-unidade-mapatematico/items/checkbox-tree";
import { useConcentracaoColheitaStore } from "features/filters/store/useConcentracaoColheitaStore";

function useConcentracaoColheitaFilters() {
  const [value, setValue] = useState<ICheckboxItem[]>(() => {
    const store = useConcentracaoColheitaStore.getState();

    if (store.filters.concentracoes.length) {
      const result: ICheckboxItem[] = [];

      for (const item of store.filters.concentracoes) {
        const v = {
          extras: item,
          key: item.nome,
          label: item.nome,
          value: item.nome,
          color: item.cor,
          parent: null,
          children: [],
        };

        if (!item.semanas.length) {
          result.push(v);
        }

        for (const semana of item.semanas) {
          result.push({
            extras: semana,
            key: `${semana.semanaCodigo}`,
            label: `${semana.semanaCodigo}º semana`,
            value: semana.semanaCodigo,
            color: semana.cor,
            parent: v,
            children: [],
          });
        }
      }

      return result;
    }

    return [];
  });

  const { loadings } = useFiltersLists();

  return {
    isLoading: loadings.concentracaoColheita,
    value,
    onChange: setValue,
  };
}

export default useConcentracaoColheitaFilters;
