import mixpanel from "mixpanel-browser";
import { useEnvs } from "core/features/env/store";

function init() {
  const key = useEnvs.getState();

  mixpanel.init(key.envs.react_app_mixpanel_api_key!);
}

export { mixpanel, init };
