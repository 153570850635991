import { createContext, ReactNode, useState } from "react";

interface CenterMapContextProps {
  shouldForceCenter: boolean;
  handleChangeShouldForceCenter: (prop: boolean) => void;
}

const CenterMapContext = createContext<CenterMapContextProps>({
  shouldForceCenter: false,
  handleChangeShouldForceCenter: () => {},
});

const CenterMapContextProvider = ({ children }: { children: ReactNode }) => {
  const [forceCenter, setForceCenter] = useState<boolean>(true);

  return (
    <CenterMapContext.Provider
      value={{
        shouldForceCenter: forceCenter,
        handleChangeShouldForceCenter: setForceCenter,
      }}
    >
      {children}
    </CenterMapContext.Provider>
  );
};

export { CenterMapContext, CenterMapContextProvider };
