import { Box } from "@chakra-ui/react";
import { Col, Row } from "antd";
import { useGraficoAplicacoes } from "features/aplicacoes/hooks/useGraficoAplicacoes";
import GraficoCustoMedioAplicacoes from "./GraficoCustoMedioAplicacoes";
import GraficoCustoTotalAplicacoes from "./GraficoCustoTotalAplicacoes";
import SkeletonGraficoCustoMedio from "./SkeletonGraficoCustoMedio";
import SkeletonGraficoCustoTotal from "./SkeletonGraficoCustoTotal";

interface GraficosProps {
  talhaoId: string;
}

const Graficos = ({ talhaoId }: GraficosProps) => {
  const { data, isFetching } = useGraficoAplicacoes(talhaoId);

  return (
    <Box h="400px">
      <Row gutter={32}>
        <Box as={Col} sm={15}>
          {!isFetching ? (
            <GraficoCustoMedioAplicacoes items={data?.porAplicacao ?? []} />
          ) : (
            <SkeletonGraficoCustoMedio />
          )}
        </Box>

        <Box as={Col} sm={9}>
          {!isFetching ? (
            <GraficoCustoTotalAplicacoes data={data?.total!} />
          ) : (
            <SkeletonGraficoCustoTotal />
          )}
        </Box>
      </Row>
    </Box>
  );
};

export default Graficos;
