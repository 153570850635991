import { green700 } from "core/resources/theme/colors";
import { FaFileAlt } from "react-icons/fa";
import { FcFolder } from "react-icons/fc";
import { Image } from "@chakra-ui/react";
import { ArquivoResponse, DiretorioData } from "../../types/arquivos";

// import kmlIcon from "assets/images/icons/agrodrive/kml-icon.svg";
import zipIcon from "assets/images/icons/agrodrive/zip-icon.svg";
import pdfIcon from "assets/images/icons/agrodrive/pdf-icon.svg";
import imgIcon from "assets/images/icons/agrodrive/img-icon.svg";
import audioIcon from "assets/images/icons/agrodrive/audio-icon.svg";
import isoIcon from "assets/images/icons/agrodrive/iso-icon.svg";
import csvPlanilhaIcon from "assets/images/icons/agrodrive/csv-planilhas-icon.svg";
// import xmlArquivoIcon from "assets/images/icons/agrodrive/xml-arquivo-icon.svg";
import dllIcon from "assets/images/icons/agrodrive/dll-icon.svg";
// import txtIcon from "assets/images/icons/agrodrive/txt-icon.svg";
import xlsIcon from "assets/images/icons/agrodrive/xls-icon.svg";
import videoIcon from "assets/images/icons/agrodrive/video-icon.svg";
import docIcon from "assets/images/icons/agrodrive/doc-icon.svg";
import fontIcon from "assets/images/icons/agrodrive/font-icon.svg";
import locationIcon from "assets/images/icons/agrodrive/location-icon.svg";
import DBIcon from "assets/images/icons/agrodrive/database-icon.svg";

export function getIcon(file: ArquivoResponse) {
  const { tipo, nome } = file;
  const extension = nome.split(".").pop();

  const extensionToMimeType = {
    //IMAGENS
    png: "image/png",
    ai: "application/postscript",
    bmp: "image/bmp",
    gif: "image/gif",
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    psd: "image/vnd.adobe.photoshop",
    svg: "image/svg+xml",
    tif: "image/tiff",
    tiff: "image/tiff",
    webp: "image/webp",
    //AUDIO
    mp3: "audio/mpeg",
    m4a: "audio/x-m4a",
    mid: "audio/midi",
    midi: "audio/midi",
    map: "audio/x-mod",
    ogg: "audio/ogg",
    wav: "audio/wav",
    wma: "audio/x-ms-wma",
    // VÍDEO
    avi: "video/x-msvideo",
    h264: "video/h264",
    m4v: "video/x-m4v",
    mkv: "video/x-matroska",
    mov: "video/quicktime",
    mp4: "video/mp4",
    mpg: "video/mpeg",
    mpeg: "video/mpeg",
    webm: "video/webm",
    wmv: "video/x-ms-wmv",
    //COMPACTADOS
    zip: "application/zip",
    "7z": "application/x-7z-compressed",
    rar: "application/x-rar-compressed",
    //BINÁRIOS
    bin: "application/octet-stream",
    dmg: "application/x-apple-diskimage",
    iso: "application/x-iso9660-image",
    //DADOS TABULADOS
    csv: "text/csv",
    xml: "application/xml",
    //EXECUTÁVEIS
    apk: "application/vnd.android.package-archive",
    bat: "application/bat",
    cgi: "application/cgi",
    pl: "application/perl",
    exe: "application/x-msdownload",
    app: "application/octet-stream",
    jar: "application/java-archive",
    msi: "application/x-msi",
    py: "application/x-python",
    //FONTES
    otf: "font/otf",
    ttf: "font/ttf",
    //PLANILHAS
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //ARQUIVOS DE SISTEMA
    bak: "application/octet-stream",
    dll: "application/x-msdownload",
    sys: "application/octet-stream",
    //DOC e DOCX
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //PDF
    pdf: "application/pdf",
    //ANALISE DE DADOS
    json: "application/json",
    geojson: "application/geo+json",
    gpkg: "application/geopackage+sqlite3",
    qgz: "application/x-qgis",
    qpj: "application/x-qgis-proj",
    qpt: "application/x-qgis-printtemplate",
    shp: "application/x-esri-shape",
    // DATABASE
    dat: "application/octet-stream",
    log: "text/plain",
    mdb: "application/x-msaccess",
    db: "application",
    dbf: "application/dbase",
  };

  if (tipo === "Folder") {
    return <FcFolder size={20} />;
  }

  for (const [ext, mime] of Object.entries(extensionToMimeType)) {
    if (extension === ext && tipo === mime) {
      if (mime.startsWith("audio/")) {
        return <Image src={audioIcon} />;
      } else if (mime.startsWith("video/")) {
        return <Image src={videoIcon} />;
      } else if (mime.startsWith("image/" || "application/postscript")) {
        return <Image src={imgIcon} />;
      } else if (
        mime.startsWith(
          "application/zip" ||
            "application/x-7z-compressed" ||
            "application/x-rar-compressed"
        )
      ) {
        return <Image src={zipIcon} />;
      } else if (
        mime.startsWith(
          // "application/octet-stream" || --> esse mesmo tipo está para arquivo do tipo [dat] database
          "application/x-apple-diskimage" || "application/x-iso9660-image"
        )
      ) {
        return <Image src={isoIcon} />;
      } else if (mime.startsWith("text/csv" || "application/xml")) {
        return <Image src={csvPlanilhaIcon} />;
      } else if (
        mime.startsWith(
          "application/vnd.android.package-archive" ||
            "application/bat" ||
            "application/cgi" ||
            "application/perl" ||
            "application/x-msdownload" ||
            // "application/octet-stream" || --> esse mesmo tipo está para arquivo do tipo [dat] database
            "application/java-archive" ||
            "application/x-msi" ||
            "application/x-python"
          // "text/plain"  --> esse mesmo tipo está para arquivo do tipo [dat] database
        )
      ) {
        return <Image src={dllIcon} />;
      } else if (mime.startsWith("font/")) {
        return <Image src={fontIcon} />;
      } else if (mime.startsWith("application/vnd")) {
        return <Image src={xlsIcon} />;
      } else if (
        mime.startsWith(
          "application/octet-stream" || "application/x-msdownload"
        )
      ) {
        return <Image src={dllIcon} />;
      } else if (
        mime.startsWith(
          "application/msword" || "application/vnd.openxmlformats"
        )
      ) {
        return <Image src={docIcon} />;
      } else if (mime.startsWith("application/pdf")) {
        return <Image src={pdfIcon} />;
      } else if (
        mime.startsWith(
          "application/json" ||
            "application/geo+json" ||
            "application/geopackage+sqlite3" ||
            "application/x-qgis" ||
            "application/x-qgis-proj" ||
            "application/x-qgis-printtemplate" ||
            "application/x-esri-shape"
        )
      ) {
        return <Image src={locationIcon} />;
      } else if (
        mime.startsWith(
          "application/octet-stream" ||
            "text/plain" ||
            "application/geopackage+sqlite3" ||
            "application/x-qgis" ||
            "application/x-qgis-proj" ||
            "application/x-qgis-printtemplate" ||
            "application/x-esri-shape"
        )
      ) {
        return <Image src={DBIcon} />;
      }
    }
  }

  return <FaFileAlt size={20} color={green700} />;
}

export const getFileSize = (bytes: number): string => {
  if (bytes === 0) return "0 B";
  const k = 1024;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = (bytes / Math.pow(k, i)).toFixed(2);
  return `${parseFloat(size).toLocaleString("pt-BR", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    useGrouping: false,
  })} ${sizes[i]}`;
};

export const sortFiles = (arquivos: DiretorioData[]) => {
  if (!arquivos) {
    return [];
  }

  // Ordena os arquivos primeiro por tipo (pastas antes de arquivos)
  const sortedFiles = arquivos.sort((a, b) => {
    if (a.tipo === "Folder" && b.tipo !== "Folder") {
      return -1; // a é uma pasta e b não é, a deve ser classificada antes de b
    } else if (a.tipo !== "Folder" && b.tipo === "Folder") {
      return 1; // a não é uma pasta e b é, b deve ser classificada antes de a
    } else {
      return a.nome.localeCompare(b.nome, "pt-BR", { sensitivity: "base" }); // Ambos são pastas ou ambos são arquivos, então ordenamos por nome
    }
  });

  return sortedFiles;
};
