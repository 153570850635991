import { Flex, Box, Skeleton } from "@chakra-ui/react";
import CheckboxFiltro from "components/inputs/checkbox-filtro";
import ButtonLimpar from "components/inputs/filter-safra-unidade-mapatematico/ButtonLimpar";
import NotFoundMapFilters from "components/NotFoundMapFilters";
import { useCallback } from "react";
import { useMapaDeChuvaIndices } from "../../hooks/useMapaDeChuvaIndices";
import LoadingSkeletonCheckbox from "../LoadingSkeletonCheckbox";

export function MapaDeChuvaIndices() {
  const { items, value, loading, onChange, onClear, onSelectAll } =
    useMapaDeChuvaIndices();

  const checkIfItemsIsSelected = useCallback(
    (indice: number): boolean => {
      const exists =
        value.find((val) => val === indice) === 0 ||
        value.find((val) => val === indice);

      return !!exists;
    },
    [value]
  );

  if (loading) {
    return (
      <Flex
        flexDirection="column"
        marginTop="20px"
        width="100%"
        marginLeft="10px"
      >
        <Flex
          margin="5px 0"
          justifyContent="space-between"
          flex={1}
          paddingRight="20px"
        >
          <Flex>
            <Skeleton height="17px" width="17px" marginRight="5px" />
            <Skeleton height="17px" width="50px" />
          </Flex>
          <Flex>
            <Skeleton height="17px" width="70px" />
          </Flex>
        </Flex>
        <LoadingSkeletonCheckbox />
        <LoadingSkeletonCheckbox />
        <LoadingSkeletonCheckbox />
        <LoadingSkeletonCheckbox />
        <LoadingSkeletonCheckbox />
      </Flex>
    );
  }

  return (
    <Box position="relative" width="100%" paddingTop="17px" paddingLeft="10px">
      <ButtonLimpar onClear={onClear} />
      <CheckboxFiltro
        onClick={value.length === items.length ? onClear : onSelectAll}
        text="Todos"
        isChecked={value.length === items.length}
      />

      {!items.length && (
        <NotFoundMapFilters message="Não foi possível achar inidices nesse período" />
      )}

      <Flex flexDirection="column">
        {items.map((item, indice) => {
          return (
            <CheckboxFiltro
              key={item.descricao + indice}
              onClick={() => onChange(item.indicePluviometrico)}
              text={item.descricao}
              color={item.cor}
              isChecked={checkIfItemsIsSelected(item.indicePluviometrico)}
            />
          );
        })}
      </Flex>
    </Box>
  );
}
