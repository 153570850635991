import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import useSearchStore from "features/search/states/useSearchStore";
import { useMapStore } from "features/maps/store/useMapStore";
import { useFilters } from "features/filters/store";
import { TipoMapaTalhoes } from "core/features/maps/typings";

function useSearch() {
  const { setQuery } = useSearchStore();
  const { data: talhoes, talhao, updateTalhao, updateCenter } = useMapStore();
  const {
    updateField,
    filters: { tipoMapa },
  } = useFilters();

  const [focused, setFocused] = useState<boolean>(false);

  const [value, setValue] = useState<string>("");

  const toast = useToast();

  const showNotFound = () => {
    toast({
      position: "top",
      duration: 2000,
      title: `Talhão ${value} não encontrado`,
      status: "error",
    });
  };

  const onSearch = () => {
    if (value === "") {
      return;
    }

    setFocused(false);

    const exists = talhoes?.features.find(
      (item) =>
        `${item.id}`.toLowerCase() === value.trim().toLowerCase() &&
        item.properties?.id
    );

    if (!exists || tipoMapa === TipoMapaTalhoes.Chuvas) {
      showNotFound();
      return;
    }

    onClear();

    updateTalhao(exists.properties);
  };

  const onClear = () => {
    setValue("");
    updateField("setor", null);
  };

  useEffect(() => {
    if (talhao) {
      const { pontoCentralLat, pontoCentralLong } = talhao;

      if (pontoCentralLat && pontoCentralLong) {
        updateCenter({
          lat: pontoCentralLat,
          long: pontoCentralLong,
        });
      }
    }
  }, [talhao, updateCenter]);

  useEffect(() => {
    if (talhoes?.Properties) {
      const { latitude, longitude } = talhoes?.Properties;

      if (latitude && longitude) {
        updateCenter({
          lat: latitude,
          long: longitude,
        });
      }
    }
  }, [talhoes?.Properties, updateCenter]);

  useEffect(() => {
    setQuery(value.length > 0 ? value : undefined);
  }, [value, setQuery]);

  return {
    onSearch,
    onClear,
    focused,
    onFocus: () => setFocused(true),
    onBlur: () => setFocused(false),
    value,
    onChange: (v: string) => setValue(v),
  };
}

export default useSearch;
