import React, { useContext } from "react";
import { Flex, Text } from "@chakra-ui/react";

import { TalhaoDetailContext } from "core/features/maps/talhao/TalhaoDetailContext";
import { TalhaoDetalhes } from "core/features/maps/typings";

import { StatusOrdemServicoEnum } from "../TabOrdensServico/ActionButtons";
import BoxInfo from "./BoxInfo";

interface PulverizacoesProps {
  detalhesTalhao: TalhaoDetalhes;
  totalDeOperacoes?: number;
  ordensEmAberto?: number;
}

function Pulverizacoes({
  detalhesTalhao,
  totalDeOperacoes,
  ordensEmAberto,
}: PulverizacoesProps) {
  const {
    handleChangeOrdensDeServicoFilterIndex,
    handleChangeTabIndex,
    handleOrdensDeServicoNumeroOs,
  } = useContext(TalhaoDetailContext);

  const handleGoToOrdensDeservico = (filterIndex: StatusOrdemServicoEnum) => {
    handleOrdensDeServicoNumeroOs(undefined);
    handleChangeTabIndex(1);
    handleChangeOrdensDeServicoFilterIndex(filterIndex);
  };

  const handleFormatData = (data: string) => {
    const dataSplited = data.split("-").reverse();
    let dataTranslated = `${dataSplited[0]}/${dataSplited[1]}/${dataSplited[2]}`;
    return dataTranslated;
  };

  return (
    <Flex flexDir="column">
      <Text fontWeight={"bold"} fontSize={16} mb={2} whiteSpace="nowrap">
        Aplicações
      </Text>

      <Flex flexDir="row" mt={3}>
        <BoxInfo
          title="Inseticidas"
          value={detalhesTalhao.aplicacoes.inseticidas || 0}
          hoverEffect
        />
        <BoxInfo
          title="Herbicidas"
          value={detalhesTalhao.aplicacoes.herbicidas || 0}
          hoverEffect
        />
        <BoxInfo
          title="Fungicidas"
          value={detalhesTalhao.aplicacoes.fungicidas || 0}
          hoverEffect
        />
        <BoxInfo
          title="Reguladores"
          value={detalhesTalhao.aplicacoes.reguladores || 0}
        />
      </Flex>
      <Flex flexDir="row">
        <BoxInfo
          title="Ordens em Aberto"
          value={ordensEmAberto || 0}
          iconLeft="folder"
          iconLeftOnMouseEnter="openFolder"
          hoverEffect
          onClick={() =>
            ordensEmAberto &&
            handleGoToOrdensDeservico(StatusOrdemServicoEnum.Abertas)
          }
        />
        <BoxInfo
          title="Total de Operações"
          value={totalDeOperacoes || 0}
          iconLeft="tractor"
          onClick={() => totalDeOperacoes && handleChangeTabIndex(2)}
          hoverEffect
          iconLeftOnMouseEnter="tractorHover"
        />
        <BoxInfo
          title="Chuva Acumulada(mm)"
          value={detalhesTalhao.chuvaAcumulada?.valor || 0}
          iconLeft="rain"
          iconRight="calendar"
          iconRightTooltipText={`${
            detalhesTalhao.chuvaAcumulada &&
            handleFormatData(detalhesTalhao.chuvaAcumulada.dataInicioSoma)
          }
           à ${
             detalhesTalhao.chuvaAcumulada &&
             handleFormatData(detalhesTalhao.chuvaAcumulada.dataFimSoma)
           }`}
        />
        <BoxInfo
          title="Estande de Plantas"
          value={detalhesTalhao.estande || 0}
          iconLeft="tree"
        />
      </Flex>
    </Flex>
  );
}

export default Pulverizacoes;
