import { Badge, Flex } from "@chakra-ui/layout";
import { Collapse } from "@chakra-ui/react";
import CheckboxFiltro from "components/inputs/checkbox-filtro";
import {
  FiltroDistribuicaoVarietal,
  Finalidade,
  KeyItemsOcupacoes,
  Ocupacao,
  Tecnologia,
  Variedade,
  VariedadeGrupo,
} from "core/features/maps/typings";
import { gray700, green700 } from "core/resources/theme/colors";
import { getIcon } from "infra/helpers/ocupacao";
import * as _ from "lodash";
import { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import {
  getCheckedChildrenColors,
  getChildrenForItem,
  getKeyForIndentLevel,
} from "./helper";

function OcupacaoItem({
  item,
  ocupacoes,
  onClick,
  indent,
  index,
  keyItem,
  filtro,
  showTodos = true,
  parentTestId,
  isCollapsed = true,
}: {
  item: Ocupacao | Tecnologia | Finalidade | Variedade | VariedadeGrupo;
  ocupacoes: Ocupacao[];
  onClick: (
    items: (Ocupacao | Tecnologia | Finalidade | Variedade | VariedadeGrupo)[],
    keyItem: KeyItemsOcupacoes,
    isChecked: boolean
  ) => any;
  indent: number;
  index: number;
  keyItem: KeyItemsOcupacoes;
  filtro: FiltroDistribuicaoVarietal | null;
  showTodos?: boolean;
  parentTestId?: string;
  isCollapsed?: boolean;
}) {
  const checkedChildrenColors = getCheckedChildrenColors(item, keyItem, filtro);

  const [collapsed, setCollapsed] = useState<boolean>(!!isCollapsed);

  const icon =
    keyItem === KeyItemsOcupacoes.ocupacoes
      ? getIcon(ocupacoes, (item as Ocupacao).codigo!)
      : undefined;

  const disabled = item.id === null;

  const children = getChildrenForItem(item, keyItem);

  const nonNullChildren = (children as any).filter(
    (child: { id: string | number | null }) => child.id !== null
  ) as (Tecnologia | Variedade | Finalidade | VariedadeGrupo)[];

  const childrenKey = getKeyForIndentLevel(indent);

  const isCheckedTodos = nonNullChildren.every((child) =>
    (filtro?.[childrenKey] as { id: string | number }[])?.find((filtroItem) =>
      _.isEqual(filtroItem, child)
    )
  );

  const isChecked = !!(filtro?.[keyItem] as { id: string | number }[])?.find(
    (i) => _.isEqual(i, item)
  );

  return (
    <Flex flexDir="column" pl={indent === 0 ? "4px" : undefined}>
      <Flex
        flex={1}
        alignItems="center"
        pl={`${Math.min(indent * 24, 96)}px`}
        mb={1}
      >
        <CheckboxFiltro
          disabled={disabled}
          onClick={() => onClick([item], keyItem, !isChecked)}
          isChecked={isChecked}
          text={item.nome}
          imageIcon={icon}
          color={item.color}
          data-testid={
            parentTestId
              ? parentTestId + `-${keyItem}-${item.nome}`
              : `menu-filtro-ocupacoes-${item.nome}`
          }
        />
        {children.length ? (
          <Flex
            cursor="pointer"
            onClick={() => setCollapsed((s) => !s)}
            ml={2}
            data-testid={
              parentTestId
                ? parentTestId + `-${keyItem}-${item.nome}-expandir`
                : `menu-filtro-ocupacoes-${item.nome}-expandir`
            }
          >
            {collapsed ? (
              <BiChevronDown fontSize={20} color="gray.900" />
            ) : (
              <BiChevronUp fontSize={20} color="gray.900" />
            )}
          </Flex>
        ) : null}
        {checkedChildrenColors.length > 0 ? (
          <Badge
            bg={green700}
            rounded="full"
            color="white"
            w="18px"
            h="18px"
            textAlign="center"
            ml={2}
          >
            {checkedChildrenColors.length}
          </Badge>
        ) : null}
      </Flex>

      <Collapse in={!collapsed}>
        {nonNullChildren.length > 1 && showTodos ? (
          <Flex
            flex={1}
            alignItems="center"
            pl={`${Math.min((indent + 1) * 24, 96)}px`}
            mb={1}
          >
            <CheckboxFiltro
              isChecked={isCheckedTodos}
              size="md"
              borderColor={gray700}
              text="Todos"
              onClick={() =>
                onClick(nonNullChildren, childrenKey, !isCheckedTodos)
              }
              data-testid={
                parentTestId
                  ? parentTestId +
                    `-${keyItem}-${item.nome}-${childrenKey}-todos`
                  : `menu-filtro-ocupacoes-todos`
              }
            />
          </Flex>
        ) : null}

        {children.map((child, index) => (
          <OcupacaoItem
            key={index}
            item={child}
            ocupacoes={ocupacoes}
            filtro={filtro}
            keyItem={childrenKey}
            indent={indent + 1}
            onClick={onClick}
            index={index}
            isCollapsed={false}
            parentTestId={
              parentTestId
                ? parentTestId + `-${keyItem}-${item.nome}`
                : `menu-filtro-ocupacoes-${item.nome}`
            }
          />
        ))}
      </Collapse>
    </Flex>
  );
}

export default OcupacaoItem;
