import { Box, Flex, Text } from "@chakra-ui/react";
import { GraficoAplicacoesTotalResult } from "features/aplicacoes/typings";
import { blue600, gray900 } from "core/resources/theme/colors";
import { LabelList } from "recharts";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import { formatMoney } from "infra/helpers/number";

const GraphicNameShape = styled.div<{ bg: string }>`
  width: 11px;
  height: 11px;
  background: ${({ bg }) => bg};
  border-radius: 50%;
  margin: 0 5px 0 10px;
`;

const renderCustomizedLabel = (props: any) => {
  const { x, y, width, height, value, color } = props;

  return (
    <g>
      <text
        x={x + 30 + width}
        y={y + height / 2}
        fill={color}
        textAnchor="middle"
        fontWeight={"bold"}
        dominantBaseline="middle"
      >
        {formatMoney(value, "$")}
      </text>
    </g>
  );
};

function GraficoCustoTotalAplicacoes({
  data,
}: {
  data: GraficoAplicacoesTotalResult;
}) {
  return (
    <Box
      py="32px"
      boxShadow="0 0 5px rgba(0,0,0,.2)"
      overflow={"hidden"}
      rounded={10}
    >
      <Text mb="16px" textAlign={"center"} fontWeight={"bold"}>
        Custo total do Talhão x Média
      </Text>

      <Box h="37.5vh" maxH="400px">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={600}
            height={300}
            data={[data]}
            margin={{
              left: -30,
              right: 60,
            }}
            layout="vertical"
          >
            {/* <YAxis dataKey="total" /> */}

            <XAxis type="number" />
            <YAxis type="category" dataKey="insumo" />

            <CartesianGrid
              strokeWidth={2}
              strokeDasharray="3 3"
              horizontal={false}
            />

            {/* <XAxis dataKey="insumo" /> */}

            <Bar
              fill={blue600}
              dataKey="talhao"
              isAnimationActive={true}
              maxBarSize={65}
              radius={[0, 10, 10, 0]}
              minPointSize={5}
            >
              <LabelList
                dataKey="talhao"
                color={blue600}
                content={renderCustomizedLabel}
              />
            </Bar>
            <Bar
              fill={gray900}
              opacity={0.9}
              dataKey="mediaDemaisTalhoes"
              isAnimationActive={true}
              maxBarSize={65}
              radius={[0, 10, 10, 0]}
              minPointSize={5}
            >
              <LabelList
                dataKey="mediaDemaisTalhoes"
                color={gray900}
                content={renderCustomizedLabel}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>

      <Flex justifyContent="center" mt="16px" pl="16px" flexDir={"column"}>
        <Flex alignItems="center">
          <GraphicNameShape bg={blue600} />
          <Text fontWeight="medium">Custo total das Aplicações</Text>
        </Flex>
        <Flex alignItems="center">
          <GraphicNameShape bg={gray900} />
          <Text fontWeight="medium">Média dos demais talhões (US$/HA)</Text>
        </Flex>
      </Flex>
    </Box>
  );
}

export default GraficoCustoTotalAplicacoes;
