import { Flex, FlexProps } from "@chakra-ui/layout";

interface ContainerItemProps extends FlexProps {
  children: any;
  onClick: () => void;
  isBorderBottom: boolean;
  isSelected?: boolean;
}
function ContainerItem({
  children,
  onClick,
  isBorderBottom,
  isSelected,
  ...rest
}: ContainerItemProps) {
  return (
    <Flex
      bg={isSelected ? "gray.100" : "white"}
      onClick={onClick}
      cursor="pointer"
      transition=".1s linear all"
      _hover={{
        bgColor: "gray.100",
      }}
      py="16px"
      borderBottomWidth={isBorderBottom ? 1 : undefined}
      borderLeft={isSelected ? "4px solid green" : "4px solid white"}
      borderBottomColor="gray.100"
      borderBottomStyle="solid"
      borderBottomRadius={isBorderBottom ? 0 : 8}
      {...rest}
    >
      <Flex>{children}</Flex>
    </Flex>
  );
}

export default ContainerItem;
