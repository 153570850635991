import { Flex, Image } from "@chakra-ui/react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";

import tractorTelemetria from "assets/images/tractor-telemetria.svg";

interface OrdensServicoExpandIconProps {
  expanded: boolean;
  onExpand: (e: any) => void;
}

const OrdensServicoExpandIcon = ({
  expanded,
  onExpand,
}: OrdensServicoExpandIconProps) => {
  if (expanded) {
    return (
      <Flex
        flexDir="row"
        alignItems="center"
        justifyContent="space-around"
        onClick={(e) => onExpand(e)}
      >
        <FaChevronDown style={{ marginRight: 6 }} fontSize={12} />

        <Image width={5} height={4} src={tractorTelemetria} />
      </Flex>
    );
  }

  return (
    <Flex
      flexDir="row"
      alignItems="center"
      justifyContent="space-around"
      onClick={(e) => {
        onExpand(e);
      }}
    >
      <FaChevronRight style={{ marginRight: 6 }} fontSize={12} />
      <Image width={5} height={4} src={tractorTelemetria} />
    </Flex>
  );
};

export default OrdensServicoExpandIcon;
