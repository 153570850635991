import { ICheckboxItem } from "components/inputs/filter-safra-unidade-mapatematico/items/checkbox-tree";
import { OcupacaoEnum } from "features/maps/enums";
import {
  ConcentracaoColheitaItem,
  ConcentracaoColheitaItemSemana,
  ConcentracaoColheitaTipo,
} from "features/maps/typings";
import * as _ from "lodash";

export function filterConcentracoes(
  items: ConcentracaoColheitaItem[],
  ocupacao: OcupacaoEnum,
  tecnologias: string[],
  tipo: ConcentracaoColheitaTipo
) {
  const filteredItems = items.filter((item) => {
    if (
      (tipo === ConcentracaoColheitaTipo.Ambos || item.tipo === tipo) &&
      item.ocupacao === ocupacao &&
      tecnologias.includes(item.tecnologiaId)
    ) {
      return true;
    }

    return false;
  });

  let grouped = _.groupBy(filteredItems, "nome");

  let newFilteredItems: ConcentracaoColheitaItem[] = [];

  for (const mesAno in grouped) {
    const semanasMerged = grouped[mesAno].reduce(
      (
        agg: any[],
        current: ConcentracaoColheitaItem
      ): ConcentracaoColheitaItemSemana[] => {
        return [...agg, ...current.concentracoesPorSemana];
      },
      []
    );

    const semanaGrouped = _.groupBy(semanasMerged, "nome");

    const semanas = Object.keys(semanaGrouped).map(
      (key) => semanaGrouped[key][0]
    );

    const item: ConcentracaoColheitaItem = {
      ...grouped[mesAno][0],
      concentracoesPorSemana: semanas,
    };

    newFilteredItems.push(item);
  }

  const result: ICheckboxItem[] = newFilteredItems.map((item) => {
    return {
      key: item.nome,
      tipo: item.tipo,
      children: _.orderBy(item.concentracoesPorSemana, "nome").map(
        (cs): ICheckboxItem => ({
          key: `${cs.semanaCodigo}`,
          label: cs.nome,
          color: cs.cor,
          value: cs.semanaCodigo,
          children: [],
          extras: cs,
          parent: {
            key: item.nome,
            label: item.nome,
            value: item.nome,
            color: item.cor,
            extras: item,
          },
        })
      ),
      label: item.nome,
      value: item.nome,
      color: item.cor,
      extras: item,
    };
  });

  return result;
}
