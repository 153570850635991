import http from "infra/http";
import { useQuery } from "react-query";
import URL from "core/resources/urls";
import { OrdensDeServicosAbertasPorEquipePorps } from "../typings";

export function useTableOrdensDeServicosAbertasPorEquipe(
  unidadeId: string,
  equipeId: string
) {
  return useQuery(
    [
      URL.MAPAS.TABELA_ORDENS_DE_SERVICOS_ABERTAS_POR_EQUIPE(unidadeId),
      unidadeId,
      equipeId,
    ],
    async () => {
      const { data } = await http.get(
        URL.MAPAS.TABELA_ORDENS_DE_SERVICOS_ABERTAS_POR_EQUIPE(unidadeId),
        {
          params: {
            equipeId,
          },
        }
      );

      return data as OrdensDeServicosAbertasPorEquipePorps;
    }
  );
}
