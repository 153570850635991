import { useQuery } from "react-query";
import http from "infra/http";
import URL from "core/resources/urls";

export function useGetTiposAtraso() {
  return useQuery(
    [URL.JUSTIFICATIVA.TIPOS_ATRASO],
    async () => {
      const { data } = await http.get(URL.JUSTIFICATIVA.TIPOS_ATRASO);

      return data;
    }
  );
}
