import { ENDPOINTS, get } from "infra/http/farmApi";
import { UseQueryOptions, useQuery } from "react-query";

export const placeholderData: any = [
    {id: 1, name: "Sector 1"}, {id: 2, name: "Sector 2"}, {id: 3, name: "Sector 3"}, {id: 4, name: "Sector 4"}
]

export default function useFarmSectors(farmId: string | number, params: any = {}, options: UseQueryOptions = {}) {
    const queryOptions: UseQueryOptions = {
        ...options,
        placeholderData: placeholderData,
    }

    const endpoint = ENDPOINTS.FARM_SECTORS
        .replace(/:farm_id/gi, farmId.toString());
    
    return useQuery<any>(
        [ENDPOINTS.FARM_SECTORS, farmId, params],
        () => get<any>(endpoint, params),
        queryOptions
    );
}
