import { Box, Flex, Image, Input } from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import { TipoMapaTalhoes } from "core/features/maps/typings";

import LupaIcon from "assets/images/Lupa.png";
import { useEffect, useState } from "react";
import { useFilters } from "core/features/filters/store";

interface SearchInputFieldProps {
  value: string;
  onChange: (v: string) => void;
  onSearch: () => void;
  onClear: () => void;
  onFocus: () => void;
}

const SearchInputField = ({
  value,
  onChange,
  onSearch,
  onClear,
  onFocus,
}: SearchInputFieldProps) => {
  const [localValue, setLocalValue] = useState(value);
  const {
    filters: { tipoMapa, setor },
  } = useFilters();

  const isSatelite = tipoMapa === TipoMapaTalhoes.Satelite;
  const isTextura = tipoMapa === TipoMapaTalhoes.Texturas;
  const isChuvas = tipoMapa === TipoMapaTalhoes.Chuvas;

  const renderPlaceHolderInput = () => {
    if (isSatelite) {
      return "Procurar por talhão";
    }
    if (isTextura || isChuvas) {
      return "Procurar por setor";
    }

    return "Procurar por talhão ou setor";
  };

  useEffect(() => {
    if (!value) {
      setLocalValue(value);
    }
  }, [value]);

  const inputValue = setor?.nome ?? localValue;

  return (
    <Flex pos="relative" w="100%">
      <Input
        data-testid="input-talhao-input"
        placeholder={renderPlaceHolderInput()}
        value={inputValue}
        readOnly={setor?.nome || isTextura || isChuvas ? true : false}
        onChange={(ev) => {
          setLocalValue(ev.target.value);
          onChange(ev.target.value);
        }}
        onFocus={onFocus}
        rounded="full"
        focusBorderColor="none"
        borderWidth={1}
        borderColor="gray.500"
        maxLength={9}
        h="45px"
        w="100%"
        bg="white"
        _hover={{
          borderColor: "gray.500",
        }}
        _focus={{
          borderColor: "gray.500",
        }}
        _placeholder={{
          color: "gray.500",
        }}
        color="gray.800"
        onKeyDown={(props) => props.key === "Enter" && onSearch()}
        pr="32px"
      />
      <Box
        d="flex"
        cursor="pointer"
        pos="absolute"
        right="16px"
        top="13px"
        zIndex={1}
      >
        {inputValue ? (
          <Box data-testid="button-talhao-clear" onClick={() => onClear()}>
            <MdClose fontSize={20} />
          </Box>
        ) : (
          <Image
            data-testid="button-talhao-search"
            src={LupaIcon}
            onClick={onSearch}
            width={5}
          />
        )}
      </Box>
    </Flex>
  );
};

export default SearchInputField;
