import { Flex, Text } from "@chakra-ui/react";
import { StatusOrdemServicoEnum } from "./ActionButtons";

interface TabOrdensServicoProps {
  index: number;
  data: any;
}

const OrdensServicoFooter = ({
  index,
  data,
}: TabOrdensServicoProps) => {
  return (
    <Flex flex={1}>
      Total de Ordens{" "}
      {index === StatusOrdemServicoEnum.Abertas
        ? "Em Aberto"
        : index === StatusOrdemServicoEnum.Fechadas
        ? "Fechadas"
        : null}
      :{" "}
      <Text fontWeight="bold" pl="4px">
        {data?.pages[0]?.paginate?.totalCount ?? 0}
      </Text>
    </Flex>
  );
};

export default OrdensServicoFooter;
