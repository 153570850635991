import { HStack, Tag, TagCloseButton, TagLabel, Text } from "@chakra-ui/react";
import { AgrodriveContext } from "core/features/agrodrive/contexts/Agrodrive.context";
import { useContext } from "react";

export const FooterAgroTable = () => {
  const {
    items: { selecteds, diretorioData },
    onChange: { setSelecteds },
  } = useContext(AgrodriveContext);

  return (
    <HStack>
      <Text fontWeight="medium" fontSize="18px" textColor="#7B7B7B">
        Total de itens: {diretorioData?.totalArquivos}
      </Text>
      {selecteds.length > 0 ? (
        <Tag
          size={"lg"}
          borderRadius="full"
          variant="solid"
          colorScheme="white"
          border="1px solid #7B7B7B"
          textColor="#7B7B7B"
          ml="2"
        >
          <TagCloseButton
            color="#7B7B7B"
            onClick={() => {
              setSelecteds([]);
            }}
          />
          <TagLabel ml="2">
            {selecteds.length === 1 && `${selecteds.length} Selecionado`}
            {selecteds.length > 1 && `${selecteds.length} Selecionados`}
          </TagLabel>
        </Tag>
      ) : null}
    </HStack>
  );
};
