import React from "react";
import { Box } from "@chakra-ui/react";
import { Spinner } from "./styles";
import ContainerFilter from "components/inputs/filter-safra-unidade-mapatematico/ContainerFilter";

interface LoadingBoxProps {
  isVisible: boolean;
  fitFilterContent?: boolean;
}

function LoadingBox({ isVisible, fitFilterContent }: LoadingBoxProps) {
  return (
    <ContainerFilter
      isVisible={isVisible}
      containerProps={{
        mt: fitFilterContent ? 14 : 3,
        rounded: 8,
        padding: 2,
        zIndex: 3,
        [fitFilterContent ? "w" : ""]: fitFilterContent ? "415px" : null,
      }}
    >
      <Box
        background="white"
        padding="11px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="10px"
      >
        <Spinner />
      </Box>
    </ContainerFilter>
  );
}

export default LoadingBox;
