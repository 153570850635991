import { Flex, IconButton } from "@chakra-ui/react";
import LoadingBox from "components/layout/loading-box";
import useConcentracaoColheitaFilters from "./hooks/useConcentracaoColheitaFilters";

import ContainerFilter from "../../ContainerFilter";

import ColhidoCombo from "./components/ColhidoCombo";
import ConcentracaoItems from "./components/ConcentracaoItems";

import ConcentracaoOcupacoes from "./components/ConcentracaoOcupacoes";
import ConcentracaoTecnologias from "./components/ConcentracaoTecnologias";

import { FaChevronUp } from "react-icons/fa";
import MapGridButton from "components/MapGridButton";

interface ConcentracaoColheitaComponentProps {
  isVisible: boolean;
  handleChangeIsVisible: (prop: boolean) => void;
  handleOpenGrid: () => void;
}

function ConcentracaoColheitaComponent({
  handleChangeIsVisible,
  handleOpenGrid,
  isVisible,
}: ConcentracaoColheitaComponentProps) {
  const { isLoading, value, onChange } = useConcentracaoColheitaFilters();

  if (isLoading) {
    return <LoadingBox isVisible={isVisible} />;
  }

  return (
    <ContainerFilter
      containerProps={{
        mt: 3,
        rounded: 8,
        padding: 4,
      }}
      isVisible={isVisible}
    >
      <ConcentracaoOcupacoes />

      <>
        <ConcentracaoTecnologias />

        <Flex h="1px" bg="gray.200" mt={4} flex={1} />

        <Flex mt={4} flexDir="column" ml={2}>
          <ColhidoCombo />

          <ConcentracaoItems onChange={onChange} value={value} />
        </Flex>
      </>

      <Flex w="100%" h="1px" bg="gray.400" mt="12px" />

      <Flex
        padding="5px"
        justifyContent="space-between"
        borderTop="1px solid #84848440 "
        alignItems="center"
        paddingTop="15px"
        marginTop="10px"
      >
        <MapGridButton handleOpenGrid={handleOpenGrid} />

        <IconButton
          background="none"
          _hover={{}}
          _focus={{}}
          _active={{}}
          icon={<FaChevronUp color="#848484" />}
          aria-label="close-button"
          marginRight="20px"
          onClick={() => handleChangeIsVisible(false)}
        />
      </Flex>
    </ContainerFilter>
  );
}

export default ConcentracaoColheitaComponent;
