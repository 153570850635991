import { TalhaoDetalhes } from "core/features/maps/typings";
import URL from "core/resources/urls";
import http from "infra/http";
import { useQuery } from "react-query";

export default function useTalhaoDetalhes(talhaoId: string | null) {
  return useQuery(
    URL.TALHOES.DETALHE_TALHAO(talhaoId!),
    async () => {
      const { data } = await http.get(URL.TALHOES.DETALHE_TALHAO(talhaoId!));

      return data as TalhaoDetalhes;
    },
    {
      enabled: !!talhaoId,
    }
  );
}
