import { Box, Flex, Select, Text } from "@chakra-ui/react";
import { hoursStrings } from "./utils/hours";

interface HourSelectProps {
  value?: string;
  onChange: (newDate: string) => void;
  label: string;
  error?: string;
  maxValue?: string;
  minValue?: string;
}

export const HourSelect = ({
  value,
  onChange,
  error,
  maxValue,
  minValue,
  label,
}: HourSelectProps) => {
  return (
    <Flex flexDirection="column" position="relative" width="100%">
      <Box
        width="100%"
        height="40px"
        display="flex"
        alignItems="center"
        gap="5px"
        border="1px solid "
        borderColor={error ? "red" : "gray.500"}
        borderRadius="6px"
      >
        <Text margin=" 0px 0px 0 15px" fontWeight="medium" color="gray.500">
          {label}
        </Text>

        <Select
          size="xs"
          color="gray.600"
          border="0"
          _active={{}}
          _focus={{}}
          fontSize="14px"
          value={value}
          onChange={(props: any) => onChange(props.target.value)}
        >
          <option value={undefined}>Selecione</option>
          {hoursStrings.map((hourProp) => {
            const hour = hourProp.split(":")[0];
            const maxHour = maxValue?.split(":")[0];
            const minHour = minValue?.split(":")[0];

            const isHourSmallerThenMinHour = hour < minHour!;
            const isHourBiggerThenMaxHour = hour > maxHour!;
            const isHourEqualToMinOrMax =
              hourProp === minValue || hourProp === maxValue;

            return (
              <option
                value={hourProp}
                disabled={
                  isHourEqualToMinOrMax
                    ? true
                    : minHour
                    ? isHourSmallerThenMinHour
                    : maxHour
                    ? isHourBiggerThenMaxHour
                    : hour === maxHour || hour === minHour
                }
                key={hour}
              >
                {hourProp}
              </option>
            );
          })}
        </Select>
      </Box>

      {error && (
        <Text
          margin="0"
          padding="0"
          position="absolute"
          bottom="-20px"
          fontSize="12px"
          color="red"
        >
          {error}
        </Text>
      )}
    </Flex>
  );
};
