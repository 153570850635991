export enum FilterIndex {
  Unidade = 0,
  Safra = 1,
  Mapa_Tematico = 2,
  Ocupacao = 3,
  Chuvas = 4,
  Concentracao_Colheita = 5,
  Imagens_Satelite = 6,
  Textura_Solo = 7,
  Operacoes_Executadas = 8,
  Numero_De_Aplicacoes = 9,
  Produtividade = 10,
  Idade_Do_Talhao = 11,
  Ordens_De_Servicos_Abertas = 12,
  Distribuicao_de_equipes = 13,
}
