import api from "../utils/api";
import { useQuery } from "react-query";
import { EnvURL } from "core/features/env/enums";

export function useGetConfigurations() {
  return useQuery(
    [EnvURL.Configurations],
    async () => {
      const { data } = await api.get(EnvURL.Configurations);

      return data;
    }
  );
}
