import URL from "core/resources/urls";
import { Feature, Geometry } from "geojson";
import {
  CustomFeature,
  DistribuicaoVarietalPostBody,
  FiltroDistribuicaoVarietal,
  TexturaSoloListagemItem,
  TipoMapaTalhoes,
} from "../typings";

export function transformDistribuicaoVarietalBody(
  filtro: FiltroDistribuicaoVarietal | null
) {
  const requestBody: DistribuicaoVarietalPostBody = {
    ocupacoes: filtro?.ocupacoes.map((ocupacao) => ocupacao.codigo) || [],
    finalidades:
      filtro?.finalidades.map((finalidade) => ({
        color: finalidade.color,
        finalidadeId: finalidade.id,
        ocupacao: finalidade.ocupacaoCodigo,
      })) || [],
    tecnologias:
      filtro?.tecnologias.map((tecnologia) => ({
        color: tecnologia.color,
        finalidadeId: tecnologia.finalidadeId,
        ocupacao: tecnologia.ocupacaoCodigo,
        tecnologiaId: tecnologia.id,
      })) || [],
    variedadeGrupos:
      filtro?.variedadeGrupos.map((variedadeGrupo) => ({
        color: variedadeGrupo.color,
        finalidadeId: variedadeGrupo.finalidadeId,
        tecnologiaId: variedadeGrupo.tecnologiaId,
        ocupacao: variedadeGrupo.ocupacaoCodigo,
        variedadeGrupoId: variedadeGrupo.id,
      })) || [],
    variedades:
      filtro?.variedades.map((variedade) => ({
        color: variedade.color,
        finalidadeId: variedade.finalidadeId,
        ocupacao: variedade.ocupacaoCodigo,
        tecnologiaId: variedade.tecnologiaId,
        variedadeGrupoId: variedade.variedadeGrupoId,
        variedadeId: variedade.id,
      })) || [],
    periodoPlantio: {
      dataInicial: filtro?.periodoPlantio?.dataInicial,
      dataFinal: filtro?.periodoPlantio?.dataFinal,
    },
    periodoColheita: {
      dataInicial: filtro?.periodoColheita?.dataInicial,
      dataFinal: filtro?.periodoColheita?.dataFinal,
    },
    periodoEmergencia: {
      dataInicial: filtro?.periodoEmergencia?.dataInicial,
      dataFinal: filtro?.periodoEmergencia?.dataFinal,
    },
    periodoPrevisaoColheita: {
      dataInicial: filtro?.periodoPrevisaoDeColheita?.dataInicial,
      dataFinal: filtro?.periodoPrevisaoDeColheita?.dataFinal,
    },
  };

  return requestBody;
}

export const getUrlForTipoMapa = (
  tipoMapa: TipoMapaTalhoes,
  unidadeId?: string,
  safraId?: string,
  texturas?: TexturaSoloListagemItem[]
) => {
  switch (tipoMapa) {
    case TipoMapaTalhoes.DistribuicaoVarietal:
      return `${URL.MAPAS.DISTRIBUICAO_VARIETAL(unidadeId!, safraId!)}`;
    case TipoMapaTalhoes.Texturas:
      const codigos = texturas?.map((t) => t.codigo) || [];
      return `${URL.MAPAS.MAPA_TEXTURAS_SOLO(unidadeId!, codigos)}`;
    case TipoMapaTalhoes.OperacoesExecutadas:
      return `${URL.MAPAS.MAPA_OPERACOES_EXECUTADAS(unidadeId!, safraId!)}`;
    case TipoMapaTalhoes.NumeroDeAplicacoes:
      return `${URL.MAPAS.MAPA_NUMERO_DE_APLICACOES(unidadeId!, safraId!)}`;
    case TipoMapaTalhoes.Produtividade:
      return `${URL.MAPAS.MAPA_PRODUTIVIDADE(unidadeId!, safraId!)}`;
    case TipoMapaTalhoes.ConcentracaoColheita:
      return `${URL.MAPAS.MAPA_CONCENTRACAO_COLHEITA(unidadeId!, safraId!)}`;
    case TipoMapaTalhoes.IdadeDoTalhao:
      return `${URL.MAPAS.MAPA_IDADE_DO_TALHAO(unidadeId!, safraId!)}`;
    case TipoMapaTalhoes.OrdensDeServicosAberta:
      return `${URL.MAPAS.MAPA_ORDENS_DE_SERVICOS_ABERTAS(unidadeId!)}`;
    case TipoMapaTalhoes.DistribuicaoDeEquipes:
      return `${URL.MAPAS.MAPA_DISTRIBUICAO_DE_EQUIPES(unidadeId!)}`;
    case TipoMapaTalhoes.Chuvas:
      return `${URL.MAPAS.MAPA_DE_CHUVA_GEOJSON(unidadeId!)}`;
    default:
      return `${URL.MAPAS.DISTRIBUICAO_VARIETAL(unidadeId!, safraId!)}`;
  }
};

export const mapPropertiesFeature = (
  feature: Feature<Geometry, CustomFeature>
) => {
  feature.properties.strokeOpacity = Number(feature.properties.strokeOpacity);
  feature.properties.fillOpacity = Number(feature.properties.fillOpacity);
  feature.properties.strokeWidth = Number(feature.properties.strokeWidth);
};
