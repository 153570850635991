import {
  CustomFeature,
  CustomLatLng,
  LatLong,
} from "core/features/maps/typings";
import { DEFAULT_ZOOM, options } from "./config";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { ReactChild, ReactChildren, useState } from "react";

import MapControl from "./components/controls";
import { MapTypeId } from "core/features/maps/enums";
import { CurrentViewTypeEnum, PointsBaseMap } from "./typings";
import ProductivityAndArea from "./components/productivityAndArea";
import { containerStyle } from "./styles";
import { useMap } from "./hooks/useMap";
import { GeometryType } from "./typings";
import { white } from "core/resources/theme/colors";

import * as S from "./styles";
import "./pluviometrico.css";

interface BaseMapProps {
  children?: ReactChild | ReactChildren | null;
  center?: LatLong;
  points?: PointsBaseMap;
  talhoes?: string[];
  precipitacao?: string;
  isLoading?: boolean;
  onLoadMap?: (map: google.maps.Map | undefined) => void;
  onUnmountMap?: (map: google.maps.Map | undefined) => void;
  onClickItem?: (latLng: CustomLatLng, feature: CustomFeature) => void;
}

function BaseMap({
  children,
  center,
  points,
  talhoes,
  precipitacao,
  isLoading,
  onLoadMap,
  onUnmountMap,
  onClickItem,
}: BaseMapProps) {
  const { onLoad, onUnmount, mapRef, displayMarkers } = useMap({
    onClickItem,
    points,
    center,
    talhoes,
  });

  const url = window.location.href;
  const isPrecipitacaoPage = url.includes("precipitacao");

  const [currentViewType, setCurrentViewType] = useState<CurrentViewTypeEnum>(
    isPrecipitacaoPage ? CurrentViewTypeEnum.numberOnly : CurrentViewTypeEnum.box
  );

  const Polygons =
    points?.features.filter(
      (feature) =>
        feature.geometry.type === GeometryType.Polygon ||
        feature.geometry.type === GeometryType.MultiPolygon
    ) || [];

  const Points =
    points?.features.filter(
      (feature) => feature.geometry.type === GeometryType.Point
    ) || [];

  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={DEFAULT_ZOOM}
        onLoad={(map: google.maps.Map) => {
          onLoad(map);
          onLoadMap?.(map);
        }}
        onUnmount={(map: google.maps.Map) => {
          onUnmount();
          onUnmountMap?.(map);
        }}
        mapTypeId={MapTypeId.HYBRID}
        options={{ ...options }}
      >
        {children}
        <S.MarkersContainer>
          {Polygons.map((item, key) => (
            <Marker
              visible={
                currentViewType !== CurrentViewTypeEnum.transparent &&
                displayMarkers
              }
              key={key}
              label={{
                text: item.properties?.codigo,
                color:
                  currentViewType === CurrentViewTypeEnum.box ? "#000" : "#fff",
                fontWeight:
                  currentViewType === CurrentViewTypeEnum.box
                    ? "regular"
                    : "bold",
              }}
              icon={{
                url: "/marker.png",
                size:
                  currentViewType !== CurrentViewTypeEnum.box
                    ? new google.maps.Size(0, 0)
                    : undefined,
              }}
              position={{
                lat: item.properties?.pontoCentralLat,
                lng: item.properties?.pontoCentralLong,
              }}
              onClick={(e) => {
                if (e.latLng) {
                  onClickItem?.(e.latLng, item.properties as CustomFeature);
                }
              }}
            />
          ))}

          {Points.map(
            (item, key) =>
              item.geometry.type === GeometryType.Point && (
                <Marker
                  key={key}
                  visible={displayMarkers}
                  zIndex={1000}
                  icon="/indicacao-meteorologica.svg"
                  position={{
                    lat: item.geometry?.coordinates[1],
                    lng: item.geometry?.coordinates[0],
                  }}
                  label={{
                    text: precipitacao ? precipitacao : String(item?.properties?.precipitacao),
                    color: white,
                    fontWeight: "800",
                    className: "pluviometrico-value",
                  }}
                />
              )
          )}
        </S.MarkersContainer>
      </GoogleMap>

      {mapRef.current ? (
        <MapControl
          center={center!}
          map={mapRef.current}
          currentViewType={currentViewType}
          handleChangeCurrentViewType={setCurrentViewType}
        />
      ) : null}

      <ProductivityAndArea
        Properties={points?.Properties}
        isLoading={isLoading as boolean}
      />
    </>
  );
}

export default BaseMap;
