import { Box, Flex, Text } from "@chakra-ui/react";
import { CustomFeature } from "features/maps/typings";
import {
  columnTablesOrdensServicoTempoTotal,
  columnTablesOrdensServicoIndicadoresOperacionais,
} from "./utils";
import DatatableAntd from "components/datatable-antd";
import { gray500 } from "core/resources/theme/colors";

import { useTabOrdensServicoRow } from "./hooks/useTabOrdensServicoRow";

interface TabOrdensServicoProps {
  talhao: CustomFeature;
  ordemServicoId: string;
}

const OrdensServicoRow = ({
  talhao,
  ordemServicoId,
}: TabOrdensServicoProps) => {
  const { convertIndicadoresOperacionaisToArray, values, isLoading } =
    useTabOrdensServicoRow({
      talhao,
      ordemServicoId,
    });

  return (
    <Flex mr={20} mt={2} ml={16} mb={8} flexDir="column">
      <Box
        ml={2.5}
        w="100%"
        h="40px"
        alignItems="center"
        justifyContent="space-between"
        flexDir="row"
        display="flex"
        px={2}
        pb={1}
        bg="#EBEAEA"
        borderWidth={1}
        borderColor={gray500}
      >
        <Text fontWeight="bold">RESUMO DO TRABALHO</Text>
        <Text fontWeight="bold">TELEMETRIA</Text>
      </Box>
      <Flex ml={1} w="100%" flexDir="row" mt={1.5}>
        <Box w="100%" padding="0px " paddingBottom="10px ">
          <DatatableAntd
            style={{
              marginLeft: -20,
            }}
            loading={isLoading}
            dataSource={values?.resumoHoras ?? []}
            columns={columnTablesOrdensServicoTempoTotal(
              values?.tempoTotal ?? ""
            )}
          />
        </Box>
        <Box w="100%" ml={-4} mr={0.5}>
          <DatatableAntd
            loading={isLoading}
            dataSource={convertIndicadoresOperacionaisToArray()}
            columns={columnTablesOrdensServicoIndicadoresOperacionais}
            bordered
            size="large"
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default OrdensServicoRow;
