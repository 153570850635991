import React from "react";
import { Button, Flex } from "@chakra-ui/react";
import { gray500, green700, white } from "core/resources/theme/colors";

import { OcupacaoEnum } from "core/features/maps/enums";

export type EnabledButtonsType = {
  soja: boolean;
  milho: boolean;
  algodao: boolean;
};

interface ActionButtonProdutividadeProps {
  label: string;
  onClick?: (props?: OcupacaoEnum) => void;
  isActive: boolean;
  enabled: boolean;
}

interface ActionButtonsProdutividadeRowProps {
  actualFilter?: OcupacaoEnum;
  handleChangeCurrentFilter?: (props: OcupacaoEnum) => void;
  totalItems?: number;
  enabledButtons: EnabledButtonsType;
}

export const ActionButtonProdutividade = ({
  onClick,
  label,
  isActive,
  enabled,
}: ActionButtonProdutividadeProps) => {
  return (
    <Button
      bg={isActive ? green700 : white}
      rounded="full"
      fontSize={16}
      mr={2}
      borderWidth={isActive ? 0 : 1}
      onClick={() => onClick && onClick()}
      borderColor={gray500}
      color={isActive ? "white" : "gray"}
      disabled={!enabled}
      _hover={{
        opacity: 0.73,
      }}
    >
      {label}
    </Button>
  );
};

export const ActionButtonsProdutividadeRow = ({
  actualFilter,
  handleChangeCurrentFilter,
  totalItems,
  enabledButtons,
}: ActionButtonsProdutividadeRowProps) => {
  return (
    <Flex>
      <ActionButtonProdutividade
        label="Todos"
        isActive={actualFilter === OcupacaoEnum.TODOS}
        onClick={() =>
          handleChangeCurrentFilter &&
          handleChangeCurrentFilter(OcupacaoEnum.TODOS)
        }
        enabled={true}
      />
      <ActionButtonProdutividade
        label="Soja"
        isActive={actualFilter === OcupacaoEnum.SOJA}
        onClick={() =>
          handleChangeCurrentFilter &&
          handleChangeCurrentFilter(OcupacaoEnum.SOJA)
        }
        enabled={enabledButtons.soja}
      />
      <ActionButtonProdutividade
        label="Milho"
        isActive={actualFilter === OcupacaoEnum.MILHO}
        onClick={() =>
          handleChangeCurrentFilter &&
          handleChangeCurrentFilter(OcupacaoEnum.MILHO)
        }
        enabled={enabledButtons.milho}
      />
      <ActionButtonProdutividade
        label="Algodão"
        isActive={actualFilter === OcupacaoEnum.ALGODAO}
        onClick={() =>
          handleChangeCurrentFilter &&
          handleChangeCurrentFilter(OcupacaoEnum.ALGODAO)
        }
        enabled={enabledButtons.algodao}
      />
    </Flex>
  );
};
