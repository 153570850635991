import { useState, useContext } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  FormLabel,
  Input,
  Button,
  ButtonGroup,
  Select,
  FormControl,
  FormErrorMessage,
  Textarea,
  Flex,
} from "@chakra-ui/react";
import { IoIosClose } from "react-icons/io";
import { useQueryClient } from "react-query";
import http from "infra/http";
import URL from "core/resources/urls";
import { useGetTiposAtraso } from "core/features/justificativa/hooks/useGetTiposAtraso";
import { PerfisEnum } from "core/features/users/enums";
import { useAuth } from "core/features/auth/store";
import { showSuccess, showError } from "infra/helpers/alerta";
import { JustificativaContext } from "core/features/justificativa/context/Justificativa.context";
import { formataData } from "../../utils/formataData";

interface AtrasosModalProps {
  isOpen: boolean;
  closeModal: any;
  atraso: any;
  justificativa?: any;
}

const AtrasosModal = ({
  isOpen,
  closeModal,
  atraso,
  justificativa,
}: AtrasosModalProps) => {

  const {
    currentUnidade,
    currentSafra,
    currentCultura,
    currentRegional,
    currentMotivo,
    apenasAprovados,
    apenasJustificados,
    apenasAprovadosCiente,
    apenasPendentesJustificativas,
    page,
    size,
  } = useContext(JustificativaContext);

  const [tipoAtraso, setTipoAtraso] = useState<string>(justificativa?.idPlanoOperacionalOperacaoTipoAtraso);
  const [descricao, setDescricao] = useState<string>(justificativa?.descricao);

  const isTipoAtrasoError = tipoAtraso === undefined || tipoAtraso === "";
  const isDescricaoError = descricao === undefined || descricao === "";

  const { user } = useAuth();
  const { data } = useGetTiposAtraso();

  const perfis = user?.perfis;
  const hasGerentePermission = perfis?.some((i) => i === PerfisEnum.Gerente_Regional || i === PerfisEnum.Gerente_Unidade);

  const qc = useQueryClient();

  const updateAtrasoJustificado = (idTalhaoPlanoOperacionalOperacao: number) => {
    qc.setQueryData([
      currentUnidade?.id,
      currentSafra?.id,
      currentCultura?.codigo,
      currentRegional,
      currentMotivo?.idPlanoOperacionalOperacaoTipoAtraso,
      apenasAprovados,
      apenasJustificados,
      apenasAprovadosCiente,
      apenasPendentesJustificativas,
      page,
      size,
      URL.JUSTIFICATIVA.ATRASOS,
    ], (data: any) => {
      const atrasos = data?.atrasos?.map((atraso: any) => {
        if (atraso.idTalhaoPlanoOperacionalOperacao === idTalhaoPlanoOperacionalOperacao) {
          atraso.justificado = true;
          return atraso;
        }
        return atraso;
      });
      return { total: data?.total, atrasos };
    });
  }

  const updateAtrasoAprovado = async (idTalhaoPlanoOperacionalOperacao: number) => {
    const { data } = await http.get(URL.JUSTIFICATIVA.SALVAR(idTalhaoPlanoOperacionalOperacao));
    const isAprovado = data?.every((item: any) => "dataAprovacao" in item);

    qc.setQueryData([
      currentUnidade?.id,
      currentSafra?.id,
      currentCultura?.codigo,
      currentRegional,
      currentMotivo?.idPlanoOperacionalOperacaoTipoAtraso,
      apenasAprovados,
      apenasJustificados,
      apenasAprovadosCiente,
      apenasPendentesJustificativas,
      page,
      size,
      URL.JUSTIFICATIVA.ATRASOS,
    ], (data: any) => {
      const atrasos = data?.atrasos?.map((atraso: any) => {
        if (atraso.idTalhaoPlanoOperacionalOperacao === idTalhaoPlanoOperacionalOperacao) {
          atraso.aprovado = isAprovado;
          return atraso;
        }
        return atraso;
      });
      return { total: data?.total, atrasos };
    });
  }

  const adicionar = async () => {
    const response = await http.post(
      URL.JUSTIFICATIVA.SALVAR(
        atraso?.idTalhaoPlanoOperacionalOperacao
      ),
      {
        IdPlanoOperacionalOperacaoTipoAtraso: Number(tipoAtraso),
        IdTalhao: atraso?.idTalhao,
        IdOrdemServico: atraso?.idOrdemServico,
        Descricao: descricao,
      }
    );

    closeModal();

    if (response?.status === 200) {
      updateAtrasoJustificado(atraso?.idTalhaoPlanoOperacionalOperacao);
      showSuccess("Justificativa adicionada com sucesso");
      return;
    }

    showError();
  }

  const aprovar = async () => {
    const response = await http.put(
      URL.JUSTIFICATIVA.APROVAR(
        atraso?.idTalhaoPlanoOperacionalOperacao,
        justificativa?.idPlanoOperacionalOperacaoJustificativa,
      ),
      {
        Descricao: descricao,
      }
    );

    closeModal();

    if (response?.status === 200) {
      updateAtrasoAprovado(atraso?.idTalhaoPlanoOperacionalOperacao);
      showSuccess("Justificativa aprovada com sucesso");
      return;
    }

    showError();
  }

  return (
    <Modal isOpen={isOpen} onClose={() => {}} isCentered size="xl">
      <ModalOverlay />
      <ModalContent padding="18px" maxW="800px">
        <Flex justifyContent="space-between" alignItems="center">
          <Text>
            Talhão: {atraso?.talhaoCodigo} - Operação {atraso?.operacaoDescricao} - Data Planejada: {formataData(atraso?.dataProgramada)}
          </Text>

          <IoIosClose size="40px" color="#000" cursor="pointer" onClick={closeModal} />
        </Flex>

        <Flex gridGap="12px">
          <FormControl
            isRequired
            marginY="12px"
            isInvalid={isTipoAtrasoError}
          >
            <FormLabel>Tipo de Atraso</FormLabel>
            <Select
              placeholder="Selecione um tipo de atraso"
              onChange={e => setTipoAtraso(e.target.value)}
              defaultValue={justificativa?.idPlanoOperacionalOperacaoTipoAtraso}
            >
              {data?.map((tipoAtraso: any) => (
                <option
                  key={tipoAtraso.codigo}
                  value={tipoAtraso.idPlanoOperacionalOperacaoTipoAtraso}
                >
                  {tipoAtraso.descricao}
                </option>
              ))}
            </Select>
            <FormErrorMessage>Tipo de atraso é obrigatório.</FormErrorMessage>
          </FormControl>

          {hasGerentePermission && justificativa && (
            <FormControl marginY="12px">
              <FormLabel>Usuário</FormLabel>
              <Input value={justificativa?.usuarioJustificativaNome} disabled />
            </FormControl>
          )}
        </Flex>

        <FormControl
          isRequired
          marginBottom="12px"
          isInvalid={isDescricaoError}
        >
          <FormLabel>Justificativa</FormLabel>
          <Textarea
            placeholder="Justificativa aqui..."
            onChange={e => setDescricao(e.target.value)}
            defaultValue={justificativa?.descricao}
          />
          <FormErrorMessage>Justificativa é obrigatório.</FormErrorMessage>
        </FormControl>

        {justificativa === undefined && (
          <ButtonGroup justifyContent="space-between">
            <Button colorScheme="red" variant="outline" onClick={closeModal}>Cancelar</Button>
            <Button
              colorScheme="green"
              variant="outline"
              onClick={adicionar}
              disabled={isTipoAtrasoError || isDescricaoError}
            >Adicionar</Button>
          </ButtonGroup>
        )}

        {hasGerentePermission && justificativa && (
          <ButtonGroup justifyContent="center">
            <Button
              colorScheme="green"
              variant="outline"
              onClick={aprovar}
              disabled={isTipoAtrasoError || isDescricaoError || atraso.aprovado}
            >Ciente</Button>
          </ButtonGroup>
        )}
      </ModalContent>
    </Modal>
  );
}

export default AtrasosModal;
