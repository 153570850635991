import { CustomFeature } from "features/maps/typings";

import { TalhaoOrdensServicoTelemetriaIndicadoresOperacionaisFormatted } from "features/maps/typings/ordensServico";
import useTalhaoOrdensServicoTelemetria from "core/features/maps/hooks/useTalhaoOrdensServicoTelemetria";

interface TabOrdensServicoProps {
  talhao: CustomFeature;
  ordemServicoId: string;
}

export const useTabOrdensServicoRow = ({
  talhao,
  ordemServicoId,
}: TabOrdensServicoProps) => {
  const { data: values, isLoading } = useTalhaoOrdensServicoTelemetria(
    talhao.id,
    ordemServicoId
  );

  const convertIndicadoresOperacionaisToArray = ():
    | TalhaoOrdensServicoTelemetriaIndicadoresOperacionaisFormatted[]
    | undefined => {
    if (values) {
      const formattedValue = Object.entries(
        values?.indicadoresOperacionais ?? {}
      ).map((e) => ({
        descricao: e[0],
        value: e[1],
      }));

      const formattedNames: TalhaoOrdensServicoTelemetriaIndicadoresOperacionaisFormatted[] =
        [
          {
            descricao: "NÚMERO DE EQUIPAMENTOS",
            value: formattedValue[0].value,
          },
          {
            descricao: "RENDIMENTO OPERACIONAL",
            value: formattedValue[1].value,
          },
          {
            descricao: "HECTARES TRABALHADOS",
            value: formattedValue[2].value,
          },
          {
            descricao: "HECTARES POR EQUIPAMENTO",
            value: formattedValue[3].value,
          },
        ];
      return formattedNames;
    }
  };

  return {
    convertIndicadoresOperacionaisToArray,
    values,
    isLoading,
  };
};
