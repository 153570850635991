export const getDateFromFilter = (filterValue: string) => {
  const valueSplited = filterValue.split("-");
  const year = +valueSplited[0];
  const month = +valueSplited[1];
  const day = +valueSplited[2];

  const dateFromString = new Date(year, month - 1, day);

  return dateFromString;
};

export const getDateStringFromDate = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};
