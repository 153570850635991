import { Safra, Unidade } from "core/features/maps/typings";
import { Setor } from "core/features/unidades/typings";
import { showError, showSuccess } from "infra/helpers/alerta";
import { createContext, ReactNode, useState } from "react";
import {
  Cultura,
  Talhao,
  CustomTag,
} from "ui/pages/app/arquivos/agrodrive/types/tags";
// import * as yup from "yup";

export interface ModalNewFolderContextProps {
  items: {
    currentSafrasVinculadas: Safra[];
    currentUnidadesVinculada: Unidade[];
    currentCulturaVinculada: Cultura[];
    currentSetoresVinculadas: Setor[];
    currentTalhoesVinculados: Talhao[];
    tagsPersonalizadasVinculadas: CustomTag[];
  };
  onChange: {
    setCurrentSafrasVinculadas: (prop: Safra[]) => void;
    setCurrentUnidadesVinculadas: (prop: Unidade[]) => void;
    setCurrentCulturaVinculada: (prop: Cultura[]) => void;
    setCurrentSetoresVinculados: (prop: Setor[]) => void;
    setCurrentTalhoesVinculados: (prop: Talhao[]) => void;
    setTagsPersonalizadasVinculadas: (prop: CustomTag[]) => void;
  };
  after: {
    onSuccess: () => Promise<void>;
    onError: () => void;
  };
  getTags: (defaultTags?: string[]) => string[];
}

export const ModalNewFolderContext = createContext(
  {} as ModalNewFolderContextProps
);

export const ModalNewFolderContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [currentSafrasVinculadas, setCurrentSafrasVinculadas] = useState<
    Safra[]
  >([]);
  const [currentUnidadesVinculada, setCurrentUnidadesVinculadas] = useState<
    Unidade[]
  >([]);
  const [currentCulturaVinculada, setCurrentCulturaVinculada] = useState<
    Cultura[]
  >([]);
  const [currentSetoresVinculadas, setCurrentSetoresVinculados] = useState<
    Setor[]
  >([]);
  const [currentTalhoesVinculados, setCurrentTalhoesVinculados] = useState<
    Talhao[]
  >([]);
  const [tagsPersonalizadasVinculadas, setTagsPersonalizadasVinculadas] =
    useState<CustomTag[]>([]);

  const getTags = (): string[] => {
    let tagsNames: string[] = [];

    currentSafrasVinculadas.forEach((tag) => tagsNames.push(tag.descricao));
    currentUnidadesVinculada.forEach((tag) => tagsNames.push(tag.nome));
    currentCulturaVinculada.forEach((tag) => tagsNames.push(tag.nome));
    currentSetoresVinculadas.forEach((tag) => tagsNames.push(tag.nome));
    currentTalhoesVinculados.forEach((tag) => tagsNames.push(tag.nome));
    tagsPersonalizadasVinculadas.forEach((tag) => tagsNames.push(tag.nome));
    return tagsNames;
  };

  const after = {
    async onSuccess() {
      await showSuccess();
    },
    onError() {
      showError();
    },
  };

  return (
    <ModalNewFolderContext.Provider
      value={{
        items: {
          currentSafrasVinculadas,
          currentUnidadesVinculada,
          currentCulturaVinculada,
          currentSetoresVinculadas,
          currentTalhoesVinculados,
          tagsPersonalizadasVinculadas,
          // tagsVinculadas,
        },
        onChange: {
          setCurrentSafrasVinculadas,
          setCurrentUnidadesVinculadas,
          setCurrentCulturaVinculada,
          setCurrentSetoresVinculados,
          setCurrentTalhoesVinculados,
          setTagsPersonalizadasVinculadas,
          // setTagsVinculadas,
        },
        after,
        getTags,
      }}
    >
      {children}
    </ModalNewFolderContext.Provider>
  );
};
