import { useCallback, useContext, useState } from "react";
import { Flex, Text, Box, useDisclosure, Button } from "@chakra-ui/react";
import DatatableAntd from "components/datatable-antd";
import { ImportTalhoesContext } from "core/features/talhoes/contexts/ImportTalhoes.context";
import { ArquivosProps } from "core/features/talhoes/typings/validacaoKML";
import { selectTalhoesTableColumns } from "./utils";
import DetailsModal from "./Modals/DetailsModal";
import UpdateConfirmationModal from "./Modals/UpdateConfirmationModal";
import "./table.css";

const SelectTalhoes = () => {
  const {
    kmlValidationRet,
    talhoesNaoMapeados,
    handleChangeArquivoAtual,
    arquivos,
    talhoesMapeados,
    talhoesToimport,
    setTalhoesToImport,
    handleImportTalhoes,
    setStep,
  } = useContext(ImportTalhoesContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isModalConfirmationOpen,
    onOpen: onOpenModalConfirmation,
    onClose: onCloseModalConfirmation,
  } = useDisclosure();
  const [rowSelected, setRowSelected] = useState<ArquivosProps>();

  const handleOpenModal = (row: ArquivosProps) => {
    setRowSelected(row);

    onOpen();
  };

  return (
    <Flex
      width="100vw"
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box width="50vw" marginRight="85px" height="100%">
        <Text
          color="#676C77"
          fontWeight="medium"
          fontSize="16px"
          marginBottom="16px"
        >
          Selecione os talhões a serem atualizados
        </Text>
        <Box
          height="100%"
          border="1px solid #70707080"
          borderTopWidth="1.4px"
          borderRadius="6px"
          overflow="hidden"
          boxShadow="0px 4px 4px #70707060"
        >
          <DatatableAntd
            columns={selectTalhoesTableColumns({
              handleOpenModal,
              handleSelectTalhaoAtual: handleChangeArquivoAtual,
              talhoesNaoMapeados: talhoesNaoMapeados,
              talhoesMapeados,
              talhoesToImport: talhoesToimport,
              setTalhoesToImport,
            })}
            dataSource={useCallback(() => {
              return arquivos;
            }, [arquivos])()}
            scroll={{ y: "calc(100vh - 600px)" }}
          />
          <Flex
            background="#F5F1F14D"
            height="39px"
            width="100%"
            borderTop="1px solid #70707080"
            justifyContent="space-between"
            alignItems="center"
            paddingX="32px"
          >
            <Text color="#525252" fontWeight="medium">
              Talhões no arquivo: {kmlValidationRet?.arquivo.length}
            </Text>
            <Text color="#525252" fontWeight="medium">
              Selecionados: {talhoesToimport.length}{" "}
            </Text>
          </Flex>
        </Box>
      </Box>
      <Flex
        flex={1}
        width="50vw"
        marginLeft={"-83px"}
        justifyContent="flex-end"
        marginTop="26px"
      >
        <Button
          width="135px"
          height="42px"
          background="none"
          fontSize="16px"
          fontWeight="medium"
          color="#707070"
          _hover={{}}
          onClick={() => setStep(1)}
        >
          Voltar
        </Button>
        <Button
          width="135px"
          height="42px"
          background="#11A557"
          color="white"
          fontSize="16px"
          fontWeight="medium"
          isDisabled={talhoesToimport.length === 0}
          onClick={onOpenModalConfirmation}
        >
          Avançar
        </Button>
      </Flex>
      <DetailsModal isOpen={isOpen} onClose={onClose} arquivo={rowSelected} />
      <UpdateConfirmationModal
        isOpen={isModalConfirmationOpen}
        onClose={onCloseModalConfirmation}
        submit={handleImportTalhoes}
      />
    </Flex>
  );
};

export default SelectTalhoes;
