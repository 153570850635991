import { DesktopDateRangePicker } from "@material-ui/pickers";
import { green700 } from "core/resources/theme/colors";
import styled from "styled-components";

export const Container = styled.div``;

export const Calendar = styled(DesktopDateRangePicker)`
  .Mui-selected {
    background: ${green700} !important;
  }
  .MuiPickersDateRangeDay-rangeIntervalDayHighlight {
    background: ${green700}60;
  }
`;
