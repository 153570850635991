import { Box } from "@chakra-ui/layout";

function Container({ children }: { children: any }) {
  return (
    <Box maxW="1200px" w="100%" mx="auto">
      {children}
    </Box>
  );
}

export default Container;
