import {
  Box,
  Button,
  Flex,
  Text,
  Image,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { MdPerson } from "react-icons/md";
import { gray500, green700 } from "core/resources/theme/colors";
import { useAuth } from "core/features/auth/store";
import { FaCheck } from "react-icons/fa";
import { AiOutlineCamera } from "react-icons/ai";
import {
  updateProfileAvatar,
  updateProfileName,
} from "core/features/auth/services";
import { useHistory } from "react-router";

const ChangeProfile = () => {
  const { user, updateField } = useAuth();
  const { goBack } = useHistory();

  const [name, setName] = useState(user?.userName);
  const [imagePreview, setImagePreview] = useState(user?.avatarUrl);
  const [image, setImage] = useState<Blob | File | string | undefined>(
    undefined
  );

  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const isEnabledSave =
    (name && name !== user?.userName) || image ? true : false;

  const handleSaveUserName = () => {
    if (name && name !== user?.userName) {
      const response = updateProfileName(name);
      if (!response) {
        return toast({
          position: "top",
          duration: 2000,
          title: `Falha ao atualizar o nome do usuario`,
          status: "error",
        });
      }
      updateField("userName", name);
      return response;
    }
  };

  const handleSaveUserAvatar = async (
    data: Blob | File | string | undefined
  ) => {
    if (data) {
      const formData = new FormData();
      formData.append("file", data, "userAvatar");
      const urlNewPhoto = await updateProfileAvatar(formData);
      if (!urlNewPhoto) {
        return toast({
          position: "top",
          duration: 2000,
          title: `Falha ao atualizar foto de perfil`,
          status: "error",
        });
      }
      updateField("avatarUrl", urlNewPhoto);
      setImage(undefined);
      return urlNewPhoto;
    }
  };

  const handleSave = async () => {
    setLoading(true);
    const userName = await handleSaveUserName();
    const avatar = await handleSaveUserAvatar(image);
    setLoading(false);
    if (avatar || userName) {
      toast({
        position: "top",
        duration: 2000,
        title: `Perfil atualizado com sucesso`,
        status: "success",
      });
    }
  };

  return (
    <Flex
      backgroundColor="white"
      flex="1"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        background="white"
        width="700px"
        borderRadius="20px"
        position="relative"
        height="360px"
        boxShadow="0px -0.3px 16px rgba(0,0,0,.3)"
      >
        <Flex
          position="absolute"
          w="95%"
          top="8px"
          right="2px"
          background="none"
          flex={1}
        >
          {/* <Button
            data-testid="talhao-modal-close-button"
            // onClick={handleClose}
            background="none"
            ml="auto"
          >
            <MdClose size={27} color={gray500} />
          </Button> */}
        </Flex>
        <Flex
          w="90%"
          h="30px"
          pb="18px"
          borderBottomWidth={1}
          borderBottomColor="gray.400"
          ml={8}
          mt={12}
          flexDir="row"
          alignItems="center"
        >
          <MdPerson color={green700} size={27} />
          <Text mt={1} color={green700} fontWeight="bold" fontSize={16} ml={2}>
            Meu Perfil
          </Text>
        </Flex>
        <Flex
          borderBottomWidth={1}
          borderBottomColor="gray.400"
          h="180px"
          w="90%"
          ml={8}
          mt={8}
          alignItems="flex-start"
          flexDir="row"
        >
          <Image rounded={70} w={140} h={140} src={imagePreview} />
          <Input
            position="absolute"
            accept="image/*"
            width="40px"
            justifyContent="center"
            alignItems="center"
            height="40px"
            rounded="full"
            type="file"
            left={32}
            top={36}
            multiple={false}
            mt={14}
            ref={inputRef}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.files?.length) {
                setImage(event.target?.files[0]);
                setImagePreview(URL.createObjectURL(event.target?.files[0]));
              }
            }}
            ml={4}
          />
          <Flex
            position="absolute"
            width="50px"
            justifyContent="center"
            alignItems="center"
            height="50px"
            left={32}
            top={36}
            mt={12}
            ml={2}
            rounded="full"
            bg="green.500"
            cursor="pointer"
            onClick={() => inputRef?.current?.click()}
          >
            <AiOutlineCamera color="white" size={26} />
          </Flex>
          <Flex mt={6} ml={6} flex={1} borderColor="gray.400" flexDir="column">
            <Input
              maxLength={30}
              w="90%"
              value={name}
              onChange={handleChange}
            />
            <Input mt={6} w="90%" value={user?.email} disabled />
          </Flex>
        </Flex>
        <Flex mr={8} mt={4} justifyContent="flex-end" flexDir="row" flex={1}>
          <Button
            aria-label="cancelar"
            colorScheme="transparent"
            fontWeight="medium"
            height="40px"
            width="140px"
            mr={4}
            onClick={goBack}
            disabled={loading}
          >
            <Text color="gray.600">Cancelar</Text>
          </Button>
          <Button
            aria-label="salvar"
            colorScheme="green"
            fontWeight="medium"
            borderRadius="8px"
            height="40px"
            width="140px"
            onClick={handleSave}
            isLoading={loading}
            _hover={
              !isEnabledSave || loading
                ? { backgroundColor: gray500 }
                : { backgroundColor: green700 }
            }
            _disabled={{ backgroundColor: gray500, cursor: "auto" }}
            disabled={!isEnabledSave || loading}
          >
            <FaCheck style={{ marginRight: "10px" }} />
            Salvar
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default ChangeProfile;
