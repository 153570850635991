import {
  Configuration,
  EventType,
  PublicClientApplication,
  AuthenticationResult,
  EventMessage,
  Logger,
  LogLevel,
} from "@azure/msal-browser";
import config from "infra/config";

const clientId =
  localStorage.getItem("@ad-client-id") || config.azure.amaggi.clientId;
const authority =
  localStorage.getItem("@ad-authority") || config.azure.amaggi.authority;

export const azureScopes = (clientId: string) => [
  "profile",
  "openid",
  `api://${clientId}/access_as_user`,
];

const configuration: Configuration = {
  auth: {
    clientId,
    authority,
    redirectUri: window.location.href,
    postLogoutRedirectUri: window.location.href,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export let msalInstance = new PublicClientApplication(configuration);

export enum TypeAD {
  Nuvem = "nuvem",
  Amaggi = "amaggi",
}

export const KEY_AUTH_TENANT = "@ad-tenant";

export const setInstance = (type: TypeAD): PublicClientApplication => {
  const clientId = config.azure[type].clientId;
  const authority = config.azure[type].authority;

  localStorage.setItem(KEY_AUTH_TENANT, type);
  localStorage.setItem("@ad-client-id", clientId);
  localStorage.setItem("@ad-authority", authority);

  msalInstance = new PublicClientApplication({
    ...configuration,
    auth: {
      ...configuration.auth,
      clientId,
      authority,
    },
  });

  return msalInstance;
};

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

msalInstance.setLogger(
  new Logger({
    logLevel: LogLevel.Verbose,
    piiLoggingEnabled: false,
    loggerCallback: (level, message, containsPii) => {
      if (containsPii) {
        return;
      }
      switch (level) {
        case LogLevel.Error:
          console.error(message);
          return;
        case LogLevel.Warning:
          console.warn(message);
          return;
      }
    },
  })
);

export default msalInstance;
