import { Configuration, PopupRequest } from "@azure/msal-browser"

import config from "infra/config"

const msalConfig = {
    auth: {
        redirectUri: window.location.href,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    }
}

// Configurações de acesso para Amaggi.
export const msalAmaggiConfig: Configuration = {
    auth: {
        ...msalConfig.auth,
        clientId: config.azure.amaggi.clientId,
        authority: config.azure.amaggi.authority,
    },
    cache: msalConfig.cache
}

export const loginAmaggiRequest: PopupRequest = {
    scopes: [
        "profile",
        "openid",
        `api://${config.azure.amaggi.clientId}/access_as_user`,
    ],
    redirectUri: window.location.origin
}

// Configurações de acesso para Nuvem.
export const msalNuvemConfig: Configuration = {
    auth: { 
        ...msalConfig.auth,
        clientId: config.azure.nuvem.clientId,
        authority: config.azure.nuvem.authority,
    },
    cache: msalConfig.cache
}

export const loginNuvemRequest: PopupRequest = {
    scopes: [
        "profile",
        "openid",
        `api://${config.azure.nuvem.clientId}/access_as_user`,
    ],
    redirectUri: window.location.origin
}