import { StatusOrdemServicoEnum } from "components/maps/mapa-geral/components/TabOrdensServico/ActionButtons";
import { useFilters } from "core/features/filters/store";
import React, { createContext, useState } from "react";
import { ModalTalhaoIndex } from "../enums";
import { TipoMapaTalhoes } from "../typings";

interface TalhaoDetailContextProviderProps {
  children: React.ReactNode;
  defaultIndex: ModalTalhaoIndex;
}

interface TalhaoDetailContextProps {
  tabsIndex: ModalTalhaoIndex;
  handleChangeTabIndex: (index: ModalTalhaoIndex) => void;
  handleChangeOrdensDeServicoFilterIndex: (index: number) => void;
  ordensDeServicoFilterIndex: StatusOrdemServicoEnum;
  ordensDeServicoNumeroOs: number | undefined;
  handleOrdensDeServicoNumeroOs: (numberoOS: number | undefined) => void;
}

export const TalhaoDetailContext = createContext<TalhaoDetailContextProps>({
  tabsIndex: ModalTalhaoIndex.Detalhes,
  ordensDeServicoFilterIndex: StatusOrdemServicoEnum.Todas,
  handleChangeTabIndex: () => {},
  handleChangeOrdensDeServicoFilterIndex: () => {},
  ordensDeServicoNumeroOs: undefined,
  handleOrdensDeServicoNumeroOs: (number: number | undefined) => {},
});

export const TalhaoDetailContextProvider = ({
  children,
  defaultIndex,
}: TalhaoDetailContextProviderProps) => {
  const [tabsIndex, setTabsIndex] = useState<ModalTalhaoIndex>(defaultIndex);
  const {
    filters: { tipoMapa },
  } = useFilters();
  const [ordensDeServicoFilterIndex, setOrdensDeServicoFilterIndex] =
    useState<StatusOrdemServicoEnum>(
      tipoMapa === TipoMapaTalhoes.OrdensDeServicosAberta
        ? StatusOrdemServicoEnum.Abertas
        : StatusOrdemServicoEnum.Todas
    );
  const [ordensDeServicoNumeroOs, setOrdensDeServicoNumeroOs] = useState<
    number | undefined
  >();

  const handleChangeOrdensDeServicoFilterIndex = (
    index: StatusOrdemServicoEnum
  ) => {
    setOrdensDeServicoFilterIndex(index);
  };

  return (
    <TalhaoDetailContext.Provider
      value={{
        tabsIndex,
        ordensDeServicoFilterIndex,
        ordensDeServicoNumeroOs,
        handleChangeTabIndex: setTabsIndex,
        handleChangeOrdensDeServicoFilterIndex,
        handleOrdensDeServicoNumeroOs: setOrdensDeServicoNumeroOs,
      }}
    >
      {children}
    </TalhaoDetailContext.Provider>
  );
};
