import { Flex } from "@chakra-ui/react";
import { green700, red100 } from "core/resources/theme/colors";

type BadgeStatusProps = {
  status: "F" | "A";
};

export enum StatusOrdenServico {
  ABERTA = "A",
  FECHADA = "F",
}

export const BadgeStatus = ({ status }: BadgeStatusProps) => {
  if (status === StatusOrdenServico.FECHADA) {
    return (
      <Flex
        w={"60px"}
        h={"25px"}
        justifyContent="center"
        alignItems="center"
        fontSize={13}
        rounded={"30px"}
        color="white"
        bg={red100}
        mt={2}
        // py={2}
      >
        Fechado
      </Flex>
    );
  }
  return (
    <Flex
      w={"50px"}
      h={"25px"}
      justifyContent="center"
      alignItems="center"
      rounded={8}
      fontSize={13}
      color="white"
      bg={green700}
      mt={2}
    >
      Aberto
    </Flex>
  );
};
