import { ENDPOINTS, get } from "infra/http/farmApi";
import { UseInfiniteQueryOptions, useInfiniteQuery } from "react-query";

const placeholderData: any = {
    pages: [{
        data: [{ ndvi_uri: 1 }, { ndvi_uri: 2 }, { ndvi_uri: 3 }, { ndvi_uri: 4 }, { ndvi_uri: 5 }, { ndvi_uri: 6 }, { ndvi_uri: 7 }, { ndvi_uri: 8 }, { ndvi_uri: 9 }],
        next_page: 1,
    }]
};

export default function useFarmMosaics(farmId: string | number, params: any = {}, options: UseInfiniteQueryOptions = {}) {
    const queryOptions: UseInfiniteQueryOptions = {
        ...options,
        getNextPageParam: (lastPage) => (lastPage as any)?.next_page,
        placeholderData: placeholderData,
    }
    
    const endpoint = ENDPOINTS.FARM_MOSAICS
        .replace(/:farm_id/gi, farmId.toString());
    
    return useInfiniteQuery<any>(
        [ENDPOINTS.FARM_MOSAICS, farmId, params],
        ({pageParam = 1}) => get(endpoint, {...params, page: pageParam}),
        queryOptions
    );
}
