import { useEffect } from "react";
import { useFilters } from "core/features/filters/store";
import { useFiltersLists } from "core/features/filters/store/lists";

export function useMapaDeChuvaIndices() {
  const { mapaDeChuvas, loadings } = useFiltersLists();
  const { filters, updateFiltroMapaDeChuvas } = useFilters();
  const { filtroMapaDeChuva } = filters;

  function handleSelectPluviometrico(code: number) {
    updateFiltroMapaDeChuvas(
      {
        indicesPluviometrico: filtroMapaDeChuva?.indicesPluviometrico
          ? [...filtroMapaDeChuva?.indicesPluviometrico, code]
          : [code],
      },
      true
    );
  }

  function handleRemovePluviometrico(code: number) {
    updateFiltroMapaDeChuvas(
      {
        indicesPluviometrico: filtroMapaDeChuva?.indicesPluviometrico.filter(
          (pluviometrico) => pluviometrico !== code
        ),
      },
      true
    );
  }

  function onChange(pluviometricoCodigo: number) {
    if (
      filtroMapaDeChuva?.indicesPluviometrico?.includes(pluviometricoCodigo)
    ) {
      handleRemovePluviometrico(pluviometricoCodigo);
    } else {
      handleSelectPluviometrico(pluviometricoCodigo);
    }
  }

  function handleClear() {
    updateFiltroMapaDeChuvas(
      {
        indicesPluviometrico: [],
      },
      true
    );
  }

  function handleSelectAll() {
    updateFiltroMapaDeChuvas(
      {
        indicesPluviometrico: mapaDeChuvas?.indices.map(
          (item) => item.indicePluviometrico
        ),
      },
      true
    );
  }

  useEffect(() => {
    if (mapaDeChuvas?.indices?.length && filtroMapaDeChuva?.periodo !== 6) {
      updateFiltroMapaDeChuvas(
        {
          indicesPluviometrico: mapaDeChuvas.indices.map(
            (indicePluviometrico) => indicePluviometrico.indicePluviometrico
          ),
        },
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapaDeChuvas?.indices, filtroMapaDeChuva?.periodo]);

  return {
    items: mapaDeChuvas?.indices!,
    value: filtroMapaDeChuva?.indicesPluviometrico || [],
    onChange,
    loading: loadings.mapaDeChuvas,
    onClear: handleClear,
    onSelectAll: handleSelectAll,
  };
}
