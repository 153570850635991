import "infra/boot";

import { AuthProvider, Authenticated, Unauthenticated } from "infra/auth";

import AntdProvider from "infra/packages/antd";
import ChakraUiProvider from "infra/packages/@chakra-ui";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { EnvProvider } from "infra/env/provider";
import ErrorBoundary from "./ui/components/ErrorBoundaries";
import LoadingBar from "react-top-loading-bar";
import { LocalizationProvider } from "@material-ui/pickers";
import LoginPage from "ui/pages/auth/login";
import PrecipitacaoPage from "ui/pages/app/precipitacao";
import Pages from "./ui/pages";
import { ReactQueryProvider } from "infra/packages/react-query";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { primary } from "core/resources/theme/colors";
import ptBR from "date-fns/locale/pt-BR";
import { useGlobalLoading } from "core/features/global-loading";
import SideBar from "components/sideBar";
import { Flex } from "@chakra-ui/react";
//PrimeReact
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primereact/resources/primereact.css"; // core css
import "primeicons/primeicons.css";
import "./ui/pages/app/arquivos/agrodrive/components/ModalNewFile/FileUploadPrime/styles/index.css";

function App() {
  return (
    <LocalizationProvider dateAdapter={DateFnsUtils} locale={ptBR}>
      <AuthProvider>
        <ChakraUiProvider>
          <ErrorBoundary>
            <AntdProvider>
              <Unauthenticated>
                <UnauthenticatedPage />
              </Unauthenticated>
              <Authenticated>
                <AuthenticatedPage />
              </Authenticated>
            </AntdProvider>
          </ErrorBoundary>
        </ChakraUiProvider>
      </AuthProvider>
    </LocalizationProvider>
  );
}

function AuthenticatedPage() {
  const { isLoading } = useGlobalLoading();
  const url = window.location.href;
  const isPrecipitacaoPage = url.includes("precipitacao");

  return (
    <ReactQueryProvider>
      <EnvProvider>
        <Router>
          {isLoading && <LoadingBar progress={60} color={primary} />}
          {isPrecipitacaoPage ? (
            <PrecipitacaoPage />
          ) : (
            <Flex>
              <SideBar />
              <Pages />
            </Flex>
          )}
        </Router>
      </EnvProvider>
    </ReactQueryProvider>
  );
}

function UnauthenticatedPage() {
  return (
    <ReactQueryProvider>
      <Router>
        <Route exact path="/">
          <LoginPage />
        </Route>
        <Route exact path="/precipitacao">
          <PrecipitacaoPage />
        </Route>
      </Router>
    </ReactQueryProvider>
  );
}

export default App;
