import React from "react";
import { Box, Button, Flex, Input } from "@chakra-ui/react";
import { FaPencilAlt, FaPlus, FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";

const focusButtonStyles = {
  border: "1px solid #707070",
};

const focusInputStyles = {
  borderColor: "#707070",
};

interface ActionsButtonsUsersModalProps {
  hasSelection: boolean;
  handleEdit: () => void;
  handleNewUser: () => void;
  searchValue: string;
  handleChangeSearch: (letter: string) => void;
  resetInput: () => void;
}

const ActionsButtonsUsersModal = ({
  hasSelection,
  handleEdit,
  handleNewUser,
  handleChangeSearch,
  searchValue,
  resetInput,
}: ActionsButtonsUsersModalProps) => {
  return (
    <Flex marginBottom="20px">
      <Button
        _focus={{ border: "none" }}
        background="green.700"
        color="white"
        fontWeight={"medium"}
        borderRadius="5px"
        marginRight={"15px"}
        onClick={handleNewUser}
      >
        <FaPlus size="14px" style={{ marginRight: "10px" }} />
        Novo Usuário
      </Button>
      <Button
        _focus={focusButtonStyles}
        _hover={{
          background: "none",
        }}
        borderRadius="5px"
        variant="outline"
        borderColor={"gray.600"}
        disabled={!hasSelection}
        fontWeight="medium"
        onClick={handleEdit}
      >
        <FaPencilAlt size="14px" style={{ marginRight: "10px" }} />
        Editar
      </Button>

      <Flex flex="1" justifyContent={"flex-end"} alignItems="flex-end">
        <Box
          display="flex"
          flexDirection="row"
          borderColor={"gray.400"}
          borderRadius="32px"
          border="1px solid"
          width="35%"
          alignItems={"center"}
          paddingRight="10px"
        >
          <Input
            border={"none"}
            height="33px"
            // maxWidth={"261px"}
            color="gray.800"
            placeholder="Pesquisar por usuário"
            _focus={focusInputStyles}
            value={searchValue}
            onChange={({ target }) => handleChangeSearch(target.value)}
          />
          <Button
            size="sm"
            background="none"
            _hover={{ background: "none" }}
            _active={{ background: "none", border: "none" }}
            _focus={{ border: "none" }}
          >
            {searchValue === "" ? (
              <FaSearch size="18px" />
            ) : (
              <MdClose size="26px" onClick={resetInput} />
            )}
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};
export default ActionsButtonsUsersModal;
