import { Box, Flex, Text } from "@chakra-ui/react";
import { fallbackText, numberWithCommas } from "ui/utils";
import { PropertiesProps } from "../../typings";
import { Skeleton } from "@chakra-ui/skeleton";
import { useMenuFilterIndex } from "core/features/filters/hooks/useMenuFilterIndex";
import { TipoMapaTalhoes } from "core/features/maps/typings";
import { getTipoMapaForIndexOpen } from "ui/pages/app/home/util";

interface ProductivityAndAreaProps extends PropertiesProps {
  isLoading: boolean;
}

const ProductivityAndArea = ({
  Properties,
  isLoading,
}: ProductivityAndAreaProps) => {
  const { indexOpen } = useMenuFilterIndex();
  const tipoMapa = getTipoMapaForIndexOpen(indexOpen);

  const url = window.location.href;
  const isPrecipitacaoPage = url.includes("precipitacao");

  const mapToHide =
    tipoMapa === TipoMapaTalhoes.DistribuicaoVarietal ||
    TipoMapaTalhoes.OrdensDeServicosAberta;

  if (
    mapToHide &&
    !Properties?.ProdutividadeMedia &&
    Properties?.totalAreaHa === 0
  ) {
    return null;
  }

  if (
    (tipoMapa === TipoMapaTalhoes.OperacoesExecutadas ||
      tipoMapa === TipoMapaTalhoes.Produtividade) &&
    !Properties
  ) {
    return null;
  }

  if (tipoMapa === TipoMapaTalhoes.Texturas && Properties?.totalAreaHa === 0) {
    return null;
  }

  if (
    tipoMapa === TipoMapaTalhoes.IdadeDoTalhao &&
    Properties?.totalAreaHa === 0
  ) {
    return null;
  }

  if (
    (tipoMapa === TipoMapaTalhoes.ConcentracaoColheita &&
      Properties?.totalAreaHa === 0) ||
    !Properties
  ) {
    return null;
  }

  if (isLoading) {
    return (
      <>
        <Flex
          pos={"fixed"}
          left="32px"
          right="32px"
          bottom={"20px"}
          alignItems="center"
          justifyContent={"flex-end"}
          paddingRight="5%"
          paddingBottom={"5px"}
        >
          <Skeleton
            w={
              tipoMapa === TipoMapaTalhoes.DistribuicaoVarietal ? "30%" : "23%"
            }
            h={30}
            padding={5}
            rounded="xl"
          />
        </Flex>
      </>
    );
  }

  if (
    tipoMapa === TipoMapaTalhoes.DistribuicaoVarietal ||
    tipoMapa === TipoMapaTalhoes.OperacoesExecutadas ||
    tipoMapa === TipoMapaTalhoes.Produtividade ||
    tipoMapa === TipoMapaTalhoes.OrdensDeServicosAberta ||
    tipoMapa === TipoMapaTalhoes.DistribuicaoDeEquipes ||
    tipoMapa === TipoMapaTalhoes.Chuvas
  ) {
    return (
      <Flex
        pos={"fixed"}
        left="16px"
        right="64px"
        bottom={"20px"}
        alignItems="center"
        justifyContent={"flex-end"}
        paddingBottom={"5px"}
      >
        <Box
          flexDirection="row"
          background={"#fff"}
          boxShadow="dark-lg"
          padding="10px"
          borderRadius={"10px"}
        >
          <Flex gridGap={2}>
            <Text color="gray.800" fontWeight="bold">
              Área total selecionada:{" "}
            </Text>
            <Text color="gray.800" fontWeight="normal">
              {Properties?.totalAreaHa
                ? `${numberWithCommas(Properties.totalAreaHa)} ha`
                : fallbackText}
            </Text>
            {tipoMapa !== TipoMapaTalhoes.Chuvas && (
              <>
                <Text color="gray.800" fontWeight="bold">
                  Produtividade média:{" "}
                </Text>
                <Text color="gray.800" fontWeight="normal">
                  {" "}
                  {Properties?.ProdutividadeMedia || fallbackText}
                </Text>
              </>
            )}
          </Flex>
        </Box>
      </Flex>
    );
  }

  if (
    tipoMapa === TipoMapaTalhoes.Texturas ||
    tipoMapa === TipoMapaTalhoes.NumeroDeAplicacoes ||
    tipoMapa === TipoMapaTalhoes.ConcentracaoColheita ||
    tipoMapa === TipoMapaTalhoes.IdadeDoTalhao
  ) {
    return (
      <Flex
        pos={"fixed"}
        left="16px"
        right="64px"
        bottom={"20px"}
        alignItems="center"
        justifyContent={"flex-end"}
        paddingBottom={"5px"}
      >
        <Box
          flexDirection="row"
          background={isPrecipitacaoPage ? "" : "#fff"}
          boxShadow="dark-lg"
          padding="10px"
          borderRadius={"10px"}
        >
          <Flex gridGap={6}>
            {!isPrecipitacaoPage && (
              <Flex>
                  <Text color="gray.800" fontWeight="bold">
                    Área total selecionada:&nbsp; {""}
                  </Text>
                <Text color="gray.800" fontWeight="normal">
                  {Properties?.totalAreaHa
                    ? `${numberWithCommas(Properties.totalAreaHa)} ha`
                    : fallbackText}
                </Text>
              </Flex>
            )}
            <Flex>
              {Properties?.ProdutividadeMedia ? (
                <Flex>
                  <Text color="gray.800" fontWeight="bold">
                    Produtividade média:&nbsp;{" "}
                  </Text>
                  <Text color="gray.800" fontWeight="normal">
                    {Properties.ProdutividadeMedia}
                  </Text>
                </Flex>
              ) : null}
            </Flex>
          </Flex>
        </Box>
      </Flex>
    );
  }

  return null;
};

export default ProductivityAndArea;
