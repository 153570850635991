import { AxiosError } from "axios";
import { useLogout } from "features/auth/hooks/useLogout";
import { primary, white } from "core/resources/theme/colors";
import URL from "core/resources/urls";
import { showConfirm } from "infra/helpers/alerta";
import http from "infra/http";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { SharedResponse } from "../typings";

export function useShare(id: string) {
  const { handleLogout } = useLogout();
  const history = useHistory();

  return useQuery(
    URL.SHARE.OBTER(id),
    async () => {
      const { data } = await http.get<SharedResponse>(URL.SHARE.OBTER(id));

      return data;
    },
    {
      enabled: !!id,
      onError: async (error) => {
        const err: AxiosError = error as any;

        if (err.response?.status === 403) {
          const { isConfirmed } = await showConfirm(
            (err.response.data as any)?.Details[0]?.Errors[0],
            {
              title: "Acesso não permitido",
              icon: "error",
              cancelButtonText: "Acessar com outro usuário",
              cancelButtonColor: white,
              confirmButtonText: "Retornar para a tela principal",
              confirmButtonColor: primary,
              customClass: {
                cancelButton: "swal2-cancel-white",
                confirmButton: "swal2-cancel-primary",
              },
            }
          );

          if (isConfirmed) {
            history.push("/");
          } else {
            await handleLogout();
          }
        }
      },
    }
  );
}
