import { Badge, Button } from "@chakra-ui/react";
import { gray500, green700 } from "core/resources/theme/colors";

export enum StatusOrdemServicoEnum {
  Todas = 0,
  Abertas = 1,
  Fechadas = 2,
}
interface ActionButtonProps {
  selectedStatus: StatusOrdemServicoEnum;
  onChangeStatus: (status: StatusOrdemServicoEnum) => void;
  selectedNumber?: number;
}

const ButtonBadge = ({ selectedNumber }: { selectedNumber: number }) => (
  <Badge
    data-testid="selected-button-number-badge"
    rounded="full"
    bg="white"
    borderWidth={1}
    borderColor={green700}
    color={green700}
    position="absolute"
    top={"-12px"}
    right={"4px"}
    fontSize={14}
    px={"4px"}
    py={"2px"}
  >
    {selectedNumber}
  </Badge>
);

export const ActionButtons = ({
  selectedStatus,
  onChangeStatus,
  selectedNumber,
}: ActionButtonProps) => {
  return (
    <>
      <Button
        data-testid="ordem-servico-status-todas"
        fontSize={16}
        _hover={{
          opacity: 0.73,
        }}
        fontWeight={
          selectedStatus === StatusOrdemServicoEnum.Todas ? "bold" : "normal"
        }
        ml={4}
        onClick={() => onChangeStatus(StatusOrdemServicoEnum.Todas)}
        w={"100px"}
        borderWidth={1}
        borderColor={
          selectedStatus === StatusOrdemServicoEnum.Todas ? green700 : gray500
        }
        bg={
          selectedStatus === StatusOrdemServicoEnum.Todas ? green700 : "white"
        }
        rounded={20}
        color={
          selectedStatus === StatusOrdemServicoEnum.Todas ? "white" : gray500
        }
      >
        Todas
        {selectedStatus === StatusOrdemServicoEnum.Todas &&
        selectedNumber !== undefined ? (
          <ButtonBadge selectedNumber={selectedNumber} />
        ) : (
          ""
        )}
      </Button>
      <Button
        data-testid="ordem-servico-status-abertas"
        fontSize={16}
        _hover={{
          opacity: 0.73,
        }}
        fontWeight={
          selectedStatus === StatusOrdemServicoEnum.Abertas ? "bold" : "normal"
        }
        ml={2}
        w={"100px"}
        onClick={() => onChangeStatus(StatusOrdemServicoEnum.Abertas)}
        borderWidth={1}
        borderColor={
          selectedStatus === StatusOrdemServicoEnum.Abertas ? green700 : gray500
        }
        bg={
          selectedStatus === StatusOrdemServicoEnum.Abertas ? green700 : "white"
        }
        rounded={20}
        color={
          selectedStatus === StatusOrdemServicoEnum.Abertas ? "white" : gray500
        }
      >
        Abertas
        {selectedStatus === StatusOrdemServicoEnum.Abertas &&
        selectedNumber !== undefined ? (
          <ButtonBadge selectedNumber={selectedNumber} />
        ) : (
          ""
        )}
      </Button>
      <Button
        data-testid="ordem-servico-status-fechadas"
        fontSize={16}
        _hover={{
          opacity: 0.73,
        }}
        fontWeight={
          selectedStatus === StatusOrdemServicoEnum.Fechadas ? "bold" : "normal"
        }
        ml={2}
        w={"100px"}
        onClick={() => onChangeStatus(StatusOrdemServicoEnum.Fechadas)}
        borderWidth={1}
        bg={
          selectedStatus === StatusOrdemServicoEnum.Fechadas
            ? green700
            : "white"
        }
        borderColor={
          selectedStatus === StatusOrdemServicoEnum.Fechadas
            ? green700
            : gray500
        }
        rounded={20}
        color={
          selectedStatus === StatusOrdemServicoEnum.Fechadas ? "white" : gray500
        }
      >
        Fechadas
        {selectedStatus === StatusOrdemServicoEnum.Fechadas &&
        selectedNumber !== undefined ? (
          <ButtonBadge selectedNumber={selectedNumber} />
        ) : (
          ""
        )}
      </Button>
    </>
  );
};
