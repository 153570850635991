import { useFilters } from "core/features/filters/store";
import { useFiltersLists } from "core/features/filters/store/lists";
import { useEffect } from "react";

export function useMapaDeChuvaPeriodos() {
  const { mapaDeChuvas, loadings } = useFiltersLists();
  const { filters, updateFiltroMapaDeChuvas } = useFilters();
  const { filtroMapaDeChuva } = filters;

  const onChange = (periodoNumber: number) => {
    updateFiltroMapaDeChuvas(
      {
        ...filtroMapaDeChuva,
        periodo: periodoNumber,
        horaInicial: undefined,
        horaFinal: undefined,
        dataInicial: undefined,
        dataFinal: undefined,
      },
      true
    );
  };

  useEffect(() => {
    if (!filtroMapaDeChuva?.periodo) {
      updateFiltroMapaDeChuvas(
        {
          periodo: 2,
        },
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapaDeChuvas?.filtroRapido]);

  return {
    items: mapaDeChuvas?.filtroRapido || [],
    onChange,
    value: filtroMapaDeChuva?.periodo,
    loading: loadings.mapaDeChuvas,
  };
}
