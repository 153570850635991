import { TalhaoResumido } from "core/features/maps/typings";
import URL from "core/resources/urls";
import http from "infra/http";
import { useQuery } from "react-query";

export default function useTalhaoResumido(talhaoId: string) {
  return useQuery(
    URL.TALHOES.TALHAO_RESUMIDO(talhaoId),
    async () => {
      const { data } = await http.get<TalhaoResumido>(
        URL.TALHOES.TALHAO_RESUMIDO(talhaoId)
      );

      return data;
    },
    {
      enabled: !!talhaoId,
    }
  );
}
