import create from "zustand";

export type RouteOverlayState = {
    imageId: string | undefined;
    setImageId: (imageId: string | undefined) => void;
    reset: () => void;
}

const useRouteOverlayStore = create<RouteOverlayState>(set => ({
    imageId: undefined,
    setImageId: (imageId: string | undefined) => set((state: RouteOverlayState) => ({ ...state, imageId })),
    reset: () => set(() => ({  
        imageId: undefined
     })),
}));

export default useRouteOverlayStore;
