import msalInstance from "infra/packages/@azure/instance";
import { useCallback } from "react";

export function useLogout() {

  const handleLogout = useCallback(async () => {
    await msalInstance.logoutPopup();

    localStorage.clear();

    window.location.reload();
  }, []);

  return {
    handleLogout,
  };
}
