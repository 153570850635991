import { Box, Text } from "@chakra-ui/react";
import { OcupacaoEnum } from "core/features/maps/enums";
import { FiltroProdutividadeInProdutividade } from "core/features/maps/typings";
import { ProdutividadeData } from "core/features/maps/typings/produtividade";
import { formatMoney } from "infra/helpers/number";

import { fallbackText } from "ui/utils";
import { ColumnType } from "antd/lib/table";

export const ProdutividadeTableColoumsTotalItems: ColumnType<ProdutividadeData>[] =
  [
    {
      key: "1",
      title: "PERÍODO",
      dataIndex: "safraPeriodoNome",
      width: 250,
      render: (data: string) => {
        return <Text>{data.toUpperCase()}</Text>;
      },
    },
    {
      key: "2",

      title: "VARIEDADE",
      render: (data: ProdutividadeData) => {
        return <Text>{data.variedade.nome.toUpperCase()}</Text>;
      },
    },
    {
      key: "3",

      title: "ÁREA(HA)",
      dataIndex: "areaHa",
      width: "13%",

      align: "right",
      render: (value) => {
        return value
          ? ` ${formatMoney(value).replace("R$", "")} HA `
          : fallbackText;
      },
    },
    {
      key: "4",

      title: "PROD.",
      align: "right",
      width: 130,
      render: (data: ProdutividadeData) => {
        const formattedValue = Number(
          data.produtividade &&
            data.produtividade &&
            data.produtividade.produtividade
        ).toLocaleString("pt-BR", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
        const formattedUnidadeValue = () => {
          if (Number(data.ocupacao.codigo) === OcupacaoEnum.ALGODAO) {
            return "@/HA";
          }
          return "SC/HA";
        };
        const value = data.produtividade
          ? `${formattedValue} ${formattedUnidadeValue()}`
          : "---";
        return <Box textAlign="right">{value}</Box>;
      },
    },
  ];

export const ProdutividadeTableColoumsDetail: ColumnType<
  ProdutividadeData["elementos"]
>[] = [
  {
    key: "5",
    title: "ELEMENTO",
    dataIndex: "nome",
  },
  {
    key: "6",

    title: "PLANEJADO",
    dataIndex: "planejado",
    width: 100,
    align: "right",
    render: (value) => {
      const valueSplitted = value !== "---" && value.split(" ");

      const newValue =
        typeof valueSplitted[0] === "string" && valueSplitted[0] !== "---"
          ? `${formatMoney(valueSplitted[0]).replace("R$", "")} ${
              valueSplitted[1]
            }`
          : fallbackText;

      return newValue;
    },
  },
  {
    key: "7",

    title: "REALIZADO",
    width: 100,
    align: "right",
    render: (data) => {
      const valueSplitted =
        typeof data.realizado === "string" &&
        data.realizado !== "---" &&
        data.realizado.split(" ");

      const newValue =
        typeof valueSplitted[0] === "string" && valueSplitted[0] !== "---"
          ? `${formatMoney(valueSplitted[0]).replace("R$", "")} ${
              valueSplitted[1]
            }`
          : fallbackText;

      return <Box textAlign="right">{newValue}</Box>;
    },
  },
];

export const getUnidadeDeMedida = (
  rowPeriodoName: string,
  value?: number
): string => {
  const isSoja = rowPeriodoName.includes("Soja");
  const isMilho = rowPeriodoName.includes("Milho");
  const isAlgodao = rowPeriodoName.includes("Algodão");

  if ((isSoja || isMilho) && value) return "sc/ha";
  if (isAlgodao && value) return "@/ha";

  return "";
};

export const checkProdutividadeAtiva = (
  actualProdTooSee: ProdutividadeData | undefined,
  item: ProdutividadeData,
  firstItem: ProdutividadeData,
  produtividades: FiltroProdutividadeInProdutividade[],
  ocupacao: OcupacaoEnum
) => {
  if (+item.ocupacao?.codigo !== ocupacao) {
    return false;
  }
  if (!actualProdTooSee && firstItem === item) {
    return true;
  } else if (actualProdTooSee && actualProdTooSee === item) {
    return true;
  }

  if (!produtividades || !produtividades.length) {
    return false;
  }

  if (!item.produtividade) {
    return false;
  }

  const { produtividade } = item.produtividade;

  const exists = produtividades.filter((prod) => {
    const { min, max } = prod;
    if (!min && max) {
      return produtividade <= max;
    }

    if (min && max) {
      return produtividade >= min && produtividade <= max;
    }

    if (!max && min) {
      return produtividade >= min;
    }
    return false;
  }).length;

  return !!exists;
};
