import { useFilters } from "features/filters/store";

import { useFiltersLists } from "features/filters/store/lists";
import { OrdensDeServicosAbertaFilters } from "features/maps/typings";

const useOrdensDeServicoAbertaPeriodosFilters = () => {
  const { filters, updateField } = useFilters();
  const { ordensDeServicosAbertas } = useFiltersLists();

  const mapperPeriodos =
    ordensDeServicosAbertas?.periodos?.map((item) => ({
      id: item.periodo,
      nome: item.descricao,
      enabled: item.enabled,
    })) ?? [];

  const onChange = (values: Partial<OrdensDeServicosAbertaFilters>) =>
    updateField("filtroOrdensDeServicosAbertas", {
      ...filters.filtroOrdensDeServicosAbertas,
      ...values,
    });

  return {
    mapperPeriodos,
    onChange,
    value: filters.filtroOrdensDeServicosAbertas,
  };
};

export default useOrdensDeServicoAbertaPeriodosFilters;
