import URL from "core/resources/urls";
import { httpAgrodrive } from "infra/http";
import config from "infra/config";
import { useMutation } from "react-query";

export default function useDeleteFiles() {
  return useMutation([URL.AGRODRIVE.ARQUIVOS()], async (ids: string[]) => {
    await httpAgrodrive.delete(URL.AGRODRIVE.ARQUIVOS_DELETE(ids), {
      data: ids,
      headers: {
        "x-api-key": config.agrodriveClientId,
      },
    });
  });
}
