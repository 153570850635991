import { ENDPOINTS, get } from "infra/http/farmApi";
import { UseQueryOptions, useQuery } from "react-query";

export default function useImageRoute(imageId: string | number, params: any = {}, options: UseQueryOptions = {}) {
    const queryOptions: UseQueryOptions = {
        ...options,
    }

    const endpoint = ENDPOINTS.IMAGE_ROUTE.replace(/:image_id/gi, imageId?.toString());

    return useQuery<any>(
        [ENDPOINTS.IMAGE_ROUTE, imageId, params],
        () => get(endpoint, params),
        queryOptions
    )
}
