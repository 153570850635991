import { ENDPOINTS, get } from "infra/http/farmApi";
import { UseInfiniteQueryOptions, useInfiniteQuery } from "react-query";

import { Image } from "core/features/image/typing";

const data: Partial<Image>[] = [
    { image_id: 1 }, { image_id: 2 }, { image_id: 3 }, { image_id: 4 }, { image_id: 5 }
];

const placeholderData: any = {
    pages: [{
        data: data,
        next_page: 1
    }]
};

export default function useImages(farmId: string | number, fieldId: string | number, params: any = {}, options: UseInfiniteQueryOptions = {}) {
    const queryOptions: UseInfiniteQueryOptions = {
        ...options,
        getNextPageParam: (lastPage) => (lastPage as any)?.next_page,
        placeholderData: placeholderData,
    }

    const endpoint = ENDPOINTS.FARM_FIELD_IMAGES
        .replace(/:farm_id/gi, farmId.toString())
        .replace(/:field_id/gi, fieldId.toString());

    return useInfiniteQuery<any>(
        [ENDPOINTS.FARM_FIELD_IMAGES, farmId, fieldId, params],
         ({pageParam = 1}) =>  get(endpoint, {...params, page: pageParam}),
        queryOptions,
    );
}
