import { TipoMapaTalhoes } from "features/maps/typings";

import BaseMap from "../base-map";
import SatelliteLayers from "components/Satellite/Layers";
import { TalhaoDetailContextProvider } from "features/maps/talhao/TalhaoDetailContext";
import TalhaoModal from "./components/TalhaoModal";
import { getDefaultIndex } from "./utils";
import { useFilters } from "features/filters/store";
import { useLoading } from "features/global-loading";
import { useState } from "react";
import { useTalhoesMapa } from "features/maps/hooks/useTalhoesMapa";

import { useMapStore } from "features/maps/store/useMapStore";

function MapaGeral() {
  const [map, setMap] = useState<google.maps.Map | undefined>();
  const {
    filters: { unidade, tipoMapa },
  } = useFilters();

  const { center, updateTalhao, talhao } = useMapStore();

  const { data, isFetching } = useTalhoesMapa();

  useLoading(isFetching);

  return (
    <>
      <BaseMap
        onLoadMap={(map: google.maps.Map | undefined) => setMap(map)}
        onClickItem={(_, feature) => {
          if (feature.id && tipoMapa !== TipoMapaTalhoes.Chuvas) {
            updateTalhao(feature);
          }
        }}
        points={data}
        center={center ? center : unidade?.pontoCentral}
        isLoading={isFetching}
      >
        {tipoMapa === TipoMapaTalhoes.Satelite ? (
          <SatelliteLayers map={map} />
        ) : null}
      </BaseMap>

      {talhao ? (
        <TalhaoDetailContextProvider defaultIndex={getDefaultIndex(tipoMapa)}>
          <TalhaoModal
            talhao={talhao}
            onClose={() => updateTalhao(undefined)}
          />
        </TalhaoDetailContextProvider>
      ) : null}
    </>
  );
}

export default MapaGeral;
