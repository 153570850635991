import URL from "core/resources/urls";
import http from "infra/http";
import { useQuery } from "react-query";
import { Safra } from "../typings";

export function useSafras() {
  return useQuery(URL.MAPAS.SAFRAS, async () => {
    const { data } = await http.get<Safra[]>(URL.MAPAS.SAFRAS);

    return data;
  });
}
