import { useQuery } from "react-query";
import http from "infra/http";
import URL from "core/resources/urls";

interface UseGetJustificativasProps {
  idTalhaoPlanoOperacionalOperacao: number;
}

export function useGetJustificativas({
  idTalhaoPlanoOperacionalOperacao,
}: UseGetJustificativasProps) {
  return useQuery(
    [
      idTalhaoPlanoOperacionalOperacao,
      URL.JUSTIFICATIVA.SALVAR,
    ],
    async () => {
      const { data } = await http.get(
        URL.JUSTIFICATIVA.SALVAR(
          idTalhaoPlanoOperacionalOperacao
        )
      );

      return data;
    }
  );
}
