import { Image } from "@chakra-ui/image";
import { Flex, Text } from "@chakra-ui/layout";
import { Unidade } from "core/features/maps/typings";
import { getIcon } from "infra/helpers/unidade";
import ContainerItem from "../../ContainerItem";

function UnidadeItem({
  item,
  onClick,
  isBorderBottom,
  unidadeSelected,
}: {
  item: Unidade;
  onClick: () => void;
  isBorderBottom: boolean;
  unidadeSelected?: Unidade;
}) {
  const isUnidadeSelected = item.codigo === unidadeSelected?.codigo;

  return (
    <ContainerItem
      isSelected={isUnidadeSelected}
      onClick={onClick}
      isBorderBottom={isBorderBottom}
      data-testid={`menu-filtros-unidades-${item.nome}`}
    >
      <Flex w="80px" alignItems="center" justifyContent="center" px="6px">
        <Image src={getIcon(item.codigo)} w="55px" h="30px" />
      </Flex>
      <Flex alignItems="center" flex={1}>
        <Text color="gray.900" fontSize="14px">
          {item.nome}
        </Text>
      </Flex>
    </ContainerItem>
  );
}

export default UnidadeItem;
