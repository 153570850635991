import create from "zustand";
import { configurePersist } from "zustand-persist";

import { EnvType } from "../typings";

const { persist } = configurePersist({
  storage: localStorage,
});

export interface UseAuth {
  envs: Partial<EnvType>;
  update: (value: Partial<EnvType>) => void;
}

const initialState = {
  envs: {},
};

export const useEnvs = create<UseAuth>(
  persist(
    {
      key: "@envs-agrosig",
    },
    (set) => ({
      ...initialState,
      update: (envs: Partial<EnvType>) => set(() => ({ envs })),
    })
  )
);
