import { MapTypeId } from "core/features/maps/enums";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import delay from "delay";
import { MapRef, UseMapaParams } from "../typings";
import { DEFAULT_ZOOM } from "../config";
import { CenterMapContext } from "core/features/maps/center-map/CenterMap.context";
import { useFilters } from "core/features/filters/store";
import { TipoMapaTalhoes } from "core/features/maps/typings";

export function useMap(params?: UseMapaParams) {
  const mapRef = useRef<MapRef>(null);
  const [displayMarkers, setDisplayMarkers] = useState(false);
  const currentData = useRef<google.maps.Data.Feature[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    filters: { tipoMapa },
  } = useFilters();
  const { shouldForceCenter, handleChangeShouldForceCenter } =
    useContext(CenterMapContext);

  const { onClickItem, points, center } = params ?? {};

  const url = window.location.href;
  const isPrecipitacaoPage = url.includes("precipitacao");

  const onLoad = useCallback((map) => {
    const bounds = new google.maps.LatLngBounds();
    map.fitBounds(bounds);
    mapRef.current = map;

    setTimeout(() => setIsLoaded(true), 200);
  }, []);

  const onUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  useEffect(() => {
    if (typeof onClickItem !== "function" || !mapRef.current) {
      return;
    }

    const listener = mapRef.current.data.addListener("click", (ev: any) => {
      const prop = Object.keys(ev.feature).find((key) => {
        return !!ev.feature[key]?.id;
      });

      if (prop) {
        onClickItem(ev.latLong, ev.feature[prop]);
      }
    });

    return () => listener?.remove();
  }, [onClickItem]);

  useEffect(() => {
    (async () => {
      if (!points || !isLoaded || !mapRef.current) {
        return;
      }

      currentData.current.forEach((feature) => {
        mapRef.current?.data.remove(feature);
      });

      currentData.current = mapRef.current.data.addGeoJson(points);

      mapRef.current.data.setStyle((feature) => {
        const codigo = feature.getProperty("codigo");
        const isCodigoInTalhoes = params?.talhoes ? params?.talhoes!.includes(codigo) : false;
        const fill = !isPrecipitacaoPage ? feature.getProperty("fill") : isCodigoInTalhoes ? "#437BFF" : "#00000000";

        const fillOpacity = feature.getProperty("fillOpacity");
        const stroke = feature.getProperty("stroke");
        const strokeOpacity = feature.getProperty("strokeOpacity");
        const strokeWidth = feature.getProperty("strokeWidth");

        return {
          opacity: fillOpacity,
          fillColor: fill,
          fillOpacity,
          strokeColor: stroke,
          strokeOpacity,
          strokeWidth,
        };
      });
    })();

    return () => {
      mapRef.current?.data.setValues();
    };
  }, [points, isLoaded]);

  useEffect(() => {
    let subscriber: any;

    (async () => {
      if (
        !center ||
        !isLoaded ||
        !mapRef.current ||
        (!shouldForceCenter &&
          tipoMapa !== TipoMapaTalhoes.ConcentracaoColheita)
      ) {
        return;
      }
      await delay(200);

      mapRef.current?.setCenter({
        lat: center.lat,
        lng: center.long,
      });

      mapRef.current?.setZoom(DEFAULT_ZOOM);
      mapRef.current?.setMapTypeId(MapTypeId.HYBRID);

      if (isPrecipitacaoPage) {
        setDisplayMarkers(true);
      }

      subscriber = mapRef.current?.addListener("zoom_changed", (ev: any) => {
        const zoom = mapRef.current?.getZoom();
        const isShowMarkers = !!(zoom && zoom >= 12);
        
        setDisplayMarkers(isShowMarkers);
        handleChangeShouldForceCenter(false);
      });
    })();

    return () => {
      if (subscriber) {
        subscriber.remove();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center, isLoaded]);

  return {
    isLoaded,
    mapRef,
    onLoad,
    onUnmount,
    displayMarkers,
  };
}
