import { useCallback, useEffect, useMemo, useState } from "react";
import useTalhaoProdutividade from "features/maps/hooks/useTalhaoProdutividade";
import { CustomFeature, TipoMapaTalhoes } from "features/maps/typings";
import { Box, Collapse, Flex, Text } from "@chakra-ui/react";
import {
  ActionButtonsProdutividadeRow,
  EnabledButtonsType,
} from "./ActionButtonsProdutividade";
import {
  checkProdutividadeAtiva,
  ProdutividadeTableColoumsDetail,
  ProdutividadeTableColoumsTotalItems,
} from "./utils";
import { ProdutividadeData } from "features/maps/typings/produtividade";
import { OcupacaoEnum } from "features/maps/enums";
import { fallbackText } from "ui/utils";
import { useFilters } from "features/filters/store";
import DatatableAntd from "components/datatable-antd";

interface TalhaoProdutividadeProps {
  talhao: CustomFeature;
  active: boolean;
  filterActive: boolean;
}

export default function TalhaoProdutividade({
  talhao,
  active,
  filterActive,
}: TalhaoProdutividadeProps) {
  const { filters } = useFilters();

  const [pagination, setPagination] = useState({ page: 1, itemCount: 1 });
  const [currentFilter, setCurrentFilter] = useState<OcupacaoEnum>(
    OcupacaoEnum.TODOS
  );

  const [enabledFiltersButtons, setEnabledFiltersButtons] =
    useState<EnabledButtonsType>({
      algodao: false,
      milho: false,
      soja: false,
    });

  const {
    data: prodData,
    status,
    fetchNextPage,
    isFetching,
  } = useTalhaoProdutividade(
    active ? talhao.id : null,
    currentFilter === 0 ? undefined : currentFilter,
    pagination.page
  );
  const [totalItemsOnFilterTodos, setTotalItemsOnFilterTodos] =
    useState<number>(0);

  const produtividades = useMemo(() => {
    return (
      prodData?.pages.reduce(
        (agg: ProdutividadeData[], item) => agg.concat(item.data ?? []),
        []
      ) ?? []
    );
  }, [prodData]);

  const items = useMemo(() => produtividades || [], [produtividades]);

  const isLoading = status === "loading" || isFetching;
  const [actualProdTooSee, setActualProdToSee] = useState<ProdutividadeData>();

  const paginate = prodData?.pages[0].paginate;
  const isProdDataBiggerThenZero = prodData && items.length > 0;
  const isDataAbleToShow =
    !isLoading && !isFetching && isProdDataBiggerThenZero;
  const isThereAProdToSee = actualProdTooSee !== undefined;

  const getActualProdToSee = useCallback(() => {
    if (
      isDataAbleToShow &&
      !isThereAProdToSee &&
      items.length > 0 &&
      items[0].elementos
    ) {
      return items[0].elementos.map((item) => ({
        nome: item.nome,
        planejado: item.planejado
          ? `${item.planejado.toFixed(2)} ${item.unidadeMedidaSigla}`
          : fallbackText,
        realizado: item.realizado
          ? `${item.realizado.toFixed(2)} ${item.unidadeMedidaSigla}`
          : fallbackText,
      }));
    }

    if (
      isDataAbleToShow &&
      isThereAProdToSee &&
      actualProdTooSee &&
      actualProdTooSee.elementos
    ) {
      return actualProdTooSee.elementos.map((item) => ({
        nome: item.nome,
        planejado: item.planejado
          ? `${item.planejado.toFixed(2)} ${item.unidadeMedidaSigla}`
          : fallbackText,
        realizado: item.realizado
          ? `${item.realizado.toFixed(2)} ${item.unidadeMedidaSigla}`
          : fallbackText,
      }));
    }
  }, [isDataAbleToShow, isThereAProdToSee, actualProdTooSee, items]);

  const nextPage = () => {
    const lastPage = prodData?.pages[prodData.pages.length - 1];

    if (!lastPage) {
      return;
    }

    const totalPages =
      lastPage.paginate.totalCount / prodData?.pages[0].paginate.resultsPerPage;

    const hasNextPage = totalPages > prodData?.pages.length!;

    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  };

  const getOcupacaoName = useCallback((): string | undefined => {
    switch (currentFilter) {
      case OcupacaoEnum.MILHO:
        return "Milho";
      case OcupacaoEnum.ALGODAO:
        return "Algodão";
      case OcupacaoEnum.SOJA:
        return "Soja";
      default:
        return undefined;
    }
  }, [currentFilter]);

  const verifyRowSelection = useCallback(
    (item: ProdutividadeData) => {
      if (item === actualProdTooSee) {
        return true;
      }

      if (filters.tipoMapa !== TipoMapaTalhoes.Produtividade) {
        return false;
      }

      if (filters.filtroProdutividade) {
        const selectRow = checkProdutividadeAtiva(
          actualProdTooSee,
          item,
          items[0],
          filters.filtroProdutividade.produtividades,
          filters.filtroProdutividade.ocupacao
        );

        return selectRow;
      }
      return true;
    },
    [filters.tipoMapa, filters.filtroProdutividade, actualProdTooSee, items]
  );

  const showTotalByFilter =
    !isFetching &&
    prodData &&
    totalItemsOnFilterTodos &&
    getOcupacaoName() !== undefined;

  useEffect(() => {
    if (paginate) {
      const totalPages = Math.ceil(paginate.totalCount / 10);
      setPagination({
        page: totalPages < pagination.page ? 1 : pagination.page,
        itemCount: paginate.totalCount,
      });
    }
  }, [paginate, pagination.page]);

  useEffect(() => {
    if (status === "success" && items) {
      setActualProdToSee(items[0]);
    }
  }, [status, items]);

  useEffect(() => {
    if (!totalItemsOnFilterTodos) {
      setTotalItemsOnFilterTodos(
        prodData?.pages[0].paginate.totalCount as number
      );
    }
  }, [prodData, totalItemsOnFilterTodos]);

  useEffect(() => {
    if (currentFilter === OcupacaoEnum.TODOS) {
      setEnabledFiltersButtons({
        algodao: !!items.find((item) =>
          item.safraPeriodoNome.toLocaleLowerCase().includes("algodão")
        ),
        milho: !!items.find((item) =>
          item.safraPeriodoNome.toLocaleLowerCase().includes("milho")
        ),
        soja: !!items.find((item) =>
          item.safraPeriodoNome.toLocaleLowerCase().includes("soja")
        ),
      });
    }
  }, [items, currentFilter]);

  return (
    <Flex direction="column" width="100%" height={"100%"}>
      <Collapse in={filterActive}>
        <ActionButtonsProdutividadeRow
          enabledButtons={enabledFiltersButtons}
          actualFilter={currentFilter}
          handleChangeCurrentFilter={(filter) => {
            setActualProdToSee(undefined);
            setCurrentFilter(filter);
          }}
          totalItems={paginate?.totalCount}
        />
      </Collapse>

      <Flex justifyContent={"space-between"} marginTop={8}>
        <Box width="62.3%">
          <DatatableAntd
            loading={isLoading}
            columns={ProdutividadeTableColoumsTotalItems}
            scroll={{ y: "calc(100vh - 380px)" }}
            rowKey={({ safraPeriodoNome }) => `key-row-${safraPeriodoNome}`}
            onLoad={() => {
              nextPage();
            }}
            dataSource={items}
            onRow={(data) => {
              return {
                className: verifyRowSelection(data) ? "row-selected" : "",
                style: { cursor: "pointer" },
                onClick: () => setActualProdToSee(data),
              };
            }}
          />

          <Box
            bg="#ebeaea"
            d="flex"
            px="16px"
            py="6px"
            textTransform="uppercase"
            borderBottom="1px solid #babfc7"
            borderLeft="1px solid #babfc7"
            borderRight="1px solid #babfc7"
          >
            <Flex flex={1} justifyContent="space-between">
              <Flex>
                Total:
                <Text fontWeight="bold" pl="4px">
                  {totalItemsOnFilterTodos ?? 0}
                </Text>
              </Flex>

              {showTotalByFilter && (
                <Flex>
                  TOTAL DE {getOcupacaoName()}:{" "}
                  <Text fontWeight="bold" pl="4px">
                    {prodData.pages[0].paginate.totalCount}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Box>
        </Box>
        <Box width="35.8%">
          <DatatableAntd
            loading={isLoading}
            pagination={false}
            columns={ProdutividadeTableColoumsDetail}
            dataSource={getActualProdToSee() as any}
          />
        </Box>
      </Flex>
    </Flex>
  );
}
