import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { AgrodriveContext } from "core/features/agrodrive/contexts/Agrodrive.context";
import { useContext } from "react";
import { ArquivoResponse } from "../../types/arquivos";
import { BreadChild, MainBread, Spare } from "./styles";

interface BreadcrumbAgrodriveProps {
  items: ArquivoResponse[];
  onChange: (v: ArquivoResponse[]) => void;
  independentMode?: boolean;
  independentChange?: any;
  independentTree?: any;
}

const BreadcrumbAgrodrive = ({
  items,
  onChange,
  independentMode,
  independentChange,
  independentTree,
}: BreadcrumbAgrodriveProps) => {
  const {
    items: { diretorioRaiz },
    onChange: {
      setPath,
      setTree,
      setPagina,
      refetchDiretorioList,
      setFullPath,
      // setDiretorioLocalizacao,
    },
  } = useContext(AgrodriveContext);

  return (
    <>
      <MainBread>
        <BreadChild
          isActual={!items.length}
          onClick={() => {
            if (independentMode) {
              independentChange("/");
              independentTree([]);
            } else {
              setFullPath(diretorioRaiz);
              setTree([]);
              setPath(diretorioRaiz);
              setPagina(1);
              refetchDiretorioList();
            }
          }}
        >
          Agrodrive
        </BreadChild>
        {items.map((item, index) => (
          <>
            <Spare>/</Spare>
            <BreadChild
              isActual={items.length - 1 === index}
              onClick={() => {
                if (independentMode) {
                  independentChange(item.nome);
                  independentTree(items.slice(0, index + 1));
                } else {
                  setPath(item.nome);
                  setTree(items.slice(0, index + 1));
                }
              }}
              title={item.nome}
            >
              {item.nome}
            </BreadChild>
          </>
        ))}
      </MainBread>
    </>
  );
};

export default BreadcrumbAgrodrive;
