import { useFiltersLists } from "features/filters/store/lists";
import { useFilters } from "features/filters/store";

import { useEffect } from "react";
import { OcupacaoEnum } from "features/maps/enums";

function useProdutividadeOcupacaoFilters() {
  const { filters, updateFiltroProdutividade } = useFilters();
  const { produtividade } = useFiltersLists();

  const { filtroProdutividade } = filters ?? {};

  const findTecnologiasByOcupacaoSelected = produtividade?.ocupacoes.find(
    (item) => item.codigo === filtroProdutividade?.ocupacao
  );

  const findProdutividades = produtividade?.produtividades.filter(
    (item) => item.ocupacao === filtroProdutividade?.ocupacao
  );

  const findProdutividadesWithTecnologiaSelected = findProdutividades?.filter(
    (item) => filtroProdutividade?.tecnologias.includes(item.tecnologiaId)
  );

  const produtividades =
    findProdutividadesWithTecnologiaSelected?.length! > 0
      ? findProdutividadesWithTecnologiaSelected
      : findProdutividades;

  const removedDuplicateProdutividades = produtividades?.filter(
    (v, i, a) => a.findIndex((t) => t.descricao === v.descricao) === i
  );

  const selectOcupacao = (ocupacaoId: OcupacaoEnum) => {
    const findOcupacao = produtividade?.ocupacoes.find(
      (item) => item.codigo === ocupacaoId
    );
    const findFirstTecnologiaEnabled = findOcupacao?.tecnologias.find(
      (item) => item.id
    );
    updateFiltroProdutividade({
      ocupacao: findOcupacao?.codigo,
      tecnologias: findFirstTecnologiaEnabled
        ? [findFirstTecnologiaEnabled.id]
        : [],
      produtividades: [],
    });
  };

  useEffect(() => {
    if (!filters.filtroProdutividade?.tecnologias.length) {
      const ocp = produtividade?.ocupacoes || [];
      const isOcupacaoEnabled = ocp.find(
        (item) => item.codigo === filters.filtroProdutividade?.ocupacao
      );

      if (
        !filters.filtroProdutividade?.ocupacao ||
        !isOcupacaoEnabled?.enabled
      ) {
        const findFirstOcupacaoEnabled = ocp.find((item) => item.enabled);
        const findFirstTecnologiaEnabled =
          findFirstOcupacaoEnabled?.tecnologias.find((item) => item.id);
        updateFiltroProdutividade({
          ocupacao: findFirstOcupacaoEnabled?.codigo,
          tecnologias: findFirstTecnologiaEnabled?.id
            ? [findFirstTecnologiaEnabled.id]
            : [],
          produtividades: [],
        });
      }
    }
  }, [
    filters.filtroProdutividade?.ocupacao,
    produtividade?.ocupacoes,
    filters.filtroProdutividade?.tecnologias,
    updateFiltroProdutividade,
  ]);

  useEffect(() => {
    if (!filters.filtroProdutividade?.tecnologias.length) {
      const findOcupacao = produtividade?.ocupacoes.find(
        (item) => item.codigo === filters.filtroProdutividade?.ocupacao
      );
      const findFirstTecnologiaEnabled =
        findOcupacao?.tecnologias &&
        findOcupacao?.tecnologias.find((item) => item.id);
      if (!filters.filtroProdutividade?.tecnologias.length) {
        updateFiltroProdutividade({
          produtividades: [],
          ocupacao: filters.filtroProdutividade?.ocupacao,
          tecnologias: findFirstTecnologiaEnabled?.id
            ? [findFirstTecnologiaEnabled.id]
            : [],
        });
      }
    }
  }, [
    filters.filtroProdutividade?.ocupacao,
    filters.filtroProdutividade?.tecnologias.length,
    produtividade?.ocupacoes,
    updateFiltroProdutividade,
  ]);

  useEffect(() => {
    if (!filters.filtroProdutividade?.tecnologias.length) {
      const findFirstTecnologiaEnabled =
        findTecnologiasByOcupacaoSelected?.tecnologias &&
        findTecnologiasByOcupacaoSelected?.tecnologias.find((item) => item.id);

      const idsProdutividadesFilter =
        filters?.filtroProdutividade?.produtividades.map((item) => {
          return item.codigo;
        });
      const compativeProdutividades = removedDuplicateProdutividades
        ?.filter((item) => idsProdutividadesFilter?.includes(item.codigo))
        .map((produtividade) => {
          return {
            color: produtividade.cor,
            codigo: produtividade.codigo,
            tecnologiaId: produtividade.tecnologiaId,
            descricao: produtividade.descricao,
            min: produtividade.min,
            max: produtividade.max,
          };
        });
      const compativeTecnologias =
        findTecnologiasByOcupacaoSelected?.tecnologias
          .filter((item) =>
            filters.filtroProdutividade?.tecnologias.includes(item.id)
          )
          .map((tecnologia) => {
            return tecnologia.id;
          });

      updateFiltroProdutividade({
        produtividades: compativeProdutividades?.length
          ? compativeProdutividades
          : [],
        ocupacao: filters.filtroProdutividade?.ocupacao,
        tecnologias: compativeTecnologias?.length
          ? compativeTecnologias
          : findFirstTecnologiaEnabled?.id
          ? [findFirstTecnologiaEnabled.id]
          : [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.unidade,
    filters.safra,
    findTecnologiasByOcupacaoSelected,
    filters.filtroProdutividade?.tecnologias.length,
  ]);

  return {
    items: produtividade?.ocupacoes ?? [],
    value: filters.filtroProdutividade?.ocupacao!,
    selectOcupacao,
  };
}

export default useProdutividadeOcupacaoFilters;
