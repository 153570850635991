import { PaginateProps } from "core/features/paginate/typings";
import URL from "core/resources/urls";
import http from "infra/http";
import { useRef } from "react";
import { useInfiniteQuery } from "react-query";
import { UsuarioPerfilProps } from "../typings";

export function useUsuarios(filter: string) {
  const lastSearch = useRef<string>();

  return useInfiniteQuery(
    [URL.USUARIO.LISTA_DE_USUARIOS, filter],
    async ({ pageParam }) => {
      if (lastSearch.current !== filter) {
        pageParam = 1;
        lastSearch.current = filter;
      }

      const { data, headers } = await http.get(URL.USUARIO.LISTA_DE_USUARIOS, {
        params: {
          filter,
          page: pageParam ?? 1,
          size: 100,
        },
      });

      const { paginate } = headers;

      return {
        users: data as UsuarioPerfilProps[],
        paginate: JSON.parse(paginate) as PaginateProps,
      };
    },
    {
      getNextPageParam: (lastPage, pages) => lastPage.paginate.currentPage + 1,
    }
  );
}
