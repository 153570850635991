import {
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import animationLoading from "assets/lotties/uploading.json";
// import * as yup from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { AgrodriveFiltersTags } from "../AgrodriveFiltersTags";

import NewFileIcon from "assets/images/icons/agrodrive/newFileIcon.svg";
import useCreateFile from "../../hooks/useCreateFile";
import { ModalNewFileContext } from "core/features/agrodrive/contexts/ModalNewFile.context";
import { showError, showSuccess } from "infra/helpers/alerta";
import { AgrodriveContext } from "core/features/agrodrive/contexts/Agrodrive.context";
import { queryClient } from "infra/packages/react-query";
import URL from "core/resources/urls";
import { LoadingTile } from "./styles";
import Lottie from "react-lottie";
// import { createNewFile } from "core/features/agrodrive/services/createNewFile";
import { useFiltersLists } from "core/features/filters/store/lists";

interface ModalNewFileProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  localizacao: string;
  tags?: string[];
}

export default function ModalNewFile({
  isOpen,
  onClose,
  localizacao,
  tags,
}: ModalNewFileProps) {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [fileName] = useState<string>("");
  const [, setFiles] = useState<any>({});
  const toast = useToast();
  const [, setTagsVinculadas] = useState<string[]>([]);

  const { mutate: handleCreate } = useCreateFile();

  const {
    items: { tagsHerdadas, customTagsData, fullPath },
    onChange: { refetchDiretorioList, setUpdating, refetchCustomTags },
  } = useContext(AgrodriveContext);

  const {
    items: {
      currentSafrasVinculadas,
      currentUnidadesVinculada,
      currentCulturaVinculada,
      currentSetoresVinculadas,
      currentTalhoesVinculados,
      tagsPersonalizadasVinculadas,
    },
    onChange: {
      setCurrentCulturaVinculada,
      setCurrentSafrasVinculadas,
      setCurrentSetoresVinculados,
      setCurrentTalhoesVinculados,
      setCurrentUnidadesVinculadas,
      setTagsPersonalizadasVinculadas,
    },
  } = useContext(ModalNewFileContext);

  const getTags = (): string[] => {
    const tagsNames: string[] = [];
    currentSafrasVinculadas.forEach((tag) => tagsNames.push(tag.descricao));
    currentUnidadesVinculada.forEach((tag) => tagsNames.push(tag.nome));
    currentCulturaVinculada.forEach((tag) => tagsNames.push(tag.nome));
    currentSetoresVinculadas.forEach((tag) => tagsNames.push(tag.nome));
    currentTalhoesVinculados.forEach((tag) => tagsNames.push(tag.nome));
    tagsPersonalizadasVinculadas.forEach((tag) => tagsNames.push(tag.nome));

    return tagsNames;
  };

  const { safras, unidades } = useFiltersLists();

  //CONTHERE
  useEffect(() => {
    if (tagsHerdadas) {
      // tagsHerdadas
      const defaultUnidades = unidades.filter(
        (item) => item.nome === tagsHerdadas[0]
      );

      setCurrentUnidadesVinculadas(defaultUnidades);
    }

    // getTags(tagsHerdadas);
  }, []);

  const handleAddFile = async (arquivo: any) => {
    try {
      setLoading(true);
      const formData = {
        Nome: fileName,
        Localizacao: fullPath,
        Tags: getTags(),
        files: arquivo.files,
      };
      await handleCreate(formData, {
        onSuccess: () => {
          refetchDiretorioList();
          refetchCustomTags();
          setLoading(false);
          showSuccess();
          onClose();
        },
        onError: (err) => {
          setLoading(false);
          showError();
          onClose();
        },
      });

      // createNewFile(formData);
      // await refetchDiretorioList();
    } catch (error) {}
  };

  useEffect(() => {
    const atLeastOneSelected =
      currentSafrasVinculadas.length ||
      currentUnidadesVinculada.length ||
      currentCulturaVinculada.length ||
      currentSetoresVinculadas.length ||
      currentTalhoesVinculados.length ||
      tagsPersonalizadasVinculadas.length;

    if (atLeastOneSelected) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    currentSafrasVinculadas,
    currentUnidadesVinculada,
    currentCulturaVinculada,
    currentSetoresVinculadas,
    currentTalhoesVinculados,
    tagsPersonalizadasVinculadas,
  ]);

  return (
    <Modal
      size="4xl"
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex display="flex" flexdir="row" mb="4">
            <Image src={NewFileIcon} />
            <Text ml="4" mt="1" color="#6B6C7E">
              Upload de Arquivos
            </Text>
          </Flex>
          <Divider />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack flex={1} mb="4">
            <HStack mt="2" mb="2">
              <Text fontWeight={700} fontSize="16px">
                Vincular tags
              </Text>
              <Text color="red.600" size="16px">
                *
              </Text>
            </HStack>
            <Box>
              <AgrodriveFiltersTags
                items={{
                  currentSafras: currentSafrasVinculadas,
                  currentUnidades: currentUnidadesVinculada,
                  currentCultura: currentCulturaVinculada,
                  currentSetores: currentSetoresVinculadas,
                  currentTalhao: currentTalhoesVinculados,
                  tagsPersonalizadas: tagsPersonalizadasVinculadas,
                  customTagsData: customTagsData,
                }}
                onChange={{
                  setCurrentSafras: setCurrentSafrasVinculadas,
                  setCurrentUnidades: setCurrentUnidadesVinculadas,
                  setCurrentCultura: setCurrentCulturaVinculada,
                  setCurrentSetores: setCurrentSetoresVinculados,
                  setCurrentTalhao: setCurrentTalhoesVinculados,
                  setTagsPersonalizadas: setTagsPersonalizadasVinculadas,
                  setTagsVinculadas: setTagsVinculadas,
                }}
              />
            </Box>
          </Stack>
          <Box mb="6">
            <FileUpload
              name="agrodriveFileUpload"
              chooseLabel="Escolher"
              cancelLabel="Cancelar"
              url={"https://dev-agrodrive.underberg.agr.br/v1/arquivo"}
              multiple
              onUpload={(prop) => {
                console.log("onUpload: ", prop);
              }}
              customUpload
              uploadHandler={async (file) => {
                setFiles(file);
                setUpdating(true);
                await handleAddFile(file);
              }}
              maxFileSize={5 * 1073741824} //5GB
              contentStyle={{ maxHeight: "337px", overflowY: "scroll" }}
              disabled={isDisabled}
              invalidFileSizeMessageDetail="Tamanho do arquivo é superior 3GB"
              emptyTemplate={
                <Text>Arraste e solte os arquivos aqui para fazer upload.</Text>
              }
            />
          </Box>
        </ModalBody>
        {loading && (
          <LoadingTile>
            <Lottie
              options={{
                animationData: animationLoading,
                rendererSettings: {
                  clearCanvas: true,
                },
              }}
              width={150}
            />
          </LoadingTile>
        )}
      </ModalContent>
    </Modal>
  );
}
