import SelectedButtons from "../../../SelectedButtons";
import useConcentracaoColheitaTecnologiasFilters from "../hooks/useConcentracaoColheitaTecnologiasFilters";

function ConcentracaoTecnologias() {
  const { items, onChange, value } =
    useConcentracaoColheitaTecnologiasFilters();

  return (
    <SelectedButtons
      value={value}
      items={items}
      onChange={(v) => onChange(v)}
    />
  );
}

export default ConcentracaoTecnologias;
