import { useMemo, useState } from "react";
import { Box, Collapse, Flex } from "@chakra-ui/react";
import InputSearch from "components/inputs/input-search";
import { CustomFeature, TipoMapaTalhoes } from "features/maps/typings";

import {
  columnTablesAplicacoes,
  columnTablesAplicacoesDetalhes,
  getInsumoName,
} from "./utils";
import useTalhoesAplicacoes from "features/maps/hooks/useTalhaoAplicacoes";
import useTalhoesInsumosGrupos from "features/maps/hooks/useTalhoesInsumosGrupos";
import { ActionButtonsAplicacoes } from "./components/ActionButtonsAplicacoes";
import { useFilters } from "features/filters/store";
import DatatableAntd from "components/datatable-antd";
import TableFooter from "./components/TableFooter";

import { IoIosArrowUp } from "react-icons/io";
import { MdChevronRight } from "react-icons/md";
import {
  AplicacoesProperties,
  StatusInsumosEnum,
} from "features/maps/typings/aplicacoes";
import Graficos from "./components/Graficos";

interface TabOrdensServicoProps {
  talhao: CustomFeature;
  active: boolean;
  filterActive: boolean;
}

const TabAplicacoes = ({
  talhao,
  active,
  filterActive,
}: TabOrdensServicoProps) => {
  const { filters } = useFilters();

  const { filtroNumeroDeAplicacoes, tipoMapa } = filters;

  const { insumoGrupo, aplicacoes } = filtroNumeroDeAplicacoes! ?? {};

  const [numeroOs, setNumeroOs] = useState<number | undefined>();

  const { insumos, loadingInsumosDisponiveis } = useTalhoesInsumosGrupos(
    active ? talhao.id : null
  );

  const [aplicacoesFilter, setAplicacoesFilter] = useState<StatusInsumosEnum>(
    insumoGrupo ?? StatusInsumosEnum.Herbicidas
  );

  const { data, isLoading, isFetching } = useTalhoesAplicacoes({
    talhaoId: active ? talhao.id : null,
    numeroOs,
    insumoGrupo: aplicacoesFilter,
  });

  const detalhesTalhaoAplicacao = data?.aplicacoesTalhao;

  const _isLoading = isLoading || isFetching || loadingInsumosDisponiveis;

  const items = useMemo(() => {
    return (detalhesTalhaoAplicacao?.aplicacoes || []).map((item) => ({
      ...item,
      key: `${item.aplicacao}`,
      children: item.insumos.map((insumo) => ({
        ...insumo,
        key: `${insumo.insumo}`,
      })),
    }));
  }, [detalhesTalhaoAplicacao?.aplicacoes]);

  const isActive = (item: AplicacoesProperties) => {
    if (tipoMapa !== TipoMapaTalhoes.NumeroDeAplicacoes) {
      return false;
    }

    return !!aplicacoes.find((ap) => ap.codigo === item.aplicacao);
  };

  console.log(aplicacoesFilter === StatusInsumosEnum.Indicadores);

  return (
    <Flex flexDir="column" flex={1}>
      <Collapse in={filterActive}>
        <Flex mb={4} flexDir="row" flex={1} w="100%" alignItems="center">
          <Flex flex={1}>
            <InputSearch
              data-testid="input-search-tab-operacoes-realizadas"
              placeholder="Digite o nº da ordem"
              onChange={(text: string) => {
                setNumeroOs(Number(text));
              }}
              maxLength={9}
              isNumeric
              isDisabled={aplicacoesFilter === StatusInsumosEnum.Indicadores}
            />
          </Flex>

          <ActionButtonsAplicacoes
            selectedStatus={aplicacoesFilter}
            insumos={insumos}
            onChangeStatus={(status) => {
              if (status === StatusInsumosEnum.Indicadores) {
                setNumeroOs(undefined);
              }
              setAplicacoesFilter(status);
            }}
          />
        </Flex>
      </Collapse>
      <Flex mt={8} flexDir="column">
        {aplicacoesFilter !== StatusInsumosEnum.Indicadores ? (
          <DatatableAntd
            columns={columnTablesAplicacoes}
            loading={_isLoading}
            dataSource={items}
            scroll={{ y: "calc(100vh - 380px)" }}
            onRow={(record) => ({
              className: isActive(record) ? "row-selected" : "",
              style: {
                cursor: !!record.insumos?.length ? "pointer" : "inherit",
              },
            })}
            expandable={{
              expandRowByClick: true,
              rowExpandable: (record) => !!record.insumos?.length,
              expandIcon: ({ expanded, onExpand, record }) =>
                !!record.insumos?.length ? (
                  expanded ? (
                    <IoIosArrowUp onClick={(e) => onExpand(record, e as any)} />
                  ) : (
                    <MdChevronRight
                      onClick={(e) => onExpand(record, e as any)}
                    />
                  )
                ) : null,

              expandedRowRender: (record) => (
                <Box m={8} ml={0}>
                  <DatatableAntd
                    dataSource={record.insumos ?? []}
                    columns={columnTablesAplicacoesDetalhes}
                  />
                </Box>
              ),
            }}
            footer={() => (
              <TableFooter
                tipo={getInsumoName(aplicacoesFilter)}
                total={detalhesTalhaoAplicacao?.aplicacoes?.length ?? 0}
                custo={detalhesTalhaoAplicacao?.custoTotalDolarHa ?? 0}
              />
            )}
          />
        ) : (
          <Graficos talhaoId={talhao.id} />
        )}
      </Flex>
    </Flex>
  );
};

export default TabAplicacoes;
