import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import EditUser from "components/maps/base-map/components/UsersModal/components/EditUser";
import UsersTable from "components/maps/base-map/components/UsersModal/components/UsersTable";

import { UsuarioPerfilProps } from "core/features/users/typings";

const Users = () => {
  const [showUsersTable, setShowUsersTable] = useState<boolean>(true);
  const [userSelected, setUserSelected] = useState<
    UsuarioPerfilProps | undefined
  >(undefined);
  const [searchUser, setSearchUser] = useState<string>("");
  const handleEditUser = () => {
    setShowUsersTable(false);
  };

  const handleNewUser = () => {
    setUserSelected(undefined);
    setShowUsersTable(false);
  };

  useEffect(() => {
    if (showUsersTable) {
      setUserSelected(undefined);
    }
  }, [showUsersTable]);

  return (
    <Box
      background="#fff"
      width={"100%"}
      borderRadius={"10px"}
      padding="66px 35px 25px"
      position="relative"
      height="100%"
    >
      {showUsersTable ? (
        <>
          <UsersTable
            handleEditUser={handleEditUser}
            handleNewUser={handleNewUser}
            userSelected={userSelected}
            setUserSelected={setUserSelected}
            search={searchUser}
            handleChangeSearch={(letter: string) => setSearchUser(letter)}
          />
        </>
      ) : (
        <EditUser
          user={userSelected}
          handleGoBack={() => setShowUsersTable(true)}
        />
      )}
    </Box>
  );
};

export default Users;
