import { Flex, Text } from "@chakra-ui/react";
import DatatableAntd from "components/datatable-antd";
import { TalhoesProps } from "core/features/talhoes/typings/listaEGeojson";

import { tableColumns } from "../../../utils/tableColumns";

interface TalhoesTableProps {
  talhoes: TalhoesProps[];
  changeCurrentItem: (item: TalhoesProps) => void;
  currentItem?: TalhoesProps;
  isLoading: boolean;
  totalItems?: number;
  shouldShowModal: boolean;
  handleShowModal: (prop: TalhoesProps) => void;
}

const TalhoesTable = ({
  talhoes,
  changeCurrentItem,
  currentItem,
  isLoading,
  totalItems,
  handleShowModal,
  shouldShowModal,
}: TalhoesTableProps) => {
  return (
    <Flex flexDirection="column">
      <DatatableAntd
        style={{ marginLeft: "7px" }}
        columns={tableColumns}
        loading={isLoading}
        dataSource={talhoes!}
        scroll={{ y: "calc(100vh - 460px)" }}
        rowKey={(prop) => prop.talhaoId}
        onRow={(data) => {
          return {
            className:
              currentItem?.talhaoId === data.talhaoId ? "row-selected" : "",
            style: { cursor: "pointer" },
            onClick: () => {
              if (currentItem === data) return;
              if (shouldShowModal) {
                return handleShowModal(data);
              }
              changeCurrentItem(data);
            },
          };
        }}
      />
      <Flex
        bg="#ebeaea"
        px="16px"
        py="6px"
        marginLeft="7px"
        textTransform="uppercase"
        borderBottom="1px solid #babfc7"
        borderLeft="1px solid #babfc7"
        borderRight="1px solid #babfc7"
      >
        <Flex flex={1} justifyContent="space-between">
          <Flex>
            Total de talhões:
            <Text fontWeight="bold" pl="4px">
              {totalItems ?? 0}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TalhoesTable;
