import React, {
  createContext,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { SafraPeriodoProps } from "../hooks/useSafraPeriodo";
import atualizarViaKML from "../service/atualizarViaKML";

import { TalhoesToImportProps } from "../typings";
import {
  ArquivosProps,
  TalhaoNaoMapeadoProps,
  ValidarKMLRetProps,
} from "../typings/validacaoKML";

interface ImportTalhoesContextProps {
  kmlValidationRet?: ValidarKMLRetProps;
  safraPeriodo?: SafraPeriodoProps;
  step: number;
  arquivos?: ValidarKMLRetProps["arquivo"];
  talhoesNaoMapeados?: TalhaoNaoMapeadoProps[];
  talhoesMapeados: string[];
  talhoesToimport: TalhoesToImportProps[];
  talhoesAtualizados: string[];
  file?: File;
  setKMLValidationRet: (prop?: ValidarKMLRetProps) => void;
  setStep: (prop: number) => void;
  setSafraPeriodo: (safraPeriodo?: SafraPeriodoProps) => void;
  handleChangeArquivoAtual: (
    arquivo: ValidarKMLRetProps["arquivo"][0],
    arquivoAtual?: ValidarKMLRetProps["talhoesNaoMapeados"][0]
  ) => void;
  setTalhoesToImport: (prop: TalhoesToImportProps[]) => void;
  handleImportTalhoes: () => Promise<any>;
  setFile: (prop?: File) => void;
  inputRef: MutableRefObject<any | null> | undefined;
  handleClearFields: () => void;
}

const ImportTalhoesContext = createContext<ImportTalhoesContextProps>({
  kmlValidationRet: undefined,
  safraPeriodo: undefined,
  step: 1,
  arquivos: undefined,
  talhoesNaoMapeados: undefined,
  talhoesMapeados: [],
  talhoesToimport: [],
  file: undefined,
  talhoesAtualizados: [],
  setStep: (prop: number) => {},
  setSafraPeriodo: (safraPeriodo?: SafraPeriodoProps) => {},
  setKMLValidationRet: (prop) => {},
  handleChangeArquivoAtual: (
    arquivo: ValidarKMLRetProps["arquivo"][0],
    arquivoAtual?: ValidarKMLRetProps["talhoesNaoMapeados"][0]
  ) => {},
  setTalhoesToImport: (prop: TalhoesToImportProps[]) => {},
  handleImportTalhoes: async () => {},
  setFile: (prop?: File) => {},
  inputRef: undefined,
  handleClearFields: () => {},
});

const ImportTalhoesContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [safraPeriodo, setSafraPeriodo] = useState<SafraPeriodoProps>();
  const [kmlValidationRet, setKMLValidationRet] =
    useState<ValidarKMLRetProps>();

  const [arquivos, setArquivos] = useState<ValidarKMLRetProps["arquivo"]>();
  console.log(arquivos);
  const [step, setStep] = useState<number>(1);
  const [talhoesMapeados, setTalhoesMapeados] = useState<string[]>([]);
  const [talhoesAtualizados, setTalhoesAtualizados] = useState<string[]>([]);
  const [file, setFile] = useState<File>();
  const inputRef = useRef<any>();

  const talhoesNaoMapeados: TalhaoNaoMapeadoProps[] | undefined =
    useCallback(() => {
      return kmlValidationRet?.talhoesNaoMapeados;
    }, [kmlValidationRet])();

  const [talhoesToimport, setTalhoesToImport] = useState<
    {
      talhaoId: string;
      wkt: string;
    }[]
  >([]);

  const handleClearFields = () => {
    setStep(1);
    setArquivos(undefined);
    setTalhoesAtualizados([]);
    setTalhoesMapeados([]);
    setFile(undefined);
    setSafraPeriodo(undefined);
    setKMLValidationRet(undefined);
    setTalhoesToImport([]);
  };

  const handleChangeArquivoAtual = (
    arquivo: ValidarKMLRetProps["arquivo"][0],
    arquivoAtual?: ValidarKMLRetProps["talhoesNaoMapeados"][0]
  ) => {
    if (!arquivoAtual) {
      const newArquivos = arquivos?.map((item, i) => {
        if (
          item.arquivo.pontoCentral?.lat ===
            arquivo.arquivo.pontoCentral?.lat &&
          item.arquivo.pontoCentral?.long === arquivo.arquivo.pontoCentral?.long
        ) {
          return kmlValidationRet?.arquivo[i];
        }
        return item;
      });

      setArquivos(newArquivos as ArquivosProps[]);
      setTalhoesToImport(
        talhoesToimport.filter((item) => item.talhaoId !== arquivo.talhaoId)
      );
      setTalhoesMapeados(
        talhoesMapeados.filter((codigo) => codigo !== arquivo.atual?.codigo)
      );
      return;
    }

    const newArquivos = arquivos?.map((item, i) => {
      if (
        item.arquivo.pontoCentral?.lat === arquivo.arquivo.pontoCentral?.lat &&
        item.arquivo.pontoCentral?.long === arquivo.arquivo.pontoCentral?.long
      ) {
        return {
          ...arquivo,
          talhaoId: arquivoAtual.talhaoId,
          atual: arquivoAtual,
          status: 1,
          statusEnum: "OK",
          statusDescription: "OK",
          alterado: true,
        };
      }
      return item;
    });

    setArquivos(newArquivos);

    setTalhoesMapeados([...talhoesMapeados, arquivoAtual.codigo]);

    // setTalhoesNaoMapeados(newTalhoesNaoMapeados);
  };

  const handleImportTalhoes = async () => {
    try {
      const data = await atualizarViaKML(talhoesToimport);

      setTalhoesAtualizados(data);
      setStep(3);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (kmlValidationRet && !arquivos) {
      setArquivos(kmlValidationRet.arquivo);
    }

    if (!kmlValidationRet && arquivos) {
      setArquivos(undefined);
    }
  }, [kmlValidationRet, arquivos, talhoesNaoMapeados]);

  return (
    <ImportTalhoesContext.Provider
      value={{
        kmlValidationRet,
        safraPeriodo,
        step,
        arquivos,
        talhoesNaoMapeados,
        talhoesMapeados,
        talhoesToimport,
        talhoesAtualizados,
        file,
        setFile,
        setSafraPeriodo,
        setStep,
        setKMLValidationRet,
        handleChangeArquivoAtual,
        setTalhoesToImport,
        handleImportTalhoes,
        inputRef,
        handleClearFields,
      }}
    >
      {children}
    </ImportTalhoesContext.Provider>
  );
};

export { ImportTalhoesContext, ImportTalhoesContextProvider };
