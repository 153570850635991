import { useQuery } from "react-query";
import { httpAgrodrive } from "infra/http";
import URL from "core/resources/urls";
import config from "infra/config";

export default function useDownloadFiles(ids: string[]) {
  const fetchDownloadFiles = async () => {
    const { data } = await httpAgrodrive.get(
      URL.AGRODRIVE.ARQUIVOS_DOWNLOAD(ids),
      {
        headers: {
          "x-api-key": config.agrodriveClientId,
        },
      }
    );
    return data;
  };

  return useQuery(
    [URL.AGRODRIVE.ARQUIVOS_DOWNLOAD(ids), ids],
    fetchDownloadFiles
  );
}
