import React from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import DatatableAntd from "components/datatable-antd";
import FullWrapper from "components/wrappers/full-wrapper";
import { useFilters } from "core/features/filters/store";
import { useTableOrdensDeServicosAbertasPorEquipe } from "core/features/maps/hooks/useTableOrdensDeServicosAbertasPorEquipe";
import { MdClose } from "react-icons/md";
import { TableOrdensPorEquipeColumns } from "./utils";

interface TableOrdensPorEquipe {
  equipeName: string;
  equipeId: string;
  handleCloseModal: () => void;
}

const TableOrdensDeServicosAbertasPorEquipe = ({
  equipeName,
  equipeId,
  handleCloseModal,
}: TableOrdensPorEquipe) => {
  const { filters } = useFilters();
  const { unidade } = filters;

  const { data, isLoading } = useTableOrdensDeServicosAbertasPorEquipe(
    unidade!.id,
    equipeId
  );

  const ordens = data?.ordens;

  return (
    <FullWrapper
      backdropFilter="blur(2px)"
      bg={"rgba(0,0,0,.3)"}
      zIndex={3}
      overflowX="auto"
    >
      <Box
        background="white"
        borderRadius="10px"
        width="80%"
        maxWidth={"1001px"}
        padding="27px 35px 20px"
        position="relative"
      >
        <Button
          background="none"
          _hover={{}}
          _active={{}}
          _focus={{}}
          position="absolute"
          right="20px"
          top="27px"
          onClick={handleCloseModal}
        >
          <MdClose size={32} />
        </Button>
        <Flex
          flexDirection="column"
          borderBottom="1px solid"
          borderBottomColor="#E5E5E5"
          paddingBottom="15px"
          marginBottom="22px"
        >
          <Text fontWeight="semibold" fontSize="24px">
            Ordens de Serviço Abertas por Equipe
          </Text>
          <Text fontSize="18px" color="gray.700" fontWeight="normal">
            Equipe - {equipeName}
          </Text>
        </Flex>
        <Box borderBottom="1px solid black" paddingLeft="-2px">
          <DatatableAntd
            dataSource={ordens}
            columns={TableOrdensPorEquipeColumns}
            loading={isLoading}
            scroll={{ y: "calc(100vh - 420px)" }}
            rowKey={({ descricao, area }) => `${descricao}-${area}`}
          />
        </Box>
      </Box>
    </FullWrapper>
  );
};

export default TableOrdensDeServicosAbertasPorEquipe;
