import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { DateRange, DateRangeDelimiter } from "@material-ui/pickers";

import { green700 } from "core/resources/theme/colors";
import CustomInput from "./customInput";
import { AiFillCheckCircle } from "react-icons/ai";
import * as S from "./styles";
import { ErrorProps } from "components/maps/base-map/components/advancedFilters/items/utils/distribuicao-varietal-utils";

interface CalendarSelectProps {
  title: string;
  value: DateRange<Date>;
  errors: ErrorProps;
  onChange: (props: DateRange<Date>) => void;
  containerColumn?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const CalendarSelect = ({
  title,
  errors,
  value,
  onChange,
  containerColumn,
  minDate,
  maxDate,
}: CalendarSelectProps) => {
  return (
    <S.Container>
      <Box margin="8px 15px" padding={0}>
        <Flex alignItems="center" marginBottom="20px">
          <Text color="gray.700" fontSize="16px" marginRight="5px">
            {title}
          </Text>
          {value[0] || value[1] ? (
            <AiFillCheckCircle color={green700} size="1.2rem" />
          ) : null}
        </Flex>
        <Flex gap="25px">
          <S.Calendar
            calendars={1}
            startText="Data Inicial"
            endText="Data Final"
            value={value}
            minDate={minDate}
            maxDate={maxDate}
            allowSameDateSelection={true}
            onChange={(newValue: DateRange<Date | any>) => onChange(newValue)}
            renderInput={(startProps, endProps) => {
              return (
                <Flex
                  flexDir={containerColumn ? "column" : "row"}
                  gridGap={containerColumn ? "15px" : "0"}
                >
                  <CustomInput
                    {...startProps}
                    ref={startProps.inputRef as React.Ref<HTMLInputElement>}
                    label={startProps.label}
                    placeholder="Data Inicial"
                    isEqual={
                      startProps.inputProps?.value ===
                      endProps.inputProps?.value
                    }
                    errorMessage={errors?.initialDate}
                    largeInput={containerColumn}
                  />
                  <DateRangeDelimiter color="secondary" />
                  <CustomInput
                    {...endProps}
                    ref={endProps.inputRef as React.Ref<HTMLInputElement>}
                    label={endProps.label}
                    placeholder="Data Final"
                    isEqual={
                      startProps.inputProps?.value ===
                      endProps.inputProps?.value
                    }
                    errorMessage={errors?.finalDate}
                    largeInput={containerColumn}
                  />
                </Flex>
              );
            }}
          />
        </Flex>
      </Box>
    </S.Container>
  );
};

export default CalendarSelect;
