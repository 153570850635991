import { Button, ButtonProps, Icon } from "@chakra-ui/react";

import { BsArrowBarLeft } from "react-icons/bs";
import useFarmState from "core/features/farm/states/useFarmStore";

export default function Back(props: ButtonProps) {
  const { sector, field, update } = useFarmState();

  const handleClick = () => {
    if (sector && field) {
      update({ field: undefined });
      return;
    }
    update({ sector: undefined, field: undefined });
  };

  return (
    <Button
      leftIcon={<Icon as={BsArrowBarLeft} />}
      size="sm"
      onClick={handleClick}
      {...props}
    >
      Voltar
    </Button>
  );
}
