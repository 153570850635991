import {
  FiltroConcentracaoColheita,
  FiltroDistribuicaoDeEquipes,
  FiltroIdadeDoTalhao,
  FiltroMapaDeChuvas,
  FiltroNumeroDeAplicacoes,
  FiltroOperacoesExecutadas,
  FiltroOrdensDeServicosAbertas,
  FiltroProdutividade,
} from "features/maps/typings";
import { AxiosResponse } from "axios";
import { useFilters } from "features/filters/store";
import URL from "core/resources/urls";
import http from "infra/http";
import { useQuery } from "react-query";
import {
  DistribuicaoVarietalPostBody,
  TalhaoResult,
  TipoMapaTalhoes,
} from "../typings";
import { getUrlForTipoMapa, mapPropertiesFeature } from "../utils/mapper";
import { useMapStore } from "../store/useMapStore";

let controller: AbortController | null = null;
export function useTalhoesMapa() {
  const { filters } = useFilters();

  const { update: updateMapStore } = useMapStore();

  const {
    tipoMapa,
    filtroDistribuicaoVarietal,
    unidade,
    safra,
    texturas,
    filtroOperacoesExecutadas,
    filtroNumeroDeAplicacoes,
    filtroProdutividade,
    filtroConcentracaoColheita,
    filtroIdadeDoTalhao,
    filtroOrdensDeServicosAbertas,
    filtroDistribuicaoDeEquipes,
    filtroMapaDeChuva,
    setor,
  } = filters;

  const unidadeId = unidade?.id;
  const setorId = setor?.id;
  const safraId = safra?.id;
  const filtro = filtroDistribuicaoVarietal;

  const url = getUrlForTipoMapa(tipoMapa, unidadeId, safraId);
  const queryKey = url;

  const requestBody: DistribuicaoVarietalPostBody = {
    setorId,
    ocupacoes: filtro?.ocupacoes.map((ocupacao) => ocupacao.codigo) || [],

    finalidades:
      filtro?.finalidades.map((finalidade) => ({
        color: finalidade.color,
        finalidadeId: finalidade.id,
        ocupacao: finalidade.ocupacaoCodigo,
      })) || [],
    tecnologias:
      filtro?.tecnologias.map((tecnologia) => ({
        color: tecnologia.color,
        finalidadeId: tecnologia.finalidadeId,
        ocupacao: tecnologia.ocupacaoCodigo,
        tecnologiaId: tecnologia.id,
      })) || [],
    variedadeGrupos:
      (filtro &&
        filtro.variedadeGrupos &&
        filtro.variedadeGrupos.length &&
        filtro?.variedadeGrupos.map((variedadeGrupo) => ({
          color: variedadeGrupo.color,
          finalidadeId: variedadeGrupo.finalidadeId,
          tecnologiaId: variedadeGrupo.tecnologiaId,
          ocupacao: variedadeGrupo.ocupacaoCodigo,
          variedadeGrupoId: variedadeGrupo.id,
        }))) ||
      [],
    variedades:
      filtro?.variedades.map((variedade) => ({
        color: variedade.color,
        finalidadeId: variedade.finalidadeId,
        ocupacao: variedade.ocupacaoCodigo,
        tecnologiaId: variedade.tecnologiaId,
        variedadeGrupoId: variedade.variedadeGrupoId,
        variedadeId: variedade.id,
      })) || [],
    periodoPlantio: {
      dataInicial: filtro?.periodoPlantio?.dataInicial,
      dataFinal: filtro?.periodoPlantio?.dataFinal,
    },
    periodoEmergencia: {
      dataInicial: filtro?.periodoEmergencia?.dataInicial,
      dataFinal: filtro?.periodoEmergencia?.dataFinal,
    },
    periodoColheita: {
      dataInicial: filtro?.periodoColheita?.dataInicial,
      dataFinal: filtro?.periodoColheita?.dataFinal,
    },
    periodoPrevisaoColheita: {
      dataInicial: filtro?.periodoPrevisaoDeColheita?.dataInicial,
      dataFinal: filtro?.periodoPrevisaoDeColheita?.dataFinal,
    },
  };

  const headers = {
    "api-version": "1",
  };

  const onLoad = async (): Promise<TalhaoResult> => {
    if (controller) {
      controller.abort();
      controller = null;
    }

    if (tipoMapa === TipoMapaTalhoes.DistribuicaoVarietal) {
      controller = new AbortController();

      const { data } = await http.post<
        DistribuicaoVarietalPostBody,
        AxiosResponse<TalhaoResult>
      >(url, requestBody, {
        headers,
        signal: controller.signal,
      });

      data.features.forEach(mapPropertiesFeature);

      return data;
    } else if (tipoMapa === TipoMapaTalhoes.Texturas) {
      controller = new AbortController();
      const codigos = texturas?.map((t) => t.codigo) || [];

      const { data } = await http.get<TalhaoResult>(
        URL.MAPAS.MAPA_TEXTURAS_SOLO(unidadeId!, codigos!),
        {
          headers,
          params: {
            setorId,
          },
          signal: controller.signal,
        }
      );
      return data;
    } else if (tipoMapa === TipoMapaTalhoes.OperacoesExecutadas) {
      if (
        filtroOperacoesExecutadas?.ocupacao &&
        filtroOperacoesExecutadas.tecnologias.length > 0
      ) {
        controller = new AbortController();

        const { data } = await http.post<
          FiltroOperacoesExecutadas,
          AxiosResponse<TalhaoResult>
        >(
          url,
          {
            ...filtroOperacoesExecutadas,
            setorId,
          } as FiltroOperacoesExecutadas,
          {
            headers,
            signal: controller.signal,
          }
        );

        data.features.forEach(mapPropertiesFeature);

        return data;
      }
    } else if (tipoMapa === TipoMapaTalhoes.ConcentracaoColheita) {
      controller = new AbortController();

      if (
        filtroConcentracaoColheita?.concentracoes.length &&
        filtroConcentracaoColheita?.ocupacao &&
        filtroConcentracaoColheita?.tipo &&
        filtroConcentracaoColheita.tecnologias.length > 0
      ) {
        const tecnologias = filtroConcentracaoColheita.tecnologias.filter(
          (item) => item
        );

        const { data } = await http.post<
          FiltroConcentracaoColheita,
          AxiosResponse<TalhaoResult>
        >(
          url,
          { ...filtroConcentracaoColheita, setorId, tecnologias },
          {
            headers,
            signal: controller.signal,
          }
        );

        data.features.forEach(mapPropertiesFeature);

        return data;
      }
    } else if (tipoMapa === TipoMapaTalhoes.NumeroDeAplicacoes) {
      if (
        filtroNumeroDeAplicacoes?.ocupacao &&
        filtroNumeroDeAplicacoes.tecnologias.length > 0 &&
        filtroNumeroDeAplicacoes.insumoGrupo
      ) {
        controller = new AbortController();

        const { data } = await http.post<
          FiltroNumeroDeAplicacoes,
          AxiosResponse<TalhaoResult>
        >(
          url,
          { ...filtroNumeroDeAplicacoes, setorId } as FiltroNumeroDeAplicacoes,
          {
            headers,
            signal: controller.signal,
          }
        );

        data.features.forEach(mapPropertiesFeature);

        return data;
      }
    } else if (tipoMapa === TipoMapaTalhoes.Produtividade) {
      if (
        filtroProdutividade?.ocupacao &&
        filtroProdutividade.tecnologias.length > 0
      ) {
        controller = new AbortController();

        const { data } = await http.post<
          FiltroProdutividade,
          AxiosResponse<TalhaoResult>
        >(url, { ...filtroProdutividade, setorId } as FiltroProdutividade, {
          headers,
          signal: controller.signal,
        });

        data.features.forEach(mapPropertiesFeature);

        return data;
      }
    } else if (tipoMapa === TipoMapaTalhoes.IdadeDoTalhao) {
      if (
        filtroIdadeDoTalhao?.ocupacao &&
        filtroIdadeDoTalhao.tecnologias.length > 0
      ) {
        controller = new AbortController();

        const { data } = await http.post<
          FiltroIdadeDoTalhao,
          AxiosResponse<TalhaoResult>
        >(url, { ...filtroIdadeDoTalhao, setorId } as FiltroIdadeDoTalhao, {
          headers,
          signal: controller.signal,
        });

        data.features.forEach((item) => {
          item.properties.strokeOpacity = Number(item.properties.strokeOpacity);
          item.properties.fillOpacity = Number(item.properties.fillOpacity);
          item.properties.strokeWidth = Number(item.properties.strokeWidth);
        });

        return data;
      }
    } else if (tipoMapa === TipoMapaTalhoes.OrdensDeServicosAberta) {
      if (filtroOrdensDeServicosAbertas) {
        controller = new AbortController();
        const { data } = await http.post<
          FiltroOrdensDeServicosAbertas,
          AxiosResponse<TalhaoResult>
        >(
          url,
          {
            ...filtroOrdensDeServicosAbertas,
            setorId,
          } as FiltroOrdensDeServicosAbertas,
          {
            signal: controller.signal,
          }
        );

        data.features.forEach((item) => {
          item.properties.strokeOpacity = Number(item.properties.strokeOpacity);
          item.properties.fillOpacity = Number(item.properties.fillOpacity);
          item.properties.strokeWidth = Number(item.properties.strokeWidth);
        });

        return data;
      }
    } else if (tipoMapa === TipoMapaTalhoes.DistribuicaoDeEquipes) {
      if (filtroDistribuicaoDeEquipes) {
        controller = new AbortController();
        const { data } = await http.post<
          FiltroDistribuicaoDeEquipes,
          AxiosResponse<TalhaoResult>
        >(
          url,
          {
            ...filtroDistribuicaoDeEquipes,

            setorId: setorId,
          },
          {
            signal: controller.signal,
          }
        );

        data.features.forEach((item) => {
          item.properties.strokeOpacity = Number(item.properties.strokeOpacity);
          item.properties.fillOpacity = Number(item.properties.fillOpacity);
          item.properties.strokeWidth = Number(item.properties.strokeWidth);
        });

        return data;
      }
    } else if (tipoMapa === TipoMapaTalhoes.Chuvas) {
      if (filtroMapaDeChuva) {
        controller = new AbortController();
        const { data } = await http.post<
          FiltroMapaDeChuvas,
          AxiosResponse<TalhaoResult>
        >(
          url,
          {
            ...filtroMapaDeChuva,
            setorId: setorId,
          },
          {
            signal: controller.signal,
          }
        );

        return data;
      }
    }

    return new Promise((resolve) =>
      resolve({ features: [], type: "FeatureCollection", Grid: [] })
    );
  };

  return useQuery(
    [
      queryKey,
      setorId,
      filtro,
      tipoMapa,
      texturas,
      filtroOperacoesExecutadas,
      filtroConcentracaoColheita,
      filtroNumeroDeAplicacoes,
      filtroProdutividade,
      filtroIdadeDoTalhao,
      filtroOrdensDeServicosAbertas,
      filtroDistribuicaoDeEquipes,
      filtroMapaDeChuva,
      headers,
    ],
    async () => {
      const data = await onLoad();

      updateMapStore(data);

      return data;
    },
    {
      enabled:
        tipoMapa === TipoMapaTalhoes.DistribuicaoVarietal
          ? !!(unidadeId && safraId)
          : !!unidadeId,
    }
  );
}
