import { Flex, IconButton } from "@chakra-ui/react";
import { FaChevronUp } from "react-icons/fa";
import ContainerFilter from "../../ContainerFilter";
import MapaDeChuvaActualIndiceDates from "./components/MapaDeChuvaActualIndiceDates";
import { MapaDeChuvaIndices } from "./components/MapaDeChuvaIndices";
import { MapaDeChuvaPeriodos } from "./components/MapaDeChuvaPeriodos";

interface FiltroMapaDeChuvaProps {
  isVisible: boolean;
  handleChangeIsVisible: (prop: boolean) => void;
}

const FiltroMapaDeChuva = ({
  handleChangeIsVisible,
  isVisible,
}: FiltroMapaDeChuvaProps) => {
  return (
    <ContainerFilter
      containerProps={{
        mt: 3,
        rounded: 8,
        padding: 2,
        zIndex: 3,
      }}
      isVisible={isVisible}
    >
      <Flex>
        <MapaDeChuvaPeriodos isVisible={isVisible} />
      </Flex>
      <MapaDeChuvaActualIndiceDates />
      <Flex>
        <MapaDeChuvaIndices />
      </Flex>
      <Flex
        padding="5px"
        flexDirection="row-reverse"
        borderTop="1px solid #84848440 "
        marginTop="10px"
      >
        <IconButton
          background="none"
          _hover={{}}
          _focus={{}}
          _active={{}}
          icon={<FaChevronUp color="#848484" />}
          aria-label="close-button"
          marginRight="20px"
          onClick={() => handleChangeIsVisible(false)}
        />
      </Flex>
    </ContainerFilter>
  );
};

export default FiltroMapaDeChuva;
