import { Text, Tooltip, Box, Grid, GridItem } from "@chakra-ui/react";
import { IoEye } from "react-icons/io5";
import { FaPlusCircle, FaCheckCircle, FaCheckDouble } from "react-icons/fa";
import { formataData } from "../../utils/formataData";

export const atrasosColumsName = (
  openAtrasosModal: any,
  openJustificativasModal: any,
) => [
  {
    title: "Fazenda",
    render: (atraso: any) => {
      return (
        <Text fontWeight="regular" fontSize="0.8rem">
          {atraso.fazenda}
        </Text>
      );
    },
    width: 50,
    align: "center" as const,
  },
  {
    title: "Período de Produção",
    render: (atraso: any) => {
      return (
        <Text fontWeight="regular" fontSize="0.8rem">
          {atraso.periodoProducao}
        </Text>
      );
    },
    width: 100,
    align: "center" as const,
  },
  {
    title: "Talhão",
    render: (atraso: any) => {
      return (
        <Text fontWeight="regular" fontSize="0.8rem">
          {atraso.talhaoCodigo}
        </Text>
      );
    },
    width: 50,
    align: "center" as const,
  },
  {
    title: "Área (ha)",
    render: (atraso: any) => {
      return (
        <Text fontWeight="regular" fontSize="0.8rem">
          {atraso.areaPlanejada}
        </Text>
      );
    },
    width: 70,
    align: "center" as const,
  },
  {
    title: "Operação",
    render: (atraso: any) => {
      return (
        <Text fontWeight="regular" fontSize="0.8rem">
          {atraso.operacaoDescricao}
        </Text>
      );
    },
    width: 110,
    align: "center" as const,
  },
  {
    title: "Data Programada",
    render: (atraso: any) => {
      return (
        <Text fontWeight="regular" fontSize="0.8rem">
          {formataData(atraso.dataProgramada)}
        </Text>
      );
    },
    width: 90,
    align: "center" as const,
  },
  {
    title: "Dias em Atraso",
    render: (atraso: any) => {
      return (
        <Text fontWeight="regular" fontSize="0.8rem">
          {atraso.dias}
        </Text>
      );
    },
    width: 60,
    align: "center" as const,
  },
  {
    title: "Ordem Serviço",
    render: (atraso: any) => {
      return (
        <Text fontWeight="regular" fontSize="0.8rem">
          {atraso.ordemServico ?? "-"}
        </Text>
      );
    },
    width: 60,
    align: "center" as const,
  },
  {
    title: "Ações",
    render: (atraso: any) => {
      return (
        <Grid templateColumns="repeat(4, 1fr)">
          <GridItem>
            <Tooltip placement="left" hasArrow label="Adicionar justificativa">
              <Box p={1}>
                <FaPlusCircle onClick={() => openAtrasosModal(atraso)} size="16px" />
              </Box>
            </Tooltip>
          </GridItem>

          {atraso.justificado && (
            <GridItem>
              <Tooltip placement="left" hasArrow label="Ver justificativas">
                <Box p={1}>
                  <IoEye onClick={() => openJustificativasModal(atraso)} size="20px" />
                </Box>
              </Tooltip>
            </GridItem>
          )}

          {atraso.aprovado && (
            <GridItem>
              <Tooltip placement="left" hasArrow label="Aprovado">
                <Box p={1}>
                  <FaCheckCircle size="17px" color="#198754" />
                </Box>
              </Tooltip>
            </GridItem>
          )}

          {atraso.ciente && (
            <GridItem>
              <Tooltip placement="left" hasArrow label="Aprovado por gerente regional">
                <Box p={1}>
                  <FaCheckDouble size="17px" color="#198754" />
                </Box>
              </Tooltip>
            </GridItem>
          )}
        </Grid>
      );
    },
    width: 60,
    align: "center" as const,
  }
];
