import { Button, Image, Tooltip } from "@chakra-ui/react";

interface ButtonMenuProps {
  isActive: boolean;
  srcImage: string;

  label: string;
  onClick?: () => void;
  rotate?: boolean
}

const ButtonMenu = ({
  isActive,
  srcImage,

  label,
  onClick,
  rotate = true,
}: ButtonMenuProps) => {
  return (
    <Tooltip label={label} placement="right" hasArrow>
      <Button
        _hover={{}}
        _active={{}}
        _focus={{}}
        background=""
        boxShadow={isActive ? " 0px 6px 10px #8E8E8E33" : ""}
        backgroundImage={"linear-gradient(#11A557,#11A557)"}
        backgroundRepeat="no-repeat"
        backgroundPosition={isActive ? "77px 0px" : "77px 52px"}
        color="white"
        width="100%"
        height="52px"
        marginBottom="10px"
        onClick={onClick}
        transition="all .7s"
        borderRadius="0"
      >
        {rotate ? (
          <Image src={srcImage} transform="rotate(90deg)" />
        ) : (
          <Image src={srcImage} />
        )}
      </Button>
    </Tooltip>
  );
};

export default ButtonMenu;
