import { useConcentracaoColheitaStore } from "features/filters/store/useConcentracaoColheitaStore";
import { ConcentracaoColheitaTipo } from "features/maps/typings";
import { useFiltersLists } from "features/filters/store/lists";
import { useEffect, useState } from "react";

type Item = {
  label: string;
  value: ConcentracaoColheitaTipo;
  enabled: boolean;
};

export function useConcentracaoColheitaColhidoFilters() {
  const {
    filters: { tecnologias, ocupacao },
  } = useConcentracaoColheitaStore();

  const { concentracaoColheita } = useFiltersLists();

  const [items, setItems] = useState<Item[]>([]);

  const isColhidoEnabled = concentracaoColheita?.concentracoes
    .filter((item) => item.ocupacao === ocupacao)
    .filter((i) => tecnologias.includes(i.tecnologiaId))
    .filter((it) => it.tipo === ConcentracaoColheitaTipo.Colhido).length
    ? true
    : false;

  const isPrevistoEnabled = concentracaoColheita?.concentracoes
    .filter((item) => item.ocupacao === ocupacao)
    .filter((i) => tecnologias.includes(i.tecnologiaId))
    .filter((it) => it.tipo === ConcentracaoColheitaTipo.Previsto).length
    ? true
    : false;

  const isAmbosEnabled = isColhidoEnabled && isPrevistoEnabled;

  useEffect(() => {
    const nextItems: Item[] = [];

    nextItems.push({
      label: "Já Colhido",
      enabled: isColhidoEnabled,
      value: ConcentracaoColheitaTipo.Colhido,
    });

    nextItems.push({
      label: "Previsto",
      enabled: isPrevistoEnabled,
      value: ConcentracaoColheitaTipo.Previsto,
    });

    nextItems.push({
      label: "Ambos",
      enabled: isAmbosEnabled,
      value: ConcentracaoColheitaTipo.Ambos,
    });

    setItems(nextItems);
  }, [isAmbosEnabled, isColhidoEnabled, isPrevistoEnabled]);

  return {
    items,
  };
}
