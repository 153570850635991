import URL from "core/resources/urls";
import http from "infra/http";
import { useQuery } from "react-query";
import { TexturaSoloListagemItem, TipoMapaTalhoes } from "../typings";

export function useTexturasSolo(tipoMapa: TipoMapaTalhoes) {
  return useQuery(
    URL.MAPAS.TEXTURAS_SOLO,
    async () => {
      const { data } = await http.get<TexturaSoloListagemItem[]>(
        URL.MAPAS.TEXTURAS_SOLO
      );

      return data;
    },
    {
      enabled: tipoMapa === TipoMapaTalhoes.Texturas,
    }
  );
}
