import { Flex, Text } from "@chakra-ui/react";

import { formatMoney } from "infra/helpers/number";

const TableFooter = ({
  total,
  custo,
  tipo,
}: {
  total: number;
  custo: number;
  tipo: string;
}) => {
  return (
    <Flex flex={1}>
      <Flex>
        <Text mr={2} textTransform="uppercase">
          TOTAL DE APLICAÇÕES DE {tipo}:{" "}
        </Text>

        <Text fontWeight="bold">{total ?? 0}</Text>
      </Flex>

      <Flex flex={1} />

      <Flex>
        <Text mr={2}>CUSTO TOTAL: </Text>
        <Text fontWeight="bold">
          {formatMoney(custo ?? 0).replace("R$", "US$/HA")}
        </Text>
      </Flex>
    </Flex>
  );
};

export default TableFooter;
