import React from "react";
import { Button, Text } from "@chakra-ui/react";
import { CurrentViewTypeEnum } from "../../typings";

interface ControlViewTypeProps {
  currentType: CurrentViewTypeEnum;
  handleChange: (prop: CurrentViewTypeEnum) => void;
}

const ControlViewType = ({
  handleChange,
  currentType,
}: ControlViewTypeProps) => {
  const fontColor = {
    box: "#000",
    numberOnly: "green.800",
    transparent: "green.300",
  };

  const handleChangeCurrentType = () => {
    switch (currentType) {
      case CurrentViewTypeEnum.box: {
        handleChange(CurrentViewTypeEnum.numberOnly);
        break;
      }
      case CurrentViewTypeEnum.numberOnly: {
        handleChange(CurrentViewTypeEnum.transparent);
        break;
      }
      case CurrentViewTypeEnum.transparent: {
        handleChange(CurrentViewTypeEnum.box);
        break;
      }
      default: {
        handleChange(CurrentViewTypeEnum.box);
        break;
      }
    }
  };

  return (
    <Button
      onClick={handleChangeCurrentType}
      size="sm"
      borderRadius={15}
      fontSize={"12px"}
      // color="green.600"
      fontWeight="bold"
      padding={0}
      _active={{}}
      _focus={{}}
      _hover={{}}
    >
      <Text
        display="flex"
        boxShadow={
          currentType === CurrentViewTypeEnum.box
            ? "0px -3px 6px #00000000"
            : "0px -3px 6px #00000050"
        }
        transition="all .7s"
        height="80%"
        width="80%"
        borderRadius={15}
        alignItems="center"
        justifyContent="center"
        color={fontColor[currentType]}
      >
        123
      </Text>
    </Button>
  );
};

export default ControlViewType;
