import { FilterIndex } from "components/inputs/filter-safra-unidade-mapatematico/enum";
import create from "zustand";

export interface MenuFilterIndexState {
  indexOpen: number | null;
  setIndexOpen: (index: number | null) => any;
}

export const useMenuFilterIndex = create<MenuFilterIndexState>((set) => ({
  indexOpen: FilterIndex.Textura_Solo,
  setIndexOpen: (index) => {
    set((state) => ({
      ...state,
      indexOpen: index,
    }));
  },
}));
