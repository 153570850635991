import styled from "styled-components";

interface TagProps {
  selected?: boolean;
}

export const MainTile = styled.div`
  width: 350px;
  height: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 1rem;
  background: #ffffff;
`;

export const TileTitle = styled.span`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const TileInput = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const TagArea = styled.div`
  height: 180px;
  overflow-y: auto !important;
`;

export const TagCloud = styled.div`
  display: flex;
  margin-top: 0.5rem;
  flex-wrap: wrap;
  height: auto;
`;

export const TagEmptyState = styled.div`
  width: auto;
`;

export const Tag = styled.div<TagProps>`
  border-radius: 15px;

  ${(props) =>
    props.selected ? `background: #14A457;` : `background: #e3e3e3;`};

  ${(props) => (props.selected ? `color: white;` : `color: gray;`)};

  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
  padding-right: 0.6rem;
  padding-left: 0.6rem;
  font-size: 1rem;
  height: 2rem;
  display: inline-block;
  margin: 0.2rem;
  cursor: pointer;
`;
