import { Flex, Text } from "@chakra-ui/react";
import { TipoMapaTalhoes } from "core/features/maps/typings";
import { useFilters } from "features/filters/store";
import { useSetores } from "features/unidades/hooks/useSetores";

import { useDetectClickOutside } from "react-detect-click-outside";
import SearchSkeleton from "./SearchSkeleton";
import SetorTag from "./SetorTag";

function SearchCollpase({
  children,
  onClose,
  open,
}: {
  children: any;
  onClose: () => void;
  open: boolean;
}) {
  const ref = useDetectClickOutside({
    onTriggered: () => {
      onClose();
    },
  });

  const {
    filters: { unidade, safra, setor, tipoMapa },
    updateField,
  } = useFilters();

  const { data: setores, isLoading } = useSetores(
    unidade?.id!,
    safra?.id!,
    open
  );

  const isSatellite = tipoMapa === TipoMapaTalhoes.Satelite;

  return (
    <Flex ref={ref} position="relative" width="350px">
      <Flex pos="absolute" zIndex={2} left={0} right={0}>
        {children}
      </Flex>

      {open && !isSatellite ? (
        <Flex
          pos="absolute"
          bg="white"
          top="25px"
          right={"1px"}
          left={"1px"}
          zIndex={1}
          borderBottomRadius="24px"
          shadow="md"
        >
          <Flex flexDir="column" px="16px" py="32px">
            <Text fontWeight="bold" color="gray.800">
              Setores
            </Text>
            <Flex mt="8px" gridGap="8px" flexWrap="wrap">
              {setores?.map((item, index) => (
                <Flex key={index}>
                  <SetorTag
                    onClick={() => updateField("setor", { ...item })}
                    value={setor}
                    item={item}
                  />
                </Flex>
              ))}

              {isLoading ? <SearchSkeleton /> : null}
            </Flex>
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  );
}

export default SearchCollpase;
