import { Flex } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";

import { gray800, green700 } from "core/resources/theme/colors";
import {
  FiltroNumeroDeAplicacoes,
  NumeroDeAplicacoesInsumos,
} from "core/features/maps/typings";

const Insumos = ({
  insumos,
  filter,
  update,
  isInsumoDisabled,
}: {
  insumos?: NumeroDeAplicacoesInsumos[];
  filter: FiltroNumeroDeAplicacoes;
  update: (value: Partial<FiltroNumeroDeAplicacoes>, merge?: boolean) => any;
  isInsumoDisabled: (codigo: number) => boolean;
}) => {
  
  return (
    <Flex flexWrap="wrap" alignItems="center" mt={6} flexDir="row">
      {insumos &&
        insumos.length > 0 &&
        insumos.map((item, index) => (
          <Button
            key={item.codigo + index}
            disabled={!item.enabled || isInsumoDisabled(item.codigo)}
            data-testid={`insumo-${item.descricao}`}
            justifyContent="space-around"
            alignItems="center"
            _hover={{
              backgroundColor: "none",
            }}
            ml={2}
            onClick={() => {
              const foundCompativeAplicacoes =
                filter.aplicacoes.filter(
                  (item) => item.insumoGrupo === item.codigo
                ) || [];
              update({
                aplicacoes: foundCompativeAplicacoes,
                insumoGrupo: item.codigo,
                ocupacao: filter.ocupacao,
                tecnologias: filter.tecnologias,
              });
            }}
            mt={2}
            color={filter.insumoGrupo === item.codigo ? "white" : gray800}
            h="32px"
            bg={filter.insumoGrupo === item.codigo ? green700 : "white"}
            border={
              filter.insumoGrupo === item.codigo
                ? "none"
                : `1px solid ${gray800}`
            }
            rounded={30}
          >
            {item.descricao}
          </Button>
        ))}
    </Flex>
  );
};

export default Insumos;
