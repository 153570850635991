import create from "zustand";

export type SearchState = {
    query: string | undefined;
    setQuery: (query: string | undefined) => void;
    reset: () => void;
}

const useSearchStore = create<SearchState>(set => ({
    query: undefined,
    setQuery: (query: string | undefined) => set((state: SearchState) => ({ ...state, query })),
    reset: () => set(() => ({
        query: undefined
    }))
}));

export default useSearchStore;
