import { ApiPagination } from "./../typings";
import { StatusOrdemServicoEnum } from "components/maps/mapa-geral/components/TabOrdensServico/ActionButtons";
import URL from "core/resources/urls";
import http from "infra/http";
import { useInfiniteQuery } from "react-query";
import { TalhaoOrdensServico } from "../typings/ordensServico";

interface useTalhaoOrdensServicoProps {
  talhaoId: string | null;
  numeroOs?: number;
  status?: StatusOrdemServicoEnum;
}

export default function useTalhaoOrdensServico({
  talhaoId,
  numeroOs,
  status = StatusOrdemServicoEnum.Todas,
}: useTalhaoOrdensServicoProps) {
  return useInfiniteQuery(
    [URL.TALHOES.ORDENS_SERVICO(talhaoId!), numeroOs, status],
    async ({ pageParam }) => {
      const { data, headers } = await http.get(
        URL.TALHOES.ORDENS_SERVICO(talhaoId!),
        {
          params: {
            page: pageParam ?? 1,
            numeroOs: !!numeroOs ? numeroOs : undefined,
            status,
            size: 100,
          },
        }
      );
      const { paginate } = headers;

      return { ordens: data, paginate: JSON.parse(paginate) } as {
        ordens: TalhaoOrdensServico[];
        paginate: ApiPagination;
      };
    },
    {
      enabled: !!talhaoId,
      getNextPageParam: (lastPage, pages) => lastPage.paginate.currentPage + 1,
    }
  );
}
