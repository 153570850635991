import URL from "core/resources/urls";
import http from "infra/http";
import { TalhaoOrdensServicoTelemetria } from "../typings/ordensServico";
import { useQuery } from "react-query";

const useTalhaoOrdensServicoTelemetria = (
  talhaoId: string,
  ordemServicoId: string
) => {
  return useQuery(
    URL.TALHOES.ORDENS_SERVICO_TELEMETRIA(talhaoId!, ordemServicoId!),
    async () => {
      const { data } = await http.get(
        URL.TALHOES.ORDENS_SERVICO_TELEMETRIA(talhaoId!, ordemServicoId!)
      );

      return data as TalhaoOrdensServicoTelemetria;
    },
    {
      enabled: !!talhaoId && !!ordemServicoId,
    }
  );
};

export default useTalhaoOrdensServicoTelemetria;
