import URL from "core/resources/urls";
import config from "infra/config";
import { httpAgrodrive } from "infra/http";
import { useQuery } from "react-query";
import { CustomTag } from "../types/tags";

export default function useGetTags() {
  return useQuery([URL.AGRODRIVE.TAGS()], async () => {
    const { data } = await httpAgrodrive.get(URL.AGRODRIVE.TAGS(), {
      headers: {
        "x-api-key": config.agrodriveClientId,
      },
    });

    return data as CustomTag[];
  });
}
