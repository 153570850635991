import { Image } from "@chakra-ui/react";
import { ColumnType } from "antd/lib/table";
import { AiFillCloseCircle, AiFillCheckCircle } from "react-icons/ai";
import TalhoesImage from "assets/images/talhoes.svg";
import { TalhoesProps } from "core/features/talhoes/typings/listaEGeojson";

var numberRegexPattern = /[^0-9]/g;

export const tableColumns: ColumnType<TalhoesProps>[] = [
  {
    sorter: (a, b) => {
      const codigo1 = Number(a.codigo.replace(numberRegexPattern, ""));
      const codigo2 = Number(b.codigo.replace(numberRegexPattern, ""));

      return +codigo1 - +codigo2;
    },
    sortDirections: ["descend"],
    title: "TALHÃO",
    dataIndex: "codigo",
    width: 80,
  },
  {
    title: "VARIEDADE",
    dataIndex: "variedade",
  },
  {
    title: "ÁREA (HA)",
    dataIndex: "areaHa",
    width: 100,
  },
  {
    title: () => {
      return <Image src={TalhoesImage} width="20px" margin="0 auto" />;
    },
    sorter: (a, b) => {
      return a.possuiGeometria ? 0 : 1;
    },
    sortDirections: ["descend"],
    width: 80,

    align: "center",
    render: (props) => {
      return props.possuiGeometria ? (
        <AiFillCheckCircle
          color="green"
          style={{
            margin: "0 auto",
          }}
        />
      ) : (
        <AiFillCloseCircle
          color="red"
          style={{
            margin: "0 auto",
          }}
        />
      );
    },
  },
];
