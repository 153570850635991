import { DEFAULT_ZOOM } from "../config";
import { LatLong } from "core/features/maps/typings";
import { MapTypeId } from "core/features/maps/enums";

export function useControls(map: google.maps.Map) {
  const minusZoom = () => {
    const zoom = map.getZoom() ?? 0;

    map.setZoom(zoom - 1);
  };

  const addZoom = () => {
    const zoom = map.getZoom() ?? 0;

    map.setZoom(zoom + 1);
  };

  const resetNavigate = (center: LatLong) => {
    map?.setCenter({
      lat: center.lat,
      lng: center.long,
    });
    map.setZoom(DEFAULT_ZOOM);
  };

  const setMapType = (type: MapTypeId) => {
    map.setMapTypeId(type);
  };

  return {
    addZoom,
    minusZoom,
    setMapType,
    resetNavigate,
  };
}
