import { useFiltersLists } from "features/filters/store/lists";

const useOrdensDeServicoAbertaFilters = () => {
  const { ordensDeServicosAbertas, loadings } = useFiltersLists();

  // useEffect(() => {
  //   if (
  //     !ordensDeServicosAbertas ||
  //     filters.filtroOrdensDeServicosAbertas?.ocupacao
  //   ) {
  //     return;
  //   }

  //   const operacoesToKeep = operacoesByOcupacao?.filter((item) =>
  //     filtroOrdensDeServicosAbertas?.operacoes.find(
  //       (opFilter) => opFilter.descricao === item.descricao
  //     )
  //   );
  //   const removedDuplicatedOp = ordensDeServicosAbertas.operacoes.filter(
  //     (v, i, a) => a.findIndex((t) => t.descricao === v.descricao) === i
  //   );

  //   const ocupacao = ordensDeServicosAbertas.ocupacoes.find(
  //     (o) => o.enabled
  //   )?.codigo;

  //   const opByOcupacao = removedDuplicatedOp.filter(
  //     (item) => item.ocupacao === ocupacao
  //   );

  //   const [enabledPe] = ordensDeServicosAbertas.periodos.filter(
  //     (periodo) =>
  //       periodo.enabled &&
  //       opByOcupacao.find((op) => op.periodo === periodo.periodo)
  //   );

  //   if (enabledPe) {
  //     const ocupacaoEnabed = ordensDeServicosAbertas.ocupacoes.find(
  //       (o) => o.enabled
  //     );

  //     updateFiltroOrdensDeServicosAbertas({
  //       ocupacao: ocupacaoEnabed?.codigo,

  //       operacoes: operacoesToKeep?.length ? operacoesToKeep : [],

  //       periodos: [enabledPe.periodo],
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [
  //   ordensDeServicosAbertas,
  //   filtroOrdensDeServicosAbertas?.operacoes.length,
  //   filters.filtroOrdensDeServicosAbertas?.ocupacao,
  // ]);

  return {
    isLoading: loadings.ordensDeServicosAbertas,
    ordensDeServicosAbertas,
  };
};

export default useOrdensDeServicoAbertaFilters;
