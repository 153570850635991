import Swal, { SweetAlertOptions } from "sweetalert2";
import ImageTest from "../../assets/images/not-found.png";
import { primary } from "core/resources/theme/colors";

export function showSuccess(
  message = "Operação realizada com sucesso",
  title = "Sucesso",
  confirmButtonText = "OK"
) {
  return Swal.fire({
    title,
    text: message,
    icon: "success",
    confirmButtonText,
  });
}

export function showError(
  message = "Houve um problema ao realizar esta operação.",
  title = "Erro"
) {
  const isUserNotFounded = title === "Usuário não encontrado";

  return Swal.fire({
    title: title,
    html: message,
    icon: !isUserNotFounded ? "error" : undefined,
    confirmButtonColor: primary,
    imageUrl: isUserNotFounded ? ImageTest : "",
  });
}

export function showConfirm(
  message = "Deseja realmente realizar esta operação?",
  config: SweetAlertOptions = {}
) {
  return Swal.fire({
    showCancelButton: true,
    cancelButtonText: "Cancelar",
    title: "Aviso",
    icon: "info",
    iconColor: primary,
    text: message,
    confirmButtonText: "Confirmar",
    confirmButtonColor: primary,
    ...config,
  });
}

export function showLoading() {
  Swal.showLoading();
}

export function hideLoading() {
  Swal.close();
}
