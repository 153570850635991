import { useFindEnvs } from "core/features/env/hooks/useFindEnvs";
import { useEnvs } from "core/features/env/store";
import { ReactChild, ReactChildren, ReactElement, useEffect } from "react";
import { init as initMixPanel } from "infra/packages/react-mixpanel";

interface EnvProviderProps {
  children: ReactElement | ReactChild | ReactChildren | JSX.Element;
}

export function EnvProvider({ children }: EnvProviderProps) {
  const { update } = useEnvs();

  const { data, isLoading } = useFindEnvs();

  useEffect(() => {
    if (data) {
      update(data);
      initMixPanel();
    }
  }, [data, update]);

  if (isLoading) {
    return null;
  }

  return <>{children}</>;
}
