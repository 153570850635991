export enum MapFilterIndex {
  DISTRIBUICAO_VARIETAL = 3,
  CHUVAS = 4,
  CONCENTRACAO_COLHEITA = 5,
  SATELLITE_IMAGE = 6,
  TEXTURA_SOLO = 7,
  OPERACOES_EXECUTADAS = 8,
  NUMERO_DE_APLICACOES = 9,
  PRODUTIVIDADE = 10,
  IDADE_DO_TALHAO = 11,
  ORDENS_DE_SERVICO_ABERTA = 12,
  DISTRIBUICAO_DE_EQUIPES = 13,
}
export interface Types_Map {
  id: string;
  nome: string;
  enabled?: boolean;
  filterMenuIndex: MapFilterIndex;
}

export enum TypesMapEnum {
  DISTRIBUICAO_VARIETAL = "1",
  CHUVAS = "2",
  CONCENTRACAO_COLHEITA = "3",
  SATELLITE_IMAGE = "4",
  TEXTURA_SOLO = "5",
  OPERACOES_EXECUTADAS = "6",
  NUMERO_DE_APLICACOES = "7",
  PRODUTIVIDADE = "8",
  IDADE_DO_TALHAO = "9",
  ORDENS_DE_SERVICO_ABERTA = "10",
  DISTRIBUICAO_DE_EQUIPES = "11",
  MAPA_DE_CHUVA = "12",
}

export enum NomeMapaTematicoEnum {
  DISTRIBUICAO_VARIETAL = "Distribuição Varietal",
  CHUVAS = "Mapa de Chuva",
  IMAGENS_SATELITE = "Imagens de Satélite",
  TEXTURA_SOLO = "Textura de Solo",
  OPERACOES_EXECUTADAS = "Operações Executadas",
  NUMEROS_DE_APLICACOES = "Número de Aplicações",
  CONCENTRACAO_COLHEITA = "Concentração de Colheita",
  SATELLITE_IMAGE = "Imagens de Satélite",
  PRODUTIVIDADE = "Produtividade",
  IDADE_DO_TALHAO = "Idade do Talhão",
  ORDENS_DE_SERVICO_ABERTA = "Ordens de Serviço em Aberto ",
  DISTRIBUICAO_DE_EQUIPES = "Distribuição de Equipes",
}

export const LIST_TYPES_MAPS: Types_Map[] = [
  {
    id: TypesMapEnum.TEXTURA_SOLO,
    nome: NomeMapaTematicoEnum.TEXTURA_SOLO,
    enabled: true,
    filterMenuIndex: MapFilterIndex.TEXTURA_SOLO,
  },
  {
    id: TypesMapEnum.DISTRIBUICAO_VARIETAL,
    nome: NomeMapaTematicoEnum.DISTRIBUICAO_VARIETAL,
    enabled: true,
    filterMenuIndex: MapFilterIndex.DISTRIBUICAO_VARIETAL,
  },
  {
    id: TypesMapEnum.CONCENTRACAO_COLHEITA,
    nome: NomeMapaTematicoEnum.CONCENTRACAO_COLHEITA,
    enabled: true,
    filterMenuIndex: MapFilterIndex.CONCENTRACAO_COLHEITA,
  },
  {
    id: TypesMapEnum.PRODUTIVIDADE,
    nome: NomeMapaTematicoEnum.PRODUTIVIDADE,
    enabled: true,
    filterMenuIndex: MapFilterIndex.PRODUTIVIDADE,
  },
  {
    id: TypesMapEnum.ORDENS_DE_SERVICO_ABERTA,
    nome: NomeMapaTematicoEnum.ORDENS_DE_SERVICO_ABERTA,
    filterMenuIndex: MapFilterIndex.ORDENS_DE_SERVICO_ABERTA,
    enabled: true,
  },
  {
    id: TypesMapEnum.OPERACOES_EXECUTADAS,
    nome: NomeMapaTematicoEnum.OPERACOES_EXECUTADAS,
    enabled: true,
    filterMenuIndex: MapFilterIndex.OPERACOES_EXECUTADAS,
  },
  {
    id: TypesMapEnum.NUMERO_DE_APLICACOES,
    nome: NomeMapaTematicoEnum.NUMEROS_DE_APLICACOES,
    enabled: true,
    filterMenuIndex: MapFilterIndex.NUMERO_DE_APLICACOES,
  },
  {
    id: TypesMapEnum.IDADE_DO_TALHAO,
    nome: NomeMapaTematicoEnum.IDADE_DO_TALHAO,
    enabled: true,
    filterMenuIndex: MapFilterIndex.IDADE_DO_TALHAO,
  },
  {
    id: TypesMapEnum.CHUVAS,
    nome: NomeMapaTematicoEnum.CHUVAS,
    filterMenuIndex: MapFilterIndex.CHUVAS,
    enabled: true,
  },
  {
    id: TypesMapEnum.SATELLITE_IMAGE,
    nome: NomeMapaTematicoEnum.SATELLITE_IMAGE,
    filterMenuIndex: MapFilterIndex.SATELLITE_IMAGE,
    enabled: true,
  },
  {
    id: TypesMapEnum.DISTRIBUICAO_DE_EQUIPES,
    nome: NomeMapaTematicoEnum.DISTRIBUICAO_DE_EQUIPES,
    filterMenuIndex: MapFilterIndex.DISTRIBUICAO_DE_EQUIPES,
    enabled: true,
  },
];
