import { showError } from "infra/helpers/alerta";
import http from "infra/http";
import { ValidarKMLRetProps } from "../typings/validacaoKML";

interface ValidarKMLProps {
  file: File;
  unidadeId: string;
  safraId: string;
  safraPeriodoId: string;
}

const validarKML = async ({
  unidadeId,
  safraId,
  safraPeriodoId,
  file,
}: ValidarKMLProps) => {
  try {
    const { data } = await http.post(
      `/kml/unidades/${unidadeId}/safras/${safraId}/safra-periodo/${safraPeriodoId}/kml/validate`,
      {
        file,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return data as ValidarKMLRetProps;
  } catch (err) {
    console.log(err);
    showError(
      "Houve um problema ao validar arquivo. Por favor, tente novamente."
    );
  }
};

export default validarKML;
