import { useFiltersLists } from "features/filters/store/lists";

import {
  ConcentracaoColheitaTipo,
  FiltroConcentracaoColheitaItem,
  FiltroConcentracaoColheitaItemSemana,
} from "features/maps/typings";
import { useCallback, useEffect, useState } from "react";
import { ICheckboxItem } from "components/inputs/filter-safra-unidade-mapatematico/items/checkbox-tree";
import { filterConcentracoes } from "components/inputs/filter-safra-unidade-mapatematico/items/concentracao-colheita/helpers";
import { useConcentracaoColheitaStore } from "features/filters/store/useConcentracaoColheitaStore";

function useConcentracaoColheitaItemsFilters(value: ICheckboxItem[]) {
  const [items, setItems] = useState<ICheckboxItem<any>[]>([]);

  const {
    filters: { ocupacao, tecnologias, tipo, concentracoes: _concentracoes },
    updateField,
  } = useConcentracaoColheitaStore();

  const { concentracaoColheita } = useFiltersLists();

  const handleSearch = useCallback(
    (tipo: ConcentracaoColheitaTipo) => {
      if (!concentracaoColheita?.concentracoes || !ocupacao || !tipo) {
        return [];
      }

      const result = filterConcentracoes(
        concentracaoColheita?.concentracoes ?? [],
        ocupacao,
        tecnologias,
        tipo
      );

      return result;
    },
    [concentracaoColheita?.concentracoes, ocupacao, tecnologias]
  );

  useEffect(() => {
    (() => {
      const result = handleSearch(tipo);

      setItems(result);
    })();
  }, [handleSearch, tipo]);

  const isCheckedAllChildren = useCallback(
    (parent: ICheckboxItem) => {
      const childrenCheckeds = value.filter(
        (c) => c.parent?.value === parent.value
      );

      return childrenCheckeds.length >= parent.children.length;
    },
    [value]
  );

  useEffect(() => {
    const concentracoes: FiltroConcentracaoColheitaItem[] = [];
    const possibles = value.filter((v) => {
      if (
        items.map((ch) =>
          ch.children.find(
            (i) => `${i.key}-${i.parent}` === `${v.key}-${v.parent}`
          )
        )
      ) {
        return true;
      }

      return false;
    });

    for (const v of possibles) {
      if (!v.parent) {
        concentracoes.push({
          ano: v.extras.ano,
          mes: v.extras.mes,
          cor: v.extras.cor,
          nome: v.extras.nome,
          semanas: [],
        });

        continue;
      }

      const index = concentracoes.findIndex((c) => c.nome === v.parent.value);

      const semanas = value
        .filter((s) => s.parent && s.parent.value === v.parent.value)
        .map(
          (s): FiltroConcentracaoColheitaItemSemana => ({
            cor: s.color!,
            semanaCodigo: s.value,
          })
        );

      if (index >= 0) {
        concentracoes[index] = {
          ano: v.parent.extras.ano,
          mes: v.parent.extras.mes,
          cor: v.parent.extras.cor,
          nome: v.parent.extras.nome,
          semanas,
        };

        continue;
      }

      concentracoes.push({
        ano: v.parent.extras.ano,
        mes: v.parent.extras.mes,
        cor: v.parent.extras.cor,
        nome: v.parent.extras.nome,
        semanas,
      });
    }

    updateField("concentracoes", concentracoes);
  }, [value, updateField, items]);

  const isCheckRoot = (v: ICheckboxItem) => {
    const exists = value.find((item) => item.value === v.value);

    return !!exists;
  };

  const isCheckChild = (v: ICheckboxItem) => {
    return !!value.find(
      (item) => item.value === v.value && item.parent.value === v.parent.value
    );
  };

  const hasSemanasChecked = _concentracoes
    .map((c) => c.semanas.length > 0)
    .includes(true);

  const isCheckedAllRoots =
    !hasSemanasChecked && _concentracoes.length === items.length;

  return {
    isCheckRoot,
    isCheckChild,
    isCheckedAllChildren,
    isCheckedAllRoots,
    items,
  };
}

export default useConcentracaoColheitaItemsFilters;
