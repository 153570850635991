import { useFilters } from "core/features/filters/store";

export const useIsApplyButtonDisabledOnOperacoesExecutadas = () => {
  const { filters } = useFilters();
  const { filtroOperacoesExecutadas } = filters;
  const isDisabled = !filtroOperacoesExecutadas?.operacoes.length;

  return {
    disabledOperacoesAdvancedFilters: isDisabled,
  };
};
