import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  Text,
  Checkbox,
  Image,
  CloseButton,
} from "@chakra-ui/react";
import { ArquivosProps } from "core/features/talhoes/typings/validacaoKML";
import TalhaoMapInfo from "../../TalhaoMapInfo";
import ArrowRight from "assets/images/icons/arrow-right.svg";
import { useContext } from "react";
import { ImportTalhoesContext } from "core/features/talhoes/contexts/ImportTalhoes.context";

interface DetailsModalProps {
  isOpen: boolean;
  onClose: () => any;
  arquivo?: ArquivosProps;
}

const DetailsModal = ({ isOpen, onClose, arquivo }: DetailsModalProps) => {
  const { talhoesToimport, setTalhoesToImport } =
    useContext(ImportTalhoesContext);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxW="992px"
        paddingX="40px"
        paddingBottom="61px"
        paddingTop="27px"
      >
        <CloseButton
          position="absolute"
          right="20px"
          size="lg"
          top="10px"
          onClick={onClose}
        />
        <ModalHeader borderBottom="1px solid #00000033 " position="relative">
          <Flex alignItems="center" gridGap="5px">
            <Text color="#2C2C2C" fontSize="18px" fontWeight="bold">
              Detalhes da Geometria
            </Text>
            <Text color="#11A557" fontSize="18px" fontWeight="bold">
              Talhão {arquivo?.codigo}
            </Text>
          </Flex>
          <Text color="#676C77" fontSize="16px" fontWeight="medium">
            Compare os talhões abaixo
          </Text>
          <Flex
            justifyContent="flex-end"
            position="absolute"
            right="0"
            bottom="7px"
          >
            <Text
              fontWeight="bold"
              color="#525252"
              letterSpacing="0.06px"
              fontSize="14px"
              marginRight="8.55px"
            >
              IMPORTAR
            </Text>
            <Checkbox
              colorScheme="green"
              isDisabled={!arquivo?.atual}
              _active={{
                outline: "none",
              }}
              _hover={{
                outline: "none",
              }}
              _focus={{
                outline: "none",
              }}
              isChecked={
                !!talhoesToimport.find(
                  (item) => item.talhaoId === arquivo?.talhaoId
                )
              }
              onChange={() => {
                const isSelected = !!talhoesToimport.find(
                  (item) => item.talhaoId === arquivo?.talhaoId
                );
                if (isSelected) {
                  setTalhoesToImport(
                    talhoesToimport.filter(
                      (item) => item.talhaoId !== arquivo?.talhaoId
                    )
                  );
                } else {
                  setTalhoesToImport([
                    ...talhoesToimport,
                    {
                      talhaoId: arquivo?.talhaoId!,
                      wkt: arquivo?.atual?.wkt
                        ? arquivo?.atual?.wkt
                        : arquivo?.atual?.wtk!,
                    },
                  ]);
                }
              }}
            />
          </Flex>
        </ModalHeader>
        <Flex
          alignItems="center"
          gridGap="38px"
          marginTop="20px"
          justifyContent="center"
        >
          {arquivo?.atual && (
            <TalhaoMapInfo
              item={arquivo.atual}
              label={arquivo.codigo}
              type="Atual"
            />
          )}
          {arquivo?.atual && arquivo?.arquivo && <Image src={ArrowRight} />}
          {arquivo?.arquivo && (
            <TalhaoMapInfo
              item={arquivo.arquivo}
              label={arquivo.codigo}
              type="Arquivo"
            />
          )}
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default DetailsModal;
