import { Box, BoxProps, Text } from "@chakra-ui/layout";
import styled from "styled-components";

const ContainerOpacity = styled.div<{ isVisible: boolean }>`
  & > .content-wrapper {
    height: auto;
    transform-origin: top;
    transform: ${({ isVisible }) => (isVisible ? "scaleY(1)" : "scaleY(0)")};
    transition: all 0.5s;
  }
`;

function ContainerFilter({
  title,
  children,
  containerProps,
  isVisible = false,
}: {
  title?: string;
  children: any;
  containerProps?: BoxProps;
  isVisible?: boolean;
}) {
  return (
    <ContainerOpacity isVisible={isVisible}>
      <Box
        zIndex={1}
        roundedBottom={8}
        bg="white"
        pos="absolute"
        top="60px"
        w="100%"
        className="content-wrapper"
        {...containerProps}
      >
        {title ? (
          <Text fontWeight="bold" p="16px" fontSize="16px" color="gray.900">
            {title}
          </Text>
        ) : null}
        <Box maxH="calc(100vh - 150px)" overflowY="auto">
          {children}
        </Box>
      </Box>
    </ContainerOpacity>
  );
}

export default ContainerFilter;
