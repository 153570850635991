import React from "react";

import algodaoImg from "assets/images/icons/algodao/algodao_fallback.png";
import milhoImg from "assets/images/icons/milho/milho_fallback.png";
import sojaImg from "assets/images/icons/soja/soja_fallback.png";
import fallbackImg from "assets/images/icons/fallback-icon.svg";
import { OcupacaoNomeEnum, TalhaoDetalhes } from "core/features/maps/typings";
import { Flex, Text, Image } from "@chakra-ui/react";
import { gray500, gray800, green700 } from "core/resources/theme/colors";
import { getTimelineItems } from "./utils";

interface TimelineProps {
  detalhesTalhao: TalhaoDetalhes;
}

export interface TimelineItemProps {
  icon?: React.ReactNode | null;
  image?: string | null;
  info: string;
  dividerInfo?: string;
  showDivider?: boolean;
}

function TimelineItemDivider({ info }: { info?: string }) {
  return (
    <Flex
      h="48px"
      pl={4}
      ml={3}
      borderLeftWidth={2}
      borderLeftStyle="dotted"
      borderLeftColor={gray500}
      alignItems="center"
      my="7px"
    >
      {info ? (
        <Text color={green700} fontWeight="bold">
          {info}
        </Text>
      ) : null}
    </Flex>
  );
}

function TimelineItem({
  icon,
  image,
  info,
  dividerInfo,
  showDivider,
}: TimelineItemProps) {
  return (
    <>
      {showDivider ? <TimelineItemDivider info={dividerInfo} /> : null}
      <Flex gridGap={2} alignItems="center">
        <Flex w="24px" h="24px">
          {image ? (
            <Image
              src={image}
              objectFit="cover"
              minWidth={info.length > 40 ? "20px" : "0"}
              minHeight={info.length > 40 ? "20px" : "0"}
            />
          ) : null}
          {icon ? icon : null}
        </Flex>
        <Text color={gray800} fontSize={14}>
          {info}
        </Text>
      </Flex>
    </>
  );
}

function Timeline({ detalhesTalhao }: TimelineProps) {
  const ocupacao: OcupacaoNomeEnum = detalhesTalhao.ocupacao
    ?.nome as OcupacaoNomeEnum;

  const renderIcon = () => {
    if (ocupacao === OcupacaoNomeEnum.Algodão) {
      return algodaoImg;
    }
    if (ocupacao === OcupacaoNomeEnum.Milho) {
      return milhoImg;
    }
    if (ocupacao === OcupacaoNomeEnum.Soja) {
      return sojaImg;
    }
    return fallbackImg;
  };

  const renderTextOcupacao = () => {
    if (
      ocupacao === OcupacaoNomeEnum.Milho ||
      ocupacao === OcupacaoNomeEnum.Soja ||
      ocupacao === OcupacaoNomeEnum.Algodão
    ) {
      return detalhesTalhao.variedade?.nome;
    }
    return detalhesTalhao.ocupacao?.nome;
  };

  const timelineItems = getTimelineItems(detalhesTalhao);

  return (
    <Flex flexDir="column">
      <Flex gridGap={2} alignItems="center" mb={6}>
        <Flex w="24px" h="24px">
          <Image src={renderIcon()} w="24px" h="100%" objectFit="contain" />
        </Flex>
        <Text color={gray800} fontSize={16} fontWeight="bold">
          {detalhesTalhao.tecnologia
            ? `${detalhesTalhao.tecnologia?.nome} `
            : ""}
          {renderTextOcupacao()}
        </Text>
      </Flex>

      <Flex flexDir="column">
        {timelineItems.map((item, index) => {
          console.log(item);
          return <TimelineItem {...item} key={index} />;
        })}
      </Flex>
    </Flex>
  );
}

export default Timeline;
