import { Box, BoxProps, Checkbox } from "@chakra-ui/react";
import { ChangeEvent, useCallback } from "react";

import useFarmStore from "core/features/farm/states/useFarmStore";

export default function Boundary(props: BoxProps) {
  const { bounds, setBounds } = useFarmStore();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setBounds(e.target.checked);
    },
    [setBounds]
  );

  return (
    <Box data-testid="satellite-map-boundary" {...props}>
      <Checkbox
        data-testid="satellite-map-boundary-checkbox"
        colorScheme="gray"
        defaultChecked={bounds}
        onChange={handleChange}
      >
        Marcação Talhão
      </Checkbox>
    </Box>
  );
}
