import URL from "core/resources/urls";
import http from "infra/http";
import { UserType } from "../typings/auth";

export async function getProfile() {
  const data = await http.get(URL.USUARIO.PERFIL);

  if (data) {
    return data as { data: UserType };
  }

  throw new Error("Usuário não autorizado");
}

export async function updateProfileName(userName: string) {
  const { data }: { data: boolean } = await http.put(URL.USUARIO.PERFIL, {
    userName,
  });

  return data;
}

export async function updateProfileAvatar(photo: FormData) {
  const { data }: { data: { urlDownload: string } } = await http.put(
    URL.USUARIO.PHOTO,
    photo,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data.urlDownload;
}
