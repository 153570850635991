import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Text,
  Flex,
  Skeleton,
  Button,
} from "@chakra-ui/react";
import FullWrapper from "components/wrappers/full-wrapper";
import { CustomFeature } from "features/maps/typings";
import { useContext, useEffect, useState } from "react";
import { tabs } from "./tabs";
import { gray500, green700 } from "core/resources/theme/colors";
import useTalhaoResumido from "features/maps/hooks/useTalhaoResumido";
import TagTalhaoResumido from "./TagTalhaoResumido";
import { mixpanel } from "infra/packages/react-mixpanel";
import { eventLogger } from "infra/events";
import { useAuth } from "features/auth/store";
import { MdClose } from "react-icons/md";
import { TalhaoDetailContext } from "features/maps/talhao/TalhaoDetailContext";
import { ModalTalhaoIndex } from "features/maps/enums";
import ButtonFilter from "./components/ButtonFilter";

function TalhaoModal({
  onClose,
  talhao,
}: {
  onClose: () => void;
  talhao: CustomFeature;
}) {
  const [isOpenFilters, setIsOpenFilters] = useState(true);

  const { data: talhaoResumido, isFetching } = useTalhaoResumido(talhao.id);

  const { tabsIndex, handleChangeTabIndex } = useContext(TalhaoDetailContext);

  const { user } = useAuth();

  useEffect(() => {
    const produtividadeTabIndex = 4;
    if (tabsIndex === produtividadeTabIndex) {
      setIsOpenFilters(true);
    }
  }, [tabsIndex]);

  return (
    <>
      <FullWrapper
        data-testid="talhao-modal-overlay"
        backdropFilter="blur(2px)"
        bg={"rgba(0,0,0,.3)"}
        transition="ease all .3s"
        zIndex={9999}
        pb="64px"
      >
        <Box
          rounded="8px"
          bg="white"
          pos="absolute"
          bottom="32px"
          top="32px"
          alignSelf="center"
          w="90%"
          minW="820px"
          height="calc(100% - 80px)"
          px="24px"
          pt="16px"
          // overflow="scroll"
          // overflowX="hidden"
        >
          <Button
            data-testid="talhao-modal-close-button"
            onClick={onClose}
            position="absolute"
            top="12px"
            right="24px"
            w="fit-content"
            px={0}
            bg="transparent"
            py={0}
          >
            <MdClose size={32} color={gray500} />
          </Button>
          <Flex gridGap={4} alignItems="center">
            <Text fontWeight="bold" fontSize="18px">
              Talhão {talhao.codigo} - Setor {talhaoResumido?.setor || "--"}
            </Text>
            {isFetching ? (
              <>
                <Skeleton rounded="full" w={80} h="100%" />
                <Skeleton rounded="full" w={80} h="100%" />
              </>
            ) : (
              <>
                <TagTalhaoResumido>
                  {talhaoResumido?.area + " ha" || "0 ha"}
                </TagTalhaoResumido>
                {talhaoResumido?.texturaSolo ? (
                  <TagTalhaoResumido>
                    {talhaoResumido?.texturaSolo.nome}{" "}
                  </TagTalhaoResumido>
                ) : null}
              </>
            )}
          </Flex>

          <Tabs index={tabsIndex} onChange={handleChangeTabIndex}>
            <Flex flex={1}>
              <Flex flex={1}>
                <TabList flex={1} mb={1} mt={2} borderWidth={0}>
                  {tabs.map((tab, index) => (
                    <Tab
                      flex={1}
                      data-testid={`button-tab-${tab.label}`}
                      onClick={() => {
                        mixpanel.track(
                          `CadernoDeCampo_${tab.label}`,
                          eventLogger(user?.email as string)
                        );
                      }}
                      key={index}
                      border={`1px solid ${gray500}`}
                      borderTopWidth={0}
                      borderLeftWidth={0}
                      borderRightWidth={0}
                      color={{
                        color: gray500,
                      }}
                      _active={{
                        color: green700,
                      }}
                      _focus={{
                        color: green700,
                      }}
                      _selected={{
                        color: green700,
                        borderBottomWidth: 1,
                        borderBottomColor: green700,
                      }}
                      px={0}
                    >
                      <Flex alignItems="center" pr={2} pb={0}>
                        <Flex w="24px" h="24px">
                          {tab.Icon}
                        </Flex>
                        <Text
                          noOfLines={1}
                          color="inherit"
                          fontSize={13}
                          fontWeight="medium"
                        >
                          {tab.label}
                        </Text>
                      </Flex>
                    </Tab>
                  ))}
                </TabList>
              </Flex>

              {tabsIndex !== ModalTalhaoIndex.Detalhes ? (
                <ButtonFilter
                  isOpen={isOpenFilters}
                  onClick={() => setIsOpenFilters((prev) => !prev)}
                />
              ) : null}
            </Flex>
            <TabPanels h="auto">
              {tabs.map((tab, index) => (
                <TabPanel px={0} key={index}>
                  <tab.Component
                    filterActive={isOpenFilters}
                    active={tabsIndex === index}
                    talhao={talhao}
                  />
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
      </FullWrapper>
    </>
  );
}

export default TalhaoModal;
