import {
  FiltroDistribuicaoVarietal,
  FiltroOperacoesExecutadas,
} from "core/features/maps/typings";

export const getNumberOfAdvancedFiltersDistribuicaoVarietal = (
  actualFilter: FiltroDistribuicaoVarietal
) => {
  if (actualFilter) {
    const {
      periodoPlantio,
      periodoColheita,
      periodoPrevisaoDeColheita,
      periodoEmergencia,
    } = actualFilter;

    const periodoPlantioCount =
      periodoPlantio?.dataInicial || periodoPlantio?.dataFinal ? 1 : 0;

    const periodoColheitaCount =
      periodoColheita?.dataInicial || periodoColheita?.dataFinal ? 1 : 0;

    const periodoPrevisaoDeColheitaCount =
      periodoPrevisaoDeColheita?.dataInicial ||
      periodoPrevisaoDeColheita?.dataFinal
        ? 1
        : 0;
    const periodoEmergenciaCount =
      periodoEmergencia?.dataInicial || periodoEmergencia?.dataFinal ? 1 : 0;

    return (
      periodoPlantioCount +
      periodoColheitaCount +
      periodoPrevisaoDeColheitaCount +
      periodoEmergenciaCount
    );
  }
  return 0;
};

export const getNumberOfAdvancedFiltersOperacoesExecutadas = (
  actualFilter: FiltroOperacoesExecutadas
) => {
  if (actualFilter) {
    const { equipeIds, insumos, periodoOperacao } = actualFilter;

    const periodoOperacaoCount =
      periodoOperacao?.dataInicial || periodoOperacao?.dataFinal ? 1 : 0;

    const insumosCount = insumos && insumos.length > 0 ? 1 : 0;

    const equipesIdCount = equipeIds && equipeIds.length > 0 ? 1 : 0;

    return periodoOperacaoCount + insumosCount + equipesIdCount;
  }
  return 0;
};

export const getIsApplyButtonDisabledOnDistribuicaoVarietal = () => {};
