import { Text, Flex, Box, Progress } from "@chakra-ui/react";
import { ColumnsType } from "antd/lib/table";
import { GridProps } from "core/features/maps/typings";
import { fallbackText } from "ui/utils";
import moment from "moment";

var numberRegexPattern = /[^0-9]/g;
const detailTalhoesColums: ColumnsType<GridProps> = [
  {
    width: "100px",
    title: () => (
      <Text fontWeight="bold" fontSize="13.5px">
        {" "}
        TALHÂO{" "}
      </Text>
    ),
    render: (_, record) => {
      return (
        <Flex alignItems="center" justifyContent="space-between">
          <Box
            height="22px"
            width="22px"
            borderRadius="5px"
            background={record.Cor}
          />
          <Text color="#525252" letterSpacing="0.06px" fontSize="15px">
            {record.Codigo}
          </Text>
        </Flex>
      );
    },
    defaultSortOrder: "ascend",
    sortDirections: [],
    sorter: (a, b) => {
      const codigo1 = Number(a.Codigo.replace(numberRegexPattern, ""));
      const codigo2 = Number(b.Codigo.replace(numberRegexPattern, ""));

      return +codigo1 - +codigo2;
    },
  },
  {
    title: () => (
      <Text fontWeight="bold" fontSize="13.5px">
        {" "}
        ÁREA(HA){" "}
      </Text>
    ),
    dataIndex: "Area",
    render(value) {
      return value || fallbackText;
    },
  },
  {
    title: () => (
      <Text fontWeight="bold" fontSize="13.5px">
        {" "}
        CULTURA{" "}
      </Text>
    ),
    dataIndex: "Ocupacao",
    render(value) {
      return value || fallbackText;
    },
  },
  {
    width: "130px",
    title: () => (
      <Text fontWeight="bold" fontSize="13.5px">
        {" "}
        VARIEDADE{" "}
      </Text>
    ),
    dataIndex: "Variedade",
    render(value) {
      return value || fallbackText;
    },
  },
  {
    align: "center",
    title: () => (
      <Text fontWeight="bold" fontSize="13.5px">
        {" "}
        PLANTIO{" "}
      </Text>
    ),
    dataIndex: "DataPlantio",
    render(value) {
      return value ? moment(value).format("DD/MM/YYYY") : fallbackText;
    },
  },
  {
    width: "110px",
    align: "center",
    title: () => (
      <Text fontWeight="bold" fontSize="13.5px">
        {" "}
        EMERGÊNCIA{" "}
      </Text>
    ),
    dataIndex: "DataEmergencia",
    render(value) {
      return value ? moment(value).format("DD/MM/YYYY") : fallbackText;
    },
  },
  {
    width: "55px",
    align: "center",
    title: () => (
      <Text fontWeight="bold" fontSize="13.5px">
        {" "}
        DAE{" "}
      </Text>
    ),
    dataIndex: "DAE",
    render(value) {
      return value || fallbackText;
    },
  },
  {
    width: "55px",
    align: "center",
    title: () => (
      <Text fontWeight="bold" fontSize="13.5px">
        {" "}
        E.F{" "}
      </Text>
    ),
    dataIndex: "EstadioFenologico",
    render(value) {
      return value || fallbackText;
    },
  },
  {
    title: () => (
      <Text fontWeight="bold" fontSize="13.5px">
        {" "}
        COLHEITA{" "}
      </Text>
    ),
    dataIndex: "DataColheita",
    render(value) {
      return value ? moment(value).format("DD/MM/YYYY") : fallbackText;
    },
  },
  {
    title: () => (
      <Text fontWeight="bold" fontSize="13.5px">
        {" "}
        CICLO DE DESENV.{" "}
      </Text>
    ),
    width: "135px",
    dataIndex: "Progresso",
    render(value) {
      return (
        <Progress
          defaultValue={0}
          value={value}
          colorScheme={value >= 100 ? "green" : "gray"}
          borderRadius="10px"
          size="sm"
          animation="infinite"
          isAnimated
        />
      );
    },
  },
  {
    width: "100px",
    title: () => (
      <Text fontWeight="bold" fontSize="13.5px">
        {" "}
        PROD.{" "}
      </Text>
    ),
    dataIndex: "Produtividade",
    render(value) {
      if (!value) return fallbackText;
      return Number(String(value).split(" ")[0]).toLocaleString("pt-br", { style: "decimal", minimumFractionDigits: 2 });
    },
  },
];

export default detailTalhoesColums;
