import { ModalTalhaoIndex } from "core/features/maps/enums";
import { TipoMapaTalhoes } from "core/features/maps/typings";

export const getDefaultIndex = (
  tipoMapa: TipoMapaTalhoes
): ModalTalhaoIndex => {
  if (tipoMapa === TipoMapaTalhoes.DistribuicaoVarietal) {
    return ModalTalhaoIndex.Detalhes;
  }

  if (tipoMapa === TipoMapaTalhoes.OperacoesExecutadas) {
    return ModalTalhaoIndex.OperacoesRealizadas;
  }

  if (tipoMapa === TipoMapaTalhoes.NumeroDeAplicacoes) {
    return ModalTalhaoIndex.Aplicacoes;
  }

  if (tipoMapa === TipoMapaTalhoes.Produtividade) {
    return ModalTalhaoIndex.Produtividade;
  }

  if (tipoMapa === TipoMapaTalhoes.IdadeDoTalhao) {
    return ModalTalhaoIndex.Detalhes;
  }
  if (tipoMapa === TipoMapaTalhoes.OrdensDeServicosAberta) {
    return ModalTalhaoIndex.OrdemServico;
  }
  if (tipoMapa === TipoMapaTalhoes.DistribuicaoDeEquipes) {
    return ModalTalhaoIndex.OrdemServico;
  }

  return ModalTalhaoIndex.Detalhes;
};
