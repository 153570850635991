import { Button, Flex } from "@chakra-ui/react";
import React from "react";
import { FilterByEnum } from "..";

interface ActionsButtonGraficoPluviometricoProps {
  actualFilter: FilterByEnum;
  handleChange: (newFilter: FilterByEnum) => void;
}

const ActionsButtonGraficoPluviometrico = ({
  actualFilter,
  handleChange,
}: ActionsButtonGraficoPluviometricoProps) => {
  return (
    <Flex>
      <Button
        background={actualFilter === FilterByEnum.Days ? "green.700" : "none"}
        borderRadius={0}
        border="1px solid"
        borderColor="gray.500"
        color={actualFilter === FilterByEnum.Days ? "white" : "gray.500"}
        _focus={{}}
        _active={{}}
        onClick={() => handleChange(FilterByEnum.Days)}
        borderTopLeftRadius="5px"
        borderBottomLeftRadius="5px"
      >
        Dias
      </Button>
      <Button
        background={actualFilter === FilterByEnum.Weeks ? "green.700" : "none"}
        borderRadius={0}
        border="1px solid"
        borderColor="gray.500"
        color={actualFilter === FilterByEnum.Weeks ? "white" : "gray.500"}
        _focus={{}}
        _active={{}}
        onClick={() => handleChange(FilterByEnum.Weeks)}
        borderRight="0"
        borderLeft="0"
      >
        Semanas
      </Button>
      <Button
        background={actualFilter === FilterByEnum.Months ? "green.700" : "none"}
        borderRadius={0}
        border="1px solid"
        borderColor="gray.500"
        color={actualFilter === FilterByEnum.Months ? "white" : "gray.500"}
        _focus={{}}
        _active={{}}
        onClick={() => handleChange(FilterByEnum.Months)}
        borderTopRightRadius="5px"
        borderBottomRightRadius="5px"
      >
        Meses
      </Button>
    </Flex>
  );
};

export default ActionsButtonGraficoPluviometrico;
