import { useFilters } from "features/filters/store";

import { useFiltersLists } from "features/filters/store/lists";
import {
  OrdensDeServicosAberta,
  OrdensDeServicosAbertaOperacoesType,
} from "features/maps/typings";
import { useCallback, useEffect, useMemo } from "react";
import * as _ from "lodash";

const useOrdensDeServicoAbertaOperacoesFilters = () => {
  const { filters, updateFiltroOrdensDeServicosAbertas } = useFilters();
  const { ordensDeServicosAbertas } = useFiltersLists();

  const removedDuplicatedOperacoes = ordensDeServicosAbertas?.operacoes?.filter(
    (v, i, a) => a.findIndex((t) => t.descricao === v.descricao) === i
  );

  const operacoesByOcupacao = removedDuplicatedOperacoes?.filter((item) =>
    filters.filtroOrdensDeServicosAbertas?.periodos?.includes(item.periodo)
  );

  const operacoesByPeriodos = operacoesByOcupacao?.filter((operacao) =>
    filters.filtroOrdensDeServicosAbertas?.periodos.find((item) => {
      return item === operacao.periodo;
    })
  );

  const selectOperacao = (descricao: string) => {
    const operacaoFinded = ordensDeServicosAbertas?.operacoes.find(
      (operacao) => operacao.descricao === descricao
    );

    const operacoesUpdate = filters.filtroOrdensDeServicosAbertas?.operacoes
      ? ([
          ...filters.filtroOrdensDeServicosAbertas?.operacoes,
          operacaoFinded,
        ] as OrdensDeServicosAberta["operacoes"])
      : [operacaoFinded];

    if (operacaoFinded) {
      updateFiltroOrdensDeServicosAbertas({
        operacoes: operacoesUpdate as OrdensDeServicosAberta["operacoes"],
        periodos: filters.filtroOrdensDeServicosAbertas?.periodos,
      });
    }
  };

  const unSelectOperacao = (descricao: string) => {
    const operacoesUpdate =
      filters.filtroOrdensDeServicosAbertas?.operacoes.filter(
        (op) => op.descricao !== descricao
      );

    if (operacoesUpdate) {
      updateFiltroOrdensDeServicosAbertas({
        ...filters.filtroOrdensDeServicosAbertas,
        operacoes: operacoesUpdate,
      });
    }
  };

  const unSelectAllOperacoes = () => {
    updateFiltroOrdensDeServicosAbertas({
      ...filters.filtroOrdensDeServicosAbertas,
      operacoes: [],
    });
  };

  const onChange = useCallback(
    (nextValue: OrdensDeServicosAbertaOperacoesType[]) => {
      updateFiltroOrdensDeServicosAbertas(
        {
          operacoes: nextValue,
        },
        true
      );
    },
    [updateFiltroOrdensDeServicosAbertas]
  );

  const value = useMemo(
    () => filters.filtroOrdensDeServicosAbertas?.operacoes ?? [],
    [filters.filtroOrdensDeServicosAbertas?.operacoes]
  );

  const items = useMemo(() => operacoesByPeriodos ?? [], [operacoesByPeriodos]);

  useEffect(() => {
    const possibles = value.filter((v) => {
      if (items.find((i) => i.descricao === v.descricao)) {
        return true;
      }

      return false;
    });

    if (!_.isEqual(possibles, value)) {
      onChange(possibles);
    }
  }, [onChange, value, items]);

  return {
    selectOperacao,
    unSelectOperacao,
    unSelectAllOperacoes,
    items: operacoesByPeriodos ?? [],
    onChange,
    value,
  };
};

export default useOrdensDeServicoAbertaOperacoesFilters;
