import {
  Divider,
  Flex,
  Image,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Text,
  useToast,
  Input,
  Button,
} from "@chakra-ui/react";
import animationLoading from "assets/lotties/uploading.json";
import { green700 } from "core/resources/theme/colors";
import { showError, showSuccess } from "infra/helpers/alerta";

import { useContext, useEffect, useState } from "react";
import GenericFileIcon from "assets/images/icons/agrodrive/genericFileIcon.svg";
import useMoveDir from "../../hooks/useMoveDir";
import { AgrodriveContext } from "core/features/agrodrive/contexts/Agrodrive.context";
import { LoadingTile } from "./styles";
import Lottie from "react-lottie";

interface ModalRenameProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  item: any;
}

export default function ModalRename({
  isOpen,
  onClose,
  item,
}: ModalRenameProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [renameInput, setRenameInput] = useState<string>(item.nome);
  const toast = useToast();
  const { mutate: handleRenameItem } = useMoveDir();

  const {
    items: { tagsHerdadas, customTagsData },
    onChange: { refetchDiretorioList },
  } = useContext(AgrodriveContext);

  const handleRename = () => {
    setLoading(true);
    handleRenameItem(
      {
        nome: item.nome,
        novoNome: renameInput,
        localizacao: item.localizacao !== "" ? item.localizacao : "/",
        novaLocalizacao: item.localizacao !== "" ? item.localizacao : "/",
        tags: item.tags,
      },
      {
        onError: () => {
          showError();
          onClose();
          setLoading(false);
        },
        onSuccess: () => {
          setLoading(false);
          refetchDiretorioList();
          onClose();
          showSuccess();
        },
      }
    );
  };

  return (
    <Modal
      size="4xl"
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex display="flex" flexdir="row" mb="4">
            <Image src={GenericFileIcon} />
            <Text ml="4" mt="1" color="#6B6C7E">
              Renomear
            </Text>
          </Flex>
          <Divider />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack mb="2">
            <Text fontWeight={700} fontSize="16px">
              Informe novo nome
            </Text>
            <Text marginLeft={"2"} color="red.600" size="16px">
              *
            </Text>
          </HStack>
          <Input
            value={renameInput}
            autoFocus
            h="28px"
            borderRadius="5px"
            maxLength={256}
            border="1px solid #747474"
            focusBorderColor={green700}
            onChange={(e) => {
              setRenameInput(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter justifyContent={"center"} alignItems={"center"}>
          <Button variant="ghost" onClick={onClose} w="100px" h="40px">
            Cancelar
          </Button>
          <Button
            bg={green700}
            color={"white"}
            w="100px"
            h="40px"
            ml="1"
            mr={3}
            _hover={{
              opacity: 0.73,
            }}
            onClick={() => handleRename()}
          >
            Renomear
          </Button>
        </ModalFooter>
        {loading && (
          <LoadingTile>
            <Lottie
              options={{
                animationData: animationLoading,
                rendererSettings: {
                  clearCanvas: true,
                },
              }}
              width={150}
            />
          </LoadingTile>
        )}
      </ModalContent>
    </Modal>
  );
}
