import { Flex, Text, TextProps } from "@chakra-ui/react";
import { TalhaoDetalhes } from "core/features/maps/typings";
import { yellow400 } from "core/resources/theme/colors";
import { AiFillStar } from "react-icons/ai";

interface OutrosDetalhesProps {
  detalhesTalhao: TalhaoDetalhes;
}
const TextTituloItem = ({ children }: TextProps) => (
  <Text
    color="gray.500"
    display="flex"
    alignItems="center"
    gridGap={1}
    whiteSpace="nowrap"
  >
    {children}
  </Text>
);

const TextValorItem = ({ children }: TextProps) => (
  <Text
    marginTop="-15px"
    marginLeft="22px"
    fontWeight="bold"
    color={"black"}
    fontSize="18px"
  >
    {children}
  </Text>
);

function OutrosDetalhes({ detalhesTalhao }: OutrosDetalhesProps) {
  return (
    <Flex flexDir="column" gridGap={4} w="100%">
      <TextTituloItem>
        {detalhesTalhao.produtividade?.produtividade ? (
          <AiFillStar color={yellow400} fontSize="18px" />
        ) : null}

        {detalhesTalhao.produtividade?.produtividade ? "Produtividade:" : ""}
      </TextTituloItem>
      <TextValorItem>
        {detalhesTalhao.produtividade?.produtividade}{" "}
        {detalhesTalhao.produtividade?.unidadeMedida}
      </TextValorItem>
    </Flex>
  );
}

export default OutrosDetalhes;
