import { Safra, Unidade } from "core/features/maps/typings";
import { Setor } from "core/features/unidades/typings";
import { createContext, ReactNode, useState } from "react";
import {
  Cultura,
  Talhao,
  CustomTag,
} from "ui/pages/app/arquivos/agrodrive/types/tags";

export interface ModalNewFileContextProps {
  items: {
    currentSafrasVinculadas: Safra[];
    currentUnidadesVinculada: Unidade[];
    currentCulturaVinculada: Cultura[];
    currentSetoresVinculadas: Setor[];
    currentTalhoesVinculados: Talhao[];
    tagsPersonalizadasVinculadas: CustomTag[];
  };
  onChange: {
    setCurrentSafrasVinculadas: (prop: Safra[]) => void;
    setCurrentUnidadesVinculadas: (prop: Unidade[]) => void;
    setCurrentCulturaVinculada: (prop: Cultura[]) => void;
    setCurrentSetoresVinculados: (prop: Setor[]) => void;
    setCurrentTalhoesVinculados: (prop: Talhao[]) => void;
    setTagsPersonalizadasVinculadas: (prop: CustomTag[]) => void;
  };
}

export const ModalNewFileContext = createContext(
  {} as ModalNewFileContextProps
);

export const ModalNewFileContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [currentSafrasVinculadas, setCurrentSafrasVinculadas] = useState<
    Safra[]
  >([]);
  const [currentUnidadesVinculada, setCurrentUnidadesVinculadas] = useState<
    Unidade[]
  >([]);
  const [currentCulturaVinculada, setCurrentCulturaVinculada] = useState<
    Cultura[]
  >([]);
  const [currentSetoresVinculadas, setCurrentSetoresVinculados] = useState<
    Setor[]
  >([]);
  const [currentTalhoesVinculados, setCurrentTalhoesVinculados] = useState<
    Talhao[]
  >([]);
  const [tagsPersonalizadasVinculadas, setTagsPersonalizadasVinculadas] =
    useState<CustomTag[]>([]);

  // const [tagsVinculadas, setTagsVinculadas] = useState<string[]>([]);

  return (
    <ModalNewFileContext.Provider
      value={{
        items: {
          currentSafrasVinculadas,
          currentUnidadesVinculada,
          currentCulturaVinculada,
          currentSetoresVinculadas,
          currentTalhoesVinculados,
          tagsPersonalizadasVinculadas,
          // tagsVinculadas,
        },
        onChange: {
          setCurrentSafrasVinculadas,
          setCurrentUnidadesVinculadas,
          setCurrentCulturaVinculada,
          setCurrentSetoresVinculados,
          setCurrentTalhoesVinculados,
          setTagsPersonalizadasVinculadas,
          // setTagsVinculadas,
        },
      }}
    >
      {children}
    </ModalNewFileContext.Provider>
  );
};
