import config from "infra/config";

const URL = {
  USUARIO: {
    PERFIL: "/user/perfil",
    PHOTO: "/user/perfil-social/avatar/upload",
    LISTA_DE_USUARIOS: "/User",
    NOVO_USUARIO: "/User",
    ATUALIZAR_USUARIO: (usuarioId: string) => `/User/${usuarioId}`,
    ATUALIAR_FOTO_DO_USUARIO: (usuarioId: string) =>
      `/User/${usuarioId}/perfil-social/avatar/upload`,
  },
  TERMO_USO: {
    ACEITAR: "/user/termos-uso/aceitar",
  },
  SHARE: {
    CRIAR: `/web/share`,
    OBTER: (id: string) => `/web/share/${id}`,
  },
  UNIDADES: {
    SETORES: (fazendaId: string, safraId: string) =>
      `/unidades/${fazendaId}/safras/${safraId}/setores`,
    REGIONAL: "unidades/regional",
  },
  MAPAS: {
    GERAL: (unidadeId: string, safraId: string) =>
      `/web/unidades/${unidadeId}/safras/${safraId}/talhoes`,
    OCUPACOES: (unidadeId: string, safraId: string) =>
      `web/Unidades/${unidadeId}/safras/${safraId}/ocupacoes`,
    DISTRIBUICAO_VARIETAL: (unidadeId: string, safraId: string) =>
      `/web/unidades/${unidadeId}/mapa/distribuicao-varietal?safraId=${safraId}`,
    UNIDADES: "/unidades",
    SAFRAS: "/safras",
    TEXTURAS_SOLO: "/texturassolo",
    MAPA_TEXTURAS_SOLO: (unidadeId: string, texturas: number[]) => {
      let url = `/web/unidades/${unidadeId}/mapa/textura-solo?`;
      texturas.forEach((textura) => {
        url = url + `textura=${textura}&`;
      });
      return url;
    },
    MAPA_OPERACOES_EXECUTADAS: (unidadeId: string, safraId: string) =>
      `/web/unidades/${unidadeId}/mapa/operacao?safraId=${safraId}`,
    OPERACOES_EXECUTADAS: (unidadeId: string, safraId: string) =>
      `web/Unidades/${unidadeId}/safras/${safraId}/operacoes`,
    MAPA_NUMERO_DE_APLICACOES: (unidadeId: string, safraId: string) =>
      `/web/unidades/${unidadeId}/mapa/numero-aplicacoes?safraId=${safraId}`,
    NUMERO_DE_APLICACOES: (unidadeId: string, safraId: string) =>
      `/web/Unidades/${unidadeId}/safras/${safraId}/numero-aplicacoes`,
    MAPA_PRODUTIVIDADE: (unidadeId: string, safraId: string) =>
      `/web/unidades/${unidadeId}/mapa/produtividade?safraId=${safraId}`,
    MAPA_CONCENTRACAO_COLHEITA: (unidadeId: string, safraId: string) =>
      `/web/unidades/${unidadeId}/mapa/concentracao-colheita?safraId=${safraId}`,
    CONCENTRACAO_COLHEITA: (unidadeId: string, safraId: string) =>
      `/web/Unidades/${unidadeId}/safras/${safraId}/concentracao-colheita`,
    PRODUTIVIDADE: (unidadeId: string, safraId: string) =>
      `/web/unidades/${unidadeId}/safras/${safraId}/produtividade`,
    MAPA_IDADE_DO_TALHAO: (unidadeId: string, safraId: string) =>
      `/web/unidades/${unidadeId}/mapa/idade-talhao?safraId=${safraId}`,
    IDADE_DO_TALHAO: (unidadeId: string, safraId: string) =>
      `/web/unidades/${unidadeId}/safras/${safraId}/idade-talhao`,
    MAPA_DE_CHUVA_FILTRO_RAPIDO: () =>
      `/web/unidades/mapa/pluviometrico/filtro-rapido`,
    MAPA_DE_CHUVA_FILTRO_INDICES: (periodo: number, unidadeId: string) =>
      `/web/unidades/${unidadeId}/mapa/pluviometrico/${periodo}/filtro-indices`,
    MAPA_DE_CHUVA_GEOJSON: (unidadeId: string) =>
      `/web/unidades/${unidadeId}/mapa/pluviometrico`,
    ORDENS_DE_SERVICOS_ABERTAS: (unidadeId: string) =>
      `/web/unidades/${unidadeId}/ordens-de-servicos-abertas`,
    MAPA_ORDENS_DE_SERVICOS_ABERTAS: (unidadeId: string) =>
      `/web/unidades/${unidadeId}/mapa/ordens-de-servicos-abertas`,
    DISTRIBUICAO_DE_EQUIPES: (unidadeId: string) =>
      `/web/unidades/${unidadeId}/distribuicao-equipe`,
    MAPA_DISTRIBUICAO_DE_EQUIPES: (unidadeId: string) =>
      `/web/unidades/${unidadeId}/mapa/distribuicao-equipe`,
    TABELA_ORDENS_DE_SERVICOS_ABERTAS_POR_EQUIPE: (unidadeId: string) =>
      `/Unidades/${unidadeId}/ordens-abertas`,
  },
  TALHOES: {
    DETALHE_TALHAO: (talhaoId: string) => `/talhoes/${talhaoId}/detalhes`,
    ORDENS_SERVICO: (talhaoId: string) => `/talhoes/${talhaoId}/ordens-servico`,
    ORDENS_SERVICO_TELEMETRIA: (talhaoId: string, ordemServicoId: string) =>
      `/talhoes/${talhaoId}/telemetria?ordemServicoId=${ordemServicoId}`,
    OPERACOES_REALIZADAS: (talhaoId: string) =>
      `/talhoes/${talhaoId}/operacoes`,
    APLICACOES: (talhaoId: string) => `/talhoes/${talhaoId}/aplicacoes`,
    APLICACOES_GRAFICO: (talhaoId: string) =>
      `/Talhoes/${talhaoId}/custo-aplicacoes`,
    TALHAO_RESUMIDO: (talhaoId: string) => `/talhoes/${talhaoId}`,
    PRODUTIVIDADE: (talhaoId: string, page: number = 1) =>
      `/web/talhoes/${talhaoId}/historico-produtividade?Size=100`,
    PRODUTIVIDADE_WITH_FILTER: (
      talhaoId: string,
      filter: number,
      page: number = 1
    ) =>
      `/web/talhoes/${talhaoId}/historico-produtividade?Page=${page}&Size=10&Ocupacao=${filter}`,
  },
  EDITAR_TALHOES: {
    OCUPACAO_E_TECNOLOGIAS: (unidadeId: string, safraId: string) =>
      `/gerencia-de-talhoes/unidades/${unidadeId}/safras/${safraId}/filtro`,
    LISTA_E_GEOJSON: (unidadeId: string, safraId: string) =>
      `/gerencia-de-talhoes/unidades/${unidadeId}/safras/${safraId}/talhoes`,
    SALVAR_TALHOES_EDITADOS: (talhaoId: string) =>
      `/gerencia-de-talhoes/${talhaoId}`,
  },
  IMPORTAR_TALHOES: {
    SAFRA_PERIODO: (safraId: string, unidadeId: string) =>
      `/safras/${safraId}/unidade/${unidadeId}/safra-periodos`,
    KML_VALIDATE: (
      unidadeId: string,
      safraId: string,
      safraPeriodoId: string
    ) =>
      `/kml/unidades/${unidadeId}/safras/${safraId}/safra-periodo/${safraPeriodoId}/kml/validate`,
    KML_UPDATE: () => "/kml",
  },
  INSUMOS_TALHOES: {
    INSUMOS_TALHAO: (talhaoId: string) =>
      `/talhoes/${talhaoId}/insumos-grupos-disponiveis`,
  },
  GRAFICO: {
    CONCENTRACAO_DE_COLHEITA: () => "/concentracao-colheita/semana",
    INDICES_PLUVIOMETRICOS: (talhaoId: string, filterBy: number) =>
      `/talhoes/${talhaoId}/grafico-pluviometrico?by=${filterBy}`,
  },
  PERFIS: {
    PERFIS_LISTA: "/Perfis",
  },

  AGRODRIVE: {
    ARQUIVOS: () => `${config.urlAgrodriveApi}/v1/arquivo`,
    ARQUIVOS_LIST: (
      pagina: number,
      tamanhoPagina: number,
      texto?: string,
      tags?: string
    ) =>
      `${config.urlAgrodriveApi}/v1/arquivo?texto=${texto}&tags=${tags}&pagina=${pagina}&tamanhoPagina=${tamanhoPagina}`,
    ARQUIVOS_FIND: () => `${config.urlAgrodriveApi}/v1/arquivo`,
    ARQUIVOS_UPDATE: (id: string) => `${config.urlAgrodriveApi}/v1/arquivo`,
    ARQUIVOS_DELETE: (ids: string[]) => `${config.urlAgrodriveApi}/v1/arquivo`,
    ARQUIVOS_DOWNLOAD: (ids: string[]) => {
      const idsQuery = ids.map((id) => `ids=${id}`).join("&");
      return `${config.urlAgrodriveApi}/v1/Download?${idsQuery}`;
    },
    ARQUIVOS_MOVE: (id: string, newFolderId: string) =>
      `${config.urlAgrodriveApi}/v1/arquivo/${id}/move/${newFolderId ?? ""}`,
    DIRETORIO: () => `${config.urlAgrodriveApi}/v1/diretorio`,
    DIRETORIO_LIST: (
      diretorio: string,
      pagina: number,
      tamanhoPagina: number,
      orderBy: string
    ) =>
      `${config.urlAgrodriveApi}/v1/diretorio?diretorio=${diretorio}&pagina=${pagina}&tamanhoPagina=${tamanhoPagina}&orderBy=${orderBy}`,
    TALHAO: () => `${config.urlAgrodriveApi}/v1/tag/custon`,
    CULTURA_LIST: (unidadeId: string, safraId: string, culturaId: string) =>
      `${config.urlAgrodriveApi}/v1/cultura?unidade=${unidadeId}&safra=${safraId}&culturaId=${culturaId}`,
    SETORES_LIST: () => `${config.urlAgrodriveApi}/...}`,

    UNIDADE: () => `${config.urlAgrodriveApi}/v1/unidade`,
    SAFRA: (fazendaIds: string[]) =>
      `${config.urlAgrodriveApi}/v1/safra?${fazendaIds.map(
        (fazendaID: string) => {
          return `fazendaIds=${fazendaID}&`;
        }
      )}`,
    CULTURA: () => `${config.urlAgrodriveApi}/...`,
    SETORES: (fazendaIds: string[], safraIds: string[]) =>
      `${config.urlAgrodriveApi}/v1/setor?${fazendaIds.map(
        (fazendaID: string) => {
          return `fazendaIds=${fazendaID}&`;
        }
      )}${safraIds.map((safraID: string) => {
        return `safraIds=${safraID}&`;
      })}`,
    TAGS: () => `${config.urlAgrodriveApi}/v1/tag/custon`,
  },

  JUSTIFICATIVA: {
    ATRASOS: (
      idFazenda?: string,
      codigoOcupacao?: string,
      idSafra?: string,
      regional?: string,
      idPlanoOperacionalOperacaoTipoAtraso?: number,
      apenasAprovados?: boolean,
      apenasJustificados?: boolean,
      apenasAprovadosCiente?: boolean,
      apenasPendentesJustificativas?: boolean,
      page?: number,
      size?: number,
    ) => 
      `plano-operacional/atrasados?pagina=${page}&tamanhoPagina=${size}${regional ? `&regional=${regional}` : ""}${idFazenda ? `&idFazenda=${idFazenda}` : ""}${codigoOcupacao ? `&codigoOcupacao=${codigoOcupacao}` : ""}${idSafra ? `&idSafra=${idSafra}` : ""}${idPlanoOperacionalOperacaoTipoAtraso ? `&idPlanoOperacionalOperacaoTipoAtraso=${idPlanoOperacionalOperacaoTipoAtraso}` : ""}${apenasAprovados !== undefined ? `&apenasAprovados=${apenasAprovados}` : ""}${apenasJustificados !== undefined ? `&apenasJustificados=${apenasJustificados}` : ""}${apenasAprovadosCiente !== undefined ? `&apenasAprovadosCiente=${apenasAprovadosCiente}` : ""}${apenasPendentesJustificativas !== undefined ? `&apenasPendentesJustificativas=${apenasPendentesJustificativas}` : ""}`,
    ATRASOS_TOTAL: (
      idFazenda?: string,
      codigoOcupacao?: string,
      idSafra?: string,
      regional?: string,
      idPlanoOperacionalOperacaoTipoAtraso?: number,
      apenasAprovados?: boolean,
      apenasJustificados?: boolean,
      apenasAprovadosCiente?: boolean,
      apenasPendentesJustificativas?: boolean,
    ) => 
    `plano-operacional/atrasados/total?${regional ? `&regional=${regional}` : ""}${idFazenda ? `&idFazenda=${idFazenda}` : ""}${codigoOcupacao ? `&codigoOcupacao=${codigoOcupacao}` : ""}${idSafra ? `&idSafra=${idSafra}` : ""}${idPlanoOperacionalOperacaoTipoAtraso ? `&idPlanoOperacionalOperacaoTipoAtraso=${idPlanoOperacionalOperacaoTipoAtraso}` : ""}${apenasAprovados !== undefined ? `&apenasAprovados=${apenasAprovados}` : ""}${apenasJustificados !== undefined ? `&apenasJustificados=${apenasJustificados}` : ""}${apenasAprovadosCiente !== undefined ? `&apenasAprovadosCiente=${apenasAprovadosCiente}` : ""}${apenasPendentesJustificativas !== undefined ? `&apenasPendentesJustificativas=${apenasPendentesJustificativas}` : ""}`,
    SALVAR: (idPlanoOperacionalOperacao: number) => 
      `plano-operacional/${idPlanoOperacionalOperacao}/justificativas`,
    APROVAR: (idPlanoOperacionalOperacao: string, idJustificativa: string) =>
      `plano-operacional/${idPlanoOperacionalOperacao}/justificativas/${idJustificativa}/aprovar`,
    JUSTIFICATIVA: (idPlanoOperacionalOperacao: number, idJustificativa: number) =>
      `plano-operacional/${idPlanoOperacionalOperacao}/justificativas/${idJustificativa}`,
    TIPOS_ATRASO: "plano-operacional/tipos-atraso",
    CIENTE: "plano-operacional/justificativas/ciente",
  },

  PRECIPITACAO: {
    OBTER: (t: string) => `/precipitacao?t=${t}`,
  },
};

export default URL;
