import { Box, Button, Flex, Radio, Text } from "@chakra-ui/react";
import React from "react";

interface RadioColorProps {
  color: string;
  name: string;
  checked: boolean;
  onClick: () => void;
}

const removedButtonDefaultStyles = {
  background: "none",
  border: "none",
};

const RadioColor = ({ color, name, checked, onClick }: RadioColorProps) => {
  return (
    <Flex justifyContent="flex-start">
      <Button
        onClick={onClick}
        background="none"
        width="100%"
        justifyContent="flex-start"
        _hover={removedButtonDefaultStyles}
        _focus={removedButtonDefaultStyles}
        _active={removedButtonDefaultStyles}
      >
        <Radio
          isChecked={checked}
          value={name}
          colorScheme={"gray"}
          color={color}
          size="md"
        />

        <Box
          height="22px"
          width="22px"
          borderRadius="5px"
          backgroundColor={color}
          margin="0 10px"
        />

        <Text fontWeight="bold" color="gray.700">
          {name}
        </Text>
      </Button>
    </Flex>
  );
};
export default RadioColor;
