import { Box } from "@chakra-ui/react";
import { TableProps, Table } from "antd";
import { useCallback, useEffect, useRef } from "react";

interface DatatableAntdProps<RecordType extends object = any>
  extends TableProps<RecordType> {
  onLoad?: () => void;
  maxH?: string;
  pagination?: any;
}

function DatatableAntd<RecordType extends object = any>({
  onLoad,
  maxH,
  pagination = false,
  ...props
}: DatatableAntdProps<RecordType>) {
  const refContainer = useRef<HTMLDivElement | null>(null);
  const lastDistanceTop = useRef<number>(0);
  const isFinishScroll = useRef<boolean>(false);

  useEffect(() => {
    if (props.dataSource?.length) {
      setTimeout(() => {
        isFinishScroll.current = false;
      }, 500);
    }
  }, [props.dataSource]);

  const handleScroll = useCallback(
    (ev: any) => {
      const tableBody: HTMLElement = document.querySelector(
        ".ant-table-body"
      ) as HTMLElement;

      const { scrollHeight } = refContainer.current ?? {};

      const distanceTop = ev.target.scrollTop + scrollHeight;

      if (
        distanceTop >= tableBody.scrollHeight &&
        lastDistanceTop.current < distanceTop &&
        isFinishScroll.current === false
      ) {
        lastDistanceTop.current = distanceTop;

        isFinishScroll.current = true;
        onLoad?.();
      }
    },
    [onLoad]
  );

  useEffect(() => {
    const tableBody: HTMLElement | null =
      document.querySelector(".ant-table-body");

    tableBody?.addEventListener("scroll", handleScroll);

    return () => {
      tableBody?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Box ref={refContainer} maxHeight={maxH}>
      <Table
        {...props}
        size="small"
        pagination={pagination}
      />
    </Box>
  );
}

export default DatatableAntd;
