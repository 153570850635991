import ContainerFilter from "../../ContainerFilter";
import { useFiltersLists } from "core/features/filters/store/lists";
import { useFilters } from "core/features/filters/store";
import { useEffect } from "react";
import OcupacoesButtons from "../../OcupacoesButtons";
import SelectedButtons from "../../SelectedButtons";

import { Flex, IconButton } from "@chakra-ui/react";
import {
  FiltroNumeroDeAplicacoes,
  NumeroDeAplicacoesAplicacoes,
  NumeroDeAplicacoesOcupacoes,
  TecnologiasItem,
} from "features/maps/typings";
import CheckboxFiltro from "components/inputs/checkbox-filtro";
import { ButtonClearMapCheckbox } from "../../ButtonLimpar";
import { capitalizeFirstLetter } from "ui/utils";
import Insumos from "./Insumos";
import { OcupacaoEnum } from "core/features/maps/enums";
import LoadingBox from "components/layout/loading-box";
import NotFoundMapFilters from "components/NotFoundMapFilters";
import { FaChevronUp } from "react-icons/fa";
import MapGridButton from "components/MapGridButton";

interface FiltersNumeroDeAplicacoesProps {
  isVisible: boolean;
  handleChangeIsVisible: (prop: boolean) => void;
  handleOpenGrid: () => void;
}

function FiltersNumeroDeAplicacoes({
  handleChangeIsVisible,
  handleOpenGrid,
  isVisible,
}: FiltersNumeroDeAplicacoesProps) {
  const { filters, updateFiltroNumeroDeAplicacoes } = useFilters();
  const { loadings, numeroDeAplicacoes } = useFiltersLists();

  const findTecnologiasByOcupacaoSelected = numeroDeAplicacoes?.ocupacoes.find(
    (item) => item.codigo === filters.filtroNumeroDeAplicacoes?.ocupacao
  );

  const findInsumosByOcupacaoSelected = numeroDeAplicacoes?.ocupacoes.find(
    (item) => item.codigo === filters.filtroNumeroDeAplicacoes?.ocupacao
  )?.insumos;

  const findAplicacoes = numeroDeAplicacoes?.aplicacoes.filter(
    (item) => item.ocupacao === filters.filtroNumeroDeAplicacoes?.ocupacao
  );

  const findAplicacoesWithTecnologiaSelected = findAplicacoes?.filter((item) =>
    filters.filtroNumeroDeAplicacoes?.tecnologias.includes(item.tecnologiaId)
  );

  const aplicacoes =
    findAplicacoesWithTecnologiaSelected &&
    findAplicacoesWithTecnologiaSelected?.length > 0
      ? findAplicacoesWithTecnologiaSelected
      : findAplicacoes;

  const findAplicacoesWithInsumoSelected = aplicacoes?.filter(
    (item) => item.insumoGrupo === filters.filtroNumeroDeAplicacoes?.insumoGrupo
  );

  const removedDuplicateAplicacoes = findAplicacoesWithInsumoSelected?.filter(
    (v, i, a) => a.findIndex((t) => t.descricao === v.descricao) === i
  );

  const isCheckedTodos =
    !!filters.filtroNumeroDeAplicacoes?.aplicacoes &&
    !!filters.filtroNumeroDeAplicacoes?.aplicacoes?.length &&
    filters.filtroNumeroDeAplicacoes?.aplicacoes.length ===
      removedDuplicateAplicacoes?.length;

  const onClickAplicacao = (aplicacao: NumeroDeAplicacoesAplicacoes) => {
    const formattedAplicacao = {
      codigo: aplicacao.codigo,
      color: aplicacao.cor,
      tecnologiaId: aplicacao.tecnologiaId,
    };

    if (
      filters.filtroNumeroDeAplicacoes?.aplicacoes?.find(
        (op) => aplicacao.codigo === op.codigo
      )
    ) {
      const filteredAplicacoes =
        filters.filtroNumeroDeAplicacoes.aplicacoes.filter(
          (op) => op.codigo !== aplicacao.codigo
        );
      updateFiltroNumeroDeAplicacoes({
        tecnologias: filters.filtroNumeroDeAplicacoes?.tecnologias,
        ocupacao: filters.filtroNumeroDeAplicacoes?.ocupacao,
        aplicacoes: filteredAplicacoes,
        insumoGrupo: filters.filtroNumeroDeAplicacoes.insumoGrupo,
      });
    } else {
      updateFiltroNumeroDeAplicacoes(
        {
          tecnologias: filters.filtroNumeroDeAplicacoes?.tecnologias,
          ocupacao: filters.filtroNumeroDeAplicacoes?.ocupacao,
          aplicacoes: [
            ...(filters.filtroNumeroDeAplicacoes?.aplicacoes as any),
            formattedAplicacao,
          ],
          insumoGrupo: filters.filtroNumeroDeAplicacoes?.insumoGrupo,
        },
        true
      );
    }
  };

  const onClearAplicacoes = () => {
    updateFiltroNumeroDeAplicacoes({
      tecnologias: filters.filtroNumeroDeAplicacoes?.tecnologias,
      ocupacao: filters.filtroNumeroDeAplicacoes?.ocupacao,
      aplicacoes: [],
      insumoGrupo: filters.filtroNumeroDeAplicacoes?.insumoGrupo,
    });
  };

  const formattedNumeroDeAplicacoesFilters = removedDuplicateAplicacoes?.map(
    (item) => {
      return {
        color: item.cor,
        codigo: item.codigo,
        tecnologiaId: item.tecnologiaId,
      };
    }
  );

  const isInsumoDisabled = (codigo?: number) => {
    const disabled = numeroDeAplicacoes?.aplicacoes
      ?.filter((item) =>
        filters.filtroNumeroDeAplicacoes?.tecnologias.includes(
          item.tecnologiaId
        )
      )
      .filter((i) => i.ocupacao === filters.filtroNumeroDeAplicacoes?.ocupacao)
      .filter((a) => a.insumoGrupo === codigo);
    return disabled && disabled.length <= 0 ? true : false;
  };

  const selectOcupacao = (ocupacaoId: OcupacaoEnum) => {
    const findOcupacao = numeroDeAplicacoes?.ocupacoes.find(
      (item) => item.codigo === ocupacaoId
    );
    const findFirstTecnologiaEnabled = findOcupacao?.tecnologias.find(
      (item) => item.id
    );
    let findFirstInsumoEnabled = findOcupacao?.insumos.find(
      (item) => item.enabled
    );

    const disabledInsumo = isInsumoDisabled(findFirstInsumoEnabled?.codigo);

    if (!disabledInsumo) {
      findFirstInsumoEnabled = findOcupacao?.insumos
        .filter((item) => item.codigo !== findFirstInsumoEnabled?.codigo)
        .find(
          (i) => i.enabled && !isInsumoDisabled(findFirstInsumoEnabled?.codigo)
        );
    }

    updateFiltroNumeroDeAplicacoes({
      ocupacao: findOcupacao?.codigo,
      tecnologias: [findFirstTecnologiaEnabled?.id as string],
      aplicacoes: [],
      insumoGrupo: findFirstInsumoEnabled?.codigo,
    });
  };

  useEffect(() => {
    if (!filters.filtroNumeroDeAplicacoes?.tecnologias.length) {
      const ocp = numeroDeAplicacoes?.ocupacoes || [];
      const isOcupacaoEnabled = ocp.find(
        (item) => item.codigo === filters.filtroNumeroDeAplicacoes?.ocupacao
      );

      if (
        !filters.filtroNumeroDeAplicacoes?.ocupacao ||
        !isOcupacaoEnabled?.enabled
      ) {
        const findFirstOcupacaoEnabled = ocp.find((item) => item.enabled);
        const findFirstInsumoEnabled = findFirstOcupacaoEnabled?.insumos.find(
          (item) => item.enabled
        );
        const findFirstTecnologiaEnabled =
          findFirstOcupacaoEnabled?.tecnologias.find((item) => item.id);
        updateFiltroNumeroDeAplicacoes({
          ocupacao: findFirstOcupacaoEnabled?.codigo,
          tecnologias: findFirstTecnologiaEnabled?.id
            ? [findFirstTecnologiaEnabled.id]
            : [],
          aplicacoes: [],
          insumoGrupo: findFirstInsumoEnabled?.codigo,
        });
      }
    }
  }, [
    filters.filtroNumeroDeAplicacoes?.ocupacao,
    filters.filtroNumeroDeAplicacoes?.tecnologias.length,
    numeroDeAplicacoes?.ocupacoes,
    updateFiltroNumeroDeAplicacoes,
  ]);

  const updateTecnologiasSelected = (tecnologiaId?: string) => {
    if (filters.filtroNumeroDeAplicacoes?.tecnologias.length) {
      const findElementIndex =
        filters.filtroNumeroDeAplicacoes.tecnologias.findIndex(
          (item) => item === tecnologiaId
        );
      const newArray = [...filters.filtroNumeroDeAplicacoes.tecnologias];
      if (findElementIndex > -1) {
        if (filters.filtroNumeroDeAplicacoes?.tecnologias.length === 1) {
          return;
        }
        newArray.splice(findElementIndex, 1);
      } else {
        newArray.push(tecnologiaId as string);
      }
      const foundCompativeAplicacoes =
        filters.filtroNumeroDeAplicacoes.aplicacoes.filter((item) =>
          newArray.includes(item.tecnologiaId as string)
        );

      const checkTodos = findTecnologiasByOcupacaoSelected?.tecnologias
        .filter((item) => item.id !== null)
        .map((i) => {
          return i.id;
        });

      if (!tecnologiaId) {
        updateFiltroNumeroDeAplicacoes({
          aplicacoes: foundCompativeAplicacoes,
          ocupacao: filters.filtroNumeroDeAplicacoes?.ocupacao,
          tecnologias: checkTodos,
          insumoGrupo: filters.filtroNumeroDeAplicacoes.insumoGrupo,
        });
      } else {
        updateFiltroNumeroDeAplicacoes({
          aplicacoes: foundCompativeAplicacoes,
          ocupacao: filters.filtroNumeroDeAplicacoes?.ocupacao,
          tecnologias: newArray,
          insumoGrupo: filters.filtroNumeroDeAplicacoes.insumoGrupo,
        });
      }
    }
  };

  useEffect(() => {
    if (!filters.filtroNumeroDeAplicacoes?.tecnologias.length) {
      const findOcupacao = numeroDeAplicacoes?.ocupacoes.find(
        (item) => item.codigo === filters.filtroNumeroDeAplicacoes?.ocupacao
      );
      const findFirstTecnologiaEnabled = findOcupacao?.tecnologias?.find(
        (item) => item.id
      );

      updateFiltroNumeroDeAplicacoes({
        aplicacoes: [],
        ocupacao: filters.filtroNumeroDeAplicacoes?.ocupacao,
        tecnologias: findFirstTecnologiaEnabled?.id
          ? [findFirstTecnologiaEnabled.id]
          : [],
      });
    }
  }, [
    filters.filtroNumeroDeAplicacoes?.ocupacao,
    filters.filtroNumeroDeAplicacoes?.tecnologias.length,
    numeroDeAplicacoes?.ocupacoes,
    updateFiltroNumeroDeAplicacoes,
  ]);

  useEffect(() => {
    if (!filters.filtroNumeroDeAplicacoes?.tecnologias.length) {
      const findOcupacao = numeroDeAplicacoes?.ocupacoes.find(
        (item) => item.codigo === filters.filtroNumeroDeAplicacoes?.ocupacao
      );
      const findFirstTecnologiaEnabled = findOcupacao?.tecnologias?.find(
        (item) => item.id
      );
      if (
        !filters.filtroNumeroDeAplicacoes?.tecnologias.includes(
          findFirstTecnologiaEnabled?.id as string
        )
      ) {
        updateFiltroNumeroDeAplicacoes({
          aplicacoes: [],
          ocupacao: filters.filtroNumeroDeAplicacoes?.ocupacao,
          tecnologias: findFirstTecnologiaEnabled?.id
            ? [findFirstTecnologiaEnabled.id]
            : [],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.unidade,
    filters.safra,
    filters.filtroNumeroDeAplicacoes?.tecnologias.length,
  ]);

  if (loadings.numeroDeAplicacoes) {
    return <LoadingBox isVisible={isVisible} />;
  }

  if (!numeroDeAplicacoes && !loadings.numeroDeAplicacoes) {
    return (
      <ContainerFilter
        containerProps={{
          mt: 3,
          rounded: 8,
          padding: 4,
        }}
      >
        <Flex pl={1} mb={1}>
          <NotFoundMapFilters message="Não foram encontadras ocupações para o filtro selecionado" />
        </Flex>
      </ContainerFilter>
    );
  }

  return (
    <ContainerFilter
      containerProps={{
        mt: 3,
        rounded: 8,
        padding: 4,
      }}
      isVisible={isVisible}
    >
      <OcupacoesButtons
        onChange={selectOcupacao}
        value={filters.filtroNumeroDeAplicacoes?.ocupacao!}
        items={numeroDeAplicacoes?.ocupacoes as NumeroDeAplicacoesOcupacoes[]}
      />
      {!loadings.numeroDeAplicacoes ? (
        <>
          <SelectedButtons
            onChange={updateTecnologiasSelected}
            value={filters.filtroNumeroDeAplicacoes?.tecnologias ?? []}
            items={
              findTecnologiasByOcupacaoSelected?.tecnologias as TecnologiasItem[]
            }
          />
          <Insumos
            update={updateFiltroNumeroDeAplicacoes}
            filter={
              filters.filtroNumeroDeAplicacoes as FiltroNumeroDeAplicacoes
            }
            insumos={findInsumosByOcupacaoSelected}
            isInsumoDisabled={isInsumoDisabled}
          />
          <Flex h="1px" bg="gray.200" mt={4} flex={1} />

          <Flex mt={4} flexDir="column" ml={2}>
            {removedDuplicateAplicacoes &&
            !removedDuplicateAplicacoes.length ? (
              <Flex>
                <NotFoundMapFilters message="Não foram encontadras aplicações para o filtro selecionado" />
              </Flex>
            ) : null}
            <Flex>
              {removedDuplicateAplicacoes &&
              removedDuplicateAplicacoes.length ? (
                <CheckboxFiltro
                  disabled={false}
                  onClick={() =>
                    updateFiltroNumeroDeAplicacoes({
                      tecnologias:
                        filters.filtroNumeroDeAplicacoes?.tecnologias,
                      ocupacao: filters.filtroNumeroDeAplicacoes?.ocupacao,
                      aplicacoes: isCheckedTodos
                        ? []
                        : formattedNumeroDeAplicacoesFilters,
                      insumoGrupo:
                        filters.filtroNumeroDeAplicacoes?.insumoGrupo,
                    })
                  }
                  isChecked={isCheckedTodos}
                  text={"Todas"}
                  data-testid="menu-filtros-numero-de-aplicacoes-todos"
                />
              ) : null}
              {removedDuplicateAplicacoes &&
              removedDuplicateAplicacoes.length ? (
                <ButtonClearMapCheckbox onClear={onClearAplicacoes} />
              ) : null}
            </Flex>

            {removedDuplicateAplicacoes &&
              removedDuplicateAplicacoes.length > 0 &&
              removedDuplicateAplicacoes.map((aplicacao, index) => (
                <CheckboxFiltro
                  key={aplicacao.codigo + index}
                  text={capitalizeFirstLetter(aplicacao.descricao)}
                  onClick={() => onClickAplicacao(aplicacao)}
                  color={aplicacao.cor}
                  data-testid={`menu-filtro-numero-de-aplicacoes-${aplicacao.descricao}`}
                  isChecked={
                    !!filters.filtroNumeroDeAplicacoes?.aplicacoes?.find(
                      (op) => op.codigo === aplicacao.codigo
                    )
                  }
                />
              ))}
          </Flex>
        </>
      ) : null}
      <Flex
        padding="5px"
        justifyContent="space-between"
        borderTop="1px solid #84848440 "
        alignItems="center"
        paddingTop="15px"
        marginTop="10px"
      >
        <MapGridButton handleOpenGrid={handleOpenGrid} />

        <IconButton
          background="none"
          _hover={{}}
          _focus={{}}
          _active={{}}
          icon={<FaChevronUp color="#848484" />}
          aria-label="close-button"
          marginRight="20px"
          onClick={() => handleChangeIsVisible(false)}
        />
      </Flex>
    </ContainerFilter>
  );
}

export default FiltersNumeroDeAplicacoes;
