import { Flex, Image, Text } from "@chakra-ui/react";

import CalendarEndDateIcon from "../../../../../../../../assets/images/icons/calendar-end-icon.svg";
import CalendarInitalDateIcon from "../../../../../../../../assets/images/icons/calendar-initial-icon.svg";
import { useMapaDeChuvaActualIndiceDates } from "../../hooks/MapaDeChuvaActualIndiceDates";

const MapaDeChuvaActualIndiceDates = () => {
  const { value } = useMapaDeChuvaActualIndiceDates();

  return (
    <Flex flexDirection="column" margin="10px">
      <Text
        marginBottom="10px"
        fontWeight="medium"
        fontSize="16px"
        color="#676C77"
      >
        Índices (mm)
      </Text>
      <Flex gridGap="5px" marginBottom="5px">
        <Image src={CalendarInitalDateIcon} />
        <Text fontWeight="bold" fontSize="14.5px" color="#676C77">
          {value.initialDate} - {value.initialHour}
        </Text>
      </Flex>
      <Flex gridGap="5px">
        <Image src={CalendarEndDateIcon} />
        <Text fontWeight="bold" fontSize="14.5px" color="#676C77">
          {value.endDate} - {value.endHour}
        </Text>
      </Flex>
    </Flex>
  );
};

export default MapaDeChuvaActualIndiceDates;
