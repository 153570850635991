import URL from "core/resources/urls";

import http from "infra/http";
import { useQuery } from "react-query";
import { MapaDeChuvaFiltroRapidoProps } from "../typings/mapaDeChuva";

export function useMapaDeChuvaFiltroRapido() {
  return useQuery([URL.MAPAS.MAPA_DE_CHUVA_FILTRO_RAPIDO], async () => {
    const { data } = await http.get<MapaDeChuvaFiltroRapidoProps[]>(
      URL.MAPAS.MAPA_DE_CHUVA_FILTRO_RAPIDO()
    );
    return data;
  });
}
