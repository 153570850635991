import { useMutation } from "react-query";

import URL from "core/resources/urls";
import { httpAgrodrive } from "infra/http";
import { ArquivoForm } from "../types/arquivos";
import config from "infra/config";

export default function useCreateFile() {
  return useMutation([URL.AGRODRIVE.ARQUIVOS()], async (body: ArquivoForm) => {
    const form = new FormData();

    form.append("Localizacao", body.Localizacao);
    body.Tags?.map((item: string) => form.append("Tags", item));
    body.files?.map((item: any) => form.append("files", item));

    await httpAgrodrive.post(URL.AGRODRIVE.ARQUIVOS(), form, {
      headers: {
        "x-api-key": config.agrodriveClientId,
      },
    });
  });
}
