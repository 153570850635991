import styled from "styled-components";

export const Spinner = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #11a557 94%, #0000) top/6.4px 6.4px
      no-repeat,
    conic-gradient(#0000 30%, #11a557);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(100% - 6.4px),
    #000 0
  );
  animation: spinner-c7wet2 1s infinite linear;
  @keyframes spinner-c7wet2 {
    100% {
      transform: rotate(1turn);
    }
  }
`;
