import { useContext } from "react";
import { HStack, Tag, TagCloseButton, TagLabel, Text } from "@chakra-ui/react";
import { JustificativaContext } from "core/features/justificativa/context/Justificativa.context";

export const FooterAtrasosTable = () => {
  const { selecteds, setSelecteds } = useContext(JustificativaContext);

  return (
    <HStack>
      {selecteds && selecteds?.length > 0 ? (
        <Tag
          size={"lg"}
          borderRadius="full"
          variant="solid"
          colorScheme="white"
          border="1px solid #7B7B7B"
          textColor="#7B7B7B"
          ml="2"
        >
          <TagCloseButton
            color="#7B7B7B"
            onClick={() => setSelecteds([])}
          />
          <TagLabel ml="2">
            {selecteds?.length === 1 && `${selecteds?.length} item selecionado`}
            {selecteds?.length > 1 && `${selecteds?.length} itens selecionados`}
          </TagLabel>
        </Tag>
      ) : null}
    </HStack>
  );
};
