import { Box, Text, Flex } from "@chakra-ui/layout";
import styled from "styled-components";

const ContainerOpacity = styled.div`
  & > .content-wrapper {
    height: auto;
    transform-origin: top;
    animation: expand 0.7s forwards;
  }

  @keyframes expand {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
`;

function ContainerSafraFilter({
  title,
  children,
}: {
  title: string;
  children: any;
}) {
  return (
    <ContainerOpacity>
      <Box
        zIndex={1}
        roundedBottom="8px"
        bg="white"
        pos="absolute"
        top="60px"
        w="100%"
        flexDir="row"
        className="content-wrapper"
      >
        <Text fontWeight="bold" p="16px" fontSize="16px" color="gray.900">
          {title}
        </Text>

        <Flex ml="16px" mr="16px" mb="36px">
          <Flex flex={1} flexWrap="wrap">{children}</Flex>
        </Flex>
      </Box>
    </ContainerOpacity>
  );
}

export default ContainerSafraFilter;
