import { BsPlus } from "react-icons/bs";
import { Divider, Flex } from "@chakra-ui/react";

import { BiMinus } from "react-icons/bi";
import { useControls } from "../../hooks/useControls";

function ControlZoom({ map }: { map: google.maps.Map }) {
  const { addZoom, minusZoom } = useControls(map);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      w="30px"
      px="6px"
      rounded="full"
      bg="white"
      flexDir="column"
    >
      <Flex
        py="8px"
        onClick={() => addZoom()}
        cursor="pointer"
        data-testid="config-mapa-zoom-in"
      >
        <BsPlus fontSize="22px" />
      </Flex>
      <Divider />
      <Flex
        onClick={() => minusZoom()}
        py="8px"
        cursor="pointer"
        data-testid="config-mapa-zoom-out"
      >
        <BiMinus fontSize="22px" />
      </Flex>
    </Flex>
  );
}

export default ControlZoom;
