import { Box, Collapse, Flex } from "@chakra-ui/react";
import InputSearch from "components/inputs/input-search";
import { CustomFeature } from "features/maps/typings";
import { ActionButtons, StatusOrdemServicoEnum } from "./ActionButtons";
import { columnTablesOrdensServico } from "./utils";
import DatatableAntd from "components/datatable-antd";

import { useTabOrdensServico } from "./hooks/useTabOrdensServico";
import OrdensServicoRow from "./OrdensServicoRow";
import OrdensServicoExpandIcon from "./OrdensServicoExpandIcon";
import OrdensServicoFooter from "./OrdensServicoFooter";
import { useFilters } from "core/features/filters/store";

interface TabOrdensServicoProps {
  talhao: CustomFeature;
  active: boolean;
  filterActive: boolean;
}

enum UnidadesToBlockRow {
  Tucunare = "2",
  AguaQuente = "8",
}

const TabOrdensServico = ({
  talhao,
  active,
  filterActive,
}: TabOrdensServicoProps) => {
  const {
    _isLoading,
    handleChangeOrdensDeServicoFilterIndex,
    handleOrdensDeServicoNumeroOs,
    isActive,
    items,
    nextPage,
    pagination,
    selectRow,
    expandedRows,
    ordensDeServicoFilterIndex,
    data,
  } = useTabOrdensServico({
    active,
    talhao,
  });
  const {
    filters: { unidade },
  } = useFilters();

  const blockExpandableRows =
    unidade?.codigo === UnidadesToBlockRow.AguaQuente ||
    unidade?.codigo === UnidadesToBlockRow.Tucunare;

  return (
    <Flex flexDir="column" flex={1}>
      <Collapse in={filterActive}>
        <Flex mb={4} flexDir="row" flex={1} w="100%" alignItems="center">
          <InputSearch
            data-testid="input-search-tab-ordem-servico"
            placeholder="Digite o n° da ordem"
            onChange={(text: string) => {
              handleOrdensDeServicoNumeroOs(Number(text));
            }}
            maxLength={9}
            isNumeric
          />
          <ActionButtons
            selectedStatus={ordensDeServicoFilterIndex}
            onChangeStatus={(status: StatusOrdemServicoEnum) => {
              handleChangeOrdensDeServicoFilterIndex(status);
            }}
            selectedNumber={_isLoading ? undefined : pagination.itemCount}
          />
        </Flex>
      </Collapse>

      <Flex flexDir="column">
        <Box>
          <DatatableAntd
            loading={_isLoading}
            dataSource={items}
            columns={columnTablesOrdensServico}
            expandedRowKeys={expandedRows}
            scroll={{ y: "calc(100vh - 380px)" }}
            onLoad={() => {
              nextPage();
            }}
            onRow={(record) => ({
              className: isActive(record) ? "row-selected" : "",
              style: { cursor: !blockExpandableRows ? "pointer" : "" },
              onClick: () =>
                !blockExpandableRows && selectRow(record.ordemDeServicoId),
            })}
            expandable={{
              expandRowByClick: true,
              expandIcon: ({ expanded, onExpand, record }) =>
                !blockExpandableRows ? (
                  <OrdensServicoExpandIcon
                    expanded={expanded}
                    onExpand={(e) => {
                      onExpand(record, e);

                      selectRow(record.ordemDeServicoId);
                    }}
                  />
                ) : null,
              expandedRowRender: (record) => (
                <OrdensServicoRow
                  ordemServicoId={record.ordemDeServicoId}
                  talhao={talhao}
                />
              ),
            }}
            footer={() => (
              <OrdensServicoFooter
                data={data}
                index={ordensDeServicoFilterIndex}
              />
            )}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default TabOrdensServico;
