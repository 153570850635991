import { Flex, Text } from "@chakra-ui/react";
import { Progress } from "@chakra-ui/progress";
import { TalhaoDetalhes } from "core/features/maps/typings";
import {
  blue600,
  green600,
  red100,
  yellow400,
  black,
} from "core/resources/theme/colors";
import React, { useCallback, useEffect, useState } from "react";

interface CicloDesenvolvimentoProps {
  detalhesTalhao: TalhaoDetalhes;
}

interface Ciclo {
  color?: string;
  text?: string | number;
}

function CicloDesenvolvimento({ detalhesTalhao }: CicloDesenvolvimentoProps) {
  const porcentagemCicloRealizado = detalhesTalhao
    ? (detalhesTalhao.ciclo.realizado / detalhesTalhao.ciclo.esperado) * 100
    : 0;

  const isColhido = detalhesTalhao.colheita?.real;

  const [ciclo, setCiclo] = useState<Ciclo>({});

  const verificaCiclo = useCallback(() => {
    const isColhido =
      detalhesTalhao.ciclo.realizado && detalhesTalhao.ciclo.esperado
        ? true
        : false;

    if (detalhesTalhao.ciclo.realizado < detalhesTalhao.ciclo.esperado) {
      if (isColhido) {
        setCiclo({
          color: yellow400,
          text: "O ciclo realizado foi menor do que o esperado",
        });
      } else {
        setCiclo({
          color: blue600,
          text: `${porcentagemCicloRealizado}% realizado`,
        });
      }
    }

    if (detalhesTalhao.ciclo.realizado === detalhesTalhao.ciclo.esperado) {
      if (isColhido) {
        setCiclo({
          color: green600,
          text: "Ciclo realizado com sucesso",
        });
      } else {
        setCiclo({
          color: blue600,
          text: `${porcentagemCicloRealizado}% realizado`,
        });
      }
    }
    if (detalhesTalhao.ciclo.realizado > detalhesTalhao.ciclo.esperado) {
      switch (isColhido) {
        case true:
      }
      if (isColhido) {
        setCiclo({
          color: red100,
          text: "O ciclo realizado ultrapassou o esperado",
        });
      } else {
        setCiclo({
          color: red100,
          text: "O ciclo ultrapassou o esperado",
        });
      }
    }
  }, [
    detalhesTalhao.ciclo.esperado,
    detalhesTalhao.ciclo.realizado,
    porcentagemCicloRealizado,
  ]);

  useEffect(() => {
    verificaCiclo();
  }, [verificaCiclo]);

  return (
    <Flex flexDir="column" gridGap={2} w="100%">
      <Flex justifyContent="space-between">
        <Text fontSize={16} fontWeight="bold" whiteSpace="nowrap">
          Ciclo de Desenvolvimento
        </Text>
        <Text fontSize={16} fontWeight="medium">
          {detalhesTalhao?.ciclo.esperado || "---"}
        </Text>
      </Flex>

      {Number.isFinite(porcentagemCicloRealizado) ? (
        <Flex my={2} w="100%">
          <Progress
            className="chakra-progress"
            rounded="full"
            value={porcentagemCicloRealizado}
            w="100%"
          />
        </Flex>
      ) : null}

      <Flex justifyContent="space-between">
        {isColhido ? (
          <Text
            fontSize={16}
            fontWeight="medium"
            color={ciclo?.color}
            whiteSpace="nowrap"
          >
            {ciclo?.text}
          </Text>
        ) : null}

        <Text
          fontSize={16}
          ml={!isColhido ? 'auto' : 0}
          fontWeight="medium"
          color={!isColhido ? black : ciclo.color}
        >
          {detalhesTalhao?.ciclo.realizado || 0}
        </Text>
      </Flex>
    </Flex>
  );
}

export default CicloDesenvolvimento;
