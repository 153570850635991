import { loginAmaggiRequest, msalAmaggiConfig } from "infra/auth/config";

import ButtonLogin from "../ButtonLogin";
import { PublicClientApplication } from "@azure/msal-browser";
import logo from "assets/images/icons/amaggi.svg";
import { useLogin } from "core/features/auth/hooks/useLogin";
import { TypeAD } from "infra/packages/@azure/instance";

export default function ButtonAmaggi() {
  const instance = new PublicClientApplication(msalAmaggiConfig);

  const { handleLogin, inProgress } = useLogin(instance);

  return (
    <ButtonLogin
      onClick={() => handleLogin(TypeAD.Amaggi, loginAmaggiRequest)}
      title="Acessar com login Amaggi"
      data-testid="button-login-amaggi"
      img={logo}
      color="green"
      isLoading={inProgress}
    />
  );
}
