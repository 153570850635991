import { Flex } from "@chakra-ui/react";
import { gray700, primary, white } from "core/resources/theme/colors";
import { MdTune } from "react-icons/md";

function ButtonFilter({
  onClick,
  isOpen,
}: {
  onClick: () => void;
  isOpen: boolean;
}) {
  return (
    <Flex w="70px" justifyContent="end" alignItems="center">
      <Flex
        mt="8px"
        justifyContent="center"
        alignItems="center"
        w="35px"
        h="35px"
        rounded="full"
        cursor="pointer"
        bg={isOpen ? primary : "transparent"}
        onClick={() => onClick()}
      >
        <MdTune fontSize={28} color={isOpen ? white : gray700} />
      </Flex>
    </Flex>
  );
}

export default ButtonFilter;
