const url = window.location.href;
const isPrecipitacaoPage = url.includes("precipitacao");

export const options = {
  panControl: false,
  zoomControl: false,
  scaleControl: false,
  rotateControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
};

export const DEFAULT_ZOOM = isPrecipitacaoPage ? 14 : 12.5;
