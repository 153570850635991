import DataOverlay from "../Overlays/DataOverlay";
import LoadingBar from "react-top-loading-bar";
import useImageRoute from "core/features/image/hooks/useImageRoute";
import useRouteOverlayStore from "core/features/image/states/useRouteOverlayStore";
import { useToast } from "@chakra-ui/react";

interface RouteLayerProps {
  map: google.maps.Map;
}

export default function RouteLayer({ map }: RouteLayerProps) {
  const toast = useToast();
  const { imageId } = useRouteOverlayStore();
  const { data, isLoading } = useImageRoute(imageId!, undefined, {
    enabled: !!imageId,
    staleTime: Infinity,
    onError: () => {
      toast({
        title: "Houve erro ao carregar rota do talhão",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    },
  });

  return (
    <>
      <LoadingBar
        progress={isLoading ? 80 : 100}
        color="#009540"
        loaderSpeed={4000}
        waitingTime={0}
      />
      {data?.data && (
        <DataOverlay
          map={map}
          geojson={{
            type: "Feature",
            geometry: data.data,
          }}
        />
      )}
    </>
  );
}
