import { BsFillLayersFill } from "react-icons/bs";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";

import { MapTypeId } from "core/features/maps/enums";
import { useControls } from "../../hooks/useControls";

function MenuTypeMap({ map }: { map: google.maps.Map }) {
  const { setMapType } = useControls(map);

  return (
    <Menu placement="left-start">
      <MenuButton data-testid="config-mapa-visualizacao-mapa">
        <BsFillLayersFill fontSize="18px" />
      </MenuButton>
      <MenuList>
        <MenuItem
          color="gray.900"
          fontWeight="bold"
          data-testid="menu-visualizacao-visualizacao"
        >
          Visualização
        </MenuItem>
        <MenuDivider />
        <MenuItem
          data-testid="menu-visualizacao-mapa"
          onClick={() => setMapType(MapTypeId.TERRAIN)}
          color="gray.900"
        >
          Mapa
        </MenuItem>
        <MenuItem
          data-testid="menu-visualizacao-satelite"
          onClick={() => setMapType(MapTypeId.SATELLITE)}
          color="gray.900"
        >
          Satélite
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default MenuTypeMap;
