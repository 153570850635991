import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  Flex,
} from "@chakra-ui/react";
import { justificativaColumsName } from "./justificativasColumns";
import DatatableAntd from "components/datatable-antd";
import { useGetJustificativas } from "core/features/justificativa/hooks/useGetJustificativas";
import { useGetTiposAtraso } from "core/features/justificativa/hooks/useGetTiposAtraso";
import { IoIosClose } from "react-icons/io";
import { useAuth } from "core/features/auth/store";
import { PerfisEnum } from "core/features/users/enums";
import { formataData } from "../../utils/formataData";

interface JustificativasModalProps {
  isOpen: boolean;
  closeModal: any;
  atraso: any;
  openDetalhesJustificativa: any;
}

const JustificativasModal = ({
  isOpen,
  closeModal,
  atraso,
  openDetalhesJustificativa,
}: JustificativasModalProps) => {

  const { user } = useAuth();

  const perfis = user?.perfis;
  const hasPermissionToEdit = perfis?.some((i) => i === PerfisEnum.Gerente_Regional || i === PerfisEnum.Gerente_Unidade);

  const { data: tiposAtraso } = useGetTiposAtraso();

  const { data, isFetching } = useGetJustificativas({
    idTalhaoPlanoOperacionalOperacao: atraso?.idTalhaoPlanoOperacionalOperacao,
  });

  return (
    <Modal isOpen={isOpen} onClose={() => {}} isCentered size="xl">
      <ModalOverlay />
      <ModalContent padding="18px" maxW="800px">
        <Flex marginBottom="8px" justifyContent="space-between" alignItems="center">
          <Text>
            Talhão: {atraso?.talhaoCodigo} - Operação {atraso?.operacaoDescricao} - Data Planejada: {formataData(atraso?.dataProgramada)}
          </Text>

          <IoIosClose size="40px" color="#000" cursor="pointer" onClick={closeModal} />
        </Flex>

        <Box height="100%" width="100%">
          <DatatableAntd
            columns={justificativaColumsName(
              tiposAtraso,
              openDetalhesJustificativa,
              hasPermissionToEdit
            )}
            loading={isFetching}
            dataSource={data}
            scroll={{ y: "calc(100vh - 260px)" }}
            onRow={() => ({
              style: {
                cursor: "pointer",
              }
            })}
          />
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default JustificativasModal;
